import {objectToQueryString} from '@root/application/infra/api/fetcher'
import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class CreditAnalysisRepository extends BaseHttpRepository {
  async getResult(proposalId, analysisId) {
    return this._api.get(`creditanalysis/result/${analysisId}/${proposalId}`)
  }

  async getResultLegacy(proposalId, propertyId) {
    return this._api.get(`creditanalysis/result/legacy/${propertyId}/${proposalId}`)
  }

  /**
   * @param {string} proposalId
   * @param {string} propertyId
   * @return {Promise<*>}
   */
  async analyze(proposalId, propertyId) {
    return this._api.post('creditanalysis/analysis', {
      proposal_id: proposalId,
      property_id: propertyId,
    })
  }
}

export const creditAnalysisRepoInstance = new CreditAnalysisRepository()

/**
 * @see {@link PropertyRepository.services}
 * @type {HookRunnerResult}
 */
export const useCreditAnalysisResult = hookRunner(creditAnalysisRepoInstance, 'getResult')

/**
 * @see {@link PropertyRepository.services}
 * @type {HookRunnerResult}
 */
export const useCreditAnalysisLegacy = hookRunner(creditAnalysisRepoInstance, 'getResultLegacy')

/**
 * @see {@link PropertyRepository.services}
 * @type {HookRunnerResult}
 */
export const useGetResultFromAny = ({analysisId, provider, proposalId, propertyId}) => {
  let hook = useCreditAnalysisLegacy
  let params = [proposalId]
  if (analysisId !== undefined && provider !== undefined) {
    hook = useCreditAnalysisResult
    params.push(analysisId)
  } else {
    hook = useCreditAnalysisLegacy
    params.push(propertyId)
  }

  return hook.apply(this, params)
}
