import TextField from '@material-ui/core/TextField'
import React from 'react'

const TextAreaRender = (controllerProps, helpers, formItemProps) => {
  const {hasError, applyShrink, forwardOnChange, forwardOnBlur} = helpers
  const {value} = controllerProps
  const {onFocus} = formItemProps

  const extraStyles = formItemProps.textAreaStyle ? {...formItemProps.textAreaStyle} : {}

  return (
    <TextField
      inputRef={helpers.inputRef}
      error={hasError}
      label={formItemProps.label}
      InputLabelProps={{shrink: applyShrink}}
      rows={3}
      variant="outlined"
      style={{whiteSpace: 'pre-wrap', ...extraStyles}}
      {...formItemProps}
      multiline={true}
      onChange={forwardOnChange}
      onBlur={forwardOnBlur}
      onFocus={onFocus}
      value={value || ''}
    />
  )
}

export default TextAreaRender
