import {makeStyles} from '@material-ui/core/styles'
import css from '@root/components/Layout/cssVariables'
import {darken} from 'polished'

export const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    'margin': '0 auto',
    'display': 'flex',
    'flexDirection': 'column',
    'backgroundColor': '#FFF',
    '& ul': {
      marginBlockStart: '1rem',
      marginBlockEnd: '1rem',
      paddingInlineStart: '40px',
    },
    '& section': {
      // padding: '0 10px',
    },
    '& section > div': {
      maxWidth: '1224px',
      margin: '0 auto',
    },
    '& *': {
      // outline: '1px dashed red',
    },
  },
  experimenteButton: {
    'width': '23.5rem',
    'height': '4.375rem',
    'borderRadius': '13px',
    'font': '600 19px/1.78 Ubuntu',
    'letterSpacing': '-0.03em',
    'color': theme.palette.primary.main,
    'background': theme.extraColors.YELLOW[200],
    '&:hover': {
      background: darken(0.1, theme.extraColors.YELLOW[200]),
    },
    [theme.breakpoints.down('sm')]: {
      width: '289px',
      height: '57px',
      font: '600 15px/1.78 Ubuntu',
    },
  },
  comeceAgoraButton: {
    width: '18.8125rem',
    height: '4.375rem',
    borderRadius: '13px',
    font: '600 19px/1.78 Ubuntu',
    letterSpacing: '-0.03em',
    [theme.breakpoints.down('sm')]: {
      width: '208px',
      height: '57px',
      font: '600 15px/1.78 Ubuntu',
    },
  },
  iniciarButton: {
    'width': '17.6875rem',
    'height': '4.375rem',
    'borderRadius': '13px',
    'font': '600 19px/1.78 Ubuntu',
    'letterSpacing': '-0.03em',
    'color': '#FFF',
    'background': '#2D7FFC',
    '&:hover': {
      background: darken(0.1, '#2D7FFC'),
    },
    [theme.breakpoints.down('sm')]: {
      width: '208px',
      height: '57px',
      font: '600 15px/1.78 Ubuntu',
    },
  },

  heroSection: {
    'position': 'relative',
    'zIndex': 1,
    'width': '100%',
    'height': '715px',
    'background': `
      linear-gradient(to right, #561387 0%, #3A06CD 48%, transparent 85%),
      linear-gradient(to left, transparent 0%, transparent 56%, #3A06CD 63%),
      url(${'/assets/new/home_hero_img.png'})`,
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': '125% 90%',
    'backgroundSize': 'contain',
    'clipPath': 'polygon(100% 0, 100% 100%, 82% 93%, 0 100%, 0 0)',
    '& .heroH1Wrapper': {
      'marginTop': '80px',
      '& h1': {
        font: '400 55px/1.036 Ubuntu',
        letterSpacing: '-0.03em',
        color: '#FFF',
        padding: '0 10px',
        position: 'relative',
      },
      [theme.breakpoints.down('xs')]: {
        'margin': 0,
        '& .mobileHeroPhoto': {
          position: 'absolute',
          width: '100%',
          top: -500,
          height: '400px',
          background: `
            linear-gradient(to bottom, transparent 0%, transparent 50%, #561387 100%),
            url(${'/assets/new/home_hero_img.png'})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
        '& h1': {
          font: '600 32px/1.2 Ubuntu',
          letterSpacing: '-0.03em',
          color: '#FFF',
          textAlign: 'center',
          maxWidth: '20ch',
          margin: '500px auto 0',
          transform: 'translateY(-168px)',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        position: 'relative',
        height: '660px',
      },
      'background': `url(${'/assets/new/home_hero_img.png'})`,
      'backgroundPosition': 'center top',
      'backgroundRepeat': 'no-repeat',
      'height': '873px',
      '& h1': {
        margin: '0 auto',
      },
      'clipPath': 'polygon(100% 0, 100% 96%, 76% 94%, 0 97%, 0 0)',
    },
    [theme.breakpoints.down('xs')]: {
      // background: 'linear-gradient(to top, #0065ff 0%, #0065ff 20%, #39b3ff 50%)',
      background: '#561387',
      backgroundPosition: 'center top',
    },
  },
  heroCardsContainer: {
    'padding': '70px 10px 0',
    'transform': 'translateY(-70px)',
    'marginTop': '140px',
    'flexWrap': 'nowrap',
    'overflowY': 'visible',
    'overflowX': 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    '& > div:not(:last-child)': {
      marginRight: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      '& > div:not(:last-child)': {
        marginRight: '15px',
      },

      'position': 'absolute',
      'bottom': 0,
      'left': 0,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 320,
    },
  },

  heroCard: {
    'position': 'relative',
    'width': '275px',
    'height': '310px',
    'display': 'flex',
    'flexDirection': 'column',
    'background': '#FFF',
    'borderRadius': '30px',
    'padding': '10px 25px 5px !important',
    '& > h5, p': {fontStyle: 'italic', textAlign: 'center'},
    '& > h5': {
      fontWeight: 600,
      lineHeight: 1.21,
      fontSize: '19px',
      letterSpacing: '-0.03em',
      margin: '65px 0 20px',
    },
    '& > p': {fontSize: '16px', lineHeight: '25px', letterSpacing: '-0.03em', color: '#575757'},
    '& > button': {
      'marginTop': 'auto',
      'color': theme.palette.primary.main,
      'font': '400 18px/34px Ubuntu',
      '&:hover': {backgroundColor: 'transparent'},
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: '321px',
    },
    [theme.breakpoints.down('xs')]: {
      'padding': '0 !important',
      'minWidth': '321px',
      'maxHeight': '238px',
      '& > h5:not(:last-child)': {
        marginTop: '78px',
      },
    },
  },
  oQueEMobenSection: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    maxWidth: '1224px',
    display: 'flex',
    margin: '0 auto 185px',
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '-206px auto 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-40px auto 0',
    },
  },
  oQueEMobenSectionLeftContainer: {
    'margin': '0 !important',
    '& h3': {
      font: '400 29px/1.036 Ubuntu',
      letterSpacing: '-0.03em',
      margin: '60px 0 30px',
    },
    '& h4': {
      fontSize: '21px',
      letterSpacing: 0,
      lineHeight: 1.19,
      marginBottom: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      'textAlign': 'center',
      '& h4': {
        fontSize: '17px',
      },
    },
  },
  oQueEMobenSectionImageContainer: {
    'position': 'relative',
    '& img': {
      // position: 'absolute',
      left: -60,
      width: '248px',
      height: '523px',
      zIndex: 100,
    },
    [theme.breakpoints.down('sm')]: {
      'marginTop': '36px !important',
      '& img': {
        position: 'static',
        width: '172px',
        height: '359px',
      },
    },
  },
  vejaComoEFacilSection: {
    'position': 'relative',
    'zIndex': 1,
    'marginTop': '-120px',
    'width': '100%',
    'background': 'linear-gradient(to bottom, #fff2c7 0%, #fff 100%)',
    '& h2': {
      font: '400 45px/1.26 Ubuntu',
      letterSpacing: '-0.03em',
      textAlign: 'center',
      marginTop: '80px',
    },
    '& .videoContainer': {
      width: '100%',
      maxWidth: '604px',
      margin: '20px auto 40px',
      border: '10px solid #484848',
    },
    'clipPath': 'polygon(23% 0, 100% 9%, 100% 100%, 0 100%, 0 6%)',
    [theme.breakpoints.down('sm')]: {
      'clipPath': 'polygon(23% 4%, 100% 6%, 100% 100%, 0 100%, 0 6%)',
      'padding': '0 10px',
      'marginTop': '-70px',
      '& h2': {
        font: '400 35px/1.26 Ubuntu',
        marginTop: '152px',
        marginBottom: '32px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& h2': {
        font: '400 29px/1.26 Ubuntu',
      },
    },
  },
  vejaComoEFacilItensContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '60px',
    columnGap: '14px',
    [theme.breakpoints.down(1245)]: {
      padding: '0 10px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      rowGap: '10px',
    },
  },
  vejaComoEFacilItem: {
    'display': 'flex',
    '& h3': {
      font: '400 35px/1.11 Ubuntu',
      letterSpacing: '-0.03em',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down(1245)]: {
        whiteSpace: 'normal',
      },
    },
    '& > div:first-child': {
      marginTop: '18px',
      marginRight: '15px',
    },
    '& p': {
      fontStyle: 'italic',
      letterSpacing: '-0.03em',
      lineHeight: 1.47,
      fontSize: '17px',
      marginTop: '15px',
      maxWidth: '50ch',
    },
    '&:last-child': {
      '& p': {
        maxWidth: '55ch',
      },
    },
    [theme.breakpoints.down('sm')]: {
      'flexDirection': 'column',
      'alignItems': 'center',
      '& h3': {
        marginTop: '10px',
      },
      '& > div:first-child': {
        marginTop: 0,
        marginRight: 0,
      },
      '& p': {
        marginBottom: '20px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& h3': {
        font: '400 25px/1.11 Ubuntu',
        textAlign: 'center',
      },
      '& p': {
        textAlign: 'center',
      },
    },
  },
  vejaComoEFacilButtonContainer: {
    width: '100%',
    margin: '40px 0',
    display: 'grid',
    placeItems: 'center',
  },

  mobenEvenSection: {
    'scrollMarginTop': '99px',
    'position': 'relative',
    'zIndex': '2',
    'padding': '70px 0 0',
    '& .mobenEContratoBg, .mobenEContratoBgFake': {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: '#F4F4F4',
      clipPath: 'polygon(0 5%, 37% 0, 100% 8%, 100% 100%, 60% 89%, 0 100%)',
    },
    '& .mobenEContratoBgFake': {
      background: '#FFF',
    },
    [theme.breakpoints.down('sm')]: {
      '& .mobenEContratoBg': {
        clipPath: 'polygon(0 3%, 38% 0, 100% 4%, 100% 100%, 60% 96%, 0 100%)',
      },
    },
  },
  mobenEvenContent: {
    display: 'flex',
    position: 'relative',
    zIndex: 2,
    margin: '-16px 0 0',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  mobenOddSection: {
    scrollMarginTop: '99px',
    position: 'relative',
    zIndex: '3',
    padding: 0,
  },
  mobenOddContent: {
    display: 'flex',
    position: 'relative',
    zIndex: 2,
    margin: '30px 0 -30px',
    [theme.breakpoints.down('sm')]: {
      'flexWrap': 'wrap',
      '& > :nth-child(1)': {order: 2},
      '& > :nth-child(2)': {order: 1},
    },
  },

  contentContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    'width': '100%',
    'minWidth': '780px',
    '& > h2': {
      font: '400 55px/1.03 Ubuntu',
      letterSpacing: '-0.03em',
      color: theme.extraColors.HOME_BLUE,
    },
    '& > div': {
      'display': 'flex',
      '& div:first-child': {
        marginTop: '20px',
      },
      '& > div': {
        'display': 'flex',
        'flexDirection': 'column',
        '& li p': {
          fontSize: '21px',
          lineHeight: 1.76,
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '& > h2': {
        textAlign: 'center',
      },
      '& > div': {
        'justifyContent': 'center',
        '& > div': {
          '& > button': {
            marginLeft: '24px',
          },
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& > h2': {
        font: '400 32px/1.03 Ubuntu',
        marginTop: '20px',
      },
      '& > div': {
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'center',
        '& div:first-child': {
          marginTop: '20px',
        },
        '& > div': {
          '& li p': {
            fontSize: '16px !important',
          },
          '& > button': {
            margin: '10px auto',
          },
        },
      },
    },
  },
  imageContainer: {
    'position': 'relative',
    'width': '620px',
    'height': '480px',

    '& img': {
      position: 'absolute',
      zIndex: 3,
      top: -20,
      left: -30,
      width: '100%',
      // boxShadow: '0px 37px 35px -39px #888888',
    },
    [theme.breakpoints.down('sm')]: {
      'width': '320px',
      'margin': '20px auto 46px',
      '& img': {
        position: 'static',
        zIndex: 3,
        height: '100%',
      },
    },
  },

  quantoCustaSection: {
    'scrollMarginTop': '99px',
    'position': 'relative',
    'backgroundColor': 'hsl(0, 60%, 10%)',
    'backgroundImage': `url(${'/assets/new/quanto_custa_bg_image.png'})`,
    'backgroundBlendMode': 'hard-light',
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': 'center 40%',
    'marginTop': -60,
    'padding': '90px 0 40px',
    '& .quantoCustaOverlay': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.06,
      background: `${theme.palette.primary.main}`,
    },
  },
  quantoCustaContentContainer: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'textAlign': 'center',
    'padding': '20px 20px 0px',
    '& > div': {flex: 1, minWidth: '500px'},
    '& h3': {
      font: '400 29px/1.96 Ubuntu',
      letterSpacing: '-0.03em',
      color: '#FFF',
    },
    '& h2': {
      font: '600 89px/1.11 Ubuntu',
      letterSpacing: '-0.05em',
    },
    '& h4': {
      fontSize: '21px',
      lineHeight: 1.19,
      maxWidth: '45ch',
      margin: '10px auto 30px',
      color: '#FFF',
    },
    [theme.breakpoints.down('xs')]: {
      '& > div': {
        minWidth: '320px',
      },
    },
  },
  quantoCustaLeftContainer: {
    '& .priceContainer': {
      marginBottom: '20px',
      color: '#FFF',
    },
  },
  quantoCustaRightContainer: {
    'textAlign': 'left',
    'marginLeft': '110px',
    '& h3': {
      marginBottom: '8px',
      color: '#FFF',
    },
    '& p': {
      'fontSize': '19px',
      'lineHeight': 1.57,
      'color': '#FFF',
      '& > img': {
        width: '22px',
        marginRight: '2px',
      },
      '&:not(:last-child)': {
        marginBottom: '2px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      'padding': '0 15px',
      'marginLeft': 0,
      'marginTop': '20px',
      '& h3': {
        textAlign: 'center',
      },
    },
  },
  quemSomosSection: {
    'scrollMarginTop': '99px',
    'marginBottom': '90px',
    '& h2': {
      font: '400 29px/1.96 Ubuntu',
      textAlign: 'center',
      margin: '10px 0 60px',
      color: theme.extraColors.HOME_BLUE,
    },
    [theme.breakpoints.down('sm')]: {
      '& h2': {
        margin: '10px 0 20px',
      },
    },
  },
  // quemSomosItemContainer: {
  //   display: 'grid',
  //   gridTemplateColumns: 'repeat(auto-fit, 150px)',
  //   justifyContent: 'center',
  //   gap: '15px',
  //   padding: '0 10px',
  //   [theme.breakpoints.down('sm')]: {
  //     gridTemplateColumns: '1fr 1fr'
  //   }
  // },
  quemSomesMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  quemSomosText: {flex: 6, padding: '0 20px 20px'},
  quemSomosPhotos: {flex: 6},
  quemSomosItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
  },
  // quemSomosItemContainerFlex: {
  //   display: 'flex',
  //   justifyContent: 'space-evenly',
  //   alignItems: 'center',
  //   flexWrap: 'wrap',
  //   [theme.breakpoints.down('xs')]: {
  //     justifyContent: 'center',
  //   },
  // },
  quemSomosItem: {
    'width': '150px',
    'marginBottom': '20px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    // 'width': '176px',
    // 'height': '176px',
    '& img': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      borderRadius: '50%',
      filter: 'grayscale(100%)',
    },
    '& p': {fontSize: '16px', textAlign: 'center', marginTop: '15px'},
    // '&:not(:last-child)': {
    //   marginRight: '20px',
    // },
  },
}))
