import {makeStyles} from '@material-ui/core/styles'

const useTypographyStyle = makeStyles((theme) => {
  const sizeStyle = (s) => ({
    fontSize: s ?? undefined,
  })
  const colorStyle = (c) => ({
    color: c ?? undefined,
  })
  const styles = {
    bold: {
      fontWeight: 600,
    },
    italic: {
      fontStyle: 'italic',
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    title: {
      color: theme.palette.secondary,
    },
  }

  return {
    ...styles,
    merged: (stylesToMerge) => {
      const availableStyles = ['style', 'bold', 'italic', 'primary', 'secondary']
      let merged = availableStyles
        .filter((prop) => !!stylesToMerge[prop])
        .reduce((prevStyle, name) => {
          const style = styles[name]
          return {...prevStyle, ...style}
        }, {})

      if (stylesToMerge.size) {
        merged = {...merged, ...sizeStyle(stylesToMerge.size)}
      }

      if (stylesToMerge.color) {
        merged = {...merged, ...colorStyle(stylesToMerge.color)}
      }
      return merged
    },
  }
})

export default useTypographyStyle
