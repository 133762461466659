import Typography from '@material-ui/core/Typography'
import {Box} from '@root/components/Layout/Container/Box/Box'
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import PropTypes from 'prop-types'
import React from 'react'
import {getBillsIncludedInRent} from '@root/utils/rentfeesCalc'

const RentFeesTotalDisplay = ({rentfees}) => {
  if (!rentfees) {
    return null
  }
  const includes = getBillsIncludedInRent(rentfees).join(' + ')
  return (
    <>
      <Box bgType="gray">
        <div className="totalValueWrapper">
          <Typography variant="h6" align="center">
            Valor total mensal
          </Typography>
          <Typography variant="h5" color="secondary" align="center">
            <NumberFormatCustom value={rentfees.total} displayType="text" />
          </Typography>
        </div>
      </Box>
      <Typography variant="subtitle1" align="center" className="totalCaption">
        {includes}
      </Typography>
    </>
  )
}
RentFeesTotalDisplay.propTypes = {
  rentfees: PropTypes.object.isRequired,
}
export default RentFeesTotalDisplay
