export const ContractTypes = {
  SET_CONTRACT_FROM_DATABASE: 'SET_CONTRACT_FROM_DATABASE',
  SET_CONTRACT_PROPERTY: 'SET_CONTRACT_PROPERTY',
  SET_CONTRACT_VALIDITY: 'SET_CONTRACT_VALIDITY',
  SET_CONTRACT_RENTDUE: 'SET_CONTRACT_RENTDUE',
  SET_CONTRACT_RENTFEES: 'SET_CONTRACT_RENTFEES',
  SET_CONTRACT_YOURINFO: 'SET_CONTRACT_YOURINFO',
  SET_CONTRACT_PENALTIES: 'SET_CONTRACT_PENALTIES',
  SET_CONTRACT_GUARANTEES: 'SET_CONTRACT_GUARANTEES',
  SET_CONTRACT_READJUSTMENT_INDEX: 'SET_CONTRACT_READJUSTMENT_INDEX',
  SET_CONTRACT_FORUM: 'SET_CONTRACT_FORUM',
  SET_CONTRACT_TENANT: 'SET_CONTRACT_TENANT',
  SET_CONTRACT_TENANT_FROM_DATABASE: 'SET_CONTRACT_TENANT_FROM_DATABASE',
  SET_CONTRACT_FIRE_INSURANCE: 'SET_CONTRACT_FIRE_INSURANCE',
  SET_CONTRACT_STEP: 'SET_CONTRACT_STEP',
  SET_CONTRACT_SURVEY: 'SET_CONTRACT_SURVEY',
  SET_CONTRACT_WITNESSES: 'SET_CONTRACT_WITNESSES',
  ADD_CONTRACT_OBSERVATION: 'ADD_CONTRACT_OBSERVATION',
  SET_CONTRACT_SNAP: 'SET_CONTRACT_SNAP',
  RESET_CONTRACT: 'RESET_CONTRACT',
}
