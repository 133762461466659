export const mapCreditStatusToColor = (analysisNum) => {
  switch (analysisNum) {
    case 1:
      return '#087E04'
    case 3:
      return '#F8F80A'

    case 4:
      return '#F8120A'

    default:
      return '#707070'
  }
}
export const getBorderStyle = (itemValue) => ({
  border: `2px solid ${mapCreditStatusToColor(itemValue)}`,
  borderRadius: '8px',
})
