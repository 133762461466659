import React, {useState} from 'react'

const EditableCell = ({value: initialValue, index, cells, id, updateData, placeholder, textArea}) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    updateData(index, cells, id, value)
  }

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (textArea) {
    return (
      <textarea
        style={{fontSize: '1rem', color: '#262626', border: '1px solid #AAA', resize: 'none'}}
        rows="4"
        cols="30"
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
    )
  }

  return (
    <input
      style={{border: 'none', borderBottom: '1px solid #AAA', fontSize: '1rem', color: '#262626', width: 110}}
      defaultValue={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  )
}

export default EditableCell
