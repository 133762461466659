import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  receipts: {
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleContainer: {
    marginTop: '100px',
    textAlign: 'center',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
    },
  },
  paper: {
    margin: '30px 0',
    border: '1px solid #707070',
    borderRadius: '12px',
    boxSizing: 'border-box',
    padding: '20px',
    width: 'min(100%, 800px)',
    display: 'flex',
    flexDirection: 'column',
  },
  typeDateReceiptWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  descriptionWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > p': {
      fontWeight: 600,
      marginBottom: '8px',
    },
    '& > textarea': {
      boxSizing: 'border-box',
      width: '100%',
      height: '120px',
      outline: 0,
      padding: '10px',
      borderRadius: '8px',
      border: '1px solid #707070',
    },
  },
  confirmButtonText: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
}))

export default useStyles
