import {Button as MUButton} from '@material-ui/core'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import PropTypes from 'prop-types'
import React from 'react'

import {useStyles} from './styles'

export function Button({
  children,
  bgType = 'purple',
  noBg = false,
  withBorder = false,
  icon,
  disabled,
  m,
  w,
  h,
  p,
  fz,
  fw,
  lh,
  className,
  ...rest
}) {
  const stylesProps = {noBg, withBorder, m, w, h, p, fz, fw, lh}

  const classes = useStyles(stylesProps)

  const Icon = icon

  const typeMap = {
    purple: 'buttonPurple',
    green: 'buttonGreen',
    yellow: 'buttonYellow',
    red: 'buttonRed',
    orange: 'buttonOrange',
  }

  return (
    <MUButton
      startIcon={Icon && <Icon />}
      variant="contained"
      disabled={disabled}
      className={`${classes.root} ${classes[typeMap[bgType]]} ${className ?? ''}`}
      {...rest}>
      {children}
    </MUButton>
  )
}

export function ButtonProximoItem({onClick, buttonProps, label, ...rest}) {
  return (
    <Button size="large" bgType="green" icon={CheckOutlinedIcon} onClick={onClick} {...buttonProps}>
      {label}
    </Button>
  )
}
ButtonProximoItem.defaultProps = {
  label: 'Próximo item',
}

export function ButtonConcluir({onClick, ...rest}) {
  const handleOnClick = () => onClick()

  return (
    <Button size="large" bgType="green" icon={CheckOutlinedIcon} onClick={handleOnClick} {...rest}>
      Concluir
    </Button>
  )
}

const styleValuesTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.number])
Button.propTypes = {
  bgType: PropTypes.oneOf(['purple', 'green', 'yellow', 'red']),
  noBg: PropTypes.bool,
  withBorder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  m: styleValuesTypes, // margin
  w: styleValuesTypes, // width
  h: styleValuesTypes, // height,
  p: styleValuesTypes, // padding,
  fz: styleValuesTypes, // font size,
  fw: styleValuesTypes, // font weight,
  lh: styleValuesTypes, // line height,
}
