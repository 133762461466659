import AddButton from '@root/components/App/Admin/ContractEditor/AddButton'
import {contractIteratorProps} from '@root/components/App/Admin/ContractEditor/contractPropTypes.ts'
import ItemEntry from '@root/components/App/Admin/ContractEditor/ItemEntry'
import actionDispatcher from '@root/lib/actionDispatcher'
import React from 'react'
import {addContractManagerAdddEntry} from '@root/redux/adminContractManager/adminContractManager.actions'

const Iterator = ({items, level, path, enableAdd, enableDelete}) => {
  return (
    <>
      {items.map((item, idx) => (
        <>
          <ItemEntry
            key={idx}
            item={item}
            level={level}
            path={[...path, idx]}
            enableAdd={enableAdd}
            enableDelete={enableDelete}
          />
          {item.has_sub && (
            <>
              <Iterator
                key={idx}
                items={item.items}
                level={level + 1}
                path={[...path, idx]}
                enableAdd={enableAdd}
                enableDelete={enableDelete}
              />
            </>
          )}
        </>
      ))}
      <div style={{paddingLeft: level * 24}}>{enableAdd && <AddButton path={[...path]} />}</div>
    </>
  )
}

Iterator.propTypes = contractIteratorProps

export default Iterator
