import {paymentRepoInstance} from '@root/application/service/repository/payment/PaymentRepository'
import {usePaymentsList} from '@root/application/service/repository/payment/PaymentRepository'
import {propertyRepoInstance} from '@root/application/service/repository/property/PropertyRepository'
import useModalControl from '@root/components/Modal/useModalControl'
import actionDispatcher from '@root/lib/actionDispatcher'
import {dateSortAsc} from '@root/lib/dateSort'
import {formatBrCurrencyToFloat, formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import propertyModel from '@root/model/propertyModel'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {isSameDay} from 'date-fns'
import dateParser, {getYear} from 'date-parser'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'

function usePaymentsHelper(propertyId) {
  const history = useHistory()

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  const [selectedPayment, setSelectedPayment] = useState(null)

  const detailControl = useModalControl()
  const editControl = useModalControl()

  const fetchPaymentsHook = usePaymentsList(propertyId)
  const payments = fetchPaymentsHook.response

  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const editPayment = async (propertyId, userId, form) => {
    actions.setGlobalLoaderAction(true)
    const {new_amount, extra_negotiation, new_due_date, apply_fine, description, is_silent} = form

    const dueDate = dateParser(new_due_date)
    const selectedDueDate = dateParser(selectedPayment.due_date)

    const amount = formatBrCurrencyToFloat(new_amount)
    const selectedAmount = selectedPayment.amount

    let updatedObj = {
      id: selectedPayment.id,
      apply_taxes: apply_fine === 'yes',
      description,
    }

    if (!isSameDay(dueDate, selectedDueDate)) {
      updatedObj.due_date = dueDate.toISOString()
    }

    if (amount !== selectedAmount) {
      updatedObj.amount = amount
    }

    if (is_silent?.accepted) {
      updatedObj.is_silent = 1
    }

    await paymentRepoInstance.changePayment(propertyId, userId, updatedObj)
    await fetchPaymentsHook.refresh()
    actions.setGlobalLoaderAction(false)
    editControl.close()
  }

  const markAsPaid = async (payment, propertyId) => {
    const confirm = window.confirm(
      'Deseja realmente marcar este item como PAGO? Ao marcar como PAGO você declara que os valores recebidos por você estão corretos.',
    )

    if (!confirm) {
      return
    }

    actions.setGlobalLoaderAction(true)
    await propertyRepoInstance.markAsPaid(propertyId, payment.id)
    await fetchPaymentsHook.refresh()
    actions.setGlobalLoaderAction(false)
  }

  const paymentsPerYearPaginator = useMemo(() => {
    // console.log('run paymentsPerYearPaginator')
    const list = payments.reduce((obj, payment) => {
      const year = getYear(dateParser(payment.due_date))
      if (!Object.prototype.hasOwnProperty.call(obj, year)) {
        obj[year] = []
      }
      obj[year].push(payment)
      return obj
    }, {})

    for (const y in list) {
      list[y].sort((a, b) => dateSortAsc(a.due_date, b.due_date))
    }

    return list
  }, [payments])

  return {
    fetchPaymentsHook,
    payments,
    isLoading: fetchPaymentsHook.isLoading,
    //
    selectedYear,
    setSelectedYear,
    paymentsPerYear: paymentsPerYearPaginator,
    selectedPayment,
    setSelectedPayment,
    detailControl,
    editControl,
    //
    editPayment,
    markAsPaid,
  }
}

export default usePaymentsHelper
