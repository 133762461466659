import {formatBrCurrencyToFloat} from '@root/lib/moneyFormater'
import * as Sentry from '@sentry/react'

export const getTotalRent = (rentfees) => {
  try {
    let totalRent = formatBrCurrencyToFloat(rentfees.rent)
    if (rentfees.include_condominium && rentfees.condominium) {
      totalRent += formatBrCurrencyToFloat(rentfees.condominium)
    }
    if (rentfees.include_iptu && rentfees.iptu_annual) {
      totalRent += formatBrCurrencyToFloat(rentfees.iptu_annual)
    }
    if (rentfees.include_fire_insurance && rentfees.fire_insurance_annual) {
      totalRent += formatBrCurrencyToFloat(rentfees.fire_insurance_annual)
    }
    return totalRent
  } catch (ex) {
    Sentry.captureException(ex)
    return 0
  }
}

export const getFeesWithoutRent = (rentfees) => {
  try {
    let totalRent = 0
    if (rentfees.include_condominium && rentfees.condominium) {
      totalRent += formatBrCurrencyToFloat(rentfees.condominium)
    }
    if (rentfees.include_iptu && rentfees.iptu_annual) {
      totalRent += formatBrCurrencyToFloat(rentfees.iptu_annual)
    }
    if (rentfees.include_fire_insurance && rentfees.fire_insurance_annual) {
      totalRent += formatBrCurrencyToFloat(rentfees.fire_insurance_annual)
    }
    return totalRent
  } catch (ex) {
    Sentry.captureException(ex)
    return 0
  }
}

/**
 * Por alguns motivos inexplicáveis existe 2 totais um dentro do contrato e outro fora, na raiz do imovel.
 * Essa funcao busca primeiro no Contrato e depois na Raiz
 * @param property
 * @return {number}
 */
export const getTotalRentFromPropertyOrRentfees = (property) => {
  let total = 0
  if (property?.contract?.rentfees?.total) {
    total = property.contract.rentfees.total
  } else {
    total = property.total_amount
  }

  return total
}

/**
 * @param {object} [rentfees]
 * @param {boolean} [includeMainRent=true]
 * @return {string[]}
 */
export const getBillsIncludedInRent = (rentfees, includeMainRent = true) => {
  const fees = rentfees ?? {}
  const includes = []

  if (includeMainRent) {
    includes.push('Aluguel')
  }

  if (fees.include_condominium) {
    includes.push('Condomínio')
  }
  if (fees.include_iptu) {
    includes.push('IPTU')
  }
  if (fees.include_fire_insurance) {
    includes.push('Seguro incêndio')
  }

  return includes
}

/**
 * @param {object} [rentfees]
 * @param {boolean} [includeMainRent=true]
 * @return {{label:string,amount:number}[]}
 */
export const getBillsIncludedInRentWithAmount = (rentfees, includeMainRent = true, includeTotal = false) => {
  const fees = rentfees ?? {}
  const includes = []

  if (includeMainRent) {
    includes.push({
      label: 'Aluguel',
      amount: rentfees.rent,
    })
  }

  if (fees.include_condominium) {
    includes.push({
      label: 'Condomínio',
      amount: rentfees.condominium,
    })
  }
  if (fees.include_iptu) {
    includes.push({
      label: 'IPTU',
      amount: rentfees.iptu_annual,
    })
  }

  if (includeTotal) {
    includes.push({
      label: 'Total',
      amount: rentfees.total,
    })
  }
  return includes
}
