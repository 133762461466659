import PropTypes from 'prop-types'

export const getDefaultProps = () => ({
  allowFullEdit: false,
  incomeText: undefined,
  displayUploads: true,
  displayIncomeField: true,
  requireIdentityDoc: true,
  requireIncomeDoc: false,
})

export const getPropTypes = () => ({
  allowFullEdit: PropTypes.bool,
  incomeText: PropTypes.string,
  displayUploads: PropTypes.bool,
  displayIncomeField: PropTypes.bool,
  requireIdentityDoc: PropTypes.bool,
  requireIncomeDoc: PropTypes.bool,
})
