import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import {validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const GuaranteeTypeSeguroFianca = ({currentGuarantee}) => {
  const {control, errors} = useFormContext()
  let extra_clauses_value

  if (currentGuarantee?.data?.extra_clauses === undefined) {
    extra_clauses_value = ''
  } else {
    extra_clauses_value = currentGuarantee.data.extra_clauses
  }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant="body1" style={{fontWeight: 600, textAlign: 'center'}}>
          Quem será o responsável por contratar o seguro?
        </Typography>
        <FormItem
          control={control}
          errors={errors}
          type="radio"
          name="responsible"
          options={[
            {value: 'owner', label: 'Eu mesmo'},
            {value: 'tenant', label: 'Inquilino'},
          ]}
          rules={{
            validate: validateRequired,
          }}
          extraStyles="row"
        />
      </div>
      <div style={{marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant="body1" style={{fontWeight: 600, marginBottom: 10, textAlign: 'center'}}>
          Indique se a seguradora exige cláusulas extras no contrato:
        </Typography>
        <FormItem
          control={control}
          errors={errors}
          type="textarea"
          name="extra_clauses"
          defaultValue={extra_clauses_value}
          fitContainer={true}
          rows={8}
        />
      </div>
    </>
  )
}

export default GuaranteeTypeSeguroFianca
