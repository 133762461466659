import {makeStyles} from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import React from 'react'

function getPadding(stylesProps) {
  if (stylesProps.p) return stylesProps.p

  if (stylesProps.isBoxTypeSimple && stylesProps.smallPadding) return '8px'

  if (!stylesProps.isBoxTypeSimple && stylesProps.smallPadding) return '8px 8px 8px 46px'

  if (stylesProps.isBoxTypeSimple) return '12px 8px'

  return '16px 16px 16px 46px'
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: (stylesProps) => (stylesProps.smallPadding ? 'center' : null),
    alignItems: (stylesProps) => (stylesProps.ai ? stylesProps.ai : null),
    width: (stylesProps) => (stylesProps.w ? stylesProps.w : '100%'),
    maxWidth: (stylesProps) => (stylesProps.mWidth ? stylesProps.mWidth : ''),
    height: (stylesProps) => (stylesProps.h ? stylesProps.h : ''),
    // marginBottom: (stylesProps) => (stylesProps.mb ? '20px' : 0),
    margin: (stylesProps) => (stylesProps.m ? stylesProps.m : ''),
    padding: (stylesProps) => getPadding(stylesProps),
    border: (stylesProps) => {
      let borderValue = `2px solid ${theme.extraColors.GRAY[100]}`
      if (stylesProps.b !== undefined) {
        borderValue = stylesProps.b
      }
      return borderValue
    },
    borderRadius: (stylesProps) => (stylesProps.br !== undefined ? stylesProps.br : theme.borderRadius.big),
    background: (stylesProps) => (stylesProps.isBoxBgColorWhite ? '#FFFFFF' : theme.extraColors.GRAY[100]),
  },
  checkItem: {
    'width': '30px',
    // 'height': '100%',
    'position': 'absolute',
    'top': -1,
    'left': -1,
    'bottom': -1,
    'display': 'grid',
    'placeContent': 'center',
    'border': (stylesProps) =>
      stylesProps.isChecked ? `3px solid ${theme.extraColors.GREEN}` : `3px solid ${theme.extraColors.GRAY[300]}`,
    'borderTopLeftRadius': 10,
    'borderBottomLeftRadius': 10,
    'background': (stylesProps) => (stylesProps.isChecked ? theme.extraColors.GREEN : theme.extraColors.GRAY[300]),
    '& svg': {
      fill: '#FFFF',
    },
  },
}))

export function Box({
  type = 'simple',
  isChecked,
  bgType = 'white',
  children,
  smallPadding,
  ai,
  mb,
  m,
  p,
  w,
  mWidth,
  h,
  br,
  b,
  className,
  ...rest
}) {
  const isBoxTypeSimple = type === 'simple'
  const isBoxBgColorWhite = bgType === 'white'

  const stylesProps = {
    isBoxTypeSimple,
    isBoxBgColorWhite,
    isChecked,
    smallPadding,
    mb,
    w,
    mWidth,
    h,
    m,
    p,
    ai,
    br,
    b,
  }

  const classes = useStyles(stylesProps)
  const rootClass = `${classes.root} ${className ?? ''} ${rest.onClick ? 'clickable' : ''}`
  if (!isBoxTypeSimple) {
    return (
      <div className={rootClass} {...rest}>
        <div className={classes.checkItem}>{isChecked && <CheckIcon fontSize="large" />}</div>
        {children}
      </div>
    )
  }

  return (
    <div className={rootClass} {...rest}>
      {children}
    </div>
  )
}

Box.propTypes = {
  type: PropTypes.oneOf(['simple', 'withCheckBox']),
  isChecked: PropTypes.bool,
  smallPadding: PropTypes.bool,
  ai: PropTypes.string, // align items
  mb: PropTypes.bool,
  m: PropTypes.string,
  p: PropTypes.string,
  bgType: PropTypes.oneOf(['white', 'gray']),
  children: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  mWidth: PropTypes.string,
}
