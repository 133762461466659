/** @module userModel */
import 'firebase/firestore'

import * as Sentry from '@sentry/react'
import fetcher, {objectToQueryString} from '@root/application/infra/api/fetcher'
import {format} from 'date-fns'
import firebase from 'firebase/app'
import {firestore} from '@root/firebase/firebase.utils'
import produce from 'immer'
import {dateParser} from '@root/lib/dateParser'
import {firebaseBase64Uploader} from '@root/lib/firebaseUploader'
import intVal from '@root/lib/intVal'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {simplePhoneFormatter} from '@root/lib/simplePhoneFormatter'
import {createEmptyTenant} from '@root/redux/tenantRegister/tenantRegister.reducer'

const COLLECTION_NAME = 'notification_viewer'

const getCollection = (extraPath = '') => firestore.collection(COLLECTION_NAME + extraPath)

/**
 * @param {string} userId
 * @param {string} paymentType
 * @param {string} [cardId]
 * @param {Array<string>} propertyIds
 * @return {Promise<any>}
 */
const fetchMailList = (queryOptions) => fetcher.get('admin/mail-track?' + objectToQueryString(queryOptions))

const userModel = {
  COLLECTION_NAME,
  getCollection,
  fetchMailList,
}

export default userModel
