/* eslint-disable react/display-name */
import {Snackbar} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import MobileStepper from '@material-ui/core/MobileStepper'
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons'
import DescriptionIcon from '@material-ui/icons/Description'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PanToolIcon from '@material-ui/icons/PanTool'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import Alert from '@material-ui/lab/Alert'
import * as Sentry from '@sentry/react'
import ContractHistoryList from '@root/components/App/Admin/ContractEditor/ContractHistoryList'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import BlockAnnotation from '@root/components/App/Admin/Property/blocks/BlockAnnotation'
import BlockFiles from '@root/components/App/Admin/Property/blocks/BlockFiles'
import BlockFinishContract from '@root/components/App/Admin/Property/blocks/BlockFinishContract'
import BlockInsurance from '@root/components/App/Admin/Property/blocks/BlockInsurance'
import BlockPayment from '@root/components/App/Admin/Property/blocks/BlockPayment'
import ProposalEntry from '@root/components/App/Admin/Property/ProposalEntry'
import useStyles from '@root/components/App/Admin/styles'
import UserBasicData from '@root/components/App/Admin/UserBasicData'
import {findGuaranteeByType} from '@root/components/App/Contract/guaranteesList'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FilePicker from '@root/components/FilePicker'
import IconSecurity from '@root/components/icons/IconSecurity'
import IconStop from '@root/components/icons/IconStop'
import IconTrashCan from '@root/components/icons/IconTrashCan'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {INVITE_TYPE_TENANT} from '@root/components/InviteRegister/Invite'
import Loader from '@root/components/Loader/Loader'
import BaseModal from '@root/components/Modal/BaseModal'
import {firestore} from '@root/firebase/firebase.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import mapsLink from '@root/lib/admin/mapsLink'
import contractStepsMap, {stepFireInsurance} from '@root/lib/contract/contractStepsMap'
import {convertDateToBr} from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import {translate} from '@root/lib/intl/translations'
import {generateInvite} from '@root/lib/inviteLinkCreator'
import {formatFloatToBrCurrency, fromCents} from '@root/lib/moneyFormater'
import {feesCondominiumOptions, feesFireInsuranceOptions, feesIptuOptions} from '@root/lib/predefinedValuesOptions'
import proposalLoader from '@root/lib/proposalLoader'
import {validateNumber} from '@root/lib/simpleValidators'
import {readableValue} from '@root/lib/simpleValuesDisplayMap'
import storage from '@root/lib/storage'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import surveyModel from '@root/model/surveyModel'
import userModel from '@root/model/userModel'
import React, {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {adminSetOwnerAction, adminSetPropertyAction} from '@root/redux/admin/admin.actions'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {paymentMethodMapper} from 'status-mapper'
import contractMapper from 'status-mapper/mapper/contract-mapper'
import {BASE_URL, STORAGE_PROPERTY_ID} from '@root/utils/constants'
import routes from '@root/utils/routes'

const MAX_DESCRIPTION_LENGTH = 255

let updateCounter = 0
const PropertyDetail = ({match}) => {
  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setNavbarTitleAction,
    adminSetPropertyAction,
    adminSetOwnerAction,
  })
  const stepsCopy = [...contractStepsMap]
  stepsCopy.splice(7, 0, stepFireInsurance)

  const contractStepsForms = stepsCopy.filter((step) => step.display_on_panel)
  const contractStepsLen = contractStepsForms.length
  const contractMaxStep = contractStepsLen - 1
  const contractMinStep = 0
  const classes = useStyles()

  const formHook = useForm()

  const {setValue} = formHook

  const propertyId = match.params.propertyId

  const downloadContractRef = useRef()
  const adInputLinkRef = useRef()

  const property = useSelector((s) => s.admin.property)

  const payments = useSelector((s) => s.admin.property?.contract?.payments)
  const paymentMethod = useSelector((s) => s.admin.property?.contract?.payment_method?.option)
  const rentfees = useSelector((s) => s.admin.property?.contract?.rentfees)

  const [charCount, setCharCount] = useState(0)

  const owner = useSelector((s) => s.admin.owner)
  const realOwner = useSelector((s) => s.admin.real_owner)

  const [contractStepIndex, setStepIndex] = useState(0)
  const [propertySnap, setPropertySnap] = useState()
  const [proposals, setProposals] = useState({})
  const [shareLink, setShareLink] = useState(false)
  const [propertyWasUpdated, setPropertyUpdated] = useState(false)
  const [selectedProposalUid, setSelectedProposalUid] = useState({})
  const [proposalSelected, setProposalSelected] = useState()
  const [boletoGerado, setBoletoGerado] = useState(false)
  const [isInsuranceVisible, setInsuranceVisibility] = useState(false)
  const [isFinishContractVisible, setFinishContractVisibility] = useState(false)

  const surveyState = useState()
  const surveyImageState = useState()

  const [refreshScreen, forceRefreshScreen] = useState({})
  const [isContractHistoryVisible, setContractHistoryVisibility] = useState(false)

  const contractStep = contractStepsForms[contractStepIndex]
  const contractHistory = property?.contract_editor_history ?? []

  const paymentMethodTranslate = paymentMethodMapper.translate(paymentMethod)

  const limitTextLen = (ev) => {
    const text = ev.target.value
    let textLen = text.length
    if (textLen > MAX_DESCRIPTION_LENGTH) {
      setValue('description', text.substring(0, MAX_DESCRIPTION_LENGTH))
      textLen = MAX_DESCRIPTION_LENGTH
    }
    setCharCount(textLen)
  }

  const updatePropertyData = async (raw, value, {path}) => {
    await firestore.collection(propertyModel.COLLECTION_NAME).doc(property.uid).update(path, value)
  }

  const downloadContract = (name, mime, content) => {
    const element = downloadContractRef.current
    const blobData = new Blob([content], {type: mime})
    const blobUrl = URL.createObjectURL(blobData)
    element.href = blobUrl
    element.download = name
    element.click()

    URL.revokeObjectURL(blobUrl)
  }

  const getInviteLink = (inviteType, proposalId = '') => {
    const generatedInviteLink = generateInvite(inviteType, property.uid, proposalId, true)

    if (generatedInviteLink) {
      navigator.clipboard
        .writeText(generatedInviteLink)
        .then(() => {
          setShareLink(generatedInviteLink)
        })
        .catch((err) => console.log(err))
    }
  }

  const closeSnackCopy = () => setShareLink(false)

  const closeSnackPropertyUpdate = () => setPropertyUpdated(false)

  const handleNextStep = () => {
    if (contractStepIndex < contractMaxStep) {
      setStepIndex(contractStepIndex + 1)
    }
  }

  const handlePrevStep = () => {
    if (contractStepIndex > contractMinStep) {
      setStepIndex(contractStepIndex - 1)
    }
  }

  const copyInviteLink = () => {
    getInviteLink(INVITE_TYPE_TENANT)
  }

  const handleDocSignedChange = async (file) => {
    actions.setGlobalLoaderAction(true)
    try {
      const uploadedFile = await firebaseUploader(file, property.uid, `property/${property.uid}/contract/signed`)
      await propertySnap.ref.update('contract.contract_signed', uploadedFile)
    } catch (ex) {
      Sentry.captureException(ex)
      alert(`${ex.message}\n\n${ex.stack}`)
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const setActiveFlagsOnUsers = async () => {
    const proposal = property.proposals[property.selected_proposal.uid]
    let propToUpdate
    const promises = proposal.interesteds.map((interested) => {
      propToUpdate = interested.type === USER_TYPE_TENANT ? 'is_active_tenant' : 'is_active_fiador'
      return interested.user.update(propToUpdate, true)
    })

    promises.push(userModel.updateUser({is_active_owner: true}, owner.uid))

    return Promise.all(promises)
  }

  const handleIniciarContratoOnClick = async () => {
    try {
      actions.setGlobalLoaderAction(true)

      await propertyModel.fetchCreatePaymentsList(property.uid, owner.uid)
      await firestore
        .collection(propertyModel.COLLECTION_NAME)
        .doc(property.uid)
        .update('contract.started_at', new Date())
      await setActiveFlagsOnUsers()

      setBoletoGerado(true)
      actions.setGlobalLoaderAction(false)
      alert(
        'Lista de pagamentos criada e status do contrato atualizado para ' +
          contractMapper.translate(contractMapper.status.INPROGRESS),
      )
    } catch (ex) {
      errorHandler(ex)
      actions.setGlobalLoaderAction(false)

      alert(errorHandler(ex, 'Não foi possível gerar os pagamentos nesse momento.'))
    }
  }

  const handleEncerrarContrato = async () => showFinishContractModal()

  const handleIniciarAssinatura = async () => {
    const hasValidCard = owner.cards.some((card) => card.valid)
    if (!hasValidCard) {
      alert(`Proprietário ${owner.name} não possui cartão de crédito válido`)
      return
    }

    if (window.confirm('Deseja realmente iniciar uma assinatura?')) {
      if (!window.confirm('Tem certeza?')) {
        return
      }
    } else {
      return
    }

    if (owner.has_subscription) {
      const createAnotherConfirm = window.confirm(
        `Proprietário ${owner.name} já tem uma assinatura em andamento, tem certeza que quer criar outra?`,
      )
      if (!createAnotherConfirm) {
        return
      }
    }

    try {
      actions.setGlobalLoaderAction(true)
      await userModel.fetchCreateSubscription(owner.uid, property.uid)
      alert('Assinatura criada com sucesso')
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const handleAddInsurance = async (form) => {}

  const showInsuranceModal = () => setInsuranceVisibility(true)
  const hideInsuranceModal = () => setInsuranceVisibility(false)

  const showFinishContractModal = () => setFinishContractVisibility(true)
  const hideFinishContractModal = () => setFinishContractVisibility(false)

  const requestDateUpdate = async (dateName) => {
    await propertyModel.fetchUpdateDate(property.uid, dateName, new Date())
  }
  const handleDateSigned = async () => {
    requestDateUpdate('signed')
  }
  const handleDateApproved = async () => {
    requestDateUpdate('approved')
  }

  const handleGenerateContract = async () => {
    actions.setGlobalLoaderAction(true)
    try {
      await propertyModel.fetchCreateContract(property.uid, property.ower_id)
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const handleEditContract = () => {
    window.open(`${routes.ADMIN}/contrato/editor/${property.uid}`, property.uid)
  }

  const handleEditStep = () => {
    storage.save(STORAGE_PROPERTY_ID, propertyId)
    window.open(`${BASE_URL}${contractStep.path_orig}?id=${propertyId}`)
  }

  const portalsHosts = ['olx', 'zapimoveis', 'vivareal']

  const handleSalvarAdLinkOnClick = async () => {
    const currentAdLinks = ad?.links ?? []
    const url = new URL(adInputLinkRef.current.value)
    const host = url.host

    const hostSplited = host.split('.')
    const validHost = portalsHosts.some((h) => hostSplited.includes(h))

    if (!validHost) {
      adInputLinkRef.current.value = ''
      return alert('Link inválido! O link tem que ser da OLX, Zap Imóveis ou Viva Real.')
    }

    let newAdLink

    if (hostSplited.includes('olx')) {
      newAdLink = {
        portal: 'olx',
      }
    } else if (hostSplited.includes('zapimoveis')) {
      newAdLink = {
        portal: 'zap_imoveis',
      }
    } else {
      newAdLink = {
        portal: 'viva_real',
      }
    }

    if (newAdLink) {
      url.searchParams.delete('preview')
      newAdLink.href = url.toString()
    }

    const adLinks = [newAdLink, ...currentAdLinks]
    try {
      actions.setGlobalLoaderAction(true)
      await updatePropertyData(null, adLinks, {path: 'ad.links'})
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
      adInputLinkRef.current.value = ''
      forceRefreshScreen({})
    }
  }

  const handleDeleteAdLinkOnclick = async (adLink) => {
    if (!adLink) return

    const adLinks = ad?.links.filter((link) => link.href !== adLink)

    try {
      actions.setGlobalLoaderAction(true)
      await updatePropertyData(null, adLinks, {path: 'ad.links'})
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
      forceRefreshScreen({})
    }
  }

  const handleShowContractEditorHistory = () => {
    setContractHistoryVisibility(true)
  }
  const handleHideContractEditorHistory = () => {
    setContractHistoryVisibility(false)
  }

  useEffect(() => {
    actions.setNavbarTitleAction(`Imóvel: ${property?.alias}`)
  }, [actions, property])

  const sortSelectedProposalAtTop = (p1, p2) => {
    // TODO
    return 1
  }

  const loadOwner = async (prop) => {
    console.log('loadOwner')
    let owner = {}
    let realOwner

    if (prop.ower_id) {
      const ownerRef = await userModel.getById(prop.ower_id)
      owner = ownerRef.data()
    }

    if (prop.contract?.owner?.data_source === 'other') {
      const realOwnerIdRef = await prop.contract.owner.user.get()
      realOwner = realOwnerIdRef.data()
    }

    actions.adminSetOwnerAction(owner, realOwner)
  }

  const loadProposals = async (prop) => {
    const initialProposal = prop.proposals ?? {}
    const loadedProposals = await proposalLoader({...initialProposal})
    if (prop?.selected_proposal?.uid) {
      const selectedId = prop.selected_proposal.uid
      const selected = loadedProposals[selectedId]
      setSelectedProposalUid(selectedId)
      setProposalSelected(selected)

      delete loadedProposals[selectedId]
    }
    setProposals(Object.entries(loadedProposals))
  }

  const loadSurvey = async () => {
    // const survey = await firestore.doc(`survey/${propertyId}`).get()
    const survey = await surveyModel.getSurvey(propertyId)
    surveyState[1](survey)
    surveyImageState[1](survey?.entering?.images ?? [])
  }

  useEffect(() => {
    const unlisten = firestore
      .collection(propertyModel.COLLECTION_NAME)
      .doc(propertyId)
      .onSnapshot(async (snapshot) => {
        if (!snapshot.exists) {
          return
        }

        const curProp = snapshot.data()

        setPropertySnap(snapshot)
        actions.adminSetPropertyAction(curProp)
        actions.setNavbarTitleAction(`Imóvel: ${curProp.alias}`)

        if (updateCounter === 0) {
          loadSurvey()
          loadOwner(curProp)
          loadProposals(curProp)
        } else {
          setPropertyUpdated(true)
        }

        updateCounter++
      })

    return () => {
      unlisten()
      updateCounter = 0
    }
  }, [])

  if (!property) {
    return <Loader loading={true} />
  }

  const {
    alias,
    created_at,
    occupation_type,
    rent_amount,
    iptu_amount,
    condominium_amount,
    more_about,
    specs,
    address,
    contract,
    ad_form_filled,
    ad_enabled,
    ad_disabled_by_admin,
    ad,
  } = property
  return (
    <div className={classes.propertyDetailRoot}>
      {owner && (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>{realOwner ? 'Administrador' : 'Proprietário'}</h3>

            <div>
              <UserBasicData user={owner} />
            </div>
          </CardContent>
        </Card>
      )}

      {realOwner && (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>
              Proprietário <small>(dono)</small>
            </h3>
            <div>
              <UserBasicData user={realOwner} />
            </div>
          </CardContent>
        </Card>
      )}

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h3>Dados do imóvel</h3>
          <div>
            <DataDisplay
              label="Link de convite"
              value={
                <Button color="secondary" size="small" onClick={copyInviteLink}>
                  copiar
                </Button>
              }
            />

            <DataDisplay label="ID" value={property.uid} />
            <DataDisplay label="Apelido" value={alias} />
            <DataDisplay label="Criado em" value={convertDateToBr(created_at)} />

            <DataDisplay label="Aluguel" value={readableValue(rent_amount, 'money')} />
            <DataDisplay label="IPTU" value={readableValue(iptu_amount, 'money')} />
            <DataDisplay label="Condominio" value={readableValue(condominium_amount, 'money')} />

            <Divider />
            <a href={`${routes.DASHBOARD_AD_LEADS}?property_id=${property.uid}`} target={property.uid}>
              Ver interessados
            </a>

            <DataDisplay label="Anúncio preenchido" value={translate(ad_form_filled)} />
            <DataDisplay
              label="Anúncio ativo"
              value={ad_enabled}
              onToggle={updatePropertyData}
              edit={{path: 'ad_enabled'}}
            />
            <DataDisplay
              label="Anúncio desabilitado no admin"
              value={ad_disabled_by_admin}
              edit={{path: 'ad_disabled_by_admin'}}
              onToggle={updatePropertyData}
            />

            <div className={classes.linksDosAnuncios}>
              <strong>Links dos anúncios:</strong>
              <div className={classes.adicionarAdLinkWrapper}>
                <input ref={adInputLinkRef} />
                <Button color="secondary" onClick={handleSalvarAdLinkOnClick}>
                  SALVAR
                </Button>
              </div>
              <ul>
                {ad?.links?.length
                  ? ad.links.map((link, idx) => (
                      <li key={idx}>
                        <a href={link.href} target="_blank" rel="noreferrer">
                          {link.portal}
                        </a>
                        <IconWrapper bgType="red" onClick={() => handleDeleteAdLinkOnclick(link.href)}>
                          <IconTrashCan />
                        </IconWrapper>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h3>
            Contrato - {contractMapper.translate(contract?.status)}
            {contract.is_manually_edited && (
              <>
                <br />
                <small className="clickable" onClick={handleShowContractEditorHistory}>
                  editado manualmente (ver historico)
                </small>
              </>
            )}
          </h3>
          <div>
            <MobileStepper
              position="static"
              variant="text"
              steps={contractStepsLen}
              activeStep={contractStepIndex}
              backButton={
                <Button size="small" onClick={handlePrevStep} disabled={contractStepIndex <= contractMinStep}>
                  <KeyboardArrowLeft />
                  Anterior
                </Button>
              }
              nextButton={
                <Button size="small" onClick={handleNextStep} disabled={contractStepIndex >= contractMaxStep}>
                  Próximo
                  <KeyboardArrowRight />
                </Button>
              }
            />

            <div>
              <h4 style={{margin: '10px 0'}}>{contractStep.title}</h4>
              <ContractStepDisplay
                id={contractStep.id}
                data={property.contract[contractStep.id]}
                progress={property.contract.progress[contractStep.id]}
                owner={owner}
                tenant={{}}
                property={property}
              />

              <br />
              {contractStep.has_form && (
                <Button size="small" variant="outlined" onClick={handleEditStep}>
                  Editar etapa
                </Button>
              )}
            </div>

            <br />
            <Divider />
            <div className={classes.buttonsContainer}>
              <div className={classes.cardBtnWrapper}>
                <Button className={classes.btn16} onClick={handleIniciarAssinatura} endIcon={<MonetizationOnIcon />}>
                  iniciar mensalidade
                </Button>
              </div>

              <div className={classes.cardBtnWrapper}>
                <Button className={classes.btn16} onClick={showInsuranceModal} endIcon={<IconSecurity />}>
                  seguros
                </Button>
              </div>

              <div className={classes.cardBtnWrapper}>
                <Button className={classes.btn16} onClick={handleEditContract} endIcon={<PanToolIcon />}>
                  ediçao manual
                </Button>
              </div>

              <div className={classes.cardBtnWrapper}>
                <Button className={classes.btn16} onClick={handleGenerateContract} endIcon={<PictureAsPdfIcon />}>
                  criar pdf
                </Button>
              </div>

              {!contract?.contract_signed && (
                <div className={classes.cardBtnWrapper}>
                  <FilePicker onChange={handleDocSignedChange}>
                    <Button className={classes.btn16} endIcon={<DescriptionIcon />}>
                      upload assinado
                    </Button>
                  </FilePicker>
                </div>
              )}

              {!contract?.payments || contract?.payments?.length === 0 ? (
                <div className={classes.cardBtnWrapper}>
                  <Button
                    className={classes.btn16}
                    disabled={boletoGerado}
                    onClick={handleIniciarContratoOnClick}
                    endIcon={<PlayCircleOutlineIcon />}>
                    play
                  </Button>
                </div>
              ) : (
                <div className={classes.cardBtnWrapper}>
                  <Button className={classes.btn16} onClick={handleEncerrarContrato} endIcon={<IconStop />}>
                    stop
                  </Button>
                </div>
              )}

              {!contract?.date_signed && (
                <div className={classes.cardBtnWrapper}>
                  <Button className={classes.btn16} endIcon={<EventAvailableIcon />} onClick={handleDateSigned}>
                    marcar como assinado
                  </Button>
                </div>
              )}

              {!contract?.date_approved && (
                <div className={classes.cardBtnWrapper}>
                  <Button className={classes.btn16} endIcon={<EventAvailableIcon />} onClick={handleDateApproved}>
                    marcar como aprovado
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h3>Arquivos</h3>
          <div>
            <BlockFiles property={property} surveyState={surveyState} surveyImageState={surveyImageState} />
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h3>Anotações</h3>
          <div>
            <BlockAnnotation />
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card2Columns}>
        <CardContent className={classes.cardContent}>
          <h3>Inquilinos</h3>
          <div>
            {proposalSelected && (
              <ProposalEntry
                name=""
                isSelected={true}
                proposalId={selectedProposalUid}
                proposalData={proposalSelected}
                onGetInvite={(type, id) => getInviteLink(type, id)}
              />
            )}
            {proposals.length ? (
              proposals.map(([proposalId, proposalData], proposalIndex) => (
                <div key={proposalIndex}>
                  <ProposalEntry
                    name={proposalIndex + 1}
                    isSelected={false}
                    proposalId={proposalId}
                    proposalData={proposalData}
                    onGetInvite={(type, id) => getInviteLink(type, id)}
                  />
                  <hr />
                </div>
              ))
            ) : (
              <div />
            )}
            <br />
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card2Columns}>
        <CardContent className={classes.cardContent}>
          <h3>Pagamentos ({paymentMethodTranslate})</h3>
          <div>
            <BlockPayment
              propertyId={propertyId}
              ownerId={owner?.uid}
              paymentMethod={paymentMethod}
              payments={payments}
              rentfees={rentfees}
            />
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h3>Endereço</h3>
          <div>
            <DataDisplay label="CEP" value={address.zip} edit={{path: 'address.zip'}} onSave={updatePropertyData} />
            <DataDisplay
              label="Logradouro"
              value={address.street}
              edit={{path: 'address.street'}}
              onSave={updatePropertyData}
            />
            <DataDisplay
              label="Numero"
              value={address.street_number}
              edit={{path: 'address.street_number', validation: validateNumber}}
              onSave={updatePropertyData}
            />
            <DataDisplay
              label="Bairro"
              value={address.neighborhood}
              edit={{path: 'address.neighborhood'}}
              onSave={updatePropertyData}
            />
            <DataDisplay
              label="Cidade"
              value={address.city}
              edit={{path: 'address.city'}}
              onSave={updatePropertyData}
            />
            <DataDisplay
              label="Estado"
              value={address.state}
              edit={{path: 'address.state', preformat: (value) => value.toUpperCase()}}
              onSave={updatePropertyData}
            />
            <DataDisplay
              label="Complemento"
              value={address.complement}
              edit={{path: 'address.complement'}}
              onSave={updatePropertyData}
            />
            <div className={classes.cardBtnWrapper}>
              {address.zip && (
                <Button color="secondary" target="_blank" href={mapsLink(address)}>
                  ver no mapa
                </Button>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h3>Dados adicionais</h3>
          <div>
            <DataDisplay label="Aceita Pets" value={more_about.pets} />
            <DataDisplay label="Mobiliado" value={more_about.furnished} />
            <DataDisplay label="Fumante" value={more_about.smoking} />
            <DataDisplay label="Metragem" value={specs.footage} />
            <DataDisplay label="Quartos" value={specs.rooms} />
            <DataDisplay label="Banheiros" value={specs.bathrooms} />
            <DataDisplay label="Garagem" value={specs.garage} />
            <DataDisplay label="Suites" value={specs.suites} />
          </div>
        </CardContent>
      </Card>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={shareLink}
        onClose={closeSnackCopy}
        autoHideDuration={5000}>
        <Alert severity="success">
          copiado
          <br />
          {shareLink}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={propertyWasUpdated}
        onClose={closeSnackPropertyUpdate}
        autoHideDuration={3000}>
        <Alert severity="info">Dados do imóvel foram atualizados</Alert>
      </Snackbar>

      <BaseModal
        title="Histórico de edição"
        isOpen={isContractHistoryVisible}
        cancelLabel=""
        okAction={handleHideContractEditorHistory}
        alignCenter={false}>
        <ContractHistoryList history={contractHistory} />
      </BaseModal>

      <BaseModal
        title="Seguro"
        isOpen={isInsuranceVisible}
        cancelLabel=""
        okLabel="Fechar"
        okAction={hideInsuranceModal}
        alignCenter={false}>
        <BlockInsurance propertyId={propertyId} closeModal={hideInsuranceModal} />
      </BaseModal>

      <BaseModal
        title="Encerrar Contrato"
        isOpen={isFinishContractVisible}
        cancelLabel=""
        okLabel="Fechar"
        okAction={hideFinishContractModal}
        alignCenter={false}>
        <BlockFinishContract property={property} owner={owner} closeModal={hideFinishContractModal} />
      </BaseModal>
    </div>
  )
}

function getResponsibleFor(option, value) {
  switch (option) {
    case 'iptu':
      return feesIptuOptions.find((opt) => opt.value === value)?.label ?? ''

    case 'condominium':
      return feesCondominiumOptions.find((opt) => opt.value === value)?.label ?? ''

    case 'fire_insurance':
      return feesFireInsuranceOptions.find((opt) => opt.value === value)?.label ?? ''

    default:
      return ''
  }
}

const ContractStepDisplay = (props) => {
  const {id, data, owner, tenant, progress, property} = props
  let comp = <>não preenchido</>

  if (!data) {
    return comp
  }
  switch (id) {
    case 'yourdata':
      comp = <UserBasicData user={owner} />
      break
    case 'tenant':
      comp = <UserBasicData user={tenant} />
      break
    case 'property':
      comp = <>redundante</>
      break
    case 'validity':
      comp = (
        <>
          <DataDisplay label="Inicio" value={convertDateToBr(data.start)} />
          <DataDisplay label="Fim" value={convertDateToBr(data.end)} />
        </>
      )
      break
    case 'rentdue':
      comp = <DataDisplay label="Dia" value={data.day} />
      break
    case 'rentfees':
      comp = (
        <>
          <DataDisplay label="Valor IPTU" value={data.iptu} readableType="money" />
          <DataDisplay label="Incluir IPTU" value={data.include_iptu} />
          <DataDisplay label="Responsável" value={getResponsibleFor('iptu', data.iptu_responsible)} />
          <Divider dashed />
          <DataDisplay label="Valor condomínio" value={data.condominium} readableType="money" />
          <DataDisplay label="Incluir condomínio" value={data.include_condominium} />
          <DataDisplay label="Responsável" value={getResponsibleFor('condominium', data.condominium_responsible)} />

          <Divider dashed />
          <DataDisplay label="Valor seguro incêndio" value={data.fire_insurance} readableType="money" />
          <DataDisplay
            label="Responsável"
            value={getResponsibleFor('fire_insurance', property?.contract?.fire_insurance?.responsible)}
          />

          <DataDisplay label="Total" value={data.total} readableType="money" />
        </>
      )
      break
    case 'fire_insurance':
      comp = (
        <>
          <DataDisplay label="Responsável" value={data.responsible === 'tenant' ? 'Inquilino' : 'Proprietário'} />
          <DataDisplay label="Seguradora" value={data.insurer} />
          <DataDisplay label="Número da apolice" value={data.policy_number} />
          <DataDisplay label="Valor" value={formatFloatToBrCurrency(fromCents(data.amount_cents ?? 0))} />
        </>
      )
      break
    case 'penalties':
      comp = (
        <>
          <DataDisplay label="Isentar a partir de" value={data.exempt_from} />
        </>
      )
      break
    case 'readjustment_index':
      comp = (
        <>
          <DataDisplay label="Indice" value={data.type} />
        </>
      )
      break
    case 'guarantees':
      comp = (
        <>
          <GuaranteeDisplay type={data.type} subtype={data.subtype} data={data.data} />
        </>
      )
      break
    case 'forum':
      comp = (
        <>
          <DataDisplay label="Tipo" value={data.type === 'camara_arbitral' ? 'Camara arbitral' : 'Justiça comum'} />
        </>
      )
      break
    case 'witnesses':
      comp = data.map((witness) => (
        <>
          <DataDisplay label="Nome" value={witness.name} />
          <DataDisplay label="Email" value={witness.email} />
          <hr />
        </>
      ))
      break
    case 'survey':
      comp = (
        <>
          <DataDisplay label="Foi/Será feita vistoria" value={data.has_survey ? 'sim' : 'nao'} />
        </>
      )
  }

  return (
    <div key={id}>
      {comp}
      <br />
      {progress?.created_at && <DataDisplay label="Criado" value={convertDateToBr(progress.created_at)} />}
      {progress?.updated_at && <DataDisplay label="Alterado" value={convertDateToBr(progress.updated_at)} />}
    </div>
  )
}

const guarantessComp = (data) => ({
  fianca: {
    fiador: () => (
      <>
        <UserBasicData user={data} />
      </>
    ),

    bancaria: () => (
      <>
        <DataDisplay label="Documento" value={data.file?.download_url} />
      </>
    ),
    seguro: () => (
      <>
        <DataDisplay label="Responsavel" value={data.responsible_for_seguro_fianca} />
        <DataDisplay label="Cláusulas extras" value={data.extra_clauses?.substring(0, 50)} />
      </>
    ),
  },
  caucao: {
    dinheiro: () => <DataDisplay label="Valor do caução" value={data.rent_months} />,
    titulo_capitalizacao: () => <>Ops!</>,
    bens_imoveis: () => (
      <>
        <DataDisplay label="Número da matrícula" value={data.registration_number} />
        <DataDisplay label="Documento" value={data.file?.download_url} />
      </>
    ),
  },
  none: () => (
    <>
      <DataDisplay label="Cobrança do boleto" value={data.cobranca_boleto} />
    </>
  ),
})

const GuaranteeDisplay = ({type, subtype, data}) => {
  const guarantee = findGuaranteeByType(type)
  const compsList = guarantessComp(data)
  let comp = <>nao preenchido</>

  const main = compsList[type]
  if (typeof main === 'function') {
    comp = main()
  } else if (main[subtype]) {
    comp = main[subtype]()
  }

  return (
    <>
      {guarantee.title}
      {comp}

      <DataDisplay label="Seguradora" value={data.insurer} />
      <DataDisplay label="Número da apolice" value={data.policy_number} />
      <DataDisplay label="Valor" value={formatFloatToBrCurrency(fromCents(data.amount_cents ?? 0))} />
    </>
  )
}

export default PropertyDetail
