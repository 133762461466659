import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import BackLink from '@root/components/BackLink'
import loadPropertyFromQuery from '@root/lib/property/loadPropertyFromQuery'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

import BoletosInfo from '../Boletos/BoletosInfo'
// import ButtonTransparentBg from '@root/components/ButtonTransparentBg'

const ControleStatus = () => {
  const history = useHistory()
  useEffect(() => {
    loadPropertyFromQuery()
  }, [])

  return (
    <DashboardWrapper title="Controle">
      <BoletosInfo type="manual" options={['pagos', 'futuros']} />
      {/* 
        <ButtonTransparentBg>NOVO PAGAMENTO RECIBO</ButtonTransparentBg>
      */}
      <div style={{display: 'flex', marginTop: 30, justifyContent: 'center'}}>
        <BackLink onClick={() => history.push(routes.DASHBOARD_ROOT)} />
      </div>
    </DashboardWrapper>
  )
}

export default ControleStatus
