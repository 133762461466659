import TenantDashboardAdditionalDocuments from '@root/components/App/TenantDashboard/AdditionalDocuments/TenantDashboardAdditionalDocuments'
import TenantDashboardContract from '@root/components/App/TenantDashboard/Contract/TenantDashboardContract'
import TenantDashboardOwner from '@root/components/App/TenantDashboard/Owner/TenantDashboardOwner'
import TenantDashboardPayments from '@root/components/App/TenantDashboard/Payments/TenantDashboardPayments'
import TenantDashboardReceipts from '@root/components/App/TenantDashboard/Receipts/TenantDashboardReceipts'
import TenantDashboard from '@root/components/App/TenantDashboard/TenantDashboard'
import React from 'react'
import {Route, Switch} from 'react-router-dom'
import routes from '@root/utils/routes'

import TenantDashboardAsk from '../Ask/TenantDashboardAsk'
import TenantDashboardProperty from '../Property/TenantDashboardProperty'

function TenantDashboardRouter() {
  return (
    <Switch>
      <Route exact path={routes.DASHBOARD_TENANT_ROOT} component={TenantDashboard} />
      <Route exact path={routes.DASHBOARD_TENANT_PROPERTY} component={TenantDashboardProperty} />
      <Route exact path={routes.DASHBOARD_TENANT_ASK} component={TenantDashboardAsk} />
      <Route exact path={routes.DASHBOARD_TENANT_OWNER_PROFILE} component={TenantDashboardOwner} />
      <Route exact path={routes.DASHBOARD_TENANT_RECEIPTS} component={TenantDashboardReceipts} />
      <Route exact path={routes.DASHBOARD_TENANT_CONTRACT} component={TenantDashboardContract} />
      <Route exact path={routes.DASHBOARD_TENANT_PAYMENTS} component={TenantDashboardPayments} />
      <Route exact path={routes.DASHBOARD_TENANT_ADDITIONAL_DOCUMENTS} component={TenantDashboardAdditionalDocuments} />
      <Route exact path={routes.DASHBOARD_TENANT_CONTRACT} component={TenantDashboardContract} />
    </Switch>
  )
}

export default TenantDashboardRouter
