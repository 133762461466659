import {SigninTypes} from '@root/redux/signin/signin.types'

import {ReceiptsActionTypes} from './receipts.types'

const initialState = {
  property_uid: '',
  receipts: [],
}

const receiptsReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SigninTypes.LOG_OUT:
      return {...initialState}

    case ReceiptsActionTypes.SET_PROPERTY_UID_FOR_RECEIPTS:
      return {
        ...state,
        property_uid: payload,
      }

    case ReceiptsActionTypes.ADD_RECEIPT:
      return {
        ...state,
        receipts: [...state.receipts, payload],
      }

    case ReceiptsActionTypes.SET_RECEIPTS_FROM_DATABASE:
      return {
        ...payload,
      }

    default:
      return state
  }
}

export default receiptsReducer
