import Text from '@root/components/Layout/Typography/Text'
import BaseModal from '@root/components/Modal/BaseModal'
import PropTypes from 'prop-types'
import React, {useState} from 'react'

/**
 *
 * @param {()=>void} [onOk]
 * @param {()=>void} [onCancel]
 * @return {{close:()=>void, open:()=>void, visibility:boolean, isOpen:boolean, isClose:boolean}}
 */
const useModalControl = (onOk, onCancel) => {
  const [visibility, setVisibility] = useState(false)

  const open = () => setVisibility(true)
  const close = () => setVisibility(false)

  const handleOk = () => {
    close()
    if (onOk) {
      onOk()
    }
  }

  const handleCancel = () => {
    close()
    if (onCancel) {
      onCancel()
    }
  }

  return {
    open,
    close,
    visibility,
    isOpen: visibility === true,
    isClose: visibility === false,
  }
}

export default useModalControl
