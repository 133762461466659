import Grid from '@material-ui/core/Grid'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import IconPencil from '@root/components/icons/IconPencil'
import IconTrashCan from '@root/components/icons/IconTrashCan'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import React from 'react'

const RepresentativeSumaryItem = ({user, onDelete, onEdit, id}) => {
  const {name, cpf, rg, phone, email} = user

  const forwardClick = (ev, cb) => {
    if (cb) {
      cb(ev, id)
    }
  }

  const proxyDelete = (ev) => forwardClick(ev, onDelete)

  const proxyEdit = (ev) => forwardClick(ev, onEdit)

  return (
    <Box bgType="gray">
      <Grid container direction="row" justify="space-between">
        <Grid item>
          <p>{name}</p>
          <p>{cpf}</p>
          <p>{rg}</p>
          <p>{phone}</p>
          <p>{email}</p>
        </Grid>

        <Grid item alignItems="flex-start">
          <IconWrapper bgType="red" spaceRight={8} onClick={proxyDelete}>
            <IconTrashCan />
          </IconWrapper>

          <IconWrapper spaceLeft={8} onClick={proxyEdit}>
            <IconPencil />
          </IconWrapper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RepresentativeSumaryItem
