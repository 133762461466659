import TextField from '@material-ui/core/TextField'
import React from 'react'

const TextFieldRender = (controllerProps, helpers, formItemProps) => {
  const {hasError, applyShrink, forwardOnChange, forwardOnBlur} = helpers
  const {value} = controllerProps
  const {onFocus, label} = formItemProps

  let curValue = value || formItemProps.value
  curValue = curValue || ''

  return (
    <TextField
      inputRef={helpers.inputRef}
      error={hasError}
      label={label}
      InputLabelProps={{shrink: applyShrink}}
      {...formItemProps}
      onChange={forwardOnChange}
      onBlur={forwardOnBlur}
      onFocus={onFocus}
      // value={value || ''}
      value={curValue}
    />
  )
}

export default TextFieldRender
