import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {ReactComponent as LocationIcon} from '@root/assets/icons/location_address_icon.svg'
import FormItem from '@root/components/FormHelper/FormItem'
import {Box} from '@root/components/Layout/Container/Box/Box'
import LocalLoader from '@root/components/Loader/LocalLoader'
import {validateNumber, validateRequired} from '@root/lib/simpleValidators'
import {getZipNum, isValidZip, searchAddress} from '@root/lib/zipcodeSearch'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {CEP_FORMAT} from '@root/utils/constants'

export const useStyles = makeStyles((theme) => ({
  addressContainer: {
    'display': 'flex',
    'alignItems': 'center',
    '& svg': {
      width: '60px',
      height: '60px',
      fill: theme.extraColors.GRAY[500],
    },
    '& ul': {
      'margin': 0,
      'padding': '0 0 0 1.5rem',
      'display': 'flex',
      'flexDirection': 'column',
      '& li:not(:first-child) h6': {fontWeight: '400 !important'},
      '& li + li': {
        marginTop: theme.margin[2],
      },
    },
  },
  cepContainer: {
    'display': 'flex',
    'alignItems': 'center',
    '& > :first-child': {
      width: '25%',
      marginRight: theme.margin[2],
    },
  },
  numeroComplementoContainer: {
    // 'margin': '15px 0',
    'display': 'flex',
    '& > :first-child': {
      width: '30%',
      marginRight: theme.margin[4],
    },
  },
}))

/**
 * Para que esse comp funcione apropriadamente precisa de ter o <FormProvider> em volta do formulario
 * https://react-hook-form.com/api/useformcontext
 * https://github.com/SoftwareBrothers/better-docs
 *
 * @component
 * @param address
 */
const AddressForm = ({address}) => {
  const classes = useStyles()
  const {control, errors, setValue} = useFormContext()
  const [isLoadingAddress, setLoadingAddress] = useState(false)
  const [isShowAddressFields, setIsShowAddressFields] = useState(false)
  const [currentAddress, setCurrentAddress] = useState()

  const zipTyping = async (evt) => {
    const zipcode = getZipNum(evt)

    if (isValidZip(zipcode)) {
      setLoadingAddress(true)
      try {
        const addr = await searchAddress(zipcode)
        setCurrentAddress(() => ({...currentAddress, ...addr}))
        setValue('address.street', addr.street)
        setValue('address.neighborhood', addr.neighborhood)
        setValue('address.city', addr.city)
        setValue('address.state', addr.state)

        const condition = addr.street === '' || addr.neighborhood === '' || addr.city === '' || addr.state === ''

        setIsShowAddressFields(condition)
      } catch (ex) {
        alert(ex.message)
      } finally {
        setLoadingAddress(false)
      }
    }
  }

  /*const onFinishFetchAddress = async (addr) => {
    setValue('address.street', addr.street)
    setValue('address.neighborhood', addr.neighborhood)
    setValue('address.city', addr.city)
    setValue('address.state', addr.uf, {shouldValidate: true})
  }*/

  useEffect(() => {
    setCurrentAddress(address)
  }, [address])

  const handleOnChange = (e) => {
    const name = e.target.name.replace('address.', '')
    const value = e.target.value
    setCurrentAddress((s) => ({...s, [name]: value}))
  }

  return (
    <LocalLoader loading={isLoadingAddress} absolute={true}>
      <>
        <div className={classes.cepContainer}>
          <FormItem
            data-testid="address-form-zip"
            id="address-form-zip"
            control={control}
            errors={errors}
            fitContainer={true}
            color="secondary"
            label="CEP"
            type="mask"
            name="address.zip"
            onChange={zipTyping}
            rules={{validate: validateRequired}}
            maskInputProps={{
              format: CEP_FORMAT,
            }}
          />
        </div>

        {currentAddress && (
          <Box bgType="gray" h="106px" m="0 0 20px">
            <div className={classes.addressContainer}>
              <LocationIcon />
              <ul>
                <li>
                  <Typography variant="h6" color="textPrimary" data-testid="address-form-preview-street">
                    {currentAddress.street}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" color="textPrimary" data-testid="address-form-preview-neighborhood">
                    {currentAddress.neighborhood}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" color="textPrimary" data-testid="address-form-preview-citystate">
                    {currentAddress.city} / {currentAddress.state}
                  </Typography>
                </li>
              </ul>
            </div>
          </Box>
        )}

        <div style={isShowAddressFields ? {display: 'flex', margin: '15px 0'} : {display: 'none', margin: '15px 0'}}>
          <FormItem
            required={true}
            rules={{validate: validateRequired}}
            control={control}
            errors={errors}
            onChange={handleOnChange}
            label="Logradouro"
            name="address.street"
            id="address-form-street"
          />
          <FormItem
            required={true}
            rules={{validate: validateRequired}}
            control={control}
            errors={errors}
            onChange={handleOnChange}
            label="Bairro"
            name="address.neighborhood"
          />
          <FormItem
            required={true}
            rules={{validate: validateRequired}}
            control={control}
            errors={errors}
            onChange={handleOnChange}
            label="Cidade"
            name="address.city"
          />
          <FormItem
            required={true}
            rules={{validate: validateRequired}}
            control={control}
            errors={errors}
            onChange={handleOnChange}
            label="Estado"
            name="address.state"
          />
        </div>

        <div className={classes.numeroComplementoContainer}>
          <FormItem
            color="secondary"
            required
            control={control}
            errors={errors}
            label="Número"
            name="address.street_number"
            rules={{
              validate: validateNumber,
            }}
          />

          <FormItem
            color="secondary"
            fitContainer
            control={control}
            errors={errors}
            required={false}
            label="Complemento"
            name="address.complement"
            defaultValue=""
          />
        </div>
      </>
    </LocalLoader>
  )
}
AddressForm.propTypes = {
  address: PropTypes.object,
}
AddressForm.defaultProps = {}

export default AddressForm
