import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  cadastroDeInquilinos: {
    position: 'relative',
    backgroundColor: '#FFF',
    paddingBottom: '100px',
  },
  stepper: {
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cadastroDeInquilinosTitle: {
    width: '100%',
    // textAlign: 'center',
    marginTop: '162px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
    },
  },
  whiteBoxTop: {
    backgroundColor: '#FFFFFF',
    width: '60%',
    margin: '40px auto 0',
    padding: '20px',
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: '15px',
    },
  },
  iconFieldBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  textFieldBtnContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
      marginTop: '10px',
    },
  },
  textFieldBtn: {
    marginLeft: '-4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
  },
  textField: {
    width: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  compartilharContainer: {
    width: '100%',
    padding: '5px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
    },
  },
  whiteBoxBottom: {
    backgroundColor: '#FFFFFF',
    width: '60%',
    height: '100px',
    margin: '40px auto 0',
    padding: '20px',
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      padding: '20px 15px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: '20px 15px 35px',
    },
  },
  link: {
    display: 'inline-block',
    color: '#0101CD',
    fontSize: '1.2rem',
    fontWeight: '600',
    textAlign: 'center',
    padding: '10px',
    width: '80%',
    margin: '20px auto 10px',
  },
  cadastrarInquilinoInfo: {
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginTop: '20px',
    },
  },
  form: {
    // backgroundColor: '#FAF',
    // width: '40%',
    // height: '650px',
    // margin: '40px auto 20px',
    borderRadius: '12px',
    /*[theme.breakpoints.down('sm')]: {
      width: '60%',
      height: '650px',
    },
    */
    [theme.breakpoints.up('sm')]: {
      padding: 30,
    },
  },
  uploadAcrescentarInquilinosBtnContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      'flexWrap': 'wrap',
      '& button': {
        width: '204px',
        marginTop: '15px',
      },
    },
  },
  dialogGridContainer: {
    padding: '40px',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  dialogCard: {
    padding: '20px',
    width: '426px',
    height: '450px',
  },
  dialogCardTitle: {
    color: '#0C005F',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  dialogCardMedia: {
    backgroundSize: 'contain',
    width: '100%',
    height: '300px',
    marginBottom: '20px',
  },
  dialogCardText: {
    color: '#030303',
  },
  dialogCardIcon: {
    width: '70px',
  },
  proposalViewRoot: {
    '& h5': {
      fontWeight: 600,
      fontSize: '18px',
    },
  },
  cardContainer: {
    position: 'relative',
    padding: '10px',
    border: '1px solid #f1f3f1',
    marginTop: '20px',
  },
  container: {
    'width': 'calc(100% + 20px)',
    'padding': '10px',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'background': '#F5F4F4',
    '& p': {
      margin: '0 !important',
      fontSize: '15px',
      lineHeight: '22px',
    },
  },
  containerTop: {
    margin: '-10px 0 -10px -10px',
  },
  containerBottom: {
    margin: '-10px 0 0 -10px',
  },

  infoContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& > div': {
      flex: 1,
    },
  },
  infoTopContainer: {
    marginTop: '20px',
  },
  infoBottomContainer: {
    marginTop: '20px',
  },
  variantBody1: {
    marginBottom: '10px',
    fontWeight: 600,
  },
  variantBody2: {
    marginTop: '0 !important',
    paddingTop: '0 !important',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: '107px',
    height: '107px',
  },
  aceitarPropostaButtonWrapper: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    'margin': '15px 0',
    '& > a': {
      marginTop: 20,
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
  creditAnalysisInfoContainer: {
    marginTop: '10px',
    background: '#F5F4F4',
    width: 'calc(100% + 20px)',
    padding: '10px',
    margin: '0 0 -10px -10px',
  },
  proposalRegister: {
    '& > p': {
      'textAlign': 'center',
      'marginBottom': 10,
      '&:not(:first-child)': {
        marginTop: 20,
      },
    },
  },
  criarNovaPropostaWrapper: {
    '& > p': {
      textAlign: 'center',
      margin: '20px 0 10px',
    },
    'display': 'grid',
    'placeItems': 'center',
  },
}))

export default useStyles
