import Grid from '@material-ui/core/Grid'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import {Divider} from '@root/components/Divider/Divider'
import {currencyFormatProps} from '@root/components/NumberMask'
import {firestore} from '@root/firebase/firebase.utils'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import dateParser, {convertDateToBr, convertDateToIso} from '@root/lib/dateParser'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {maritalStatusOptions, occupationOptions} from '@root/lib/predefinedValuesOptions'
import {numberFormatValidateCurrencyOptional, validateCpf, validateDate} from '@root/lib/simpleValidators'
import userModel from '@root/model/userModel'
import PropTypes from 'prop-types'
import React from 'react'
import {Link} from 'react-router-dom'
import registrationTypeMapper from 'status-mapper/mapper/registration-type-mapper'
import {CPF_FORMAT, DATE_BR_FORMAT} from '@root/utils/constants'

const CommonData = (props) => {
  const {user, updateUserData, ...rest} = props
  return (
    <>
      <DataDisplay label="Nome" value={user.name} />
      <DataDisplay label="E-Mail" value={user.email} readableType="email" />
      <DataDisplay label="Telefone" value={user.phone} readableType="phone" />

      <DataDisplay
        label="Nascimento"
        value={convertDateToBr(user.birthdate)}
        onSave={updateUserData}
        formProps={{
          type: 'mask',
          maskInputProps: {format: DATE_BR_FORMAT},
        }}
        edit={{path: 'birthdate', validation: validateDate, preformat: (value) => dateParser(value)}}
      />

      <DataDisplay
        label="CPF"
        value={user.cpf}
        onSave={updateUserData}
        formProps={{
          type: 'mask',
          maskInputProps: {format: CPF_FORMAT},
        }}
        edit={{path: 'cpf', validation: validateCpf}}
      />
      <DataDisplay
        label="RG"
        value={user.rg}
        onSave={updateUserData}
        formProps={{
          type: 'rg',
        }}
        edit={{path: 'rg'}}
      />
      <DataDisplay
        label="Orgão emissor"
        value={user.rg_issuing_agency}
        onSave={updateUserData}
        edit={{path: 'rg_issuing_agency'}}
      />
      <DataDisplay
        label="Estado civil"
        value={user.marital_status}
        onSave={updateUserData}
        formProps={{type: 'select', options: maritalStatusOptions}}
        edit={{path: 'marital_status'}}
      />
      <DataDisplay
        label="Ocupação"
        value={user.occupation}
        onSave={updateUserData}
        formProps={{type: 'select', options: occupationOptions}}
        edit={{path: 'occupation'}}
      />
      <DataDisplay label="Profissão" value={user.profession} onSave={updateUserData} edit={{path: 'profession'}} />
      <DataDisplay
        label="Renda"
        value={user.income}
        readableType="money"
        onSave={updateUserData}
        formProps={{
          type: 'mask',
          maskInputProps: currencyFormatProps,
        }}
        edit={{path: 'income', validation: numberFormatValidateCurrencyOptional, preformat: formatMoneyBrToFloat}}
      />
    </>
  )
}

const UserBasicData = ({user, indent, type, title}) => {
  let creditAnalysis

  if (user && user.credit_analysis) {
    creditAnalysis = user.credit_analysis[user.credit_analysis.length - 1]
  }

  const updateUserData = async (raw, value, {path}) => {
    await firestore.collection(userModel.COLLECTION_NAME).doc(user.uid).update(path, value)
  }

  const isCompany = registrationTypeMapper.assertStatus.isCompany(user.register_type)
  const isPersonal = !isCompany
  const contactTextAppend = isCompany ? 'do contato' : ''
  return (
    <div style={{marginLeft: indent ? 30 : 0}}>
      {title && <h4>{title}</h4>}
      <DataDisplay label="ID" value={<Link to={adminPathBuilder(`/usuarios/${user.uid}`)}>{user.uid}</Link>} />
      <DataDisplay label="Criado em" value={convertDateToBr(user.created_at)} />
      <DataDisplay label="Tipo de cadastro" value={registrationTypeMapper.translate(user.register_type)} />
      <DataDisplay
        label={`Nome ${contactTextAppend}`}
        value={user.name}
        onSave={updateUserData}
        edit={{path: 'name'}}
      />
      <DataDisplay label={`E-Mail ${contactTextAppend}`} value={user.email} readableType="email" />
      <DataDisplay label={`Telefone ${contactTextAppend}`} value={user.phone} readableType="phone" />

      {isPersonal && <CommonData user={user} updateUserData={updateUserData} />}

      {isCompany && (
        <>
          <DataDisplay label="CNPJ" value={user.cnpj} />
          <DataDisplay label="Razão social" value={user.company_real_name} />
          <DataDisplay label="Nome fantasia" value={user.company_name} />
          <DataDisplay label="Inscrição estadual" value={user.state_registration} />
          <DataDisplay label="Natureza da pessoa juridica" value={user.company_origin} />
          <DataDisplay label="Ramo de atividade" value={user.comercial_activity} />

          <Grid container direction="row">
            <Grid xs={12}>
              <h3>Representantes</h3>
            </Grid>
            {user.representatives.map((representative, idx) => (
              <Grid key={idx} item xs={12} sm={6}>
                <CommonData user={representative} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Divider dashed={true} />

      <DataDisplay label="Origem" value={user.origin_source} />
      {user.document && <DataDisplay label="Documento" value={<a href={user.document.download_url}>abrir</a>} />}
      <DataDisplay label="Credito analisado" value={creditAnalysis?.success} />
      {type && <DataDisplay label="Tipo" value={type} readableType="usertype" />}
      {user.tenant_invite_link && <DataDisplay label="Link convidado" value={user.tenant_invite_link?.path} />}
      <DataDisplay label="Proprietário" value={user.is_owner} />
      <DataDisplay label="Inquilino" value={user.is_tenant} />
      <DataDisplay label="Fiador" value={user.is_fiador} />
    </div>
  )
}

UserBasicData.defaultProps = {
  indent: false,
  title: undefined,
}
UserBasicData.propTypes = {
  indent: PropTypes.bool,
  title: PropTypes.string,
}
export default UserBasicData
