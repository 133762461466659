import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {whatsAppLinkGenerator, whatsAppLinkMobenDefault, whatsAppLinkMobenSales} from '@root/lib/simplePhoneFormatter'

const useWhatappLinker = () => {
  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))
  const target = isExtraSmallViewPort ? '_self' : '_blank'

  return {
    create: whatsAppLinkGenerator,
    createMoben: whatsAppLinkMobenDefault,
    createSales: whatsAppLinkMobenSales,
    openTarget: target,
    open: (link) => window.open(link, target),
  }
}

export default useWhatappLinker
