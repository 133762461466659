/** @module cartModel */
import {produce} from 'immer'
import intVal from '@root/lib/intVal'
import storage from '@root/lib/storage'
import propertyModel from '@root/model/propertyModel'
import {setCartCurrentIndexAction, setCartListAction} from '@root/redux/cart/cart.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'
import store from '@root/redux/store'
import {setCurrentUser} from '@root/redux/user/user.actions'
import uid from 'uid'

const KEY_CART = 'cart'
export const PAYMENT_METHOHD_BOLETO = 'boleto'
export const PAYMENT_METHOHD_CREDIT_CARD = 'credit_card'
export const PROPERTY_STATUS_PENDING = 'pending'
export const PROPERTY_STATUS_PAID = 'paid'
export const PROPERTY_STATUS_DONE = 'done'

const getOccupationValue = (ocupationType) => {
  if (propertyModel.occupationInfo[ocupationType]) {
    return propertyModel.occupationInfo[ocupationType].price
  }

  return -1
}

const formatToCart = (propertyState, tenantsList) =>
  produce({property: propertyState.property, tenants: tenantsList}, (draft) => {
    if (draft.property) {
      draft.property.rent_amount = intVal(draft.property.rent_amount)
      draft.property.condominium_amount = intVal(draft.property.condominium_amount)
      draft.property.iptu_amount = intVal(draft.property.iptu_amount)
    }
  })

const formatToCartFromState = () => {
  const {propertyRegister, tenantRegister} = store.getState()
  return formatToCart(propertyRegister, tenantRegister.tenantsList)
}

const saveLocal = () => {
  const {cart} = store.getState()
  storage.save(KEY_CART, cart.list)
}

const loadLocal = () => {
  return storage.load(KEY_CART, [])
}

const loadFromLocalToStore = () => {
  const cartList = loadLocal()
  const cartIndex = cartList.length - 1
  const lastItem = cartList[cartIndex]

  if (lastItem && lastItem.property) {
    store.dispatch(setCartListAction(cartList))
    store.dispatch(setCartCurrentIndexAction(cartIndex))

    store.dispatch(setCurrentProperty(lastItem.property))
    return lastItem
    // store.dispatch(setTenantFullAction(localData.tenantRegister))
  }
}

const saveToDatabase = async (cartList) => {
  return new Promise((resolve, reject) => {
    const userState = store.getState().user
    const userData = Object.assign({}, userState.user)
    Object.assign(userData.properties, userState.properties)
    Object.assign(userData.property_groups, userState.properties)

    const userRef = userState.firebaseRef
    const propertiesNewList = cartList.map((item) => item.uid)

    const groupId = uid(24)
    const group = {
      total_amount: getCartTotalAmount(cartList),
      properties: propertiesNewList,
      payment_info: null,
    }
    userData.property_groups[groupId] = group

    userRef.update(userData).then(() => {
      store.dispatch(setCurrentUser(userData))
      resolve(groupId)
    })
  })
}

const getPropertiesToPay = (cartList) => {
  return cartList.filter((property) => property.data().status !== 'paid')
}

const getCartTotalAmount = (cartList) => {
  const toPay = getPropertiesToPay(cartList)
  return toPay.reduce((total, current) => total + cartModel.getOccupationValue(current.data().occupation_type), 0)
}

const fetchPropertiesPending = (userId) => fetchPropertiesByStatus(userId, PROPERTY_STATUS_PENDING)

const fetchPropertiesByStatus = (userId, status) => {
  return propertyModel.getByStatus(userId, status)
}

const cartModel = {
  KEY_CART,
  saveToDatabase,
  saveLocal,
  loadLocal,
  loadFromLocalToStore,
  getOccupationValue,
  getPropertiesToPay,
  formatToCart,
  formatToCartFromState,
  getCartTotalAmount,
  fetchPropertiesPending,
  fetchPropertiesByStatus,
}

export default cartModel
