import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class InsuranceRepository extends BaseHttpRepository {
  async list(propertyId) {
    const resp = await this._api.get(`insurance/${propertyId}/list`)
    return resp
  }

  /**
   * @param {string} propertyId
   * @param {object} data
   * @param {string} data.policy_number
   * @param {string} data.type
   * @param {string} data.insurer
   * @param {string} data.amount
   * @param {Array<{
   *          created_at:string,
   *          name:string,
   *          name_original:string,
   *          path:string,
   *          download_url:string,
   *        }>} data.documents
   */
  async add(propertyId, data) {
    const resp = await this._api.post(`insurance/${propertyId}/add`, data)
    return resp
  }
}

export const insuranceRepoInstance = new InsuranceRepository()

// export const useInsuranceList = hookRunner(insuranceRepoInstance, 'list', [])
