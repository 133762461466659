/** @module progressChecker */
import {getCurrentStepFromState} from '@root/lib/contract/stepWalker'
import store from '@root/redux/store'
import contractMapper from 'status-mapper/mapper/contract-mapper'

/**
 * Foco maior e usar nos testes
 * @return {{updated_at: null, created_at: null}}
 */
export const createEmptyProgress = () => ({
  created_at: null,
  updated_at: null,
})

/**
 * @param {Object} property
 * @return {Object}
 */
const getProgressFromProperty = (property) => property.contract?.progress ?? {}

/**
 *
 * @param {Object} property
 * @param {string} field
 * @return {Object}
 */
const getProgressField = (property, field) => {
  const progress = getProgressFromProperty(property)
  return progress[field] ?? {}
}

/**
 *
 * @param {Object} property
 * @return {Object[]}
 */
export const getProgressAsList = (property) => Object.entries(getProgressFromProperty(property))

/**
 * @param {Object} property
 * @param {string} field
 * @return {boolean}
 */
export const checkProgress = (property, field) => {
  const progress = getProgressField(property, field)
  return !!progress.created_at
}

/**
 * @param {Object} property
 * @return {boolean}
 */
export const isProgressComplete = (property) => {
  return getProgressAsList(property).every(([field]) => checkProgress(property, field))
}

/**
 * @param {Object} property
 * @return {boolean}
 */
export const isProgressEmpty = (property) => {
  return getProgressAsList(property).every(([field]) => !checkProgress(property, field))
}

/**
 * @param {Object} property
 * @return {boolean}
 */
export const hasInitiatedContractCreation = (property) => {
  return getProgressAsList(property).some(([field]) => checkProgress(property, field))
}

export const createProgressData = (field) => {
  return {
    path: `contract.progress.${field}`,
    payload: createEmptyProgress(),
  }
}

export const updateProgressData = (property, field) => {
  const progress = getProgressField(property, field)
  const info = createProgressData(field)
  const date = new Date()

  info.payload.created_at = progress.created_at ?? date
  info.payload.updated_at = date

  return info
}

/**
 *
 * @param property
 * @return {{path: string, payload: {updated_at: null, created_at: null}}}
 */
export const updateProgressDataFromRedux = (property) => {
  const step = getCurrentStepFromState()
  if (step) {
    return updateProgressData(property, step.id)
  }
}

export const createProgressFromList = (fields) => {
  const progress = {}
  fields.forEach((field) => {
    progress[field] = createProgressData(field).payload
  })

  return progress
}

export const isContractEditable = (property, includeIsAdmin = true) => {
  const userReducer = store.getState().user

  const isManuallyEdited = !!property?.contract?.is_manually_edited
  const isAdmin = userReducer?.user?.user_role === 'admin'
  const contractStatus = property?.contract?.status

  const isEditable =
    (includeIsAdmin && isAdmin) || (!isManuallyEdited && contractMapper.assertStatus.isBuilding(contractStatus))
  return isEditable
}

export const isContractEditableNoAdmin = (property) => isContractEditable(property, false)

export const isContractBlocked = (property) => !isContractEditable(property)
