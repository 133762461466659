import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const defaults = {
  bold: false,
  italic: false,
  component: 'span',
}
const constraints = {
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

/**
 * @param props
 * @param overrides
 * @return {{comp: any, style: any}} comp are props to be passed to component, style are props exclusive to those custom typography
 */
export const extractProps = (props, overrides = {}) => {
  const {bold, italic, size, color, primary, secondary, style, ...remaingProps} = props
  return {
    style: {bold, italic, size, color, primary, secondary, style, ...overrides},
    comp: remaingProps,
  }
}
export default {
  defaults,
  constraints,
}
