import {Grid} from '@material-ui/core'
import {alternateFactory} from '@root/components/App/Login/signupHelper'
import FormItem from '@root/components/FormHelper/FormItem'
import useSignin from '@root/hooks/useSignin'
import {PROVIDER_CREDENTIALS, signIn} from '@root/lib/signIn'
import {validateEmail, validateFullName, validateMatch, validatePhone, validateRequired} from '@root/lib/simpleValidators'
import userModel from '@root/model/userModel'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {PHONE_FORMAT} from '@root/utils/constants'
import routes from '@root/utils/routes'

const EmailRegister = () => {
  const {control, errors, watch, getValues} = useFormContext()
  const {handleFocusBlurForLogger} = useSignin()

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'Nome', 'name')}
          control={control}
          errors={errors}
          fitContainer={true}
          label="Nome"
          name="name"
          rules={{
            validate: validateFullName,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'Telefone celular', 'phonenumber')}
          control={control}
          errors={errors}
          fitContainer={true}
          type="mask"
          label="Telefone celular"
          name="phonenumber"
          rules={{
            validate: validatePhone,
          }}
          maskInputProps={{
            format: PHONE_FORMAT,
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'E-Mail', 'email')}
          control={control}
          errors={errors}
          fitContainer={true}
          label="E-Mail"
          name="email"
          rules={{
            validate: validateEmail,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'Senha', 'password')}
          control={control}
          errors={errors}
          fitContainer={true}
          inputProps={{type: 'password'}}
          label="Senha"
          name="password"
          rules={{
            validate: validateRequired,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'Confirmação de senha', 'confirm_password')}
          control={control}
          errors={errors}
          fitContainer={true}
          inputProps={{type: 'password'}}
          label="Confirmação de senha"
          name="confirm_password"
          rules={{
            validate: (value) => validateMatch(value, getValues('password')),
          }}
        />
      </Grid>
    </>
  )
}

EmailRegister.propTypes = {}
EmailRegister.defaultProps = {}

EmailRegister.formData = {}
EmailRegister.isRegister = true
EmailRegister.isInUse = async () => {
  const form = EmailRegister.formData
  const isPhoneInUse = await userModel.getByPhone(form.phonenumber)

  const resp = {
    exist: false,
    message: '',
  }

  if (isPhoneInUse) {
    resp.exist = true
    resp.message = 'Este telefone já esta em uso'
  }
  return resp
}
EmailRegister.handler = async () => {
  const form = EmailRegister.formData
  const extra = {
    name: form.name,
    email: form.email,
    password: form.password,
    phone: form.phonenumber,
    create: true,
  }
  const authResult = await signIn(PROVIDER_CREDENTIALS, extra)
  return {
    auth: authResult,
    extra,
  }
}
EmailRegister.alternate = alternateFactory(EmailRegister.isRegister, routes.SIGNUP_SIGNIN_EMAIL)

export default EmailRegister
