import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import FormItem from '@root/components/FormHelper/FormItem'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import Text from '@root/components/Layout/Typography/Text'
import {validateRequired} from '@root/lib/simpleValidators'
import {readableValue} from '@root/lib/simpleValuesDisplayMap'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

const childCommonStyle = ({asTable}) => {
  return {
    display: 'flex',
    flex: asTable ? 1 : 'none',
    alignItems: 'center',
    lineHeight: 0,
    padding: '4px 0px',
  }
}

const useDataDisplayStyle = makeStyles((theme) => ({
  container: ({asTable}) => ({
    'margin': '2px 0',
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    '&:hover': {
      background: asTable ? theme.extraColors.STRIPE.dark : '',
    },
  }),
  labelContainer: childCommonStyle,
  valueContainer: childCommonStyle,
}))

const FIELD_NAME = 'inputValue'
const DataDisplay = ({label, value, readableType, asTable, onSave, onToggle, edit, formProps}) => {
  const displayClasses = useDataDisplayStyle({asTable})

  const {control, errors, handleSubmit} = useForm()
  const [isEditing, setEditing] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const currentValue = value
  const [toggleEnabled, setToggleValue] = useState(false)

  const isValidElement = React.isValidElement(value)
  const isEditable = edit?.path && onSave && !isValidElement

  const enableEdit = () => setEditing(true)
  const disableEdit = () => setEditing(false)

  const saveToDatabase = async (form) => {
    if (!onSave) {
      return
    }

    let formattedValue = form[FIELD_NAME]
    const rawValue = form[FIELD_NAME]

    setLoading(true)
    try {
      if (edit.preformat) {
        formattedValue = edit.preformat(formattedValue)
      }

      await onSave(rawValue, formattedValue, edit)
    } catch (err) {
      alert(err.message)
    }
    setLoading(false)
    disableEdit()
  }

  const handleToggleOnChange = async () => {
    if (!onToggle) {
      return
    }

    setLoading(true)

    try {
      await onToggle(null, !toggleEnabled, edit)
      setToggleValue(!toggleEnabled)
    } catch (err) {
      alert(err.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    setToggleValue(value)
  }, [value])

  return (
    <div className={displayClasses.container}>
      <span className={displayClasses.labelContainer}>
        <Text bold>{label}:</Text>&nbsp;
      </span>
      <span className={displayClasses.valueContainer}>
        {!isEditing &&
          !onToggle &&
          (isValidElement ? value : <Text>{readableValue(currentValue, readableType)} </Text>)}

        {isEditing && !onToggle && (
          <FormItem
            {...formProps}
            control={control}
            errors={errors}
            name={FIELD_NAME}
            rules={{validate: edit.validation}}
            defaultValue={currentValue}
          />
        )}

        {isEditable && !isEditing && !onToggle && (
          <IconWrapper>
            <EditIcon onClick={enableEdit} />
          </IconWrapper>
        )}

        {isEditing && !onToggle && (
          <>
            <IconWrapper bgType="red">
              <CancelIcon onClick={disableEdit} />
            </IconWrapper>
            <IconWrapper>
              {isLoading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <CheckCircleIcon onClick={handleSubmit(saveToDatabase)} />
              )}
            </IconWrapper>
          </>
        )}

        {onToggle && (
          <div style={{display: 'inline-flex', alignItems: 'center', marginLeft: 5}}>
            <span>Não</span>
            <Switch checked={toggleEnabled} name={FIELD_NAME} color="primary" onChange={handleToggleOnChange} />
            <span style={{marginRight: 10}}>Sim</span>
            {isLoading && <CircularProgress size={20} color="secondary" />}
          </div>
        )}
      </span>
    </div>
  )
}

DataDisplay.defaultProps = {
  readableType: 'default',
  asTable: false,
  edit: {
    validation: validateRequired,
  },
}

DataDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  readableType: PropTypes.oneOf(['money', 'date', 'phone', 'email', 'usertype', 'default']),
  asTable: PropTypes.bool,
  onSave: PropTypes.func,
  formProps: PropTypes.object,
  edit: PropTypes.shape({
    path: PropTypes.string,
    preformat: PropTypes.func,
    validation: PropTypes.func,
  }),
}

export default DataDisplay
