import {makeStyles, useTheme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {Box} from '@root/components/Layout/Container/Box/Box'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractReadjustmentIndexAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

import {firestore} from '../../../firebase/firebase.utils'
import {useStyles} from './styles'

const READJUSTMENT_INDEX_OPTIONS = [
  {
    label: 'IGP-M',
    value: 'igpm',
  },
  {
    label: 'IPCA',
    value: 'ipca',
  },
]

const createReadjustmentIndexInitialState = (indexType) => {
  return {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: ['___', '___', '___', '___', '___', '___', '___', '___', '___', '___', '___', '___'],
      },
      fill: {
        colors: ['#061BCD'],
      },
      yaxis: {
        title: {
          text: '% (porcertagem)',
        },
      },
      grid: {show: false},
    },
    series: [
      {
        name: indexType,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  }
}

const fetchReadjustmentIndexData = async (indexType) => {
  return firestore
    .collection(`application/indices/${indexType.toLowerCase().replace('-', '')}`)
    .orderBy('period', 'desc')
    .limit(12)
    .get()
    .then((response) => {
      return response.docs.reverse().reduce((obj, current) => {
        if (!obj.categories) {
          obj.categories = []
        }

        if (!obj.data) {
          obj.data = []
        }

        return {
          categories: [...obj.categories, `${current.data().month_name_short}/${current.data().year}`],
          data: [...obj.data, parseFloat(current.data().value_last_year).toFixed(1)],
        }
      }, {})
    })
}

export function ReadjustmentIndex() {
  const classes = useStyles()
  const history = useHistory()

  const theme = useTheme()
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'))
  const chartWidth = isSmallViewPort ? '340' : '650'

  const [showReajustmentIndexHistory, setShowReajustmentIndexHistory] = useState(false)
  const [igpm, setIgpm] = useState(createReadjustmentIndexInitialState(READJUSTMENT_INDEX_OPTIONS[0].label))
  const [ipca, setIpca] = useState(createReadjustmentIndexInitialState(READJUSTMENT_INDEX_OPTIONS[1].label))

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const readjustmentIndex = useSelector((s) => s.property.data?.contract?.readjustment_index)

  const {handleSubmit, control, errors, reset} = useForm()
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractReadjustmentIndexAction,
    setContractStepAction,
  })

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)
    try {
      const selectedReadjustmentIndex = formData.readjustment_index
      await propertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.readjustment_index.type': selectedReadjustmentIndex,
        }),
      )

      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const toggleShowReajustmentIndexHistory = () => setShowReajustmentIndexHistory(!showReajustmentIndexHistory)

  const loadReadjustment = async () => {
    const igpmResponseData = await fetchReadjustmentIndexData(READJUSTMENT_INDEX_OPTIONS[0].label)
    const ipcaResponseData = await fetchReadjustmentIndexData(READJUSTMENT_INDEX_OPTIONS[1].label)

    setIgpm({
      options: {
        ...igpm.options,
        xaxis: {
          categories: [...igpmResponseData.categories],
        },
      },
      series: [
        {
          name: READJUSTMENT_INDEX_OPTIONS[0].label,
          data: [...igpmResponseData.data],
        },
      ],
    })

    setIpca({
      options: {
        ...igpm.options,
        xaxis: {
          categories: [...ipcaResponseData.categories],
        },
      },
      series: [
        {
          name: READJUSTMENT_INDEX_OPTIONS[1].label,
          data: [...ipcaResponseData.data],
        },
      ],
    })
  }

  useEffect(() => {
    reset({
      readjustment_index: readjustmentIndex?.type ?? '',
    })
  }, [readjustmentIndex])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    loadReadjustment()
  }, [])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.reajustmentIndex}`}>
        <Typography variant="body2">
          Aqui você pode escolher qual índice deverá ser utilizado para o reajuste anual do valor do aluguel.
        </Typography>
        <Typography variant="body2">
          Este reajuste é garantido pela Lei do Inquilinato e pode ser definido em comum acordo entre as partes dentro
          do contrato de aluguel.
        </Typography>
        <Typography variant="body2">
          Historicamente, o IGPM é o índice mais utilizado nesta operação, contudo, durante a pandemia, chegou a bater
          mais de 30% em diversos meses e isso fez com que houvesse uma onda de solicitações de renegociação. <br />
          Muitos dos contratos que conseguiram manter o reajuste para cima utilizaram o IPCA.
        </Typography>
        <Typography variant="body2">
          Aqui na Moben, seguindo as melhores práticas de mercado, deixamos aberto para você escolher entre o IGP-M e o
          IPCA.
        </Typography>

        {!showReajustmentIndexHistory && (
          <div className={classes.verHistoricoBtnWrapper}>
            <Button noBg onClick={toggleShowReajustmentIndexHistory}>
              Ver histórico dos índices{' '}
            </Button>
          </div>
        )}

        {showReajustmentIndexHistory && (
          <>
            <div className={classes.chartWrapper}>
              <div>
                <Typography variant="body2">
                  O IGP-M, Índice Geral de Preços Mercado, é aferido mensalmente pelo Instituto Brasileiro de Economia
                  da Fundação Getúlio Vargas (FGV IBRE) e apura informações sobre a variação de preços do dia 21 do mês
                  anterior ao dia 20 do mês de coleta, ou mês de referência.
                </Typography>
                <Typography variant="body1" align="center">
                  <strong>IGP-M</strong>
                </Typography>
                <Chart options={igpm.options} series={igpm.series} type="bar" width={chartWidth} />
                <Typography variant="caption" className={classes.chartCaption}>
                  IGP-M variação acumulada em 12 meses
                </Typography>
              </div>

              <div>
                <Typography variant="body2">
                  O IPCA, Índice Nacional de Preços ao Consumidor Amplo (IPCA) é calculado mensalmente pelo Instituto
                  Brasileiro de Geografia e Estatística (IBGE) desde 1980. O indicador é considerado a inflação oficial
                  do país.
                </Typography>
                <Typography variant="body1" align="center">
                  <strong>IPCA</strong>
                </Typography>
                <Chart options={ipca.options} series={ipca.series} type="bar" width={chartWidth} />
                <Typography variant="caption" className={classes.chartCaption}>
                  IPCA variação acumulada em 12 meses
                </Typography>
              </div>
            </div>

            <div className={classes.verHistoricoBtnWrapper}>
              <Button noBg onClick={toggleShowReajustmentIndexHistory}>
                Fechar histórico dos índices{' '}
              </Button>
            </div>
          </>
        )}

        <Box bgType="gray" m="30px 0">
          <Typography variant="body2">
            Para calcular o reajuste basta multiplicar o valor do aluguel pelo indice+100%.
            <br />
            Exemplo: em um aluguel de R$ 1000 reajustado pelo IGPM de set/21 (24,86%) ficaria assim.
          </Typography>
          <br />
          <Typography variant="body2">
            Valor do aluguel: R$ 1.000 <br />
            IGPM: 24,86% <br />
            Novo Aluguel: R$ 1000 x 124,86% = R$ 1.284,86 <br />
          </Typography>
        </Box>

        <Typography variant="body1" align="center">
          {' '}
          Escolha uma das opções abaixo{' '}
        </Typography>

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="radio"
          name="readjustment_index"
          options={READJUSTMENT_INDEX_OPTIONS}
          rules={{validate: validateRequired}}
          extraStyles="row"
          style={{marginBottom: -30}}
        />
      </form>
      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
