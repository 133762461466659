import {SurveyActionTypes} from './survey.types'

export const setPropertyUIDForSurvey = (propertyUID) => ({
  type: SurveyActionTypes.SET_PROPERTY_UID_FOR_SURVEY,
  payload: propertyUID,
})

export const setSurveyScheduledFor = ({surveyType, date}) => ({
  type: SurveyActionTypes.SET_SURVEY_SCHEDULED_FOR,
  payload: {surveyType, date},
})

export const setSurveyCreatedAt = ({surveyType, date}) => ({
  type: SurveyActionTypes.SET_SURVEY_CREATED_AT,
  payload: {surveyType, date},
})

export const setSurveyServiceRequested = () => ({
  type: SurveyActionTypes.SET_SURVEY_SERVICE_REQUESTED,
})

export const setSurveyFromDatabase = (surveyObj) => ({
  type: SurveyActionTypes.SET_SURVEY_FROM_DATABASE,
  payload: surveyObj,
})

export const setSurveyImages = ({surveyType, imagesArr}) => ({
  type: SurveyActionTypes.SET_SURVEY_IMAGES,
  payload: {surveyType, imagesArr},
})
