import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getCreatedRoute, getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import {validateEmail, validateFullName} from '@root/lib/simpleValidators'
import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractStepAction, setContractWitnessesAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

import {useStyles} from './styles'

const WITNESSES_SIZE = 2
const witnessesListStatic = Array.from(new Array(WITNESSES_SIZE))
export function Witnesses() {
  const classes = useStyles()
  const history = useHistory()
  const {control, errors, handleSubmit, reset} = useForm()

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const contractPropertySnap = useSelector((s) => s.property.snap)
  const witnesses = useSelector((s) => s.property.data?.contract?.witnesses)
  const witnessesList = useMemo(() => witnesses ?? [], [witnesses])
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
    setContractWitnessesAction,
  })

  const handleContinue = async (formData) => {
    const {witnesses_0, witnesses_1} = formData
    const witnesses = [witnesses_0, witnesses_1]
    actions.setGlobalLoaderAction(true)
    try {
      await contractPropertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.witnesses': witnesses,
        }),
      )
      actions.setGlobalLoaderAction(false)
      let route = pathNextStepAfterSave()
      if (property?.contract?.contract_file) {
        route = getCreatedRoute()
      }
      history.push(route)
    } catch (ex) {
      alert(errorHandler(ex))
      actions.setGlobalLoaderAction(false)
    }
  }

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
    handleContractLoad(loadContract, user, history)
  }, [])

  useEffect(() => {
    reset({
      witnesses_0: witnessesList[0] ?? {},
      witnesses_1: witnessesList[1] ?? {},
    })
  }, [witnessesList])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.witnesses}`}>
        <Typography variant="body2">
          Para a validação do contrato você deve indicar duas testemunhas para assinarem digitalmente. Preencha os dados
          de quem você deseja que seja testemunha e cuidaremos de todo o resto.
        </Typography>
        <Typography variant="body2">
          Lembre-se de cadastrar um email válido, pois será nesse endereço que enviaremos o contrato para assinatura.
        </Typography>

        {witnessesListStatic.map((_, index) => (
          <section key={index}>
            <Typography variant="body1" className="bold">
              Testemunha {index + 1}
            </Typography>

            <FormItem
              required
              fitContainer
              control={control}
              errors={errors}
              type="textfield"
              name={`witnesses_${index}.name`}
              label="Nome Completo"
              rules={{validate: validateFullName}}
            />

            <FormItem
              required
              fitContainer
              control={control}
              errors={errors}
              type="textfield"
              name={`witnesses_${index}.email`}
              label="E-Mail"
              rules={{
                validate: validateEmail,
              }}
            />
          </section>
        ))}
      </form>

      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleContinue)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
