import Typography from '@material-ui/core/Typography'
import {ConfirmationTitle} from '@root/components/page/DefaultConfirmationPage/DefaultConfirmationPage'
import PropTypes from 'prop-types'
import React from 'react'

const InterestedAnalysisConsent = () => {
  return (
    <>
      <ConfirmationTitle />
      <Typography variant="body2">
        Agora você pode fechar a sua janela e aguardar os próximos passos. <br />
        Vamos avisá-lo em cada etapa através do seu e-mail cadastrado.
      </Typography>
    </>
  )
}

InterestedAnalysisConsent.propTypes = {}
InterestedAnalysisConsent.defaultProps = {}

export default InterestedAnalysisConsent
