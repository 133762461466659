import ProposalRepositoryInterface from '@root/application/domain/repository/ProposalRepositoryInterface'
import {firestore, forceArrayUnion} from '@root/firebase/firebase.utils'
import uidGenerator from '@root/lib/uidGenerator'

export default class FirestoreProposalRepository extends ProposalRepositoryInterface {
  _firestore = firestore
  _collection = 'property'

  async loadAll(proposals) {
    if (!proposals || typeof proposals === 'string') {
      return {}
    }

    const result = {}
    const loads = Object.entries(proposals)
      .map(async ([id, proposal]) => {
        const loaded = await this.loadSingle(id, proposal)
        result[id] = loaded
      })
      .flat(1)
    await Promise.all(loads)
    return result
  }

  async loadSingle(proposalId, proposalSingle) {
    let userSnap
    const result = {
      ...proposalSingle,
      interesteds: proposalSingle.interesteds.map((i) => ({...i})), // quando vem do redux da problema de object is not extensible
    }

    const loads = proposalSingle.interesteds.map(async (interested, index) => {
      userSnap = await interested.user.get()
      result.interesteds[index].data = userSnap.data()
    })
    await Promise.all(loads)
    return result
  }

  async createEmpty(propertyId, mode, name) {
    const proposalId = uidGenerator()
    const proposal = {
      analysis: false,
      created_at: new Date(),
      interesteds: [],
      name: name ?? null,
      mode: mode ?? 'default',
      id: proposalId,
    }

    await this._firestore
      .collection(this._collection)
      .doc(propertyId)
      .update({
        [`proposals.${proposalId}`]: proposal,
      })
    return proposal
  }
}
