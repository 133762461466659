import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {ANSWER_YES} from '@root/components/App/Contract/DefaultRadioGroup'
import FilePickerMultiple from '@root/components/FilePickerMultiple'
import FormItem from '@root/components/FormHelper/FormItem'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {validateRequired} from '@root/lib/simpleValidators'
import surveyModel from '@root/model/surveyModel'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setSurveyImages} from '@root/redux/survey/survey.actions'

const SURVEY_OPTIONS = [
  {
    label: 'Sim',
    value: 'yes',
  },
  {
    label: 'Não',
    value: 'no',
  },
]

const useStyles = makeStyles((theme) => ({
  surveyHandlerRoot: {margin: '30px 0 50px'},
  anexarArquivoWrapper: {
    position: 'relative',
  },
  anexarArquivoFile: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  uploadContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBtnWrapper: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'width': '100%',
    'marginBottom': '20px',
    'background': 'transparent',
    'cursor': 'pointer',
    '& span': {
      color: 'rgb(1, 1, 205) !important',
      textDecoration: 'underline',
      fontSize: '15px',
    },
  },
  documentNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  documentName: {
    maxWidth: '270px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: `${theme.extraColors.BLACK} !important`,
    textDecoration: 'underline !important',
  },
  surveyImagesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const SurveyHandler = ({control, errors, reset, isContract = false, id}) => {
  const classes = useStyles()
  const survey = useSelector((s) => s.survey)
  const contractSurvey = useSelector((s) => s.property.data?.contract?.survey)
  const propertySnap = useSelector((s) => s.property.snap)
  const property = useSelector((s) => s.property.data)

  const {prepareContractStepUpdate} = useContract(property)

  const surveyType = 'entering'

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setSurveyImages,
  })

  const handleHasSurveyOnChange = async (evt) => {
    actions.setGlobalLoaderAction(true)
    const hasSurvey = evt.target.value === ANSWER_YES
    try {
      const surveyPayload = {
        ...contractSurvey,
        has_survey: hasSurvey,
      }

      if (isContract) {
        await propertySnap.ref.update(prepareContractStepUpdate({'contract.survey': surveyPayload}))
      } else {
        await propertySnap.ref.update({'contract.survey': surveyPayload})
      }

      actions.setGlobalLoaderAction(false)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const onChangeFileCallback = async (uploadedFiles) => {
    const updatedSurvey = await surveyModel.createOrUpdateSurvey(
      {
        surveyType,
        images: [...survey?.entering?.images, ...uploadedFiles],
      },
      property?.uid,
    )
    actions.setSurveyImages({surveyType, imagesArr: updatedSurvey.entering.images})
  }

  const onDeleteFileCallback = async (url) => {
    const filteredSurveyImagesArr = survey?.entering?.images.filter((doc) => doc.download_url !== url)

    actions.setSurveyImages({surveyType, imagesArr: filteredSurveyImagesArr})

    await surveyModel.createOrUpdateSurvey(
      {
        surveyType: 'entering',
        images: filteredSurveyImagesArr,
      },
      property?.uid,
    )
  }

  useEffect(() => {
    if (contractSurvey) {
      reset({survey: contractSurvey.has_survey === true ? 'yes' : 'no'})
    }
  }, [contractSurvey])

  useEffect(() => {
    if (property?.uid) {
      surveyModel.getSurvey(property.uid).then((response) => {
        if (response) {
          actions.setSurveyImages({surveyType, imagesArr: response[surveyType].images})
        } else {
          actions.setSurveyImages({surveyType, imagesArr: []})
        }
      })
    }
  }, [property])

  return (
    <div className={classes.surveyHandlerRoot}>
      <Typography variant="body1" align="center" className="bold">
        Foram (ou serão) realizadas vistorias de entrada ou saída no imóvel?
      </Typography>

      <FormItem
        onChange={handleHasSurveyOnChange}
        fitContainer
        control={control}
        errors={errors}
        type="radio"
        name="survey"
        id="survey_options"
        options={SURVEY_OPTIONS}
        rules={{validate: validateRequired}}
        extraStyles="row"
      />

      <FilePickerMultiple
        label="Carregar Documentos"
        uid={property?.uid}
        filePrefix="survey_"
        section="property/survey"
        list={survey?.entering?.images}
        displayLinks
        id={id}
        onChangeFileCallback={onChangeFileCallback}
        onDeleteFileCallback={onDeleteFileCallback}
      />
    </div>
  )
}
