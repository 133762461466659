import DataDisplay from '@root/components/App/Admin/DataDisplay'
import IconPencil from '@root/components/icons/IconPencil'
import Text from '@root/components/Layout/Typography/Text'
import {useStyles} from '@root/components/YourProperty/styles'
import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import {translate} from '@root/lib/intl/translations'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import specsDisplay from '@root/lib/specsDisplay'
import {getBillsIncludedInRent, getTotalRentFromPropertyOrRentfees} from '@root/utils/rentfeesCalc'
import routes, {routeWithID} from '@root/utils/routes'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {IconWrapper} from '../IconWrapper/IconWrapper'

export default function Property({property}) {
  const classes = useStyles()
  const history = useHistory()

  const propertyData = property.data
  const billsIncludedInRent = () => {
    const bills = getBillsIncludedInRent(propertyData?.contract?.rentfees, false)
      .map((bill) => bill.toLowerCase())
      .join(', ')

    return bills !== '' ? `(inclui ${bills})` : ''
  }

  const handleOnPropertyRegisterClick = () => {
    history.push(routeWithID(routes.REGISTER_PROPERTY_EDIT, propertyData.uid))
  }

  return (
    <div className={classes.property}>
      <Text>{addressDisplay(propertyData.address, ADDRESS_VERBOSITY_MINIMAL)}</Text>
      <DataDisplay label="Descrição" value={specsDisplay(propertyData)} />
      <DataDisplay label="Imóvel mobiliado" value={translate(propertyData?.more_about?.furnished)} />
      <DataDisplay label="Finalidade" value={translate(propertyData?.occupation_sector)} />
      <DataDisplay label="Animais permitidos" value={translate(propertyData?.more_about?.pets)} />
      <DataDisplay
        label="Valor"
        value={formatFloatToBrCurrency(getTotalRentFromPropertyOrRentfees(propertyData)) + ' ' + billsIncludedInRent()}
      />

      <div className={classes.propertyEditIconWrapper}>
        <IconWrapper onClick={handleOnPropertyRegisterClick}>
          <IconPencil />
        </IconWrapper>
      </div>
    </div>
  )
}
