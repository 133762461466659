/*eslint-disable react/prop-types */
import {Button as MButton, Grid} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import UserAvatar from '@root/components/UserAvatar'
import React from 'react'

import {Button} from '../Button/Button'
import useStyles from './styles'

const proposalPaperProps = {
  borderRadius: '20px',
  width: '376px',
  height: '461px',
  display: 'flex',
  flexDirection: 'column',
}

const creditAnalysisPaperProps = {
  borderRadius: '20px',
  width: '376px',
  height: '460px',
  display: 'flex',
  flexDirection: 'column',
}

export function Modal({onClose, handleClose, open, children, ...rest}) {
  return (
    <Dialog
      open={open}
      onClose={onClose || handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{
        style: {
          background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
        },
      }}
      {...rest}>
      {children}
    </Dialog>
  )
}

export function ModalAcceptProposal({open, onClose, onConfirm, ...rest}) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: proposalPaperProps,
      }}
      BackdropProps={{
        style: {
          background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
        },
      }}
      {...rest}>
      <DialogTitle className={classes.title}>
        <Typography variant="h5" color="secondary" align="center">
          Confirmação de escolha da proposta
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.textInfo}>
          <Typography variant="body2" align="center">
            Parabéns! Você está a um passo de escolher o seu futuro inquilino!
          </Typography>
          <br />
          <Typography variant="body2" align="center">
            Ao confirmar a escolha desta proposta, este inquilino estará pronto para ser incluído no contrato.
          </Typography>
        </div>
      </DialogContent>

      <DialogActions className={classes.buttonsContainer}>
        <Button fz="15px" h="48px" onClick={onConfirm}>
          Escolher proposta e seguir para contrato
        </Button>
        <Button fz="15px" h="48px" noBg onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ModalRequestCreditAnalysis({open, onClose, onConfirm, interesteds, ...rest}) {
  const classes = useStyles()
  const hasInteresteds = interesteds !== undefined && interesteds.length > 0

  const UsersList = ({users, label}) => {
    if (users.length === 0) {
      return null
    }
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <TextSecondary>{label}</TextSecondary>
        </Grid>
        {users.map((user) => (
          <Grid key={user.uid} container item direction="row" justify="flex-start" alignContent="center">
            <Grid item xs={3} sm={2} alignContent="center">
              <UserAvatar style={{width: 30, height: 30, margin: '10px'}} user={user} className={classes.avatarPhoto} />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Text>{user.name}</Text>
              <br />
              <Text>CPF: {user.cpf}</Text>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }

  if (!hasInteresteds) {
    return null
  }

  const tenants = []
  const fiadores = []

  interesteds.forEach((user) => {
    if (user.type === 'tenant') {
      tenants.push(user)
    } else if (user.type === 'fiador') {
      fiadores.push(user)
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: creditAnalysisPaperProps,
      }}
      BackdropProps={{
        style: {
          background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
        },
      }}
      {...rest}>
      <DialogTitle className={classes.title}>
        <Typography variant="h5" color="secondary" align="center">
          Solicitar análise de crédito
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.textInfo}>
          <Typography variant="body2" align="center">
            A análise de crédito é realizada por CPF e você pode realizar até 10 análises por imóvel.
          </Typography>
        </div>

        <UsersList users={tenants} label="Inquilino(s)" />
        <UsersList users={fiadores} label="Fiadore(s)" />
      </DialogContent>

      <DialogActions className={classes.buttonsContainer}>
        <Button fz="15px" h="48px" onClick={onConfirm}>
          Confirmar análise
        </Button>
        <Button fz="15px" h="48px" noBg onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ModalConsentCreditAnalysis({open, onClose, ...rest}) {
  const classes = useStyles()

  if (!open) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: creditAnalysisPaperProps,
      }}
      BackdropProps={{
        style: {
          background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
        },
      }}
      {...rest}>
      <DialogTitle className={classes.title}>
        <Typography variant="h5" color="secondary" align="center">
          Aguardando liberação de dados do inquilino
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.textInfo}>
          <TextBlock component="p">
            Todos os dados que são cadastrados na Moben devem ter autorização dos usuários para que possam ser
            utilizados.
            <br />
            <br />
          </TextBlock>

          <TextBlock component="p">
            Dessa forma, no momento em que você cadastrou os dados do inquilino um email foi enviado para que ele possa
            aceitar os termos e condições e política de privacidade da Moben. Assim garantimos estar dentro da Lei Geral
            de Proteção de Dados.
            <br />
            <br />
          </TextBlock>

          <TextBlock component="p">
            Quando o inquilino aceitar os termos você será avisado.
            <br />
            <br />
          </TextBlock>
        </div>
      </DialogContent>

      <DialogActions className={classes.buttonsContainer}>
        <Button fz="15px" h="48px" noBg onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ModalAnalysisResult({onClose, onConfirm, analysisItem, ...rest}) {
  const classes = useStyles()

  // console.log(field, value, config)
  if (!analysisItem) {
    return null
  }
  const {code, icon, label, hex, accent, color, prop_name, reason} = analysisItem

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: creditAnalysisPaperProps,
      }}
      BackdropProps={{
        style: {
          background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
        },
      }}
      {...rest}>
      <DialogTitle
        style={{
          background: `${hex}`,
        }}
        className={classes.modalAnalysisResultTitle}>
        <icon color={accent} />
        <Typography variant="h5" align="center" style={{color: accent}}>
          {label}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.analysisContent}>
        <Typography variant="body" align="center">
          {reason}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.analysisActionsContainer}>
        <MButton className={classes.analysisActionButton} onClick={onConfirm}>
          OK
        </MButton>
      </DialogActions>
    </Dialog>
  )
}
