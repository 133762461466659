import {objectToQueryString} from '@root/application/infra/api/fetcher'
import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class ProposalRepository extends BaseHttpRepository {
  async list(propertyId) {
    return this._api.get(`proposal/${propertyId}`)
  }

  async single(propertyId, proposalId) {
    return this._api.get(`proposal/${propertyId}/proposal/${proposalId}`)
  }
}

export const proposalRepoInstance = new ProposalRepository()

/**
 * @param {string} propertyId
 * @see {@link PropertyRepository.services}
 * @type {HookRunnerResult}
 */
export const useProposalList = hookRunner(proposalRepoInstance, 'list')

/**
 * @param {string} propertyId
 * @param {string} proposalId
 * @see {@link PropertyRepository.services}
 * @type {HookRunnerResult}
 */
export const useProposalSingle = hookRunner(proposalRepoInstance, 'single')
