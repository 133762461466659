import Grid from '@material-ui/core/Grid'
import InviteForm from '@root/components/Forms/InviteForm'
import React from 'react'
import {useSelector} from 'react-redux'

const GuaranteeTypeFiador = ({extraDispatch, fiador}) => {
  // const property = useSelector((s) => s.contract.property) ?? {}
  return (
    <>
      <Grid
        component="label"
        container
        alignItems="flex-start"
        spacing={1}
        direction="column"
        style={{marginBottom: 12}}>
        <InviteForm
          allowFullEdit={true}
          displayUploads={true}
          requireIdentityDoc={false}
          requireIncomeDoc={false}
          dispatch={extraDispatch}
          user={fiador}
        />
      </Grid>
    </>
  )
}

export default GuaranteeTypeFiador
