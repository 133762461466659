import analyticsLogger from '@root/firebase/analytics.utils'
import {firestore} from '@root/firebase/firebase.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import {clearBackLink, retriveBackLink} from '@root/lib/backToLink'
import {discordNotifyUserRegister} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import {extractPropertyId, hasInviteStored, invitePathHasFiador, invitePathHasTenant} from '@root/lib/invitePath'
import {simplePhoneFormatter} from '@root/lib/simplePhoneFormatter'
import userModel from '@root/model/userModel'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {setRegisterFormAction} from '@root/redux/signin/signin.actions'
import {createEmptyTenant} from '@root/redux/tenantRegister/tenantRegister.reducer'
import {setCurrentUser, setUserFromFirebaseAction} from '@root/redux/user/user.actions'
import routes from '@root/utils/routes'

const useSignin = () => {
  const history = useHistory()
  const [signinError, setSigninError] = useState({isOpen: false})
  const registerForm = useSelector((s) => s.signin.register_form)

  const actions = actionDispatcher({
    setUserFromFirebaseAction,
    setGlobalLoaderAction,
    setRegisterFormAction,
  })

  const handleEvents = (event_type, alternative_event_name, button_name) => {
    analyticsLogger.logEvent(event_type, {
      alternative_event_name,
      button_name,
    })
  }

  const handleFocusBlurForLogger = (watch, label, inputName, alternativeName) => {
    const fildOutType = watch(inputName) === '' ? 'clear' : 'with_content'
    return {
      onFocus: () => handleEvents('input_click', alternativeName, label),
      onBlur: () => handleEvents(`input_out_${fildOutType}`, alternativeName, label),
    }
  }

  const handleUserCreation = async (authUser, extra = {}) => {
    const extraData = {...registerForm, ...extra}
    const hasInvite = hasInviteStored()
    const invitePath = retriveBackLink()
    const propertyId = extractPropertyId(hasInvite ? invitePath : '')

    const userDocRef = firestore.collection(userModel.COLLECTION_NAME).doc(authUser.uid)
    let userSnap = await userDocRef.get()
    let user = userSnap.data()
    let isFirstLogin = false
    const isTenant = invitePathHasTenant(invitePath)
    const isFiador = invitePathHasFiador(invitePath)
    const isOwner = !isTenant && !isFiador

    if (!userSnap.exists) {
      isFirstLogin = true
      user = createEmptyTenant()
      user.uid = authUser.uid
      user.name = authUser.displayName || extraData.name || ''
      user.email = authUser.email || extraData.email || ''
      user.phone = simplePhoneFormatter((authUser.phoneNumber || extraData.phone || '').replace(/^\+55/, ''))
      user.photoURL = authUser.photoURL
      user.reward_code = extraData.reward_code ?? ''
      user.created_at = new Date()
      user.register_by = 'self'
      user.is_owner = isOwner
      user.is_tenant = isTenant
      user.is_fiador = isFiador
      user.tenant_invite_link.path = invitePath
      user.auth_provider = authUser.providerData[0].providerId
      user.terms_accepted = {
        register: {
          status: 'accepted',
          created_at: new Date(),
        },
      }
      await userDocRef.set(user)
    } else if (hasInvite) {
      const updatePayload = {
        'tenant_invite_link.path': invitePath,
      }

      if (isTenant) {
        updatePayload.is_tenant = true
        user.is_tenant = true // isso aqui talvez nem precise mais
      } else if (isFiador) {
        updatePayload.is_fiador = true
        user.is_fiador = true // isso aqui talvez nem precise mais
      }

      await userDocRef.update(updatePayload)
      user.tenant_invite_link = {
        path: invitePath,
      }
    }

    analyticsLogger.setUserID(user.uid)
    return {
      user,
      userRef: userDocRef,
      isFirstLogin,
    }
  }

  const handlePostSigninSuccess = (creationPayload) => {
    analyticsLogger.logEvent('sign_up')

    if (creationPayload.isFirstLogin) {
      analyticsLogger.logEvent('primeiro_login')
    }

    actions.setUserFromFirebaseAction(creationPayload.user, creationPayload.userRef)
    actions.setGlobalLoaderAction(false)
    actions.setRegisterFormAction({})
    handlePostLoginRedirect(creationPayload)
    // setTimeout(() => actions.setRegisterFormAction({}), 5000)
  }

  const handlePostSigninFail = (ex) => {
    let message = errorHandler(ex, 'Houve um erro ao registrar.')

    if (ex.humanMessage) {
      message = ex.humanMessage
    }

    actions.setGlobalLoaderAction(false)
    analyticsLogger.logEvent('sign_up_error', {message})
    analyticsLogger.logEvent('login_erro', {message})

    setSigninError({
      humanMessage: message,
      error: ex,
      isOpen: true,
    })
  }

  const handlePostLoginRedirect = (creationPayload) => {
    const loggedUser = creationPayload.user
    const isFirstLogin = creationPayload.isFirstLogin
    const backLinkPath = retriveBackLink()

    let path = backLinkPath ?? routes.DASHBOARD_ROOT

    if (hasInviteStored() && loggedUser.is_tenant) {
      if (loggedUser.tenant_concluded) {
        path = routes.DASHBOARD_TENANT_PROPERTY_LIST
      } /* else if (loggedUser.tenant_invite_link?.path) {
        path = loggedUser.tenant_invite_link.path
      }*/
    } else if (isFirstLogin) {
      path = routes.DASHBOARD_INIT
    }
    clearBackLink()
    history.replace(path)
  }

  return {
    handleUserCreation,
    handlePostSigninSuccess,
    handlePostSigninFail,
    handlePostLoginRedirect,

    handleFocusBlurForLogger,

    setSigninError,
    signinError,
  }
}

export default useSignin
