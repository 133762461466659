import {makeStyles, useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppContainer from '@root/AppContainer'
import Admin from '@root/components/App/Admin/Admin'
import TenantFiadorRegister from '@root/components/App/Cadastro/cadastro-components/TenantFiadorRegister/TenantFiadorRegister'
import CreditCard from '@root/components/App/CreditCard/CreditCard'
import AssistenciaJuridica from '@root/components/App/Dashboard/DashboardPages/AssistenciaJuridica/AssistenciaJuridica'
import Boletos from '@root/components/App/Dashboard/DashboardPages/Boletos/Boletos'
import Construction from '@root/components/App/Dashboard/DashboardPages/Construction'
import ControleStatus from '@root/components/App/Dashboard/DashboardPages/ControlePage/ControleStatus'
import DivulgationPage from '@root/components/App/Dashboard/DashboardPages/DivulgationPage/DivulgationPage'
import FotosPage from '@root/components/App/Dashboard/DashboardPages/FotosPage/FotosPage'
import IRPF from '@root/components/App/Dashboard/DashboardPages/IRPF/IRPF'
import Reparos from '@root/components/App/Dashboard/DashboardPages/Reparos/Reparos'
import SeguroFianca from '@root/components/App/Dashboard/DashboardPages/SeguroFianca/SeguroFianca'
import SeguroIncendio from '@root/components/App/Dashboard/DashboardPages/SeguroIncendio/SeguroIncendio'
import StatusAluguelPage from '@root/components/App/Dashboard/DashboardPages/StatusAluguelPage/StatusAluguelPage'
import SurveyPage from '@root/components/App/Dashboard/DashboardPages/SurveyPage/SurveyPage'
import ProposalList from '@root/components/App/Dashboard/Proposals/ProposalList'
import ProposalOptions from '@root/components/App/Dashboard/Proposals/ProposalOptions'
import ProposalsRegister from '@root/components/App/Dashboard/Proposals/ProposalsRegister'
import ProposalView from '@root/components/App/Dashboard/Proposals/ProposalView'
import EmailRegister from '@root/components/App/Login/forms/EmailRegister'
import EmailSignin from '@root/components/App/Login/forms/EmailSignin'
import PhoneRegister from '@root/components/App/Login/forms/PhoneRegister'
import PhoneSignin from '@root/components/App/Login/forms/PhoneSignin'
import RecoverPassword from '@root/components/App/Login/RecoverPassword'
import ResetPassword from '@root/components/App/Login/ResetPassword'
import Signup from '@root/components/App/Login/Signup'
import SignupOptions from '@root/components/App/Login/SignupOptions'
import VerificationCode from '@root/components/App/Login/VerificationCode'
import PreDashboard from '@root/components/App/PreDashboard/PreDashboard'
import PrivacyPolicy from '@root/components/App/PrivacyPolicy/PrivacyPolicy'
import MainProfilePage from '@root/components/App/Profile/MainProfilePage'
import Receipts from '@root/components/App/Receipts/Receipts'
import TenantDashboardRouter from '@root/components/App/TenantDashboard/Router/TenantDashboardRouter'
import {ContractIndex} from '@root/components/Contract/ContractIndex'
import {ContractLandingPage} from '@root/components/Contract/ContractLandingPage'
import TenantApprovalLogin from '@root/components/Contract/TenantApproval/TenantApprovalLogin'
import TenantApprovalObservation from '@root/components/Contract/TenantApproval/TenantApprovalObservation'
import TenantApprovalSummary from '@root/components/Contract/TenantApproval/TenantApprovalSummary'
import FirebaseActionDispatch from '@root/components/FirebaseActionDispatch'
import {FrequentAskedQuestionsPage} from '@root/components/FrequentAskedQuestions/FrequentAskedQuestionsPage'
import Guide1 from '@root/components/Guide1'
import {Header} from '@root/components/Header/Header'
import ExtraInformation from '@root/components/InviteRegister/ExtraInformation'
import FinancialObligations from '@root/components/InviteRegister/FinancialObligations'
import Invite from '@root/components/InviteRegister/Invite'
import RegisterIncome from '@root/components/InviteRegister/RegisterIncome'
import Loader from '@root/components/Loader/Loader'
import {Navbar} from '@root/components/Navbar/Navbar'
import NotFound from '@root/components/NotFound'
import DefaultConfirmationPage from '@root/components/page/DefaultConfirmationPage/DefaultConfirmationPage'
import {Home} from '@root/components/page/Home/Home'
import HowWorkPage from '@root/components/page/HowWorkPage'
import MarkAsPaid from '@root/components/page/MarkAsPaid/MarkAsPaid'
import PropertyAdForm from '@root/components/page/PropertyAd/PropertyAdForm'
import Playground from '@root/components/Playground'
import {PropertyList} from '@root/components/PropertyList/PropertyList'
import {PropertyRegister} from '@root/components/PropertyRegister/PropertyRegister'
import {SendInvite} from '@root/components/SendInvite/SendInvite'
import CreatePayments from '@root/components/YourProperty/sections/SectionPayment/CreatePayments'
import {PaymentDetail} from '@root/components/YourProperty/sections/SectionPayment/PaymentDetail'
import {Payments} from '@root/components/YourProperty/sections/SectionPayment/Payments'
import {YourProperty} from '@root/components/YourProperty/YourProperty'
import CookiesAccept from '@root/CookiesAccept'
import contractStepsMap from '@root/lib/contract/contractStepsMap'
import AdLeads from '@root/pages/AdLeads/AdLeads'
import BankStatement from '@root/pages/BankStatement/BankStatement'
import CalculatorReadjustment from '@root/pages/Calculator/Readjustment/CalculatorReadjustment'
import Renew from '@root/pages/dashboard/Renew/Renew'
import DocumentArea from '@root/pages/DocumentArea/DocumentArea'
import FaleConosco from '@root/pages/FaleConosco/FaleConosco'
import InquilinoPage from '@root/pages/InquilinoPage/InquilinoPage'
import PaymentView from '@root/pages/Payment/PaymentView'
import PaymentsListMonth from '@root/pages/PaymentsList/Month/PaymentsListMonth'
import {TenantContractDetails} from '@root/pages/TenantContractDetails/TenantContractDetails'
import {TenantPaymentList} from '@root/pages/TenantPaymentList/TenantPaymentList'
import {TenantProperty} from '@root/pages/TenantProperty/TenantProperty'
import {TenantPropertyList} from '@root/pages/TenantPropertyList/TenantPropertyList'
import routes, {routeWithParams} from '@root/utils/routes'
import React, {useLayoutEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {BrowserRouter, Route, Switch} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    'marginTop': (styleProps) => (styleProps.isHeaderless ? 0 : 89),
    'position': 'relative',
    'marginLeft': 0,
    '& > div': {padding: (styleProps) => (styleProps.isRoot || !styleProps.useMainPadding ? 0 : theme.padding.main)},
    '& > div > :not(nav)': {
      maxWidth: (styleProps) => (styleProps.isDashboardProperty ? 1126 : styleProps.isRoot ? '100%' : 1126),
      margin: (styleProps) => (styleProps.isRoot ? 0 : '150px auto 40px'),
      // padding: (styleProps) => (styleProps.isRoot ? 0 : '20px 10px'),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: (styleProps) => (styleProps.isRoot || styleProps.isHeaderless ? 0 : 239),
    },
    [theme.breakpoints.down('sm')]: {
      'marginTop': (styleProps) => (styleProps.isHeaderless ? 0 : 68),
      '& > div > :not(nav)': {
        margin: (styleProps) => (styleProps.isRoot ? 0 : '129px auto 40px'),
      },
    },
  },
}))

const App = () => {
  const isGlobalLoading = useSelector((s) => s.globalLoader.loading)
  const useMainPadding = useSelector((s) => s.application.useMainPadding)
  const navbar = useSelector((s) => s.application.navbar)
  const isRoot = useSelector((s) => s.application.routeInfo.isRoot)
  const isDashboardProperty = useSelector((s) => s.application.routeInfo.isDashboardProperty)
  const isHeaderless = useSelector((s) => s.application.routeInfo.isHeaderless)
  const mainRef = useRef()
  const [mainSize, setMainSize] = useState(0)
  const theme = useTheme()
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'))

  const styleProps = {isRoot, isDashboardProperty, isHeaderless, useMainPadding}

  const classes = useStyles(styleProps)

  useLayoutEffect(() => {
    const refCurrent = mainRef.current

    const listener = () => {
      setMainSize(refCurrent.clientWidth)
    }

    if (mainRef.current) {
      if (mainSize === 0) setMainSize(refCurrent.clientWidth)
      window.addEventListener('resize', listener)
    }
    return () => window.removeEventListener('resize', listener)
  })

  const renderNavBar = () =>
    navbar.title && !isRoot && !isHeaderless ? (
      <Navbar
        styles={{
          position: 'fixed',
          top: isSmallViewPort ? 68 : 89,
          width: isSmallViewPort ? '100%' : 'calc(100% - 239px)',
          zIndex: 2,
          maxWidth: mainSize,
        }}>
        {navbar.title}
      </Navbar>
    ) : null

  return (
    <Loader loading={isGlobalLoading}>
      <BrowserRouter>
        <AppContainer>
          <Header />
          <main className={classes.root} ref={mainRef}>
            {renderNavBar()}
            <div>
              <Switch>
                {/*<Route path="/blog" component={BlogRedirect} />*/}
                <Route exact path={routes.PLAYGROUND} component={Playground} />
                <Route exact path={routes.DASHBOARD_DOCUMENT_AREA} component={DocumentArea} />
                <Route exact path={routes.DASHBOARD_TENANT_DOCUMENT_AREA} component={DocumentArea} />
                <Route exact path={routes.ROOT} component={Home} />

                <Route exact path={routes.TALK_TO_US} component={FaleConosco} />

                <Route exact path={routes.PRIVACY_POLICIES} component={PrivacyPolicy} />
                <Route exact path={routes.MYDATA_PROFILE} component={MainProfilePage} />
                <Route exact path={routes.MYDATA_PROFILE_ADD_CARD} component={CreditCard} />
                <Route exact path={routes.RECEIPTS} component={Receipts} />
                <Route exact path={routes.TENANT} component={InquilinoPage} />
                <Route exact path={routes.SURVEY} component={SurveyPage} />
                <Route exact path={routes.UNDER_CONSTRUCTION} component={Construction} />

                <Route
                  exact
                  path={routeWithParams(routes.DEFAULT_CONFIRMATION, ':page')}
                  component={DefaultConfirmationPage}
                />

                {/*--------------------------
                  *-----   CADASTRO    -----*
                  --------------------------*/}
                <Route exact path={routes.EXPERIMENTE}>
                  <Signup />
                </Route>
                <Route exact path={routes.SIGNUP_REGISTER_EMAIL}>
                  <Signup formComp={EmailRegister} />
                </Route>
                <Route exact path={routes.SIGNUP_SIGNIN_EMAIL}>
                  <Signup formComp={EmailSignin} />
                </Route>
                <Route exact path={routes.SIGNUP_REGISTER_PHONE}>
                  <Signup formComp={PhoneRegister} />
                </Route>
                <Route exact path={routes.SIGNUP_SIGNIN_PHONE}>
                  <Signup formComp={PhoneSignin} />
                </Route>

                <Route exact path={routes.SIGNUP_REGISTER_OPTIONS}>
                  <SignupOptions isRegister={true} />
                </Route>
                <Route exact path={routes.SIGNUP_SIGNIN_OPTIONS}>
                  <SignupOptions isSignin={true} />
                </Route>

                <Route exact path={routes.SIGNUP_VERIFICATION_CODE} component={VerificationCode} />
                <Route exact path={routes.SIGNUP_SIGNIN_MAIL_RECOVER} component={RecoverPassword} />
                <Route exact path={routes.SIGNUP_RESET_PASS} component={ResetPassword} />
                <Route exact path={routes.FIREBASE_ACTION_DISPATCHER} component={FirebaseActionDispatch} />

                <Route
                  exact
                  path={routeWithParams(
                    routes.REGISTER_INVITE_ENTRY,
                    ':inviteType',
                    ':propertyId',
                    ':creditAnalysis?',
                    ':proposalId?',
                  )}
                  component={Invite}
                />
                <Route exact path={routes.REGISTER_MYDATA_INCOME} component={RegisterIncome} />
                <Route exact path={routes.REGISTER_MYDATA_FINANCIAL_OBLIGATIONS} component={FinancialObligations} />
                <Route exact path={routes.REGISTER_MYDATA_EXTRA_DATA} component={ExtraInformation} />
                <Route exact path={routes.REGISTER_PROPERTY_NEW} component={PropertyRegister} />
                <Route exact path={routes.REGISTER_PROPERTY_EDIT} component={PropertyRegister} />
                <Route
                  exact
                  path={routeWithParams(routes.REGISTER_TENANT_FIADOR, ':propertyId', ':creditAnalysis', ':proposalId')}
                  component={TenantFiadorRegister}
                />

                {/*--------------------------
                  *-----   CONTRATO    -----*
                  --------------------------*/}
                <Route exact path={routes.CONTRACT_ENTRY} component={ContractLandingPage} />
                <Route exact path={routes.CONTRACT_STEPS_LIST} component={ContractIndex} />
                {contractStepsMap.map((step, idx) => (
                  <Route
                    key={idx}
                    exact
                    path={step.path_orig}
                    render={(props) => <step.component classes={classes} {...props} />}
                  />
                ))}
                <Route exact path={routes.CONTRACT_APPROVAL} component={TenantApprovalLogin} />
                <Route exact path={routes.CONTRACT_APPROVAL_OBSERVATION} component={TenantApprovalObservation} />
                <Route exact path={routes.CONTRACT_APPROVAL_SUMMARY} component={TenantApprovalSummary} />
                <Route exact path={routes.CONTRACT_APPROVAL_DONE} component={Signup} />

                {/*--------------------------
                    *-----   DASHBOARD   -----*
                    --------------------------*/}
                <Route path={routes.DASHBOARD_PROPOSALS_INVITE} component={SendInvite} />
                <Route path={routes.DASHBOARD_DECISION} component={PreDashboard} />
                <Route path={routes.DASHBOARD_TENANT_ROOT} component={TenantDashboardRouter} />
                <Route exact path={routes.FAQ} component={FrequentAskedQuestionsPage} />
                <Route exact path={routes.DASHBOARD_ROOT} component={YourProperty} />
                <Route exact path={routes.TUTORIAL} component={Guide1} />
                <Route exact path={routes.DASHBOARD_INIT} component={HowWorkPage} />
                <Route exact path={routes.DASHBOARD_REPAIRS} component={Reparos} />
                <Route exact path={routes.DASHBOARD_CONTROL_STATUS} component={ControleStatus} />
                <Route exact path={routes.DASHBOARD_FOTOS} component={FotosPage} />
                <Route exact path={routes.DASHBOARD_DIVULGATION} component={DivulgationPage} />
                <Route exact path={routes.DASHBOARD_IRPF} component={IRPF} />
                <Route exact path={routes.DASHBOARD_STATUS_RENT} component={StatusAluguelPage} />
                <Route exact path={routes.DASHBOARD_BOLETOS} component={Boletos} />
                <Route exact path={routes.DASHBOARD_BOLETO_FORM} component={CreatePayments} />
                <Route exact path={routes.DASHBOARD_BOLETO_DETAIL} component={PaymentDetail} />
                <Route exact path={routes.DASHBOARD_BOLETOS_STATUS} component={Payments} />
                <Route exact path={routes.DASHBOARD_LEGAL} component={AssistenciaJuridica} />
                <Route exact path={routes.DASHBOARD_FIANCA_INSURANCE} component={SeguroFianca} />
                <Route exact path={routes.DASHBOARD_FIRE_INSURANCE} component={SeguroIncendio} />
                <Route exact path={routes.DASHBOARD_RECEIPTS} component={Receipts} />
                <Route exact path={routes.DASHBOARD_PROPOSALS_REGISTER} component={ProposalsRegister} />
                <Route exact path={routes.DASHBOARD_PROPOSALS_EDIT} component={ProposalsRegister} />
                <Route exact path={routes.DASHBOARD_PROPOSALS_OPTIONS} component={ProposalOptions} />
                <Route exact path={routes.DASHBOARD_PROPOSALS_LIST} component={ProposalList} />
                <Route exact path={routes.DASHBOARD_PROPERTY_LIST} component={PropertyList} />
                <Route exact path={routes.DASHBOARD_TENANT_PROPERTY_LIST} component={TenantPropertyList} />
                <Route exact path={routes.DASHBOARD_ROOT_TENANT} component={TenantProperty} />
                <Route exact path={routes.DASHBOARD_CONTRACT_TENANT} component={TenantContractDetails} />
                <Route exact path={routes.DASHBOARD_TENANT_PAYMENT_LIST} component={TenantPaymentList} />
                <Route exact path={routes.DASHBOARD_AD_CREATE} component={PropertyAdForm} />
                <Route exact path={routes.DASHBOARD_BANK_STATMENT} component={BankStatement} />
                <Route exact path={routes.DASHBOARD_PAYMENTS_LIST_MONTH} component={PaymentsListMonth} />
                <Route exact path={routes.DASHBOARD_AD_LEADS} component={AdLeads} />
                <Route exact path="/dashboard/renovacao" component={Renew} />
                <Route exact path={routes.PAYMENT_MARK_AS_PAID} component={MarkAsPaid} />
                <Route exact path={routeWithParams(routes.PAYMENT_VIEW, ':encodedParams')} component={PaymentView} />

                <Route
                  exact
                  path={routeWithParams(routes.DASHBOARD_CREDIT_ANALYSIS, ':proposalId')}
                  component={ProposalView}
                />

                {/*--------------------------
                  *-----     RESTO     -----*
                  --------------------------*/}
                <Route path={routes.ADMIN} component={Admin} />
                <Route path={routes.CALCULATOR_READJUST} component={CalculatorReadjustment} />
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </main>
          {/*<RegisterTermsAccept />*/}
          <CookiesAccept />
        </AppContainer>
      </BrowserRouter>
    </Loader>
  )
}

export default App
