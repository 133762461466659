import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'

const PhotoContainer = ({photo, index, id, getPhotoClassName, movePhotoContainer}) => {
  const ref = useRef(null)

  const [{handlerId}, drop] = useDrop({
    accept: 'photo_container',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      movePhotoContainer(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'photo_container',
    item: () => {
      return {id, index}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div style={{opacity}} ref={ref} index={index} data-handler-id={handlerId}>
      <div
        name={photo.download_url}
        className={getPhotoClassName(photo)}
        style={{
          backgroundImage: `url(${photo.download_url})`,
        }}
      />
    </div>
  )
}

export default PhotoContainer
