import './index.css'

import {ThemeProvider} from '@material-ui/core/styles'
import App from '@root/App'
import envConfig from '@root/env-config.js'
import {setBranchName} from '@root/lib/branch'
import {getRealEnv, isDevTestEnv, isProductionEnv, isStagingEnv} from '@root/lib/envDetector'
import mobenTheme from '@root/mobenTheme'
import DevError from '@root/pages/Error/DevError'
import Error from '@root/pages/Error/Error'
import store from '@root/redux/store'
import {BASE_URL} from '@root/utils/constants'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {setDebug, setMode} from 'discord-notify'
import {enableES5} from 'immer'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'

const {host, pathname, search} = window.location
if (host === 'moben-dev-5b99b.web.app') {
  window.location.href = `${BASE_URL}/${pathname}${search}`
}

// discord notify configs
let discordMode = 'production'
let discordDebug = true

if (isDevTestEnv()) {
  discordMode = 'console'
  discordDebug = false
} else if (isStagingEnv()) {
  discordMode = 'playground'
}
setMode(discordMode)
setDebug(discordDebug)

setBranchName(envConfig.BRANCH)
enableES5()
const sentryEnabled = isProductionEnv() || isStagingEnv()
// const sentryEnabled = true
const TRACE_SAMPLE_RATE = sentryEnabled ? 1 : 0
const SAMPLE_RATE = sentryEnabled ? 1.0 : 0
const FALLBACK_COMP = sentryEnabled ? Error : DevError
// console.log('Sentry', sentryEnabled, TRACE_RATE)

Sentry.init({
  dsn: 'https://12d47e9608e74d43a2082b7b34cc6091@o465215.ingest.sentry.io/5477507',
  integrations: [new Integrations.BrowserTracing()],
  environment: getRealEnv(),
  release: `moben-frontend@${envConfig.APP_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: TRACE_SAMPLE_RATE, // transactions
  sampleRate: SAMPLE_RATE,
  normalizeDepth: 10,
})

navigator.serviceWorker
  .getRegistrations()
  .then((registrations) => {
    for (let registration of registrations) {
      registration
        .unregister()
        .then(() => true)
        .catch((err) => console.log('failed to unregister', err))
    }
  })
  .catch((err) => console.log('get registrations error', err))

ReactDOM.render(
  <Sentry.ErrorBoundary showDialog={false} fallback={(errorProps) => <FALLBACK_COMP {...errorProps} />}>
    <Provider store={store}>
      <ThemeProvider theme={mobenTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
)
