import HttpRepositoryInterface from '@root/application/domain/HttpRepositoryInterface'
import {mobenAPIInstance} from '@root/application/infra/datasource/MobenAPI'
import firebase from '@root/firebase/firebase.utils'

const removeInitialSlashRegex = /^\//

export default class BaseHttpRepository extends HttpRepositoryInterface {
  /** @var {HttpApiInterface} */
  _api = mobenAPIInstance

  /** @var {string} */
  _userId = ''

  _retrieveUserId() {
    if (!this._userId) {
      this._userId = firebase.auth().currentUser.uid
    }
    return this._userId
  }

  _skipRequest() {
    return Promise.reject(new Error('Request not sent'))
  }
}
