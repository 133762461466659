import * as Sentry from '@sentry/react'
import StartLoader from '@root/components/Loader/StartLoader'
import analyticsLogger from '@root/firebase/analytics.utils'
import {auth, firestore} from '@root/firebase/firebase.utils'
import {useQuery} from '@root/hooks/urlHook'
import useOptimize from '@root/hooks/useOptimize'
import useRewards from '@root/hooks/useRewards'
import {historyHandler} from '@root/lib/authChecker'
import {saveBackLink} from '@root/lib/backToLink'
import {isDevEnv} from '@root/lib/envDetector'
import {addBreadCrumb} from '@root/lib/errorHandler'
import {signOut} from '@root/lib/signIn'
import storage from '@root/lib/storage'
import userModel from '@root/model/userModel'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {addOptimizeVariant, setApplicationReadyAction, setCookiesAction} from '@root/redux/application/application.actions'
import {setCartListAllAction} from '@root/redux/cart/cart.actions'
import {setContractFromDatabaseAction} from '@root/redux/contract/contract.actions'
import {resetPropertyFromSnap} from '@root/redux/property/property.actions'
import {setPropertyUIDForSurvey} from '@root/redux/survey/survey.actions'
import {setAuthFinishedAction, setUserFromFirebaseAction, setUserIdAction} from '@root/redux/user/user.actions'
import {STORAGE_COOKIES, STORAGE_PROPERTY_ID, STORAGE_TENANT_PROPERTY_ID} from '@root/utils/constants'
import routes from '@root/utils/routes'

import propertyModel from './model/propertyModel'
import {loadTenantPropertyAction, loadTenantPropertyListAction} from './redux/tenantProperty/tenantProperty.actions'

let storedPropertyId = storage.load(STORAGE_PROPERTY_ID, null)
const tenantPropertyId = storage.load(STORAGE_TENANT_PROPERTY_ID)

let alreadyLoadSnap = false
let listenerAuthCounter = 0
const AppContainer = (props) => {
  const history = useHistory()
  const rewards = useRewards()
  const appIsReady = useSelector((s) => s.application.ready)
  const appRouteInfo = useSelector((s) => s.application.routeInfo)
  const authFinished = useSelector((s) => s.user.authFinished)
  const currentPropertyId = useSelector((s) => s.property.currentPropertyId)
  const currentUser = useSelector((s) => s.user.user)
  const currentUserId = useSelector((s) => s.user.id)

  const [listenCurrentUser, setListenCurrentUser] = useState(true)
  const {getVariantByExperiment, isOptimizeLoaded} = useOptimize()
  const {hasParam, getParam} = useQuery()

  const isAdmin = useSelector((s) => s.user?.user?.user_role) === 'admin'
  const dispatch = useDispatch()

  const dispatchPropertyActions = (propSnap) => {
    dispatch(resetPropertyFromSnap(propSnap))
    dispatch(setContractFromDatabaseAction(propSnap.data()?.contract ?? {}, propSnap))
    dispatch(setPropertyUIDForSurvey(propSnap.data()?.uid))
  }

  useEffect(() => {
    if (rewards.hasCode) {
      rewards.save(rewards.code)
    }
  }, [])

  useEffect(() => {
    if (hasParam('id')) {
      const pid = getParam('id')

      storedPropertyId = pid
      storage.save(STORAGE_PROPERTY_ID, pid)
    }
  }, [])

  useEffect(() => {
    const acceptVal = storage.load(STORAGE_COOKIES)
    const isAccepted = acceptVal === 'yes'
    dispatch(setCookiesAction(isAccepted))
  }, [])

  useEffect(() => {
    if (isOptimizeLoaded) {
      dispatch(addOptimizeVariant('optimizedot', getVariantByExperiment('Oj54tjJQT7qenfXh4SBbDg')))
      dispatch(addOptimizeVariant('home', getVariantByExperiment('o5lcbtNwREed-DqPOzbDbQ')))
    }
  }, [isOptimizeLoaded])

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        dispatch(setUserIdAction(userAuth.uid))
        analyticsLogger.setUserID(userAuth.uid)

        firestore
          .collection(userModel.COLLECTION_NAME)
          .doc(userAuth.uid)
          .update('last_access', new Date())
          .catch(() => null) // sim e um catch cala boca, tem mais a ver com algum tipo de race-condition no primeiro acesso, ai da um guruzinho
        if (isDevEnv()) {
          console.log('me: ', userAuth.uid)
        }
      } else {
        dispatch(setApplicationReadyAction())
      }

      dispatch(setAuthFinishedAction())

      listenerAuthCounter++

      // console.log('auth counter', listenerAuthCounter)
    })

    return function () {
      unsubscribeFromAuth()
    }
  }, [])

  useEffect(() => {
    if (listenCurrentUser && currentUser) {
      Sentry.setUser({id: currentUser.uid, email: currentUser.email, username: currentUser.name})
      setListenCurrentUser(false)
    }
  }, [listenCurrentUser, currentUser])

  useEffect(() => {
    if (!appIsReady) {
      return
    }
    const unlisten = history.listen((config) => historyHandler(history, config))
    return function () {
      unlisten()
    }
  }, [appIsReady])

  useEffect(() => {
    if (appIsReady) {
      historyHandler(history, history.location)
    }
  }, [appIsReady])

  //USER LISTENER
  useEffect(() => {
    if (!currentUserId) {
      return
    }

    const unlisten = firestore
      .collection(userModel.COLLECTION_NAME)
      .doc(currentUserId)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          if (isDevEnv()) {
            console.log('user listener update')
          }
          dispatch(setUserFromFirebaseAction(snapshot.data(), snapshot.ref))
        } else if (listenerAuthCounter === 1) {
          /*
    previne de um user ficar preso caso o usuario exista no auth mas nao no firestore. Caso que em producao normalmente nao vai acontecer
    o lance do ===1 e que ta fazendo um incremento a cada AuthStateChanged dai pode acontecer:
    - primeiro AuthStateChanged quando NAO esta logado, o currentUserId permanece null e nada acontece
    - primeiro AuthStateChanged quando ESTA logado e user nao existe na colecao, vai disparar o currentUserId, mas quando chegar aqui no listener nao vai encontrar nada
        e isso e feito no load entao o listenerAuthCounter vai ser 1 que significa o primeiro load da pagina basicamente
    */
          // listenerAuthCounter = 0
          signOut(history)
        }
      })
    return function () {
      unlisten()
    }
  }, [currentUserId])

  // OWNER PROPERTIES LISTENER
  useEffect(() => {
    if (!currentPropertyId) {
      return
    }

    const unlisten = firestore
      .collection(propertyModel.COLLECTION_NAME)
      .doc(currentPropertyId)
      .onSnapshot((snapshot) => {
        if (isDevEnv()) {
          console.log('property listener update')
        }
        dispatchPropertyActions(snapshot)
      })
    return function () {
      unlisten()
    }
  }, [currentPropertyId])

  useEffect(() => {
    if (!currentUser) {
      return
    }

    const unlisten = firestore
      .collection(propertyModel.COLLECTION_NAME)
      .where('ower_id', '==', currentUser.uid)
      .orderBy('created_at', 'desc')
      .onSnapshot(async (snapshot) => {
        const news = snapshot.docChanges().filter((change) => change.type === 'added')
        if (storedPropertyId && news.length > 0 && !alreadyLoadSnap) {
          let loadedPropSnap = snapshot.docs.find((snap) => snap.id === storedPropertyId)
          if (loadedPropSnap) {
            addBreadCrumb('loaded property from storage', storedPropertyId)
            dispatchPropertyActions(loadedPropSnap)
          } else if (isAdmin) {
            loadedPropSnap = await propertyModel.getById(storedPropertyId)

            if (loadedPropSnap) {
              addBreadCrumb('loaded property from storage to admin', storedPropertyId)
              dispatchPropertyActions(loadedPropSnap)
            }
          } else if (appRouteInfo.hasMatchProtected && !appRouteInfo.isRoot && !appRouteInfo.isAllowWithoutProperty) {
            // TODO esta mesma condicao acima existe no authChecker precisa otimizar isso, para nao ficar tao repetido
            addBreadCrumb('back to property list from AppContainer')
            const {pathname, search} = window.location
            saveBackLink(pathname + search)
            history.replace(routes.DASHBOARD_PROPERTY_LIST)
          }
        }

        dispatch(setCartListAllAction(snapshot.docs))
        dispatch(setApplicationReadyAction())

        alreadyLoadSnap = true
      })
    return function () {
      unlisten()
    }
  }, [currentUser])

  // TENANT PROPERTY LISTENER
  useEffect(() => {
    if (!currentUser || !currentUser?.tenant_of) {
      return
    }
    const propertiesRefs = currentUser.tenant_of

    let propertyIdArray = propertiesRefs.map((property) => property.id)

    if (propertyIdArray.length > 0) {
      const unlisten = firestore
        .collection(propertyModel.COLLECTION_NAME)
        .where('uid', 'in', propertyIdArray)
        .onSnapshot(async (snapshot) => {
          let propertiesArray = snapshot.docs.map((property) => property.data())

          let propertiesArrayWithOwner = propertiesArray.map(async (property) => {
            const owner = await userModel.getById(property.ower_id)
            property.owner_data = owner.data()
            return property
          })
          propertiesArrayWithOwner = await Promise.all(propertiesArrayWithOwner)

          let currentProperty = propertiesArrayWithOwner.find((property) => property.uid === tenantPropertyId)

          dispatch(loadTenantPropertyAction(currentProperty))
          dispatch(loadTenantPropertyListAction(propertiesArrayWithOwner))
        })
      return function () {
        unlisten()
      }
    } else {
      dispatch(loadTenantPropertyAction([]))
      dispatch(loadTenantPropertyListAction([]))
    }
  }, [currentUser, authFinished])

  const showPage = authFinished && appIsReady
  return <>{showPage ? props.children : <StartLoader />}</>
}

export default AppContainer
