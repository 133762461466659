/** @module ProposalHelper */
import dateParser from '@root/lib/dateParser'
import {USER_TYPE_FIADOR, USER_TYPE_TENANT} from '@root/lib/userType'
import {createEmptyTenant} from '@root/redux/tenantRegister/tenantRegister.reducer'
import uidGenerator from '@root/lib/uidGenerator'
import userModel from '@root/model/userModel'
import {firestore} from '@root/firebase/firebase.utils'
import propertyModel from '@root/model/propertyModel'
import addressDisplay, {ADDRESS_VERBOSITY_FULL} from '@root/lib/addressDisplay'
import proposalFinder from '@root/lib/proposalFinder'
import {USER_SOURCE_SELF} from '@root/utils/constants'

const notEmpty = (value) => value !== '' && value !== undefined && value !== null
const isEmpty = (value) => !notEmpty(value)
const matchObjectProp = (prop, obj1, obj2) => obj1[prop] && obj1[prop] === obj2[prop]

/** @typedef {user[]} UserType {@see https://github.com/MobenDev/database-structure} */
/** @typedef {user[]} UserTypeRef {@see https://github.com/MobenDev/database-structure} */
export default class ProposalHelper {
  static MODE_DEFAULT = 'default'
  static MODE_EXPRESS = 'express'

  /** @type {string} */
  #id = undefined

  /** @type {Date} */
  #date = undefined

  /** @type {string} */
  #mode = ProposalHelper.MODE_DEFAULT

  /** @type {string} */
  #name = ''

  /** @type {boolean} */
  #autoAnalysis = false

  /** @type {boolean} */
  #consentAnalysis = false

  /** @type {Date} */
  #consentDate = new Date()

  /** @type {UserType[]}  */
  #tenants = []

  /** @type {UserType[]}  */
  #fiadores = []

  /** @type {UserTypeRef[]}  */
  #interestedsRaw = []

  /** @type {UserType[]}  */
  #interestedsLoaded = []

  /** @type {boolean}  */
  #loading = false

  /**
   * @return {ProposalHelper}
   */
  static init() {
    return new ProposalHelper()
  }

  /**
   * @param property
   * @return {ProposalHelper}
   */
  static initWithProperty(property) {
    const helper = new ProposalHelper()
    helper.initFromProperty(property)

    return helper
  }

  /**
   * @param property
   * @param user
   * @param proposalId
   * @return {ProposalHelper}
   */
  static initWithPropertyAndUserOrID(property, user, proposalId) {
    const proposals = property.proposals ?? {}
    const selected = property.selected_proposal ?? {}
    const userInProposal = proposalFinder(proposals, user?.uid)
    const helper = new ProposalHelper()
    if (proposalId) {
      helper.setProposal(proposalId, proposals[proposalId])
    } else if (selected.uid) {
      helper.setProposal(selected.uid, proposals[selected.uid])
    } else if (userInProposal.exists) {
      helper.setProposal(userInProposal.id, proposals[userInProposal.id])
    }

    return helper
  }

  /**
   * @typedef {object} SaveUserReturn
   * @property {Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>} snap
   * @property {string} type
   * @property {boolean} updated
   * @property {boolean} created
   */

  /**
   * @param {string} type
   * @param {any} userData
   * @param {string?} customId
   * @param {any?} property
   * @param {string} [source]
   * @param {boolean} [isTenantApproved]
   * @return {SaveUserReturn}
   */
  static async saveUser(type, userData, customId, property, source = 'unknown', isTenantApproved = false) {
    if (!type || (type !== USER_TYPE_TENANT && type !== USER_TYPE_FIADOR && type !== 'owner')) {
      throw new Error('Invalid user type for proposal')
    }

    let newUser = {
      ...createEmptyTenant(),
      active: false, // default que vem do createEmptyTenant é true, aqui ele e sobrescrito, mas no caso do user ja existir e for true  a linha de baixo sobrescreve novamente
      origin_source: source, // idem acima
      ...userData,
    }
    const userExists = newUser.uid !== undefined && newUser.uid !== ''
    let uid = uidGenerator()

    if (userData && userData.uid) {
      uid = userData.uid
    }
    newUser.uid = uid

    return ProposalHelper._persistsUser(type, newUser, property, userExists, isTenantApproved)
  }

  /**
   * @private
   * @return {SaveUserReturn}
   */
  static async _persistsUser(type, user, property, userExists, isTenantApproved = false) {
    if (!user || (user && !user.uid)) {
      throw new Error('Missing user data or ID')
    }
    if (!property) {
      throw new Error('Missing property data')
    }

    const isUserDuplicate = await userModel.userExists(user)
    if (userExists && isUserDuplicate) {
      throw new Error('Telefone e e-mail já estão em uso. Por favor utilize outros dados ou convide este usuário')
    } else if (!userExists) {
      await userModel.fetchCreateUser(user)
    }

    const propertyId = property?.uid
    const propertyDoc = firestore.collection(propertyModel.COLLECTION_NAME).doc(propertyId)
    const userSnap = await firestore.collection(userModel.COLLECTION_NAME).doc(user.uid).get()
    let newUser = user
    let created = false
    let updated = false

    if (userSnap.exists) {
      newUser = {...userSnap.data(), ...user}
    }
    if (type === USER_TYPE_TENANT) {
      newUser.is_tenant = true
    } else if (type === USER_TYPE_FIADOR) {
      newUser.is_fiador = true
    }

    const proposalExists = newUser.proposal_of.some((proposal) => proposal.property.id == propertyId)
    if (!proposalExists && type !== 'owner') {
      newUser.proposal_of.push({
        type,
        property: propertyDoc,
        display_address: addressDisplay(property.address, ADDRESS_VERBOSITY_FULL),
      })
    }

    if (isTenantApproved) {
      newUser.tenant_of = ProposalHelper.handleTenantOf(propertyDoc, newUser.tenant_of)
    }

    newUser = userModel.prepareForDatabase(newUser)
    if (userSnap.exists) {
      await userSnap.ref.update(newUser)
      updated = true
    } else {
      await userSnap.ref.set(newUser)
      created = true
    }
    // por padrao apos o o update ou set o data nao vem populado, por isso essa gambi marota
    userSnap.data = () => newUser
    return {
      snap: userSnap,
      created,
      updated,
      type,
    }
  }

  static handleTenantOf(propertyReference, currentTenantOfArray) {
    let newTenantOfArray = currentTenantOfArray ?? []
    const hasPropertyOnArray = newTenantOfArray.some((tenant_of) => tenant_of.id === propertyReference.id)

    if (!hasPropertyOnArray) {
      newTenantOfArray.push(propertyReference)
    }

    return newTenantOfArray
  }

  constructor() {}

  /**
   * @param property
   */
  initFromProperty(property) {
    const selected = property?.selected_proposal
    if (selected?.uid) {
      const proposal = property?.proposals[selected.uid]
      if (!proposal) {
        throw new Error('Proposal could not be found')
      }

      this.setProposal(selected.uid, proposal)
    }
  }

  /**
   * Transforma a classe em um objeto pronto para ir para o banco
   * @return {{interesteds: any[], created_at: Date, analysis: boolean}}
   */
  toObject() {
    if (!this.isValid()) {
      throw new Error('Cannot convert empty proposal to object')
    }
    return {
      created_at: this.#date,
      analysis: this.#autoAnalysis,
      mode: this.#mode,
      name: this.#name,
      interesteds: this.#interestedsRaw.map((interested) => {
        const {type, user, credit_analysis_consent} = interested
        return {
          user,
          type,
          credit_analysis_consent: credit_analysis_consent ?? {},
        }
      }),
    }
  }

  getPath() {
    return `proposals.${this.getId()}`
  }

  isValid() {
    return notEmpty(this.#date) && notEmpty(this.#id)
  }

  createIfNotExists() {
    if (!this.isValid()) {
      this.initEmptyProposal()
    }
  }

  setProposal(id, proposal) {
    this.setId(id)
    this._extractFromProposal(proposal)
  }

  /**
   * @private
   * @param proposal
   */
  _extractFromProposal(proposal) {
    this.#date = dateParser(proposal.created_at)
    this.#autoAnalysis = !!proposal.analysis
    this.#mode = proposal.mode ?? ProposalHelper.MODE_DEFAULT
    this.#name = proposal.name ?? ''
    this._extractUsersRefsFromInteresteds(proposal.interesteds)
  }

  /**
   * @private
   * @param interesteds
   */
  _extractUsersRefsFromInteresteds(interesteds) {
    if (interesteds) {
      this.#interestedsRaw = [...interesteds]
    }
  }

  /**
   */
  initEmptyProposal() {
    this.#id = this.generateId()
    this.#date = new Date()
    this.#autoAnalysis = false
    this.#mode = ProposalHelper.MODE_DEFAULT
    this.#name = ''
    this.#tenants = []
    this.#fiadores = []
    this.#interestedsRaw = []
  }

  /**
   * @param id
   */
  setId(id) {
    if (isEmpty(id) || typeof id !== 'string') {
      throw new Error('Invalid ID')
    }
    this.#id = id
  }

  /**
   * @return {string}
   */
  getId() {
    return this.#id
  }

  /**
   * @return {string}
   */
  getMode() {
    return this.#mode
  }

  /**
   * @return {boolean}
   */
  isModeDefault() {
    return this.#mode === ProposalHelper.MODE_DEFAULT || !this.#mode
  }

  /**
   * @return {boolean}
   */
  isModeExpress() {
    return this.#mode === ProposalHelper.MODE_EXPRESS
  }

  /**
   * @return {string}
   */
  getName() {
    return this.#name
  }

  /**
   * @return {string}
   */
  generateId() {
    return uidGenerator()
  }

  /**
   * @param {'default' | 'express'} mode
   */
  setMode(mode) {
    this.#mode = mode
  }

  /**
   * @return {boolean}
   */
  hasAutoAnalysis() {
    return this.#autoAnalysis
  }

  /**
   * @deprecated
   * @param analyse
   */
  setAutoAnalysis(analyse) {
    this.#autoAnalysis = false
  }

  /**
   * @param agreed
   */
  setConsentAnalysis(agreed) {
    this.#consentAnalysis = agreed
  }

  /**
   * @deprecated
   */
  enableAutoAnalysis() {
    this.setAutoAnalysis(true)
  }

  /**
   * @deprecated
   */
  disableAutoAnalysis() {
    this.setAutoAnalysis(false)
  }

  /**
   * @return {*}
   */
  getAnalysisCount() {
    return this.getInteresteds().filter((user) => user.credit_analysis?.length > 0).length
  }

  /**
   * @return {Date}
   */
  getDate() {
    return this.#date
  }

  /**
   * @return {boolean}
   */
  hasInterested() {
    return this.getInterestedsCount() > 0
  }

  /**
   * @return {boolean}
   */
  hasTenantRef() {
    return this.#interestedsRaw.some((item) => item.type === USER_TYPE_TENANT)
  }

  /**
   * @param {string} type
   * @param {firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>} snap
   */
  addInterestedFromSnap(type, snap) {
    if (!this._interestedExists(snap.id)) {
      const isRegisterSelf = snap.data().register_by === USER_SOURCE_SELF
      this.#interestedsRaw.push({
        user: snap.ref,
        type,
        credit_analysis_consent: {
          agreed: isRegisterSelf,
          updated_at: new Date(),
        },
      })
      this._dispatchInterested(type, snap)
    }
  }

  /**
   * @method
   * @private
   * @param {string} type
   * @param {firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>} snap
   */
  _dispatchInterested(type, snap) {
    const userObj = snap.data()
    const userSnapInjected = {...userObj, type, snap}
    if (type === USER_TYPE_TENANT) {
      this.#tenants.push(userSnapInjected)
    } else if (type === USER_TYPE_FIADOR) {
      this.#fiadores.push(userSnapInjected)
    }
  }

  /**
   * @param userData
   * @return {xgh} {Pick<*, Exclude<keyof *, "type"|"snap">>}
   */
  clearInjectedSnap(userData) {
    const {snap, type, ...cleanedUser} = userData
    return cleanedUser
  }

  /**
   * @return {UserTypeRef[]}
   */
  findInterestedById(id) {
    return this.getInteresteds().find((interested) => interested.uid === id)
  }

  /**
   * @return {UserTypeRef[]}
   */
  getInteresteds() {
    return [...this.getTenants(), ...this.getFiadores()]
  }

  /**
   * @return {UserTypeRef[]}
   */
  getInterestedsRaw() {
    return this.#interestedsRaw
  }

  /**
   * @return {number}
   */
  getInterestedsCount() {
    return this.getTenantsCount() + this.getFiadoresCount()
  }

  /**
   * @return {UserTypeRef}
   */
  getInterestedRawAt(index) {
    return this.#interestedsRaw[index]
  }

  /**
   * @return {number}
   */
  getRefsCount() {
    return this.#interestedsRaw.length
  }

  /**
   * @param forceFetch
   * @return {Promise<void>}
   */
  async loadUsersRefs(forceFetch = false) {
    // la no `run()` do ContractTenantInfo chama esse metodo 2 vezes seguidas, e acaba duplicando o que tem em #tenants
    if (this.#loading) {
      return
    }

    const useCached = !forceFetch && this.getInterestedsCount() > 0
    if (useCached) {
      return
    }

    this.#loading = true
    this.#interestedsLoaded = []
    this.#tenants = []
    this.#fiadores = []
    const promises = this.#interestedsRaw.map(async (interested) => {
      const snap = await interested.user.get()
      this.#interestedsLoaded.push(snap)
      this._dispatchInterested(interested.type, snap)
    })

    await Promise.all(promises)
    this.#loading = false
  }

  /**
   * @return {Promise<void>}
   */
  async loadUsersRefsForceFetch() {
    this.loadUsersRefs(true)
  }

  /**
   * Procura um usuario usando a seguite ordem
   *   - userData.uid
   *   - userData.email && userData.phone
   *   - userData.email
   *   - userData.phone
   *
   * @param {any} userData
   * @param {string} type
   * @returns {boolean}
   */
  updateUser(userData, type) {
    let foundUser, user, index
    const usersList = this.getInteresteds()

    for (index in usersList) {
      user = usersList[index]

      if (matchObjectProp('uid', userData, user)) {
        foundUser = user
        break
      } else if (matchObjectProp('email', userData, user) && matchObjectProp('phone', userData, user)) {
        foundUser = user
        break
      } else if (matchObjectProp('email', userData, user)) {
        foundUser = user
        break
      } else if (matchObjectProp('phone', userData, user)) {
        foundUser = user
        break
      }
    }

    if (foundUser) {
      // usersList[index] = {...foundUser, ...userData, type}
      usersList[index] = ProposalHelper.saveUser(type ?? foundUser.type, userData)
    }
    this._extractUsersRefsFromInteresteds(usersList)

    return foundUser !== undefined
  }

  /**
   * @return {UserType[]}
   */
  getTenants() {
    return this.#tenants
  }

  /**
   * @return {number}
   */
  getTenantsCount() {
    return this.getTenants().length
  }

  /**
   * @return {boolean}
   */
  hasTenant() {
    return this.getTenantsCount() > 0
  }

  /**
   * @param index
   * @return {user[]}
   */
  getTenantAt(index) {
    return this.#tenants[index]
  }

  /**
   * @param index
   * @param user
   */
  replaceTenantAt(index, user) {
    const currentUser = this.#tenants[index] ?? {}
    const uid = currentUser.uid ? currentUser.uid : uidGenerator()
    const newUser = {...createEmptyTenant(), ...currentUser, ...user, uid, type: USER_TYPE_TENANT}
    this.#tenants[index] = userModel.prepareForDatabase(newUser)
  }

  /**
   * @return {UserType[]}
   */
  getFiadores() {
    return this.#fiadores
  }

  /**
   * @return {number}
   */
  getFiadoresCount() {
    return this.getFiadores().length
  }

  /**
   * @return {boolean}
   */
  hasFiador() {
    return this.getFiadoresCount() > 0
  }

  /**
   * @param index
   * @return {user[]}
   */
  getFiadorAt(index) {
    return this.#fiadores[index]
  }

  /**
   * @param index
   * @param user
   */
  replaceFiadorAt(index, user) {
    const currentUser = this.#fiadores[index] ?? {}
    const uid = currentUser.uid ? currentUser.uid : uidGenerator()
    const newUser = {...createEmptyTenant(), ...currentUser, ...user, uid, type: USER_TYPE_FIADOR}
    this.#fiadores[index] = userModel.prepareForDatabase(newUser)
  }

  /**
   * @private
   * @param {string} id
   * @return {boolean}
   */
  _interestedExists(id) {
    return this.#interestedsRaw.some((interested) => interested.user.id === id)
  }
}
