import Typography from '@material-ui/core/Typography'
import {extractProps} from '@root/components/Layout/Typography/proptypesTypography'
import useTypographyStyle from '@root/components/Layout/Typography/useTypographyStyle'
import PropTypes from 'prop-types'
import React from 'react'

const Title = (props) => {
  const resultProps = extractProps(props, {secondary: true, size: '1.25rem'})
  const classes = useTypographyStyle(resultProps.style)

  return (
    <Typography {...props} className={`${classes.merged} ${props.className}`}>
      {props.children}
    </Typography>
  )
}

Title.propTypes = {}
Title.defaultProps = {}

export default Title
