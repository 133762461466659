import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddProposalInterestedActions from '@root/components/AddProposalInterestedActions'
import {Button} from '@root/components/Button/Button'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import InviteForm from '@root/components/Forms/InviteForm'
import InterestedAnalysis from '@root/components/InterestedAnalysis/InterestedAnalysis'
import {ProposalsListItem} from '@root/components/ProposalsListItem/ProposalsListItem'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import ProposalManager from '@root/lib/contract/ProposalManager'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler, {addBreadCrumb} from '@root/lib/errorHandler'
import proposalLoader, {loadProposalWithManagerAndRedux} from '@root/lib/proposalLoader'
import {proposalSaver} from '@root/lib/proposalSaver'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractPropertyAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {USER_SOURCE_CONTRACT} from '@root/utils/constants'
import routes, {routeWithParams} from '@root/utils/routes'

import {useStyles} from './styles'

function TenantInfo() {
  const classes = useStyles()
  const extraReducer = (state, action) => {
    if (action.type === 'set_extra') {
      return action.extra
    }
  }

  const history = useHistory()

  const formHook = useForm()
  const {handleSubmit} = formHook
  const user = useSelector((s) => s.user.user)
  const currentProperty = useSelector((s) => s.property.data) ?? {}
  const propertySnap = useSelector((s) => s.property.snap)
  const proposalManager = useSelector((s) => s.property.proposals.manager)

  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(currentProperty)

  const [proposalsCount, setProposalsCount] = useState(0)
  const [tenantsList, setTenantsList] = useState([])
  const [proposalsList, setProposalsList] = useState([])
  const [selectedProposal, setSelectedProposal] = useState()

  const isEmptyProposal = proposalsCount === 0
  // const hasSingleProposal = proposalsCount === 1
  // const hasManyProposals = proposalsCount > 1

  const showContinueButton = selectedProposal || isEmptyProposal

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
    setHeaderMainHeader,
    setFooterNotVisible,
    setContractPropertyAction,
  })

  const handleContinue = (data) => {
    if (selectedProposal) {
      goToNextStep()
    } else if (isEmptyProposal) {
      saveTenantForm(data)
    }
  }

  const goToNextStep = () => {
    history.push(pathNextStepAfterSave())
  }

  const goToProposal = () => {
    history.push(routeWithParams(routes.DASHBOARD_CREDIT_ANALYSIS, selectedProposal.getId()))
  }

  const saveTenantForm = async (data) => {
    actions.setGlobalLoaderAction(true)

    try {
      const savedProposal = await proposalSaver({
        propertySnap,
        manager: proposalManager,
        proposalId: proposalManager.getSelected()?.getId(),
        tenantFormData: {...selectedProposal, ...data},
        autoAnalysis: false,
        proposalType: USER_TYPE_TENANT,
        originSource: USER_SOURCE_CONTRACT,
        isTenantApproved: true,
      })

      const proposalId = savedProposal.getId()
      const curTenantUid = savedProposal.getTenantAt(0).uid

      const updatedProperty = {
        tenant: curTenantUid,
        selected_proposal: {
          uid: proposalId,
          interested: 0,
        },
      }
      await propertySnap.ref.update(prepareContractStepUpdate(updatedProperty))
    } catch (ex) {
      addBreadCrumb('tenant form data', data)
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex, ex.message))
      return
    }

    goToNextStep()
    actions.setGlobalLoaderAction(false)
  }

  const loadTenants = async () => {
    const manager = await loadProposalWithManagerAndRedux()
    manager.selectProposalFromPropertyOrId(currentProperty)

    const hasProposalSelected = manager.hasSelected() && manager.getSelected().isValid()
    const plist = manager.getProposalsList()
    const plen = manager.getProposalsLen()

    setProposalsCount(plen)
    setProposalsList(plist)
    if (hasProposalSelected) {
      actions.setGlobalLoaderAction(true)
      if (manager.getSelected().hasTenant()) {
        // const tenant = manager.getSelected().getTenantAt(0)
        // setProposalUser(tenant)
        const curSelectedProposal = manager.getSelected()
        const interestedsList = curSelectedProposal.getTenants()

        setSelectedProposal(curSelectedProposal)
        setTenantsList(interestedsList)
      }
      actions.setGlobalLoaderAction(false)
    }
  }

  /*useEffect(() => {
    proposalHelper.loadUsersRefs()
  }, [proposalHelper])*/

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    if (currentProperty) {
      loadTenants()
    }
  }, [currentProperty])

  return (
    <ContractContainer>
      <form className={`${classes.root}`}>
        <FormProvider {...formHook}>
          {isEmptyProposal && (
            <InviteForm
              allowFullEdit={true}
              requireIncomeSource={false}
              requireIncomeDoc={false}
              requireIdentityDoc={false}
              user={selectedProposal}
            />
          )}

          {selectedProposal && (
            <>
              {tenantsList.map((user) => (
                <div key={user.uid} style={{marginBottom: 24}}>
                  <InterestedAnalysis user={user} showFullData={true} />
                </div>
              ))}

              <AddProposalInterestedActions proposalId={selectedProposal.getId()} />
            </>
          )}

          {!isEmptyProposal && !selectedProposal && (
            <>
              <Typography variant="body2" align="center">
                Você ainda não selecionou nenhuma proposta.
              </Typography>
              {proposalsList.map((proposalh, idx) => (
                <ProposalsListItem
                  key={proposalh.getId()}
                  id={proposalh.getId()}
                  index={idx}
                  interesteds={proposalh.getTenants()}
                />
              ))}
            </>
          )}
        </FormProvider>
      </form>

      <ContractContainerBottom>
        {showContinueButton && <ContractContinueButton onClick={handleSubmit(handleContinue)} />}
      </ContractContainerBottom>

      {selectedProposal && (
        <Typography
          variant="h6"
          color="secondary"
          align="center"
          className="clickable"
          style={{marginTop: 24}}
          onClick={goToProposal}>
          Declinar essa proposta e escolher outro inquilino
        </Typography>
      )}
    </ContractContainer>
  )
}

export default TenantInfo
