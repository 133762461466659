import {IPTU_BILLING_ANNUAL} from '@root/lib/propertySumAmounts'
import reducerMatch from '@root/lib/reducerMatch'
import {ImoveisActionTypes} from '@root/redux/property/property.types'
import {SigninTypes} from '@root/redux/signin/signin.types'
import produce from 'immer'
import contractMapper from 'status-mapper/mapper/contract-mapper'

const stepsList = [
  {
    id: 'ownerdata',
    has_form: true,
  },
  {
    id: 'owneraddress',
    has_form: true,
  },
  {
    id: 'bankinfo',
    has_form: true,
  },
  {
    id: 'property',
    has_form: true,
  },
  {
    id: 'validity',
    has_form: true,
  },
  {
    id: 'rentfees',
    has_form: true,
  },
  {
    id: 'penalties',
    has_form: true,
  },
  {
    id: 'forum',
    has_form: true,
  },
  {
    id: 'guarantees',
    has_form: true,
  },
  {
    id: 'tenant',
    has_form: true,
  },
  {
    id: 'survey',
    has_form: true,
  },
  {
    id: 'witnesses',
    has_form: true,
  },
  {
    id: 'extrainfo',
    has_form: false,
  },
  {
    id: 'created',
    has_form: false,
  },
]

// For now type definitions are here
// https://github.com/MobenDev/database-structure#property
export const createPropertyInitialState = () => {
  const initial = {
    snap: undefined,
    currentPropertyId: undefined,
    proposals: {
      manager: undefined,
    },
    data: {
      ad: {
        description: '',
        video_link: '',
        photos: [],
      },
      ad_disabled_by_admin: false,
      ad_enabled: false,
      ad_form_filled: false,
      uid: null,
      ower_id: '',
      alias: '',
      occupation_type: '', // vazio ou ocupado
      occupation_sector: '', // residencial ou comercial
      furnished: '', // imovel mobiliado
      managed_by_realestate: '', // gerenciado por imobiliaria
      rent_amount: null,
      condominium_amount: null,
      iptu_amount: null,
      iptu_billing_type: IPTU_BILLING_ANNUAL,
      contract: {
        status: contractMapper.status.BUILDING,
      }, // dados do form de contrato
      contract_document: null, // contrato existente no caso de imovel ocupado
      deed: null, // escritura
      power_attorney: null, // procuracao
      tenant: null, // id do inquilino que foi aprovado e vai morar no imovel
      credit_analysis: [], //IDS dos inquilinos para analize de credito
      invite_emails: [], //emails dos interessados
      created_at: null,
      proposals: [],
      selected_proposal: {},
      more_about: {
        pets: '',
        furnished: '',
        smoking: '',
      },
      address: {
        zip: '',
        street: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        street_number: '',
        formatted: '',
      },
      confirmations: {
        pre_register: false,
      },
      specs: {
        bathrooms: 0,
        footage: 0,
        garage: 0,
        rooms: 0,
        suites: 0,
        trousseau: false,
      },
    },
  }

  initial.data.contract.progress = stepsList
    .filter((step) => step.has_form)
    .reduce((obj, step) => {
      obj[step.id] = {
        created_at: null,
        updated_at: null,
      }

      return obj
    }, {})

  return initial
}

const initialState = createPropertyInitialState()
const propertyReducers = {
  [SigninTypes.LOG_OUT]: () => createPropertyInitialState(),

  [ImoveisActionTypes.RESET_PROPERTY_FROM_SNAP]: (state, action) => {
    const {snap, data, uid} = action.payload
    return {
      ...state,
      snap,
      data,
      currentPropertyId: uid,
    }
  },
  [ImoveisActionTypes.SET_CURRENT_PROPERTY_ID]: (state, action) => {
    return {
      ...state,
      currentPropertyId: action.payload.uid,
    }
  },
  [ImoveisActionTypes.SET_CURRENT_IMOVEL]: (state, action) => {
    return {
      ...state,
      data: action.payload,
    }
  },
  [ImoveisActionTypes.SET_CURRENT_PROPERTY_REF]: (state, action) => {
    return {
      ...state,
      snap: action.payload,
    }
  },

  [ImoveisActionTypes.RESET_PROPERTY_INITIAL_STATE]: () => {
    return createPropertyInitialState()
  },

  [ImoveisActionTypes.SET_PROPERTY_VIACEP_ADDRESS]: (state, {payload}) => {
    const {logradouro, cep, bairro, localidade, uf} = payload
    return produce(state, (draft) => {
      draft.data.address = {
        zip: cep,
        street: logradouro,
        neighborhood: bairro,
        city: localidade,
        state: uf,
      }
    })
  },
  [ImoveisActionTypes.UPDATE_PROPERTY_ADDRESS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.data.address = payload.address
    })
  },
  [ImoveisActionTypes.SET_PROPERTY_ADDRESS_NUMBER]: (state, {payload}) => {
    const {number} = payload
    return produce(state, (draft) => {
      draft.data.address.street_number = number
    })
  },

  [ImoveisActionTypes.SET_ESTADO_DO_IMOVEL]: (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        occupation_type: action.payload,
      },
    }
  },

  [ImoveisActionTypes.SET_CONTRATO_ENVIADO]: (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        confirmations: {
          ...state.property.confirmations,
          contract: true,
        },
      },
    }
  },

  [ImoveisActionTypes.SET_FORMA_DE_PAGAMENTO]: (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        payment_method: action.payload,
      },
    }
  },

  [ImoveisActionTypes.ADD_CREDIT_ANALYSIS]: (state, {payload}) => {
    const newArray = state.property.credit_analysis
    newArray.splice(state.property.credit_analysis.length, 0, payload.uid)
    return {
      ...state,
      data: {
        ...state.data,
        credit_analysis: newArray,
      },
    }
  },
  [ImoveisActionTypes.CLEAR_CREDIT_ANALYSIS]: (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        credit_analysis: [],
      },
    }
  },

  [ImoveisActionTypes.SET_TENANT]: (state, {payload}) => {
    return {
      ...state,
      data: {
        ...state.data,
        tenant: payload.id,
      },
    }
  },
  [ImoveisActionTypes.REMOVE_TENANT]: (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        tenant: '',
      },
    }
  },

  [ImoveisActionTypes.SET_PROPERTY_CONTRACT_FILE]: (state, {payload}) => {
    return {
      ...state,
      document: payload.file,
      data: {
        ...state.data,
        contract_document: payload.file,
        has_contract: !!payload.file,
      },
    }
  },

  [ImoveisActionTypes.SET_PROPERTY_CONTRACT_FILES]: (state, {payload}) => {
    return {
      ...state,
      document: {
        ...payload.contractDocument,
        extra_files: [...payload.extraFiles],
      },
      data: {
        ...state.data,
        contract_document: {
          ...payload.contractDocument,
          extra_files: [...payload.extraFiles],
        },
        has_contract: !!payload,
      },
    }
  },

  [ImoveisActionTypes.SET_INVITE_IMOVEL]: (state, {payload}) =>
    produce(state, (draft) => {
      draft.data = payload
    }),

  [ImoveisActionTypes.SET_OR_UPDATE_PROPERTY_ALIAS]: (state, {payload}) => {
    return {
      ...state,
      data: {
        ...state.data,
        alias: payload,
      },
    }
  },

  [ImoveisActionTypes.SET_PROPERTY_PROPOSAL_MANAGER_INSTANCE]: (state, {payload}) =>
    produce(state, (draft) => {
      draft.proposals.manager = payload.manager
    }),
}

export default reducerMatch(propertyReducers, initialState)
