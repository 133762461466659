/** @module ownerLoader */
import {firestore} from '@root/firebase/firebase.utils'
import {convertDateToBr} from '@root/lib/dateParser'
import userModel from '@root/model/userModel'
import store from '@root/redux/store'
import {createEmptyTenant} from '@root/redux/tenantRegister/tenantRegister.reducer'
import {DATASOURCE_ADMIN, DATASOURCE_ME, DATASOURCE_OTHER, USER_SOURCE_CONTRACT} from '@root/utils/constants'

let isLoading = false

export const ownerContractSave = async (propertySnap, source, userRef, userUpdated) => {
  let prevOwnerRef = propertySnap.data()?.contract?.owner?.user ?? null

  if (source === DATASOURCE_OTHER || source === DATASOURCE_ADMIN) {
    prevOwnerRef = userRef
  }
  await propertySnap.ref.update('contract.owner', {
    data_source: source,
    user: prevOwnerRef,
  })
  const userPrepared = userModel.prepareForDatabase(userUpdated)
  if (userRef.exists) {
    await userRef.update(userPrepared, {merge: true})
  } else {
    await userRef.set(userPrepared)
  }
}

export const ownerLoader = async (contractOwner, currentSource) => {
  // if (isLoading) {
  //   return {
  //     userData: {},
  //   }
  // }

  // isLoading = true
  let source = currentSource ?? contractOwner?.data_source
  if (!source) {
    source = DATASOURCE_ME
  }

  let ownerInfo
  if (source === DATASOURCE_OTHER) {
    ownerInfo = await getOtherData(contractOwner)
  } else {
    ownerInfo = getMeFormData()
  }

  ownerInfo.data.birthdate = convertDateToBr(ownerInfo.data.birthdate)
  // isLoading = false
  return {
    source,
    userRef: ownerInfo.ref,
    userData: ownerInfo.data,
  }
}

const getMeFormData = () => ({
  ref: store.getState().user.firebaseRef,
  data: store.getState().user.user,
})

const getOtherData = async (contractOwner) => {
  if (contractOwner?.user) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const ownerSnap = await contractOwner.user.get()
        resolve({
          ref: contractOwner.user,
          data: ownerSnap.data(),
        })
      }, 700)
    })
  } else {
    const ref = firestore.collection(userModel.COLLECTION_NAME).doc()
    const owner = {
      ...createEmptyTenant(),
      uid: ref.id,
      active: false,
      origin_source: USER_SOURCE_CONTRACT,
    }
    return {
      ref: ref,
      data: owner,
    }
  }
}
