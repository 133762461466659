import CheckboxRender from '@root/components/FormHelper/renderers/CheckboxRender'
import CheckboxSingleRender from '@root/components/FormHelper/renderers/CheckboxSingleRender'
import CurrencyRender from '@root/components/FormHelper/renderers/CurrencyRender'
import DatePickerRender from '@root/components/FormHelper/renderers/DatePickerRender'
import FileRender from '@root/components/FormHelper/renderers/FileRender'
import MaskRender from '@root/components/FormHelper/renderers/MaskRender'
import RadioRender from '@root/components/FormHelper/renderers/RadioRender'
import RGRender from '@root/components/FormHelper/renderers/RGRender'
import SelectRender from '@root/components/FormHelper/renderers/SelectRender'
import TextAreaRender from '@root/components/FormHelper/renderers/TextAreaRender'
import TextFieldRender from '@root/components/FormHelper/renderers/TextFieldRender'

const typeToCompMap = {
  'textfield': TextFieldRender,
  'file': FileRender,
  'textarea': TextAreaRender,
  'mask': MaskRender,
  'rg': RGRender,
  'currency': CurrencyRender,
  'select': SelectRender,
  'radio': RadioRender,
  'checkbox': CheckboxRender,
  'checkbox-single': CheckboxSingleRender,
  'datepicker': DatePickerRender,
}
const forwardHandler = (originalHandler, customHandler, args) => {
  let customValue
  if (originalHandler) {
    customValue = originalHandler.apply(this, args)
  }
  if (customHandler) {
    customValue = customHandler.apply(this, args)
  }
  return customValue ?? args[0]?.target?.value
}

const itemRenderer = (controllerProps, formItemProps, hasError) => {
  const renderFunction = typeToCompMap[formItemProps.type]

  const helpers = {
    hasError,
    inputRef: controllerProps.ref, //agora o RHF manda o ref
    // applyShrink: controllerProps.value !== undefined && controllerProps.value !== '',
    applyShrink: true,
    forwardOnChange: function () {
      forwardHandler.apply(this, [controllerProps.onChange, formItemProps.onChange, arguments])
    },
    forwardOnBlur: function () {
      forwardHandler.apply(this, [controllerProps.onBlur, formItemProps.onBlur, arguments])
    },
    forwardOnFocus: function () {
      forwardHandler.apply(this, [controllerProps.onFocus, formItemProps.onFocus, arguments])
    },
  }

  if (renderFunction) {
    return renderFunction(controllerProps, helpers, formItemProps)
  }

  return null
}

export default itemRenderer
