/* @module helper-functions */

export const debounce = (func, delay) => {
  let inDebounce
  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

/*
 * @typedef {{
        name: string,
        size: number,
        type: string,
        content: string,
      }} FileSerialized
 */

/**
 * Converte um objeto to tipo File em plain object, e assim e possivel usar o JSON.stringfy por ex
 * @function
 * @param {File} file
 * @return {Promise<FileSerialized>}
 */
export const serializeFile = async (file) => {
  // const imageAsText = URL.createObjectURL(file)
  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.onload = (e) => {
      const imageAsText = e.target.result
      resolve({
        name: file.name,
        size: file.size,
        type: file.type,
        content: imageAsText,
      })
    }
    reader.onerror = (e) => reject(e.error)
    reader.readAsDataURL(file)
  })
}

/**
 * @param {string} filestr
 * @return {Blob}
 */
export const stringToBlob = (filestr) => {
  return new Blob([filestr])
}

/**
 * @param {string} string64
 * @return {{data: string, type: string, matches: string[]}}
 */
export const extractBase64Info = (string64) => {
  const matches = string64.match(/^data:(.*\/.*);base64,/)
  const matchesLen = matches.length

  return {
    matches,
    data: matchesLen >= 1 ? matches[0] : '',
    type: matchesLen >= 2 ? matches[1] : '',
  }
}
