export const CartTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',
  SET_CART_CURRENT_INDEX: 'SET_CART_CURRENT_INDEX',
  SET_CURRENT_CART: 'SET_CURRENT_CART',
  SET_CART_LIST: 'SET_CART_LIST',
  SET_CART_LIST_ALL: 'SET_CART_LIST_ALL',
  SET_CART_PAYMENT_METHOD: 'SET_CART_PAYMENT_METHOD',
  SET_PROPERTY_TYPE: 'SET_PROPERTY_TYPE',
  SET_CART_ITEM_BY_ID: 'SET_CART_ITEM_BY_ID',
  REMOVE_FROM_CART_BY_ID: 'REMOVE_FROM_CART_BY_ID',
  REMOVE_FROM_LIST_ALL: 'REMOVE_FROM_LIST_ALL',
}
