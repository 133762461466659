import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class AdRepository extends BaseHttpRepository {
  async leads(propertyId) {
    const resp = await this._api.get(`propertyads/leads/${propertyId}`)
    return resp
  }
}

export const adRepoInstance = new AdRepository()

export const useAdLeads = hookRunner(adRepoInstance, 'leads', [])
