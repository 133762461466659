export const PAYMENT_METHOD_BOLETO = 'boleto'
export const PAYMENT_METHOD_PIX = 'pix'
export const PAYMENT_METHOD_TRANSFER = 'transfer'
export const PAYMENT_METHOD_CASH = 'cash'
export const PAYMENT_METHOD_OTHER = 'other'

export const paymentMethodsMap = {
  [PAYMENT_METHOD_BOLETO]: 'Boleto Moben',
  [PAYMENT_METHOD_PIX]: 'PIX Moben',
  [PAYMENT_METHOD_TRANSFER]: 'Transferencia bancaria',
  [PAYMENT_METHOD_CASH]: 'Dinheiro',
  [PAYMENT_METHOD_OTHER]: 'Outra forma',
}

const checkType = (type, expectedType) => type === expectedType

export const isBoleto = (type) => checkType(type, PAYMENT_METHOD_BOLETO)
export const isPix = (type) => checkType(type, PAYMENT_METHOD_PIX)
export const isTransfer = (type) => checkType(type, PAYMENT_METHOD_TRANSFER)
export const isCash = (type) => checkType(type, PAYMENT_METHOD_CASH)
export const isOther = (type) => checkType(type, PAYMENT_METHOD_OTHER)
export const isPaymentManaged = (type) => isBoleto(type) || isPix(type)
