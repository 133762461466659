import {formatBrCurrencyToFloat} from '@root/lib/moneyFormater'

/**
 * @param {string} feeTarget
 * @param {string} responsible
 * @param {boolean} [excludeFromRent]
 * @param {boolean} [isImmune]
 * @return {boolean}
 */
export const shouldIncludeFeeWithRent = (feeTarget, responsible, excludeFromRent, isImmune) => {
  const isCondominium = feeTarget === 'condominium'
  const guessInclude = ['tenant', 'include_with_rent', 'owner_contract_tenant_pay_with_rent'].includes(responsible)
  const guessIncludeCondo = ['include_with_rent'].includes(responsible)
  let includeWithRent = isCondominium ? guessIncludeCondo : guessInclude

  if (isImmune) {
    includeWithRent = false
  } else if (excludeFromRent) {
    // e meio confuso sim, por que no front o checkbox e para "nao incluir/nao aplicar", ou seja, vem true, para nao fazer algo, mas no banco precisa inverter esse valor
    includeWithRent = !excludeFromRent
  }

  return includeWithRent
}
/**
 * @param {string} baseProp Nome da prop iptu|condominium|fire_insurance|
 * @param fee Essencialmente o objeto rentfees
 * @param {year|month} inputAmountType Tipo de valor da entrada, mensal ou anual
 * @param {boolean} [excludeFromRent]
 * @return {{[p: string]: number|boolean|*|string}}
 */
export const buildFeesValues = (baseProp, fee, inputAmountType, excludeFromRent) => {
  const root = baseProp
  const annual = `${baseProp}_annual`
  const responsible = `${baseProp}_responsible`
  const immune = `${baseProp}_immune`
  const include = `include_${baseProp}`

  const result = {
    [root]: 0,
    [annual]: 0,
    [include]: false,
    [responsible]: '',
  }

  if (!fee) {
    return result
  }

  const responsibleVal = fee[responsible] ?? ''

  const amount = formatBrCurrencyToFloat(fee[root])
  let amountMonth = 0
  let amountYear = 0

  if (inputAmountType === 'year') {
    amountYear = amount
    amountMonth = amount > 0 ? amount / 12 : 0
  } else if (inputAmountType === 'month') {
    amountYear = amount * 12
    amountMonth = amount
  }

  result[root] = amountYear
  result[annual] = amountMonth // sim temos um problema de nomeclatura que nao sei bem como surgiu, mas o annual na verdade e o mensal
  result[responsible] = responsibleVal
  result[include] = shouldIncludeFeeWithRent(baseProp, responsibleVal, excludeFromRent, fee[immune])
  return result
}
