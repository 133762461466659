/** @module dateSort */
import dateParser from '@root/lib/dateParser'

/**
 * @param {DateLike} dateAEntry nao precisa aplicar dateParser antes, isso ja e feito automaticamente
 * @param {DateLike} dateBEntry nao precisa aplicar dateParser antes, isso ja e feito automaticamente
 * @param {string} direction asc ou desc
 * @return {number}
 */
export const dateSort = (dateAEntry, dateBEntry, direction = 'asc') => {
  let dateA, dateB
  let timeA, timeB
  const isAsc = direction === 'asc'

  dateA = dateParser(dateAEntry)
  dateB = dateParser(dateBEntry)

  if (!dateA || !dateB) {
    return -1
  }

  timeA = dateA.getTime()
  timeB = dateB.getTime()

  if (timeA > timeB) {
    return isAsc ? 1 : -1
  } else if (timeB > timeA) {
    return isAsc ? -1 : 1
  }

  return 0
}
export const dateSortAsc = (dateAEntry, dateBEntry) => dateSort(dateAEntry, dateBEntry, 'asc')
export const dateSortDesc = (dateAEntry, dateBEntry) => dateSort(dateAEntry, dateBEntry, 'desc')
