/** @module propertyModel */
import 'firebase/firestore'

import fetcher, {objectToQueryString} from '@root/application/infra/api/fetcher'
import firebase from 'firebase/app'
import {firestore} from '@root/firebase/firebase.utils'
import produce from 'immer'
import dateParser from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {PROPERTY_STATUS_PENDING} from '@root/model/cartModel'
import {getCollectionById, getCollectionFromRef} from '@root/model/firestorePromisify'
import {contractMapper} from 'status-mapper'
import {SEND_MAIL_FAIL, SEND_MAIL_SUCCESS} from '@root/utils/constants'

const COLLECTION_NAME = 'report'

/**
 * @param {Date} start
 * @param {Date} end
 * @param {Date} prevStart
 * @param {Date} prevEnd
 * @return Promise<string|void>
 */
const fetchDaily = async (start, end, prevStart, prevEnd) => {
  const params = {}

  params.start = start
  params.end = end
  params['prev-period-start'] = prevStart
  params['prev-period-end'] = prevEnd

  const result = await fetchDailyWithQueryParams(objectToQueryString(params))
  return result
}

/**
 * @param {string} queryParams
 * @return Promise<string|void>
 */
const fetchDailyWithQueryParams = async (queryParams) => {
  const result = await fetcher.get('/admin/report?' + queryParams)
  return result
}

const reportModel = {
  COLLECTION_NAME,
  fetchDaily,
  fetchDailyWithQueryParams,
}

export default reportModel
