import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import {Box} from '@root/components/Layout/Container/Box/Box'
import actionDispatcher from '@root/lib/actionDispatcher'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractFromDatabaseAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import routes, {routeWithID} from '@root/utils/routes'

import {useStyles} from './styles'

export function ExtraInformation() {
  const classes = useStyles()
  const history = useHistory()
  const {control, errors, watch, setValue} = useForm()

  const property = useSelector((s) => s.property.data)
  const propertyId = useSelector((s) => s.property.currentPropertyId)

  const extraInfo = watch('extra_info')
  const firstTimeCreatingContract = !property?.contract?.contract_file
  const isTermsAccepted = extraInfo?.accepted === true || !firstTimeCreatingContract

  const actions = actionDispatcher({
    setContractStepAction,
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setContractFromDatabaseAction,
  })

  const proposalHelper = useMemo(() => ProposalHelper.initWithProperty(property), [property])

  const handleClickContinuar = async () => {
    const hasTenant = proposalHelper.hasTenantRef()
    if (!hasTenant) {
      alert('Você precisa ter um inquilino para finalizar o contrato.')
      history.push(routeWithID(routes.CONTRACT_TENANT, propertyId))
      return
    }

    history.push(getNextStep().path)
  }

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    if (!firstTimeCreatingContract) {
      setValue('extra_info', {accepted: true})
    }
  }, [firstTimeCreatingContract])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.extraInformation}`}>
        <Typography variant="body1" className="bold" align="center">
          Informações adicionais
        </Typography>

        <Box bgType="gray">
          <div>
            <section>
              <Typography variant="body1" className="bold">
                DocuSign
              </Typography>
              <Typography variant="body2">
                Utilizamos o sistema de assinatura eletrônica da DocuSign. Assim que todos aprovarem a Minuta do
                Contrato, vocês receberão o contrato nos e-mails cadastrados com os detalhes para prosseguir com a
                assinatura. A assinatura eletrônica é o meio mais moderno para assinar documentos sem sair de casa e de
                qualquer dispositivo.
              </Typography>
            </section>
          </div>
        </Box>

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="checkbox"
          name="extra_info"
          options={[
            {
              label: 'Li e estou ciente destas informações',
              value: 'accepted',
            },
          ]}
        />
      </form>

      <ContractContainerBottom>
        <ContractContinueButton onClick={handleClickContinuar} buttonProps={{disabled: !isTermsAccepted}} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
