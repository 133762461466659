import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {SEND_MAIL_ALERT_ZINDEX, SEND_MAIL_FAIL, SEND_MAIL_SUCCESS} from '@root/utils/constants'

const EmailAlert = ({fetchInviteByEmailResponse, isVisible}) => {
  const [alertMsg, setAlertMsg] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('')
  const ALERT_SUCCESS = 'success'
  const ALERT_FAIL = 'error'

  useEffect(() => {
    if (fetchInviteByEmailResponse !== '') {
      switch (fetchInviteByEmailResponse) {
        case SEND_MAIL_SUCCESS:
          setAlertMsg(SEND_MAIL_SUCCESS)
          setAlertSeverity(ALERT_SUCCESS)
          break
        case SEND_MAIL_FAIL:
          setAlertMsg(SEND_MAIL_FAIL)
          setAlertSeverity(ALERT_FAIL)
          break
      }
    }
  }, [fetchInviteByEmailResponse])

  return (
    <Snackbar
      style={{zIndex: SEND_MAIL_ALERT_ZINDEX}}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={4000}
      open={isVisible}>
      <Alert severity={alertSeverity}>{alertMsg}</Alert>
    </Snackbar>
  )
}

EmailAlert.propTypes = {
  fetchInviteByEmailResponse: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
}

export default EmailAlert
