import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {ReactComponent as FaceBookIcon} from '@root/assets/new/facebook-icon.svg'
import {ReactComponent as InstagramIcon} from '@root/assets/new/instagram-icon.svg'
import {ReactComponent as LinkedinIcon} from '@root/assets/new/linkedin-icon.svg'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import {MOBEN_WHATSAPP, SOCIAL_MEDIA_URLS} from '@root/utils/constants'
import React from 'react'

import {useStyles} from './styles'

const Footer = () => {
  const classes = useStyles()

  const socialMediaStyles = {backgroundColor: 'transparent'}

  return (
    <footer className={classes.footer}>
      <div className={classes.footerOuterContainer}>
        <div>
          {/* <Typography variant="h3">Sobre a Moben</Typography> */}
          <Typography variant="h3">
            Moben: uma plataforma pensada exclusivamente para a relação entre inquilino e proprietário.
          </Typography>
        </div>
        <Divider style={{background: '#D3D3D3', opacity: 0.3, margin: '25px 0'}} />
        <div className={classes.bottomSection}>
          <div>
            <Typography variant="h3">Fale Conosco</Typography>
            <Typography variant="h5">
              Dúvidas, sugestões ou comentários?
              <br />
              <WhatsAppLinkWrapper>Contato via WhatsApp</WhatsAppLinkWrapper>
            </Typography>
          </div>
          <div>
            <Typography variant="h4" className={classes.siganos}>
              Siga-nos nas redes sociais
            </Typography>
            <div className={classes.socialMediaContainer}>
              <IconButton style={socialMediaStyles}>
                <a href={SOCIAL_MEDIA_URLS.linkedin} target="_blank" rel="noreferrer">
                  <LinkedinIcon />
                </a>
              </IconButton>
              <IconButton style={socialMediaStyles}>
                <a href={SOCIAL_MEDIA_URLS.instagram} target="_blank" rel="noreferrer">
                  <InstagramIcon />
                </a>
              </IconButton>
              <IconButton style={socialMediaStyles}>
                <a href={SOCIAL_MEDIA_URLS.facebook} target="_blank" rel="noreferrer">
                  <FaceBookIcon />
                </a>
              </IconButton>
            </div>
          </div>
        </div>
        <Divider style={{background: '#D3D3D3', opacity: 0.3, margin: '25px 0'}} />
        <div style={{textAlign: 'center'}}>
          Moben Software Serviços Ltda. 35.269.925/0001-17 | Para atividades que envolvam intermediação CRECI/SP
          041.303-J
        </div>
      </div>
    </footer>
  )
}

export default Footer
