import IconMoney from '@root/components/icons/IconMoney'
import PropTypes from 'prop-types'
import React from 'react'
const IconOpen = ({fill, size}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.323 16.323"
      width={size}
      height={size}
      style={{width: size, height: size}}>
      {' '}
      <g id="icons_Q2" data-name="icons Q2" transform="translate(0)">
        {' '}
        <path
          id="Path_122"
          data-name="Path 122"
          d="M25.645,4a.816.816,0,0,0-.816.939.857.857,0,0,0,.857.694H29.4l-5.1,5.1a.816.816,0,0,0-.082,1.143.735.735,0,0,0,.612.286.816.816,0,0,0,.571-.245l5.142-5.142v3.713a.857.857,0,0,0,.694.857.816.816,0,0,0,.939-.816V4.816A.816.816,0,0,0,31.358,4Z"
          transform="translate(-15.851 -4)"
          fill={fill}
        />{' '}
        <path
          id="Path_123"
          data-name="Path 123"
          d="M19.384,14.61a.857.857,0,0,0-.694.857V18.69H5.632V5.632H8.9a.816.816,0,0,0,.816-.939A.857.857,0,0,0,8.856,4H4.816A.816.816,0,0,0,4,4.816v14.69a.816.816,0,0,0,.816.816h14.69a.816.816,0,0,0,.816-.816V15.426a.816.816,0,0,0-.939-.816Z"
          transform="translate(-4 -4)"
          fill={fill}
        />{' '}
      </g>
    </svg>
  )
}
IconOpen.propTypes = {
  size: PropTypes.any,
  fill: PropTypes.string,
}
IconOpen.defaultProps = {
  size: '16.323',
  fill: '#FFF',
}

export default IconOpen
