import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {ReactComponent as Mastercard} from '@root/assets/new/mastercard.svg'
import {ReactComponent as Visa} from '@root/assets/new/visa.svg'
import {Button} from '@root/components/Button/Button'
import CreditCardIcon from '@root/components/CreditCardIcon'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {dateSortDesc} from '@root/lib/dateSort'
import userModel from '@root/model/userModel'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import routes from '@root/utils/routes'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardInfoStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  cardInfo: {
    'display': 'flex',
    'flexDirection': 'column',
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  cardBrandNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  cardNumber: {
    'display': 'flex',
    'alignItems': 'center',
    'marginLeft': 5,
    '& > span:first-child': {
      transform: 'translateY(3px)',
      marginRight: 5,
    },
  },
  cardActions: {
    'display': 'flex',
    'alignItems': 'center',
    '& > span': {
      marginRight: 10,
      fontSize: '.5rem',
      fontWeight: 700,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '2px 10px',
      color: theme.palette.primary.main,
    },
  },
  title: {
    'padding': '16px 24px',
    '& h5': {fontWeight: 600},
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    'flex': '1 1 auto',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    '& button:first-child': {
      marginBottom: '12px',
    },
  },
}))

const CreditCardsList = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const creditCards = useSelector((s) => s.user.user.cards) ?? []
  const userRef = useSelector((s) => s.user.firebaseRef)
  const cardsOrdered = [...creditCards].reverse()

  const [cardToExclude, setCardToExclude] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)

  const goToNewCard = () => history.push(routes.MYDATA_PROFILE_ADD_CARD)

  const selectCardOnClick = async (card_id) => {
    dispatch(setGlobalLoaderAction(true))
    await userModel.fetchSelectCard(userRef.id, card_id)
    dispatch(setGlobalLoaderAction(false))
  }

  const excludeCardOnClick = (card) => {
    setCardToExclude(card)
    setDialogOpen(true)
  }

  const confirmExcludeCardOnClick = async () => {
    const newCreditCards = creditCards.filter((creditCard) => creditCard.card_id !== cardToExclude.card_id)
    await userRef.update('cards', newCreditCards)
    setDialogOpen(false)
    setCardToExclude({})
  }

  const dialogCloseOnClick = () => {
    setCardToExclude({})
    setDialogOpen(false)
  }

  const paperProps = {
    borderRadius: '20px',
    width: '376px',
    height: '461px',
    display: 'flex',
    flexDirection: 'column',
  }

  return (
    <>
      {cardsOrdered.map((card) => (
        <Box key={card.card_id} m="0 0 15px">
          <div className={classes.cardRoot}>
            <div className={classes.cardInfoStatus}>
              <div className={classes.cardInfo}>
                <div className={classes.cardBrandNumber}>
                  {/* {card.brand === 'mastercard' ? <Mastercard /> : <Visa />} */}
                  <CreditCardIcon brand={card.brand} />
                  <div className={classes.cardNumber}>
                    <span>****</span>
                    <span>{card.last_digits}</span>
                  </div>
                </div>
                <div>
                  <span>{card.holder_name}</span>
                </div>
              </div>
            </div>

            <div className={classes.cardActions}>
              {!card.active && (
                <>
                  <Button onClick={() => excludeCardOnClick(card)} noBg p="0.5rem 1rem">
                    Excluir
                  </Button>
                  <Button onClick={() => selectCardOnClick(card.card_id)} noBg p="0.5rem 1rem">
                    Selecionar
                  </Button>
                </>
              )}

              {card.active && <span>SELECIONADO</span>}
            </div>
          </div>
        </Box>
      ))}

      <Button onClick={goToNewCard}>Adicionar cartão</Button>

      <Dialog
        open={dialogOpen}
        onClose={dialogCloseOnClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: paperProps,
        }}
        BackdropProps={{
          style: {
            background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
          },
        }}>
        <DialogTitle className={classes.title}>
          <Typography variant="h5" color="secondary" align="center">
            Tem certeza de que deseja excluir este cartão?
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div className={classes.cardInfo}>
            <div className={classes.cardBrandNumber}>
              {cardToExclude.brand === 'mastercard' ? <Mastercard /> : <Visa />}
              <div className={classes.cardNumber}>
                <span>****</span>
                <span>{cardToExclude.last_digits}</span>
              </div>
            </div>
            <div>
              <span>{cardToExclude.holder_name}</span>
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.buttonsContainer}>
          <Button fz="15px" h="48px" onClick={confirmExcludeCardOnClick}>
            Sim
          </Button>
          <Button fz="15px" h="48px" noBg onClick={dialogCloseOnClick}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreditCardsList
