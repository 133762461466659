import {Card, Grid, Typography} from '@material-ui/core'
import {Button as MUButton} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import {
  creditAnalysisRepoInstance,
  useGetResultFromAny,
} from '@root/application/service/repository/credit-analysis/CreditAnalysisRepository'
import {useProposalSingle} from '@root/application/service/repository/proposal/ProposalRepository'
import AddProposalInterestedActions from '@root/components/AddProposalInterestedActions'
import AnalysisButton from '@root/components/App/CreditAnalysis/ButtonsMap/AnalysisButton'
import {Button} from '@root/components/Button/Button'
import InterestedAnalysis from '@root/components/InterestedAnalysis/InterestedAnalysis'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import {
  ModalAcceptProposal,
  ModalAnalysisResult,
  ModalConsentCreditAnalysis,
  ModalRequestCreditAnalysis,
} from '@root/components/Modal/Modal'
import useModalControl from '@root/components/Modal/useModalControl'
import {Navbar} from '@root/components/Navbar/Navbar'
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import Page from '@root/components/Page'
import analyticsLogger from '@root/firebase/analytics.utils'
import {useQuery} from '@root/hooks/urlHook'
import useWhatappLinker from '@root/hooks/useWhatappLinker'
import actionDispatcher from '@root/lib/actionDispatcher'
import addressDisplay, {ADDRESS_VERBOSITY_COMPLETE} from '@root/lib/addressDisplay'
import calcDeclaredIncomeAnalysis, {getPropertyRentAmount} from '@root/lib/calcDeclaredIncomeAnalysis'
import {isProgressEmpty, updateProgressData} from '@root/lib/contract/progressChecker'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import {getNextStep, stepRouteMatchCurrent} from '@root/lib/contract/stepWalker'
import {convertDateToBr} from '@root/lib/dateParser'
import {discordNotifyApproveProposal, discordNotifyCreditAnalysis} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import intVal, {onlyNumbers} from '@root/lib/intVal'
import {getInviteTenant} from '@root/lib/inviteLinkCreator'
import {sendMailing} from '@root/lib/mailing'
import {proposalInterestedFinder} from '@root/lib/proposalFinder'
import {proposalLoaderSingle} from '@root/lib/proposalLoader'
import {readableValue} from '@root/lib/simpleValuesDisplayMap'
import sleep from '@root/lib/sleep'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import userModel from '@root/model/userModel'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setSelectedProposal} from '@root/redux/proposal/proposal.actions'
import routes, {routeWithID} from '@root/utils/routes'
import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'

import useStyles from './styles'

const analysisItens = ['documentacao', 'negativacao', 'ocorrencia_de_cobrancas', 'score_serasa']
// analise credito
// https://xd.adobe.com/view/c86a7d16-5397-41ca-aed7-c90618ac14ab-9d39/screen/28c26ee0-aaeb-4df8-bbd5-34a7b7bd6af6

const ProposalView = ({match}) => {
  const classes = useStyles()
  const {hasParam, getParam} = useQuery()
  const history = useHistory()
  const waLinker = useWhatappLinker()

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setNavbarTitleAction,
    setSelectedProposal,
  })

  const proposalId = match.params.proposalId

  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const propertyId = useSelector((s) => s.property.currentPropertyId)

  const [proposalConsolidated, setProposalConsolidated] = useState()
  const [selectedInterested, setSelectedInterested] = useState()
  const [analysisDescriptionData, setAnalysisDescriptionData] = useState()

  const analysisRequestControl = useModalControl()
  const analysisConsentControl = useModalControl()
  const acceptProposalControl = useModalControl()
  const analysisResultControl = useModalControl()

  const selectedProposalRaw = property.proposals[proposalId]

  const analysisResultHook = useGetResultFromAny({
    propertyId: property.uid,
    proposalId,
    analysisId: selectedProposalRaw?.analysis_id,
    provider: selectedProposalRaw?.provider,
  })
  /** @var {AnalysisResult} proposalEntry */
  const analysisResult = analysisResultHook.response

  const proposalHook = useProposalSingle(propertyId, proposalId)
  /** @var {ProposalSingle} proposalEntry */
  const proposalEntry = proposalHook.response

  const interestedsSize = proposalEntry?.interesteds?.length ?? 0
  const hasInteresteds = interestedsSize > 0
  const hasMultipleInteresteds = interestedsSize > 1
  const isProposalModeExpress = proposalEntry?.mode === ProposalHelper.MODE_EXPRESS
  const isProposalModeDefault = !isProposalModeExpress

  const isSelectedProposal = proposalEntry?.is_proposal_selected
  const isWaiting = proposalEntry?.status === 'waiting'
  const isPending = proposalEntry?.status === 'pending'
  const isComplete = proposalEntry?.status === 'complete'

  const inviteLink = useMemo(() => getInviteTenant(property.uid, proposalId, isProposalModeDefault), [
    property.uid,
    proposalId,
    isProposalModeDefault,
  ])

  const totalIncome = useMemo(() => {
    let total = 0
    if (proposalEntry) {
      total = proposalEntry.interesteds.reduce(
        (prevAmount, user) => prevAmount + (user.income - user.recurring_debts),
        0,
      )
    }

    return total
  }, [proposalEntry])

  const handleEditInterested = (interestedId) => {
    history.push(routeWithID(routes.DASHBOARD_PROPOSALS_EDIT, interestedId))
  }

  /**
   * @deprecated
   * @return {Promise<void>}
   */
  const handleDeclineProposal = async () => {
    const progressInfo = updateProgressData(property, 'tenant')
    progressInfo.payload.created_at = null
    progressInfo.payload.updated_at = null

    await propertySnap.ref.update({
      [progressInfo.path]: progressInfo.payload,
      'selected_proposal.uid': '',
      'tenant': null,
    })

    await userModel.removeTenantOf(proposalEntry.interesteds[0].user.id, propertySnap.ref)
    actions.setSelectedProposal({proposalUID: ''})

    analyticsLogger.logEvent('declinar_proposta')
  }

  const handleAnalysisItem = (analysisItem) => () => {
    setAnalysisDescriptionData(analysisItem)
    analysisResultControl.open()
  }

  /**
   * @deprecated
   * @return {Promise<void>}
   */
  const acceptProposalAction = async () => {
    actions.setGlobalLoaderAction(true)
    try {
      const progressInfo = updateProgressData(property, 'tenant')
      propertySnap.ref.update({
        [progressInfo.path]: progressInfo.payload,
        'selected_proposal.uid': proposalId,
        'tenant': proposalEntry.interesteds[0].uid,
      })

      await userModel.updateTenantOf(proposalEntry.interesteds[0].user.id, propertySnap.ref)

      actions.setSelectedProposal({proposalUID: proposalId})
      analyticsLogger.logEvent('selecionar_proposta')

      let path = routes.CONTRACT_OWNER_DATA
      if (isProgressEmpty(propertySnap.data())) {
        path = routes.CONTRACT_ENTRY
      } else if (stepRouteMatchCurrent(routes.CONTRACT_TENANT)) {
        path = getNextStep().path_orig
      }

      discordNotifyApproveProposal(proposalId, property)
      history.push(routeWithID(path, property.uid))
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
      acceptProposalControl.close()
    }
  }

  const getCreditAnalysisAction = async () => {
    analyticsLogger.logEvent('analisecredito_analisar_agora', {button_name: 'Fazer analise de credito'})

    actions.setGlobalLoaderAction(true)
    try {
      const analysisResponse = await creditAnalysisRepoInstance.analyze(proposalId, propertyId)
      // const {id, status, message} = analysisResponse

      // analysisResultHook.refresh()
      proposalHook.refresh({
        ...proposalEntry,
        id: analysisResponse.id,
        status: analysisResponse.status,
      })
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      analysisRequestControl.close()
      actions.setGlobalLoaderAction(false)
    }
  }

  useEffect(() => {
    const title = getParam('name', 'Proposta')
    actions.setNavbarTitleAction(title)
  }, [])

  useEffect(() => {
    const loading = proposalHook.isLoading || analysisResultHook.isLoading
    actions.setGlobalLoaderAction(loading)
  }, [proposalHook.isLoading, analysisResultHook.isLoading])

  useEffect(() => {
    const analysisHash = {}
    if (analysisResult) {
      analysisResult.analysis.forEach((analysisItem) => {
        const docRaw = onlyNumbers(analysisItem.document)
        analysisHash[docRaw] = analysisItem
      })
    }

    if (proposalEntry) {
      const unifiedProposal = {
        ...proposalEntry,
        interesteds: proposalEntry.interesteds.map((interested) => {
          const docRaw = onlyNumbers(interested.cpf)
          return {
            ...interested,
            analysis: analysisHash[docRaw],
          }
        }),
      }

      setProposalConsolidated(unifiedProposal)
    }
  }, [proposalEntry, analysisResult])

  if (!proposalEntry) {
    return null
  }

  return (
    <div className={classes.proposalViewRoot}>
      <AddProposalInterestedActions proposalId={proposalId} shareLink={inviteLink} />

      <Typography variant="body1" className={classes.variantBody1} align="center">
        Criada em {convertDateToBr(proposalEntry.created_at)}
      </Typography>
      <Typography variant="body2" className={classes.variantBody2} align="center">
        Veja aqui as informações cadastradas nesta proposta. Se desejar, também pode gerar a análise de crédito
        individual.
        <br />
        Depois de analisar todas as informações você poderá decidir se quer prosseguir com esta proposta.
      </Typography>

      {hasMultipleInteresteds && (
        <Card className={classes.cardContainer}>
          <div className={`${classes.infoContainer} ${classes.infoTopContainer}`}>
            {isComplete && (
              <div>
                <TextBlock align="center">
                  Análise do total da renda declarada de todos os interessados.
                  <br />
                  <br />
                </TextBlock>
                <AnalysisButton
                  analysisItem={analysisResult.combined.rent_ratio}
                  onClick={handleAnalysisItem(analysisResult.combined.rent_ratio)}
                />
              </div>
            )}

            <div>
              <Typography variant="body1" align="right">
                Renda TOTAL mensal
              </Typography>
              <Typography variant="h5" color="secondary" align="right">
                <NumberFormatCustom value={totalIncome} displayType="text" />
              </Typography>
            </div>
          </div>
        </Card>
      )}

      <div id="proposal-root">
        {proposalConsolidated?.interesteds?.map((interested, index) => {
          const analysis = interested?.analysis
          const hasConsent = interested.has_consent

          return (
            <>
              <div key={interested.uid} className="credit-analisys-interested-item">
                <Card className={classes.cardContainer}>
                  <InterestedAnalysis user={interested} onEdit={handleEditInterested} />

                  {analysis && (
                    <Grid container spacing={1} className={classes.creditAnalysisInfoContainer}>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" style={{fontStyle: 'italic', marginBottom: '5px'}}>
                          Clique nos itens para mais detalhes
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <AnalysisButton
                          analysisItem={analysis.document_status}
                          onClick={handleAnalysisItem(analysis.document_status)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AnalysisButton
                          analysisItem={analysis.credit_reporting}
                          onClick={handleAnalysisItem(analysis.credit_reporting)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AnalysisButton
                          analysisItem={analysis.debt_notice}
                          onClick={handleAnalysisItem(analysis.debt_notice)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AnalysisButton
                          analysisItem={analysis.rent_ratio}
                          onClick={handleAnalysisItem(analysis.rent_ratio)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AnalysisButton
                          analysisItem={analysis.civil_action}
                          onClick={handleAnalysisItem(analysis.civil_action)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AnalysisButton analysisItem={analysis.score} onClick={handleAnalysisItem(analysis.score)} />
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </div>

              {!hasConsent && (
                <Alert
                  severity="info"
                  onClick={analysisConsentControl.open}
                  className="clickable"
                  action={
                    <MUButton color="inherit" size="small">
                      Saiba mais
                    </MUButton>
                  }>
                  <AlertTitle>
                    <Text bold>Aguardando liberação de dados do inquilino</Text>
                  </AlertTitle>
                  Conforme a Lei Geral de Proteção de Dados, o inquilino deve permitir que os seus dados sejam usados
                  para a análise de crédito
                </Alert>
              )}
            </>
          )
        })}

        {isWaiting && proposalEntry.full_consent && (
          <>
            <Typography variant="body2" align="center" style={{marginTop: 24}}>
              Você pode gerar e visualizar a análise aqui
            </Typography>
            <Button
              bgType="purple"
              w="100%"
              h="48px"
              m="10px 0"
              fz="15px"
              onClick={analysisRequestControl.open}
              className="interested-consent-available">
              Gerar análise de crédito
            </Button>
          </>
        )}
      </div>

      {isPending && (
        <>
          <br />
          <Alert severity="success">
            <AlertTitle>
              <Text bold>Análise realizada</Text>
            </AlertTitle>
            A análise esta sendo processada, em até 10 minutos os dados estarão disponíveis.
          </Alert>
        </>
      )}

      {hasInteresteds && (
        <div className={classes.aceitarPropostaButtonWrapper}>
          <Button
            bgType="green"
            disabled={isSelectedProposal}
            w="100%"
            h="48px"
            fz="15px"
            p="0.55rem 1rem"
            onClick={acceptProposalControl.open}
            id="pick-credit-analysis">
            {isSelectedProposal ? 'Você já aceitou esta proposta' : 'Escolher proposta e seguir para o contrato'}
          </Button>

          <Link to={routes.DASHBOARD_PROPOSALS_LIST}>Voltar para índice de propostas</Link>

          {isSelectedProposal && (
            <Typography
              variant="h6"
              color="secondary"
              align="center"
              className="clickable"
              style={{marginTop: 24}}
              onClick={handleDeclineProposal}>
              Declinar essa proposta e escolher outro inquilino
            </Typography>
          )}
        </div>
      )}

      {/*confirmar analise de credito*/}
      <ModalRequestCreditAnalysis
        open={analysisRequestControl.isOpen}
        interesteds={proposalEntry.interesteds}
        onConfirm={getCreditAnalysisAction}
        onClose={analysisRequestControl.close}
      />

      <ModalConsentCreditAnalysis open={analysisConsentControl.isOpen} onClose={analysisConsentControl.close} />

      {/*confirmar aceite de proposta*/}
      <ModalAcceptProposal
        open={acceptProposalControl.isOpen}
        onConfirm={acceptProposalAction}
        onClose={acceptProposalControl.close}
      />

      <ModalAnalysisResult
        open={analysisResultControl.isOpen}
        onConfirm={analysisResultControl.close}
        onClose={analysisResultControl.close}
        analysisItem={analysisDescriptionData}
      />
    </div>
  )
}

export default ProposalView
