/** @module firebaseUploader */
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'
import errorHandler, {addBreadCrumb} from '@root/lib/errorHandler'
import {setGlobalLoaderDataAction} from '@root/redux/loader/loader.actions'
import store from '@root/redux/store'
import {extractBase64Info} from '@root/utils/helper-functions'

import {storage} from '../firebase/firebase.utils'

/**
 * @typedef {{path:string, download_url: string, name:string, name_original:string}|undefined} FirebaseUploadInfo
 */

/**
 * @private
 * @param {firebase.storage.UploadTaskSnapshot} uploadSnap
 * @param {string} nameOriginal
 * @return {FirebaseUploadInfo}
 */
const getUploadInfo = async (uploadSnap, nameOriginal = '') => {
  try {
    const downloadUrl = await uploadSnap.ref.getDownloadURL()
    // console.log('downloadURL', uploadSnap, downloadUrl)
    return {
      name_original: nameOriginal,
      path: uploadSnap.ref.fullPath,
      download_url: downloadUrl,
      name: uploadSnap.ref.name,
      created_at: new Date(),
    }
  } catch (ex) {
    console.log('download info error', ex.message)
  }
  return undefined
}

/**
 * @private
 * @param {File|string} file
 * @param {string} name
 * @param {string} [prefix]
 * @param {string} section
 * @return {FirebaseUploadInfo}
 */
const uploader = async (file, name, prefix, section) => {
  const storageRef = storage.ref()
  const prefixPart = prefix ? `${prefix}_` : ''
  const fileRef = storageRef.child(`${section}/${prefixPart}${name}`)
  let uploadTask

  if (file instanceof File) {
    uploadTask = await fileRef.put(file)
  } else if (typeof file === 'string') {
    const base64Info = extractBase64Info(file)
    const contentBase64InfoStriped = file.replace(base64Info.data, '')
    uploadTask = await fileRef.putString(contentBase64InfoStriped, firebase.storage.StringFormat.BASE64, {
      contentType: base64Info.type,
    })
  }

  if (!uploadTask) {
    throw 'Unsuported upload object'
  }

  return await getUploadInfo(uploadTask, name)
}

/**
 * @param {File} file
 * @param {string} [prefix]
 * @param {string} section
 * @return {FirebaseUploadInfo}
 */
const firebaseUploader = async (file, prefix, section) => {
  try {
    return uploader(file, file.name, prefix, section)
  } catch (ex) {
    Sentry.captureException(ex)
    throw ex
  }
}

/**
 * @param {File[]} files
 * @param {string} [prefix]
 * @param {string} section
 * @return {Promise<FirebaseUploadInfo[]>}
 */
const firebaseFilesUploader = async (files, prefix, section) => {
  try {
    if (files.length) {
      let filesResult = []

      for (const file of files) {
        const f = await uploader(file, file.name, prefix, section)
        filesResult.push(f)
      }

      return filesResult
    }
  } catch (ex) {
    Sentry.captureException(ex)
    throw new Error(ex.message)
  }
}

/**
 * @param {string} filestr
 * @param {string} [prefix]
 * @param {string} section
 * @return {FirebaseUploadInfo}
 */
const firebaseBase64Uploader = async (filestr, name, prefix, section) => {
  try {
    return uploader(filestr, name, prefix, section)
  } catch (ex) {
    Sentry.captureException(ex)
  }

  return undefined
}

/**
 * @param {Object} config
 * @param {File|Object} config.file
 * @param {string} config.docUpdatePath
 * @param {DocumentReference} config.docRef
 * @param {string} config.readableName
 * @param {string} config.destinationDir
 * @param {string} [config.prefix]
 * @return {Promise<void>}
 */
const uploadAndSaveFileWithStepedModal = async ({
  file,
  docUpdatePath,
  docRef,
  readableName,
  destinationDir,
  prefix = '',
}) => {
  if (file && file instanceof File) {
    store.dispatch(setGlobalLoaderDataAction({title: `Enviando ${readableName}, aguarde...`}))

    const uploadedFile = await firebaseUploader(file, prefix, destinationDir)
    // const uploadedFile = await firebaseUploader(file, user.uid, `/user/documents/${user.uid}/`)
    try {
      await docRef.update(docUpdatePath, uploadedFile)
    } catch (ex) {
      addBreadCrumb('Arquivo ' + readableName, {file, docUpdatePath})
      errorHandler(ex)
    }
  }
}

export {firebaseUploader, firebaseFilesUploader, firebaseBase64Uploader, uploadAndSaveFileWithStepedModal}
