import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Guide1, {useGuide} from '@root/components/Guide1'
import IconOpen from '@root/components/icons/IconOpen'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import addressDisplay, {ADDRESS_VERBOSITY_COMPLETE} from '@root/lib/addressDisplay'
import storage from '@root/lib/storage'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {loadTenantPropertyAction} from '@root/redux/tenantProperty/tenantProperty.actions'
import {STORAGE_TENANT_PROPERTY_ID} from '@root/utils/constants'
import routes from '@root/utils/routes'

const useStyles = makeStyles({
  root: {
    'marginTop': '10px !important',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'gap': 15,
    '& h5': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    '& p': {
      fontSize: '13px',
      lineHeight: '17px',
      fontStyle: 'italic',
    },
  },
  cadastrarImovelContainer: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    '& > div': {
      'display': 'flex',
      'alignItems': 'center',
      '& > img': {
        marginRight: 5,
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  h5: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '22px',
  },
  p: {
    fontSize: '13px',
    fontStyle: 'italic',
    lineHeight: '17px',
  },
  wrapper: {
    margin: '15px 0',
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

export const TenantPropertyList = () => {
  const classes = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()

  const propertiesList = useSelector((state) => state.tenantProperty.list)

  const propertiesListProposal = useSelector((state) => state.user?.user?.proposal_of)

  const [tenantProposalList, setTenantProposalList] = useState([])

  const hasTenantProperties = propertiesList.length > 0
  const [hasTenantProposals, setHasTenantProposals] = useState(false)
  const hasTenantProposalsAndProperties = hasTenantProposals || hasTenantProperties

  useEffect(() => {
    dispatch(setNavbarTitleAction('Meus Imóveis'))
  })

  useEffect(() => {
    if (hasTenantProperties) {
      let proposalList = []
      proposalList = propertiesListProposal.filter((proposal) => {
        let returnStatus = false

        for (const property of propertiesList) {
          if (property.uid !== proposal.property.id) {
            returnStatus = true
            break
          }
        }
        return returnStatus
      })
      setTenantProposalList(proposalList)
      setHasTenantProposals(!!proposalList?.length > 0)
    } else {
      setTenantProposalList(propertiesListProposal)
      setHasTenantProposals(!!propertiesListProposal?.length > 0)
    }
  }, [])

  const handlePropertyViewPanel = (property) => {
    storage.save(STORAGE_TENANT_PROPERTY_ID, property.uid)
    dispatch(loadTenantPropertyAction(property))
    history.push(routes.DASHBOARD_ROOT_TENANT)
  }

  const isGuideVisible = useGuide()
  if (isGuideVisible) {
    return <Guide1 />
  }
  if (!hasTenantProposalsAndProperties) {
    return (
      <div className={classes.root}>
        <Typography variant="h4" align="center">
          Nenhum imóvel cadastrado
        </Typography>
      </div>
    )
  }
  return (
    <div className={classes.rootTenant}>
      {hasTenantProperties && (
        <>
          <Typography variant="h5" color="secondary">
            Imóveis onde sou inquilino
          </Typography>
          <div className={classes.wrapper}>
            {propertiesList.map((property, index) => {
              return (
                <Box smallPadding h="77px" key={property.uid} onClick={() => handlePropertyViewPanel(property)}>
                  <div className={`${classes.container} clickable property-container-${index}`}>
                    <div>
                      <Typography variant="h5" color="secondary" className={classes.h5}>
                        {addressDisplay(property.address, ADDRESS_VERBOSITY_COMPLETE)}
                      </Typography>
                      <Typography variant="body1" className={classes.p}>
                        Propreitário: {property.owner_data.name}
                      </Typography>
                    </div>

                    <IconWrapper>
                      <IconOpen />
                    </IconWrapper>
                  </div>
                </Box>
              )
            })}
          </div>
        </>
      )}

      {hasTenantProposals && (
        <>
          <Typography variant="h5" color="secondary" style={{marginTop: '0.8rem'}}>
            Propostas que estou participando
          </Typography>
          <div className={classes.wrapper}>
            {tenantProposalList.map((proposal, index) => {
              return (
                <Box smallPadding h="77px" key={proposal.property.id}>
                  <div className={`${classes.container} property-container-${index}`}>
                    <div>
                      <Typography variant="h5" color="secondary" className={classes.h5}>
                        Proposta enviada para
                      </Typography>
                      <Typography variant="body1" className={classes.p}>
                        {proposal.display_address}
                      </Typography>
                    </div>
                  </div>
                </Box>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
