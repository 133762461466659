import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import EmailAlert from '@root/components/App/Cadastro/cadastro-components/Invite/EmailAlert'
import ModalShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ModalShareInvite'
import useShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/useShareInvite'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import useStyles from '@root/components/App/Dashboard/styles'
import BackLink from '@root/components/BackLink'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import actionDispatcher from '@root/lib/actionDispatcher'
import addressDisplay, {ADDRESS_VERBOSITY_FULL} from '@root/lib/addressDisplay'
import {getInviteTenant} from '@root/lib/inviteLinkCreator'
import loadPropertyFromQuery from '@root/lib/property/loadPropertyFromQuery'
import {proposalsSort} from '@root/lib/proposalSort'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentProperty, setCurrentPropertySnap} from '@root/redux/property/property.actions'
import {SEND_MAIL_ALERT_TIME} from '@root/utils/constants'
import routes, {routeWithParams} from '@root/utils/routes'

const ProposalOptions = ({match}) => {
  const history = useHistory()
  const classes = useStyles()
  const inviteShare = useShareInvite()

  const user = useSelector((s) => s.user.user)

  const [inviteLink, setInviteLink] = useState()
  const [mailAlertVisible, setMailAlertVisible] = useState(false)
  const [fetchInviteByEmailResponse, setFetchInviteByEmailResponse] = useState('')
  const property = useSelector((s) => s.property.data) ?? {}
  const selectedProposal = property?.selected_proposal?.uid
  const propertyId = property?.uid
  const proposals = property?.proposals ?? {}
  const proposalsEntries = useMemo(() => proposalsSort(proposals), [proposals])
  // const proposalHelper = useMemo(()=>ProposalHelper.initWithProperty(property), [property])

  const actions = actionDispatcher({
    setCurrentPropertySnap,
    setCurrentProperty,
    setGlobalLoaderAction,
    setHeaderMainHeader,
  })

  useEffect(() => {
    loadPropertyFromQuery()
    actions.setHeaderMainHeader()
  }, [])

  const handleNewProposal = () => {
    history.push(`${routes.DASHBOARD_PROPOSALS_REGISTER}?uid=${propertyId}`)
  }

  const handleInviteInterested = () => {
    setInviteLink(getInviteTenant(propertyId, undefined, true))
    inviteShare.open()
  }

  const handleInviteTenant = (proposalId) => () => {
    history.push(`${routes.DASHBOARD_PROPOSALS_REGISTER}?uid=${propertyId}&proposal=${proposalId}`)
  }

  const handleViewProposal = (proposalId) => () => {
    history.push(routeWithParams(routes.DASHBOARD_CREDIT_ANALYSIS, proposalId))
  }

  const onFinish = (formData, snapShot) => {
    setInviteLink(getInviteTenant(formData.uid, undefined, true))
    actions.setCurrentPropertySnap(snapShot)
    actions.setCurrentProperty(formData)
  }

  const onInviteByEmail = async (email) => {
    actions.setGlobalLoaderAction(true)
    const response = await propertyModel.fetchInviteByEmail(email, user.uid, property.uid)
    setFetchInviteByEmailResponse(response)
    setMailAlertVisible(true)
  }

  useEffect(() => {
    if (fetchInviteByEmailResponse !== '') {
      actions.setGlobalLoaderAction(false)
      setTimeout(() => {
        setMailAlertVisible(false)
      }, SEND_MAIL_ALERT_TIME)
    }
  }, [fetchInviteByEmailResponse])

  return (
    <>
      <EmailAlert fetchInviteByEmailResponse={fetchInviteByEmailResponse} isVisible={mailAlertVisible} />
      <DashboardWrapper title="Propostas Cadastradas">
        <section>
          <Typography variant="h6" component="p" align="center" style={{marginBottom: 20}}>
            <span>{property?.alias}</span>
            <br />
            <span className={classes.proposalCardAddress}>
              {addressDisplay(property.address, ADDRESS_VERBOSITY_FULL)}
            </span>
          </Typography>
        </section>
        {/* ### convites fora da proposta ### */}
        <ButtonTransparentBg buttonStyle={{marginBottom: 15}} onClick={handleNewProposal}>
          CADASTRAR MAIS PROPOSTAS
        </ButtonTransparentBg>
        <ButtonTransparentBg buttonStyle={{marginBottom: 15}} onClick={handleInviteInterested}>
          CONVIDAR NOVOS INTERESSADOS
        </ButtonTransparentBg>
        {/* ### convites fora da proposta ### */}
        <Grid container className={classes.proposalCardContainer}>
          {proposalsEntries.map(([uid, proposal], index) => (
            <Grid key={uid} xs={12} item className={classes.proposalCardContent}>
              <header className={classes.flexSpaceBetween} style={{margin: 0}}>
                <span style={{fontWeight: 700}}>
                  Proposta {index + 1}
                  {selectedProposal === uid && <small> (SELECIONADA)</small>}
                </span>

                <span>
                  {Array.from(proposal?.interesteds).filter((user) => user.type === USER_TYPE_TENANT).length} inquilinos
                </span>
              </header>
              <body className={classes.proposalCardBody}>
                <ul className={classes.proposalCardUsers}>
                  {Array.from(proposal?.interesteds).map((user) => (
                    <li key={user.uid}>{user.name}</li>
                  ))}
                </ul>
                <div className={classes.flexSpaceBetween}>
                  <ButtonTransparentBg onClick={handleViewProposal(uid)}>VER PROPOSTA</ButtonTransparentBg>
                  <ButtonTransparentBg onClick={handleInviteTenant(uid)}>ADICIONAR MORADOR</ButtonTransparentBg>
                </div>
              </body>
            </Grid>
          ))}
          <div className={classes.divAlignCenter}>
            <BackLink />
          </div>
        </Grid>

        <ModalShareInvite inviteShare={inviteShare} shareLink={inviteLink} />
      </DashboardWrapper>
    </>
  )
}

export default ProposalOptions
