import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'

class ContractRepository extends BaseHttpRepository {
  async renew(option, propertyId) {
    const resp = await this._api.get(`contract/renew/${option}`, {property_id: propertyId, skip_redirect: 1})
    return resp
  }
}

export const contractRepoInstance = new ContractRepository()

// export const useContract* = hookRunner(contractRepoInstance, '*')
