import {Card, CardContent} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import {Divider} from '@root/components/Divider/Divider'
import IconCircle from '@root/components/icons/IconCircle'
import Text from '@root/components/Layout/Typography/Text'
import ListItem from '@root/components/PropertyList/ListItem'
import {useStyles} from '@root/components/PropertyList/styles'
import useContract from '@root/hooks/useContract'
import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import PropTypes from 'prop-types'
import React from 'react'
import contractMapper from 'status-mapper/mapper/contract-mapper'

const ProgressRowEntry = ({property, index, onClick}) => {
  const classes = useStyles()
  const {ad, proposals, proggress, rent} = property.computed
  const {contractStatus} = useContract(property)
  const contractTitle = contractMapper.translate(contractStatus)

  return (
    <Grid container xs={12} sm={6} md={4}>
      <Card className={classes.cardWrapper} onClick={onClick}>
        <CardContent className={classes.contentWrapper}>
          <Grid container item direction="column" justify="space-between">
            <ListItem
              size={12}
              label={property.alias}
              value={addressDisplay(property.address, ADDRESS_VERBOSITY_MINIMAL, true)}
            />
            <Grid container item>
              <Grid container item direction="row" spacing={2}>
                <ListItem label="Valor" value={rent.amount} />
                <ListItem label="Anunciado" value={ad.enabled_text} />

                <ListItem label="Progresso" value={`${proggress.pct.done}%`} />
                <ListItem label="Interessados" value={proposals.count} />
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Grid item>
                <Divider />
              </Grid>
              <Grid container item direction="row" justify="center" alignItems="center">
                <Grid item>
                  <div style={{display: 'inline-block'}}>
                    <IconCircle style={{fontSize: 32, color: contractMapper.color(contractStatus)}} />
                  </div>
                </Grid>
                <Grid item>
                  <Text>{contractTitle}</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

ProgressRowEntry.propTypes = {
  property: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
}
ProgressRowEntry.defaultProps = {}

export default ProgressRowEntry
