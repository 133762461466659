import React from 'react'

export function HomeIcon({src, alt, imgWidth, icon, styles}) {
  const IC = icon
  return (
    <div style={{...styles}}>
      {src && <img src={src} alt={alt} style={{width: imgWidth}} />}
      {icon && <IC size={imgWidth} style={{width: imgWidth}} fill={styles?.fill ?? styles?.color} />}
    </div>
  )
}
