import {ApprovalTypes} from '@root/redux/approval/approval.types'

export const setApprovalAllAction = ({ready, property, propertySnap, owner, approved}) => {
  return {
    type: ApprovalTypes.APPROVAL_SET_ALL,
    payload: {
      ready,
      property,
      propertySnap,
      owner,
      approved,
    },
  }
}

export const addApprovalObservationAction = (data) => {
  return {
    type: ApprovalTypes.ADD_APPROVAL_OBSERVATION,
    payload: {data},
  }
}
export const removeApprovalObservationAction = (index) => {
  return {
    type: ApprovalTypes.REMOVE_APPROVAL_OBSERVATION,
    payload: index,
  }
}
