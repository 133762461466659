import {Card, CardContent} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@root/components/Layout/Typography/Text'
import NotFound from '@root/components/NotFound'
import {isDevEnv} from '@root/lib/envDetector'
import React from 'react'

const Item = ({label, value}) => {
  return (
    <Grid container item direction="column" xs={6}>
      <Grid item>
        <Text color="#9698b4" size="11">
          {label}
        </Text>
      </Grid>
      <Grid item>
        <Text size="12">{value}</Text>
      </Grid>
    </Grid>
  )
}

const FullComp = () => {
  return (
    <Grid container xs={12} sm={6} md={4}>
      <Card style={{margin: 12}}>
        <CardContent>
          <Grid container item direction="column">
            <Grid item>Casa Feira</Grid>
            <Grid item>Rua asldkfjaskd asd, 234, sadfsadf, Sao Paulo/SP</Grid>
            <Grid container item>
              <Grid container item direction="row" spacing={1}>
                <Item label="Valor" value="R$ 2.500,00" />
                <Item label="Pendencia" value="Sim" />

                <Item label="Mes anterior" value="02/20223 - Pago" />
                <Item label="Mes vigente" value="03/20223 - Aguardando pagamento" />

                <Item label="Próximo reajuste" value="05/06/2023" />
                <Item label="Fim do contrato" value="25/12/2023" />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

const Playground = () => {
  if (!isDevEnv()) {
    return <NotFound />
  }

  return (
    <Grid container>
      <FullComp />
      <FullComp />
      <FullComp />
      <FullComp />
      <FullComp />
    </Grid>
  )
}

const Spaces = () => (
  <>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </>
)

export default Playground
