import PropTypes from 'prop-types'
import React from 'react'

const IconMoney = ({fill, size}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={{width: size, height: size}}
      viewBox="0 0 109 109">
      <path
        id="money-11934"
        d="M54.5,0A54.5,54.5,0,1,0,109,54.5,54.517,54.517,0,0,0,54.5,0Zm0,2.271A52.229,52.229,0,1,1,2.271,54.5,52.212,52.212,0,0,1,54.5,2.271ZM47.688,24.979a4.557,4.557,0,0,0-4.542,4.542v2.271H18.167V74.938a6.823,6.823,0,0,0,6.813,6.813H84.021a6.823,6.823,0,0,0,6.813-6.812V31.792H65.854V29.521a4.557,4.557,0,0,0-4.542-4.542Zm0,2.271H61.313a2.241,2.241,0,0,1,2.271,2.271v2.271H45.417V29.521A2.241,2.241,0,0,1,47.688,27.25Zm-27.25,6.813H88.563V52.988a2.241,2.241,0,0,1-2.271,2.271H68.613a14.773,14.773,0,0,0-29.388,0H22.708a2.241,2.241,0,0,1-2.271-2.271V34.063ZM53.875,44.281A12.28,12.28,0,0,1,66.4,56.522v.248a12.492,12.492,0,1,1-12.529-12.49Zm-1.082,2.271v2.333a5.2,5.2,0,0,0-2.129.909,4.3,4.3,0,0,0-1.7,4.737A4.877,4.877,0,0,0,53.8,57.906h.271a2.737,2.737,0,0,1,2.612,1.739l.009.022a2.16,2.16,0,0,1-.843,2.235l-.031.022a3.119,3.119,0,0,1-4.094-.546,2.042,2.042,0,0,1-.39-1.175H49.062a4.267,4.267,0,0,0,.816,2.51,4.926,4.926,0,0,0,2.914,1.9V66.99h2.271V64.666a5.157,5.157,0,0,0,2.1-.9,4.305,4.305,0,0,0,1.7-4.737,4.877,4.877,0,0,0-4.834-3.375h-.266a2.744,2.744,0,0,1-2.617-1.739l-.009-.022a2.161,2.161,0,0,1,.843-2.235l.031-.027a3.119,3.119,0,0,1,4.094.546h0a2.035,2.035,0,0,1,.386,1.175h2.271a4.248,4.248,0,0,0-.816-2.506,4.932,4.932,0,0,0-2.883-1.9v-2.4ZM20.438,56.891a4.472,4.472,0,0,0,2.271.639H39.19a14.741,14.741,0,0,0,29.445,0H86.292a4.472,4.472,0,0,0,2.271-.639V74.938a4.507,4.507,0,0,1-4.542,4.542H24.979a4.507,4.507,0,0,1-4.542-4.542Z"
        fill={fill}
      />
    </svg>
  )
}

IconMoney.propTypes = {
  size: PropTypes.any,
}
IconMoney.defaultProps = {
  size: '109px',
}

export default IconMoney
