import {Grid} from '@material-ui/core'
import ErrorModal from '@root/components/App/Login/ErrorModal'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import PageMini from '@root/components/PageMini'
import {auth} from '@root/firebase/firebase.utils'
import {useQuery} from '@root/hooks/urlHook'
import useSignin from '@root/hooks/useSignin'
import actionDispatcher from '@root/lib/actionDispatcher'
import {getHumanSigninErrorMessage} from '@root/lib/signIn'
import {validateEmail, validateFullName, validateMatch, validateRequired} from '@root/lib/simpleValidators'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {BASE_URL} from '@root/utils/constants'
import routes from '@root/utils/routes'

// http://localhost:3000/action/dispatch?mode=resetPassword&lang=en&oobCode=3CVYNR_69defuSfCutKLf6HiOz_1uAlB7-bSRD4pHmfsNi0iInmg5-&apiKey=fake-api-key&continueUrl=http%3A%2F%2Flocalhost%3A3000%2Fentrar%2Femail&newPassword=NEW_PASSWORD_HERE
// senha teste: abc75315_98246def

// verifyPasswordResetCode - https://firebase.google.com/docs/reference/js/auth#verifypasswordresetcode
// confirmPasswordReset - https://firebase.google.com/docs/reference/js/auth.md#confirmpasswordreset
const ResetPassword = () => {
  const history = useHistory()
  const {control, errors, watch, handleSubmit, getValues} = useForm()
  const {handleFocusBlurForLogger, signinError, setSigninError} = useSignin()
  const query = useQuery()
  const code = query.getParam('oobCode')

  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const handleConfirmCode = async (form) => {
    const pass = form.password

    actions.setGlobalLoaderAction(true)
    try {
      await auth.confirmPasswordReset(code, pass)
      history.replace(routes.SIGNUP_SIGNIN_EMAIL)
    } catch (ex) {
      setSigninError(ErrorModal.openPayload(getHumanSigninErrorMessage(ex, 'Erro ao iniciar a recuperação de senha')))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  /*
  const verifyResetCode = async () => {
    const emailToReset = await auth.verifyPasswordResetCode(code)
  }
  useEffect(() => {
    verifyResetCode()
  }, [])
  */

  return (
    <PageMini navtitle="Nova senha" innerTitle="Estamos quase lá, agora é só escolher uma nova senha.">
      <Grid container direction="column" alignItems="center">
        <Grid container item spacing={4} justify="center" alignItems="center" xs={12} md={5}>
          <Grid item xs={12}>
            <FormItem
              {...handleFocusBlurForLogger(watch, 'Senha', 'password')}
              control={control}
              errors={errors}
              fitContainer={true}
              inputProps={{type: 'password'}}
              label="Senha"
              name="password"
              rules={{
                validate: validateRequired,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormItem
              {...handleFocusBlurForLogger(watch, 'Confirmação de senha', 'confirm_password')}
              control={control}
              errors={errors}
              fitContainer={true}
              inputProps={{type: 'password'}}
              label="Confirmação de senha"
              name="confirm_password"
              rules={{
                validate: (value) => validateMatch(value, getValues('password')),
              }}
            />
          </Grid>

          <Grid item alignContent="center">
            <Button p="1rem" fz="15px" fw={600} onClick={handleSubmit(handleConfirmCode)}>
              CONTINUAR
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ErrorModal signinError={signinError} setSigninError={setSigninError} />
    </PageMini>
  )
}

export default ResetPassword
