import {Button, Typography} from '@material-ui/core'
import WhatsAppIcon from '@root/assets/whatsapp_icon.svg'
import useWhatappLinker from '@root/hooks/useWhatappLinker'
import React from 'react'

const WhatsAppButton = ({message, ...props}) => {
  const waLinker = useWhatappLinker()
  let btColor = '#a1a1a1'
  props.disabled ? (btColor = '#a1a1a1') : (btColor = '#54C410')

  const handleOnClick = () => {
    waLinker.open(waLinker.createMoben())
  }

  return (
    <Button
      size="small"
      style={{
        background: 'transparent',
        border: `2px solid ${btColor}`,
        transition: '0.4s',
        width: '200px',
      }}
      onClick={handleOnClick}
      {...props}>
      <Typography
        variant="body1"
        style={{
          fontWeight: '700',
          fontSize: '1.3rem',
          transition: '0.4s',
          textTransform: 'none',
          color: btColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...props.style,
        }}>
        <img src={WhatsAppIcon} alt="WhatsApp" style={{marginRight: '10px'}} />
        WhatsApp
      </Typography>
    </Button>
  )
}

export default WhatsAppButton
