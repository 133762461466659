import {makeStyles} from '@material-ui/core/styles'
import ContractImage from '@root/assets/contract_landpage_image.png'

import css from '../../Layout/cssVariables'

const colors = css.colors

const useStyles = makeStyles((theme) => ({
  contrato: {
    backgroundColor: '#FFF',
    paddingBottom: '60px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    marginTop: '162px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
      width: '90%',
    },
  },
  subtitle: {
    width: '65%',
    margin: '10px auto',
    fontSize: '1.2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '90%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '800px',
    margin: '20px auto',
  },
  fieldset: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: 0,
  },
  fieldsetTitle: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  fieldsetPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 15px',
    borderRadius: '12px',
  },
  standAloneFieldsetPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
    borderRadius: '12px',
    [theme.breakpoints.up('xs')]: {
      // background: 'green',
      margin: '0 auto',
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      padding: '30px 60px',
    },
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  dadosBancarios: {
    fontWeight: 600,
    fontSize: '1.3rem',
    marginTop: '40px',
    marginLeft: '20px',
  },
  saibaMais: {
    textDecoration: 'underline',
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  customRentContainer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  customRentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '90px',
  },
  customRentInput: {
    padding: '4px',
  },
  ownerWelcome: {
    backgroundColor: '#FFF',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  titleContainer: {
    fontWeight: 600,
    width: '100%',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      width: '90%',
    },
  },
  leftSection: {
    width: '50vw',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px 10px',
      marginBottom: 30,
    },
  },
  rightSection: {
    width: '50vw',
    height: '90vh',
    backgroundImage: `url(${ContractImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  observationsContainer: {
    backgroundColor: '#FFF',
    padding: '0 200px',
    [theme.breakpoints.down('md')]: {
      padding: '0 100px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 50px',
    },
  },
  textfield: {
    flex: 1,
    padding: 0,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 35,
    },
  },
  faleconosco: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 20,
  },
  backdrop: {
    zIndex: 100,
    backgroundColor: 'white',
  },
  fireInsuranceRadioGroup: {
    'width': '95%',
    'margin': '20px auto 0',
    'display': 'flex',
    'flexDirection': 'column',
    '& div': {
      'display': 'flex',
      'justifyContent': 'space-between',
      'alignItems': 'center',
      '& label': {
        margin: '10px 0',
      },
    },
  },
  contractContainer: {
    backgroundColor: '#FFF',
  },
  contractContentContainer: {
    display: 'flex',
    width: '85%',
    margin: '20px auto',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
  },
  contractContainerSideText: {
    /*[theme.breakpoints.down('sm')]: {
      display: 'none',
    },*/
  },
  contractContainerSideTextTypography: {
    width: '80%',
    margin: '4px auto',
  },
  genericFieldsContainer: {
    display: 'flex',
    width: '100%',
    margin: '20px auto',
    padding: '0px 25px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  validityDatesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  rentfeeGridItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  yesOrNoSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
  },
  guaranteeText: {
    width: '50%',
    margin: '20px auto',
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  guaranteeButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '200px',
    marginTop: 20,
  },
  contractSummaryGrid: {
    marginBottom: 15,
  },
  contractSummaryTypography: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: 65,
      fontSize: '1.2rem',
    },
  },
  contractSummaryTypographyInfo: {
    margin: 15,
    [theme.breakpoints.down('xs')]: {
      margin: '30px 0',
    },
  },
  contractSummaryValue: {
    width: 150,
    fontWeight: 600,
    color: css.colors.blueDark3,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}))

export default useStyles
