import {Button as MUButton} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {paymentRepoInstance} from '@root/application/service/repository/payment/PaymentRepository'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import IconEye from '@root/components/icons/IconEye'
import IconPaid from '@root/components/icons/IconPaid'
import IconPencil from '@root/components/icons/IconPencil'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import Title from '@root/components/Layout/Typography/Title'
import BaseModal from '@root/components/Modal/BaseModal'
import {currencyFormatProps} from '@root/components/NumberMask'
import usePaymentsHelper from '@root/components/YourProperty/sections/SectionPayment/usePaymentsHelper'
import {formatBrCurrencyToFloat, formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import {
  numberFormatValidateCurrency,
  numberFormatValidateCurrencyOptional,
  validateDateAhead,
} from '@root/lib/simpleValidators'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {getFeesWithoutRent, getTotalRent} from '@root/utils/rentfeesCalc'
import dateParser, {convertDateToBr} from 'date-parser'
import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {useStyles} from './styles'

function ChangeHistoryEntry({entry}) {
  return (
    <>
      <Text>Alterações em {entry.created_at}</Text>
      <br />
      {entry.changes.map((change, idx) => (
        <>
          <Text>{change.label}</Text>
          <DataDisplay key={idx} label="De" value={change.from} />
          <DataDisplay key={idx} label="Para" value={change.to} />
        </>
      ))}
      <DataDisplay label="Motivo" value={entry.description} />
    </>
  )
}

function BoxEntry({label, value}) {
  const classes = useStyles()

  return (
    <Box bgType="gray" smallPadding style={{margin: '20px 0'}}>
      <div className={classes.amountContainer}>
        <Typography variant="body1">{label}: </Typography>
        <Typography variant="body1" className={classes.amount}>
          {value}
        </Typography>
      </div>
    </Box>
  )
}

function PaymentsList({propertyId, rentfees, tenantMode, displayAdminInfo}) {
  const ownerMode = !tenantMode

  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const formHook = useForm()
  // const dirtyFields = Object.values(formHook.formState.dirtyFields)

  const {handleSubmit, control, errors, setValue, watch, getValues} = formHook
  const loggedUser = useSelector((state) => state.user.user)

  const editedDueDate = watch('new_due_date')
  const includeFine = watch('apply_fine')
  const newAmount = watch('new_amount')

  const [prevDueDate, setPrevDueDate] = useState()
  const [prevAmount, setPrevAmount] = useState(0)
  const [needReCalc, setNeedReCalc] = useState(false)
  const [newTaxes, setNewTaxes] = useState()

  const paymentListHook = usePaymentsHelper(propertyId)
  const {
    fetchPaymentsHook,
    detailControl,
    editControl,
    selectedYear,
    setSelectedYear,
    paymentsPerYear,
    selectedPayment,
    setSelectedPayment,
  } = paymentListHook

  const handleOpenEdit = (payment) => () => {
    setSelectedPayment(payment)
    editControl.open()
  }

  const handleOpenDetails = (payment) => () => {
    setSelectedPayment(payment)
    detailControl.open()
  }

  const handlePaymentEdit = (form) => {
    paymentListHook.editPayment(propertyId, loggedUser.uid, form)
  }

  const handleMarkAsPaid = (payment) => () => {
    paymentListHook.markAsPaid(payment, propertyId)
  }

  const fetchFineInterest = async () => {
    const fine = await paymentRepoInstance.calcFineInterest(editedDueDate, selectedPayment.id, propertyId)

    dispatch(setGlobalLoaderAction(true))
    setNewTaxes(fine)
    handleIncludeFine(includeFine, fine)
    dispatch(setGlobalLoaderAction(false))
  }

  const handleIncludeFine = (include, taxes) => {
    if (!taxes) {
      return
    }

    let amount = prevAmount
    if (include) {
      setPrevAmount(newAmount)
      amount = taxes.amount_raw
    }
    setValue('new_amount', amount)
  }

  useEffect(() => {
    handleIncludeFine(includeFine, newTaxes)
  }, [includeFine])

  useEffect(() => {
    const newDue = convertDateToBr(editedDueDate)
    const prevDue = convertDateToBr(prevDueDate)
    const isSame = prevDue === newDue || selectedPayment?.due_date === newDue
    const recalc = !isSame

    if (recalc) {
      setPrevDueDate(editedDueDate)
      setNeedReCalc(recalc)
      fetchFineInterest()
    }
  }, [editedDueDate])

  // console.log('debug', 'paymentsPerYear', paymentsPerYear, 'selectedYear', selectedYear)
  return (
    <>
      <nav className={classes.yearContainer}>
        {Object.keys(paymentsPerYear).map((year) => (
          <MUButton
            key={year}
            className={`${classes.year} ${selectedYear === year ? classes.isActive : ''}`}
            onClick={() => setSelectedYear(year)}>
            {year}
          </MUButton>
        ))}
      </nav>

      {paymentsPerYear[selectedYear]?.map((payment) => (
        <Box key={payment.id} bgType="gray" smallPadding>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid container item direction="row" alignItems="center" xs={12} sm={6} md={5}>
              <Grid item xs={3} sm={2}>
                <Typography variant="h6" color="secondary">
                  {payment.due_date_month_short}
                </Typography>
              </Grid>
              <Grid item xs={9} sm={10}>
                <DataDisplay label="Status" value={payment.status_translate} />
                <DataDisplay label="Emissão em" value={payment.reminder_at} />
                <DataDisplay
                  label="Vencimento"
                  value={payment.due_date_has_diverged ? payment.due_date_new : payment.due_date}
                />
                {payment.due_date_has_diverged && <DataDisplay label="Referente a" value={payment.due_date} />}
                <DataDisplay label="Pago em" value={payment.paid_at} />
                <DataDisplay
                  label="Visualizar"
                  value={
                    payment.is_status_waiting_schedule ? (
                      'Indisponível'
                    ) : (
                      <a href={payment.viewer_url} target="_blank" rel="noreferrer">
                        {payment.method_translate}
                      </a>
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={5}>
              <DataDisplay label="Valor" value={payment.amount_currency} />
              {ownerMode && (
                <>
                  <DataDisplay label="Cobranças Moben" value={payment.headquarter_charges.length} />
                  <DataDisplay label="Notificações" value={(payment.billings ?? []).length} />
                  <DataDisplay label="Alterações" value={payment.change_history.length} />
                </>
              )}
            </Grid>

            {ownerMode && (
              <Grid
                container
                item
                spacing={2}
                direction="column"
                justify="center"
                alignItems="stretch"
                xs={12}
                sm={12}
                md={2}>
                <Grid item>
                  <Button icon={IconEye} onClick={handleOpenDetails(payment)} w="100%">
                    Detalhes
                  </Button>
                </Grid>
                {payment.is_editable && (
                  <Grid item>
                    <Button icon={IconPencil} onClick={handleOpenEdit(payment)} w="100%">
                      Editar
                    </Button>
                  </Grid>
                )}

                {!payment.is_status_paid && (
                  <Grid item>
                    <Button icon={IconPaid} onClick={handleMarkAsPaid(payment)} w="100%">
                      Marcar como pago
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      ))}

      {/* EDICAO PAGAMENTO */}
      {selectedPayment && (
        <BaseModal
          onClose={editControl.close}
          cancelAction={editControl.close}
          isOpen={editControl.isOpen}
          okAction={handleSubmit(handlePaymentEdit)}
          dialodContentStyle={{textAlign: 'inherit'}}
          size="md"
          okLabel={'Salvar e notificar o inquilino'}
          cancelLabel={'Cancelar'}
          title={'Editar pagamento'}>
          <Title>Composição original do pagamento emitido em {selectedPayment.reminder_at}</Title>
          <BoxEntry label="Data de vencimento original" value={selectedPayment.due_date} />
          <BoxEntry label="Valor mensal do aluguel" value={formatFloatToBrCurrency(rentfees?.rent)} />

          {rentfees && rentfees.include_condominium && (
            <BoxEntry label="Valor mensal do condomínio" value={formatFloatToBrCurrency(rentfees?.condominium)} />
          )}

          {rentfees && rentfees.include_iptu && (
            <BoxEntry label="Valor mensal do IPTU" value={formatFloatToBrCurrency(rentfees?.iptu_annual)} />
          )}

          {rentfees && rentfees.include_fire_insurance && (
            <BoxEntry
              label="Valor do Seguro Incêndio"
              value={formatFloatToBrCurrency(rentfees?.fire_insurance_annual)}
            />
          )}

          <BoxEntry label="Valor do pagamento" value={formatFloatToBrCurrency(rentfees?.total)} />

          {selectedPayment.is_editable && (
            <FormProvider {...formHook}>
              <Divider dashed={true} />
              <Title>Definição de valores para emissão de um novo pagamento</Title>

              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormItem
                    defaultValue={dateParser(
                      selectedPayment.due_date_has_diverged ? selectedPayment.due_date_new : selectedPayment.due_date,
                    )}
                    control={control}
                    errors={errors}
                    fitContainer
                    type="datepicker"
                    name="new_due_date"
                    label="Nova data de vencimento"
                    rules={{
                      validate: validateDateAhead,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormItem
                    defaultValue={selectedPayment.amount_raw}
                    control={control}
                    errors={errors}
                    fitContainer
                    type="mask"
                    name="new_amount"
                    label="Novo valor do pagamento"
                    rules={{
                      validate: numberFormatValidateCurrency,
                    }}
                    maskInputProps={currencyFormatProps}
                    style={{transform: 'translateY(5px)'}}
                  />
                </Grid>

                {needReCalc && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <FormItem
                        fitContainer
                        control={control}
                        errors={errors}
                        type="checkbox-single"
                        name="apply_fine"
                        defaultValue="yes"
                        options={[
                          {
                            label: 'Incluir multa e juros?',
                            value: 'yes',
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <BoxEntry
                    label={`Valor total${includeFine ? ' com multa e juros' : ''}`}
                    value={formatFloatToBrCurrency(newAmount ?? selectedPayment.amount_raw)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    Indique o motivo da alteração do pagamento (o texto será exibido para o Inquilino)
                  </Typography>
                  <FormItem control={control} errors={errors} fitContainer type="textarea" name="description" />
                </Grid>

                {/* <FormItem
                fitContainer
                control={control}
                errors={errors}
                type="checkbox"
                name="is_silent"
                options={[
                  {
                    label:
                      'Marcar esta opção vai IMPEDIR que a notificação (email, whatsapp, etc.) seja enviada, PP e IQ NÃO vão receber nada',
                    value: 'accepted',
                  },
                ]}
                /> */}
              </Grid>
            </FormProvider>
          )}
        </BaseModal>
      )}

      {/* DETALHES SOBRE O PAGAMENTO */}
      <BaseModal
        size="md"
        cancelLabel=""
        onClose={detailControl.close}
        okAction={detailControl.close}
        isOpen={detailControl.isOpen}
        title="Detalhes do pagamento"
        dialodContentStyle={{display: 'initial', textAlign: 'initial'}}>
        {selectedPayment && (
          <Grid container spacing={2}>
            <Grid container item direction="column" alignItems="flex-start">
              {displayAdminInfo && (
                <>
                  <DataDisplay
                    label="ID da transação"
                    value={
                      <a
                        href={`https://beta.dashboard.pagar.me/#/transactions/${selectedPayment.transaction_id}`}
                        target="_blank"
                        rel="noreferrer">
                        #{selectedPayment.transaction_id}
                      </a>
                    }
                  />
                  <DataDisplay label="ID Pagamento" value={selectedPayment.id} />
                </>
              )}
              <DataDisplay label="Status" value={selectedPayment.status_translate} />
              <DataDisplay label="Valor" value={selectedPayment.amount_currency} />
              <DataDisplay label="Emissão em" value={selectedPayment.reminder_at} />
              <DataDisplay label="Vencimento" value={selectedPayment.due_date} />
              <DataDisplay label="Pago em" value={selectedPayment.paid_at} />
            </Grid>
            <Divider />

            <Grid item xs={12} md={4}>
              <h3>Cobranças Moben</h3>
              {selectedPayment.headquarter_charges.map((chargeEntry, idx) => (
                <div key={idx}>
                  <DataDisplay label="Descrição" value={chargeEntry.description} />
                  <DataDisplay label="Valor" value={chargeEntry.amount_currency} />
                  <Divider dashed />
                </div>
              ))}
            </Grid>

            <Grid item xs={12} md={4}>
              <h3>Histórico Notificações</h3>
              {selectedPayment.billings.map((statusEntry, idx) => (
                <div key={idx}>
                  <DataDisplay label="Data" value={statusEntry.created_at} />
                  <DataDisplay
                    label="Tipo"
                    value={statusEntry.is_reminder ? 'Lembrete de pagamento' : 'Pagamento atrasado'}
                  />
                  <Divider dashed />
                </div>
              ))}
            </Grid>

            <Grid item xs={12} md={4}>
              <h3>Histórico de alterações</h3>
              {selectedPayment.change_history.map((changeEntry, idx) => (
                <div key={idx}>
                  <ChangeHistoryEntry entry={changeEntry} />
                  <Divider dashed />
                </div>
              ))}
            </Grid>
          </Grid>
        )}
      </BaseModal>
    </>
  )
}

PaymentsList.defaultProps = {
  tenantMode: true,
  displayAdminInfo: false,
  rentfees: {},
}
PaymentsList.propTypes = {
  tenantMode: PropTypes.bool,
  displayAdminInfo: PropTypes.bool,
  payments: PropTypes.array.isRequired,
  rentfees: PropTypes.object.isRequired,
}

export default PaymentsList
