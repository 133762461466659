import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {PROPERTY_STATUS_PENDING} from '@root/model/cartModel'
import {CartTypes} from '@root/redux/cart/cart.types'
import {SigninTypes} from '@root/redux/signin/signin.types'

export const createCartInitialState = () => ({
  currentIndex: -1,
  currentPropertyType: undefined,
  paymentMethod: undefined,
  list: [],
  listAll: [],
})

const filterPending = (list) => list.filter((p) => p.status === PROPERTY_STATUS_PENDING)

const initialState = createCartInitialState()
const reducers = {
  [SigninTypes.LOG_OUT]: () => createCartInitialState(),

  [CartTypes.SET_CART_LIST]: (state, {payload}) => {
    return {
      ...state,
      list: payload.list,
    }
  },

  [CartTypes.SET_CART_LIST_ALL]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.listAll = payload.list
      draft.list = filterPending(payload.list)
    })
  },

  [CartTypes.ADD_TO_CART]: (state, {payload}) => {
    const newArray = state.list
    newArray.splice(state.list.length, 0, payload.item)

    return {
      ...state,
      list: newArray,
    }
  },

  [CartTypes.SET_CURRENT_CART]: (state, {payload}) => {
    const newArray = state.list.map((item, index) => {
      return index !== state.currentIndex ? item : payload.item
    })
    return {
      ...state,
      list: newArray,
    }
  },

  [CartTypes.SET_CART_ITEM_BY_ID]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.listAll = state.listAll.map((a) => {
        if (a.uid === payload.uid) {
          return payload.item
        }
        return a
      })
      draft.list = filterPending(draft.listAll)
    })
  },

  [CartTypes.SET_CART_CURRENT_INDEX]: (state, {payload}) => {
    return {
      ...state,
      currentIndex: payload.index,
    }
  },

  [CartTypes.CLEAR_CART]: () => {
    return initialState
  },

  [CartTypes.REMOVE_FROM_CART]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.list.splice(payload.index, 1)
    })
  },

  [CartTypes.REMOVE_FROM_LIST_ALL]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.listAll.splice(payload.index, 1)
    })
  },

  [CartTypes.REMOVE_FROM_CART_BY_ID]: (state, {payload}) => {
    const propertyId = payload.id
    return produce(state, (draft) => {
      const listLen = draft.list.length
      let item
      for (let i = 0; i < listLen; i++) {
        item = draft.list[i]
        if (item.uid == propertyId) {
          draft.list.splice(i, 1)
        }
      }
    })
  },

  [CartTypes.SET_CART_PAYMENT_METHOD]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.paymentMethod = payload.method
    })
  },

  [CartTypes.SET_PROPERTY_TYPE]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.currentPropertyType = payload.type
    })
  },
}

export default reducerMatch(reducers, initialState)
