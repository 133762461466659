import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {LoaderTypes} from '@root/redux/loader/loader.types'
import {LOADER_MODE_SPINNER} from '@root/utils/constants'

const initialState = {
  loading: false,
  mode: LOADER_MODE_SPINNER,
  data: {},
}
const reducers = {
  [LoaderTypes.TOGGLE_GLOBAL_LOADING]: (state) => {
    return {
      data: {},
      loading: !state.loading,
      mode: LOADER_MODE_SPINNER,
    }
  },
  [LoaderTypes.SET_GLOBAL_LOADING]: (state, action) => {
    return {
      data: {},
      loading: action.payload.loading,
      mode: LOADER_MODE_SPINNER,
    }
  },
  [LoaderTypes.SET_GLOBAL_LOADING_WITH_MODE]: (state, action) =>
    produce(state, (draft) => {
      draft.loading = action.payload.loading
      draft.mode = action.payload.mode
      draft.data = action.payload.data ?? {}
    }),
  [LoaderTypes.SET_GLOBAL_LOADING_DATA]: (state, action) =>
    produce(state, (draft) => {
      draft.data = action.payload.data ?? {}
    }),
}
export default reducerMatch(reducers, initialState)
