import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React from 'react'

const IconWhatsApp = (props) => (
  <WhatsAppIcon {...props} style={{fontSize: props.size, color: props.fill, ...(props.style ?? {})}} />
)

IconWhatsApp.defaultProps = {
  size: 24,
  fill: '#25d366',
}
export default IconWhatsApp
