import React from 'react'
import NumberFormat from 'react-number-format'

const NumberFormatCustom = (props) => {
  const {inputRef, onChange, ...other} = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) {
          const {floatValue, formattedValue, value} = values
          onChange({
            target: {
              name: props.name,
              value: value,
            },
          })
        }
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix="R$ "
      // suffix=",00"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )
}

export default NumberFormatCustom
