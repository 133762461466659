import Grid from '@material-ui/core/Grid'
import {calculatorRepoInstance} from '@root/application/service/repository/calculator/CalculatorRepository'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import {currencyFormatProps} from '@root/components/NumberMask'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {formatBrCurrencyToFloat} from '@root/lib/moneyFormater'
import {montsList} from '@root/lib/predefinedValuesOptions'
import {numberFormatValidateCurrency, validateNumber, validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useForm} from 'react-hook-form'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const CalculatorForm = ({dispatch}) => {
  const {control, errors, handleSubmit} = useForm()

  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const setResultVisibility = (visibility, result) => {
    dispatch({type: 'visibility', visibility, result})
  }

  const handleCalculator = async (form) => {
    actions.setGlobalLoaderAction(true)
    try {
      const result = await calculatorRepoInstance.readjustment(
        form.readjusment_index,
        formatBrCurrencyToFloat(form.rent),
        form.month,
        form.year,
      )
      setResultVisibility(true, result)
    } catch (ex) {
      errorHandler(ex, 'Não foi possivel realizar o cálculo de reajuste')
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <FormItem
          control={control}
          errors={errors}
          name="rent"
          label="Valor do aluguel"
          type="mask"
          rules={{
            validate: numberFormatValidateCurrency,
          }}
          maskInputProps={currencyFormatProps}
        />
      </Grid>

      <Grid container item xs={12} spacing={2} direction="row" justify="space-between">
        <Grid item xs={12}>
          <TextBlock>Inicio da vigencia. Informe o mês e o ano em que o aluguel começou.</TextBlock>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="select"
            name="month"
            label="Mês"
            options={montsList}
            rules={{validate: validateRequired}}
          />
        </Grid>

        <Grid item xs={6}>
          <FormItem
            control={control}
            errors={errors}
            type="mask"
            name="year"
            label="Ano"
            maskInputProps={{format: '####'}}
            rules={{validate: validateNumber}}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormItem
          control={control}
          errors={errors}
          type="select"
          name="readjusment_index"
          label="Índice de reajuste"
          fitContainer
          options={[
            {value: 'igpm', label: 'IGP-M'},
            {value: 'ipca', label: 'IPCA'},
          ]}
          rules={{validate: validateRequired}}
        />
      </Grid>

      <Grid container item xs={12} justify="flex-end">
        <Grid item>
          <Button onClick={handleSubmit(handleCalculator)}>Calcular</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default CalculatorForm
