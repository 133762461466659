export const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USER_FIREBASE_REF: 'SET_USER_FIREBASE_REF',
  SET_USER_FROM_FIREBASE: 'SET_USER_FROM_FIREBASE',
  SET_USER_NULL: 'SET_USER_NULL',
  ADD_USER_CARD: 'ADD_USER_CARD',
  SET_USER_ADDRESS: 'SET_USER_ADDRESS',
  SET_USER_AUTH_FINISHED: 'SET_USER_AUTH_FINISHED',
  SET_USER_LOGGED: 'SET_USER_LOGGED',
  SET_USER_ID: 'SET_USER_ID',
  SET_USER_FIRST_LOGIN: 'SET_USER_FIRST_LOGIN',
  SET_USER_INITIAL: 'SET_USER_INITIAL',
}
