import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import HomeIcon from '@material-ui/icons/Home'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import CreditCardsList from '@root/components/App/Profile/CreditCardsList'
import Profile from '@root/components/App/Profile/Profile'
import Subscription from '@root/components/App/Profile/Subscription'
import Transfers from '@root/components/App/Profile/Transfers'
import UserBankData from '@root/components/App/Profile/UserBankData'
import {isDevEnv} from '@root/lib/envDetector'
import BankStatement from '@root/pages/BankStatement/BankStatement'
import PaymentsListMonth from '@root/pages/PaymentsList/Month/PaymentsListMonth'
// import React from 'react'

const isDev = isDevEnv()

export const profileTabsList = [
  {
    title: 'Meus dados',
    hash: 'meus-dados',
    panel: Profile,
    icon: HomeIcon,
    checkVisibility: () => true,
  },
  {
    title: 'Cartões',
    hash: 'cartoes',
    panel: CreditCardsList,
    icon: CreditCardIcon,
    checkVisibility: () => true,
  },
  /*{
    title: 'Dados bancários',
    hash: 'banco',
    panel: UserBankData,
    icon: AccountBalanceIcon,
    checkVisibility: () => true,
  },*/
  /*{
    title: 'Recebimentos',
    hash: 'recebimentos',
    panel: Transfers,
    icon: SwapHorizIcon,
    checkVisibility: (user) => isDev || user.has_transfer,
  },*/
  {
    title: 'Extrato',
    hash: 'extrato',
    available_since: '2023-02-28',
    panel: BankStatement,
    icon: ReceiptIcon,
    checkVisibility: (user) => isDev || user.is_owner || user.is_active_owner,
  },
  {
    title: 'Controle Financeiro',
    hash: 'controle-financeiro',
    available_since: '2023-03-10',
    panel: PaymentsListMonth,
    icon: MonetizationOnIcon,
    checkVisibility: (user) => isDev || user.is_owner || user.is_active_owner,
  },
  /*{
    title: 'Assinatura',
    hash: 'assinatura',
    panel: Subscription,
    icon: CardMembershipIcon,
    checkVisibility: (user) => isDev || user.has_subscription,
  },*/
]
