import {Grid, Typography} from '@material-ui/core'
import anonymUser from '@root/assets/profile_avatar.png'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import BackLink from '@root/components/BackLink'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import analyticsLogger from '@root/firebase/analytics.utils'
import userModel from '@root/model/userModel'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getPropertyOwnerId} from '@root/utils/helper-property'

import useStyles from '../styles'

const TenantDashboardProperty = () => {
  const [owner, setOwner] = useState({})
  const property = useSelector((s) => s.property.data)
  const contract = property?.contract
  const classes = useStyles()

  useEffect(() => {
    if (property) {
      getOwnerData()
    }
  }, [property])

  const getOwnerData = async () => {
    const ownerId = getPropertyOwnerId(property.uid, property, contract)
    const ownerRef = await userModel.getById(ownerId)
    setOwner(ownerRef.data())
  }

  const mailOwner = () => {
    analyticsLogger.logEvent('button_click', {
      button_name: 'enviar mensagem',
      alternative_event_name: 'click_tenant_dashboard_msg_proprietario',
    })
    window.open(`mailto:${owner?.email}`, '_blank')
  }

  return (
    <DashboardWrapper title="">
      <Grid container>
        <Grid item xs={3}>
          <img src={anonymUser} alt="icone contrato" className={classes.tenantDashboardProfileIcon} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h4">proprietário</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.tenantDashboardGreyText} variant="body1">
            Nome Completo
          </Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 15}}>
          <Typography variant="body1">{owner?.name}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.tenantDashboardGreyText}>
          E-mail
        </Grid>
        <Grid item xs={12} style={{marginBottom: 15}}>
          {owner?.email}
        </Grid>
        <Grid item xs={12} className={classes.tenantDashboardGreyText}>
          Telefone
        </Grid>
        <Grid item xs={12} style={{marginBottom: 15}}>
          {owner?.phone}
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 15}}>
          <ButtonTransparentBg onClick={mailOwner}>enviar mensagem</ButtonTransparentBg>
        </Grid>
      </Grid>
      <Grid container align="center" justify="center">
        <BackLink />
      </Grid>
    </DashboardWrapper>
  )
}

export default TenantDashboardProperty
