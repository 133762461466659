import BoletosInfo from '@root/components/App/Dashboard/DashboardPages/Boletos/BoletosInfo'
import Fakedoor from '@root/components/App/Dashboard/DashboardPages/Fakedoor/Fakedoor'
import React from 'react'
import {TENANT_DASHBOARD_BLOCK_TITLES} from '@root/utils/constants'

const TenantDashboardPayments = () => {
  return (
    <Fakedoor title={TENANT_DASHBOARD_BLOCK_TITLES.payments} hideWhatsappBtn>
      <BoletosInfo type="automatic" options={['pagos', 'futuros']} />
    </Fakedoor>
  )
}

export default TenantDashboardPayments
