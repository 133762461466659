export const getURL = () => {
  const obj = new URL(window.location.href)
  return obj
}

export let url = getURL()

export const useQuery = () => {
  url = getURL()
  return {
    getQueryString: () => url.searchParams.toString(),
    /**
     * @param {string} name - query string param name
     * @param {any} fallback - default value if param not exists, defaults to an empty string
     * @return {string}
     */
    getParam: (name, fallback = '') => url.searchParams.get(name) ?? fallback,
    getAllParam: (name) => url.searchParams.getAll(name),
    hasParam: (name) => {
      const exists = url.searchParams.has(name)
      const value = url.searchParams.get(name)
      if (exists) {
        return value !== 'undefined' && value !== ''
      }
      return exists
    },
  }
}
