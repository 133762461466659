import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Text from '@root/components/Layout/Typography/Text'
import analyticsLogger from '@root/firebase/analytics.utils'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {useHistory} from 'react-router-dom'

const PropertyAlert = (props) => {
  const history = useHistory()
  const {id, date_end, level, color, page, title, description, cta, payload} = props
  const isDescriptionString = typeof description === 'string'
  const isDescriptionNode = React.isValidElement(description)

  const actionComp = useMemo(() => {
    if (!cta) {
      return null
    }

    return (
      <Button
        color="inherit"
        size="small"
        onClick={(evt) => {
          analyticsLogger.logEvent(`alert_home_${id}`, {
            title,
            id,
          })

          if (page) {
            history.push(page)
          } else if (props.onClick) {
            props.onClick(evt)
          }
        }}>
        {cta}
      </Button>
    )
  }, [cta, page])
  return (
    <Alert severity={level} action={actionComp}>
      <AlertTitle>
        <Text bold>{title.toUpperCase()}</Text>
      </AlertTitle>

      {isDescriptionString && <Text>{description}</Text>}
      {isDescriptionNode && description}
    </Alert>
  )
}

PropertyAlert.propTypes = {
  propertyId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  date_end: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  page: PropTypes.string,
  onClick: PropTypes.func,
}

PropertyAlert.defaultProps = {}

export default PropertyAlert
