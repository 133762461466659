import {Checkbox, FormControlLabel, Paper, TextField, Typography} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import SubjectIcon from '@material-ui/icons/Subject'
import {addDays} from 'date-fns'
import Fuse from 'fuse.js'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import dateParser, {convertDateToBr, fromTimestampToDate} from '@root/lib/dateParser'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import {paymentMethodsMap} from '@root/lib/paymentMethods'
import propertyModel from '@root/model/propertyModel'
import userModel from '@root/model/userModel'
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import paymentMapper from 'status-mapper/mapper/payment-mapper'

import useStyles from './styles'

const currentDate = new Date()
const filterPayments = (payment, status) => payment.status === status

const filterPaidPayments = (payment) => filterPayments(payment, paymentMapper.status.PAID)
const filterSchedulePayments = (payment) => filterPayments(payment, paymentMapper.status.WAITING_SCHEDULE)
const filterWaitingPayments = (payment) => filterPayments(payment, paymentMapper.status.WAITING_PAYMENT)
const filterOverduePayments = (payment) => {
  const dueDate = dateParser(payment.due_date)
  return (
    paymentMapper.assertStatus.isWaitingPayment(payment.status) &&
    dueDate < currentDate &&
    addDays(dueDate, 2) <= currentDate
  )
}

const PaymentEntry = ({payment, bgColor = undefined}) => {
  const classes = useStyles()

  return (
    <div style={{background: bgColor}}>
      <Typography variant="body1">
        ID: <strong>{payment.id}</strong>
      </Typography>
      <Typography variant="body1">
        Vencimento: <strong>{convertDateToBr(payment.due_date)}</strong>
      </Typography>
      <Typography variant="body1">
        Valor: <strong>{formatFloatToBrCurrency(payment.amount)}</strong>
      </Typography>
      <Typography variant="body1">
        Boleto gerado em: <strong>{convertDateToBr(payment.reminder_at)}</strong>
      </Typography>
      {payment.boleto_url && (
        <Typography variant="body1" className={classes.pEllipsis}>
          <a target="_blank" rel="noreferrer" href={payment.boleto_url}>
            <strong>Ver boleto</strong>
          </a>
        </Typography>
      )}
    </div>
  )
}

const Payments = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [searchTerm, setSearchTerm] = useState('')
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [selectedProperty, setSelectedProperty] = useState()
  const [paymentsRange, setPaymentsRange] = useState({start: null, end: null})
  const [payments, setPayments] = useState({paids: [], waitings: [], schedules: [], overdues: []})

  const fuse = useMemo(() => {
    const options = {
      threshold: 0.4,
      keys: ['alias', 'uid'],
    }
    return new Fuse(rows, options)
  }, [rows])

  useEffect(() => {
    propertyModel.getPropertiesWithActivePayment().then((data) => setRows(data))
  }, [])

  useEffect(() => {
    if (!searchTerm || searchTerm === '') {
      setFilteredRows(rows)
      return
    }

    setFilteredRows(fuse.search(searchTerm).map((item) => item.item))
  }, [searchTerm, fuse])

  useEffect(() => {
    dispatch(setNavbarTitleAction('Alugueis'))
  }, [dispatch])

  useEffect(() => {
    if (selectedProperty) {
      const payments = selectedProperty.contract?.payments ?? []
      const paids = payments.filter(filterPaidPayments)
      const waitings = payments.filter(filterWaitingPayments)
      const schedules = payments.filter(filterSchedulePayments)
      const overdues = payments.filter(filterOverduePayments)

      setPayments({paids, waitings, schedules, overdues})

      if (payments) {
        setPaymentsRange({start: payments[0]?.due_date, end: payments?.pop()?.due_date})
      }
    }
  }, [selectedProperty])

  const onClickIconAction = (property) => {
    setSelectedProperty(property)
  }

  return (
    <div className={classes.userListRoot}>
      <div className={classes.fieldsContainer}>
        <TextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          placeholder="Buscar..."
        />

        <div>
          {/* <Typography variant="body1">Filtrar: </Typography>
          <FormControlLabel control={<Checkbox color="secondary" />} label="Em dia" />
          <FormControlLabel control={<Checkbox color="secondary" />} label="Atrasados" /> */}
        </div>
      </div>

      <div className={classes.userDetailTopContainer}>
        <TableContainer component={Paper} className={classes.card}>
          <Table size="small" aria-label="a dense table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>ID Imóvel</TableCell>
                <TableCell>Apelido</TableCell>
                <TableCell>Metodo de pagamento</TableCell>
                <TableCell>PP/Admin</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((property) => (
                <PropertyRow
                  key={property.uid}
                  property={property}
                  selected={property.uid === selectedProperty?.uid}
                  onClickIcon={(property) => onClickIconAction(property)}
                  classes={classes}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {selectedProperty && (
              <div>
                <h3>{`${selectedProperty.alias} - ${selectedProperty.uid}`}</h3>

                <div>
                  {/*<Typography variant="body1">
                    Vencimento: dia
                    <strong>{convertDateToBr(paymentsRange.start)}</strong>
                  </Typography>*/}
                  <Typography variant="body1">
                    Primeiro boleto: <strong>{convertDateToBr(paymentsRange.start)}</strong>
                  </Typography>
                  <Typography variant="body1">
                    Último boleto: <strong>{convertDateToBr(paymentsRange.end)}</strong>
                  </Typography>
                  <Typography variant="body1">
                    Forma de pagamento:{' '}
                    <strong>{paymentMethodsMap[selectedProperty.contract?.payment_method?.option]}</strong>
                  </Typography>
                </div>

                <div className={classes.boletosContainer}>
                  <div>
                    <Typography variant="h6">Aguardando pagamento</Typography>
                    {payments.waitings.map((payment) => (
                      <PaymentEntry key={payment.id} payment={payment} />
                    ))}

                    <Typography variant="h6" style={{marginTop: 20}}>
                      Aguardando Emissão
                    </Typography>
                    {payments.schedules.map((payment) => (
                      <PaymentEntry key={payment.id} payment={payment} />
                    ))}
                  </div>

                  <div>
                    <Typography variant="h6">Atrasados</Typography>
                    {payments.overdues.map((payment) => (
                      <PaymentEntry key={payment.id} payment={payment} bgColor="#ffe0e0" />
                    ))}
                  </div>

                  <div>
                    <Typography variant="h6">Pagos</Typography>
                    {payments.paids.map((payment) => (
                      <PaymentEntry key={payment.id} payment={payment} bgColor="#e5ffe0" />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const PropertyRow = ({property, selected, onClickIcon, classes}) => {
  const paymentsList = property.contract.payments ?? []
  const propertyDetailPath = adminPathBuilder(`/imoveis/${property.uid}`)

  const [userName, setUserName] = useState('')
  const [hasOverduePayment, setHasOverduePayment] = useState(false)

  useEffect(() => {
    if (property) {
      userModel.getById(property.ower_id).then((user) => {
        setUserName(user.data().name)
      })

      const hasOverdue = paymentsList.filter(filterOverduePayments)
      hasOverdue.length && setHasOverduePayment(true)
    }
  }, [property])

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Link to={propertyDetailPath}>{property.uid}</Link>
      </TableCell>
      <TableCell>{property.alias}</TableCell>
      <TableCell>{paymentMethodsMap[property.contract?.payment_method?.option]}</TableCell>

      <TableCell>{userName}</TableCell>
      {hasOverduePayment ? (
        <TableCell style={{color: 'red'}}>Atrasado</TableCell>
      ) : (
        <TableCell style={{color: 'green'}}>Em dia</TableCell>
      )}
      <TableCell className={classes.iconsContainer}>
        <button onClick={() => onClickIcon(property)} disabled={selected}>
          <SubjectIcon className="clickable" />
        </button>
      </TableCell>
    </TableRow>
  )
}

export default Payments
