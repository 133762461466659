import {ProposalActionTypes} from './proposal.types'

export const setProposalsWithTenants = ({propertyRef, proposals = {}}) => ({
  type: ProposalActionTypes.SET_PROPOSALS_WITH_TENANTS,
  payload: {propertyRef, proposals},
})

export const setSelectedProposal = ({proposalUID}) => ({
  type: ProposalActionTypes.SET_SELECTED_PROPOSAL,
  payload: {proposalUID},
})

export const setViewProposal = ({proposalUID}) => ({
  type: ProposalActionTypes.SET_VIEW_PROPOSAL,
  payload: {proposalUID},
})
