import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    // 'marginTop': '-10px !important',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'gap': 15,
    '& h5': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    '& p': {
      fontSize: '13px',
      lineHeight: '17px',
      fontStyle: 'italic',
    },
  },
  cadastrarImovelContainer: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    '& > div': {
      'display': 'flex',
      'alignItems': 'center',
      '& > img': {
        marginRight: 5,
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  // -------------
  cardWrapper: {
    margin: 12,
  },
  contentWrapper: {
    padding: 12,
  },
}))
