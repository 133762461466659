import Typography from '@material-ui/core/Typography'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import {Button} from '@root/components/Button/Button'
import IconOpen from '@root/components/icons/IconOpen'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Title from '@root/components/Layout/Typography/Title'
import {Navbar} from '@root/components/Navbar/Navbar'
import UserAvatar from '@root/components/UserAvatar'
import {useStyles} from '@root/components/YourProperty/styles'
import analyticsLogger from '@root/firebase/analytics.utils'
import {isFullyEmpty} from '@root/lib/fullyEmpty'
import pluralize from '@root/lib/intl/pluralize'
import userModel from '@root/model/userModel'
import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import routes, {routeWithParams} from '@root/utils/routes'

function SectionTenant() {
  const history = useHistory()
  const classes = useStyles()
  const [tenant, setTenant] = useState(undefined)

  const proposals = useSelector((state) => state.property?.data?.proposals)
  const proposalsLen = Object.keys(proposals ?? {}).length
  const selectedTenantUID = useSelector((state) => state.property?.data?.tenant)
  // const selectedProposalUID = useSelector((state) => state.property?.data?.selected_proposal.uid)

  const config = useMemo(() => {
    const defaultConfig = {
      title: 'Interessado',
      exists: false,
      has_selected: false,
      selected: undefined,
    }

    if (tenant) {
      defaultConfig.title = 'Inquilino'
      defaultConfig.exists = true
      defaultConfig.has_selected = !isFullyEmpty(selectedTenantUID)
      defaultConfig.selected = selectedTenantUID
    }

    return defaultConfig
  }, [tenant])

  const goToProposalsList = () => {
    analyticsLogger.logEvent('dashboard_pp_ver_propostas', {proposal_quantity: proposalsLen})

    let path = routes.DASHBOARD_PROPOSALS_LIST
    history.push(path)
  }

  const goToProposalsListCreate = () => {
    analyticsLogger.logEvent('dashboard_pp_criar_proposta', {proposal_quantity: proposalsLen})
    history.push(`${routes.DASHBOARD_PROPOSALS_LIST}?create=1`)
  }

  const goToProposalInvite = () => {
    analyticsLogger.logEvent('dashboard_pp_proposta_convite')
    history.push(routes.DASHBOARD_PROPOSALS_INVITE)
  }

  const goToProposalRegister = () => {
    analyticsLogger.logEvent('dashboard_pp_proposta_cadastrar')
    history.push(routes.DASHBOARD_PROPOSALS_REGISTER)
  }

  useEffect(() => {
    if (!selectedTenantUID) {
      return
    }

    const getTenantDataById = () => {
      userModel.getById(selectedTenantUID).then((data) => setTenant(data.data()))
    }
    getTenantDataById()
  }, [selectedTenantUID])

  /*if (!tenant) {
    return (
      <div className={classes.sectionItem}>
        <Typography variant="h5" color="secondary">
          {config.title}
        </Typography>
        <Typography variant="subtitle1">Nenhum inquilino cadastrado</Typography>

        <div className={classes.buttonsContainer}>
          <Button onClick={handleOnClick}>Convidar</Button>
          <Button onClick={handleOnClick}>Cadastrar</Button>
        </div>
      </div>
    )
  }*/

  return (
    <div className={classes.tenant}>
      <Title bold>{config.title}</Title>

      {!config.has_selected && proposalsLen > 0 && (
        <Navbar type="warning" br="8px" onClick={goToProposalsList} withHover>
          {proposalsLen} {pluralize(proposalsLen, 'proposta')} {pluralize(proposalsLen, 'recebida')}
          <IconWrapper isAbsolutePositionRightCenter={true}>
            <IconOpen />
          </IconWrapper>
        </Navbar>
      )}

      {!tenant && proposalsLen === 0 && (
        <Typography variant="subtitle1" align="start">
          Nenhum {config.title.toLowerCase()} cadastrado
        </Typography>
      )}

      {!tenant && (
        <div className={`${classes.buttonsContainer} ${classes.buttonsTenantContainer}`} id="dash-tenant-container">
          <Button onClick={goToProposalsList}>Ver propostas</Button>
          <Button onClick={goToProposalsListCreate}>Adicionar interessado</Button>
        </div>
      )}

      {tenant && (
        <Box bgType="gray" smallPadding onClick={goToProposalsList}>
          <div className={classes.tenantAvatarInfo}>
            <UserAvatar user={tenant} className={classes.tenantAvatarPhoto} />
            <div className={classes.tenantInfo}>
              <Typography variant="body1">{tenant.name}</Typography>
              <Typography variant="body1">{tenant.phone}</Typography>
              <Typography variant="body1">{tenant.email}</Typography>
            </div>
          </div>

          <div className={classes.tenantGoToIconWrapper}>
            <IconWrapper onClick={() => {}}>
              <LaunchOutlinedIcon />
            </IconWrapper>
          </div>
        </Box>
      )}
    </div>
  )
}

export default SectionTenant
