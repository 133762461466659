import GuaranteeTypeCaucaoDinheiro from '@root/components/App/Contract/GuaranteesForms/GuaranteeTypeCaucaoDinheiro'
import GuaranteeTypeFiador from '@root/components/App/Contract/GuaranteesForms/GuaranteeTypeFiador'
import GuaranteeTypeNone from '@root/components/App/Contract/GuaranteesForms/GuaranteeTypeNone'
import GuaranteeTypeSeguroFianca from '@root/components/App/Contract/GuaranteesForms/GuaranteeTypeSeguroFianca'
import dateParser from '@root/lib/dateParser'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'

import GuaranteeTypeCartaFianca from './GuaranteesForms/GuaranteeTypeCartaFianca'
import GuaranteeTypeCaucaoCapitalizacao from './GuaranteesForms/GuaranteeTypeCaucaoCapitalizacao'
import GuaranteeTypeCaucaoImoveis from './GuaranteesForms/GuaranteeTypeCaucaoImoveis'

/**
 * @typedef {object} FormatterCallback
 * @callback
 * @param {object} payload
 * @param {string} [propertyId]
 * @param {object} [currentPayload]
 * @return Promise<any>
 */

/**
 * @typedef {object} GuaranteeOptionItem
 * @property {string} title
 * @property {string} type
 * @property {string[]} descriptionList
 * @property {React.Component} formComp
 * @property {string} [alternativeDecision]
 * @property {GuaranteeOptionItem[]} [subtypes]
 * @property {FormatterCallback} [postFormatter]
 * @property {FormatterCallback} [preFormatter]
 */

/**
 * @typedef {GuaranteeOptionItem[]} GuaranteesList
 */

const GUARANTEES_SECTION = 'property/docs/garantia'
// https://github.com/MobenDev/database-structure#contract
/** @var GuaranteesList guaranteesList */
const guaranteesList = [
  {
    title: 'Fiança',
    type: 'fianca',
    descriptionList: [],
    subtypes: [
      {
        title: 'Fiador',
        type: 'fiador',
        descriptionList: [
          'O fiador é a pessoa corresponsável pelo aluguel do imóvel e assume os débitos e eventuais encargos em caso de inadimplência por parte do Inquilino.',
          'Quem pode ser fiador: qualquer pessoa física ou jurídica, desde que aprovada pelo proprietário.',
          'Na Moben você pode fazer a análise de crédito dos Fiadores Pessoa Física.',
        ],
        formComp: GuaranteeTypeFiador,
      },
      {
        title: 'Fiança Bancaria',
        type: 'bancaria',
        descriptionList: [
          'A fiança bancária é a garantia emitida por um banco na qual ele passa a ser o fiador do aluguel, cumprindo com as obrigações financeiras do inquilino em caso de inadimplência.',
        ],
        formComp: GuaranteeTypeCartaFianca,
        preformater: (payload) => ({
          file: {name: payload.document.name_original},
        }),
        postformater: async (data, propertyId, current) => {
          let doc = current?.document
          if (data.file instanceof File) {
            const uploaded = await firebaseUploader(data.file, propertyId + '_fianca_bancaria', GUARANTEES_SECTION)
            doc = uploaded
          }
          return {
            document: doc,
          }
        },
      },
      {
        title: 'Seguro Fiança',
        type: 'seguro',
        descriptionList: [
          'O seguro fiança é um seguro que garante o pagamento do aluguel mesmo em eventuais inadimplências do inquilino. Além disso, muitas seguradoras ainda oferecem a assistência jurídica para os casos de despejo.',
          'Pode ser contratado pelo Proprietário ou pelo Inquilino.',
          'Na Moben customizamos o contrato de acordo com as exigências da seguradora escolhida.',
          'Muitas seguradoras exigem a inclusão de cláusulas específicas no contrato de aluguel. Caso o produto contratado tenha esta exigência, insira as cláusulas aqui:',
        ],
        formComp: GuaranteeTypeSeguroFianca,
        postformater: (payload) => ({
          ...payload,
          refund_value: formatMoneyBrToFloat(payload.refund_value),
        }),
      },
    ],
  },
  {
    title: 'Caução',
    type: 'caucao',
    descriptionList: [
      'Caução é o adiantamento de um valor máximo, equivalente a três meses de aluguel depositados em uma conta poupança conjunta entre Proprietário e Inquilino.',
      'Este depósito é feito adiantado e guardado para uso futuro em caso de inadimplência.',
      'Se nunca for usado, ao término do contrato de aluguel o valor e seus rendimentos são devolvidos ao depositante.',
    ],
    subtypes: [
      {
        title: 'Dinheiro',
        type: 'dinheiro',
        descriptionList: [],
        formComp: GuaranteeTypeCaucaoDinheiro,
        postformater: (data) => ({rent_months: formatMoneyBrToFloat(data.rent_months)}),
      },
      {
        title: 'Título de capitalização',
        type: 'titulo_capitalizacao',
        descriptionList: [],
        formComp: GuaranteeTypeCaucaoCapitalizacao,
      },
      {
        title: 'Bens Imóveis',
        type: 'bens_imoveis',
        descriptionList: [],
        formComp: GuaranteeTypeCaucaoImoveis,
        preformater: (payload) => ({
          registration_number: payload.registration_number,
          file: {name: payload.document.name_original},
        }),
        postformater: async (data, propertyId, current) => {
          let doc = current?.document
          if (data.file instanceof File) {
            const uploaded = await firebaseUploader(data.file, propertyId + '_caucao_bens_imoveis', GUARANTEES_SECTION)
            doc = uploaded
          }
          return {
            registration_number: data.registration_number,
            document: doc,
          }
        },
      },
    ],
    /*postformater: (payload) => ({
      amount: formatMoneyBrToFloat(payload.amount),
      bank: {
        ...payload.bank,
        name: bankCodeToName(payload.bank.name),
      },
    }),*/
  },
  {
    title: 'Sem Garantia',
    type: 'none',
    descriptionList: [
      'O contrato de locação sem garantias pode trazer algumas vantagens para o proprietário como por exemplo a velocidade de despejo, a cobrança antecipada do aluguel e a facilidade na locação do imóvel.',
      'Por outro lado o proprietário não terá a quem recorrer em caso de inadimplência.',
    ],
    formComp: GuaranteeTypeNone,
    preformater: (payload) => ({
      cobranca_boleto: payload.receive,
      // cobranca_inicio: payload.start_at,
    }),
    postformater: (data) => ({
      receive: data.cobranca_boleto,
      // start_at: data.cobranca_inicio ? dateParser(data.cobranca_inicio) : null,
    }),
  },
  /*{
    title: 'Não escolhida',
    type: 'no-tenant',
    descriptionList: ['Garantia ainda não foi foi escolhida.'],
    formComp: GuaranteeTypeNone,
    // postformater: () => ({}),
  },*/
]

/**
 *
 * @param {string} type
 * @returns {GuaranteeOptionItem|undefined}
 */
export const findGuaranteeByType = (type) => guaranteesList.find((gua) => gua.type === type)

export default guaranteesList
