import Typography from '@material-ui/core/Typography'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import getContractDownloadURL from '@root/components/App/Contract/getContract'
import {Button} from '@root/components/Button/Button'
import Text from '@root/components/Layout/Typography/Text'
import Title from '@root/components/Layout/Typography/Title'
import {useStyles} from '@root/components/YourProperty/styles'
import useContract from '@root/hooks/useContract'
import {hasInitiatedContractCreation} from '@root/lib/contract/progressChecker'
import {getCreatedRoute, getStepsIndexRoute} from '@root/lib/contract/stepWalker'
import routes, {routeWithID} from '@root/utils/routes'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

const SectionContract = () => {
  const classes = useStyles()
  const history = useHistory()

  const property = useSelector((state) => state.property?.data)
  const {isContractBlocked, isBuilding, isInProgress, contractStatus, validity} = useContract(property)
  const contractFileUrl = getContractDownloadURL(property)

  const config = useMemo(() => {
    const conf = {
      label: 'Criar contrato',
      route: routeWithID(routes.CONTRACT_ENTRY, property.uid),
      url: null,
    }
    if (isInProgress && contractFileUrl) {
      conf.label = 'Ver contrato'
      conf.url = contractFileUrl
    } else if (isContractBlocked) {
      conf.label = 'Ações do contrato'
      conf.route = getCreatedRoute(property.uid)
    } else if (hasInitiatedContractCreation(property)) {
      conf.label = 'Continuar contrato'
      conf.route = getStepsIndexRoute(property.uid)
    }

    return conf
  }, [property])

  const handleContractPath = () => {
    if (config.url) {
      window.open(config.url, '_blank')
    } else {
      history.push(config.route)
    }
  }

  const handleSurveyPath = () => {
    history.push(routes.SURVEY)
  }

  const getContractSubtitle = () => {
    if (!contractStatus) return 'Nenhum contrato incluído'

    if (isBuilding) return 'Contrato em processo de edição'

    if (isInProgress) {
      return 'Contrato em andamento'
    } else {
      return ''
    }
  }

  return (
    <div className={classes.sectionItem}>
      <Title bold>Contrato</Title>

      <Text italic>{getContractSubtitle()}</Text>

      {isInProgress && (
        <>
          <DataDisplay asTable label="Início do contrato" value={validity.start} readableType="date" />
          <DataDisplay asTable label="Fim do contrato" value={validity.end} readableType="date" />
        </>
      )}

      <div className={classes.buttonsContainer}>
        <Button onClick={handleContractPath}>{config.label}</Button>
        <Button onClick={handleSurveyPath}>Vistoria</Button>
      </div>
    </div>
  )
}

export default SectionContract
