import Typography from '@material-ui/core/Typography'
import useStyles from '@root/components/ContainerStyle'
import IconPencil from '@root/components/icons/IconPencil'
import analyticsLogger from '@root/firebase/analytics.utils'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {stepsList} from '@root/lib/contract/contractStepsMap'
import {checkProgress, isContractEditable} from '@root/lib/contract/progressChecker'
import {buildStepUrl, getCreatedRoute} from '@root/lib/contract/stepWalker'
import {convertDateToBr} from '@root/lib/dateParser'
import React, {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {addContractObservationAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

import {IconWrapper} from '../IconWrapper/IconWrapper'
import {Box} from '../Layout/Container/Box/Box'

const StepEntry = ({isChecked, onClick, disabled, title, subtitle}) => {
  const classes = useStyles()

  const clickHandler = (e) => {
    if (!disabled && onClick) {
      onClick(e)
    }
  }
  return (
    <Box type="withCheckBox" isChecked={isChecked} onClick={clickHandler} smallPadding h="67px">
      <div className={classes.itemInfoContainer}>
        <Typography variant="body1" color="secondary">
          {title}
        </Typography>
        <Typography variant="body1">{subtitle}</Typography>
      </div>
      <div>
        {isContractEditable && (
          <IconWrapper disabled={disabled} isAbsolutePositionRightCenter>
            <IconPencil />
          </IconWrapper>
        )}
      </div>
    </Box>
  )
}

export function ContractIndex() {
  const classes = useStyles()
  const history = useHistory()

  const actions = actionDispatcher({
    setContractStepAction,
    setGlobalLoaderAction,
    addContractObservationAction,
    setNavbarTitleAction,
  })

  const user = useSelector((s) => s.user.user)
  const propertySnap = useSelector((s) => s.property.snap)
  const property = useSelector((s) => s.property.data) ?? {}
  const lastNotificationText = useMemo(() => {
    let text = ''
    const notifications = property.contract?.observations ?? []
    const lastNotification = notifications.pop()
    if (lastNotification) {
      text = `Última alteração feita em ${convertDateToBr(lastNotification.created_at)} - ${lastNotification.text}`
    }
    return text
  }, [property])

  const {canContractBeGenerated, isContractBlocked, isContractProgressComplete, generateContract} = useContract(
    property,
  )

  const indexItems = stepsList.filter((step) => step.has_form)

  const progressChecks = useMemo(() => {
    const progress = {}
    indexItems.forEach((step) => {
      progress[step.id] = checkProgress(property, step.id)
    })

    return progress
  }, [property])

  const handleGenerateContract = () => {
    generateContract(propertySnap, user).then(goToContractCreated)
  }

  const goToContractCreated = () => history.push(getCreatedRoute(property.uid))

  const handleIconClick = (item) => () => {
    if (item.path) {
      analyticsLogger.logEvent(`click_contrato_indice_${item.id}`)
      history.push(buildStepUrl(item.path))
    }
  }

  useEffect(() => {
    console.log('handleContractLoad -> loadContract')
    handleContractLoad(loadContract, user, history)
  }, [])

  useEffect(() => {
    // actions.setNavbarTitleAction(addressDisplay(property.address, ADDRESS_VERBOSITY_COMPLETE))
    actions.setNavbarTitleAction(property.alias)
    if (isContractBlocked) {
      history.replace(getCreatedRoute(property.uid))
    }
  }, [property])

  return (
    <div className={classes.root}>
      {isContractProgressComplete && (
        <StepEntry
          isChecked={false}
          disabled={false}
          title="Ver histórico de aprovação"
          subtitle={lastNotificationText}
          onClick={goToContractCreated}
        />
      )}

      {indexItems.map((item, index) => (
        <StepEntry
          key={item.title}
          isChecked={progressChecks[item.id]}
          title={`${index + 1} - ${item.title}`}
          subtitle={item.subtitle}
          disabled={!item.path}
          onClick={handleIconClick(item)}
        />
      ))}
    </div>
  )
}
