/** @module simpleValuesDisplayMap */
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import {convertDateToBr} from 'date-parser'
import {translate} from '@root/lib/intl/translations'
import {whatsAppLinkGenerator} from '@root/lib/simplePhoneFormatter'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import React from 'react'

export const readableValue = (value, type) => {
  if (!type) {
    return translate(value) ?? value
  }

  let readable = value
  switch (type) {
    case 'money':
      readable = <NumberFormatCustom value={value} displayType="text" />
      break
    case 'date':
      readable = convertDateToBr(value)
      break
    case 'phone':
      readable = <WhatsAppLinkWrapper phone={value}>{value}</WhatsAppLinkWrapper>
      break
    case 'email':
      readable = <a href={`mailto:${value}`}>{value}</a>
      break
    case 'usertype':
      readable = value === USER_TYPE_TENANT ? 'Inquilino' : 'Fiador'
      break
    case 'boolean':
      readable = value ? 'Sim' : 'Não'
      break
  }

  return readable
}
