import firebase from '@root/firebase/firebase.utils'

export default class HttpRepositoryInterface {
  /** @var {HttpApiInterface} */
  _api

  /** @var {string} */
  _userId = ''

  _retrieveUserId() {
    if (!this._userId) {
      this._userId = firebase.auth().currentUser.uid
    }
    return this._userId
  }

  _skipRequest() {
    return Promise.reject(new Error('Request not sent'))
  }
}
