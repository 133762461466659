import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import {convertDateToBr} from '@root/lib/dateParser'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'

const GuaranteeTypeNone = () => {
  const {control, errors, setValue, watch} = useFormContext()
  const [selectedDate, setSelectedDate] = useState()
  const chargeType = watch('cobranca_boleto')

  const onChangeDate = (date) => {
    setSelectedDate(date)
    setValue('cobranca_inicio', convertDateToBr(date))
  }

  useEffect(() => {
    setValue('cobranca_inicio', chargeType === 'before' ? new Date() : '')
  }, [chargeType])

  return (
    <>
      <Typography variant="h6" style={{marginTop: '30px'}}>
        Como pretende que seja feita a cobrança do boleto?
      </Typography>
      <FormItem
        control={control}
        errors={errors}
        type="radio"
        name="cobranca_boleto"
        rules={{validate: validateRequired}}
        options={[
          optionFactory(
            'Receber adiantado: o inquilino paga e mora (sugerimos alinhar com o inquilino antes de optar por esta modalidade)',
            'before',
          ),
          optionFactory(
            'Receber depois: o inquilino mora e paga ao final do mês (opção mais comum de mercado)',
            'after',
          ),
        ]}
      />
    </>
  )
}

export default GuaranteeTypeNone
