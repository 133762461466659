import {Dialog, DialogActions, DialogContent, Grid, Typography} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import {makeStyles} from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import MuiAlertTitle from '@material-ui/lab/AlertTitle'
import fetcher from '@root/application/infra/api/fetcher'
import getContractDownloadURL from '@root/components/App/Contract/getContract'
import {Button} from '@root/components/Button/Button'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import {ContractButton} from '@root/components/Contract/ContractButton'
import {useStyles as useContractStyles} from '@root/components/Contract/styles.js'
import ContractNotification from '@root/components/ContractNotification/ContractNotification'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import PageMini from '@root/components/PageMini'
import analyticsLogger from '@root/firebase/analytics.utils'
import {getURL} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import {convertDateToBr} from '@root/lib/dateParser'
import {discordNotifyTenantApprovedContract} from '@root/lib/discordNotification'
import {isDevEnv} from '@root/lib/envDetector'
import {addNotificationToProperty, createNotificationBody} from '@root/lib/notification'
import {validateCpf} from '@root/lib/simpleValidators'
import {guessUserTypeFromProperty, isUserTypeOwner, USER_TYPE_TENANT} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import userModel from '@root/model/userModel'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {setApprovalAllAction} from '@root/redux/approval/approval.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'
import contractMapper from 'status-mapper/mapper/contract-mapper'
import {CPF_FORMAT, LOADER_MODE_TEXT} from '@root/utils/constants'
import routes, {routeWithID} from '@root/utils/routes'

import addressDisplay, {ADDRESS_VERBOSITY_COMPLETE} from '../../../lib/addressDisplay'
import {setNavbarTitleAction} from '../../../redux/application/application.actions'

// const LogoHeader = () => {
//   const classesSignupInicial = useStyles()
//   return (
//     <div className={classesSignupInicial.logoContainer} style={{marginTop: 12}}>
//       <a>
//         <img src={Logo} />
//       </a>
//     </div>
//   )
// }

const useStyles = makeStyles({
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contractButtons: {
    'display': 'flex',
    '& > :not(:last-child)': {
      marginRight: '20px',
    },
  },
})

const TenantApprovalLogin = ({history}) => {
  const {control, errors, handleSubmit, watch} = useForm()

  const classes = useStyles()
  const classesContract = useContractStyles()

  const [approvedModal, showApprovedModal] = useState(false)
  const [mainTenant, setMainTenant] = useState()
  const [cpf, setCPF] = useState(isDevEnv() ? '04885177022' : undefined)
  //const [cpf, setCPF] = useState()

  const [snackData, setSnackData] = useState()

  const propertyId = getURL().searchParams.get('id')
  const user = useSelector((s) => s.user.user)
  const {property, propertySnap, owner, ready, approved} = useSelector((s) => s.approval)
  const notificationsList = property?.contract?.observations
  const notifications = notificationsList ?? []
  const hasNotifications = notifications.length > 0

  const proposalHelper = useMemo(() => new ProposalHelper(), [])
  const pdfLink = useMemo(() => getContractDownloadURL(property), [property])
  const hasContractGenerated = !!pdfLink

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setCurrentProperty,
    setApprovalAllAction,
    setNavbarTitleAction,
  })

  const handleViewContract = (e) => {
    e.preventDefault()
    window.open(pdfLink, '_blank')
  }

  const goToObservations = () => {
    history.push(routeWithID(routes.CONTRACT_APPROVAL_OBSERVATION, propertyId))
  }

  const searchCPF = async (form) => {
    actions.setGlobalLoaderAction(true)
    const searchCpf = form.cpf

    proposalHelper.initFromProperty(property)
    await proposalHelper.loadUsersRefs()

    const mainTenantLoaded = proposalHelper.getTenantAt(0)

    if (property && mainTenantLoaded && mainTenantLoaded.cpf === searchCpf) {
      setCPF(searchCpf)
      setMainTenant(mainTenantLoaded)
    } else {
      alert(`O CPF ${searchCpf} não encontrado.`)
    }
    actions.setGlobalLoaderAction(false)
  }

  const handleApprovalBtn = async () => {
    actions.setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, {title: 'Aprovação em andamento.'})
    const approvedDate = new Date()
    const approvalData = {
      date: approvedDate,
      type: mainTenant ? 'inquilino' : 'anonymous',
      name: mainTenant?.name ?? 'Não informado',
      status: contractMapper.status.APPROVED,
      // TODO sera que adianta algo logar o IP
      // http://meuip.com/api/meuip.php
    }
    const updatedData = {
      'contract.approval': approvalData,
      'contract.status': contractMapper.status.APPROVED,
      'contract.date_approved': approvedDate,
    }

    actions.setGlobalLoaderDataAction({title: 'Notificando o proprietário'})
    await propertySnap.ref.update(updatedData)
    await fetcher.post('/mailing', {
      code: 'm0006',
      owner_id: property.ower_id,
    })
    await addNotificationToProperty(
      propertySnap.ref,
      createNotificationBody({origin: USER_TYPE_TENANT, text: 'Inquilino aprovou o contrato'}),
    )
    analyticsLogger.logEvent('button_click', {
      alternative_event_name: 'contrato_aprovar_aprovar',
      button_name: 'APROVAR',
    })

    discordNotifyTenantApprovedContract(user, pdfLink, property)

    actions.setGlobalLoaderAction(false)

    history.push(routeWithID(routes.CONTRACT_APPROVAL_DONE, propertyId))
  }

  const hasApproved = (propertyd) =>
    contractMapper.assertStatus.isApproved(propertyd?.contract?.status) ||
    contractMapper.assertStatus.isInProgress(propertyd?.contract?.status)

  const closeSnack = () => setSnackData()

  const loadProperty = async () => {
    actions.setGlobalLoaderAction(true)
    const propSnap = await propertyModel.getById(propertyId)
    if (propSnap.exists) {
      const prop = propSnap.data()
      if (isUserTypeOwner(guessUserTypeFromProperty(propSnap.data(), user))) {
        alert('Você não pode aprovar o próprio contrato')
        history.push(routes.DASHBOARD_ROOT)
      }

      const ownerSnap = await userModel.getById(prop.ower_id)
      const ownerData = ownerSnap.data()

      actions.setNavbarTitleAction(addressDisplay(prop.address, ADDRESS_VERBOSITY_COMPLETE))

      actions.setApprovalAllAction({
        property: prop,
        propertySnap: propSnap,
        owner: ownerData,
        ready: true,
        approved: hasApproved(prop),
      })
      actions.setGlobalLoaderAction(false)
    }
  }

  useEffect(() => {
    loadProperty()
  }, [])

  if (!ready) {
    return <PageMini></PageMini>
  }
  if (!cpf && ready) {
    return (
      <PageMini innerTitle="Informe o seu CPF para acessar o contrato.">
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <FormItem
              control={control}
              errors={errors}
              type="mask"
              name="cpf"
              label="CPF"
              rules={{validate: validateCpf}}
              maskInputProps={{format: CPF_FORMAT}}
            />
          </Grid>
          <Grid item>
            <Button bgType="purple" onClick={handleSubmit(searchCPF)}>
              Acessar
            </Button>
          </Grid>
        </Grid>
      </PageMini>
    )
  }

  if (!property && ready) {
    return (
      <PageMini>
        <Typography variant="h4">Imóvel não encontrado</Typography>
      </PageMini>
    )
  }

  return (
    <PageMini
      outerTitleLine1="Bem vindo!"
      outerNode={
        <Typography variant="body2">
          {owner.name} disponibilizou o contrato de locação para sua análise. Leia com atentamente e caso seja
          necessário, sugira as alterações que achar necessárias. Caso contrario basta aceitar.
        </Typography>
      }>
      <div className={classes.mainContent}>
        <div className={classes.contractButtons}>
          <ContractButton type="visualizar" onClick={handleViewContract} disabled={!hasContractGenerated} />
        </div>

        <Divider dashed m="15px 0 20px" />

        {!approved && (
          <>
            <Button
              m="0 auto 20px"
              w="244px"
              h="48px"
              fz="15px"
              fw={600}
              bgType="green"
              onClick={handleApprovalBtn}
              disabled={!hasContractGenerated}>
              Aceitar contrato
            </Button>

            <Button noBg m="0 auto 20px" w="256px" h="48px" fz="15px" fw={600} onClick={goToObservations}>
              Sugerir alteração/correção
            </Button>
          </>
        )}

        {!hasContractGenerated && (
          <Typography variant="body2">
            O proprietário ainda ainda não concluiu o contrato, por favor aguarde, assim que tudo estiver pronto
            avisaremos você
          </Typography>
        )}
        {approved && (
          <Typography variant="body2">Aprovado em: {convertDateToBr(property.contract.approval.date)}</Typography>
        )}

        {hasNotifications && (
          <>
            <Divider dashed m="15px 0 20px" />
            <div className={classesContract.contractCreated}>
              <ContractNotification notifications={notifications} allowEdit={false} />
            </div>
          </>
        )}
      </div>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={20000}
        open={snackData !== undefined}
        onClose={closeSnack}>
        <MuiAlert>
          <MuiAlertTitle>{snackData?.title}</MuiAlertTitle>
          {snackData?.text}
        </MuiAlert>
      </Snackbar>

      <Dialog open={approvedModal}>
        <DialogContent style={{padding: '30px 80px'}}>
          <Typography style={{textAlign: 'center', marginBottom: 20, fontWeight: 700, fontSize: '1.8em'}}>
            Contrato Aprovado!
          </Typography>
          <Typography style={{marginBottom: 20, fontSize: '1.3em'}}>
            Ótimo! <br />
            Falta pouco pra você poder entrar no imóvel.
          </Typography>
          <Typography style={{marginBottom: 20, fontSize: '1.3em'}}>
            Atenção aos próximos passos: <br />- Assinatura do contrato via DocuSign
          </Typography>
          <Typography style={{marginBottom: 20, fontSize: '1.3em'}}>
            Você vai receber um e-mail para assinatura através da plataforma da DocuSign. Basta acessar o link e assinar
            digitalmente.
          </Typography>
          <Typography style={{marginBottom: 40, fontSize: '1.3em'}}>
            - Entrega das Chaves: Deverá ser combinado diretamente com o proprietário de que forma será feita a entrega
            das chaves.
          </Typography>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'center', paddingBottom: 30}}>
          <ButtonTransparentBg onClick={() => showApprovedModal(false)} color="primary" autoFocus>
            OK, Entendi.
          </ButtonTransparentBg>
        </DialogActions>
      </Dialog>
    </PageMini>
  )
}

export default TenantApprovalLogin
