import {Grid, Typography} from '@material-ui/core'
import getContractDownloadURL from '@root/components/App/Contract/getContract'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import analyticsLogger from '@root/firebase/analytics.utils'
import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import {convertDateToBr} from '@root/lib/dateParser'
import React from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

const TenantDashboardContract = () => {
  const property = useSelector((s) => s.tenantDashboard.property)
  const owner = useSelector((s) => s.tenantDashboard.owner)
  const contract = property?.contract
  const addr = property?.address ?? {}
  const history = useHistory()
  const OPEN_ID = 'tenant_dashboard_open_contract'
  const APPROVE_ID = 'tenant_dashboard_approve_contract'

  const handleContractOptionsClick = (id) => {
    const contractUrl = getContractDownloadURL(property)
    let redirect = false
    let analyticsParameters = {alternative_event_name: `click_${id}`, button_name: ''}

    switch (id) {
      case OPEN_ID:
        analyticsParameters.button_name = 'ver contrato'
        window.open(contractUrl, '_blank')
        break
      case APPROVE_ID:
        analyticsParameters.button_name = 'aprovar'
        redirect = true
        break
    }

    analyticsLogger.logEvent('button_click', analyticsParameters)
    if (redirect) {
      history.push(`${routes.CONTRACT_APPROVAL}?id=${property.uid}`)
    }
  }

  return (
    <DashboardWrapper title="CONTRATO">
      <Typography variant="h6" align="center" style={{fontWeight: 700}}>
        Minuta do Contrato de Locação
      </Typography>
      <br />
      <section>
        <Typography variant="h6">Seu contrato foi criado com sucesso.</Typography>
        <Typography variant="h6">Por favor leia com atenção ANTES DE APROVAR.</Typography>
        <br />
        <Typography variant="h6">
          Assim que for APROVADO vamos enviar para a assinatura digital via DocuSign.
        </Typography>
      </section>
      <br />
      <section id="tenant-dashboard-contract-info">
        <Typography variant="h6">
          Imóvel: <strong>{property?.alias}</strong>
        </Typography>
        <Typography variant="h6">
          Endereço: <strong>{addressDisplay(addr, ADDRESS_VERBOSITY_MINIMAL)}</strong>
        </Typography>
        <br />
        <Typography variant="h6">
          Proprietário: <strong>{owner?.name}</strong>
        </Typography>
        <Typography variant="h6">
          Início do contrato: <strong>{convertDateToBr(contract?.validity.start)}</strong>
        </Typography>
      </section>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonTransparentBg id={OPEN_ID} onClick={() => handleContractOptionsClick(OPEN_ID)} fullWidth>
            VER CONTRATO
          </ButtonTransparentBg>
        </Grid>
        <Grid item xs={12}>
          <ButtonTransparentBg id={APPROVE_ID} onClick={() => handleContractOptionsClick(APPROVE_ID)} fullWidth>
            APROVAR
          </ButtonTransparentBg>
        </Grid>
      </Grid>
    </DashboardWrapper>
  )
}

export default TenantDashboardContract
