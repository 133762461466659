import {ANSWER_NO, ANSWER_YES} from '@root/components/App/Contract/DefaultRadioGroup'

/**
 * @param {string} label
 * @param {*} value
 * @param {string} [description] descricao curta sobre a opcao
 * @return {{label:string, value:*, description:string}}
 */
export const optionFactory = (label, value, description) => ({
  label,
  value,
  description,
})

/**
 * @param {string} label
 * @return {{label:string, value:*}}
 */
export const optionFactorySingle = (label) => optionFactory(label, label)

/**
 * @param {IBaseMapper} mapper
 * @param {string} prop
 * @return {{label:string, value:*}}
 */

export const optionFactoryFromMapper = (mapper, prop) => ({
  label: mapper.translate(mapper.status[prop]),
  value: mapper.status[prop],
})

export const optionsYesNo = () => [optionFactory('Sim', ANSWER_YES), optionFactory('Não', ANSWER_NO)]
