import {Grid, Typography} from '@material-ui/core'
import houseIcon from '@root/assets/como_funciona_icon_2.png'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import BackLink from '@root/components/BackLink'
import addressDisplay, {ADDRESS_VERBOSITY_COMPLETE} from '@root/lib/addressDisplay'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'

import useStyles from '../styles'

const TenantDashboardProperty = () => {
  const classes = useStyles()
  const property = useSelector((s) => s.property.data)

  return (
    <DashboardWrapper title="">
      <Grid container align="center" className={classes.tenantDashboardBlock}>
        <Grid item xs={12} sm={10} align="center">
          <Typography variant="h6">Dados do imóvel</Typography>
          <br />
          <Typography variant="body1">{addressDisplay(property?.address, ADDRESS_VERBOSITY_COMPLETE)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} style={{display: 'flex'}} justify="center" alignItems="center">
          <img src={houseIcon} alt="icone casa" style={{display: 'block', width: 50, height: 40}} />
        </Grid>
      </Grid>
      <Grid container align="center" justify="space-evenly">
        <Grid item xs={8} align="left">
          <Typography>Nome do imóvel:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{property?.alias}</Typography>
        </Grid>
        <br />
        <Grid item xs={8} align="left">
          <Typography>Valor mensal do aluguel:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{formatFloatToBrCurrency(property?.total_amount)}</Typography>
        </Grid>
        <br />
        <Grid item xs={8} align="left">
          <Typography>Valor mensal do condomínio:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{formatFloatToBrCurrency(property?.condominium_amount)}</Typography>
        </Grid>
        <br />
        <Grid item xs={8} align="left">
          <Typography>Valor anual do IPTU:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{formatFloatToBrCurrency(property?.iptu_annual)}</Typography>
        </Grid>
        <Grid item xs={8} align="left">
          <Typography>Valor do boleto:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{formatFloatToBrCurrency(property?.total_amount)}</Typography>
        </Grid>
        <Grid item xs={8} align="left">
          <Typography>Imobiliado:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{property?.furnished === 'yes' ? 'sim' : 'não'}</Typography>
        </Grid>
        <Grid item xs={8} align="left">
          <Typography>Tamanho:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{property?.specs?.footage} &#13217;</Typography>
        </Grid>
        <Grid item xs={8} align="left">
          <Typography>Quartos:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{property?.specs?.rooms}</Typography>
        </Grid>
        <Grid item xs={8} align="left">
          <Typography>Banheiros:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{property?.specs?.bathrooms}</Typography>
        </Grid>
        <Grid item xs={8} align="left">
          <Typography>Garagem:</Typography>
        </Grid>
        <Grid item xs={4} align="left">
          <Typography>{property?.specs?.garage}</Typography>
        </Grid>
      </Grid>
      <Grid container align="center" justify="center">
        <BackLink />
      </Grid>
    </DashboardWrapper>
  )
}

export default TenantDashboardProperty
