import Typography from '@material-ui/core/Typography'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import IconCheck from '@root/components/icons/IconCheck'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {currencyFormatProps} from '@root/components/NumberMask'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import {fromTimestampToDate, getMonthYear} from '@root/lib/dateParser'
import {formatFloatToBrCurrency, formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {numberFormatValidateCurrency, validateTextLen} from '@root/lib/simpleValidators'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {paymentMapper} from 'status-mapper'
import routes from '@root/utils/routes'

import {paymentMethodsMap} from '../../../../lib/paymentMethods'
import {useStyles} from './styles'

const MAX_DESCRIPTION_LENGTH = 255

export function PaymentDetail() {
  const classes = useStyles()
  const history = useHistory()
  const formHook = useForm()

  const {handleSubmit, control, errors, setValue} = formHook
  const [charCount, setCharCount] = useState(0)

  const paymentId = useQuery().getParam('payment')
  const property = useSelector((s) => s.property?.data)
  const rentfees = useSelector((s) => s.property?.data.contract?.rentfees)
  const payment = useSelector((s) => s.property?.data.contract?.payments.find((payment) => payment.id === paymentId))
  const paymentMethod = useSelector((s) => s.property?.data.contract?.payment_method?.option)
  const loggedUser = useSelector((s) => s.user.user)
  const canEdit = paymentMapper.assertStatus.isWaitingSchedule(payment?.status)

  const actions = actionDispatcher({
    setNavbarTitleAction,
    setGlobalLoaderAction,
  })

  useEffect(() => {
    if (payment) {
      const date = fromTimestampToDate(payment.due_date)

      actions.setNavbarTitleAction(getMonthYear(date))
    }
  }, [payment])

  const limitTextLen = (ev) => {
    const text = ev.target.value
    let textLen = text.length
    if (textLen > MAX_DESCRIPTION_LENGTH) {
      setValue('description', text.substring(0, MAX_DESCRIPTION_LENGTH))
      textLen = MAX_DESCRIPTION_LENGTH
    }
    setCharCount(textLen)
  }

  const handleClickSalvarAlteracao = async ({new_amount, description}) => {
    actions.setGlobalLoaderAction(true)
    // await discordNotifyBoletoUpdate(
    //   property.uid,
    //   loggedUser.name,
    //   loggedUser.uid,
    //   curSchedule.amount,
    //   new_amount,
    //   convertDateToBr(curSchedule.due_date),
    //   description,
    // )
    await propertyModel.fetchChangePayment(property.uid, loggedUser.uid, {
      id: paymentId,
      amount: formatMoneyBrToFloat(new_amount),
      description,
    })
    actions.setGlobalLoaderAction(false)
    history.push(routes.DASHBOARD_BOLETOS_STATUS)
  }

  if (!canEdit) {
    return (
      <div className={classes.paymentDetail}>
        <Typography variant="body2">Este boleto já foi emitido e portanto não pode ser alterado.</Typography>
      </div>
    )
  }

  return (
    <div className={classes.paymentDetail}>
      <Typography variant="body2" style={{marginTop: '60px'}}>
        Você poderá fazer alterações no valor do {paymentMethodsMap[paymentMethod]} até 15 dias antes do seu vencimento.
        A partir deste período as alterações só serão aplicadas a partir do mês subsequente.
      </Typography>

      <Box bgType="gray" smallPadding>
        <div className={classes.amountContainer}>
          <Typography variant="body1">Valor mensal do aluguel</Typography>
          <Typography variant="body1" className={classes.amount}>
            {formatFloatToBrCurrency(rentfees?.rent)}
          </Typography>
        </div>
      </Box>

      {rentfees && rentfees.include_condominium && (
        <Box bgType="gray" smallPadding>
          <div className={classes.amountContainer}>
            <Typography variant="body1">Valor mensal do condomínio</Typography>
            <Typography variant="body1" className={classes.amount}>
              {formatFloatToBrCurrency(rentfees?.condominium)}
            </Typography>
          </div>
        </Box>
      )}

      {rentfees && rentfees.include_iptu && (
        <Box bgType="gray" smallPadding>
          <div className={classes.amountContainer}>
            <Typography variant="body1">Valor anual do IPTU</Typography>
            <Typography variant="body1" className={classes.amount}>
              {formatFloatToBrCurrency(rentfees?.iptu_annual)}
            </Typography>
          </div>
        </Box>
      )}

      {rentfees && rentfees.include_fire_insurance && (
        <Box bgType="gray" smallPadding>
          <div className={classes.amountContainer}>
            <Typography variant="body1">Valor do Seguro Incêndio</Typography>
            <Typography variant="body1" className={classes.amount}>
              {formatFloatToBrCurrency(rentfees?.fire_insurance_annual)}
            </Typography>
          </div>
        </Box>
      )}

      <FormProvider {...formHook}>
        <Box bgType="gray" m="20px auto 40px !important">
          <div className={classes.valorBoletoContainer}>
            <Typography align="center" variant="h6">
              Valor do {paymentMethodsMap[paymentMethod]}
            </Typography>
            <FormItem
              defaultValue={payment?.amount}
              control={control}
              errors={errors}
              type="mask"
              name="new_amount"
              rules={{
                validate: numberFormatValidateCurrency,
              }}
              maskInputProps={currencyFormatProps}
              style={{width: 120, transform: 'translateY(5px)'}}
            />
          </div>
        </Box>

        <div className={classes.motivoContainer}>
          <Typography variant="body2">Indique o motivo da alteração do {paymentMethodsMap[paymentMethod]}:</Typography>
          <FormItem
            control={control}
            errors={errors}
            type="textarea"
            name="description"
            rules={{validate: (text) => validateTextLen(text, 10, MAX_DESCRIPTION_LENGTH)}}
            onChange={limitTextLen}
          />
          <Typography variant="body1">{charCount} de 255 caracteres</Typography>
        </div>
      </FormProvider>

      <Button
        w="218px"
        h="48px"
        m="10px auto"
        bgType="green"
        icon={IconCheck}
        onClick={handleSubmit(handleClickSalvarAlteracao)}>
        Salvar alteração
      </Button>
    </div>
  )
}
