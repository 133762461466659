import Typography from '@material-ui/core/Typography'
import guaranteesList, {findGuaranteeByType} from '@root/components/App/Contract/guaranteesList'
import {useStyles} from '@root/components/Contract/styles'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import ProposalManager from '@root/lib/contract/ProposalManager'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import {proposalSaver} from '@root/lib/proposalSaver'
import {validateRequired} from '@root/lib/simpleValidators'
import {USER_TYPE_FIADOR} from '@root/lib/userType'
import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  setContractGuaranteesAction,
  setContractPropertyAction,
  setContractStepAction,
} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {MOBEN_WHATSAPP, USER_SOURCE_GUARANTEE} from '@root/utils/constants'

const SUB_FORM_RESET_TIMEOUT = 400
// let proposalHelper = new ProposalHelper()
export default function Guarantee() {
  const classes = useStyles()
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, control, errors, watch, reset, setValue, getValues} = formHook

  const extraReducer = (state, action) => {
    if (action.type === 'set_extra') {
      return action.extra
    }
  }

  // const [isProposalLoaded, setProposalsLoaded] = useState(false)
  const [currentFiador, setCurrentFiador] = useState()

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertyId = useSelector((s) => s.property.currentPropertyId)
  const propertySnap = useSelector((s) => s.property.snap)
  const currentGuarantee = useSelector((s) => s.property.data?.contract?.guarantees)

  const proposalHelper = useMemo(() => ProposalHelper.initWithProperty(property), [propertyId])

  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const mainGuaranteeType = watch('guarantee')
  const subGuaranteeType = watch('guarantee_sub')

  /**
   * @param {GuaranteeOptionItem|undefined} guarantee
   */
  const resetForm = (guarantee, extra = {}) => {
    const isNoneReset = guarantee?.type === 'none'
    let formValues = {
      guarantee: mainGuaranteeType,
      guarantee_sub: subGuaranteeType,
      ...currentGuarantee?.data,
      ...extra,
    }
    // let formValues = {...getValues(), ...currentGuarantee?.data}

    if (guarantee && guarantee.preformater && currentGuarantee) {
      if (isNoneReset || guarantee.type === currentGuarantee?.subtype) {
        formValues = {...formValues, ...guarantee.preformater(currentGuarantee?.data)}
      }
    }
    reset(formValues)
  }

  const selectedGuarantee = useMemo(() => {
    const main = findGuaranteeByType(mainGuaranteeType)
    resetForm(main, {
      guarantee_sub: undefined,
    })
    return main
  }, [mainGuaranteeType])

  const selectedGuaranteeSub = useMemo(() => {
    let sub
    const guarantee = findGuaranteeByType(mainGuaranteeType)

    // console.log('guarantee', guarantee)
    if (guarantee) {
      sub = guarantee.subtypes?.find((item) => item.type === subGuaranteeType)
      // resetForm(sub)
    }

    return sub
  }, [subGuaranteeType])

  const isFiador = selectedGuarantee?.type === 'fianca' && selectedGuaranteeSub?.type === 'fiador'
  const isNone = selectedGuarantee?.type === 'none'

  const renderForm = (guarantee) => {
    if (!guarantee) {
      return null
    }

    return (
      <div>
        {guarantee.descriptionList.map((line, idx) => (
          <Typography key={idx} variant="body2">
            {line}
          </Typography>
        ))}

        {guarantee.formComp && (
          <div style={{marginTop: 20}}>
            <guarantee.formComp
              extraDispatch={extraDispatch}
              currentGuarantee={currentGuarantee}
              fiador={currentFiador}
            />
          </div>
        )}
      </div>
    )
  }
  const renderMainForm = useCallback(() => renderForm(selectedGuarantee), [selectedGuarantee])
  const renderSubForm = useCallback(() => renderForm(selectedGuaranteeSub), [selectedGuaranteeSub, currentFiador])

  const [extraState, extraDispatch] = useReducer(extraReducer)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
    setContractGuaranteesAction,
    setContractPropertyAction,
  })

  const handleClickContinuar = async () => {
    const {guarantee, guarantee_sub, ...formData} = getValues()

    actions.setGlobalLoaderAction(true)
    try {
      let payload = formData
      let type = selectedGuarantee.type
      let subtype = selectedGuaranteeSub?.type ?? ''

      if (selectedGuaranteeSub?.postformater) {
        payload = await selectedGuaranteeSub.postformater(formData, propertyId, currentGuarantee?.data)
      } else if (selectedGuarantee.postformater) {
        payload = await selectedGuarantee.postformater(formData, propertyId, currentGuarantee?.data)
      }

      const guaranteeData = {type, subtype, data: payload}
      // 🐴 XGH time | aqui e que fica responsavel por salvar o fiador no proposals | essa parte de proposals saiu um pouco fora do controle
      if (isFiador) {
        const proposalManager = ProposalManager.initWithProperty(property)
        if (!proposalHelper.isValid()) {
          proposalHelper.createIfNotExists()
          proposalManager.addProposal(proposalHelper)
        }
        await proposalSaver({
          manager: proposalManager,
          propertySnap: propertySnap,
          originSource: USER_SOURCE_GUARANTEE,
          proposalType: USER_TYPE_FIADOR,
          tenantFormData: {...currentFiador, ...formData},
          proposalId: proposalHelper.getId(),
        })
        guaranteeData.data = {}
      }

      await propertySnap.ref.update(prepareContractStepUpdate({'contract.guarantees': guaranteeData}))
      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    if (currentGuarantee) {
      setValue('guarantee', currentGuarantee.type)
      setTimeout(() => setValue('guarantee_sub', currentGuarantee.subtype), SUB_FORM_RESET_TIMEOUT)
      // setValue('guarantee_sub', currentGuarantee.subtype)
    }
  }, [currentGuarantee])

  useEffect(() => {
    if (user) {
      handleContractLoad(loadContract, user, history)
    }
  }, [user])

  useEffect(() => {
    ;(async () => {
      actions.setGlobalLoaderAction(true)
      await proposalHelper.loadUsersRefs()

      if (proposalHelper.hasFiador()) {
        const fiador = proposalHelper.getFiadorAt(0)
        setCurrentFiador(fiador)
      }
      actions.setGlobalLoaderAction(false)
    })()
  }, [proposalHelper])

  return (
    <ContractContainer>
      <FormProvider {...formHook}>
        <form className={`${classes.root} ${classes.guarantee}`}>
          <Typography variant="body2">
            Selecione o tipo de garantia que será sua segurança em caso de inadimplência. Você poderá voltar e decidir a
            Garantia depois caso ainda não tenha fechado com o inquilino. Se você deseja seguir com outro tipo de
            garantia diferente dessas, entre em contato via{' '}
            <strong>
              <WhatsAppLinkWrapper
                phone={MOBEN_WHATSAPP}
                message="Olá, estou criando meu contrato e estou com dúvidas sobre as garantias.">
                WhatsApp
              </WhatsAppLinkWrapper>
            </strong>
            .
          </Typography>

          <FormItem
            fitContainer
            type="radio"
            name="guarantee"
            rules={{validate: validateRequired}}
            options={guaranteesList.map((item) => optionFactory(item.title, item.type))}
          />

          {selectedGuarantee && (
            <>
              <Box bgType="gray">
                {selectedGuarantee.type !== 'none' ? (
                  selectedGuarantee.descriptionList.map((line, idx) => (
                    <Typography key={idx} variant="body2">
                      {line}
                    </Typography>
                  ))
                ) : (
                  <div style={{marginTop: -20}} />
                )}

                <FormItem
                  fitContainer
                  control={control}
                  errors={errors}
                  type="radio"
                  name="guarantee_sub"
                  rules={{validate: !isNone ? validateRequired : undefined}}
                  options={selectedGuarantee.subtypes?.map((item) => optionFactory(item.title, item.type))}
                />

                {!isFiador && renderSubForm()}
                {isNone && renderMainForm()}
              </Box>

              {isFiador && (
                <>
                  <div style={{width: '100%', margin: '20px 0'}}>
                    <Divider dashed />
                  </div>
                  <div style={{padding: '0 10px'}}>{renderSubForm()}</div>
                </>
              )}
            </>
          )}
        </form>
      </FormProvider>

      <ContractContainerBottom>
        <ContractContinueButton
          onClick={handleSubmit(handleClickContinuar)}
          disabled={!selectedGuarantee?.formComp && !selectedGuaranteeSub?.formComp}
        />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
