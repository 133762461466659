import {makeStyles} from '@material-ui/core/styles'
import css from '@root/components/Layout/cssVariables'

const useStyles = makeStyles((theme) => ({
  dashboardTenant: {
    margin: '100px auto',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: `${css.colors.white}`,
    maxWidth: '1280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      maxWidth: 750,
    },
  },
  stepper: {
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dashboardTitle: {
    marginTop: '120px',
    marginBottom: '50px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '2rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '80px',
      marginBottom: '20px',
      fontSize: '1.5rem',
    },
  },
  pendingRegisterBtn: {
    boxSizing: 'border-box',
    border: 'none',
    boxShadow: '10px 10px 10px #00000015',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1rem',
    background: `${css.colors.purple}`,
    borderRadius: '50px',
    color: 'white',
    margin: '15px auto',
    padding: '10px 120px',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: 340,
      display: 'block',
      padding: '10px 60px',
    },
  },
  dashboardMainContainer: {
    'boxSizing': 'border-box',
    'width': '100%',
    'display': 'grid',
    'gridTemplateColumns': 'repeat(auto-fit, minmax(360px, 1fr))',
    'gridAutoRows': '70px',
    'gridGap': '25px',
    'marginBottom': '30px',
    '& h3, p': {
      margin: 0,
    },
    [theme.breakpoints.down('1280')]: {
      gridTemplateColumns: '360px 360px',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('760')]: {
      gridTemplateColumns: '99%',
      justifyContent: 'center',
      gridGap: '15px',
    },
  },
  dashboardOptions: {
    width: '100%',
  },
  dashboardOptionsContainer: {
    boxSizing: 'border-box',
    width: '100%',
    margin: 0,
    gap: '15px',
  },
  dashboardOptionsTitle: {
    marginBottom: '10px',
  },
  // dashboardOptionButton: {
  //   'boxSizing': 'border-box',
  //   'padding': '0 10px',
  //   // 'marginBottom': '12px',
  //   'cursor': 'pointer',
  //   'backgroundColor': `${css.colors.blueDark3}`,
  //   'borderRadius': '12px',
  //   'color': `${css.colors.white}`,
  //   'height': '95px',
  //   'width': '30%',
  //   'textTransform': 'uppercase',
  //   'display': 'flex',
  //   'justifyContent': 'center',
  //   'alignItems': 'center',
  //   '& p': {
  //     fontSize: '.7rem',
  //     fontWeight: 700,
  //     textAlign: 'center',
  //   },
  //
  //   [theme.breakpoints.up('sm')]: {
  //     '& p': {
  //       fontSize: '1rem',
  //     },
  //   },
  // },
  dashboardDisabledOptionButton: {
    'boxSizing': 'border-box',
    'padding': '0 10px',
    // 'marginBottom': '12px',
    'backgroundColor': `${css.colors.grey2}`,
    'color': `${css.colors.grey1}`,
    'cursor': 'not-allowed',
    'borderRadius': '12px',
    'height': '95px',
    'width': '30%',
    'textTransform': 'uppercase',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    '& p': {
      fontSize: '.7rem',
      fontWeight: 700,
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      '& p': {
        fontSize: '1rem',
      },
    },
  },
  titles: {
    fontWeight: 700,
    fontSize: '1.4rem',
  },
  imovel: {
    boxSizing: 'border-box',
    gridColumn: '1 / 2',
    gridRow: '1 / 4',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
    backgroundColor: `${css.colors.blueDark3}`,
    color: `${css.colors.white}`,
    display: 'flex',
    flexDirection: 'column',
  },
  alugueisRecebidos: {
    boxSizing: 'border-box',
    gridColumn: '2 / 3',
    gridRow: '1 / 5',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
  },
  statusDoContrato: {
    boxSizing: 'border-box',
    gridColumn: '3 / 4',
    gridRow: '1 / 5',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
  },
  propostas: {
    boxSizing: 'border-box',
    gridColumn: '1 / 2',
    gridRow: '4 / 7',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
    backgroundColor: `${css.colors.blueDark2}`,
    color: `${css.colors.white}`,
    display: 'flex',
    flexDirection: 'column',
  },
  contrato: {
    position: 'relative',
    boxSizing: 'border-box',
    gridColumn: '1 / 2',
    gridRow: '7 / 10',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
    backgroundColor: `${css.colors.blueDark1}`,
    color: `${css.colors.white}`,
    display: 'flex',
    flexDirection: 'column',
  },
  survey: {
    position: 'relative',
    boxSizing: 'border-box',
    gridColumn: '1 / 2',
    gridRow: '10 / 13',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
    backgroundColor: `${css.colors.blue2}`,
    color: `${css.colors.white}`,
    display: 'flex',
    flexDirection: 'column',
  },
  termination: {
    position: 'relative',
    boxSizing: 'border-box',
    gridColumn: '1 / 2',
    gridRow: '13 / 16',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
    backgroundColor: `${css.colors.blue1}`,
    color: `${css.colors.white}`,
    display: 'flex',
    flexDirection: 'column',
  },
  receitas: {
    boxSizing: 'border-box',
    gridColumn: '2 / 3',
    gridRow: '5 / 9',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  performance: {
    boxSizing: 'border-box',
    gridColumn: '3 / 4',
    gridRow: '5 / 9',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
  },
  notifications: {
    boxSizing: 'border-box',
    gridColumn: '2 / 4',
    gridRow: '9 / 12',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
  },
  chat: {
    boxSizing: 'border-box',
    gridColumn: '2 / 4',
    gridRow: '12 / 16',
    width: '100%',
    height: '100%',
    padding: '10px 20px 10px',
    borderRadius: '12px',
  },
  contractMissingInfoItem: {
    width: '25%',
    textDecoration: 'underline',
  },
  dashboardBlock: {
    padding: 20,
    marginBottom: 20,
    background: css.colors.white,
    boxShadow: css.effects.dashboardBlockShadow,
    borderRadius: css.sizes.dashboardBlockRadius,
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
  dashboardBlockTitle: {
    fontWeight: 700,
    fontSize: '1.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: `${css.colors.blueDark2}`,
  },
  dashboardButton: {
    fontWeight: 600,
    width: '100%',
    minWidth: 120,
    textAlign: 'center',
    color: `${css.colors.blueDark2}`,
    border: `2px solid ${css.colors.blueDark2}`,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      minHeight: 80,
    },
  },
  dashboardDoubleColItem: {
    height: 280,
    display: 'flex',
    padding: '10px 30px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  proposalCardContainer: {
    display: 'block',
    /*
    flexDirection: 'column',
    alignItems: 'center',
    background: 'orangered',
    */
  },
  proposalCardContent: {
    border: `2px solid ${css.colors.blueDark2}`,
    padding: 12,
    margin: '10px 0px',
    borderRadius: 8,
    flexGrow: 0,
    width: '100%',
    maxWidth: '100%',
    flexBasis: '100%',
    // background: 'green',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  proposalCardAddress: {
    fontSize: '1.1rem',
  },
  proposalCardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  proposalCardUsers: {
    listStyle: 'none',
    padding: 0,
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between !important',
  },
  divAlignCenter: {
    margin: '30px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typographySpacing: {
    margin: '10px auto',
  },
  fakedoorBtn: {
    width: 120,
    margin: '10px auto',
  },
}))

export default useStyles
