import {usePropertyServices} from '@root/application/service/repository/property/PropertyRepository.js'
import addressDisplay, {ADDRESS_VERBOSITY_FULL} from '@root/lib/addressDisplay'
import {whatsAppLinkGenerator} from '@root/lib/simplePhoneFormatter'
import {
  batchMention,
  BOT_ENDPOINT_OTHERS,
  DISCORD_ID_LEANDRO,
  DISCORD_ID_MARCEL,
  discordNotify,
  isDebug,
  setDebug,
} from 'discord-notify'

/**
 * @param {Property} property
 */
const usePropertyServicesService = (property) => {
  const servicesFetch = usePropertyServices(property.uid)
  const surveyService = servicesFetch.response.find((item) => item.id === 'survey')
  const photoService = servicesFetch.response.find((item) => item.id === 'photo')
  const hasTrousseau = property?.more_about?.trousseau === 'yes'

  return {
    hasTrousseau,
    hasSurvey: !!surveyService,
    hasPhoto: !!photoService,

    survey: surveyService,
    photo: photoService,
    refresh: servicesFetch.refresh,

    sendBudget: async (user, service) => {
      setDebug(true)
      await discordNotify(
        [
          'Solicitacao de orcamento',
          `Serviço: ${service.name}`,
          `[${user.uid}] ${user.name}`,
          `Valor aproximado: ${service.payload.amount_formatted}`,
          'Dados do imovel',
          `ID: ${property.uid} ${property.alias}`,
          `Endereço: ${addressDisplay(property.address, ADDRESS_VERBOSITY_FULL)}`,
          `Area: ${property.specs.footage}m2`,
          `Mobiliado: ${property.more_about.furnished}`,
          `Tem enxoval: ${property.more_about.trousseau}`,
          `Falar com PP: ${whatsAppLinkGenerator(user.phone)}`,
          batchMention([DISCORD_ID_LEANDRO, DISCORD_ID_MARCEL]),
        ],
        BOT_ENDPOINT_OTHERS,
      )
    },
  }
}

export default usePropertyServicesService
