import Grid from '@material-ui/core/Grid'
import FormItem from '@root/components/FormHelper/FormItem'
import {validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const GuaranteeTypeCaucaoImoveis = () => {
  const {control, errors} = useFormContext()
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormItem
            control={control}
            errors={errors}
            name="registration_number"
            label="Número da matrícula"
            rules={{
              validate: validateRequired,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <FormItem
            control={control}
            errors={errors}
            multiple={false}
            type="file"
            label="Documento"
            name="file"
            rules={{
              validate: validateRequired,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default GuaranteeTypeCaucaoImoveis
