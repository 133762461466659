import React from 'react'

const IconTrashCan = (props) => {
  return (
    <svg
      id="icons_Q2"
      data-name="icons Q2"
      xmlns="http://www.w3.org/2000/svg"
      width="17.365"
      height="19.192"
      viewBox="0 0 17.365 19.192"
      {...props}>
      <path
        id="Path_132"
        data-name="Path 132"
        d="M22.364,5.65a1.051,1.051,0,0,1-.274.731.777.777,0,0,1-.64.274H15.967a.914.914,0,0,1-.914-.914V4.828H12.311v.914a.914.914,0,0,1-.914.914H5.959A.96.96,0,0,1,5,5.833,1.051,1.051,0,0,1,5.274,5.1a.777.777,0,0,1,.64-.274h4.57V3.914A.914.914,0,0,1,11.4,3h4.57a.914.914,0,0,1,.914.914v.914H21.4a.96.96,0,0,1,.96.823Z"
        transform="translate(-4.999 -3)"
        fill="#fff"
      />
      <path
        id="Path_133"
        data-name="Path 133"
        d="M20.893,15.823,19.8,26.881H12.119l-1.1-11.059A.914.914,0,0,0,10.108,15h0a.914.914,0,0,0-.914,1.005l1.188,11.881a.914.914,0,0,0,.914.823h9.322a.914.914,0,0,0,.914-.823l1.188-11.881A.914.914,0,0,0,21.807,15h0A.914.914,0,0,0,20.893,15.823Z"
        transform="translate(-7.275 -9.516)"
        fill="#fff"
      />
    </svg>
  )
}

IconTrashCan.defaultProps = {}

export default IconTrashCan
