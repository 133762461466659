/*
 import getContract from '@root/components/App/Contract/getContract'
 const link = getContract('contract', contract)
 */

import {CONTRACT_TYPE_MOBEN, CONTRACT_TYPE_OWNER} from '@root/utils/constants'

const contractInfo = (property) => {
  let contractObj
  let contractType
  const contractSigned = property?.contract?.contract_signed
  const contractFile = property?.contract?.contract_file
  const contractDoc = property?.contract_document

  if (contractSigned) {
    contractObj = contractSigned
    contractType = CONTRACT_TYPE_MOBEN
  } else if (contractFile) {
    contractObj = contractFile
    contractType = CONTRACT_TYPE_MOBEN
  } else if (contractDoc) {
    contractObj = contractDoc
    contractType = CONTRACT_TYPE_OWNER
  }
  /*if (isDevEnv()) {
    console.log('contract selected', contractObj)
  }*/
  return {
    empty: !contractObj,
    type: contractType,
    contract: contractObj,
  }
}

export const getContractType = (property) => contractInfo(property).type

export const getContractObject = (property) => contractInfo(property).contract

const getContractDownloadURL = (property) => {
  const info = contractInfo(property)
  let downloadUrl

  if (info.empty) {
    return ''
  }

  // por causa de alguns problemas no passado alguns contratos podem ser um array, ja esta resolvido, mas ainda falta corrigir esses casos
  if (Array.isArray(info.contract.download_url) && info.contract.download_url.length > 0) {
    downloadUrl = info.contract.download_url[0]
  } else {
    downloadUrl = info.contract.download_url
  }

  return downloadUrl
}

export default getContractDownloadURL
