import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {Navbar} from '@root/components/Navbar/Navbar'
import PropTypes from 'prop-types'
import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    // 'maxWidth': '430px !important',
    'height': '100%',
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    '& > a': {
      'display': 'block',
      'width': '164px',
      'alignSelf': 'center',
      'marginBottom': '15px',
      '& > img': {
        width: '100%',
      },
    },
  },
  innerTitle: {
    // margin: '10px 0 20px 0',
    margin: '6px 0 0px 0',
    textAlign: 'center',
  },
  outerTitleLine1: {
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 20,
  },
  outerNode: {
    marginBottom: 20,
    padding: '0 10px',
  },
}))

const navStyles = {
  position: 'absolute',
  top: -1,
  left: -1,
  right: -1,
  width: 'calc(100% + 2px)',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  border: '1px solid #E1E2E3',
}

const PageMini = ({
  children,
  onBackClick,
  outerTitleLine1,
  outerNode,
  innerTitle,
  navtitle,
  displayBackButton,
  innerTitleStyle,
}) => {
  const classes = useStyles()
  const navbar = useSelector((s) => s.application.navbar)
  const showBackButton = displayBackButton && !!onBackClick
  const hasNavTitle = navtitle !== undefined || navbar?.title !== ''
  const finalNavTitle = navtitle ?? navbar?.title
  const boxPadding = `${hasNavTitle ? '43px 15px 15px' : '15px'}`
  const innerTitleList = innerTitle && typeof innerTitle === 'string' ? [innerTitle] : innerTitle

  return (
    <div className={classes.root}>
      <Link to="/">
        <img src="/images/logo/Moben-Logo-header.png" />
      </Link>

      {outerTitleLine1 && (
        <Typography variant="body1" className={classes.outerTitleLine1}>
          {outerTitleLine1}
        </Typography>
      )}

      <div className={classes.outerNode}>{outerNode}</div>

      <Box p={boxPadding}>
        {hasNavTitle && (
          <Navbar onBackClick={onBackClick} displayBackButton={showBackButton} styles={navStyles}>
            {finalNavTitle}
          </Navbar>
        )}

        {innerTitleList && (
          <div styles={innerTitleStyle}>
            {innerTitleList.map((innerTitle, idx) => (
              <Typography key={idx} variant="body2" className={classes.innerTitle}>
                {innerTitle}
              </Typography>
            ))}
            <br />
          </div>
        )}
        {children}
      </Box>
    </div>
  )
}

PageMini.defaultProps = {
  displayBackButton: true,
  outerNode: null,
  innerTitle: undefined,
  navtitle: undefined,
  innerTitleStyle: {},
}

PageMini.propTypes = {
  onBackClick: PropTypes.func,
  displayBackButton: PropTypes.bool,
  outerNode: PropTypes.node,
  outerTitleLine1: PropTypes.string,
  innerTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  navtitle: PropTypes.string,
  innerTitleStyle: PropTypes.any,
}

export default PageMini
