/** @module inviteLinkCreator */
import {
  INVITE_HAS_ANALYSIS,
  INVITE_NO_ANALYSIS,
  INVITE_TYPE_FIADOR,
  INVITE_TYPE_TENANT,
} from '@root/components/InviteRegister/Invite'
import {BASE_URL} from '@root/utils/constants'
import routes from '@root/utils/routes'

/**
 * @param {string} type inquilino ou fiador
 * @param {string} propertyId
 * @param {string} [proposalId]
 * @param {boolean} [withCreditAnalysis]
 * @return {string}
 */
export const generateInvite = (type, propertyId, proposalId = '', withCreditAnalysis = true) => {
  const analysis = withCreditAnalysis ? INVITE_HAS_ANALYSIS : INVITE_NO_ANALYSIS
  const inviteParts = [routes.REGISTER_INVITE_ENTRY, type, propertyId, analysis]

  if (proposalId && typeof proposalId === 'string') {
    inviteParts.push(proposalId)
  }

  return BASE_URL + inviteParts.join('/')
}

/**
 * @param {string} propertyId
 * @param {string} [proposalId]
 * @param {boolean} [withCreditAnalysis]
 * @return {string}
 */
export const getInviteTenant = (propertyId, proposalId, withCreditAnalysis) =>
  generateInvite(INVITE_TYPE_TENANT, propertyId, proposalId, withCreditAnalysis)

/**
 * @param {string} propertyId
 * @param {string} [proposalId]
 * @param {boolean} [withCreditAnalysis]
 * @return {string}
 */
export const getInviteFiador = (propertyId, proposalId, withCreditAnalysis) =>
  generateInvite(INVITE_TYPE_FIADOR, propertyId, proposalId, withCreditAnalysis)
