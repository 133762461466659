import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import boletoIcon from '@root/assets/boleto_icon.svg'
import BackLink from '@root/components/BackLink'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import CustomDialog from '@root/components/CustomDialog/CustomDialog'
import FormItem from '@root/components/FormHelper/FormItem'
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import {currencyFormatProps} from '@root/components/NumberMask'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import {convertDateToBr} from '@root/lib/dateParser'
import {discordNotification} from '@root/lib/discordNotification'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {validateRequired, validateTextLen} from '@root/lib/simpleValidators'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'
import contractMapper from 'status-mapper/mapper/contract-mapper'
import routes from '@root/utils/routes'

import BoletosInfo, {BOLETOS_INFO_OPTIONS} from './BoletosInfo'

const MAX_DESCRIPTION_LENGTH = 255

const useStyles = makeStyles({
  boletoStatus: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'flexDirection': 'column',
    'height': 'calc(100vh - 65px)',
    'width': '100%',
    'maxWidth': '500px',
    'margin': '56px auto 0',
    'padding': '0 15px',
    '& header': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'margin': '15px 0',
      '& h1': {
        marginLeft: '10px',
        fontSize: '1.5rem',
        textAlign: 'center',
      },
    },
  },
  alterarBackLinkWrapper: {
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'marginTop': 'auto',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      cursor: 'pointer',
    },
    '& span': {
      fontSize: '1rem',
      color: '#3f51b5',
      marginRight: '2px',
    },
  },
  backLinkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },

  paragraphSpace: {
    marginBottom: 22,
  },
  dialog: {
    'padding': '20px',
    '& h3': {
      textAlign: 'center',
    },
    '& > div': {
      'display': 'flex',
      'alignItems': 'center',
      '& > p': {
        marginRight: '5px',
      },
      '& > span': {
        fontWeight: 600,
      },
      '&:last-child': {
        justifyContent: 'center',
      },
    },
  },
})

export const filterStatus = (list, status, reverse = true) => {
  if (!list) {
    return []
  }

  let tempList = reverse ? [...list].reverse() : list
  return tempList.filter((payment) => payment.status === status)
}

const BoletosStatus = () => {
  const classes = useStyles()
  const {getParam, hasParam} = useQuery()
  const {handleSubmit, control, errors, setValue} = useForm()
  const [updateAmountVisibility, setUpdateAmountVisibility] = useState(false)
  const [charCount, setCharCount] = useState(0)
  const [dialogData, setDialogData] = useState({
    boleto_amount: '',
    due_date: '',
  })
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const history = useHistory()

  const property = useSelector((s) => s.property.data)
  const loggedUser = useSelector((s) => s.user.user)

  const contractStatus = property?.contract?.status
  const payments = property?.contract?.payments
  const paids = filterStatus(payments, 'paid')
  const waitings = filterStatus(payments, 'waiting_payment')
  const schedules = filterStatus(payments, 'waiting_schedule', false)
  const curSchedule = schedules[0] ?? {}

  const actions = actionDispatcher({
    setHeaderMainHeader,
    setFooterNotVisible,
    setCurrentProperty,
    setGlobalLoaderAction,
  })

  actions.setHeaderMainHeader()
  actions.setFooterNotVisible()

  const limitTextLen = (ev) => {
    const text = ev.target.value
    let textLen = text.length
    if (textLen > MAX_DESCRIPTION_LENGTH) {
      setValue('description', text.substring(0, MAX_DESCRIPTION_LENGTH))
      textLen = MAX_DESCRIPTION_LENGTH
    }
    setCharCount(textLen)
  }

  const confirmUpdate = () => {
    handleSubmit(async ({new_amount, description}) => {
      // actions.setGlobalLoaderAction(true)
      // await discordNotifyBoletoUpdate(
      //   property.uid,
      //   loggedUser.name,
      //   loggedUser.uid,
      //   curSchedule.amount,
      //   new_amount,
      //   convertDateToBr(curSchedule.due_date),
      //   description,
      // )
      // actions.setGlobalLoaderAction(false)

      propertyModel.fetchChangePayment(property.uid, loggedUser.uid, {
        id: curSchedule.id,
        amount: formatMoneyBrToFloat(new_amount),
        description,
      })

      setDialogData({
        boleto_amount: new_amount,
        due_date: convertDateToBr(curSchedule.due_date),
      })
      setUpdateAmountVisibility(false)
      setIsDialogOpen(true)
    })()
  }

  const onDialogClose = () => {
    setIsDialogOpen(false)
    history.push(routes.DASHBOARD_ROOT)
  }

  const handleVoltarOnClick = () => {
    setUpdateAmountVisibility(false)
  }

  const handleAlterarProximoBoleto = () => {
    setUpdateAmountVisibility(true)
  }

  useEffect(() => {
    const load = async () => {
      //TODO fazer algo parecido com o contrato, uma funcao que carrega e valida o imovel
      if (hasParam('uid')) {
        const uid = getParam('uid')
        const propertyDb = await propertyModel.getById(uid)
        if (propertyDb.exists) {
          actions.setCurrentProperty(propertyDb.data())
        }
      }
    }
    load()
  }, [])

  if (!contractMapper.assertStatus.isInProgress(contractStatus)) {
    return (
      <Wrapper>
        <div>Este imóvel ainda não tem um contrato em andamento.</div>
      </Wrapper>
    )
  }

  if (updateAmountVisibility) {
    return (
      <>
        <Wrapper>
          <Typography className={classes.paragraphSpace} variant="h5" component="p">
            Alteração do valor do próximo boleto
          </Typography>
          <Typography className={classes.paragraphSpace} component="p">
            Existem muitos motivos para alterar o valor do boleto como por exemplo acrescentar valores que devem ser
            cobrados ou então descontar um valor já pago pelo inquilino.
          </Typography>
          <Typography className={classes.paragraphSpace} component="p" style={{fontWeight: 'bold'}}>
            Valor atual do boleto: <NumberFormatCustom value={curSchedule.amount} displayType="text" /> referente a{' '}
            {convertDateToBr(curSchedule.due_date)}
          </Typography>
          <FormItem
            control={control}
            errors={errors}
            type="textarea"
            label="Descreva abaixo o motivo da alteração"
            name="description"
            rules={{validate: (text) => validateTextLen(text, 10, MAX_DESCRIPTION_LENGTH)}}
            onChange={limitTextLen}
          />
          {charCount} de 255 caracteres
          <FormItem
            control={control}
            errors={errors}
            type="mask"
            label="Valor do próximo boleto"
            name="new_amount"
            rules={{validate: validateRequired}}
            maskInputProps={currencyFormatProps}
          />
          <ButtonTransparentBg buttonStyle={{marginTop: 22}} onClick={confirmUpdate}>
            CONFIRMAR ALTERAÇÃO
          </ButtonTransparentBg>
          <div className={classes.alterarBackLinkWrapper}>
            <Typography variant="body1" color="primary" onClick={handleVoltarOnClick}>
              <span>&#8592;</span>
              Voltar
            </Typography>
          </div>
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <Wrapper>
        {schedules.length > 0 && (
          <ButtonTransparentBg onClick={handleAlterarProximoBoleto}>
            Alterar valor do próximo boleto
          </ButtonTransparentBg>
        )}

        <BoletosInfo type="automatic" options={BOLETOS_INFO_OPTIONS} />

        <div className={classes.backLinkWrapper}>
          {property.contract.status === 'in_progress' ? (
            <Link to={routes.DASHfBOARD_ROOT}>
              <Typography
                variant="body1"
                color="primary"
                style={{display: 'flex', alignItems: 'center', fontWeight: 700}}>
                <span style={{marginRight: '2px', fontWeight: 700}}>&#8592;</span>
                Voltar
              </Typography>
            </Link>
          ) : (
            <BackLink />
          )}
        </div>
      </Wrapper>

      <CustomDialog onClose={onDialogClose} open={isDialogOpen}>
        <div className={classes.dialog}>
          <h3>BOLETO ALTERADO COM SUCESSO</h3>

          <div>
            <p>Valor - </p>
            <span>{dialogData.boleto_amount}</span>
          </div>
          <div>
            <p>Vencimento - </p>
            <span>{dialogData.due_date}</span>
          </div>

          <div>
            <ButtonTransparentBg onClick={onDialogClose}>PAINEL DE CONTROLE</ButtonTransparentBg>
          </div>
        </div>
      </CustomDialog>
    </>
  )
}

export const Wrapper = ({children}) => {
  const classes = useStyles()
  return (
    <div className={classes.boletoStatus}>
      <header>
        <img src={boletoIcon} alt="Icon" />
        <h1>Boletos</h1>
      </header>
      {children}
    </div>
  )
}

export default BoletosStatus
