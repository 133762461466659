import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ErrorModal from '@root/components/App/Login/ErrorModal'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import PageMini from '@root/components/PageMini'
import {auth} from '@root/firebase/firebase.utils'
import {useQuery} from '@root/hooks/urlHook'
import useSignin from '@root/hooks/useSignin'
import actionDispatcher from '@root/lib/actionDispatcher'
import {getHumanSigninErrorMessage} from '@root/lib/signIn'
import {validateEmail} from '@root/lib/simpleValidators'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {BASE_URL} from '@root/utils/constants'
import routes from '@root/utils/routes'

// sendPasswordResetEmail - https://firebase.google.com/docs/reference/js/auth#sendpasswordresetemail

const RecoverPassword = () => {
  const history = useHistory()
  const query = useQuery()
  const {control, errors, watch, handleSubmit, setValue} = useForm()
  const {handleFocusBlurForLogger, signinError, setSigninError} = useSignin()

  const [isResetSent, setReset] = useState(false)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const handleRecoverPass = async (form) => {
    const email = form.email
    const redirectUrl = BASE_URL + routes.SIGNUP_SIGNIN_EMAIL

    actions.setGlobalLoaderAction(true)
    try {
      await auth.sendPasswordResetEmail(email, {url: redirectUrl})
      setReset(true)
    } catch (ex) {
      setSigninError(ErrorModal.openPayload(getHumanSigninErrorMessage(ex, 'Erro ao iniciar a recuperação de senha')))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }
  useEffect(() => {
    if (query.hasParam('email')) {
      setValue('email', query.getParam('email'))
    }
  }, [])

  return (
    <PageMini
      navtitle="Recuperação de senha"
      displayBackButton={true}
      onBackClick={history.goBack}
      innerTitle="Enviaremos um link para redefinição de senha no email abaixo">
      <Grid container justify="center">
        <Grid container item spacing={4} direction="row" justify="center" xs={12} md={6}>
          {!isResetSent && (
            <>
              <Grid item xs={12}>
                <FormItem
                  {...handleFocusBlurForLogger(watch, 'E-Mail', 'email')}
                  control={control}
                  errors={errors}
                  fitContainer={true}
                  label="E-Mail"
                  name="email"
                  rules={{
                    validate: validateEmail,
                  }}
                />
              </Grid>

              <Grid item>
                <Button p="1rem" fz="15px" fw={600} onClick={handleSubmit(handleRecoverPass)} id="bt-recover-pass">
                  RECUPERAR MINHA SENHA
                </Button>
              </Grid>
            </>
          )}

          {isResetSent && (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary">
                  A solicitação para recuperação de senha foi enviada.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <ErrorModal signinError={signinError} setSigninError={setSigninError} />
    </PageMini>
  )
}

export default RecoverPassword
