import {makeStyles} from '@material-ui/core/styles'
import css from '@root/components/Layout/cssVariables'

const useStyles = makeStyles((theme) => ({
  dashboardTenant: {
    margin: '100px auto',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: `${css.colors.white}`,
    maxWidth: '1280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: (props) => (props ? '0 auto' : '100px auto'),
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      maxWidth: 750,
    },
  },
  tenantDashboard: {
    margin: '100px auto',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: `${css.colors.white}`,
    maxWidth: '1280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: (props) => (props ? '0 auto' : '100px auto'),
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      maxWidth: 750,
    },
  },
  tenantDashboardBlock: {
    padding: 20,
    marginBottom: 20,
    minHeight: 180,
    background: css.colors.white,
    boxShadow: css.effects.dashboardBlockShadow,
    borderRadius: css.sizes.dashboardBlockRadius,
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
    cursor: 'pointer',
  },
  tenantDashboardIcon: {
    width: 70,
    height: 70,
    marginBottom: 20,
    display: 'block',
  },
  tenantDashboardProfileIcon: {
    width: 70,
    height: 70,
    marginBottom: 20,
    display: 'block',
    border: `2px solid ${css.colors.blueDark3}`,
    borderRadius: 100,
  },
  tenantDashboardGreyText: {
    color: css.colors.grey3,
  },
  dashboardTenantButtons: {
    width: '100%',
    marginBottom: 35,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      margin: '80px auto 20px',
    },
  },
  receipts: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'flexDirection': 'column',
    'height': 'calc(100vh - 65px)',
    'width': '100%',
    'maxWidth': '500px',
    'margin': '56px auto 0',
    'padding': '0 15px',
    '& header': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'margin': '15px 0',
      '& h1': {
        marginLeft: '10px',
        fontSize: '1.5rem',
        textAlign: 'center',
      },
    },
  },
  uploadContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBtnWrapper: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'margin': '15px 0 20px',
    'width': '100%',
    'height': '36px',
    'background': 'transparent',
    'border': '2px solid rgb(1, 1, 205)',
    'borderRadius': '4px',
    'cursor': 'pointer',
    'padding': '4px 5px',
    '& span': {
      color: 'rgb(1, 1, 205) !important',
      fontWeight: 600,
    },
  },
  documentNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  documentName: {
    maxWidth: '270px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default useStyles
