import {Typography} from '@material-ui/core'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {isStatusAccepted, isStatusPending, isStatusRefused} from '@root/lib/contract/observationsHelper'
import {buildStepUrl, getInitialStep} from '@root/lib/contract/stepWalker'
import {convertDateToBr} from '@root/lib/dateParser'
import {translateContractStep} from '@root/lib/intl/translations'
import {filterByObservation} from '@root/lib/notification'
import React from 'react'
import {useHistory} from 'react-router-dom'

export const ObservationEntry = ({observation, onEdit, onRefuse, allowEdit}) => {
  const history = useHistory()

  const isAccepted = isStatusAccepted(observation.status)
  const isRefused = isStatusRefused(observation.status)
  const isPending = isStatusPending(observation.status)
  const section = observation.section ?? 'Outros'
  const sectionTranslated = translateContractStep(section)

  const config = {
    class: 'changePending',
    pretext: `A sugestão de alteração em ${sectionTranslated} ainda não foi analisada`,
  }
  if (isRefused) {
    config.class = 'changeNotAccepted'
    config.pretext = `A sugestão de alteração em ${sectionTranslated} foi recusada`
  }
  if (isAccepted) {
    config.class = 'changeAccepted'
    config.pretext = `A sugestão de alteração em ${sectionTranslated} foi aceita`
  }

  const handleRefuse = () => {
    if (onRefuse) {
      onRefuse(observation, observation.id)
    }
  }

  const handleEdit = () => {
    if (onEdit) {
      onEdit(observation, observation.id)
    } else {
      const backToEnd = true
      let path
      if (observation.route) {
        path = buildStepUrl(observation.route, backToEnd)
      } else {
        path = getInitialStep(backToEnd).path
      }
      history.push(path)
    }
  }

  return (
    <div>
      <Typography variant="body1" className="notificationDate">
        {convertDateToBr(observation.created_at)}
      </Typography>
      <Box>
        <div className="changeRequest">
          <Typography variant="body1" className="bold">
            Solicitação de alteração em {sectionTranslated}
          </Typography>
          <Typography variant="body1">{observation.text}</Typography>

          <Divider />

          <div className="buttonsContainer">
            {isPending && allowEdit ? (
              <>
                <Button bgType="red" onClick={handleRefuse}>
                  Recusar solicitação
                </Button>
                <Button onClick={handleEdit}>Editar {sectionTranslated}</Button>
              </>
            ) : (
              <Typography variant="body2" className={config.class}>
                {config.pretext}
              </Typography>
            )}
          </div>
        </div>
      </Box>
    </div>
  )
}

export const NotificationEntry = ({notification}) => {
  return (
    <div>
      <Typography variant="body1" className="notificationDate">
        {convertDateToBr(notification.created_at)}
      </Typography>
      <Box smallPadding>
        <div className="notification">
          <SendOutlinedIcon fontSize="small" />
          <Typography variant="body1" className="bold">
            {notification.text}
          </Typography>
        </div>
      </Box>
    </div>
  )
}

const ObservationHistory = ({observations, onEdit, onRefuse}) => {
  if (!observations || observations == 0) {
    return null
  }

  return observations
    .filter(filterByObservation)
    .map((obs, idx) => <ObservationEntry key={idx} observation={obs} onEdit={onEdit} onRefuse={onRefuse} />)
}

export default ObservationHistory
