import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {SurveyHandler} from '@root/components/SurveyHandler/SurveyHandler'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {MOBEN_WHATSAPP} from '@root/utils/constants'

const useStyles = makeStyles({
  vistoria: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'flexDirection': 'column',
    'width': '100%',
    '& strong > a': {
      color: 'inherit !important',
      textDecoration: 'underline !important',
    },
  },
})

const SurveyPage = ({onFinishUpload}) => {
  const classes = useStyles()
  const {control, errors, reset} = useForm()
  const dispatch = useDispatch()
  const propertyAlias = useSelector((s) => s.property.data.alias)

  useEffect(() => {
    dispatch(setNavbarTitleAction(`${propertyAlias} - Vistoria`))
  }, [])

  return (
    <div className={classes.vistoria}>
      <Typography variant="body2">
        Vistorias de entrada e saídas são fundamentais para você ter a segurança de que receberá o imóvel no mesmo
        estado que o entregou.
      </Typography>

      <Typography variant="body2">
        Caso você já tenha feito a vistoria pode enviá-la clicando no botão abaixo e então será anexada para assinatura
        junto com o contrato.
      </Typography>

      <Typography variant="body2">
        Se você precisa de ajuda com sua vistoria entre em contato sem compromisso via{' '}
        <strong>
          <WhatsAppLinkWrapper phone={MOBEN_WHATSAPP} message="Olá, preciso de ajuda com vistoria.">
            WhatsApp
          </WhatsAppLinkWrapper>
        </strong>
        .
      </Typography>

      <SurveyHandler control={control} errors={errors} reset={reset} />
    </div>
  )
}

export default SurveyPage
