import {Button} from '@root/components/Button/Button'
import CreditCardForm from '@root/components/Forms/CreditCardForm'
import Page from '@root/components/Page'
import analyticsLogger from '@root/firebase/analytics.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import userModel from '@root/model/userModel'
import React from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setUserAddressAction} from '@root/redux/user/user.actions'
import routes from '@root/utils/routes'

/**
 * @component
 */
const CreditCard = () => {
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, reset} = formHook

  const user = useSelector((s) => s.user.user)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setUserAddressAction,
  })

  const saveCard = async (form) => {
    actions.setGlobalLoaderAction(true)
    try {
      const {card} = form
      await userModel.fetchAddCard(user.uid, card)
      analyticsLogger.logEvent('add_cartao_sucesso')

      history.replace(routes.MYDATA_PROFILE_SECTION_CARDS)
    } catch (e) {
      alert(errorHandler(e))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  return (
    <Page>
      <FormProvider {...formHook}>
        <CreditCardForm />
      </FormProvider>

      <Button m="0 auto" w="fit-content" onClick={handleSubmit(saveCard)}>
        Salvar
      </Button>
    </Page>
  )
}

export default CreditCard
