import {Box} from '@root/components/Layout/Container/Box/Box'
import NoContentAvailable from '@root/pages/Error/NoContentAvailable'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const Page = ({children, loading, contained, showContent}) => {
  const dispatch = useDispatch()
  const border = !contained ? '0' : undefined

  useEffect(() => {
    dispatch(setGlobalLoaderAction(loading))
  }, [loading])
  return (
    <Box type="simple" bgType="white" m="0 auto" b={border}>
      {!showContent && <NoContentAvailable />}
      {showContent && children}
    </Box>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  contained: PropTypes.bool,
  showContent: PropTypes.bool,
}

Page.defaultProps = {
  loading: false,
  contained: false,
  showContent: true,
}

export default Page
