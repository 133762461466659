import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {TenantPropertyTypes} from '@root/redux/tenantProperty/tenantProperty.types'

const initialState = {
  data: {},
  list: [],
}
const reducers = {
  [TenantPropertyTypes.LOAD_TENANT_PROPERTY]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.data = payload.property
    })
  },
  [TenantPropertyTypes.LOAD_TENANT_PROPERTIES]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.list = payload.properties
    })
  },
}

export default reducerMatch(reducers, initialState)
