import Grid from '@material-ui/core/Grid'
import Text from '@root/components/Layout/Typography/Text'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'

const ListItem = ({label, value, size, onClick, className}) => {
  const valueComp = useMemo(() => {
    if (React.isValidElement(value)) {
      return value
    }

    return ListItem.defaultValueComponent(value)
  }, [value])
  return (
    <Grid container item direction="column" xs={size} onClick={onClick}>
      <Grid item>
        <Text color={ListItem.labelColor} size="11">
          {label}
        </Text>
      </Grid>
      <Grid item style={{overflowWrap: 'anywhere'}} className={className}>
        {valueComp}
      </Grid>
    </Grid>
  )
}
ListItem.labelColor = '#9698b4'
ListItem.defaultValueComponent = (value) => <Text size="12">{value}</Text>

ListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.number,
}
ListItem.defaultProps = {
  size: 6,
}

export default ListItem
