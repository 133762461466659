import {useQuery} from '@root/hooks/urlHook'
import React from 'react'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

const modeToRoutes = {
  /*
  mode=resetPassword&
  oobCode=LZtnkojeyQpCL53Wy81_G91H48rU0VDcno8ot8XE23gAAAGBYwon0g&
  apiKey=AIzaSyAWNS7WL6abMR2Z2yhyyLkYcVCIJhBEpdw&
  continueUrl=https%3A%2F%2Fdev-moben--mob-1260-email-signin-uool0z8o.web.app%2Fentrar%2Femail%2Fconfirmar-codigo%3Femail%3Dneuber.oliveira05%40gmail.com&
  lang=pt-BR*/
  resetPassword: routes.SIGNUP_RESET_PASS,
}
const FirebaseActionDispatch = () => {
  const history = useHistory()
  const {getParam, getQueryString} = useQuery()

  const mode = getParam('mode')

  if (modeToRoutes[mode]) {
    history.replace(modeToRoutes[mode] + `?${getQueryString()}`)
    return null
  }
  return null
}

export default FirebaseActionDispatch
