import React from 'react'
const IconCheck = () => {
  return (
    <svg
      id="icons_Q2"
      data-name="icons Q2"
      xmlns="http://www.w3.org/2000/svg"
      width="22.283"
      height="17.449"
      viewBox="0 0 22.283 17.449">
      {' '}
      <path
        id="Path_118"
        data-name="Path 118"
        d="M10.708,26.776,5.864,21.992a1.272,1.272,0,0,1-.121-1.635,1.151,1.151,0,0,1,1.817-.121l4,4L25.847,9.942a1.211,1.211,0,0,1,1.7,1.7L12.4,26.776a1.151,1.151,0,0,1-1.7,0Z"
        transform="translate(-5.501 -9.7)"
        fill="#fff"
      />
    </svg>
  )
}

export default IconCheck
