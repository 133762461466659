/** @module authChecker */
import analyticsLogger from '@root/firebase/analytics.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import {ADMIN_PATH_PREFIX} from '@root/lib/admin/adminPathHelper'
import {saveBackLink} from '@root/lib/backToLink'
import contractStepsMap from '@root/lib/contract/contractStepsMap'
import {isDevEnv} from '@root/lib/envDetector'
import {addBreadCrumb} from '@root/lib/errorHandler'
import {isInvitePath, isMatchPath} from '@root/lib/invitePath'
import storage from '@root/lib/storage'
import {setApplicationReadyAction, setRouteInfoAction} from '@root/redux/application/application.actions'
import {setUnescapableHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import store from '@root/redux/store'
import {
  setAuthFinishedAction,
  setUserFirstLoginAction,
  setUserFromFirebaseAction,
  setUserIdAction,
  setUserInitialAction,
  setUserLoggedAction,
  setUserNull,
} from '@root/redux/user/user.actions'
import {STORAGE_IS_TENANT} from '@root/utils/constants'
import routes from '@root/utils/routes'

const actions = actionDispatcher({
  setUserFromFirebaseAction,
  setUserNull,
  setGlobalLoaderAction,
  setAuthFinishedAction,
  setApplicationReadyAction,
  setUnescapableHeader,
  setUserLoggedAction,
  setUserIdAction,
  setUserFirstLoginAction,
  setUserInitialAction,
})

const allowedPathsWithoutProperty = [
  // routes.ROOT -> root precisa ser checkada sem exact, por isso nao entra aqui
  routes.INVITE_ROOT,
  routes.MYDATA_ROOT,
  routes.DASHBOARD_INIT,
  routes.DASHBOARD_PROPERTY_LIST,
  routes.DASHBOARD_TENANT_PROPERTY_LIST,
  routes.REGISTER_PROPERTY_NEW,
  routes.FAQ,
]

const protectedPaths = [
  // aqui o check e feito sem o exact entao dashboard/ da match em dashboard/qualquer/coisa
  routes.DASHBOARD_DOCUMENT_AREA,
  routes.ADMIN,
  routes.DASHBOARD_ROOT,
  routes.DASHBOARD_ROOT_TENANT,
  routes.REGISTER_ROOT,
  routes.INVITE_ROOT,
  routes.MYDATA_ROOT,
  routes.CONTRACT_STEPS_LIST,
  ...contractStepsMap.map((step) => step.path_orig),
]

const headerLessPaths = [
  routes.HOW_WORK,
  routes.PRICING,
  routes.TEAM,
  routes.TALK_TO_US,
  routes.ADMIN,
  routes.PRIVACY_POLICIES,
  routes.EXPERIMENTE,
  routes.REGISTER_SIGNUP,
  routes.REGISTER_LOGIN,
  routes.REGISTER_VERIFICATION_CODE,
  routes.REGISTER_INVITE_ENTRY,
  routes.REGISTER_MYDATA_INCOME,
  routes.REGISTER_MYDATA_FINANCIAL_OBLIGATIONS,
  routes.REGISTER_MYDATA_EXTRA_DATA,
  routes.REGISTER_TENANT_FIADOR,
  routes.CONTRACT_APPROVAL,
  routes.CONTRACT_APPROVAL_OBSERVATION,
  routes.CONTRACT_APPROVAL_SUMMARY,
  routes.CONTRACT_APPROVAL_DONE,
  routes.PAYMENT_VIEW,
  routes.INTERESTED_ANALYSIS_CONFIRMATION,
  routes.DEFAULT_CONFIRMATION,
  routes.PAYMENT_MARK_AS_PAID,
  '/calculadora/reajuste',
]

const adminPaths = [ADMIN_PATH_PREFIX]

let loaderCount = 0
let isFirstLoad = true
let isLoged = false
let isAdmin = false
let hasPropertyId = false
let hasTenantPropertyId = false
const isProtectedPath = (pathname, arrayPaths) => !!isMatchPath(pathname, arrayPaths, undefined, false)

export const historyHandler = (history, {pathname, search}) => {
  if (isDevEnv()) {
    console.log('route to: ', pathname)
  }
  isFirstLoad = loaderCount === 0
  loaderCount++

  const {user, property, tenantProperty} = store.getState()
  isLoged = user.isLogged
  isAdmin = user?.user?.user_role === 'admin'
  hasPropertyId = property.currentPropertyId !== undefined
  hasTenantPropertyId = tenantProperty.data !== undefined

  // collectUTMParams()

  window.scroll(0, 0)

  const hasMatchProtected = isProtectedPath(pathname, protectedPaths, false, false)
  const hasMatchAdmin = isProtectedPath(pathname, adminPaths, false, false)
  const isHeaderless = isMatchPath(pathname, headerLessPaths)
  const isRoot = isMatchPath(routes.ROOT, pathname, true, true)
  const isDashboardProperty = isMatchPath(pathname, routes.DASHBOARD_ROOT, true, true)
  const isAllowWithoutProperty = isMatchPath(pathname, allowedPathsWithoutProperty, false, false)
  store.dispatch(
    setRouteInfoAction({isRoot, isDashboardProperty, isHeaderless, hasMatchProtected, isAllowWithoutProperty}),
  )

  if (hasMatchAdmin) {
    if (!isLoged || !isAdmin) {
      history.push(routes.ROOT)
    }
  } else if (hasMatchProtected) {
    const isInvite = isInvitePath(pathname)
    if (!isLoged) {
      saveBackLink(pathname + window.location.search)
      if (isInvite) {
        storage.save(STORAGE_IS_TENANT, true)
      }
      history.replace(routes.SIGNUP_SIGNIN_OPTIONS)
    } else if (!isFirstLoad && !hasPropertyId && !hasTenantPropertyId && !isRoot && !isAllowWithoutProperty) {
      // TODO esta mesma condicao acima existe no AppContainer precisa otimizar isso, para nao ficar tao repetido
      // testando
      // http://localhost:3000/cadastro/inquilino/fiador/7Uad9TMIniYOkiHTl0b9/noanalysis/vy2vc6iji13nd7741dz18dqrcqfyhdps
      // http://localhost:3000/meus-dados/fiador/7Uad9TMIniYOkiHTl0b9/noanalysis/vy2vc6iji13nd7741dz18dqrcqfyhdps
      addBreadCrumb('back to property list from authChecker')
      saveBackLink(pathname + search)
      history.replace(routes.DASHBOARD_PROPERTY_LIST)
    }
  }
  analyticsLogger.setScreenView()
}
