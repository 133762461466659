import {objectToQueryString} from '@root/application/infra/api/fetcher'
import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class PropertyRepository extends BaseHttpRepository {
  async list() {
    const resp = await this._api.get('property/l')
    return resp
  }

  async paymentStatuses(propertyId) {
    const userId = this._retrieveUserId()
    if (!userId && !propertyId) {
      return this._skipRequest()
    }

    const resp = await this._api.get('getContractPaymentsStatuses', {propertyUID: propertyId, ownerUID: this._userId})
    // all thanks to Luiz Vital
    if (!resp.success) {
      return Promise.reject(resp)
    }

    return resp
  }

  async receipts(propertyId) {
    const resp = await this._api.get(`property/${propertyId}/receipts`)
    return resp
  }

  /**
   * @param {string} propertyId
   * @param {string} paymentId
   * @param {string} [ownerId] opcional caso esteja logado, por que ai pega do token
   * @param {document} [receipt] @see https://github.com/MobenDev/backend-functions/blob/118cd9caaad8809da0a85f9bf09a6d84f05594b3/functions/src/typings/common.ts#L28
   * @return {Promise<*>}
   */
  async markAsPaid(propertyId, paymentId, ownerId, receipt) {
    const resp = await this._api.post('payment/pay', {
      property_id: propertyId,
      payment_id: paymentId,
      user_id: ownerId,
      document: receipt,
    })

    return resp
  }

  /**
   * @private
   * @param {string} endpoint
   * @param {string} propertyId
   * @param {string} tenantId
   * @param {string} reminderId
   * @param {document[]} receipts
   * @return {Promise<*>}
   */
  async _sendReceipt(endpoint, propertyId, tenantId, reminderId, receipts) {
    const resp = await this._api.post(`property/receipt/${propertyId}/${endpoint}`, {
      tenant_id: tenantId,
      reminder_id: reminderId,
      documents: receipts,
    })

    return resp
  }

  /**
   * @param {string} propertyId
   * @param {string} tenantId
   * @param {string} reminderId
   * @param {document[]} receipts
   * @return {Promise<*>}
   */
  async sendReceiptOwnership(propertyId, tenantId, reminderId, receipts) {
    return this._sendReceipt('ownership', propertyId, tenantId, reminderId, receipts)
  }

  /**
   * @param {string} propertyId
   * @param {string} tenantId
   * @param {string} reminderId
   * @param {document[]} receipts
   * @return {Promise<*>}
   */
  async sendReceiptCondominium(propertyId, tenantId, reminderId, receipts) {
    return this._sendReceipt('condominium', propertyId, tenantId, reminderId, receipts)
  }

  async _baseCreateContract(propertyId, ownerId, mode = '') {
    const params = {
      mode,
    }

    const queryParams = objectToQueryString(params)
    const resp = await this._api.post('generateContractPDF?' + queryParams, {
      user_id: ownerId,
      property_id: propertyId,
    })

    return resp
  }

  async createContract(property) {
    return this._baseCreateContract(property.uid, property.ower_id, '')
  }

  async createContractRaw(property) {
    return this._baseCreateContract(property.uid, property.ower_id, 'raw')
  }

  async createContractHTML(property) {
    return this._baseCreateContract(property.uid, property.ower_id, 'html')
  }

  async createContractDoc(property) {
    return this._baseCreateContract(property.uid, property.ower_id, 'doc')
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} ownerId
   * @param {(full|keep_interested|stop_only)} resetMode
   * @return {Promise<*>}
   */
  async finishContract(propertyId, ownerId, resetMode) {
    const resp = await this._api.post('finishContract', {
      user_id: ownerId,
      property_id: propertyId,
      reset_mode: resetMode,
    })

    return resp
  }

  /**
   * @param {string} propertyId
   * @return {Promise<*>}
   */
  async alerts(propertyId) {
    const resp = await this._api.get(`property/${propertyId}/alerts`)
    return resp
  }

  /**
   * @param {string} propertyId
   * @return {Promise<*>}
   */
  async services(propertyId) {
    const resp = await this._api.get(`property/${propertyId}/services`)
    return resp
  }
}

export const propertyRepoInstance = new PropertyRepository()

export const usePropertyPaymentStatuses = hookRunner(propertyRepoInstance, 'paymentStatuses')
export const usePropertyList = hookRunner(propertyRepoInstance, 'list', [])
export const usePropertyAlerts = hookRunner(propertyRepoInstance, 'alerts', [])

/**
 * @see {@link PropertyRepository.services}
 * @type {HookRunnerResult}
 */
export const usePropertyServices = hookRunner(propertyRepoInstance, 'services', [])
