import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {alternateFactory} from '@root/components/App/Login/signupHelper'
import FormItem from '@root/components/FormHelper/FormItem'
import UseSignin from '@root/hooks/useSignin'
import {PROVIDER_CREDENTIALS, signIn} from '@root/lib/signIn'
import {validateEmail, validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

const EmailSignin = () => {
  const history = useHistory()
  const {control, errors, watch, getValues} = useFormContext()
  const {handleFocusBlurForLogger} = UseSignin()
  const email = getValues('email')

  const goToRecoverPass = () => {
    history.push(`/entrar/email/recuperar-senha?email=${email}`)
  }
  return (
    <Grid item xs={12} md={6}>
      <Grid item xs={12}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'E-Mail', 'email')}
          control={control}
          errors={errors}
          fitContainer={true}
          label="E-Mail"
          name="email"
          rules={{
            validate: validateEmail,
          }}
          defaultValue={email}
        />
      </Grid>
      <Grid item xs={12}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'Senha', 'password')}
          control={control}
          errors={errors}
          fitContainer={true}
          inputProps={{type: 'password'}}
          label="Senha"
          name="password"
          rules={{
            validate: validateRequired,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className="clickable" onClick={goToRecoverPass}>
          Caso não tenha uma senha, ou se esqueceu clique aqui
        </Typography>
      </Grid>

      <Grid item xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  )
}

EmailSignin.propTypes = {}
EmailSignin.defaultProps = {}

EmailSignin.formData = {}
EmailSignin.isSignin = true
EmailSignin.isInUse = async () => ({
  exist: false,
  message: '',
})
EmailSignin.handler = async () => {
  const form = EmailSignin.formData
  const extra = {
    email: form.email,
    password: form.password,
    create: false,
  }
  const authResult = await signIn(PROVIDER_CREDENTIALS, extra)
  return {
    auth: authResult,
    extra,
  }
}

EmailSignin.alternate = alternateFactory(EmailSignin.isRegister, routes.SIGNUP_REGISTER_EMAIL)

export default EmailSignin
