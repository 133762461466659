import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import CancelIcon from '@material-ui/icons/Cancel'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import PropertiesList from '@root/components/App/Admin/PropertiesList'
import UserBasicData from '@root/components/App/Admin/UserBasicData'
import {mapCreditStatusToColor} from '@root/components/App/CreditAnalysis/WithProposals/getBorderStyle'
import {tagsInfo} from '@root/components/App/CreditAnalysis/WithProposals/tagsInfo'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import Loader from '@root/components/Loader/Loader'
import {firestore, forceArrayUnionSingle} from '@root/firebase/firebase.utils'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import {bankCodeToName} from '@root/lib/banksList'
import {convertDateToBr} from '@root/lib/dateParser'
import {validateEmail} from '@root/lib/simpleValidators'
import {readableValue} from '@root/lib/simpleValuesDisplayMap'
import userModel from '@root/model/userModel'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import registrationTypeMapper from 'status-mapper/mapper/registration-type-mapper'
import {BASE_URL} from '@root/utils/constants'
import routes, {routeWithID, routeWithParams} from '@root/utils/routes'

import useStyles from './styles'

const CreditAnalysisInfo = ({statusNum, tagItem}) => (
  <div>
    <div>
      <span>
        <strong>{tagsInfo[tagItem]?.title}:</strong>
      </span>
      <span
        style={{
          background: mapCreditStatusToColor(statusNum),
          width: 20,
          height: 20,
          display: 'inline-block',
          textAlign: 'center',
        }}>
        {statusNum}
      </span>
    </div>
    <div style={{marginBottom: 8}}>{tagsInfo[tagItem]?.text[statusNum]}</div>
  </div>
)

const UserDetail = ({match}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const userId = match.params.userId
  const [user, setUser] = useState()
  const [userSnap, setUserSnap] = useState()

  const forwardNotificationForm = useForm()
  const bankAccount = userModel.getLoggedActiveBank(user)
  const isCompany = registrationTypeMapper.assertStatus.isCompany(user?.register_type)

  let creditAnalysis
  if (user) {
    const tmpAnalysis = user.credit_analysis[user.credit_analysis.length - 1]
    if (tmpAnalysis) {
      creditAnalysis = {
        ...tmpAnalysis.analysis,
        info: tmpAnalysis?.info ?? {},
        errors: tmpAnalysis?.errors ?? [],
        success: tmpAnalysis.success,
        created_at: tmpAnalysis.created_at,
      }
      if (!tmpAnalysis.score_serasa_fx) {
        creditAnalysis.score_serasa_fx = tmpAnalysis.nogord_result?.result?.value?.FX_SCORE ?? 'indisponivel'
      }
    }
  }

  const addForward = async (data) => {
    const {email} = data
    try {
      await firestore
        .collection(userModel.COLLECTION_NAME)
        .doc(user.uid)
        .update(
          'forward_notification',
          forceArrayUnionSingle({
            email,
            forward_list: ['*'],
          }),
        )
      forwardNotificationForm.reset({email: ''})
    } catch (ex) {
      alert(ex.message)
    }
  }

  const removeForward = (idx) => async () => {
    const newList = user.forward_notification.filter((entry, index) => idx !== index)
    try {
      await firestore.collection(userModel.COLLECTION_NAME).doc(user.uid).update('forward_notification', newList)
      setUser((pu) => ({...pu, forward_notification: newList}))
    } catch (ex) {
      alert(ex.message)
    }
  }

  const changeAdminRole = (newRole) => {
    setUser({...user, user_role: newRole})
    return firestore.collection(userModel.COLLECTION_NAME).doc(userId).update('user_role', newRole)
  }
  const grantAdminRole = async () => {
    const confirmation = window.confirm('Tem certeza?')
    if (confirmation) {
      await changeAdminRole('admin')
    }
  }
  const revokeAdminRole = async () => {
    await changeAdminRole('')
  }

  const execCreditAnalysis = () => {
    // userModel.fetchCreditAnalysis(userId, '', 'admin')
    alert('em breve :D')
  }

  const viewUserBankStatment = () => {
    window.open(BASE_URL + `${routes.DASHBOARD_BANK_STATMENT}?user_id=${user.uid}`, user.uid)
  }

  const viewUserPaymentsList = () => {
    window.open(BASE_URL + `${routes.DASHBOARD_PAYMENTS_LIST_MONTH}?user_id=${user.uid}`, user.uid)
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction(`Usuário: ${user?.name}`))
  }, [dispatch, user])

  useEffect(() => {
    const load = async () => {
      const user = await userModel.getById(userId)
      setUser(user.data())
      setUserSnap(user)
    }
    load()
  }, [])

  if (!user) {
    return <Loader loading={true} />
  }

  return (
    <div className={classes.userDetailRoot}>
      <div className={classes.userDetailTopContainer}>
        <Card className={isCompany ? classes.inquilinoCard : classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>Dados do cliente</h3>
            <div>
              <Button onClick={viewUserBankStatment}>Ver Extrato</Button>
              <Button onClick={viewUserPaymentsList}>Ver Financeiro</Button>
              <UserBasicData user={user} />
            </div>
          </CardContent>
        </Card>

        {bankAccount && (
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <h3>Dados bancários</h3>
              <div>
                <DataDisplay label="Titular" value={bankAccount.legal_name} />
                <DataDisplay label="Banco" value={bankCodeToName(bankAccount.bank_code)} />
                <DataDisplay label="Agência" value={`${bankAccount.agency}-${bankAccount.agency_digit}`} />
                <DataDisplay label="Conta" value={`${bankAccount.account}-${bankAccount.account_digit}`} />
                <DataDisplay label="Documento" value={bankAccount.document_number} />
                <DataDisplay label="Tipo" value={bankAccount.type} />
                <DataDisplay label="ID Conta bancaria" value={bankAccount.id} />
                <DataDisplay label="ID Recebedor" value={bankAccount.recipient_id} />
              </div>
            </CardContent>
          </Card>
        )}

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>Analise de crédito</h3>
            <div>
              {creditAnalysis && (
                <>
                  <DataDisplay label="Analisado em" value={convertDateToBr(creditAnalysis.created_at)} />
                  <DataDisplay label="ID da execução" value={creditAnalysis.info.process_execution_id} />
                  <DataDisplay label="Executada com sucesso" value={creditAnalysis.success} />
                  {creditAnalysis?.success ? (
                    <>
                      <DataDisplay label="Serasa Pontuacao" value={creditAnalysis.score_serasa} />
                      <DataDisplay label="Serasa Pontuacao (fx)" value={creditAnalysis.score_serasa_fx} />

                      <CreditAnalysisInfo statusNum={creditAnalysis.renda_valor_aluguel} tagItem="renda_valor_pessoa" />
                      <CreditAnalysisInfo statusNum={creditAnalysis.documentacao} tagItem="status_do_cpf" />
                      <CreditAnalysisInfo
                        statusNum={creditAnalysis.ocorrencia_de_cobrancas}
                        tagItem="ocorrencia_de_cobrancas"
                      />
                      <CreditAnalysisInfo statusNum={creditAnalysis.negativacao} tagItem="negativacao" />
                      <CreditAnalysisInfo statusNum={creditAnalysis.score_serasa} tagItem="score_serasa" />
                    </>
                  ) : (
                    <ul>
                      {creditAnalysis?.errors.map((str, index) => (
                        <li key={index}>{str}</li>
                      ))}
                    </ul>
                  )}
                </>
              )}

              <div className={classes.cardBtnWrapper}>
                <Button variant="outlined" color="secondary" onClick={execCreditAnalysis}>
                  Analisar agora
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>Cartões</h3>
            <div>
              {user.cards.map((card) => (
                <div key={card.last_digits}>
                  {card.brand} {card.active ? 'ATIVO' : ''} <br />
                  {card.holder_name}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {'**** '.repeat(3)} {card.last_digits}
                  <Divider />
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>Participando das Propostas</h3>
            <div>
              {user.proposal_of.map((proposal, idx) => (
                <div key={idx}>
                  <DataDisplay label="Tipo" value={readableValue(proposal.type)} />
                  <Link to={adminPathBuilder(`/imoveis/${proposal.property.id}`)}>{proposal.display_address}</Link>
                  <Divider />
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h3>Encaminhamento de mensagens</h3>
            <div>
              <div>
                <FormItem
                  control={forwardNotificationForm.control}
                  errors={forwardNotificationForm.errors}
                  name="email"
                  label="EMail"
                  rules={{validate: validateEmail}}
                />
                <Button onClick={forwardNotificationForm.handleSubmit(addForward)}>Adicionar</Button>
              </div>

              {user.forward_notification?.map((item, idx) => (
                <div key={idx}>
                  {item.email}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <IconWrapper bgType="red">
                    <CancelIcon onClick={removeForward(idx)} />
                  </IconWrapper>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
      <div>
        <h3 style={{marginBottom: 5}}>Imoveis</h3>
        <PropertiesList user={user} classes={classes} />
      </div>
    </div>
  )
}

export default UserDetail
