import Grid from '@material-ui/core/Grid'
import {insuranceRepoInstance} from '@root/application/service/repository/property/InsuranceRepository'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactorySingle} from '@root/components/FormHelper/optionFactory'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import {formatBrCurrencyToFloat} from '@root/lib/moneyFormater'
import {validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useForm} from 'react-hook-form'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const INSURANCE_FIRE = 'fire'
const INSURANCE_GUARANTEE = 'guarantee'

const BlockInsurance = ({propertyId, closeModal}) => {
  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const {handleSubmit, control, errors, reset} = useForm()

  const handleForm = async (form) => {
    actions.setGlobalLoaderAction(true)

    try {
      const documents = await firebaseFilesUploader(
        form.documents,
        form.type,
        `property/${propertyId}/insurance/${form.type}`,
      )
      await insuranceRepoInstance.add(propertyId, {
        type: form.type,
        policy_number: form.policy,
        insurer: form.insurance_company,
        amount: formatBrCurrencyToFloat(form.amount),
        documents,
      })
      reset()
      closeModal()
    } catch (ex) {
      alert(errorHandler(ex.message))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }
  return (
    <>
      <Grid container direction="column">
        <FormItem
          required
          control={control}
          errors={errors}
          type="select"
          name="type"
          label="Tipo do seguro"
          options={[
            {label: 'Seguro Residencial', value: INSURANCE_FIRE},
            {label: 'Garantia', value: INSURANCE_GUARANTEE},
          ]}
          rules={{validate: validateRequired}}
        />
        <FormItem
          control={control}
          errors={errors}
          name="policy"
          label="Número da apolice"
          rules={{validate: validateRequired}}
        />
        <FormItem
          control={control}
          errors={errors}
          name="insurance_company"
          label="Seguradora"
          type="select"
          options={[optionFactorySingle('Porto Seguro'), optionFactorySingle('Tokio Marine')]}
          rules={{validate: validateRequired}}
        />
        <FormItem control={control} errors={errors} name="amount" label="Valor" rules={{validate: validateRequired}} />
        <FormItem control={control} errors={errors} multiple={true} type="file" name="documents" label="Documento" />
      </Grid>
      <Button onClick={handleSubmit(handleForm)}>Adicionar</Button>
    </>
  )
}

BlockInsurance.propTypes = {}
BlockInsurance.defaultProps = {}

export default BlockInsurance
