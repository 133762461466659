import {Card, CardContent} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import {useBankRepoListMonth} from '@root/application/service/repository/payment/PaymentRepository'
import {Divider} from '@root/components/Divider/Divider'
import IconCircle from '@root/components/icons/IconCircle'
import Text from '@root/components/Layout/Typography/Text'
import Title from '@root/components/Layout/Typography/Title'
import Page from '@root/components/Page'
import ListItem from '@root/components/PropertyList/ListItem'
import {useQuery} from '@root/hooks/urlHook'
import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import {translate} from '@root/lib/intl/translations'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import contractMapper from 'status-mapper/mapper/contract-mapper'
import routes from '@root/utils/routes'

const usePaymentsListStyle = makeStyles((theme) => ({
  row: {
    'cursor': 'pointer',
    'margin': '2px 0px',
    'padding': '12px 4px !important',
    '&:nth-child(odd)': {
      background: theme.extraColors.STRIPE.light,
    },
    '&:nth-child(even)': {
      // background: theme.extraColors.STRIPE.dark,
    },
  },
}))

const PaymentsListMonth = () => {
  const paymentsListStyle = usePaymentsListStyle()
  const dispatch = useDispatch()

  const history = useHistory()
  const userId = useQuery().getParam('user_id', undefined)

  const paymentsGroup = useBankRepoListMonth(userId)
  const hasEntries = paymentsGroup.response.length > 0

  const goToEditPayment = (payment) => {
    const paymentId = payment.id
    const propertyId = payment.property_id

    if (payment.is_editable) {
      // TODO a pagina de detalhe do boleto esta mal feita, precisa ter o imovel ja "carregado' quando chega la
      window.open(`${routes.DASHBOARD_BOLETO_DETAIL}?id=${propertyId}&payment=${paymentId}`, '_self')
    }
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction('Controle financeiro'))
  }, [])

  return (
    <Page loading={paymentsGroup.isLoading} showContent={hasEntries}>
      <Grid container spacing={6}>
        {paymentsGroup.response.map((group, key) => (
          <Grid key={key} container item direction="row" spacing={1}>
            <Grid item xs={12}>
              <Title>
                {group.month_name}/{group.year}
              </Title>
            </Grid>

            <Grid
              key={key}
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
              spacing={1}>
              <Grid item xs={3} lg={3}>
                <Text bold>Imovel</Text>
              </Grid>
              <Grid item xs={3} lg={2}>
                <Text bold>Valor</Text>
              </Grid>
              <Grid item xs={3} lg={2}>
                <Text bold>Vencimento</Text>
              </Grid>
              <Grid item xs={3} lg={3}>
                <Text bold>Status</Text>
              </Grid>
            </Grid>
            {group.payments.map((payment, key) => (
              <Grid
                key={key}
                container
                item
                onClick={() => goToEditPayment(payment)}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className={paymentsListStyle.row}
                xs={12}>
                <Grid item xs={3} lg={3}>
                  <Text>{payment.property_name}</Text>
                </Grid>
                <Grid item xs={3} lg={2}>
                  <Text>{payment.amount_currency}</Text>
                </Grid>
                <Grid item xs={3} lg={2}>
                  <Text>{payment.due_date}</Text>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Text>{payment.status_translate}</Text>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Page>
  )
}

PaymentsListMonth.propTypes = {}
PaymentsListMonth.defaultProps = {}

export default PaymentsListMonth
