import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {ApprovalTypes} from '@root/redux/approval/approval.types'

const initialState = {
  ready: false,
  property: undefined,
  propertySnap: undefined,
  owner: undefined,
  observationsQueue: [],
}
const reducers = {
  [ApprovalTypes.APPROVAL_SET_ALL]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.ready = payload.ready
      draft.property = payload.property
      draft.propertySnap = payload.propertySnap
      draft.owner = payload.owner
      draft.approved = payload.approved
    })
  },
  [ApprovalTypes.ADD_APPROVAL_OBSERVATION]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.observationsQueue.push(payload.data)
    })
  },

  [ApprovalTypes.REMOVE_APPROVAL_OBSERVATION]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.observationsQueue.splice(payload.index, 1)
    })
  },
}

export default reducerMatch(reducers, initialState)
