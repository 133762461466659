import Grid from '@material-ui/core/Grid'
import {Button} from '@root/components/Button/Button'
import React from 'react'

function PaymentAreaPix({payment, onCopyCode, buttonLabel, buttonIcon}) {
  return (
    <>
      <Grid item>
        <img src={payment.image_code} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
      </Grid>
      <Grid item>
        <Button onClick={onCopyCode} icon={buttonIcon} w="100%">
          {buttonLabel}
        </Button>
      </Grid>
    </>
  )
}

export default PaymentAreaPix
