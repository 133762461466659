import {Button} from '@material-ui/core'
import useStyles from '@root/components/App/Admin/styles'
import {Divider} from '@root/components/Divider/Divider'
import FilePickerMultiple from '@root/components/FilePickerMultiple'
import FormItem from '@root/components/FormHelper/FormItem'
import BaseModal from '@root/components/Modal/BaseModal'
import {convertDateToBr} from 'date-parser'
import {firestore} from '@root/firebase/firebase.utils'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import {validateRequired} from '@root/lib/simpleValidators'
import annotationModel from '@root/model/annotationModel'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const BlockAnnotation = () => {
  const classes = useStyles()
  const {handleSubmit, errors, control} = useForm()
  const dispatch = useDispatch()

  const [isNewVisible, setNewVisibility] = useState(false)
  const [annotations, setAnnotations] = useState([])
  const [files, setFiles] = useState([])

  const property = useSelector((s) => s.admin.property)
  const user = useSelector((s) => s.user.user)

  const openModalForm = () => setNewVisibility(true)
  const closeModalForm = () => setNewVisibility(false)

  const handleFilesChange = (files) => setFiles(files)
  const handleFilesDelete = (deleted, prefix, newList) => setFiles(newList)

  const cleanup = () => {
    setFiles([])
    closeModalForm()
  }
  const onSave = async (form) => {
    dispatch(setGlobalLoaderAction(true))
    const uploads = await execBatchUpload()
    await annotationModel.addToProperty(property.uid, form.description, user.name, uploads)

    cleanup()
    dispatch(setGlobalLoaderAction(false))
  }

  const execBatchUpload = async () => {
    const promises = files.map((file) => firebaseUploader(file, '', `property/${property.uid}/annotation`))
    const uploads = await Promise.all(promises)
    return uploads
  }

  useEffect(() => {
    firestore
      .collection(annotationModel.COLLECTION_NAME)
      .where('target_id', '==', property.uid)
      .orderBy('created_at', 'desc')
      .onSnapshot((querySnap) => {
        setAnnotations(querySnap.docs.map((doc) => ({...doc.data(), uid: doc.id})))
      })
  }, [])

  return (
    <>
      <Button variant="outlined" color="secondary" className={classes.btn16} onClick={openModalForm}>
        Adicionar
      </Button>
      <br />
      <br />

      {annotations.map((item) => (
        <div key={item.uid}>
          {convertDateToBr(item.created_at, true)} <br />
          {item.created_by} <br />
          {item.text} <br />
          {item.files && (
            <>
              <br />
              {item.files.map((file) => (
                <>
                  <a href={file.download_url} rel="noreferrer" target="_blank">
                    {file.name}
                  </a>
                  <br />
                </>
              ))}
            </>
          )}
          <Divider dashed={true} />
        </div>
      ))}

      <BaseModal
        onClose={closeModalForm}
        size="sm"
        title="Nova Anotação"
        okLabel="Criar"
        isOpen={isNewVisible}
        okAction={handleSubmit(onSave)}>
        <>
          <FormItem
            control={control}
            errors={errors}
            name="description"
            label="Descrição"
            type="textarea"
            rules={{validate: validateRequired}}
          />

          <FilePickerMultiple
            id="annotation_files"
            label="Arquivos"
            list={files}
            autoUpload={false}
            displayLinks={true}
            onChangeFileCallback={handleFilesChange}
            onDeleteFileCallback={handleFilesDelete}
          />
        </>
      </BaseModal>
    </>
  )
}

export default BlockAnnotation
