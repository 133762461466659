import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import EmailIcon from '@material-ui/icons/Email'
import analyticsLogger from '@root/firebase/analytics.utils'
import React, {useEffect} from 'react'

import useStyles from './styles'

const Error = () => {
  const classes = useStyles()

  const goHome = () => {
    analyticsLogger.logEvent('erro_fatal_ir_para_home')
    window.location.href = '/'
  }

  const openMailHandler = () => {
    analyticsLogger.logEvent('erro_fatal_enviar_email')
  }

  useEffect(() => {
    analyticsLogger.logEvent('tela_erro_fatal')
  }, [])

  return (
    <div className={classes.root} id="error-screen">
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={6} className={(classes.gridItem, classes.gridItemTxt)}>
          <img src="/images/logo/Moben-Logo.png" className={classes.logoImg} onClick={goHome} />
          <h1>Erro</h1>
          <p>Ups! Erramos alguma vírgula.</p>
          <p>Fique tranquilo, seus dados foram computados e retornaremos via e-mail.</p>
          <p>Enquanto isso, algum dev vai ter que escrever mais códigos.</p>
          <br />
          <div className={classes.smallContainer}>
            <small>Queremos a sua opinião!</small>
            <br />
            <small>Por ser uma novidade estamos ansiosos</small>
            <br />
            <small>para entender melhor as suas expectativas</small>
          </div>
          <br />
          <a
            href="mailto:moben@moben.com.br"
            target="_blank"
            rel="noreferrer"
            className={classes.emailLink}
            onClick={openMailHandler}>
            <strong>
              <EmailIcon /> moben@moben.com.br
            </strong>
          </a>
        </Grid>
        <Box
          component={Grid}
          className={(classes.gridItem, classes.gridImgContainer)}
          item
          sm={6}
          display={{xs: 'none', sm: 'block'}}>
          <img src="/assets/sentry-error.jpg" className={classes.errorImg} />
        </Box>
      </Grid>
    </div>
  )
}

export default Error
