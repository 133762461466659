import {differenceInYears} from 'date-fns'
import dateParser, {convertDateToBr} from 'date-parser'
import {firestore} from '@root/firebase/firebase.utils'
import addressDisplay from '@root/lib/addressDisplay'
import calcContractLengthMonths from '@root/lib/calcContractLengthMonths'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import propertyModel from '@root/model/propertyModel'
import userModel from '@root/model/userModel'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import contractMapper from 'status-mapper/mapper/contract-mapper'

import EditableCell from './EditableCell'

const USER_ACCESSOR_IDS = ['owner_gender', 'owner_quantity', 'owner_situation', 'tenant_quantity']
const IPTU_RESPONSIBLE = {
  include_with_rent: 'incl. no aluguel',
  tenant: 'IQ',
  owner: 'PP',
}
const FORUM = {
  justica_comum: 'justiça comum',
  camara_arbitral: 'arbitragem',
}

const getGuarantee = (type, subtype) => {
  if (!type) {
    return ''
  }

  const TYPE = {
    'fianca': 'fiança',
    'caucao': 'caução',
    'none': 'sem garantia',
    'no-tenant': 'sem IQ',
    'fiador': 'fiador',
  }

  const SUBTYPE = {
    fiador: 'fiador',
    bancaria: 'bançaria',
    seguro: 'seguro fiança',
    dinheiro: 'dinheiro',
    titulo_capitalizacao: 'título cap',
    bens_imoveis: 'bens imóveis',
  }

  return `${TYPE[type]}${subtype ? ' - ' + SUBTYPE[subtype] : ''}`
}

const FIRE_INSURANCE_RESPONSIBLE = {
  owner: 'PP',
  owner_contract_tenant_pay_with_rent: 'PP contrata e IQ paga com aluguel',
  owner_contract_tenant_pay_directly: 'PP contrata e IQ paga diretamente',
  tenant: 'IQ',
}

const getFieldName = (id) => {
  const splitId = id.split('_')

  if (splitId.length === 2) {
    if (splitId[0] === 'owner' || splitId[0] === 'tenant') {
      return id
    }
    return splitId[1]
  } else if (splitId.length > 2) {
    splitId.splice(0, 1) // tira o 'property'

    if (id.indexOf('seguro_fianca') >= 0) {
      return `${splitId.splice(0, 1)}.guarantees.${splitId.join('_')}`
    }
    if (id.indexOf('fire_insurance_moben') >= 0) {
      return `${splitId.splice(0, 1)}.fire_insurance.${splitId.join('_')}`
    }
    if (id.indexOf('survey_moben') >= 0) {
      return `${splitId.splice(0, 1)}.survey.${splitId.join('_')}`
    }
    if (id.indexOf('payment_first_charge') >= 0) {
      return `${splitId.splice(0, 1)}.${splitId.join('_')}`
    }
    return `${splitId.splice(0, 1)}.${splitId.join('_')}`
  } else {
    return id
  }
}

const useActiveContracts = () => {
  const [activeContracts, setActiveContracts] = useState([])

  const updateData = useCallback(async (index, cells, id, value) => {
    const data = value ?? ''
    const property_uid = cells[0].value
    const user_uid = id.indexOf('tenant') >= 0 ? cells[16].value : cells[1].value

    if (USER_ACCESSOR_IDS.indexOf(id) >= 0) {
      await firestore.doc(`${userModel.COLLECTION_NAME}/${user_uid}`).update(getFieldName(id), data)
    } else {
      await await firestore.doc(`${propertyModel.COLLECTION_NAME}/${property_uid}`).update(getFieldName(id), data)
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Imóvel ID',
        accessor: 'property_uid',
      },
      {
        Header: 'PP ID',
        accessor: 'owner_uid',
      },
      {
        Header: 'PP Nome',
        accessor: 'owner_name',
      },
      {
        Header: 'PP Celular',
        accessor: 'owner_phone',
      },
      {
        Header: 'Nascimento',
        accessor: 'owner_birthdate',
      },
      {
        Header: 'Idade',
        accessor: 'owner_age',
      },
      {
        Header: 'Gênero',
        accessor: 'owner_gender',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="gênero..."
            />
          )
        },
      },
      {
        Header: 'Estado Civil',
        accessor: 'marital_status',
      },
      {
        Header: 'Profissão',
        accessor: 'profession',
      },
      {
        Header: 'Quant. PP',
        accessor: 'owner_quantity',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="quant..."
            />
          )
        },
      },
      {
        Header: 'Situação PP',
        accessor: 'owner_situation',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="situação..."
            />
          )
        },
      },
      {
        Header: 'Endereço',
        accessor: 'address',
      },
      {
        Header: 'Bairro',
        accessor: 'neighborhood',
      },
      {
        Header: 'Cidade',
        accessor: 'city',
      },
      {
        Header: 'UF',
        accessor: 'state',
      },
      {
        Header: 'm2',
        accessor: 'm2',
      },
      {
        Header: 'Quem assinou (PP)',
        accessor: 'property_contract_signed_by',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="quem assinou..."
            />
          )
        },
      },
      {
        Header: 'IQ ID',
        accessor: 'tenant_uid',
      },
      {
        Header: 'IQ Nome',
        accessor: 'tenant_name',
      },
      {
        Header: 'IQ Celular',
        accessor: 'tenant_phone',
      },
      {
        Header: 'IQ Aceitou Termos?',
        accessor: 'terms_accepted',
      },
      {
        Header: 'Quant. IQ',
        accessor: 'tenant_quantity',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="quant..."
            />
          )
        },
      },
      {
        Header: 'Aluguel',
        accessor: 'rentfee',
      },
      {
        Header: 'Aluguel/m2',
        accessor: 'rentfee_m2',
      },
      {
        Header: 'Condomínio',
        accessor: 'condominium',
      },
      {
        Header: 'IPTU',
        accessor: 'iptu',
      },
      {
        Header: 'Resp. IPTU',
        accessor: 'iptu_responsible',
      },
      {
        Header: 'Repasse IPTU p/ Boleto Moben',
        accessor: 'iptu_boleto_moben',
      },
      {
        Header: 'Boleto Moben',
        accessor: 'boleto_moben',
      },
      {
        Header: 'Início da Vigência',
        accessor: 'validity_start',
      },
      {
        Header: 'Final da Vigência',
        accessor: 'validity_end',
      },
      {
        Header: 'Meses de Vigência',
        accessor: 'validity_months',
      },
      {
        Header: 'Foro',
        accessor: 'forum',
      },
      {
        Header: 'Garantia Contratada?',
        accessor: 'property_contract_guarantee_contracted',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="gar..."
            />
          )
        },
      },
      {
        Header: 'Garantia',
        accessor: 'guarantee',
      },
      {
        Header: 'Resp. Seg. Fiança',
        accessor: 'property_contract_seguro_fianca_responsible',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="resp..."
            />
          )
        },
      },
      {
        Header: 'Seg. Incêndio Contratado?',
        accessor: 'fire_insurance_contracted',
      },
      {
        Header: 'Resp. Seg. Incêndio',
        accessor: 'fire_insurance_responsible',
      },
      {
        Header: 'Seg. Incêndio Moben?',
        accessor: 'property_contract_fire_insurance_moben',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="seg. inc..."
            />
          )
        },
      },
      {
        Header: 'Vistoria',
        accessor: 'has_survey',
      },
      {
        Header: 'Vistoria Moben?',
        accessor: 'property_contract_survey_moben',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="vist. moben..."
            />
          )
        },
      },
      {
        Header: 'Cartão Cadastrado?',
        accessor: 'has_card',
      },
      {
        Header: 'Data Primeira Cobrança',
        accessor: 'first_payment',
      },
      {
        Header: 'Vcto Boleto',
        accessor: 'rentdue',
      },
      {
        Header: 'Criou anúncio?',
        accessor: 'created_ad',
      },
      {
        Header: 'Tem anúncio ativo?',
        accessor: 'has_ad',
      },
      {
        Header: 'Renovação Anual Contrato',
        accessor: 'property_contract_annual_renewal',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="ren. anual..."
            />
          )
        },
      },
      {
        Header: 'OBS',
        accessor: 'property_contract_admin_observations',
        Cell: ({value, row: {index, cells}, column: {id}}) => {
          return (
            <EditableCell
              textArea
              value={value}
              index={index}
              cells={cells}
              id={id}
              updateData={updateData}
              placeholder="obs..."
            />
          )
        },
      },
    ],
    [],
  )

  useEffect(() => {
    const fetchActiveContracts = async () => {
      const properties = await firestore
        .collection(propertyModel.COLLECTION_NAME)
        .where('contract.status', '==', contractMapper.status.INPROGRESS)
        .get()

      let ownersPropertiesTenants = []

      for (const property of properties.docs) {
        if (property.exists) {
          const ownerId = property.data().ower_id
          const tenantId = property.data().tenant

          const ownerPromise = userModel.getById(ownerId)
          const tenantPromise = firestore.doc(`${userModel.COLLECTION_NAME}/${tenantId ? tenantId : ''}`).get()

          let [owner, tenant] = await Promise.all([ownerPromise, tenantPromise])

          if (tenant.exists) {
            tenant = tenant.data()
          } else {
            tenant = {}
          }

          ownersPropertiesTenants.push({
            property_uid: property.id,
            owner_uid: owner.data().uid,
            owner_name: owner.data().name,
            owner_birthdate: owner.data().birthdate ? convertDateToBr(dateParser(owner.birthdate)) : '',
            owner_age: owner.data().birthdate ? differenceInYears(new Date(), dateParser(owner.birthdate)) : '',
            owner_gender: owner.data().gender,
            owner_phone: owner.data().phone,
            marital_status: owner.data().marital_status,
            profession: owner.data().profession,
            owner_quantity: owner.data().owner_quantity,
            owner_situation: owner.data().owner_situation,
            address: addressDisplay(property.data().address),
            neighborhood: property.data().address?.neighborhood,
            city: property.data().address?.city,
            state: property.data().address?.state,
            m2: property.data().specs?.footage,
            property_contract_signed_by: property.data().contract?.signed_by,
            tenant_uid: tenant.uid,
            tenant_name: tenant.name,
            tenant_phone: tenant.phone,
            terms_accepted: tenant.terms_accepted?.register?.status === 'accepted' ? '👍🏼' : '',
            tenant_quantity: tenant.tenant_quantity,
            rentfee: formatFloatToBrCurrency(property.data().contract?.rentfees?.rent),
            rentfee_m2: formatFloatToBrCurrency(
              property.data().contract?.rentfees?.rent /
                (property.data().specs?.footage ? parseFloat(property.data().specs?.footage) : 1),
            ),
            condominium: formatFloatToBrCurrency(property.data().contract?.rentfees?.condominium),
            iptu: formatFloatToBrCurrency(property.data().contract?.rentfees?.iptu),
            iptu_responsible: IPTU_RESPONSIBLE[property.data().contract?.rentfees?.iptu_responsible],
            iptu_boleto_moben:
              property.data().contract?.rentfees?.include_iptu &&
              property.data().contract?.payment_method?.option === 'boleto'
                ? '👍🏼'
                : '',
            boleto_moben: property.data().contract?.payment_method?.option === 'boleto' ? '👍🏼' : '',
            validity_start: convertDateToBr(property.data().contract?.validity?.start),
            validity_end: convertDateToBr(property.data().contract?.validity?.end),
            validity_months: calcContractLengthMonths(
              dateParser(property.data().contract?.validity?.start),
              dateParser(property.data().contract?.validity?.end),
            ),
            forum: FORUM[property.data().contract?.forum?.type],
            property_contract_guarantee_contracted: property.data().contract?.guarantee_contracted,
            guarantee: getGuarantee(
              property.data().contract?.guarantees?.type,
              property.data().contract?.guarantees?.subtype,
            ),
            property_contract_seguro_fianca_responsible: property.data().contract?.guarantees
              ?.seguro_fianca_responsible,
            fire_insurance_contracted: property.data().contract?.fire_insurance?.responsible ? '👍🏼' : '',
            fire_insurance_responsible:
              FIRE_INSURANCE_RESPONSIBLE[property.data().contract?.fire_insurance?.responsible],
            property_contract_fire_insurance_moben: property.data().contract?.fire_insurance?.fire_insurance_moben,
            has_survey: property.data().contract?.survey?.has_survey ? '👍🏼' : '',
            property_contract_survey_moben: property.data().contract?.survey?.survey_moben,
            has_card: owner.data().cards?.length ? '👍🏼' : '',
            first_payment: convertDateToBr(property.data().contract?.rentdue?.first_payment),
            rentdue: property.data().contract?.rentdue?.day ? 'dia ' + property.data().contract?.rentdue?.day : '',
            property_contract_annual_renewal: property.data().contract?.annual_renewal,
            property_contract_admin_observations: property.data().contract?.admin_observations,
            has_ad: property.data().ad_enabled ? '👍🏼' : '',
            created_ad: property.data().ad_form_filled ? '👍🏼' : '',
          })
        }
      }

      setActiveContracts(ownersPropertiesTenants)
    }

    fetchActiveContracts()
  }, [])

  return {
    columns,
    data: activeContracts,
  }
}

export default useActiveContracts
