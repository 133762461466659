import {SigninTypes} from '@root/redux/signin/signin.types'

import {SurveyActionTypes} from './survey.types'

const initialState = {
  property_uid: '',
  entering: {
    created_at: null,
    scheduled_for: null,
    images: [],
  },
  leaving: {
    created_at: null,
    scheduled_for: null,
    images: [],
  },
}

const surveyReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SigninTypes.LOG_OUT:
      return {...initialState}

    case SurveyActionTypes.SET_PROPERTY_UID_FOR_SURVEY:
      return {
        ...state,
        property_uid: payload,
      }

    case SurveyActionTypes.SET_SURVEY_SCHEDULED_FOR:
      if (payload.surveyType === 'entering') {
        return {
          ...state,
          entering: {
            ...state.entering,
            scheduled_for: payload.date,
          },
        }
      } else {
        return {
          ...state,
          leaving: {
            ...state.leaving,
            scheduled_for: payload.date,
          },
        }
      }

    case SurveyActionTypes.SET_SURVEY_CREATED_AT:
      if (payload.surveyType === 'entering') {
        return {
          ...state,
          entering: {
            ...state.entering,
            created_at: payload.date,
          },
        }
      } else {
        return {
          ...state,
          leaving: {
            ...state.leaving,
            created_at: payload.date,
          },
        }
      }
    case SurveyActionTypes.SET_SURVEY_FROM_DATABASE:
      return {
        ...payload,
      }

    case SurveyActionTypes.SET_SURVEY_IMAGES:
      if (payload.surveyType === 'entering') {
        return {
          ...state,
          entering: {
            ...state.entering,
            images: payload.imagesArr,
          },
        }
      } else {
        return {
          ...state,
          leaving: {
            ...state.leaving,
            images: payload.imagesArr,
          },
        }
      }

    default:
      return state
  }
}

export default surveyReducer
