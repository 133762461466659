import {HeaderActionTypes} from './header.types'

export const setUnescapableHeader = (allowScape = true) => ({
  type: HeaderActionTypes.SET_UNESCAPABLE,
  allowScape,
})

export const setHeaderMainHeader = () => ({
  type: HeaderActionTypes.SET_HEADER_MAIN_HEADER,
})

export const setHeaderCadastroProprietarioLoginSignup = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_LOGIN_SIGNUP,
})

export const setHeaderCadastroProprietarioSMSLogin = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_SMS_LOGIN,
})

export const setHeaderCadastroProrpietarioEstadoDoImovel = (currentUser) => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_ESTADO_DO_IMOVEL,
  payload: {user: currentUser},
})

export const setHeaderCadastroProrpietarioFormaPagamento = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_FORMA_PAGAMENTO,
})

export const setHeaderCadastroProprietarioGarantias = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_GARANTIAS,
})

export const setHeaderCadastroProprietarioImovel = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL,
})

export const setHeaderCadastroProprietarioImovelCadastrado = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL_CADASTRADO,
})

export const setHeaderCadastroProprietarioInquilinos = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_INQUILINOS,
})

export const setHeaderCadastroProprietarioAnaliseContrato = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_ANALISE_CONTRATO,
})

export const setHeaderCadastroProprietarioParabens = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_PARABENS,
})

export const setHeaderCadastroProrpietarioResumo = () => ({
  type: HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_RESUMO,
})

export const setHeaderPainelDeControle = () => ({
  type: HeaderActionTypes.SET_HEADER_PAINEL_DE_CONTROLE,
})

export const setHeaderTermosECondicoes = () => ({
  type: HeaderActionTypes.SET_HEADER_TERMOS_E_CONDICOES,
})
