import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import {Button} from '@root/components/Button/Button'
import IconPencil from '@root/components/icons/IconPencil'
import IconPlus from '@root/components/icons/IconPlus'
import IconTrashCan from '@root/components/icons/IconTrashCan'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import {bankCodeToName} from '@root/lib/banksList'
import {calcPct, formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import PropTypes from 'prop-types'
import React from 'react'

const useAccountItemStyle = makeStyles((theme) => ({
  errorAll: ({hasError}) => ({
    '& * ': {
      color: hasError ? theme.extraColors.INVALID : '',
    },
  }),
}))
const ContainerBlock = ({children}) => (
  <Grid container item xs={6} sm={5} spacing={0} direction="row" justify="space-between">
    {children}
  </Grid>
)
const SectionBlock = ({children}) => (
  <Grid container item direction="row" xs={12}>
    {children}
  </Grid>
)
const ContentBlock = ({children, accent, none, onClick, className}) => {
  let WrapperText = Text
  if (accent) {
    WrapperText = TextSecondary
  }
  if (none) {
    WrapperText = React.Fragment
  }

  return (
    <Grid item xs={6} sm={6} onClick={onClick} className={className}>
      <WrapperText>{children}</WrapperText>
    </Grid>
  )
}

const AccountItem = ({account, totalAmount, index, editable, isEditing, onSplitChange, onRemove, onUse, onEdit}) => {
  const classes = useAccountItemStyle({hasError: editable && account.amount_pct <= 0})
  const isTrullyEdit = editable && isEditing
  const handleSplitAmountChange = (evt) => onSplitChange(evt, account, index)
  const handleRemoveAccount = () => onRemove(account, index)
  const handleRequestUse = () => onUse(account, index)
  const handleRequestEdit = () => onEdit(account, index)

  return (
    <Box bgType="gray" m="18px 0" className={classes.errorAll}>
      <Grid container spacing={2} direction="row">
        <Grid container item xs={10} sm={9} md={10} direction="row">
          <Grid item>
            <TextSecondary bold>
              {account.legal_name} - {bankCodeToName(account.bank_code)}
            </TextSecondary>
          </Grid>
          <Grid container item direction="row" justify="space-between">
            <ContainerBlock>
              <SectionBlock>
                <ContentBlock>Agência</ContentBlock>
                <ContentBlock accent>
                  {account.agency}-{account.agency_digit}
                </ContentBlock>
              </SectionBlock>

              <SectionBlock>
                <ContentBlock>Conta</ContentBlock>
                <ContentBlock accent>
                  {account.account}-{account.account_digit}
                </ContentBlock>
              </SectionBlock>
            </ContainerBlock>

            {editable && (
              <ContainerBlock>
                <SectionBlock>
                  <ContentBlock>Divisão</ContentBlock>
                  <ContentBlock none onClick={handleRequestEdit} className="clickable">
                    {isTrullyEdit && (
                      <>
                        <input
                          className="change-split-input"
                          type="number"
                          step="1"
                          value={account.amount_pct}
                          onChange={handleSplitAmountChange}
                        />
                      </>
                    )}
                    {!isTrullyEdit && (
                      <>
                        <TextSecondary>{account.amount_pct}%</TextSecondary>
                        <IconWrapper style={{marginLeft: 10, position: 'relative', bottom: 1}} width={20} height={20}>
                          <IconPencil width={10} height={10} />
                        </IconWrapper>
                      </>
                    )}
                  </ContentBlock>
                </SectionBlock>
                <SectionBlock>
                  <ContentBlock>Valor a receber</ContentBlock>
                  <ContentBlock accent>
                    {formatFloatToBrCurrency(calcPct(totalAmount, account.amount_pct))}
                  </ContentBlock>
                </SectionBlock>
              </ContainerBlock>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={2} sm={3} md={2} direction="column" justify="center" alignItems="flex-end">
          {editable && (
            <Grid item>
              <IconWrapper bgType="red" onClick={handleRemoveAccount}>
                <IconTrashCan width="12px" />
              </IconWrapper>
            </Grid>
          )}

          {!editable && (
            <Grid item>
              <IconWrapper onClick={handleRequestUse}>
                <IconPlus width="12px" />
              </IconWrapper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

AccountItem.propTypes = {
  editable: PropTypes.bool,
  isEditing: PropTypes.bool,
  totalAmount: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onSplitChange: PropTypes.func,
  onUse: PropTypes.func,
  onEdit: PropTypes.func,
}
AccountItem.defaultProps = {
  editable: false,
  isEditing: false,
}

export default AccountItem
