import {FooterActionTypes} from './footer.types'

const initialState = {
  isVisible: false,
}

const footerReducer = (state = initialState, action) => {
  const newState = {
    ...state,
  }
  switch (action.type) {
    case FooterActionTypes.SET_FOOTER_IS_VISIBLE:
      newState.isVisible = true
      break

    case FooterActionTypes.SET_FOOTER_NOT_VISIBLE:
      newState.isVisible = false
  }

  return newState
}

export default footerReducer
