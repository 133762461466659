/** @module intVal */

/**
 * @function
 * @param {*} value
 * @return {string}
 */
export const onlyNumbers = (value) => String(value).replace(/\D/g, '')

/**
 * @function
 * @param {*} value
 * @return {string}
 */
export const extractDecimal = (value) => {
  const valstr = String(value)

  const matches = valstr.match(/\d+(\.\d+)?/)
  if (matches && matches.length > 0) {
    return matches[0]
  }

  return '0'
}

/**
 * @function
 * @param {*} value
 * @return {number} if cannot parse will return 0(zero)
 */
export default (value) => {
  if (typeof value !== 'number') {
    const decimalVal = extractDecimal(value)
    let num = parseFloat(decimalVal)
    if (isNaN(num)) {
      num = 0
    }
    return num
  }
  return value
}
