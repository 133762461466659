import * as appRoutes from 'app-routes'
import React from 'react'

/**
 * @deprecated
 * @param {string} route
 * @param {string} id
 * @return {string}
 */
export const routeWithUID = (route, id) => `${route}?uid=${id}`

const allRoutes = appRoutes.default

// gambi para nao refazer todos os imports
export const getDashboardLink = appRoutes.getDashboardLink
export const routeWithParams = appRoutes.routeWithParams
export const routeWithID = appRoutes.routeWithID

export default allRoutes
