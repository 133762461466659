import {Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import {Button} from '@root/components/Button/Button'
import analyticsLogger from '@root/firebase/analytics.utils'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {MOBEN_EMAIL, MOBEN_WHATSAPP} from '@root/utils/constants'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import PageMini from '../../components/PageMini'

const useStyles = makeStyles((theme) => ({
  mainSection: {
    'overflowX': 'hidden',
    '& h5': {
      margin: '10px 0 20px',
      fontWeight: 600,
    },
    '& p': {textAlign: 'center'},
    '& p + p': {
      // marginTop: theme.margin[3],
    },
    '& img': {
      width: '100%',
    },
    '& button + button': {
      marginTop: theme.margin[3],
    },
    '& > :last-child': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '244px',
      margin: '30px auto 0',
    },
  },
  textContainer: {
    padding: '0 8px',
  },
  imageContainer: {
    'transform': 'scaleX(1.1)',
    'position': 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: -2,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(to top, transparent 0%, transparent 70%, #fff 98%)',
    },
  },
  linksContainer: {
    'padding': '0 8px',
    '& a': {display: 'flex', alignItems: 'center', color: '#000', fontWeight: 'bold', cursor: 'pointer'},
    '& svg': {color: theme.extraColors.PURPLE, fontSize: 28, marginRight: 2},
  },
}))

export default function FaleConosco() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    dispatch(setNavbarTitleAction('Fale conosco'))
  }, [])

  const openWhatsApp = () => {
    analyticsLogger.logEvent('faleconosco_whatsapp')
    isExtraSmallViewPort
      ? window.open(`https://api.whatsapp.com/send?phone=55${MOBEN_WHATSAPP}&text=`, '_self')
      : window.open(`https://api.whatsapp.com/send?phone=55${MOBEN_WHATSAPP}&text=`, '_blank')
  }

  const openEmail = () => {
    analyticsLogger.logEvent('faleconosco_email')
    window.open(`mailto:${MOBEN_EMAIL}`, '_blank')
  }

  return (
    <PageMini>
      <Grid container align="center" className={classes.mainSection}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography variant="body2" align="left">
            Estamos disponivel para conversar com você e explicar qualquer detalhe que queira se aprofundar.
          </Typography>
          <Typography variant="body2" align="left">
            Entre em contato através de um dos canais abaixo:
          </Typography>
          <br />
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          justify="space-evenly"
          className={classes.linksContainer}>
          <Typography variant="body1" component="a" onClick={openEmail}>
            <AlternateEmailIcon /> {MOBEN_EMAIL}
          </Typography>
          <Typography variant="body1" component="a" onClick={openWhatsApp}>
            <PhoneInTalkIcon /> {MOBEN_WHATSAPP}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.imageContainer}>
          <img src={'/assets/new/talk_to_us_img.jpg'} alt="Fale conosco" />
        </Grid>

        <Grid item xs={12}>
          <Button size="large" w="244px" fz="15px" p="18px 0" fw={600} onClick={openWhatsApp}>
            Contato via WhatsApp
          </Button>
        </Grid>
      </Grid>
    </PageMini>
  )
}
