/** @module invitePath */
import {INVITE_TYPE_FIADOR, INVITE_TYPE_TENANT} from '@root/components/InviteRegister/Invite'
import {retriveBackLink} from '@root/lib/backToLink'
import storage from '@root/lib/storage'
import {matchPath} from 'react-router'
import {STORAGE_IS_TENANT} from '@root/utils/constants'
import routes from '@root/utils/routes'

// melhor explicado a diferenca sobre strict e exact
// https://stackoverflow.com/a/52275337/6749912
export const isMatchPath = (curPath, checkPath, strict = false, exact = undefined) =>
  matchPath(curPath, {
    path: checkPath,
    strict,
    exact,
  }) !== null

export const isInvitePath = (path) => isMatchPath(path, routes.REGISTER_INVITE_ENTRY)

export const invitePathHasTenant = (path) => {
  return path && path.indexOf(INVITE_TYPE_TENANT) !== -1
}

export const invitePathHasFiador = (path) => {
  return path && path.indexOf(INVITE_TYPE_FIADOR) !== -1
}

export const hasInviteStored = () => {
  const backlink = retriveBackLink()
  const isTenant = storage.load(STORAGE_IS_TENANT, false)
  const isTenantFromBacklink = isInvitePath(backlink)

  return isTenant || isTenantFromBacklink
}

export const extractPropertyId = (path) => {
  // /cadastro/convite/inquilino/2J0tZP3x44SFo830TpDB
  const pathParts = path.split('/')

  if (pathParts[4]) {
    return pathParts[4]
  }

  return ''
}

export const isInInviteFlow = (path) => {
  return (
    isInvitePath(path) ||
    isMatchPath(path, routes.REGISTER_TENANT_FIADOR) ||
    isMatchPath(path, routes.REGISTER_TENANT_MORE_ABOUT) ||
    isMatchPath(path, routes.REGISTER_TENANT_CONCLUDED)
  )
}
