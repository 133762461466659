import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {SigninTypes} from '@root/redux/signin/signin.types'
import {UserActionTypes} from '@root/redux/user/user.types'

const initialState = {
  terms_accepted: false,
  register_form: {},
  extra_data: {},
}
const reducers = {
  [UserActionTypes.SET_USER_NULL]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.extra_data = {}
    })
  },
  [SigninTypes.SET_REGISTER_FORM]: (state, {payload}) =>
    produce(state, (draft) => {
      draft.register_form = payload.form
      draft.extra_data = payload.form
    }),
  [SigninTypes.SET_ACCEPTED_TERMS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.terms_accepted = payload.accepted
    })
  },
  [SigninTypes.SET_EXTRA_DATA]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.extra_data = payload.extraData
    })
  },
}

export default reducerMatch(reducers, initialState)
