import Grid from '@material-ui/core/Grid'
import routes from 'app-routes'
import {calculatorRepoInstance} from '@root/application/service/repository/calculator/CalculatorRepository'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TitleRaw from '@root/components/Layout/Typography/TitleRaw'
import {currencyFormatProps} from '@root/components/NumberMask'
import PageMini from '@root/components/PageMini'
import useWhatappLinker from '@root/hooks/useWhatappLinker'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {formatBrCurrencyToFloat} from '@root/lib/moneyFormater'
import {montsList} from '@root/lib/predefinedValuesOptions'
import {numberFormatValidateCurrency, validateNumber, validateRequired} from '@root/lib/simpleValidators'
import CalculatorForm from '@root/pages/Calculator/Readjustment/CalculatorForm'
import ReadjustmentResult from '@root/pages/Calculator/Readjustment/ReadjustmentResult'
import React, {useReducer} from 'react'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const initialState = {
  resultVisibility: false,
  result: {},

  /* resultVisibility: true,
  result: {
    rent: 'R$ 1000,00',
    validity_start: '01/2020',
    readjusment_index: 'IGP-M',
    history_size: 2,
    history: [
      {
        readjust_date: '01/2021',
        new_amount: 'R$ 1200,00',
        index_variation: '10%',
      },
      {
        readjust_date: '01/2022',
        new_amount: 'R$ 1400,00',
        index_variation: '20%',
      },
    ],
  },*/
}

function reducerFunc(state, action) {
  switch (action.type) {
    case 'visibility':
      return {resultVisibility: action.visibility, result: action.result}
  }
}

const Feature = ({title, description}) => {
  return (
    <Grid container item xs={12} md={6} direction="row" alignContent="flex-start" style={{padding: 8, marginBottom: 8}}>
      <Grid item xs={12} md={12} lg={12} style={{marginBottom: 4}}>
        <Text bold size="1.1rem">
          {title}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text>{description}</Text>
      </Grid>
    </Grid>
  )
}

const CalculatorReadjustment = () => {
  const reducer = useReducer(reducerFunc, initialState)
  const [state, dispatch] = reducer
  const isResultVisible = state.resultVisibility
  const walinker = useWhatappLinker()

  const handleOpenWhats = () => {
    walinker.open(walinker.createSales('Quero conhecer mais sobre a Moben'))
  }

  return (
    <PageMini navtitle="Calculadora de reajuste">
      <Grid container direction="row" spacing={4} style={{marginLeft: -12, paddingTop: 12}}>
        <Grid
          container
          item
          xs={12}
          md={4}
          direction="row"
          spacing={1}
          alignContent="flex-start"
          style={{background: '#f4f4f4'}}>
          <Grid item xs={12}>
            <TitleRaw>Calcule o reajuste do seu aluguel</TitleRaw>
          </Grid>

          {!isResultVisible && <CalculatorForm state={state} dispatch={dispatch} />}
          {isResultVisible && <ReadjustmentResult state={state} dispatch={dispatch} />}
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid container item>
            <Grid container item md={6}>
              <Grid item>
                <TitleRaw bold>Transforme a maneira de cuidar da sua locação</TitleRaw>
              </Grid>
              <Grid item>
                <Text>
                  Moben é a plataforma para você administrar diretamente com o inquilino o seu imovel alugado.
                </Text>
              </Grid>
              <Grid item>
                <Text>
                  Aqui você encontra tecnologia e conhecimento para realizar a análise de crédito, contrato sob medida,
                  controle de pagamentos, anúncios e muito mais em um único painel de controle.
                </Text>
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid item>
                <img
                  width="100%"
                  alt="Moben um novo jeito de administrar o seu imóvel alugado"
                  className="full-bleed-image hero-home-copy"
                  src="/home2/image/home1.png"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item>
            <Feature
              title="Datas"
              description="Não se preocupe mais com as datas, cálculo de rejuste, cobranças de alugueis em atraso, lembretes para troca de titularidade de contas de consumo e muito mais, são feitos automaticamente para você."
            />

            <Feature
              title="Anúncio"
              description="Crie um anúncio seguindo as melhores práticas do mercado, contrate fotógrafos profissionais, receba alertas de interessados e acelere a ocupação do seu imóvel vazio."
            />
            <Feature
              title="Análise de crédito"
              description="Veja as informações disponíveis nos principais bureaus de crédito do mercado relacionados ao CPF do inquilino."
            />
            <Feature
              title="Contrato"
              description="Tenha mais segurança no seu aluguel. Personalize seu contrato e não deixe nenhum item importante de fora. Tudo isso em poucos minutos. E você ainda conta com o conforto de assinar digitalmente de onde estiver."
            />
            <Feature
              title="Pagamento"
              description="Agora você não precisa mais ficar respondendo as solicitações para pagamento após o vencimento do inquilino esquecido. Basta ele efetuar o pagamento do boleto ou pix a qualquer momento. E mais: se atrasar, a multa e juros são calculados automaticamente. Você ainda acompanha o processo desde a emisão do boleto até o deposito na sua conta por mensagens no seu whatsapp."
            />
            <Feature
              title="Sem taxas"
              description="Sem taxas de administração, na Moben você paga um valor fixo pelo uso da plataforma. Começe a pagar somente após a entrega das chaves para o inquilino"
            />
          </Grid>
          <Grid container item xs={12} justify="center">
            <Grid item>
              <Button bgType="green" w={360} h={45} onClick={handleOpenWhats}>
                QUERO CONHECER A MOBEN
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageMini>
  )
}

CalculatorReadjustment.propTypes = {}
CalculatorReadjustment.defaultProps = {}

export default CalculatorReadjustment
