import Grid from '@material-ui/core/Grid'
import {Divider} from '@root/components/Divider/Divider'
import IconPlus from '@root/components/icons/IconPlus'
import React from 'react'
import {useDispatch} from 'react-redux'
import {addContractManagerAdddEntry} from '@root/redux/adminContractManager/adminContractManager.actions'

const AddButton = ({path}) => {
  const dispatch = useDispatch()
  const handleAdd = () => {
    dispatch(addContractManagerAdddEntry(path.join('.')))
  }

  return (
    <div style={{flexGrow: 1}}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs>
          <Divider dashed={true} />
        </Grid>
        <Grid item xs={1} style={{textAlign: 'center'}}>
          <IconPlus className="clickable" style={{fontSize: 32}} onClick={handleAdd} />
        </Grid>
        <Grid item xs>
          <Divider dashed={true} />
        </Grid>
      </Grid>
    </div>
  )
}

export default AddButton
