import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {Button} from '@root/components/Button/Button'
import InviteForm from '@root/components/Forms/InviteForm'
import Page from '@root/components/Page'
import analyticsLogger from '@root/firebase/analytics.utils'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import {discordNotifyOwnerRegisteredProposal} from '@root/lib/discordNotification'
import {isCypressEnabled, isDevEnv} from '@root/lib/envDetector'
import errorHandler from '@root/lib/errorHandler'
import {isMatchPath} from '@root/lib/invitePath'
import {loadProposalWithManagerAndRedux} from '@root/lib/proposalLoader'
import {proposalSaver} from '@root/lib/proposalSaver'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import React, {useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setCartItemById} from '@root/redux/cart/cart.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'
import {USER_SOURCE_PROPOSAL} from '@root/utils/constants'
import routes, {routeWithParams} from '@root/utils/routes'

import useStyles from './styles'

const staticEmptyUser = {}
const ProposalsRegister = () => {
  const classes = useStyles()

  const formHook = useForm()
  const {reset, handleSubmit} = formHook
  const history = useHistory()
  const proposalId = useQuery().getParam('proposal')
  const interestedId = useQuery().getParam('id')

  const [currentProposal, setProposal] = useState()
  const [interestedLoaded, setInterestedLoaded] = useState({})

  const isLoading = useSelector((state) => state.globalLoader.loading)
  const currentProperty = useSelector((state) => state.property.data)
  const propertySnap = useSelector((state) => state.property.snap)
  const proposalManager = useSelector((state) => state.property.proposals.manager)

  const proposalsLen = proposalManager?.getProposalsLen() ?? 0

  const isEdit = isMatchPath(window.location.pathname, routes.DASHBOARD_PROPOSALS_EDIT)
  const isModeDefault = currentProposal?.isModeDefault() ?? true

  const pageConfigs = useMemo(() => {
    const config = {
      title: 'Novo interessado',
    }

    if (isEdit) {
      config.title = 'Editar interessado'
    }
    return config
  }, [])

  const actions = actionDispatcher({
    setCurrentProperty,
    setCartItemById,
    setGlobalLoaderAction,
    setNavbarTitleAction,
  })

  const handleClickContinuar = async (formData) => {
    analyticsLogger.logEvent('cadastrar_proposta_continuar')
    actions.setGlobalLoaderAction(true)
    try {
      const proposalHelper = await proposalSaver({
        originSource: USER_SOURCE_PROPOSAL,
        proposalType: USER_TYPE_TENANT,
        manager: proposalManager,
        autoAnalysis: false,
        tenantFormData: {...interestedLoaded, ...formData},
        propertySnap,
        proposalId: currentProposal.getId(),
      })
      discordNotifyOwnerRegisteredProposal(currentProperty, formData)
      setTimeout(() => {
        actions.setGlobalLoaderAction(false)
        history.push(routeWithParams(routes.DASHBOARD_CREDIT_ANALYSIS, currentProposal.getId()))
      }, 1200)
    } catch (err) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(err, err.message))
    }
  }

  const handleAddInterestedClick = (id) => {
    history.push(`${routes.DASHBOARD_PROPOSALS_REGISTER}?proposal=${id}`)
  }

  const loadProposals = async () => {
    actions.setGlobalLoaderAction(true)
    const manager = await loadProposalWithManagerAndRedux()
    let proposal

    if (isEdit) {
      proposal = manager.findProposalByInterestedId(interestedId)
    } else {
      proposal = manager.findProposalById(proposalId)
    }

    if (proposal) {
      const interested = proposal.findInterestedById(interestedId)
      setProposal(proposal)
      setInterestedLoaded(interested)
    } else {
      alert('Proposta não encontrada.')
      history.push(routes.DASHBOARD_PROPOSALS_LIST)
    }
    actions.setGlobalLoaderAction(false)
  }

  useEffect(() => {
    actions.setNavbarTitleAction(pageConfigs.title)
    if (isDevEnv() && !isCypressEnabled()) {
      reset({
        name: 'Neoptolemus Marcianus',
        email: 'neoptolemus@gmail.com',
        birthdate: '01/01/2000',
        cpf: '04885177022',
        rg: '123456789',
        rg_issuing_agency: 'ssp-sp',
        phone: '(11) 12345-6789',
        marital_status: 'Solteiro',
        profession: 'Dev',
        occupation: 'Estatutário',
        income: '1111',
      })
    }
  }, [])

  useEffect(() => {
    loadProposals()
  }, [])

  return (
    <Page loading={isLoading}>
      {!isEdit && (
        <Typography variant="body2">
          Uma proposta pode ter um ou mais moradores e todas as pessoas que forem assinar o contrato deverão estar
          cadastradas na mesma proposta.
          <br />
          As rendas mensais declaradas por pessoas na mesma proposta serão somadas afim de compor a renda mínima para
          garantir o pagamento do aluguel.
        </Typography>
      )}

      <FormProvider {...formHook}>
        <Grid container alignItems="flex-start">
          <InviteForm
            user={interestedLoaded}
            proposalId={proposalId}
            // --------------------------------
            allowFullEdit={!isEdit}
            displayIncomeField={isModeDefault}
            displayUploads={isModeDefault}
            requireIdentityDoc={false}
            requireIncomeDoc={false}
          />
        </Grid>
      </FormProvider>

      <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
        <Button bgType="green" onClick={handleSubmit(handleClickContinuar)}>
          CONTINUAR
        </Button>
      </div>
    </Page>
  )
}

export default ProposalsRegister
