/** @module zipcodeSearch */
import axios from 'axios'
import {isTestEnv} from '@root/lib/envDetector'
import {onlyNumbers} from '@root/lib/intVal'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import store from '@root/redux/store'

import * as zipSearch from './zipcodeSearch' // precisa importar o proprio arquivo aqui, so assim da para mockar searchAddress la no teste

const {dispatch} = store
export const searchAddress = async (zip) => {
  const result = await axios.get(`https://viacep.com.br/ws/${zip}/json/`)
  const addressResult = result.data

  if (addressResult.erro) {
    return Promise.reject(new Error(`O CEP ${zip} não foi encontrado`))
  }

  return Promise.resolve({
    zip: onlyNumbers(addressResult.cep),
    street: addressResult.logradouro,
    neighborhood: addressResult.bairro,
    city: addressResult.localidade,
    state: addressResult.uf,
    uf: addressResult.uf,
  })
}

/**
 * @param {string|Event} eventOrCode
 * @return {string}
 */
export const getZipNum = (eventOrCode) => {
  const isZipCode = typeof eventOrCode === 'string' || typeof eventOrCode === 'number'
  const isEvent = eventOrCode.target && eventOrCode.target.blur

  let zipcode = ''

  if (isZipCode) {
    zipcode = onlyNumbers(eventOrCode)
  } else {
    zipcode = onlyNumbers(eventOrCode.target.value)
  }

  return zipcode
}

/**
 * @param {string} zip
 * @return {boolean}
 */
export const isValidZip = (zip) => zip.length === 8

/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback searchAddressCallback
 * @param {{zip:string,street:string,neighborhood: string,city: string,state: string}} addressData
 */

/**
 * @param {string|Event} eventOrCode
 * @param {searchAddressCallback} onSearchComplete
 * @return {Promise<void>}
 */
const zipHandler = async (eventOrCode, onSearchComplete) => {
  const zipcode = getZipNum(eventOrCode)

  if (isValidZip(zipcode)) {
    try {
      const addressResult = await zipSearch.searchAddress(zipcode)
      if (typeof onSearchComplete === 'function') {
        onSearchComplete(addressResult)
      }
    } catch (ex) {
      alert(ex.message)
    }
  }
}

export default zipHandler
