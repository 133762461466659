import {Dialog, DialogContent, DialogTitle, TextField} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {Close} from '@material-ui/icons'
import {strContains} from '@root/components/Contract/OwnerInfo'
import BankItem from '@root/components/Forms/Bank/BankItem'
import {banksMap} from '@root/lib/banksList'
import React, {useState} from 'react'

let intervalId
const BankPickerDialog = ({open, onChange, onClose}) => {
  const [bankTerm, setBankTerm] = useState('')
  const updateBankTerm = (ev) => {
    if (intervalId) {
      clearTimeout(intervalId)
    }
    intervalId = setTimeout((val) => setBankTerm(val), 600, ev.target.value)
  }
  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <div style={{position: 'relative', padding: 12}}>
        <Close
          onClick={onClose}
          fontSize="default"
          className="clickable"
          style={{position: 'absolute', top: 10, right: 10}}
        />
      </div>

      <DialogTitle>
        <Grid container>
          <Grid item xs={12}>
            Bancos disponíveis
          </Grid>
          <Grid item xs={12}>
            <TextField onChange={updateBankTerm} placeholder="encontre o seu banco...." style={{width: '100%'}} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {banksMap
              .filter((bankItem) => strContains(bankItem.label, bankTerm, true) || bankTerm == '')
              .map((bankItem) => (
                <BankItem key={bankItem.value} bank={bankItem} onChange={onChange} />
              ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default BankPickerDialog
