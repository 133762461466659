import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import {usePropertyList} from '@root/application/service/repository/property/PropertyRepository'
import {HeaderButton} from '@root/components/HeaderButton/HeaderButton'
import IconOpen from '@root/components/icons/IconOpen'
import IconPlus from '@root/components/icons/IconPlus'
import InProgressList from '@root/components/PropertyList/inProgress/InProgressList'
import OthersList from '@root/components/PropertyList/others/OthersList'
import useRD from '@root/hooks/useRD'
import actionDispatcher from '@root/lib/actionDispatcher'
import {clearBackLink, retriveBackLink} from '@root/lib/backToLink'
import storage from '@root/lib/storage'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {resetContractAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {resetPropertyFromSnap} from '@root/redux/property/property.actions'
import {setPropertyUIDForSurvey} from '@root/redux/survey/survey.actions'
import {STORAGE_PROPERTY_ID} from '@root/utils/constants'
import routes from '@root/utils/routes'
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import contractMapper from 'status-mapper/mapper/contract-mapper'

import {useStyles} from './styles'

const TAB_ID_PROGRESS = 0
const TAB_ID_BUILDING = 1

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  )
}

const StyledAppBar = withStyles({
  root: {
    boxShadow: 'none',
    zIndex: 1,
  },
})((props) => <AppBar {...props} position="static" />)

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{children: null}} />)

const StyledTab = withStyles((theme) => ({
  root: {
    'background': theme.purpleButton.bg,
    'color': theme.purpleButton.color,
    'borderRadius': 10,
    'textTransform': 'uppercase',
    'fontWeight': theme.typography.fontWeightRegular,
    'fontSize': theme.typography.pxToRem(15),
    'marginRight': theme.spacing(4),
    'border': 'none',
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

export const PropertyList = () => {
  const classes = useStyles()
  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const {isFilledFormInitial} = useRD()
  const [selectedTab, setSelectedTab] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()
  const propertiesList = usePropertyList()

  const cartListAll = useSelector((state) => state.cart.listAll)

  const inProgressList = useMemo(
    () =>
      propertiesList.response.filter((property) => contractMapper.assertStatus.isInProgress(property.computed.status)),
    [propertiesList.response],
  )
  const inProgressSize = inProgressList.length

  const othersList = useMemo(
    () =>
      propertiesList.response.filter((property) => !contractMapper.assertStatus.isInProgress(property.computed.status)),
    [propertiesList.response],
  )
  const othersSize = othersList.length

  const hasProperties = propertiesList.response.length > 0

  const handleTabChange = (event, tab) => {
    setSelectedTab(tab)
  }

  const onSelectProperty = (property) => {
    const propertySnap = cartListAll.find((snap) => snap.id === property.uid)
    dispatch(resetPropertyFromSnap(propertySnap))
    dispatch(resetContractAction())
    dispatch(setPropertyUIDForSurvey(property.uid))
    storage.save(STORAGE_PROPERTY_ID, property.uid)

    const backlink = retriveBackLink()
    const route = backlink ?? routes.DASHBOARD_ROOT
    clearBackLink()

    history.push(route)
  }

  function goToInsertProperty() {
    history.push(routes.REGISTER_PROPERTY_NEW)
  }

  function goToBankStatment() {
    history.push(routes.DASHBOARD_BANK_STATMENT)
  }

  function goToPaymentsList() {
    history.push(routes.DASHBOARD_PAYMENTS_LIST_MONTH)
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction('Meus Imóveis'))
  }, [])

  useEffect(() => {
    actions.setGlobalLoaderAction(propertiesList.isLoading)
  }, [propertiesList.isLoading])

  useEffect(() => {
    if (!propertiesList.isLoading && inProgressSize === 0) {
      setSelectedTab(TAB_ID_BUILDING)
    }
  }, [inProgressSize, propertiesList.isLoading])

  /*const isGuideVisible = useGuide()
  if (isGuideVisible) {
    return <Guide1 />
  }*/

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2}>
        <HeaderButton
          label="Cadastre o seu imóvel"
          icon={IconPlus}
          imageRef={'/assets/new/house_dashboard.png'}
          onClick={goToInsertProperty}
        />
        <HeaderButton
          label="Extrato"
          icon={IconOpen}
          imageRef={'/assets/new/bank_dashboard.png'}
          onClick={goToBankStatment}
        />
        <HeaderButton
          label="Controle Financeiro"
          icon={IconOpen}
          imageRef={'/assets/new/money_dashboard.png'}
          onClick={goToPaymentsList}
        />
      </Grid>

      {!hasProperties && (
        <Typography variant="h4" align="center">
          Nenhum imóvel cadastrado
        </Typography>
      )}

      {hasProperties && (
        <>
          <StyledAppBar>
            <StyledTabs value={selectedTab} onChange={handleTabChange}>
              <StyledTab label={`Contratos Ativos (${inProgressSize})`} />
              <StyledTab label={`Contratos em Edição (${othersSize})`} />
            </StyledTabs>
          </StyledAppBar>
          <TabPanel value={selectedTab} index={TAB_ID_PROGRESS}>
            <InProgressList properties={inProgressList} onSelectProperty={onSelectProperty} />
          </TabPanel>
          <TabPanel value={selectedTab} index={TAB_ID_BUILDING}>
            <OthersList properties={othersList} onSelectProperty={onSelectProperty} />
          </TabPanel>
        </>
      )}
    </div>
  )
}
