import {makeStyles} from '@material-ui/core/styles'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import amex from '@root/assets/new/cards/amex.png'
import aura from '@root/assets/new/cards/aura.png'
import diners from '@root/assets/new/cards/diners.png'
import discover from '@root/assets/new/cards/discover.png'
import elo from '@root/assets/new/cards/elo.png'
import hipercard from '@root/assets/new/cards/hipercard.png'
import jcb from '@root/assets/new/cards/jcb.png'
import mastercard from '@root/assets/new/cards/mastercard.png'
import visa from '@root/assets/new/cards/visa.png'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  img: {
    width: 32,
  },
}))

const creditCards = {
  mastercard,
  amex,
  aura,
  diners,
  discover,
  elo,
  hipercard,
  jcb,
  visa,
}

const CredidCardIcon = ({brand}) => {
  const isOtherCardBrand = !creditCards[brand]

  const classes = useStyles()

  if (isOtherCardBrand) {
    return <CreditCardIcon color="primary" />
  }

  return <img className={classes.img} src={creditCards[brand]} />
}

export default CredidCardIcon
