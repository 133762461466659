import React from 'react'

const BankItem = ({bank, onChange}) => {
  const handleSelected = () => {
    if (onChange) {
      onChange(bank)
    }
  }

  return (
    <div
      onClick={handleSelected}
      className="clickable"
      style={{padding: '8px 0px', margin: '8px 0', borderBottom: '1px solid #000'}}>
      {bank?.label}
    </div>
  )
}

export default BankItem
