const ASCII_TABLE_START = 96
export const convertionEnumerationTable = {
  'numeric': {
    label: 'Numerico 1',
    formatter: (numStr) => numStr,
  },
  'char': {
    label: 'Letra a)',
    formatter: (numStr) => String.fromCharCode(ASCII_TABLE_START + parseFloat(numStr)) + ') ',
  },
  'roman': {
    label: 'Romano I',
    formatter: (numStr) => numStr + '*',
  },
  'roman-lower': {
    label: 'romaninho i',
    formatter: (numStr) => numStr.toLowerCase() + '*',
  },
}
export const convertEnumeration = (num, type) => {
  if (convertionEnumerationTable[type]) {
    return convertionEnumerationTable[type].formatter(String(num))
  }

  return num
}
