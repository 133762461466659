import checkIcon from '@root/assets/icon_awesome_check.svg'
import Fakedoor from '@root/components/App/Dashboard/DashboardPages/Fakedoor/Fakedoor'
import FormItem from '@root/components/FormHelper/FormItem'
import actionDispatcher from '@root/lib/actionDispatcher'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import {validateRequired} from '@root/lib/simpleValidators'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {addReceipt, setReceiptsFromDatabase} from '@root/redux/receipts/receipts.actions'
import {setSurveyImages} from '@root/redux/survey/survey.actions'
import {TENANT_DASHBOARD_BLOCK_TITLES} from '@root/utils/constants'

import useStyles from '../styles'

const comprovantesTiposMap = {
  luz_alteracao_titularidade: 'Luz - Alteração de titularidade',
  gas_alteração_titularidade: 'Gás - Alteração de titularidade',
  agua_alteração_titularidade: 'Água - Alteração de titularidade',
  iptu_parcela_mensal: 'IPTU - Parcela mensal',
  iptu_parcela_unica: 'IPTU - Parcela única',
  outro: 'Outro',
}

const TenantDashboardReceipts = () => {
  const classes = useStyles()
  const history = useHistory()

  const {control, errors, getValues} = useForm()

  const receipts = useSelector((s) => s.receipts)
  const actions = actionDispatcher({
    setHeaderMainHeader,
    setFooterNotVisible,
    setGlobalLoaderAction,
    setSurveyImages,
    addReceipt,
    setReceiptsFromDatabase,
  })

  actions.setHeaderMainHeader()
  actions.setFooterNotVisible()

  useEffect(() => {
    if (receipts) {
      if (!receipts.property_uid) {
        return history.goBack()
      }
      // setReceiptsFromDatabase(receipts?.receipts)
    }
  }, [receipts, history])

  const handleDocChange = async (evt) => {
    if (evt.target.files.length > 0) {
      const files = Array.from(evt.target.files)

      const receiptType = getValues('receipt_type')

      actions.setGlobalLoaderAction(true)

      const uploadedFiles = await firebaseFilesUploader(files, receipts.property_uid, 'property/receipts')

      actions.addReceipt({type: receiptType ?? 'outro', images: [...uploadedFiles]})

      await propertyModel.saveOrUpdateReceiptsToDatabase(
        [...receipts.receipts, {type: receiptType ?? 'outro', images: [...uploadedFiles]}],
        receipts.property_uid,
      )

      actions.setGlobalLoaderAction(false)
    }
  }

  return (
    <Fakedoor title={TENANT_DASHBOARD_BLOCK_TITLES.comprovantes} hideWhatsappBtn>
      <p>Carregue todos os comprovantes aqui para que você e o proprietário possam acessar quando quiserem.</p>

      <FormItem
        control={control}
        errors={errors}
        type="select"
        label="Tipo de comprovante"
        name="receipt_type"
        options={[
          {value: 'luz_alteracao_titularidade', label: 'Luz - Alteração de titularidade'},
          {value: 'gas_alteração_titularidade', label: 'Gás - Alteração de titularidade'},
          {value: 'agua_alteração_titularidade', label: 'Água - Alteração de titularidade'},
          {value: 'iptu_parcela_mensal', label: 'IPTU - Parcela mensal'},
          {value: 'iptu_parcela_unica', label: 'IPTU - Parcela única'},
          {value: 'outro', label: 'Outro'},
        ]}
        rules={{validate: validateRequired}}
      />

      <div className={classes.uploadContainer}>
        <label htmlFor="survey-upload-file" className={classes.uploadBtnWrapper}>
          <span>CARREGAR COMPROVANTE</span>

          <input
            onChange={handleDocChange}
            type="file"
            id="survey-upload-file"
            accept="application/pdf,image/*"
            multiple
            capture
            style={{display: 'none'}}
          />
        </label>
      </div>

      <h3>Comprovantes enviados:</h3>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        {receipts.receipts.length ? (
          receipts.receipts.map((rec, idx) => (
            <div key={idx} style={{display: 'flex', flexDirection: 'column', marginBottom: '15px'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <h4 style={{margin: '0 8px 0 0'}}>{comprovantesTiposMap[rec.type]}</h4>
                <img src={checkIcon} alt="Check Icon" />
              </div>
              <ul style={{margin: '8px 0'}}>
                {rec.images.length &&
                  rec.images.map((img) => (
                    <li key={img.download_url}>
                      <a target="_blank" rel="noreferrer" href={img.download_url} className={classes.documentName}>
                        {img.name_original}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))
        ) : (
          <p>Sem comprovantes...</p>
        )}
      </div>
    </Fakedoor>
  )
}

export default TenantDashboardReceipts
