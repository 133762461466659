import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBtnWrapper: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'width': '100%',
    'marginBottom': '20px',
    'background': 'transparent',
    'cursor': 'pointer',
    '& span': {
      color: 'rgb(1, 1, 205) !important',
      textDecoration: 'underline',
      fontSize: '15px',
    },
  },
  documentNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  documentName: {
    maxWidth: '270px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: `${theme.extraColors.BLACK} !important`,
    textDecoration: 'underline !important',
  },
  noLinkDocument: {
    maxWidth: '270px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: `${theme.extraColors.BLACK} !important`,
  },
}))

const FilePickerMultipleNewProperty = ({label, filePrefix, docs, handleDocs, displayLinks = false}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  })

  const handleFileOnChange = async (evt) => {
    if (evt.target.files.length > 0) {
      const files = Array.from(evt.target.files)

      const filteredFiles = files.filter((file) => !docs[filePrefix].some((docsFile) => docsFile.name === file.name))

      if (!filteredFiles.length) return

      handleDocs({
        ...docs,
        [filePrefix]: [...docs[filePrefix], ...filteredFiles],
      })
    }
  }

  const handleDeleteFile = async (documentName) => {
    const filteredDocuments = docs[filePrefix].filter((doc) => doc.name !== documentName)

    handleDocs({
      ...docs,
      [filePrefix]: [...filteredDocuments],
    })
  }

  return (
    <>
      <div className={classes.uploadContainer}>
        <label htmlFor={filePrefix} className={classes.uploadBtnWrapper}>
          <span>{label}</span>
          <input
            ref={inputRef}
            onChange={handleFileOnChange}
            type="file"
            id={filePrefix}
            accept="application/pdf,image/*"
            multiple
            capture
            style={{display: 'none'}}
          />
        </label>
      </div>

      {displayLinks && docs?.[filePrefix].length ? (
        <Box bgType="gray">
          {docs[filePrefix].map((item, idx) => (
            <div key={idx} className={classes.documentNameWrapper}>
              <p className={classes.noLinkDocument}>{item.name}</p>
              <IconButton aria-label="deletar" color="primary" size="small" onClick={() => handleDeleteFile(item.name)}>
                <DeleteOutlineIcon fontSize="small" style={{color: 'red'}} />
              </IconButton>
            </div>
          ))}
        </Box>
      ) : null}
    </>
  )
}

export default FilePickerMultipleNewProperty
