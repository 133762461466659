import {Button} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import useStyles from '@root/components/App/Admin/styles'
import UserBasicData from '@root/components/App/Admin/UserBasicData'
import {Divider} from '@root/components/Divider/Divider'
import {INVITE_TYPE_FIADOR, INVITE_TYPE_TENANT} from '@root/components/InviteRegister/Invite'
import React from 'react'

const ProposalEntry = ({isSelected, proposalId, name, proposalData, onGetInvite}) => {
  const classes = useStyles()

  const handleGetInvite = (type) => () => {
    if (onGetInvite) {
      onGetInvite(type, proposalId)
    }
  }
  return (
    <>
      <h3 className={classes.inquilinoCardH3}>
        {isSelected && <CheckCircleOutlineIcon style={{color: '#44a22d', fontSize: 30}} />}
        Proposta {name} <small>({proposalId})</small>
      </h3>

      <>
        <Button
          variant="outlined"
          size="small"
          onClick={handleGetInvite(INVITE_TYPE_TENANT)}
          style={{marginBottom: 15, marginRight: 15}}>
          Convidar Inquilino
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleGetInvite(INVITE_TYPE_FIADOR)}
          style={{marginBottom: 15, marginRight: 15}}>
          Convidar Fiador
        </Button>
      </>

      {proposalData.interesteds.map((interested) => (
        <div key={interested.data.uid} className="panel-interested-list-item">
          <UserBasicData user={interested.data} type={interested.type} />
          <Divider dashed />
        </div>
      ))}
    </>
  )
}

export default ProposalEntry
