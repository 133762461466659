// Material UI
import Grid from '@material-ui/core/Grid'
// Images
import inquilinoImg1 from '@root/assets/inquilino_page_img_1.png'
import inquilinoImg2 from '@root/assets/inquilino_page_img_2.png'
import inquilinoImg3 from '@root/assets/inquilino_page_img_3.png'
import inquilinoImg4 from '@root/assets/inquilino_page_img_4.png'
import inquilinoImg5 from '@root/assets/inquilino_page_img_5.png'
import React from 'react'

// Components
import ImgContentContainer from './ImgContentContainer'

const MainFeaturesSection = () => {
  return (
    <Grid container justify="center" style={{marginBottom: '100px'}}>
      <ImgContentContainer
        imgFirst
        img={inquilinoImg1}
        alt="Avaliação de crédito"
        title="Avaliação de crédito"
        textOne="Faremos uma análise da sua proposta para que o proprietário tenha a segurança e transparência necessárias num contrato de locação."
      />
      <ImgContentContainer
        img={inquilinoImg2}
        alt="Contrato sob medida"
        title="Contrato sob medida"
        textOne="O contrato é o instrumento mais importante da locação e vai garantir que sua negociação e seus direitos estejam preservados."
      />
      <ImgContentContainer
        imgFirst
        img={inquilinoImg3}
        alt="Boletos automáticos"
        title="Boletos automáticos"
        textOne="Todos os meses será enviado o boleto do aluguel e também lembretes para você lembrar da data correta do pagamento."
      />
      <ImgContentContainer
        img={inquilinoImg4}
        alt="Seu aluguel organizado"
        title="Seu aluguel organizado"
        textOne="Todas as informações relacionadas ao imóvel alugado em um painel de controle exclusivo. Pagamentos efetuados, contrato, vistoria, datas de reajustes e muito mais."
      />
      <ImgContentContainer
        imgFirst
        img={inquilinoImg5}
        alt="Comunique-se por aqui"
        title="Comunique-se por aqui"
        textOne="Troque mensagens referentes ao imóvel através do chat e formalize todas as conversas."
      />
    </Grid>
  )
}

export default MainFeaturesSection
