import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import itemRenderer from '@root/components/FormHelper/itemRenderer'
import RequiredMark from '@root/components/FormHelper/RequiredMark'
import {dotParse} from '@root/lib/dotParse'
import PropTypes from 'prop-types'
import React from 'react'
import {Controller} from 'react-hook-form'

const inputsDisplay = {
  simple: ['radio', 'checkbox', 'checkbox-single', 'file'],
  hide: ['textfield', 'textarea', 'mask', 'rg', 'datepicker'],
}

const checkRequired = (required, optional, rules) => {
  let req = true
  if (rules !== undefined) {
    req = true
  } else {
    req = !optional || required
  }

  return req
}

/**
 * @component
 */
const FormLabel = ({label, type, required, radioLabelStyle}) => {
  let comp
  const isSimpleLabel = inputsDisplay.simple.indexOf(type) !== -1
  const isHiddenLabel = inputsDisplay.hide.indexOf(type) !== -1 || !label || label === ''

  if (isHiddenLabel) {
    comp = null
  } else if (isSimpleLabel) {
    comp = (
      <label
        style={radioLabelStyle}
        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated Mui-required Mui-required">
        {label}
        <RequiredMark required={required} />
      </label>
    )
  } else {
    comp = <InputLabel required={required}>{label}</InputLabel>
  }

  // console.log(label, type, required)
  return comp
}

const FormItem = (props) => {
  const {
    formItemId,
    name,
    label,
    type,
    required,
    optional,
    rules,
    fitContainer,
    defaultValue,
    errors,
    control,
    style,
    radioLabelStyle,
    textAreaStyle,
  } = props
  const realRequired = checkRequired(required, optional, rules)
  const elementId = (props.id ?? props.name).replace('.', '-')
  const errorVal = dotParse(errors, name)
  const hasError = errorVal !== undefined
  const errorMessage = errorVal?.message
  const formControlStyle = {
    width: `${fitContainer ? '100%' : undefined}`,
    ...style,
  }

  const renderFieldComponent = (controllerProps) =>
    itemRenderer(controllerProps, {...props, required: realRequired, id: elementId}, hasError)
  return (
    <FormControl error={hasError} style={formControlStyle}>
      <FormLabel label={label} type={type} required={realRequired} radioLabelStyle={radioLabelStyle} />
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={renderFieldComponent}
      />
      <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

const optionsArrayTypeNode = PropTypes.arrayOf(PropTypes.node)
const optionsArrayTypeCustom = PropTypes.arrayOf(
  PropTypes.shape({value: PropTypes.any.isRequired, label: PropTypes.string.isRequired}),
)
FormItem.defaultProps = {
  type: 'textfield',
  defaultValue: '',
  fitContainer: false,
  // required: true,
  // optional: false,
}
FormItem.propTypes = {
  type: PropTypes.oneOf([
    'textfield',
    'file',
    'textarea',
    'mask',
    'currency',
    'rg',
    'select',
    'radio',
    'checkbox',
    'checkbox-single',
    'datepicker',
  ]),
  fitContainer: PropTypes.bool,
  required: PropTypes.bool,
  optional: PropTypes.bool,

  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,

  options: PropTypes.oneOfType([optionsArrayTypeCustom]),
  maskInputProps: PropTypes.object,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default FormItem
