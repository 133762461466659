import {Grid} from '@material-ui/core'
import FormItem from '@root/components/FormHelper/FormItem'
import {getDefaultProps, getPropTypes} from '@root/components/Forms/Invite/invite-props'
import {companyOriginOptions} from '@root/lib/predefinedValuesOptions'
import {validateEmail, validateFullName, validatePhone, validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {CNPJ_FORMAT, PHONE_FORMAT} from '@root/utils/constants'

const SPACING = 2
const CompanyForm = ({allowFullEdit}) => {
  const formHookContext = useFormContext()
  const {control, errors} = formHookContext
  return (
    <Grid container spacing={SPACING} direction="row" justify="space-between">
      <Grid item xs={12} sm={4}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer={true}
          label="Nome do contato"
          name="name"
          rules={{validate: validateFullName}}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer={true}
          label="E-Mail do contato"
          name="email"
          InputProps={{readOnly: !allowFullEdit}}
          rules={{validate: validateEmail}}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <FormItem
          control={control}
          errors={errors}
          type="mask"
          fitContainer={true}
          label="Telefone do contato"
          name="phone"
          rules={{validate: validatePhone}}
          maskInputProps={{
            format: PHONE_FORMAT,
            disabled: !allowFullEdit,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          label="Razão social"
          name="company_real_name"
          rules={{validate: validateRequired}}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          label="Nome fantasia"
          name="company_name"
          rules={{validate: validateRequired}}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          type="mask"
          label="CNPJ"
          name="cnpj"
          rules={{
            validate: validateRequired,
          }}
          maskInputProps={{
            format: CNPJ_FORMAT,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          label="Inscrição estadual"
          name="state_registration"
          rules={{validate: validateRequired}}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          type="select"
          label="Natureza jurídica"
          name="company_origin"
          options={companyOriginOptions}
          rules={{validate: validateRequired}}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          label="Ramo de atividade"
          name="comercial_activity"
          rules={{validate: validateRequired}}
        />
      </Grid>
    </Grid>
  )
}

CompanyForm.defaultProps = getDefaultProps()
CompanyForm.propTypes = getPropTypes()

export default CompanyForm
