import {makeStyles} from '@material-ui/core/styles'

import InquilinoImage from '../../../assets/inquilino_page_image_new.png'

const useStyles = makeStyles((theme) => ({
  banner: {position: 'relative'},
  photoContainer: {
    marginLeft: '-1px',
    position: 'relative',
    display: 'flex',
    minHeight: '600px',
    width: '100%',
    background: `url(${InquilinoImage}) center / auto 480px no-repeat`,
    zIndex: 1,
    [theme.breakpoints.down(740)]: {
      background: `url(${InquilinoImage}) right / auto 480px no-repeat`,
    },
  },
  leftPinkDiv: {
    position: 'absolute',
    top: '90px',
    left: 0,
    height: '77%',
    width: '50%',
    backgroundColor: '#8301CD',
    transform: 'skewY(2deg)',
    zIndex: 0,
  },
  rightPinkDiv: {
    position: 'absolute',
    top: '90px',
    right: '2px',
    width: '50%',
    height: '77%',
    backgroundColor: '#8301CD',
    transform: 'skewY(-2deg)',
  },
  blackOverlayDiv: {
    width: '573px',
    height: '255px',
    position: 'absolute',
    top: '170px',
    left: '80px',
    padding: '20px',
    borderRadius: '14px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    filter: 'blur(2px)',
    [theme.breakpoints.down(1000)]: {
      width: '573px',
      height: '255px',
    },
    [theme.breakpoints.down(740)]: {
      width: '80%',
      height: '225px',
      top: '150px',
      left: '5%',
    },
    [theme.breakpoints.down(596)]: {
      height: '320px',
    },
  },
  contentDiv: {
    width: '573px',
    position: 'absolute',
    top: '170px',
    left: '80px',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.up('lg')]: {
      width: '573px',
      left: '80px',
    },

    [theme.breakpoints.down(769)]: {
      width: '80%',
      left: '5%',
    },
    [theme.breakpoints.down(740)]: {
      width: '80%',
      top: '170px',
      left: '5%',
      padding: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: 0,
    },
  },
  bannerTitle: {
    color: '#F6F5FA',
    width: '573px',
    marginBottom: '20px',
    fontWeight: 'bold',
    textShadow: '1px 2px #000000',
    [theme.breakpoints.down(1000)]: {
      fontSize: '28px',
      width: '573px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      textAlign: 'left',
      width: '90%',
    },
  },
  bannerSubtitle: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: '10px',
    width: '573px',
    [theme.breakpoints.down(1000)]: {
      fontSize: '18px',
      width: '540px',
    },
    [theme.breakpoints.down(740)]: {
      width: '90%',
    },
  },
}))

export default useStyles
