import fetcher from '@root/application/infra/api/fetcher'
import {propertyRepoInstance} from '@root/application/service/repository/property/PropertyRepository'
import {firestore} from '@root/firebase/firebase.utils'
import {useQuery} from '@root/hooks/urlHook'
import {isContractNeedToBeGenerated} from '@root/lib/contract/checkContractGeneration'
import {approveObservations} from '@root/lib/contract/observationsHelper'
import {
  isContractEditable,
  isProgressComplete,
  isProgressEmpty,
  updateProgressDataFromRedux,
} from '@root/lib/contract/progressChecker'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import {getNextStep} from '@root/lib/contract/stepWalker'
import {discordNotifyContractCreated} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import {isFullyEmpty} from '@root/lib/fullyEmpty'
import {addNotificationToProperty, createNotificationBody} from '@root/lib/notification'
import {USER_TYPE_OWNER} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import {setGlobalLoaderAction, setGlobalLoaderWithModeAction} from '@root/redux/loader/loader.actions'
import {LOADER_MODE_TEXT} from '@root/utils/constants'
import routes, {routeWithID} from '@root/utils/routes'
import dateParser from 'date-parser'
import {useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import contractMapper from 'status-mapper/mapper/contract-mapper'

const getInitialLoaderPayload = (subtitle) => ({
  title: 'Estamos preparando o seu contrato',
  subtitle,
})
const useContract = (property) => {
  const dispatch = useDispatch()
  const {hasParam} = useQuery()
  const propertyId = property?.uid
  const currentStep = useSelector((s) => s.contract?.step)
  const user = useSelector((s) => s.user.user)

  const getPropertyRef = () => firestore.collection(propertyModel.COLLECTION_NAME).doc(propertyId)

  const addNotification = async (text) => {
    const observationEntry = createNotificationBody({
      userId: user.uid,
      origin: USER_TYPE_OWNER,
      text,
    })
    await addNotificationToProperty(getPropertyRef(), observationEntry)
  }

  const generateContract = async (propertySnap, user, onGenerateFail) => {
    let contractResponse = {download_url: '#'}
    const loaderPayload = getInitialLoaderPayload('Criando arquivo')
    dispatch(setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, loaderPayload))
    try {
      const progressInfo = updateProgressDataFromRedux(property)
      await propertySnap.ref.update(progressInfo.path, progressInfo.payload)

      contractResponse = await propertyRepoInstance.createContract(propertySnap.data())
      discordNotifyContractCreated(user, propertySnap.id)

      await addNotification('Contrato criado')
    } catch (ex) {
      alert(errorHandler(ex))
      if (onGenerateFail) {
        onGenerateFail(ex)
      }
    }
    dispatch(setGlobalLoaderAction(false))

    return contractResponse
  }

  const mailTenant = async (userData) => {
    const loaderPayload = getInitialLoaderPayload('Notificando o inquilino')
    const proposalHelper = ProposalHelper.initWithProperty(property)
    const mainTenantFromProposal = proposalHelper.getInterestedRawAt(0)

    dispatch(setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, loaderPayload))
    await fetcher.post('/mailing', {
      code: 'm0024',
      property_id: propertyId,
    })
    let notificationText = 'Contrato enviado para o inquilino'
    if (userData?.email) {
      notificationText += ' no endereço ' + userData.email
    }
    await addNotification(notificationText)
    dispatch(setGlobalLoaderAction(false))
  }

  const pathNextStepAfterSave = () => {
    let nextStep = getNextStep()

    if (!nextStep.component) {
      nextStep = getNextStep()
    }

    let path = nextStep.path
    if (hasParam('backend')) {
      path = routeWithID(routes.CONTRACT_CREATED, propertyId)
    }
    return path
  }

  /**
   * @param {string} section
   * @param {string} path
   * @return {{[p: string]: []|unknown[]}}
   */
  const getObservationsUpdatedBySection = (section, path = 'contract.observations') => {
    return {
      [path]: approveObservations(section, property?.contract?.observations),
    }
  }

  /**
   * @param {object} payload
   * @return {*&{[p: string]: {updated_at: null, created_at: null}}}
   */
  const prepareContractStepUpdate = (payload) => {
    const progressInfo = updateProgressDataFromRedux(property)
    const dateCreated = property?.contract?.date_create ?? new Date()
    const updateData = {
      [progressInfo.path]: progressInfo.payload,
      ...getObservationsUpdatedBySection(currentStep.id),
      ...payload,
      'contract.date_create': dateCreated,
    }
    return updateData
  }

  const memoizedProps = useMemo(() => {
    const contract = property?.contract
    const status = contract?.status
    const isEditable = isContractEditable(property)
    const isStatusBuilding = contractMapper.assertStatus.isBuilding(status)
    const isStatusProgress = contractMapper.assertStatus.isInProgress(status)
    const isStatusApproved = contractMapper.assertStatus.isApproved(status)

    return {
      contractStatus: status,
      isBuilding: isStatusBuilding,
      isInProgress: isStatusProgress,
      isApproved: isStatusApproved,
      isContractEditable: isEditable,
      isContractBlocked: !isEditable,
      isContractProgressComplete: isProgressComplete(property),
      isContractProgressEmpty: isProgressEmpty(property),
      canContractBeGenerated: isContractNeedToBeGenerated(property),
      hasContractGenerated: !isFullyEmpty(property?.contract?.contract_file),
      validity: {
        start: dateParser(property?.contract?.validity?.start),
        end: dateParser(property?.contract?.validity?.end),
      },
    }
  }, [property])

  return {
    ...memoizedProps,
    generateContract,
    pathNextStepAfterSave,
    getObservationsUpdatedBySection,
    prepareContractStepUpdate,
    mailTenant,
  }
}

export default useContract
