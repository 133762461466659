/* eslint-disable no-undef */
import * as Sentry from '@sentry/react'
import {INVITE_TYPE_FIADOR, INVITE_TYPE_TENANT} from '@root/components/InviteRegister/Invite'
// import {analytics} from '@root/firebase/firebase.utils'
import {retriveBackLink} from '@root/lib/backToLink'
import {addBreadCrumb} from '@root/lib/errorHandler'

/*
Cola:
https://github.com/gozego/react-native-firebase/blob/f2958b860d870628c1264d9e07b47136cb45f9f3/dist/modules/analytics/index.js
https://firebase.google.com/docs/analytics/user-properties

  EVENTS:

  button_click
  share
  send_email
  login
  user_type
  sign_up
*/

export const MSG_ERRORS = {
  name: 'analytics.logEvent(): Primeiro argumento "name" é obligatorfio e deve ser do tipo STRING.',
  params: 'analytics.logEvent(): Segundo argumento (opcional) "params" deve ser do tipo OBJETO.',
  reserved: 'analytics.logEvent(): Nome do evento usado é reservado e nao pode ser usado.',
}

const ReservedEventNames = [
  'app_clear_data',
  'app_uninstall',
  'app_update',
  'error',
  'first_open',
  'in_app_purchase',
  'notification_dismiss',
  'notification_foreground',
  'notification_open',
  'notification_receive',
  'os_update',
  'session_start',
  'user_engagement',
]

class Analytics {
  /**
   * Logs an app event.
   * @param  {string} name
   * @param {object} params
   */
  logEvent(name, params = {}) {
    if (typeof name !== 'string') {
      throw new Error(MSG_ERRORS.name)
    }

    if (typeof params !== 'undefined' && typeof params !== 'object') {
      throw new Error(MSG_ERRORS.params)
    }

    // check name is not a reserved event name
    if (ReservedEventNames.includes(name)) {
      throw new Error(MSG_ERRORS.reserved)
    }

    this.logFirebase(name, params)
    this.logSentry(name, params)
  }

  logFirebase(name, params) {
    // Event names can be up to 40 characters long, may only contain alphanumeric characters and underscores ("_"), and must start with an alphabetic character.
    this.getUsertype(name, params)
    try {
      // analytics.logEvent(name, params)
      this.logTagManager(name, params)
      if (params?.alternative_event_name) {
        const {alternative_event_name, ...otherParams} = params
        // analytics.logEvent(alternative_event_name, otherParams)
        this.logTagManager(alternative_event_name, otherParams)
      }
    } catch (ex) {
      Sentry.captureException(ex)
    }
  }

  logTagManager(name, params) {
    gtag({event: name, ...params})
  }

  logSentry(name, params) {
    addBreadCrumb(name, params)
  }

  getUsertype(name, params) {
    if (name === 'sign_up') {
      const backLink = retriveBackLink()
      let user_type = 'proprietario'

      if (backLink) {
        if (backLink.indexOf('cadastro/convite/' + INVITE_TYPE_FIADOR) !== -1) {
          user_type = 'fiador'
        } else if (backLink.indexOf('cadastro/convite/' + INVITE_TYPE_TENANT) !== -1) {
          user_type = 'inquilino'
        }
      }

      params.user_type = user_type
    }
  }

  //  https://firebase.google.com/docs/analytics/user-properties
  /**
   * popular user properties
   * @param {object} data
   */
  setUserData(data) {
    // analytics.setUserProperties(data)
  }

  setUserID(id) {
    this.logTagManager({user_id: id})
  }

  setScreenView(screenName) {
    this.logTagManager('pageview', {page: {path: screenName ?? window.location.pathname + window.location.hash}})
  }
}

const analyticsLogger = new Analytics()
export default analyticsLogger
