/** @module contractLoader */
import {getURL} from '@root/hooks/urlHook'
import {loadPropertyFromStore} from '@root/lib/property/loadProperty'
import userModel from '@root/model/userModel'
import {setContractTenantFromDatabaseAction} from '@root/redux/contract/contract.actions'
import store from '@root/redux/store'

let loadContractMessage = ''
/**
 * Remover o quanto antes
 * @deprecated
 */
export const loadContract = async (user, allowTenant = false) => {
  return true
  // store.dispatch(setGlobalLoaderAction(true))
  // eslint-disable-next-line no-unreachable
  const state = store.getState()

  if (user) {
    let contract = state.contract ?? {}
    let cartListAll = state.cart.listAll
    let propertyId = contract.propertyId
    let property = contract.property
    let propertySnap
    const url = getURL()

    if (url.searchParams.has('id')) {
      propertyId = url.searchParams.get('id')
    }

    try {
      await loadPropertyFromStore(propertyId, user, allowTenant)
      return true
    } catch (err) {
      return err.message
    }
  }
}

/**
 * @deprecated
 * @param user
 * @param {boolean} allowTenant Permite que o IQ visualize a tela, caso contrario não é permitido
 * @return {Promise<string|DocumentSnapshot<DocumentData>|undefined>}
 */
export const loadContractWithTenant = async (user, allowTenant = false) => {
  const property = await loadContract(user, allowTenant)
  const tenantId = property?.tenant

  if (!tenantId) {
    return 'no-tenant'
  }

  try {
    const tenant = await userModel.getById(tenantId)
    store.dispatch(setContractTenantFromDatabaseAction(tenant.data()))
    return tenant
  } catch (error) {
    loadContractMessage = 'Inquilino não encontrado'
    return undefined
  }
}

/**
 * Remover o quanto antes
 * @deprecated
 */
export const loadContractWithAllowTenant = async (user) => loadContractWithTenant(user, true)

/**
 * Remover o quanto antes
 * @deprecated
 */
export const handleContractLoad = async (loadFunc, user, history) => {
  if (user) {
    const response = await loadFunc(user)

    if (!response) {
      alert(loadContractMessage)
      return history.replace('/')
    }

    return response
  }
}
