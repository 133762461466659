import {ANSWER_NONE} from '@root/components/App/Cadastro/cadastro-components/TenantMoreAbout/DefaultRadioGroup'
import produce from 'immer'
import {SigninTypes} from '@root/redux/signin/signin.types'
import {UserActionTypes} from '@root/redux/user/user.types'

import reducerMatch from '../../lib/reducerMatch'
import TenantRegisterActionTypes from './tenantRegister.types'

// For now type definitions are here
// https://github.com/MobenDev/database-structure#user
export const createEmptyTenant = () => ({
  uid: '',
  name: '',
  email: '',
  cpf: '',
  rg: '',
  phone: '',
  marital_status: '',
  profession: '',
  occupation: '',
  income: 0,
  recurring_debts: 0,
  birthdate: '',
  income_sources: {
    salario: false,
    prolabore: false,
    dividendos: false,
    alugueis: false,
    aposentaria: false,
    bolsas: false,
    renda: false,
    investimentos: false,
    outros: false,
    outros_resposta: '',
  },
  terms_accepted: {
    register: {
      status: null,
      accepted_at: null,
    },
  },
  address: {},
  cards: [],
  properties: [],
  documents: {},
  fiador: null,
  bank_accounts: [],
  credit_analysis: [],
  representatives: null,
  register_type: '',
  more_about: {
    in_rent: ANSWER_NONE,
    how_long_in_rent: ANSWER_NONE,
    reason_to_move: '',
    contact_reference: null,
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    pets: ANSWER_NONE,
    pets_details: '',
    smoking: ANSWER_NONE,
    more_peoples: ANSWER_NONE,
    peoples_quantity: '',
    hobby: '',
    living_beings: {},
  },
  register_by: 'owner',
  active: true,
  is_owner: false,
  is_tenant: false,
  is_fiador: false,
  is_active_owner: false,
  is_active_tenant: false,
  is_active_fiador: false,
  origin_source: 'unknown',
  has_proposal: false,
  tenant_of: null,
  fiador_of: null,
  proposal_of: [],

  created_at: new Date(),
  last_access: new Date(),
  payment_history_owner: [],
  property_groups: {},
  nationality: '',
  tenant_invite_link: {
    path: null,
    property: null,
    proposal: null,
    analysis: null,
    inviteType: null,
  },
  tenant_concluded: false,
  tenant_additional_documents: [],
  user_role: '',
})

export const createTenantRegisterInitialState = () => ({
  document: undefined,
  tenant: createEmptyTenant(),
  tenantsList: [],
})
const initialState = createTenantRegisterInitialState()

const reducers = {
  [SigninTypes.LOG_OUT]: () => createTenantRegisterInitialState(),

  [UserActionTypes.SET_USER_NULL]: (state, {payload}) => {
    return createTenantRegisterInitialState()
  },

  [TenantRegisterActionTypes.SET_TENANT_FULL]: (state, action) => {
    return action.payload
  },

  [TenantRegisterActionTypes.SET_TENANT]: (state, {payload}) => {
    return {
      ...state,
      tenant: {
        ...state.tenant,
        ...payload.tenant,
      },
    }
  },

  [TenantRegisterActionTypes.SET_TENANT_DOCUMENT]: (state, {payload}) => {
    return {
      ...state,
      document: payload.file,
    }
  },

  [TenantRegisterActionTypes.CLEAR_TENANT]: () => initialState,

  [TenantRegisterActionTypes.ADD_TENANT_TO_LIST]: (state, {payload}) => {
    const {tenant, document} = payload
    return produce(state, (draft) => {
      draft.tenantsList.splice(state.tenantsList.length, 0, {
        tenant,
        document,
      })
    })
  },
}

export default reducerMatch(reducers, initialState)
