import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import {dotParse} from '@root/lib/dotParse'
import React from 'react'

const GROWTH_COLOR_UP = '#5a8c46'
const GROWTH_COLOR_DOWN = '#8c4646'
const useGrowthBlockStyle = makeStyles((theme) => {
  return {
    growthAmount: {
      fontSize: 16,
    },
    curCount: {
      fontSize: 16,
    },
    prevCount: {
      fontSize: 13,
    },
    colorUp: {
      color: GROWTH_COLOR_UP,
    },
    colorDown: {
      color: GROWTH_COLOR_DOWN,
    },
  }
})
const EMPTY_VALUE = 0
const GrowthBlock = ({label, growth, path, successDirection = 'up'}) => {
  const classes = useGrowthBlockStyle()
  const growthAmount = dotParse(growth, `growth.${path}`, EMPTY_VALUE)
  const isGrowthDirectionUp = successDirection == 'up'
  const isGrowthDirectionDown = successDirection == 'down'
  const isGrowthAmountUp = growthAmount > 0
  const isGrowthAmountDown = growthAmount < 0

  let GrowthIcon = React.Fragment
  let growthStyles = [classes.growthAmount]
  let growthIconClass

  if ((isGrowthAmountUp && isGrowthDirectionUp) || (isGrowthAmountDown && isGrowthDirectionDown)) {
    GrowthIcon = TrendingUpIcon
    growthStyles.push(classes.colorUp)
    growthIconClass = classes.colorUp
  } else if ((isGrowthAmountUp && isGrowthDirectionDown) || (isGrowthAmountDown && isGrowthDirectionUp)) {
    GrowthIcon = TrendingDownIcon
    growthStyles.push(classes.colorDown)
    growthIconClass = classes.colorDown
  }

  const growthClass = growthStyles.join(' ')
  return (
    <>
      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h3">
              {label}
            </Typography>

            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid container item direction="column" xs={4}>
                <Grid item>
                  <span className={classes.curCount}>{dotParse(growth, `current.${path}`, EMPTY_VALUE)}</span>
                </Grid>
                <Grid item>
                  <span className={classes.prevCount}>{dotParse(growth, `prev.${path}`, EMPTY_VALUE)}</span>
                </Grid>
              </Grid>

              <Grid container item direction="row" xs={8}>
                <Grid item>
                  <span className={growthClass}>%{growthAmount.toFixed(2)}</span>
                </Grid>
                <Grid item>
                  <GrowthIcon className={growthIconClass} />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default GrowthBlock
