import {Backdrop, makeStyles} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {CIRCULAR_PROGRESS_ZINDEX, LOADER_MODE_SPINNER, LOADER_MODE_TEXT} from '@root/utils/constants'

const useStyles = makeStyles({
  backdrop: {
    zIndex: CIRCULAR_PROGRESS_ZINDEX,
    color: '#0101CD',
  },
})

const SimpleLoader = () => <CircularProgress color="inherit" />
const StepedLoader = ({title, subtitle}) => {
  return (
    <Grid container justify="center" alignContent="center">
      <Grid
        container
        item
        component={Paper}
        justify="center"
        alignItems="center"
        direction="column"
        xs={11}
        sm={8}
        md={3}
        elevation={0}
        style={{padding: 18, alignItems: 'center'}}>
        <h3>{title}</h3>
        <h5>{subtitle}</h5>
        {/*baita coisa sinista mas quando usa um Typography aqui parece que da um reset em algumas coisas do thema.*/}
        {/*Por ex na tela /verificacao-sms alguns textos ficam vermelhos, secondary color*/}
        {/*<Typography>{title}</Typography>*/}
        {/*<Typography>{}</Typography>*/}
        <br />
        <SimpleLoader />
      </Grid>
    </Grid>
  )
}

const LocalLoader = (props) => {
  const classes = useStyles()
  const {mode, data, loading} = props

  const LoaderComp = useMemo(() => {
    let comp = SimpleLoader
    if (mode === LOADER_MODE_TEXT) {
      comp = StepedLoader
    }

    return comp
  }, [mode])

  return (
    <>
      {props.children}
      <Backdrop className={classes.backdrop} open={loading} style={{position: props.absolute ? 'absolute' : ''}}>
        <LoaderComp {...data} />
      </Backdrop>
    </>
  )
}

LocalLoader.defaultProps = {
  loading: false,
  absolute: false,
  mode: LOADER_MODE_SPINNER,
}

LocalLoader.propTypes = {
  loading: PropTypes.bool,
  absolute: PropTypes.bool,
  children: PropTypes.node,
  mode: PropTypes.oneOf([LOADER_MODE_SPINNER, LOADER_MODE_TEXT]),
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }),
}

export default LocalLoader
