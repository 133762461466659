import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import ContractRenewContent from '@root/components/page/DefaultConfirmationPage/confirmations-content/ContractRenewContent'
import InterestedAnalysisConsent from '@root/components/page/DefaultConfirmationPage/confirmations-content/InterestedAnalysisConsent'
import ReceiptCondominium from '@root/components/page/DefaultConfirmationPage/confirmations-content/ReceiptCondominium'
import ReceiptOwnership from '@root/components/page/DefaultConfirmationPage/confirmations-content/ReceiptOwnership'
import PageMini from '@root/components/PageMini'
import analyticsLogger from '@root/firebase/analytics.utils'
import useWhatappLinker from '@root/hooks/useWhatappLinker'
import React from 'react'
import {MOBEN_EMAIL} from '@root/utils/constants'

const useStyles = makeStyles((theme) => ({
  analseConfRoot: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& > h5': {
      margin: '30px 0',
      color: theme.palette.secondary.main,
    },
  },
  contactContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > a': {
      'fontWeight': 700,
      'display': 'flex',
      'alignItems': 'center',
      '& > svg': {
        marginRight: 10,
      },
      '&:not(:last-child)': {
        marginBottom: 18,
      },
    },
  },
}))

const pageToContent = {
  'analise': <InterestedAnalysisConsent />,
  'renovacao': <ContractRenewContent />,
  'comprovante-titularidade': <ReceiptOwnership />,
  'comprovante-condominio': <ReceiptCondominium />,
}

export const ConfirmationTitle = ({title = 'Tudo certo por aqui!'}) => {
  return (
    <Typography variant="h5" align="center">
      {title}
    </Typography>
  )
}
const DefaultConfirmationPage = ({match, title = 'Tudo certo por aqui!'}) => {
  const classes = useStyles()
  const waLinker = useWhatappLinker()
  const page = match.params.page
  const contentComp = pageToContent[page] ?? null

  const openWhatsApp = () => {
    analyticsLogger.logEvent('faleconosco_whatsapp')
    waLinker.open(waLinker.createMoben())
  }

  const openEmail = () => {
    analyticsLogger.logEvent('faleconosco_email')
    window.open(`mailto:${MOBEN_EMAIL}`, '_blank')
  }

  return (
    <PageMini>
      <div className={classes.analseConfRoot}>
        {contentComp}
        {!contentComp && ''}

        <Typography variant="h5" align="center">
          Dúvidas? <br />
          Entre em contato
        </Typography>

        <div className={classes.contactContainer}>
          <Typography className="clickable  " variant="body1" component="a" onClick={openWhatsApp}>
            <WhatsAppIcon color="primary" /> Contato via WhatsApp
          </Typography>

          <Typography className="clickable" variant="body1" component="a" onClick={openEmail}>
            <AlternateEmailIcon color="primary" /> {MOBEN_EMAIL}
          </Typography>
        </div>
      </div>
    </PageMini>
  )
}

export default DefaultConfirmationPage
