import 'firebase/firestore'

import {Grid, Typography} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import {Button} from '@root/components/Button/Button'
import InviteForm from '@root/components/Forms/InviteForm'
import IconCheck from '@root/components/icons/IconCheck'
import MySelfScreen from '@root/components/InviteRegister/screen/MySelfScreen'
import RegisterFinishedScreen from '@root/components/InviteRegister/screen/RegisterFinished'
import Loader from '@root/components/Loader/Loader'
import PageMini from '@root/components/PageMini'
import actionDispatcher from '@root/lib/actionDispatcher'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import ProposalManager from '@root/lib/contract/ProposalManager'
import {discordNotifyNewProposal} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import {proposalSaver} from '@root/lib/proposalSaver'
import storage from '@root/lib/storage'
import {USER_TYPE_FIADOR, USER_TYPE_TENANT} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {resetPropertyFromSnap} from '@root/redux/property/property.actions'
import {setCurrentUser} from '@root/redux/user/user.actions'
import {STORAGE_BACKLINK, STORAGE_IS_TENANT, USER_SOURCE_INVITE} from '@root/utils/constants'
import routes, {routeWithParams} from '@root/utils/routes'

export const INVITE_TYPE_FIADOR = 'fiador'
export const INVITE_TYPE_TENANT = 'inquilino'
export const INVITE_HAS_ANALYSIS = 'com-analise'
export const INVITE_NO_ANALYSIS = 'sem-analise'
export const INVITE_OLD_HAS_ANALYSIS = 'analysis'
export const INVITE_OLD_NO_ANALYSIS = 'noanalysis'

const expressModeOptions = [INVITE_OLD_NO_ANALYSIS, INVITE_OLD_HAS_ANALYSIS, INVITE_NO_ANALYSIS]
const Invite = ({match}) => {
  const history = useHistory()

  const inviteType = match.params.inviteType
  const propertyId = match.params.propertyId
  const proposalId = match.params.proposalId
  const creditAnalysisParam = match.params.creditAnalysis ?? INVITE_HAS_ANALYSIS
  // basicamente o `analysis` e `noanalysis` foram depreciados, mas como ja tem muito link por ai com esses valores, mantive aqui
  const isProposalModeExpress = expressModeOptions.indexOf(creditAnalysisParam) !== -1
  const isProposalModeDefault = !isProposalModeExpress

  const isTenant = match.params.inviteType === INVITE_TYPE_TENANT
  const isFiador = match.params.inviteType === INVITE_TYPE_FIADOR

  const formHook = useForm()
  const {handleSubmit, getValues} = formHook

  const [builder, setBuilder] = useState()
  const [registerFinished, setRegisterFinished] = useState(false)
  const [itsMySelf, setItsMySelf] = useState(false)
  const [hasLoadError, setLoadError] = useState(false)

  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setCurrentUser,
    setNavbarTitleAction,
    resetPropertyFromSnap,
  })

  const mySelfValidation = (id) => user.uid === id

  const saveProperty = async (formData) => {
    actions.setGlobalLoaderAction(true)

    let interestedType
    // const {docs, ...tenantData} = getValues()

    const property = builder.property.data //imovel que achou pelo id na url
    const analysisMode = isProposalModeExpress ? ProposalHelper.MODE_EXPRESS : ProposalHelper.MODE_DEFAULT
    const proposalManager = ProposalManager.initWithProperty(property)
    const interestedProposal = proposalManager.findProposalByInterestedId(user.uid)

    if (proposalId) {
      proposalManager.selectProposalById(proposalId)
    } else if (!interestedProposal) {
      const proposalHelper = new ProposalHelper()
      proposalHelper.initEmptyProposal()
      proposalHelper.setMode(analysisMode)

      proposalManager.addProposal(proposalHelper)
      proposalManager.selectProposalById(proposalHelper.getId())
    } else {
      proposalManager.selectProposalById(interestedProposal.getId())
    }

    if (inviteType === INVITE_TYPE_TENANT) {
      interestedType = USER_TYPE_TENANT
    } else if (inviteType === INVITE_TYPE_FIADOR) {
      interestedType = USER_TYPE_FIADOR
    }

    const selectedProposal = proposalManager.getSelected()
    const propertySnap = builder.property.snap

    try {
      const mergedUser = {
        ...user,
        ...formData,
        is_tenant: true,
        origin_source: 'invite',
        tenant_invite_link: {
          property: propertyId,
          proposal: selectedProposal.getId(),
          analysis: creditAnalysisParam,
          inviteType: inviteType,
          path: routeWithParams(
            routes.REGISTER_INVITE_ENTRY,
            inviteType,
            propertyId,
            creditAnalysisParam,
            selectedProposal.getId(),
          ),
        },
      }

      await proposalSaver({
        manager: proposalManager,
        proposalId: selectedProposal.getId(),
        originSource: USER_SOURCE_INVITE,
        proposalType: interestedType,
        propertySnap,
        tenantFormData: mergedUser,
      })

      storage.remove(STORAGE_IS_TENANT)
      storage.remove(STORAGE_BACKLINK)

      delete mergedUser.docs
      delete mergedUser.snap
      discordNotifyNewProposal(mergedUser, builder.property.data, creditAnalysisParam)
    } catch (ex) {
      alert(errorHandler(ex))
      actions.setGlobalLoaderAction(false)
      return
    }

    if (isProposalModeExpress) {
      setRegisterFinished(true)
    } else if (isTenant) {
      history.push(routes.REGISTER_MYDATA_INCOME)
    } else {
      setRegisterFinished(true)
    }
    actions.setGlobalLoaderAction(false)
  }

  useEffect(() => {
    const run = async () => {
      try {
        const propertySnap = await propertyModel.getById(propertyId)
        const property = propertySnap.data()
        if (mySelfValidation(property.ower_id)) {
          setItsMySelf(true)
          return
        }

        setBuilder({
          property: {
            snap: propertySnap,
            ref: propertySnap.ref,
            data: property,
          },
        })
        // actions.resetPropertyFromSnap(propertySnap)
      } catch (ex) {
        errorHandler(ex)
        setLoadError(true)
      }
    }
    actions.setNavbarTitleAction(isFiador ? 'Fiador' : 'Inquilino')
    run()
  }, [])

  if (hasLoadError) {
    return (
      <>
        <Container maxWidth="md">
          <Grid container style={{marginTop: 80}}>
            <Typography variant="h6" style={{fontWeight: '600', display: 'block'}}>
              Ops.....
              <br />
              Não foi possível carregar os dados para essa pagina.
            </Typography>
          </Grid>
        </Container>
      </>
    )
  }

  if (itsMySelf) {
    return <MySelfScreen inviteType={inviteType} />
  }

  if (!builder) {
    return <Loader loading={true} />
  }

  if (registerFinished) {
    return <RegisterFinishedScreen />
  }

  return (
    <PageMini displayBackButton={false}>
      <FormProvider {...formHook}>
        <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <InviteForm
            currentProperty={builder.property.data}
            user={user}
            displayUploads={false}
            displayIncomeField={false}
          />
          <Button
            m="20px 0 10px"
            w="168px"
            h="48px"
            fz="15pt"
            bgType="green"
            icon={IconCheck}
            onClick={handleSubmit(saveProperty)}>
            Continuar
          </Button>
        </div>
      </FormProvider>
    </PageMini>
  )
}
Invite.propTypes = {
  match: PropTypes.any,
}
export default Invite
