import Text from '@root/components/Layout/Typography/Text'
import BaseModal from '@root/components/Modal/BaseModal'
import useModalControl from '@root/components/Modal/useModalControl'
import PropTypes from 'prop-types'
import React, {useState} from 'react'

/**
 *
 * @param {string} [title]
 * @param {string} [question]
 * @param {()=>void} [onOk]
 * @param {()=>void} [onCancel]
 * @return {{modal: JSX.Element, close:()=>void, open:()=>void, setTitle:(string)=>void, setQuestion:(string)=>void}}
 */
const useConfirmModal = (title, question, onOk, onCancel) => {
  const modalControl = useModalControl(onOk, onCancel)

  const [_title, setTitle] = useState(title ?? '')
  const [_question, setQuestion] = useState(question ?? '')

  const handleOk = () => {
    modalControl.close()
    if (onOk) {
      onOk()
    }
  }

  const handleCancel = () => {
    modalControl.close()
    if (onCancel) {
      onCancel()
    }
  }

  return {
    open: modalControl.open,
    close: modalControl.close,
    setTitle,
    setQuestion,
    modal: (
      <BaseModal
        title={_title}
        isOpen={modalControl.visibility}
        cancelLabel="cancelar"
        okAction={handleOk}
        cancelAction={handleCancel}
        size="md"
        mainStyle={{}}>
        <Text>{_question}</Text>
      </BaseModal>
    ),
  }
}

export default useConfirmModal
