import {Grid, Typography} from '@material-ui/core'
import houseIcon from '@root/assets/como_funciona_icon_2.png'
import contractIcon from '@root/assets/como_funciona_icon_5.png'
import questionIcon from '@root/assets/Icon_awesome-question.svg'
import moneyIcon from '@root/assets/Icon_material-attach-money.svg'
import folderIcon from '@root/assets/Icon_metro-folder-open.svg'
import idIcon from '@root/assets/Icon_metro-profile.svg'
import anonymUser from '@root/assets/profile_avatar.png'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import analyticsLogger from '@root/firebase/analytics.utils'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import addressDisplay, {ADDRESS_VERBOSITY_COMPLETE} from '@root/lib/addressDisplay'
import {sanitizeEventTitle} from '@root/lib/createAlternativeEventName'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentPropertyId} from '@root/redux/property/property.actions'
import {setPropertyUIDForReceipts} from '@root/redux/receipts/receipts.actions'
import routes from '@root/utils/routes'

import useStyles from './styles'

/*
const propertyId = 'lRjMYIvfqkoCdBOxjU6k'
const userId = 'n7nmcxkSBi1wA23HHKLnicQMtSqs' 
*/

const TenantDashboard = () => {
  const {getParam} = useQuery()
  const history = useHistory()
  const classes = useStyles()
  const propertyIdUrl = getParam('uid')
  const isLoggedUser = useSelector((state) => state.user.isLogged)
  const currentUser = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  // const propertySnap = useSelector((s) => s.property.snap)

  const actions = actionDispatcher({
    setHeaderMainHeader,
    setGlobalLoaderAction,
    setPropertyUIDForReceipts,
    setCurrentPropertyId,
    setFooterNotVisible,
  })

  actions.setHeaderMainHeader()
  actions.setFooterNotVisible()

  useEffect(() => {
    let hasPermission

    if (currentUser?.tenant_of?.id) {
      hasPermission = currentUser.tenant_of.id === propertyIdUrl
    }

    if (currentUser?.fiador_of?.id) {
      hasPermission = currentUser.fiador_of.id === propertyIdUrl
    }

    if (!isLoggedUser || !hasPermission) {
      return history.push(routes.DASHBOARD_ROOT)
    }
    actions.setCurrentPropertyId(propertyIdUrl)
  }, [])

  const handleClick = (clickedBlock) => {
    analyticsLogger.logEvent('button_click', {
      button_name: clickedBlock,
      alternative_event_name: `click_tenant_dashboard_${sanitizeEventTitle(clickedBlock, true)}`,
    })

    if (clickedBlock === 'comprovantes') {
      actions.setPropertyUIDForReceipts(propertyIdUrl)
    }

    history.push(`${routes.DASHBOARD_TENANT_ROOT}/${clickedBlock.replace(' ', '-')}`)
  }

  return (
    <DashboardWrapper title="" id="dashboard-tenant">
      <Grid
        container
        align="center"
        className={classes.tenantDashboardBlock}
        onClick={() => handleClick('dados imovel')}>
        <Grid item xs={12} sm={10} align="center">
          <Typography variant="h6">Dados do imóvel</Typography>
          <br />
          <Typography variant="body1">{addressDisplay(property?.address, ADDRESS_VERBOSITY_COMPLETE)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} style={{display: 'flex'}} justify="center" alignItems="center">
          <img src={houseIcon} alt="icone casa" style={{display: 'block', width: 50, height: 40}} />
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center">
        <Grid
          item
          xs={12}
          sm={5}
          align="center"
          onClick={() => handleClick('contrato')}
          className={classes.tenantDashboardBlock}>
          <img src={contractIcon} alt="icone contrato" className={classes.tenantDashboardIcon} />
          <Typography className={classes.dashboardBlockTitle}>contrato</Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          align="center"
          onClick={() => handleClick('pagamentos')}
          className={classes.tenantDashboardBlock}>
          <img src={moneyIcon} alt="icone contrato" className={classes.tenantDashboardIcon} />
          <Typography className={classes.dashboardBlockTitle}>pagamentos</Typography>
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center">
        <Grid
          item
          xs={12}
          sm={5}
          align="center"
          onClick={() => handleClick('proprietario')}
          className={classes.tenantDashboardBlock}>
          <img src={anonymUser} alt="icone contrato" className={classes.tenantDashboardProfileIcon} />
          <Typography className={classes.dashboardBlockTitle}>proprietário</Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          align="center"
          onClick={() => handleClick('comprovantes')}
          className={classes.tenantDashboardBlock}>
          <img src={folderIcon} alt="icone contrato" className={classes.tenantDashboardIcon} />
          <Typography className={classes.dashboardBlockTitle}>comprovantes</Typography>
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center">
        <Grid
          item
          xs={12}
          sm={5}
          align="center"
          onClick={() => handleClick('documentos adicionais')}
          className={classes.tenantDashboardBlock}>
          <img src={idIcon} alt="icone contrato" className={classes.tenantDashboardIcon} />
          <Typography className={classes.dashboardBlockTitle}>documentos adicionais</Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          align="center"
          onClick={() => handleClick('duvidas')}
          className={classes.tenantDashboardBlock}>
          <img src={questionIcon} alt="icone contrato" className={classes.tenantDashboardIcon} />
          <Typography className={classes.dashboardBlockTitle}>dúvida</Typography>
        </Grid>
      </Grid>
    </DashboardWrapper>
  )
}

export default TenantDashboard
