import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  tenantAvatarPhoto: {
    margin: '0 !important',
    width: '55px',
    height: '55px',
  },
  tenantGoToIconWrapper: {
    position: 'absolute',
    top: 8,
    right: 8,
    transform: 'translate(0, calc(50% - 4px))',
  },
  tenantAvatarInfo: {
    display: 'flex',
    height: '100%',
  },
  tenantInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sectionItem: {
    '& h5': {
      margin: '4px 0',
      fontWeight: 600,
    },
    '& h6': {
      marginBottom: '8px',
    },
    '& button + button': {
      marginLeft: theme.margin[1],
    },
  },
  boxContainer: {
    backgroundColor: '#f5f4f4',
    padding: '8px',
    borderRadius: '10px',
    margin: '0.4rem 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
