import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React from 'react'

const buildItensList = (options) => {
  if (!Array.isArray(options)) {
    return null
  }

  return options.map((item) => {
    if (React.isValidElement(item)) {
      return item
    }

    const {value, label} = item
    return (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    )
  })
}

const SelectRender = (controllerProps, helpers, formItemProps) => {
  const {label, options} = formItemProps
  const {hasError, applyShrink, forwardOnChange, forwardOnBlur} = helpers
  const {value} = controllerProps
  return (
    <Select
      inputRef={helpers.inputRef}
      label={label}
      autoWidth={true}
      displayEmpty={true}
      {...formItemProps}
      onChange={forwardOnChange}
      onBlur={forwardOnBlur}
      value={value || ''}>
      {buildItensList(options)}
    </Select>
  )
}

export default SelectRender
