import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  section: {
    '& #numero_matricula': {
      width: '79.5%',
    },
    '& > p': {
      // marginBottom: theme.margin[4],
    },
    '& h6': {
      width: '100%',
      fontWeight: 600,
    },
  },
  cepContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'marginBottom': '15px',
    '& > :first-child': {
      width: '25%',
      marginRight: theme.margin[2],
    },
  },
  addressContainer: {
    'display': 'flex',
    'alignItems': 'center',
    '& svg': {
      width: '60px',
      height: '60px',
      fill: theme.extraColors.GRAY[500],
    },
    '& ul': {
      'margin': 0,
      'padding': '0 0 0 1.5rem',
      'display': 'flex',
      'flexDirection': 'column',
      '& li:first-child h6': {
        fontWeight: 600,
      },
      '& li + li': {
        marginTop: theme.margin[2],
      },
    },
  },
  numeroComplementoContainer: {
    'margin': '15px 0',
    'display': 'flex',
    '& > :first-child': {
      width: '30%',
      marginRight: theme.margin[4],
    },
  },
  specsContainer: {
    // 'margin': '5px 0 0',
    'display': 'flex',
    '& > div': {
      'width': '24%',
      '& + div': {
        marginLeft: theme.margin[4],
      },
      '&:first-child': {
        width: '35%',
      },
    },
  },
  anexarArquivosBoxStyles: {
    justifyContent: 'center',
    padding: theme.margin[1],
  },
  amountsContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& > div': {
      width: '116px',
      transform: 'translateY(12px)',
    },
  },
  comercialActivityContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& > div': {
      width: '80%',
      transform: 'translateY(12px)',
    },
  },
  valorTotalContainer: {
    'width': '70%',
    'margin': '0 auto 20px',
    'display': 'flex',
    'justifyContent': 'center',
    '& .wrapper': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      '& h4, h6': {
        width: '100%',
        textAlign: 'center',
      },
      '& h6': {
        margin: '8px 0 18px',
      },
    },
  },
  concluirButtonContainer: {
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'marginBottom': theme.margin[4],
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  body2Wrapper: {
    'width': '100',
    'display': 'flex',
    'justifyContent': 'center',
    '& > p': {
      maxWidth: '47ch',
    },
  },
  anexarArquivoWrapper: {
    position: 'relative',
    marginBottom: 20,
  },
  anexarArquivoFile: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  salvarButtonWrapper: {
    width: 170,
    margin: '0 auto',
  },
}))
