/** @module loadPropertyFromQuery */
import {getURL} from '@root/hooks/urlHook'
import {isDevEnv} from '@root/lib/envDetector'
import propertyModel from '@root/model/propertyModel'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {resetPropertyFromSnap} from '@root/redux/property/property.actions'
import store from '@root/redux/store'

/**
 * Remover o quanto antes
 * @deprecated
 */
const loadPropertyFromQuery = async () => {
  return true
  // eslint-disable-next-line no-unreachable
  const propertyId = getURL().searchParams.get('id')
  const storeProperty = store.getState().property

  if (!propertyId) {
    return false
  }
  if (storeProperty?.currentPropertyId === propertyId) {
    return true
  }

  store.dispatch(setGlobalLoaderAction(true))
  const propertySnap = await propertyModel.getById(propertyId)
  store.dispatch(resetPropertyFromSnap(propertySnap))
  store.dispatch(setGlobalLoaderAction(false))

  if (isDevEnv()) {
    console.log(`[LPFQ]currentProperty: ${propertySnap?.id}`)
  }

  // return propertySnap.data()
  return true
}

export default loadPropertyFromQuery
