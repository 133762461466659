export const ApplicationTypes = {
  ACCEPT_COOKIES: 'ACCEPT_COOKIES',
  SET_GUIDE_VISIBILITY: 'SET_GUIDE_VISIBILITY',
  APP_READY: 'APP_READY',
  SET_ROUTE_INFO: 'SET_ROUTE_INFO',
  SET_NAVBAR_TITLE: 'SET_NAVBAR_TITLE',
  SET_USE_MAIN_PADDING: 'SET_USE_MAIN_PADDING',
  ADD_OPTIMIZE_VARIANT: 'ADD_OPTIMIZE_VARIANT',
  SET_HEADERLESS: 'SET_HEADERLESS',
}
