import {Checkbox} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, {useEffect, useState} from 'react'

// const checkboxRender = (helpers, controllerProps, formItemProps) => {
// const CheckboxRender = (helpers) => {
const CheckboxRender = (controllerProps, helpers, formItemProps) => {
  const {forwardOnChange} = helpers
  const options = formItemProps.options ?? []
  const optionsLen = options.length
  const [breakPoints, setBreakPoints] = useState({xs: 12, sm: 12, md: 12})

  // const {forwardOnChange} = helpers
  const [selecteds, setSelecteds] = useState({})

  const handleChange = (evt) => {
    const selectedsUpdate = {
      ...selecteds,
      [evt.target.name]: evt.target.checked,
    }
    if (controllerProps.onChange) {
      forwardOnChange({
        ...evt,
        target: {
          ...evt.target,
          value: selectedsUpdate,
        },
      })
      /*controllerProps.onChange({
        ...evt,
        target: {
          ...evt.target,
          value: selectedsUpdate,
        },
      })*/
    }

    setSelecteds(selectedsUpdate)
  }

  useEffect(() => {
    const initialSelecteds = {}
    const alreadySelecteds = controllerProps.value
    if (alreadySelecteds) {
      setSelecteds(alreadySelecteds)
    } else {
      options.forEach(({value}) => (initialSelecteds[value] = false))
      setSelecteds(initialSelecteds)
    }
  }, [options, controllerProps.value])

  useEffect(() => {
    if (optionsLen > 1) {
      setBreakPoints((bk) => ({...bk, sm: 6, md: 4}))
    }
  }, [options])

  return (
    <Grid container>
      {options.map(({label, value}, idx) => {
        const name = value
        return (
          // <Grid key={IDBIndex} item xs={12} sm={breakPoints.sm} md={breakPoints.md}>
          <Grid key={idx} item xs={12} sm={12} md={6}>
            <label style={{display: 'flex', alignItems: 'center'}}>
              <Checkbox
                id={formItemProps.id}
                name={name}
                value={true}
                checked={!!selecteds[name]}
                onChange={handleChange}
              />
              {label}
            </label>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default CheckboxRender
