/** @module propertyModel */
import 'firebase/firestore'

import fetcher, {objectToQueryString} from '@root/application/infra/api/fetcher'
import {paymentRepoInstance} from '@root/application/service/repository/payment/PaymentRepository'
import {firestore} from '@root/firebase/firebase.utils'
import errorHandler from '@root/lib/errorHandler'
import {formatBrCurrencyToFloat} from '@root/lib/moneyFormater'
import {PROPERTY_STATUS_PENDING} from '@root/model/cartModel'
import {getCollectionById, getCollectionFromRef} from '@root/model/firestorePromisify'
import {SEND_MAIL_FAIL, SEND_MAIL_SUCCESS} from '@root/utils/constants'
import dateParser from 'date-parser'
import firebase from 'firebase/app'
import produce from 'immer'
import {contractMapper} from 'status-mapper'

export const OCCUPATION_EMPTY = 'empty'
export const OCCUPATION_FULL = 'occupied'
const COLLECTION_NAME = 'property'

const occupationInfo = {
  [OCCUPATION_FULL]: {
    label: 'Ocupado',
    price: 49.9,
  },
  [OCCUPATION_EMPTY]: {
    label: 'Vazio',
    price: 49.9,
  },
}

const getOccupationByType = (type) => {
  return (
    occupationInfo[type] || {
      label: '',
      price: 0,
    }
  )
}

const prepareDoc = (doc) => {
  if (doc?.created_at) {
    return {
      ...doc,
      created_at: dateParser(doc.created_at),
    }
  }
  return null
}

const getCollectionRef = (id) => {
  return firestore.collection(COLLECTION_NAME).doc(id)
}

const prepareForDatabase = (property) => {
  return Object.assign(
    {},
    produce(property, (draft) => {
      draft.rent_amount = formatBrCurrencyToFloat(property.rent_amount)
      draft.iptu_amount = formatBrCurrencyToFloat(property.iptu_amount)
      draft.condominium_amount = formatBrCurrencyToFloat(property.condominium_amount)
      // draft.total_amount = draft.rent_amount + draft.iptu_amount + draft.condominium_amount
      draft.taxpayer = property.taxpayer ?? ''

      // draft.deed = prepareDoc(property.deed)
      // draft.power_attorney = prepareDoc(property.power_attorney)
      // draft.contract_document = prepareDoc(property.contract_document)
      // draft.document = prepareDoc(property.document)

      if (!property?.address?.complement) {
        draft.address = {
          ...property.address,
          complement: '',
        }
      }
      if (property?.address) {
        draft.address = {
          ...property.address,
          formatted: `${property.address.street}, ${property.address.street_number} - ${
            property.address.neighborhood
          } - ${property.address.city}/${property.address.state} - ${property.address.zip} ${
            property.address.complement ? ' - ' + property.address.complement : ''
          }`,
        }
      }

      // usando o zip por que o address e um objeto e sempre vai estar disponivel, logo somente property.address sempre vai dar true e formatar o endereco com um monte de undefined, ou nao....
      if (property?.address?.zip) {
        draft.address = {
          ...property.address,
          formatted: `${property.address.street}, ${property.address.street_number} - ${
            property.address.neighborhood
          } - ${property.address.city}/${property.address.state} - ${property.address.zip} ${
            property.address.complement ? ' - ' + property.address.complement : ''
          }`,
        }
      }
    }),
  )
}

/**
 *
 * @param {string} propertyUid
 * @param {Object} newFiled
 * @return boolean
 */
const addSignedContract = (propertyUid, newField) => {
  try {
    getCollectionRef(propertyUid).set(newField, {merge: true})
    // console.log(`propriedade com uid  = ${propertyUid} foi atualizada com sucesso.`)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

/**
 *
 * @param property
 * @return {Promise<DocumentSnapshot<DocumentData>>}
 */
const saveToDatabase = async (property) => {
  const exists = property.uid !== null && property.uid !== undefined
  const collection = firestore.collection(COLLECTION_NAME)
  let propertyDb
  let propertyUpdate = prepareForDatabase(property)

  if (exists) {
    const doc = collection.doc(property.uid)
    await doc.update(propertyUpdate)
    propertyDb = await doc.get()
  } else {
    propertyDb = await collection.add(propertyUpdate)
    propertyUpdate.uid = propertyDb.id
    await propertyDb.update(propertyUpdate)
    propertyDb = await propertyDb.get()
  }

  return Promise.resolve(propertyDb)
}

/**
 * @param {string} id
 * @return {Promise<DocumentSnapshot<DocumentData>>}
 */
const getById = async (id) => {
  return getCollectionById(COLLECTION_NAME, id)
}

/**
 * @param {string} id
 * @return {Promise<DocumentData>}
 */
const getDataById = async (id) => {
  const snap = await getById(id)
  if (snap.exists) {
    return snap.data()
  }
}

const getByStatus = async (userId, status) =>
  getCollectionFromRef(
    firestore.collection(COLLECTION_NAME).where('status', '==', status).where('ower_id', '==', userId),
  )

/**
 * @param {string} propertyId
 * @param {string} userId
 * @return Promise<void>
 */
const deletePending = (propertyId, userId) => {
  return firestore
    .collection(COLLECTION_NAME)
    .where('status', '==', PROPERTY_STATUS_PENDING)
    .where('ower_id', '==', userId)
    .where('uid', '==', propertyId)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete()
      })
    })
}

/**
 * @param {string} userId
 * @return {Object}
 */
const getByOwnerId = async (userId) => {
  // try {
  const properties = await firestore.collection(COLLECTION_NAME).where('ower_id', '==', userId).get()
  if (properties.empty) {
    return []
  }

  const usersRef = firestore.collection('users')

  const propertiesFromDB = []

  for (const doc of properties.docs) {
    const curProp = doc.data()
    let tenant
    if (curProp.tenant) {
      tenant = await usersRef.doc(curProp.tenant).get()
      if (tenant) {
        tenant = tenant.data()
      }
    }

    propertiesFromDB.push({
      ...curProp,
      uid: doc.id,
      tenant: tenant,
    })
  }

  return propertiesFromDB
  /*} catch (error) {
    return error.message
  }*/
}

/**
 * Criar array com todos os imoveis do owner/proprietario
 * @param {string} userId
 * @return {Array<property>}
 */
const getPropertiesOwned = async (userId) => {
  let propertiesOwned = []

  await firestore
    .collection(COLLECTION_NAME)
    .where('ower_id', '==', userId)
    .get()
    .then((querySnap) => {
      querySnap.forEach((doc) => {
        propertiesOwned.push(doc.data())
      })
    })
    .catch((error) => console.log(error.message))

  return propertiesOwned
}

/**
 * Retorna contagem de imóveis do owner/proprietario
 * @param {string} userId
 * @return {number}
 */
const getPropertiesOwnedCount = async (userId) => {
  let propertiesCount

  await firestore
    .collection(COLLECTION_NAME)
    .where('ower_id', '==', userId)
    .get()
    .then((querySnap) => {
      propertiesCount = querySnap.docs.length
    })
    .catch((error) => console.log(error.message))

  return propertiesCount
}

/**
 * Criar array com todos os ids dos imoveis do owner/proprietario
 * @param {string} uid user.uid
 * @return {Array<string>} property.uid
 */
const getAllPropertiesIdsOwned = async (uid) => {
  const result = await getPropertiesOwned(uid)
  return result.map((property) => property.uid)
}

/**
 * Retorna todos os imóveis com contrato ativo (contract.status == 'in_progress')
 * e pagamento por boleto Moben (contract.payment_method.option == 'boleto').
 * @return {Array<Property>} property
 */
const getPropertiesWithActivePayment = async () => {
  let result = []

  const querySnap = await firestore
    .collection(COLLECTION_NAME)
    .where('contract.status', '==', contractMapper.status.INPROGRESS)
    .get()

  querySnap.forEach((doc) => {
    result.push(doc.data())
  })

  return result
}

/**
 * retorna propriedade pelo inquilino
 * @param {string} tenantId tenant uid
 * @return {Object} property
 */
const getPropertyByTenant = async (tenantId) => {
  let result

  await firestore
    .collection(COLLECTION_NAME)
    .where('tenant', '==', tenantId)
    .get()
    .then((querySnap) => {
      querySnap.forEach((doc) => {
        result = doc.data()
      })
    })
    .catch((error) => console.log(error.message))

  return result
}

const updateContractObservations = async (uid, txt) => {
  const propertyRef = firestore.collection(COLLECTION_NAME)
  let updatedProperty = await getById(uid)
  updatedProperty = updatedProperty.data()
  updatedProperty.contract.observations = txt
  propertyRef.doc(uid).set(updatedProperty, {merge: true})
}

/**
 * @deprecated
 */
const updatePropertyContractProgress = async (uid, stepName, substepToUpdate) => Promise.resolve('deprecated')

/**
 * @param {Object} property - objeto a ser atualizado
 * @param {int} idx - index do step
 */
const updateStepStatus = async (property, idx) => {
  const propertyRef = firestore.collection(COLLECTION_NAME)
  let updatedProperty = property
  updatedProperty.track_progress[idx].status = 'done'
  propertyRef.doc(property.uid).set(updatedProperty, {merge: true})
}

/**
 * @param {string} id
 * @param {string} path
 * @param {Object} updateData
 * @return {Promise<void>}
 */
const updateById = async (id, path, updateData) => {
  const propertyRef = firestore.collection(COLLECTION_NAME)
  return await propertyRef.doc(id).update(path, updateData)
}

const propertiesOwnedAndRented = async (userId) => {
  const propertiesOwnedList = await getByOwnerId(userId)
  const propertyRented = await getPropertyByTenant(userId)
  if (propertyRented) {
    propertiesOwnedList.push(propertyRented)
  }
  // return propertiesOwnedList.filter((prop) => prop.status == PROPERTY_STATUS_PAID)
  return propertiesOwnedList
}

/**
 * @param {string[]} uids uids to delete
 */
const bulkDelete = async (uids) => {
  if (!uids) {
    return
  }

  const result = await firestore.collection(COLLECTION_NAME).where('uid', 'in', uids).get()
  const removes = result.docs.map((doc) => doc.ref.delete())

  return await Promise.all(removes)
}

const saveOrUpdateSurveyToDatabase = async (surveyObj, property_uid) => {
  const surveyCollectionRef = firestore.collection('survey')

  if (property_uid) {
    const surveyRef = surveyCollectionRef.doc(property_uid)
    const surveySnap = await surveyRef.get()

    if (surveySnap.exists) {
      return await surveyRef.update(surveyObj)
    } else {
      const surveyToBeSaved = {
        property_uid,
        entering: {
          created_at: surveyObj.entering?.created_at ?? null,
          scheduled_for: surveyObj.entering?.scheduled_for ?? null,
          images: surveyObj.entering?.images ?? [],
        },
        leaving: {
          created_at: surveyObj.leaving?.created_at ?? null,
          scheduled_for: surveyObj.leaving?.scheduled_for ?? null,
          images: surveyObj.leaving?.images ?? [],
        },
      }
      return await surveyCollectionRef.doc(property_uid).set(surveyToBeSaved)
    }
  }
}

const saveOrUpdateReceiptsToDatabase = async (receiptsObjArr, property_uid) => {
  const receiptsCollectionRef = firestore.collection('receipts')

  if (property_uid) {
    const receiptsRef = receiptsCollectionRef.doc(property_uid)
    const receiptsSnap = await receiptsRef.get()

    if (receiptsSnap.exists) {
      return await receiptsRef.update({receipts: [...receiptsObjArr]})
    } else {
      const receiptsToBeSaved = {
        property_uid,
        receipts: [...receiptsObjArr],
      }
      return await receiptsCollectionRef.doc(property_uid).set(receiptsToBeSaved)
    }
  }
}

/**
 * @param {string} email
 * @param {string} userId
 * @param {string} propertyId
 * @return Promise<string|void>
 */
const fetchInviteByEmail = async (email, userId, propertyId) => {
  try {
    await fetcher.post('/mailing', {
      code: 'm0010',
      email: email,
      owner_id: userId,
      property_id: propertyId,
    })
    await firestore
      .collection(COLLECTION_NAME)
      .doc(propertyId)
      .update({invite_emails: firebase.firestore.FieldValue.arrayUnion(email)})
    return SEND_MAIL_SUCCESS
  } catch (error) {
    return errorHandler(error, SEND_MAIL_FAIL)
  }
}

/**
 * @param {string} propertyId
 * @param {string} userId
 * @param {boolean} [createRaw] default false
 * @return {Promise<*>}
 */
const fetchCreateContract = (propertyId, userId, createRaw = false) => {
  let endpoint = '/generateContractPDF'

  if (createRaw) {
    endpoint += '?rawonly=1'
  }

  return fetcher.post(endpoint, {
    user_id: userId,
    property_id: propertyId,
  })
}

/**
 * @param propertyId
 * @param userId
 * @return {Promise<*>}
 */
const fetchCreateContractRaw = (propertyId, userId) => {
  return fetchCreateContract(propertyId, userId, true)
}

/**
 * @param {string} propertyId
 * @param {string} userId
 * @param {{id:string payment_id:string, amount:number, description:string, due_date:string, is_silent:boolean}} updateObj
 * @return {Promise<*>}
 *
 * @see PaymentRepository.changePayment()
 */
const fetchChangePayment = paymentRepoInstance.changePayment

/**
 * @param {string} propertyId
 * @param {string} userId
 * @return {Promise<*>}
 */
const fetchCreatePaymentsList = (propertyId, userId) => {
  return fetcher.post('/contractPlay', {
    user_id: userId,
    property_id: propertyId,
  })
}

/**
 * @param {string} propertyId
 * @param {string} paymentId
 * @return {Promise<*>}
 */
const fetchPaymentData = (propertyId, paymentId) => {
  const params = objectToQueryString({property_id: propertyId, payment_id: paymentId})
  return fetcher.get('/payment/info?' + params)
}

/**
 * @param {string} propertyId
 * @param {string} dateName
 * @param {Date} dateValue
 * @return {Promise<*>}
 */
const fetchUpdateDate = (propertyId, dateName, dateValue) => {
  return fetcher.post('/admin/set-date', {
    property_id: propertyId,
    date_name: dateName,
    date_value: dateValue.toISOString(),
  })
}

/**
 * @param {string} propertyId
 * @param {'yes'|'not'} trousseau
 * @return {Promise<*>}
 */
const updateTrousseau = (propertyId, trousseau) => {
  return getCollectionRef(propertyId).update('more_about.trousseau', trousseau)
}

const propertyModel = {
  COLLECTION_NAME,
  OCCUPATION_EMPTY,
  OCCUPATION_FULL,
  occupationInfo,
  prepareForDatabase,
  getOccupationByType,
  saveToDatabase,
  getById,
  getDataById,
  getByStatus,
  deletePending,
  addSignedContract,
  getByOwnerId,
  getPropertiesOwned,
  getPropertiesOwnedCount,
  getAllPropertiesIdsOwned,
  updatePropertyContractProgress,
  updateContractObservations,
  getPropertyByTenant,
  updateStepStatus,
  updateById,
  propertiesOwnedAndRented,
  bulkDelete,
  saveOrUpdateSurveyToDatabase,
  saveOrUpdateReceiptsToDatabase,
  getPropertiesWithActivePayment,
  fetchInviteByEmail,
  fetchCreateContract,
  fetchCreateContractRaw,
  fetchChangePayment,
  fetchCreatePaymentsList,
  fetchPaymentData,
  fetchUpdateDate,
  updateTrousseau,
}

export default propertyModel
