import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

import RadioItem from './RadioItem'

export const ANSWER_YES = 'yes'
export const ANSWER_NO = 'no'
export const ANSWER_NONE = 'no_response'

const DefaultRadioGroup = (props) => {
  return (
    <div style={{marginTop: '20px'}}>
      <Typography variant="h6" style={{fontWeight: '600', ...props.otherTitleStyles}}>
        {props.title}
      </Typography>

      <RadioGroup
        onChange={props.onChange}
        name={props.name}
        style={{display: 'flex', flexDirection: 'row', ...props.otherStyles}}>
        {props.customTitleOne && (
          <RadioItem
            title={props.customTitleOne}
            value={props.customValueOne}
            otherRadioItemStyles={props.otherRadioItemStyles}
            checked={props.defaultValue === props.customValueOne}
          />
        )}
        {props.customTitleTwo && (
          <RadioItem
            title={props.customTitleTwo}
            value={props.customValueTwo}
            otherRadioItemStyles={props.otherRadioItemStyles}
            checked={props.defaultValue === props.customValueTwo}
          />
        )}

        <RadioItem title="Sim" value={ANSWER_YES} hide={props.hideYes} />
        {props.afterYes}

        <RadioItem title="Não" value={ANSWER_NO} hide={props.hideNo} />
        {props.afterNo}

        <RadioItem title="Prefiro não responder" value={ANSWER_NONE} hide={props.hideNone} />
        {props.afterNone}
      </RadioGroup>
    </div>
  )
}

DefaultRadioGroup.defaultValue = {
  hideYes: false,
  hideNo: false,
  hideNone: false,
}

DefaultRadioGroup.propTypes = {
  hideYes: PropTypes.bool,
  hideNo: PropTypes.bool,
  hideNone: PropTypes.bool,
  afterYes: PropTypes.node,
  afterNo: PropTypes.node,
  afterNone: PropTypes.node,
  otherStyles: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.any.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  otherTitleStyles: PropTypes.object,
  otherRadioItemStyles: PropTypes.object,
  customTitleOne: PropTypes.string,
  customValueOne: PropTypes.string,
  customTitleTwo: PropTypes.string,
  customValueTwo: PropTypes.string,
}

export default DefaultRadioGroup
