import {Card, CardContent} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {useAdLeads} from '@root/application/service/repository/ad/AdRepository'
import {useBankRepoStatement} from '@root/application/service/repository/bank/BankRepository'
import {Divider} from '@root/components/Divider/Divider'
import IconOpen from '@root/components/icons/IconOpen'
import IconWhatsApp from '@root/components/icons/IconWhatsApp'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import Title from '@root/components/Layout/Typography/Title'
import Page from '@root/components/Page'
import ListItem from '@root/components/PropertyList/ListItem'
import {useStyles} from '@root/components/PropertyList/styles'
import {useQuery} from '@root/hooks/urlHook'
import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import {translate} from '@root/lib/intl/translations'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

const AdLeads = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const property = useSelector((s) => s.property.data)
  const propertyId = useQuery().getParam('property_id', property.uid)

  const leads = useAdLeads(propertyId)

  const openMail = (lead) => {
    window.open(`mailto:${lead.email}`)
  }
  const openWhatsapp = (lead) => {
    window.open(lead.phone_whatsapp, '_blank')
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction('Interessados'))
  }, [])

  return (
    <Page loading={leads.isLoading} showContent={leads.hasListingEntries}>
      <TextBlock>
        Essa é a lista de interessados recebidos através dos anúncios nos principais portais do mercado, Viva Real, ZAP
        e OLX
      </TextBlock>
      <TextBlock>
        Clique no número de telefone ou email para entrar em contato com o interessado. Lembre-se, quanto mais rápida
        for a abordagem maiores as chances de fechar o negócio.
      </TextBlock>

      <Grid container>
        {leads.response.map((lead) => (
          <Grid key={lead.uid} container xs={12} sm={6} md={4}>
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.contentWrapper}>
                <Grid container item direction="column" justify="space-between">
                  <ListItem size={12} label={lead.created_at_formatted} value="" />
                  <Grid container item>
                    <Grid container item direction="row" spacing={2}>
                      <ListItem label="Nome" value={lead.name} />
                      {lead.has_phone && (
                        <ListItem
                          label="Telefone"
                          value={
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              className="clickable"
                              onClick={() => openWhatsapp(lead)}>
                              <Grid item>{ListItem.defaultValueComponent(lead.phone_formatted)}</Grid>
                              <Grid item>
                                &nbsp;
                                <IconWhatsApp />
                              </Grid>
                            </Grid>
                          }
                        />
                      )}
                      <ListItem
                        size={12}
                        className="clickable"
                        label="E-Mail"
                        value={lead.email}
                        onClick={() => openMail(lead)}
                      />

                      {lead.message && <ListItem label="Informações adicionais" value={lead.message} size={12} />}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Page>
  )
}

AdLeads.propTypes = {}
AdLeads.defaultProps = {}

export default AdLeads
