/** @module calcContractLengthMonths */
import {differenceInMonths, isWithinInterval} from 'date-fns'
import {MIN_CONTRACT_LENGTH} from '@root/utils/constants'

const calcContractLengthMonths = (start, end) => {
  return differenceInMonths(end, start)
}

export const validateContractLength = (start, end) => calcContractLengthMonths(start, end) >= MIN_CONTRACT_LENGTH

export const isDateInRange = (start, end, date) => {
  return isWithinInterval(date, {start, end})
}

export default calcContractLengthMonths
