import fetcher from '@root/application/infra/api/fetcher'

const sendMail = async (code, params) => await fetcher.post('/mailing', {code, ...params})

/**
 * @param {string} payload.property_id
 * @param {string} payload.proposal_id
 * @param {string} payload.interested_id
 * @return {Promise<*>}
 */
export const mailAnalysisConsentRequest = async (payload) => sendMail('m0047', payload)
