import {Grid} from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import AddressForm from '@root/components/Forms/AddressForm'
import BaseUserForm from '@root/components/Forms/Invite/BaseUserForm'
import CompanyForm from '@root/components/Forms/Invite/CompanyForm'
import RepresentativeSumaryItem from '@root/components/Forms/Invite/RepresentativeSumaryItem'
import useStyles from '@root/components/Forms/stylesTenantForm'
import {Box} from '@root/components/Layout/Container/Box/Box'
import BaseModal from '@root/components/Modal/BaseModal'
import {convertDateToBr} from '@root/lib/dateParser'
import {userRegisterOptions} from '@root/lib/predefinedValuesOptions'
import {safeUserReset} from '@root/lib/safeReset'
import {validateRequired} from '@root/lib/simpleValidators'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import registrationTypeMapper from 'status-mapper/mapper/registration-type-mapper'

const REPRESENTATIVES_FORM_NAME = 'representatives'
const InviteForm = ({
  user,
  allowFullEdit,
  incomeText,
  displayUploads,
  displayAddress,
  displayIncomeField,
  requireIdentityDoc,
  requireIncomeDoc,
  defaultRegistrationType,
}) => {
  const userFormPersonalProps = {
    user,
    allowFullEdit,
    incomeText,
    displayUploads,
    displayIncomeField,
    requireIdentityDoc,
    requireIncomeDoc,
  }

  const formHookContext = useFormContext()
  const formHookRepresentative = useForm()
  const {control, errors, getValues, reset, watch, register, setError, setValue} = formHookContext
  const classes = useStyles()

  const [representativeFormVisibility, setRepresentativeFormVisibility] = useState(false)
  const [editingId, setEditingId] = useState()
  const [representatives, setRepresentatives] = useState([])
  /*const [representatives, setRepresentatives] = useState([
    {
      uid: 1,
      name: 'Bla bla bla',
      cpf: '030.014.070-34',
      rg: '12.345.789-9',
      phone: '(11) 99527-8831',
      email: 'neuber.oliveira05@gmail.com',
    },
    {
      uid: 2,
      name: 'Bla bla bla',
      cpf: '030.014.070-34',
      rg: '12.345.789-9',
      phone: '(11) 99527-8831',
      email: 'neuber.oliveira05@gmail.com',
    },
    {
      uid: 3,
      name: 'Bla bla bla',
      cpf: '030.014.070-34',
      rg: '12.345.789-9',
      phone: '(11) 99527-8831',
      email: 'neuber.oliveira05@gmail.com',
    },
  ])*/

  const isEditing = editingId !== undefined

  const registerType = watch('register_type')
  const isRegisterPersonal = registrationTypeMapper.assertStatus.isPersonal(registerType)
  const isRegisterCompany = registrationTypeMapper.assertStatus.isCompany(registerType)

  const validateReprentatives = () => {
    const representativesVal = getValues(REPRESENTATIVES_FORM_NAME)
    const isInvalid = isRegisterCompany && (!representativesVal || representativesVal?.length <= 0)
    if (isInvalid) {
      return 'Pelo menos um represente deve ser cadastrado'
    }

    return true
  }

  const showForm = () => {
    setEditingId(undefined)
    setRepresentativeFormVisibility(true)
  }
  const hideForm = () => {
    setRepresentativeFormVisibility(false)
    formHookRepresentative.reset(REPRESENTATIVES_FORM_NAME, {})
  }

  const setRepresentativeData = (state) => {
    setRepresentatives(state)
    setValue(REPRESENTATIVES_FORM_NAME, state)
  }

  const addRepresentative = (formData) => {
    const newState = [...representatives]

    if (isEditing) {
      newState[editingId] = formData
    } else {
      newState.push(formData)
    }

    setRepresentativeData(newState)
    formHookRepresentative.clearErrors(REPRESENTATIVES_FORM_NAME)
    hideForm()
  }

  const removeRepresentative = (ev, idx) => {
    const tempList = [...representatives]
    tempList.splice(idx, 1)
    setRepresentativeData(tempList)
  }

  const editRepresentative = (ev, idx) => {
    const user = representatives[idx]
    showForm()
    setEditingId(idx)
    formHookRepresentative.reset(user)
  }

  useEffect(() => {
    register({name: REPRESENTATIVES_FORM_NAME, type: 'validate'}, {validate: validateReprentatives})
    if (defaultRegistrationType) {
      register({name: 'register_type', type: 'validate', value: defaultRegistrationType}, {validate: validateRequired})
    }
  }, [register, registerType])

  useEffect(() => {
    if (user) {
      const resetData = {
        ...getValues(),
        ...user,
        birthdate: convertDateToBr(user.birthdate) ?? '',
        recurring_debts: user.recurring_debts ?? 0,
      }

      if (user.documents) {
        resetData['docs.identity'] = user.documents.identity
        resetData['docs.income'] = user.documents.income
      }

      if (user[REPRESENTATIVES_FORM_NAME]) {
        setRepresentativeData(user[REPRESENTATIVES_FORM_NAME])
      }

      safeUserReset(resetData, reset)
    }
  }, [user])

  return (
    <div className={classes.form}>
      <Grid container alignItems="flex-start" direction="column">
        {!defaultRegistrationType && (
          <Grid item xs={12}>
            <FormItem
              control={control}
              errors={errors}
              fitContainer={true}
              extraStyles="row"
              type="radio"
              name="register_type"
              label="Tipo"
              options={userRegisterOptions}
              rules={{validate: validateRequired}}
            />
          </Grid>
        )}

        {isRegisterPersonal && (
          <FormProvider {...formHookContext}>
            <BaseUserForm {...userFormPersonalProps} />
          </FormProvider>
        )}

        {isRegisterCompany && (
          <FormProvider {...formHookContext}>
            <CompanyForm {...userFormPersonalProps} />

            {displayAddress && <AddressForm address={user?.address} />}
          </FormProvider>
        )}
      </Grid>

      {isRegisterCompany && (
        <Grid container spacing={2} direction="row">
          <Grid container direction="column" alignItems="center" wrap="nowrap">
            <Grid item xs={12}>
              <Button bgType="purple" p="16px" fz="16px" onClick={showForm}>
                Adicionar um representante
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText error={errors.representatives}>{errors.representatives?.message}</FormHelperText>
            </Grid>
          </Grid>

          {representatives.length > 0 && (
            <>
              {representatives.map((user, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <RepresentativeSumaryItem
                    user={user}
                    id={index}
                    onDelete={removeRepresentative}
                    onEdit={editRepresentative}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      )}

      <BaseModal
        isOpen={representativeFormVisibility}
        onClose={hideForm}
        title="Dados do representante"
        okLabel="Salvar"
        okAction={formHookRepresentative.handleSubmit(addRepresentative)}>
        <FormProvider {...formHookRepresentative}>
          <Grid container alignItems="flex-start" direction="column">
            <BaseUserForm
              allowFullEdit={true}
              incomeText={false}
              displayUploads={false}
              displayIncomeField={false}
              requireIdentityDoc={false}
              requireIncomeDoc={false}
            />
          </Grid>
        </FormProvider>
      </BaseModal>
    </div>
  )
}

InviteForm.defaultProps = {
  allowFullEdit: false,
  incomeText: undefined,
  displayUploads: true,
  displayAddress: true,
  displayIncomeField: true,
  requireIdentityDoc: true,
  requireIncomeDoc: false,
}
InviteForm.propTypes = {
  allowFullEdit: PropTypes.bool,
  user: PropTypes.object.isRequired,
  defaultRegistrationType: PropTypes.oneOf([
    registrationTypeMapper.status.PERSONAL,
    registrationTypeMapper.status.COMPANY,
  ]),
  incomeText: PropTypes.string,
  displayUploads: PropTypes.bool,
  displayAddress: PropTypes.bool,
  displayIncomeField: PropTypes.bool,
  requireIdentityDoc: PropTypes.bool,
  requireIncomeDoc: PropTypes.bool,
}
export default InviteForm
