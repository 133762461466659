import {makeStyles, useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {WhatsApp} from '@material-ui/icons'
import css from '@root/components/Layout/cssVariables'
import analyticsLogger from '@root/firebase/analytics.utils'
import {whatsAppLinkGenerator} from '@root/lib/simplePhoneFormatter'
import PropTypes from 'prop-types'
import React from 'react'
import {MOBEN_WHATSAPP} from '@root/utils/constants'

const useWAStyle = makeStyles((theme) => ({
  whatsappButton: {
    cursor: 'pointer',
    fontSize: '80px',
    background: css.colors.whatsapp,
    position: 'fixed',
    zIndex: 1000,
    bottom: '5%',
    right: '4%',
    color: css.colors.white,
    borderRadius: '50px',
    padding: '8px',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '60px',
    },
  },
}))

const WhatsAppHomeButton = () => {
  const classes = useWAStyle()
  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))
  const openWhatsapp = () => {
    analyticsLogger.logEvent('button_click', {
      alternative_event_name: 'click_home_whatsapp',
      button_name: 'icone whatsapp',
    })
    const link = whatsAppLinkGenerator(MOBEN_WHATSAPP)
    isExtraSmallViewPort ? window.open(link, '_self') : window.open(link, '_blank')
  }

  return <WhatsApp className={classes.whatsappButton} onClick={openWhatsapp} id="whatsapp-fab" />
}

WhatsAppHomeButton.propTypes = {}
WhatsAppHomeButton.defaultProps = {}

export default WhatsAppHomeButton
