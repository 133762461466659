import {produce} from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {SigninTypes} from '@root/redux/signin/signin.types'

import {UserActionTypes} from './user.types'

const initialState = {
  isLogged: false,
  authFinished: false,
  id: undefined,
  isFirstLogin: false,
  user: undefined,
  firebaseRef: undefined,
}

const reducers = {
  [SigninTypes.LOG_OUT]: (state) => ({...initialState, authFinished: true}),

  [UserActionTypes.SET_USER_NULL]: (state, {payload}) => {
    return initialState
  },

  [UserActionTypes.SET_CURRENT_USER]: (state, {payload}) => {
    return {
      ...state,
      user: payload,
      isLogged: true,
    }
  },
  [UserActionTypes.SET_USER_FIREBASE_REF]: (state, {payload}) => {
    return {
      ...state,
      firebaseRef: payload.ref,
    }
  },
  [UserActionTypes.SET_USER_FROM_FIREBASE]: (state, {payload}) => {
    return {
      ...state,
      user: payload.user,
      firebaseRef: payload.ref,
      isLogged: true,
      // authFinished: true,
    }
  },
  [UserActionTypes.SET_USER_AUTH_FINISHED]: (state, {payload}) => {
    return {
      ...state,
      authFinished: true,
    }
  },

  [UserActionTypes.SET_USER_NULL]: () => {
    return {
      ...initialState,
    }
  },

  [UserActionTypes.SET_USER_ADDRESS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.user.address = payload.address
    })
  },

  [UserActionTypes.ADD_USER_CARD]: (state, {payload}) => {
    return produce(state, (draft) => {
      // TODO descobrir o por que o usuario esta vindo sem o cartao, pode ser user antigo
      if (!draft.user.cards) {
        draft.user.cards = []
      }

      draft.user.cards.push(payload.card)
    })
  },
  [UserActionTypes.SET_USER_LOGGED]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.isLogged = payload.logged
    })
  },
  [UserActionTypes.SET_USER_ID]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.id = payload.id
    })
  },

  [UserActionTypes.SET_USER_FIRST_LOGIN]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.isFirstLogin = payload.isFirst
    })
  },

  [UserActionTypes.SET_USER_INITIAL]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.isFirstLogin = payload.isFirst
      draft.id = payload.id
      draft.isLogged = payload.logged
    })
  },
}

export default reducerMatch(reducers, initialState)
