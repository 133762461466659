import {combineReducers} from 'redux'
import adminReducer from '@root/redux/admin/admin.reducer'
import adminContractManager from '@root/redux/adminContractManager/adminContractManager.reducer'
import applicationReducer from '@root/redux/application/application.reducer'
import approvalReducer from '@root/redux/approval/approval.reducer'
import cartReducer from '@root/redux/cart/cart.reducer'
import contractReducer from '@root/redux/contract/contract.reducer'
import creditAnalysisReducer from '@root/redux/creditAnalysis/creditAnalysis.reducer'
import footerReducer from '@root/redux/footer/footer.reducer'
import headerReducer from '@root/redux/header/header.reducer'
import loaderReducer from '@root/redux/loader/loader.reducer'
import propertyReducer from '@root/redux/property/property.reducer'
import proposalReducer from '@root/redux/proposal/proposal.reducer'
import rdstationReducer from '@root/redux/rdstation/rdstation.reducer'
import receiptsReducer from '@root/redux/receipts/receipts.reducer'
import signinReducer from '@root/redux/signin/signin.reducer'
import surveyReducer from '@root/redux/survey/survey.reducer'
import tenantPropertyReducer from '@root/redux/tenantProperty/tenantProperty.reducer'
import tenantRegisterReducer from '@root/redux/tenantRegister/tenantRegister.reducer'
import userReducer from '@root/redux/user/user.reducer'

const rootReducer = combineReducers({
  admin: adminReducer,
  application: applicationReducer,
  user: userReducer,
  header: headerReducer,
  property: propertyReducer,
  tenantProperty: tenantPropertyReducer,
  tenantRegister: tenantRegisterReducer,
  globalLoader: loaderReducer,
  cart: cartReducer,
  signin: signinReducer,
  contract: contractReducer,
  footer: footerReducer,
  creditAnalysis: creditAnalysisReducer,
  survey: surveyReducer,
  receipts: receiptsReducer,
  proposal: proposalReducer,
  approval: approvalReducer,
  rdstation: rdstationReducer,
  adminContract: adminContractManager,
})

export default rootReducer
