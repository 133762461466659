import React from 'react'
const IconPencil = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.891" height="16.891" viewBox="0 0 16.891 16.891" {...props}>
      {' '}
      <g id="icons_Q2" data-name="icons Q2" transform="translate(0 0)">
        {' '}
        <path
          id="Path_129"
          data-name="Path 129"
          d="M20.631,10.238,14.68,4.244a.8.8,0,0,0-1.182,0L4.253,13.489A.844.844,0,0,0,4,14.08v5.952a.844.844,0,0,0,.844.844H10.8a.844.844,0,0,0,.591-.253l9.244-9.2a.8.8,0,0,0,0-1.182ZM10.458,19.187H5.688v-4.77l5.276-5.234,4.728,4.77Zm6.458-6.416L12.147,8l1.942-1.984,4.77,4.812Z"
          transform="translate(-4 -3.985)"
          fill="#fff"
        />{' '}
      </g>
    </svg>
  )
}

export default IconPencil
