import PropTypes from 'prop-types'

const commonToAll = {
  enableDelete: PropTypes.bool,
  enableAdd: PropTypes.bool,
}

const commonToIterator = {
  ...commonToAll,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  level: PropTypes.number.isRequired,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
}

export const contractDisplayProps = {
  ...commonToAll,
  section: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  entryPoint: PropTypes.oneOf(['appendixes', 'clauses']).isRequired,
}

export const contractIteratorProps = commonToIterator
export const contractItemEntryProps = commonToIterator
