import * as Sentry from '@sentry/react'
import {isDevEnv} from '@root/lib/envDetector'
import {applyMiddleware, compose, createStore} from 'redux'

import rootReducer from './root-reducer'

const configureReduxDevTools = () => window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 10})
const enableReduxDevTools = () => {
  const isDev = isDevEnv()
  return isDev && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const composeEnhancers = enableReduxDevTools() ? configureReduxDevTools() : compose
const enhancer = composeEnhancers(sentryReduxEnhancer)

const store = createStore(rootReducer, enhancer)

export const createTestStore = (initialState) => {
  if (initialState) {
    return () => initialState
  }
  return createStore(rootReducer)
}

export default store
