import Grid from '@material-ui/core/Grid'
import FormItem from '@root/components/FormHelper/FormItem'
import {validateDate} from '@root/lib/simpleValidators'
import React from 'react'
import {DATE_BR_FORMAT} from '@root/utils/constants'

const DatePickerRange = ({control, errors, names, ...restProps}) => {
  return (
    <>
      <Grid item {...restProps}>
        <FormItem
          control={control}
          errors={errors}
          name={names.start}
          fitContainer={false}
          type="datepicker"
          rules={{
            validate: validateDate,
          }}
        />
      </Grid>
      <Grid item {...restProps}>
        <FormItem
          control={control}
          errors={errors}
          name={names.end}
          fitContainer={false}
          type="datepicker"
          rules={{
            validate: validateDate,
          }}
        />
      </Grid>
    </>
  )
}

export default DatePickerRange
