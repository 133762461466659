import {IconButton, useMediaQuery, useTheme} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import {Link, useRouteMatch} from 'react-router-dom'

export const LogoElement = ({classes, onClose}) => {
  const theme = useTheme()
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'))

  const {path, isExact} = useRouteMatch()
  const isHomeRoute = path === '/' && isExact

  const handleOnClick = () => onClose()

  if (isSmallViewPort && !isHomeRoute) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img
          src="/images/logo/Moben-Logo-header.png"
          style={{width: 150, transform: isSmallViewPort ? 'translateX(-28px)' : ''}}
        />
        {isSmallViewPort && (
          <IconButton onClick={handleOnClick} style={{position: 'absolute', right: 0}}>
            <CloseIcon style={{fontSize: 34, color: '#061BCD'}} />
          </IconButton>
        )}
      </div>
    )
  }

  return (
    <Link to="/" className="home-logo-link">
      <img src="/images/logo/Moben-Logo-header.png" className={classes.logoElementImg} />
    </Link>
  )
}
