import {forceArrayUnionSingle} from '@root/firebase/firebase.utils'
import {getStepById} from '@root/lib/contract/stepWalker'
import uidGenerator from '@root/lib/uidGenerator'
import {USER_TYPE_OWNER} from '@root/lib/userType'
/** @typedef {{created_at: Date, type: string, origin: string, text: string, creator_uid: string}} NotificationBody **/
/** @typedef {{created_at: Date, type: string, origin: string, text: string, creator_uid: string, name: string, username: string, route: string, section: string, status: string}} ObservationBody **/

export const NOTIFICATION_TYPE_NOTIFY = 'notification'
export const NOTIFICATION_TYPE_OBSERVER = 'observation'

/**
 *
 * @param {string} type
 * @param {string} origin
 * @param {string} userId
 * @param {string} text
 * @return {NotificationBody}
 */
const createBaseNotification = (type, origin, userId, text) => ({
  created_at: new Date(),
  type,
  origin,
  text,
  creator_uid: userId ?? null,
  id: uidGenerator(12),
})
/**
 * @param {{sectionId, origin, text, userId, userName}} config
 * @return {ObservationBody}
 */
export const createObservationBody = (config) => {
  const params = {status: 'pending', ...config}
  const {sectionId, origin, text, userId, userName, status} = params
  const step = getStepById(sectionId)

  return {
    ...createBaseNotification(NOTIFICATION_TYPE_OBSERVER, origin, userId, text),
    title: step.title,
    username: userName ?? 'Inquilino',
    route: step.path_orig,
    section: step.id,
    status,
  }
}

/**
 *
 * @param {{origin, text, userId}} config
 * @return {NotificationBody}
 */
export const createNotificationBody = (config) => {
  const params = {...config}
  const {origin, text, userId} = params

  return createBaseNotification(NOTIFICATION_TYPE_NOTIFY, origin, userId, text)
}

export const isNotificationType = (type) => type === NOTIFICATION_TYPE_NOTIFY
export const isObservationType = (type) => type === NOTIFICATION_TYPE_OBSERVER

/**
 * @param {NotificationBody} notifi
 * @return {boolean}
 */
export const filterByNotification = (notifi) => isNotificationType(notifi.type)

/**
 * @param {NotificationBody} notifi
 * @return {boolean}
 */
export const filterByObservation = (notifi) => isObservationType(notifi.type)

export const addNotificationToProperty = async (propertyRef, notification) => {
  return await propertyRef.update({
    'contract.observations': forceArrayUnionSingle(notification),
  })
}
