/** @module createAlternativeEventName */
import {isTestEnv} from '@root/lib/envDetector'

const regexPath = new RegExp(' |/', 'g')
const regexTitleDash = new RegExp(' |-', 'g')
const regexTitleUnder = new RegExp(' ', 'g')
const regexDash = new RegExp('-', 'g')
const regexStartUnderscore = new RegExp('^_')
const regexDiatrics = new RegExp('[\u0300-\u036f]', 'g')
const isTest = isTestEnv()

export const removeDiatrics = (str) => str.normalize('NFD').replace(regexDiatrics, '')
export const sanitizeEventTitle = (buttonName, spaceForUnderscore = false) => {
  let sanitized = removeDiatrics(buttonName.toLowerCase())

  if (spaceForUnderscore) {
    sanitized = sanitized.replace(regexTitleUnder, '_')
  } else {
    sanitized = sanitized.replace(regexTitleDash, '')
  }

  return sanitized
}
const createAlternativeEventName = (buttonName) => {
  if (!buttonName) {
    return undefined
  }
  const pathname = isTest ? '/custom/path-with-dash' : window.location.pathname
  const basename = pathname.replace(regexDash, '').replace(regexPath, '_').replace(regexStartUnderscore, '')
  const eventname = sanitizeEventTitle(buttonName)

  return `${basename}_${eventname}`
}

export default createAlternativeEventName
