/**
 * @param {object} obj
 * @param {string} path
 * @param {any} defaultValue
 * @return {any|undeined}
 */
export const dotParse = (obj, path, defaultValue) => {
  const keys = path.split('.')
  let result = obj

  keys.forEach((key) => {
    if (result && result[key] !== undefined && result[key] !== null) {
      result = result[key]
    } else {
      result = defaultValue
    }
  })

  return result
}
