import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import SectionContainer from '@root/components/Layout/Container/SectionContainer'
import Text from '@root/components/Layout/Typography/Text'
import Title from '@root/components/Layout/Typography/Title'
import React from 'react'

const useStyles = makeStyles(() => ({
  section: {
    marginTop: 24,
    marginBottom: 8,
  },
}))

const HistoryEntry = ({entry}) => (
  <Grid container item>
    <Grid item xs={12}>
      <Text bold>Data do reajuste: </Text>
      <Text>{entry.readjust_date}</Text>
    </Grid>
    <Grid item xs={12}>
      <Text bold>Valor reajustado: </Text>
      <Text>{entry.new_amount}</Text>
    </Grid>
    <Grid item xs={12}>
      <Text bold>
        Variação do índice <small>(12 meses): </small>
      </Text>
      <Text>{entry.index_variation}</Text>
    </Grid>
    <Divider dashed />
  </Grid>
)
const ReadjustmentResult = ({state, dispatch}) => {
  const classes = useStyles()
  const {result} = state
  const handleNewCalc = () => {
    dispatch({type: 'visibility', visibility: false})
  }

  const recentReadjust = result.history_size > 0 ? result.history[result.history_size - 1] : {}

  return (
    <>
      <Grid container item direction="column">
        <Grid item className={classes.section}>
          <Title>Dados do cálculo de reajuste</Title>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <Text bold>Valor do aluguel: </Text>
            <Text>{result.rent}</Text>
          </Grid>

          <Grid item>
            <Text bold>Início do aluguel: </Text>
            <Text>{result.validity_start}</Text>
          </Grid>

          <Grid item>
            <Text bold>Índice de reajuste: </Text>
            <Text>{result.readjusment_index}</Text>
          </Grid>
        </Grid>

        <Grid item className={classes.section}>
          <Title>Resultado do cálculo de reajuste</Title>
        </Grid>
        <Grid container item direction="row" justify="space-between">
          <Grid item>
            <Text bold>Reajuste até: </Text>
            <br />
            <Text secondary size="1.10rem">
              {recentReadjust.readjust_date}
            </Text>
          </Grid>

          <Grid item>
            <Text bold>Valor reajustado: </Text>
            <br />
            <Text secondary size="1.10rem">
              {recentReadjust.new_amount}
            </Text>
          </Grid>
        </Grid>

        <Grid item className={classes.section}>
          <Title>Historico</Title>
        </Grid>
        <Grid container item>
          {result.history.map((entry, index) => (
            <HistoryEntry key={index} entry={entry} />
          ))}
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="flex-end">
        <Grid item>
          <Button onClick={handleNewCalc}>Novo cálculo</Button>
        </Grid>
      </Grid>
    </>
  )
}

ReadjustmentResult.propTypes = {}
ReadjustmentResult.defaultProps = {}

export default ReadjustmentResult
