/** @module surveyModel */
import {firestore} from '@root/firebase/firebase.utils'

const COLLECTION_NAME = 'annotation'

const create = async (payload) => {
  const {text, files, target, target_id, username} = payload
  return await firestore.collection(COLLECTION_NAME).add({
    created_at: new Date(),
    origin: 'painel-admin',
    created_by: username,
    text,
    files,
    target,
    target_id,
  })
}

const addToProperty = async (id, text, username, files = []) => {
  return create({
    text,
    files,
    username,
    target: 'property',
    target_id: id,
  })
}

const annotationModel = {
  COLLECTION_NAME,
  addToProperty,
}

export default annotationModel
