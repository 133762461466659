import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import {useTheme} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MenuIcon from '@material-ui/icons/Menu'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {AvatarBadge} from './AvatarBadge'
import {DrawerMenuItens} from './DrawerMenuItens'
import {HomeMenuItens} from './MenuItems'
import {useStyles, useStylesHomeNav} from './styles'

export function Header() {
  const history = useHistory()

  const styles = useStylesHomeNav()
  const classes = useStyles()
  const theme = useTheme()
  const isMediumViewPort = useMediaQuery(theme.breakpoints.up('md'))
  const isRoot = useSelector((s) => s.application.routeInfo.isRoot)
  const isHeaderless = useSelector((s) => s.application.routeInfo.isHeaderless)

  const drawerConfig = useMemo(() => {
    //defaults to desktop
    const config = {
      variant: 'permanent',
      anchor: 'left',
    }

    if (!isMediumViewPort) {
      config.variant = 'temporary'
    }

    return config
  }, [isMediumViewPort])

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container = document.body

  if (isHeaderless) return null

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {isRoot ? (
            <>
              <HomeMenuItens classes={classes} styles={styles} />
            </>
          ) : (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {history.location.pathname.includes('inquilino') ? 'Sou inquilino' : 'Sou proprietário'}
              </Typography>

              <AvatarBadge styles={styles} />
            </>
          )}
        </Toolbar>
        {/* <Divider /> */}
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/*TODO o conteudo esta passando todo por "baixo" do drawer, com ele ai nao da para testar nada do painel*/}
        {!isRoot && (
          <Drawer
            container={container}
            variant={drawerConfig.variant}
            anchor={drawerConfig.anchor}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}>
            <DrawerMenuItens classes={classes} onClose={setMobileOpen} />
          </Drawer>
        )}
      </nav>
    </>
  )
}
