/** @module userModel */
import 'firebase/firestore'

import * as Sentry from '@sentry/react'
import fetcher from '@root/application/infra/api/fetcher'
import firebase from 'firebase/app'
import {firestore} from '@root/firebase/firebase.utils'
import produce from 'immer'
import addressDisplay, {ADDRESS_VERBOSITY_FULL} from '@root/lib/addressDisplay'
import {dateParser} from '@root/lib/dateParser'
import {firebaseBase64Uploader} from '@root/lib/firebaseUploader'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {simplePhoneFormatter} from '@root/lib/simplePhoneFormatter'
import {createEmptyTenant} from '@root/redux/tenantRegister/tenantRegister.reducer'

const COLLECTION_NAME = 'users'

const getCollection = (extraPath = '') => firestore.collection(COLLECTION_NAME + extraPath)

export const docsToPlainArray = (ref) => ref.docs.map((doc) => doc.data())

const saveToDatabase = async (user, doc) => {
  const userDb = await getCollection().add(user)

  if (doc) {
    const uploaded = await firebaseBase64Uploader(doc.content, doc.name, userDb.id, 'user')
    if (uploaded) {
      await userDb.update({
        document: {
          ref: uploaded.path,
          url: uploaded.download_url,
        },
      })
    }
  }
  return userDb
}

/**
 * @param {firebase.firestore.DocumentSnapshot|object} userSnapOrData
 * @return {object} {@see https://github.com/MobenDev/database-structure}
 */
const fromDatabaseToTenant = (userSnapOrData) => {
  const isDocSnap = userSnapOrData instanceof firebase.firestore.DocumentSnapshot
  const user = isDocSnap ? userSnapOrData.data() : userSnapOrData
  const tenant = createEmptyTenant()

  Object.keys(tenant).forEach((prop) => {
    // eslint-disable-next-line no-prototype-builtins
    if (user.hasOwnProperty(prop)) {
      tenant[prop] = user[prop]
    }
  })

  if (isDocSnap) {
    tenant.uid = userSnapOrData.id
  }

  tenant.name = user.name || user.displayName
  tenant.photoURL = user.photoURL

  return tenant
}

const prepareDoc = (doc) => {
  if (doc?.created_at) {
    return {
      ...doc,
      created_at: dateParser(doc.created_at),
    }
  }
  return null
}
const prepareForDatabase = (user) => {
  return {
    ...produce(user, (draft) => {
      draft.birthdate = dateParser(user.birthdate) ?? ''
      draft.documents.identity = prepareDoc(user.documents?.identity)
      draft.documents.income = prepareDoc(user.documents?.income)

      if (user.income !== undefined) {
        draft.income = formatMoneyBrToFloat(user.income)
      } else {
        draft.income = 0
      }
      if (user.recurring_debts !== undefined) {
        draft.recurring_debts = formatMoneyBrToFloat(user.recurring_debts)
      } else {
        draft.recurring_debts = 0
      }

      if (user?.address) {
        draft.address.complement = user.address.complement ?? ''
      } else if (user?.address === undefined) {
        // draft.address = {}
        draft.address = null
      }
      if (user?.address?.street) {
        draft.address.formatted = addressDisplay(user.address, ADDRESS_VERBOSITY_FULL)
      }

      if (!user?.representatives) {
        draft.representatives = null
      }

      if (user.name) {
        draft.name = user.name.trim().toUpperCase()
      }
      if (user.email) {
        draft.email = user.email.trim().toLowerCase()
      }
      if (user.cpf) {
        draft.cpf = user.cpf.trim()
      }
      if (user.phone) {
        draft.phone = user.phone.trim()
      }
    }),
  }
}

/**
 * @param {string} userId
 * @param {string} paymentType
 * @param {string} [cardId]
 * @param {Array<string>} propertyIds
 * @return {Promise<any>}
 */
const fetchPropertyOwerPay = (userId, paymentType, cardId = undefined, propertyIds = [], promoCode = undefined) => {
  return fetcher.post('propertyOwerPay', {
    user_id: userId,
    property_ids: propertyIds,
    card_id: cardId,
    payment_type: paymentType,
    code: promoCode,
  })
}

/**
 * @param {string} userId
 * @param {string} phone
 * @param {object} card
 * @param {object} address
 * @return {Promise<any>}
 */
const fetchAddCard = (userId, card) => {
  return fetcher.post('addCard', {user_id: userId, card})
}

/**
 * @param {string} userId
 * @param {string} cardId
 * @return {Promise<any>}
 */
const fetchSelectCard = (userId, cardId) => {
  return fetcher.post('selectCard', {user_id: userId, card_id: cardId})
}

/**
 * @param {string} userId
 * @param {string} phone
 * @param {object} address
 * @return {Promise<any>}
 */
const fetchUpdateAddress = (userId, phone, address) => {
  return fetcher.post('saveAddress', {user_id: userId, phone, address})
}

/**
 * @param {string} name
 * @param {object} email
 * @param {string} phone
 * @return {Promise<any>}
 */
const fetchRDIntegration = (name, email, phone) => {
  return fetcher.post('/integration', {user_name: name, email: email, phone: phone})
}

/**
 * @param {string} email
 * @return {Promise<any>}
 */
const fetchCheckUserMail = (email) => {
  return fetcher.post('checkUser', {email})
}

const fetchCreateUser = (user) => {
  return fetcher.post('createUser', {
    countryCode: '55',
    email: user.email,
    phone: user.phone,
    displayName: user.name,
    uid: user.uid,
  })
}

/**
 * @param bank
 * @param {string} user_id
 * @param {string} property_id
 * @return {Promise<*>}
 */
const fetchAddRecipient = (bank, user_id, property_id) => {
  return fetcher.post('addRecipient', {
    bank,
    user_id,
    property_id,
  })
}

/**
 * @param {string} userId ID do interessado
 * @param {string} [propertyId]
 * @return {Promise<any>}
 */
const fetchCreditAnalysis = (userId, propertyId = '') => {
  const body = {user_id: userId}

  if (propertyId) {
    body.property_id = propertyId
  }
  if (origin) {
    body.origin = origin
  }

  return fetcher.post('getCreditAnalysis', body)
}

const fetchCreateSubscription = (ownerId, propertyId) => {
  return fetcher.post('createOwnerSubscription', {user_id: ownerId, property_id: propertyId})
}

const splitDigit = (str) => {
  const [number, digit] = str.split('-')
  return {number, digit}
}

const getLoggedActiveBank = (user) => {
  return user?.bank_accounts?.find((bank) => bank.active)
}

/**
 * @param {string} id
 * @return {Promise<DocumentSnapshot<DocumentData>>}
 */
const getById = async (id) => {
  const userDb = await firestore.collection(COLLECTION_NAME).doc(id).get()
  let promise
  if (userDb.exists) {
    promise = Promise.resolve(userDb)
  } else {
    promise = Promise.reject(['Usuário não encontrado'])
  }

  return promise
}

/**
 * @param {string[]} ids
 * @return {Promise<never>}
 */
const getByIdsList = async (ids) => {
  if (ids.length <= 0) {
    return Promise.resolve([])
  }

  const userDb = await firestore.collection(COLLECTION_NAME).where('uid', 'in', ids).get()
  let promise
  if (!userDb.empty) {
    const docs = userDb.docs.map((doc) => doc.data())
    promise = Promise.resolve(docs)
  } else {
    promise = Promise.reject(['Usuário não encontrado'])
  }

  return promise
}

/**
 * @param {string} email
 * @return {Promise<object|null>} user
 */
const getByEmail = async (email) => {
  let user = await firestore
    .collection(COLLECTION_NAME)
    .where('email', '==', email)
    .get()
    .then((snap) => {
      if (snap.docs.length === 0) {
        return null
      }
      return snap.docs[0].data()
    })
    .catch((ex) => {
      Sentry.captureException(ex)
      return null
    })

  return user
}

/**
 * @param {string} phone
 * @return {Promise<object>} user
 */
const getByPhone = async (phone) => {
  const phoneFormatted = simplePhoneFormatter(phone)

  let user = await firestore
    .collection(COLLECTION_NAME)
    .where('phone', '==', phoneFormatted)
    .get()
    .then((snap) => {
      if (snap.docs.length === 0) {
        return null
      }
      return snap.docs[0].data()
    })
    .catch((ex) => {
      Sentry.captureException(ex)
      return null
    })

  return user
}

/**
 * @param {string} cpf
 * @return {Promise<object>} user
 */
const getByCpf = async (cpf) => {
  let user = await firestore
    .collection(COLLECTION_NAME)
    .where('cpf', '==', cpf)
    .get()
    .then((snap) => {
      if (snap.docs.length === 0) {
        return null
      }
      return snap.docs[0].data()
    })
    .catch((error) => {
      console.log(`${error} cpf nao encontrado`)
      return null
    })

  return user
}

/**
 * @param {string} phone
 * @param {string} email
 * @return {Promise<User>}
 */
const getByPhoneAndMail = async (phone, email) => {
  let user = await firestore
    .collection(COLLECTION_NAME)
    .where('phone', '==', phone)
    .where('email', '==', email)
    .get()
    .then((snap) => {
      if (snap.docs.length === 0) {
        return null
      }
      return snap.docs[0].data()
    })
    .catch((error) => {
      console.log('Usuario nao encontrado')
      return null
    })

  return user
}

const getPaginatedQuery = (page = 0, perPage = 50) => {
  // const offset = page * perPage
  return (
    firestore
      .collection(COLLECTION_NAME)
      // .startAfter('created_at', offset)
      // .limit(perPage)
      .orderBy('created_at', 'desc')
  )
}

const getPaginated = (onSnapshot, page = 0, perPage = 50) => {
  const query = getPaginatedQuery(page)
  return query.onSnapshot(onSnapshot)
}

/**
 * @param {string} phone
 * @param {string} email
 * @returns {Promise<boolean>}
 */
const isEmailAlreadyInUse = async (phone, email) => {
  const existsUser = await getByEmail(email)
  return existsUser
}

/**
 * @param {User} user
 * @returns {Promise<boolean>}
 */
const userExists = async (user) => {
  const {uid, phone, email} = user
  const foundUser = await getByPhoneAndMail(phone, email)
  const userExists = foundUser !== null && foundUser.uid !== uid
  return userExists
}

const updateUser = async (payload, userUid) => {
  const userRef = firestore.collection(COLLECTION_NAME).doc(userUid)
  const userSnap = await userRef.get()

  if (userSnap.exists) {
    const key = Object.keys(payload)[0]
    return await userRef.update(key, payload[key])
  }

  return
}
const fetchUsersList = async () => {
  return await fetcher.get('admin/users')
}

const saveRdForm = async (userId, formName, fields) => {
  const updateData = {
    [`marketing.rd.form.${formName}`]: fields,
  }
  return await firestore.collection(COLLECTION_NAME).doc(userId).update(updateData)
}

const updateTenantOf = async (userId, propertyRef) => {
  const userSnap = await getById(userId)
  const user = userSnap.data()

  let selectedTenant = userSnap.data()
  let tenantOfArray = selectedTenant.tenant_of ?? []

  const existsInTenantOf = tenantOfArray.some((dbPropertyRef) => dbPropertyRef.id === propertyRef.id)

  if (!existsInTenantOf) {
    tenantOfArray.push(propertyRef)

    await userSnap.ref.update({
      tenant_of: tenantOfArray,
    })
  }
}

const removeTenantOf = async (userId, propertyRef) => {
  const userSnap = await getById(userId)
  const user = userSnap.data()

  let tenantOfArray = user.tenant_of ?? []

  const selectedIndex = tenantOfArray.findIndex((dbPropertyRef) => dbPropertyRef.id === propertyRef.id)
  const existsInTenantOf = selectedIndex > -1

  if (existsInTenantOf) {
    tenantOfArray.splice(selectedIndex, 1)
    await userSnap.ref.update({
      tenant_of: tenantOfArray,
    })
  }
}

const userModel = {
  COLLECTION_NAME,
  getCollection,
  saveToDatabase,
  prepareForDatabase,
  fromDatabaseToTenant,
  fetchPropertyOwerPay,
  fetchUpdateAddress,
  fetchRDIntegration,
  fetchAddCard,
  fetchSelectCard,
  fetchCheckUserMail,
  fetchCreateUser,
  fetchAddRecipient,
  fetchCreditAnalysis,
  fetchCreateSubscription,
  getLoggedActiveBank,
  getByEmail,
  getByCpf,
  getById,
  getByIdsList,
  getByPhoneAndMail,
  getPaginated,
  isEmailAlreadyInUse,
  getByPhone,
  userExists,
  updateUser,
  fetchUsersList,
  saveRdForm,
  updateTenantOf,
  removeTenantOf,
}

export default userModel
