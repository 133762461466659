import {Typography} from '@material-ui/core'
import algoliasearch from 'algoliasearch/lite'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import {isProductionEnv} from '@root/lib/envDetector'
import React, {useEffect} from 'react'
import {Index, InstantSearch, SearchBox} from 'react-instantsearch-dom'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

import {PropertiesScrollableHits} from './PropertiesScrollableHits/PropertiesScrollableHits'
import useStyles from './styles'
import {UsersScrollableHits} from './UsersScrollableHits/UsersScrollableHits'

const getAlgoliaKeys = () =>
  isProductionEnv()
    ? ['H254BIZX86', '39373d94ec7d0a320febec91a4fff77d']
    : ['R9T2LJ59Y2', '4ff85af2516f43fbc4f87fa1e2bccc8f']

const getUsersIndexName = () => (isProductionEnv() ? 'prod_users' : 'dev_users')
const getPropertyIndexName = () => (isProductionEnv() ? 'prod_property' : 'dev_property')

const searchClient = algoliasearch(getAlgoliaKeys()[0], getAlgoliaKeys()[1])

const UsersList = ({hits}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(setNavbarTitleAction('Usuários'))
  }, [])

  const goToUserDetail = (user) => {
    history.push(adminPathBuilder(`/usuarios/${user.uid}`))
  }

  return (
    <div className={classes.userListRoot}>
      <InstantSearch searchClient={searchClient} indexName={getUsersIndexName()}>
        <SearchBox />

        <div className={classes.mainHitsContainer}>
          <div>
            <Typography variant="h5" color="secondary" align="center">
              Usuários
            </Typography>
            <div>
              <Index indexName={getUsersIndexName()}>
                <UsersScrollableHits />
              </Index>
            </div>
          </div>

          <div>
            <Typography variant="h5" color="secondary" align="center">
              Imóveis
            </Typography>

            <div>
              <Index indexName={getPropertyIndexName()}>
                <PropertiesScrollableHits />
              </Index>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  )
}

export default UsersList
