import {Card, CardContent} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Text from '@root/components/Layout/Typography/Text'
import ListItem from '@root/components/PropertyList/ListItem'
import {useStyles} from '@root/components/PropertyList/styles'
import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import {translate} from '@root/lib/intl/translations'
import PropTypes from 'prop-types'
import React from 'react'

const ProgressRowEntry = ({property, onClick}) => {
  const classes = useStyles()
  const {payment, rent, validity, next_readjustment} = property.computed

  return (
    <Grid container xs={12} sm={6} md={4}>
      <Card className={classes.cardWrapper} onClick={onClick}>
        <CardContent className={classes.contentWrapper}>
          <Grid container item direction="column" justify="space-between">
            <ListItem
              size={12}
              label={property.alias}
              value={addressDisplay(property.address, ADDRESS_VERBOSITY_MINIMAL, true)}
            />
            <Grid container item>
              <Grid container item direction="row" spacing={2}>
                <ListItem label="Valor" value={rent.amount} />
                <ListItem label="Pendencia" value={translate(payment.has_arrear)} />

                <ListItem label="Mes anterior" value={`${payment.prev.period} - ${payment.prev.status}`} />
                <ListItem label="Mes vigente" value={`${payment.current.period} - ${payment.current.status}`} />

                <ListItem label="Próximo reajuste" value={next_readjustment.date} />
                <ListItem label="Fim do contrato" value={validity.end} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

ProgressRowEntry.propTypes = {
  property: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
}
ProgressRowEntry.defaultProps = {}

export default ProgressRowEntry
