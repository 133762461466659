import {Typography} from '@material-ui/core'
import fetcher from '@root/application/infra/api/fetcher'
import getContractDownloadURL from '@root/components/App/Contract/getContract'
import {Button} from '@root/components/Button/Button'
import IconTrashCan from '@root/components/icons/IconTrashCan'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import PageMini from '@root/components/PageMini'
import analyticsLogger from '@root/firebase/analytics.utils'
import firebase from 'firebase/app'
import {forceArrayUnion} from '@root/firebase/firebase.utils'
import {getURL} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import {getStepById} from '@root/lib/contract/stepWalker'
import {discordNotifyTenantContractObservation} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import {createNotificationBody, createObservationBody} from '@root/lib/notification'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {removeApprovalObservationAction} from '@root/redux/approval/approval.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import routes, {routeWithID} from '@root/utils/routes'

const TenantApprovalSummary = ({classes, history}) => {
  const {control, errors, handleSubmit, watch} = useForm()
  const propertyId = getURL().searchParams.get('id')

  const user = useSelector((s) => s.user.user)
  const {ready, owner, property, propertySnap, observationsQueue} = useSelector((s) => s.approval)
  const hasQueue = observationsQueue.length > 0

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    removeApprovalObservationAction,
  })

  const goToObservations = () => {
    history.push(routeWithID(routes.CONTRACT_APPROVAL_OBSERVATION, propertyId))
  }

  /*const handleObservationBtn = () => {
    analyticsLogger.logEvent('button_click', {
      alternative_event_name: 'contrato_aprovar_observacoes',
      button_name: 'Observações',
    })
    setObservationVisibility(true)
  }*/

  const saveObservations = async () => {
    actions.setGlobalLoaderAction(true)
    try {
      const observationPayload = observationsQueue.map((obs) =>
        createObservationBody({
          sectionId: obs.section_id,
          userName: user?.name,
          userId: user?.uid,
          text: obs.text,
          origin: USER_TYPE_TENANT,
        }),
      )
      await propertySnap.ref.update({
        'contract.observations': forceArrayUnion(observationPayload),
      })
      await fetcher.post('/mailing', {
        code: 'm0035',
        property_id: propertySnap.id,
      })
      analyticsLogger.logEvent('contrato_aprovar_observacoes_enviadas')

      const pdfLink = getContractDownloadURL(property)
      discordNotifyTenantContractObservation(user, pdfLink, observationPayload)

      alert('Observações enviadas com sucesso.')
      history.push(routeWithID(routes.CONTRACT_APPROVAL, property.uid))
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const removeObservation = (index) => () => {
    actions.removeApprovalObservationAction(index)
  }

  useEffect(() => {
    if (!ready) {
      history.replace(routeWithID(routes.CONTRACT_APPROVAL, propertyId))
    }
  }, [])

  return (
    <PageMini
      outerTitleLine1="Gostaria de sugerir mais alguma alteração ou correção?"
      outerNode={
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Button m="0 auto 15px" w="318px" h="48px" fz="15px" fw={600} bgType="purple" onClick={goToObservations}>
            Sugerir nova alteração/correção
          </Button>

          <Button m="0 auto 30px" w="318px" h="48px" fz="15px" fw={600} bgType="orange" onClick={saveObservations}>
            Enviar alterações ao proprietário
          </Button>

          <Typography variant="body1" align="center">
            Suas sugestões
          </Typography>
        </div>
      }>
      <Box w="unset" bgType="gray" style={{margin: -15}}>
        {!hasQueue && <Typography variant="body1">Nenhuma observação adicionada</Typography>}

        {hasQueue &&
          observationsQueue.map((obs, index) => (
            <Box m="0 0 5px" key={index} style={{position: 'relative'}}>
              <Typography variant="body1" style={{fontWeight: 600, maxWidth: '89%'}}>
                Solicitação de alteração em {obs.section_name}
              </Typography>
              <Typography variant="body1" style={{maxWidth: '89%'}}>
                {obs.text}
              </Typography>

              <IconWrapper
                bgType="red"
                onClick={removeObservation(index)}
                style={{padding: 4.5, position: 'absolute', top: 12, right: 8}}>
                <IconTrashCan />
              </IconWrapper>
            </Box>
          ))}
      </Box>
    </PageMini>
  )
}

export default TenantApprovalSummary
