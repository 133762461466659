/** @module safeReset */
export const safeUserReset = (user, resetFunc) => {
  const {tenant_of, fiador_of, proposal_of, snap, type, subscriptions, credit_analysis, ...safeData} = user ?? {}
  resetFunc(safeData)
}

export const safePropertyReset = (property, resetFunc) => {
  const {contract, proposals, additional_owners, ...safeData} = property ?? {}
  resetFunc(safeData)
}
