import {makeStyles} from '@material-ui/core/styles'

const modalStyle = makeStyles((theme) => ({
  title: {
    'padding': '16px 24px',
    '& h5': {fontWeight: 600},
  },
  content: {flex: '1 1 auto'},
  avatarPhoto: {
    // margin: '0 auto 10px !important',
    margin: '0 auto 10px',
    width: '82px',
    height: '82px',
  },
  nameCPFContainer: {
    'margin': '10px 0',
    '& > h6': {
      'lineHeight': '22px',
      'letterSpacing': '0.07px',
      '&:first-child': {
        fontWeight: 600,
      },
    },
  },
  textInfo: {
    'margin': '20px auto 0',
    '& > p': {
      maxWidth: '45ch',
    },
  },
  buttonsContainer: {
    'flex': '1 1 auto',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    '& button:first-child': {
      marginBottom: '12px',
    },
  },

  analysisActionsContainer: {
    padding: '24px',
  },
  analysisActionButton: {
    width: '100%',
    borderRadius: 0,
  },
  analysisContent: {
    // padding: '72px 22px',
    padding: '22px 22px',
    textAlign: (styleProps) => (styleProps.alignCenter ? 'center' : ''),
  },
  modalAnalysisResultTitle: {
    '& > h2': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'fontWight': 'bold',
      '& > svg': {
        marginRight: 14,
      },
    },
  },
}))

export default modalStyle
