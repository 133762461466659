import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  fakedoorContainer: {
    marginTop: 100,
    // border: '1px solid red',
    maxWidth: '500px',
    [theme.breakpoints.down('lg')]: {
      margin: '100px auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
      padding: '0 20px',
      maxWidth: 'unset',
    },
  },
  fakedoorTitle: {
    fontWeight: 700,
    fontSize: '1.8rem',
    marginLeft: 20,
  },
  statusAluguel: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'flexDirection': 'column',
    // 'height': 'calc(100vh - 65px)',
    'minHeight': 'calc(100vh - 65px)',
    'width': '100%',
    'maxWidth': '500px',
    'margin': '75px auto 0',
    'padding': '0 15px',
    '& header': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'margin': '15px 0',
      '& h1': {
        marginLeft: '10px',
        fontSize: '1.5rem',
        textAlign: 'center',
      },
    },
  },
}))

const DashboardWrapper = ({children, title, iconImg}) => {
  const classes = useStyles()
  return (
    <div className={classes.statusAluguel}>
      <header>
        {iconImg && <img src={iconImg} alt={`icone ${title}`} className={classes.fakedoorIcon} />}
        {/*<h1>Boletos</h1>*/}
        <Typography className={classes.fakedoorTitle} variant="h1">
          {title}
        </Typography>
      </header>
      {children}
    </div>
  )
}

DashboardWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  iconImg: PropTypes.node,
}
export default DashboardWrapper
