import {stepsList} from '@root/lib/contract/contractStepsMap'
import {USER_TYPE_FIADOR, USER_TYPE_TENANT} from '@root/lib/userType'
import {PROPERTY_STATUS_PAID, PROPERTY_STATUS_PENDING} from '@root/model/cartModel'
import propertyModel from '@root/model/propertyModel'

//map mais sinistro que voces verao por um bom tempo
const translationsMap = {
  [PROPERTY_STATUS_PAID]: 'Pago',
  [PROPERTY_STATUS_PENDING]: 'Pendente',
  [USER_TYPE_TENANT]: 'Inquilino',
  [USER_TYPE_FIADOR]: 'Fiador',
  [propertyModel.OCCUPATION_EMPTY]: 'Vazio',
  [propertyModel.OCCUPATION_FULL]: 'Ocupado',
  [true]: 'Sim',
  [false]: 'Não',
  [undefined]: 'Não informado',
  ['']: 'Não informado',
  yes: 'Sim',
  no: 'Não',
  unknown: 'Desconhecido',
  invite: 'Convite',
  contract: 'Contract',
  adult: 'Adultos (maiores de 18 anos)',
  child: 'Crianças e Adolescentes',
  dog_large: 'Cachorro de grande porte',
  dog_medium: 'Cachorro de médio porte',
  dog_small: 'Cachorro de pequeno porte',
  cat: 'Gato',
  residential: 'Residencial',
  commercial: 'Comercial',

  /*
  'ownerdata': '',
  'owneraddress': '',
  'bankinfo': '',
  'property': '',
  'validity': '',
  'rentfees': '',
  'penalties': '',
  'forum': '',
  'guarantees': '',
  'tenant': '',
  'survey': '',
  'witnesses': '',
  'extrainfo': '',
  'created': '',*/
}
/**
 * Mapa sinistrao para traduzir umas coisas corriqueiras, yes, no, undefined......
 * @param {string} str
 * @return {string}
 */
export const translate = (str) => translationsMap[str]

/**
 * @param {string} id
 * @return {string}
 */
export const translateContractStep = (id) => {
  const step = stepsList.find((step) => step.id === id)
  if (step) {
    return step.title
  }
}
