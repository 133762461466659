import {Grid, Typography} from '@material-ui/core'
import checkIcon from '@root/assets/icon_awesome_check.svg'
import Fakedoor from '@root/components/App/Dashboard/DashboardPages/Fakedoor/Fakedoor'
import FormItem from '@root/components/FormHelper/FormItem'
import actionDispatcher from '@root/lib/actionDispatcher'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import {validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentUser} from '@root/redux/user/user.actions'
import {TENANT_DASHBOARD_BLOCK_TITLES} from '@root/utils/constants'

import useStyles from '../styles'

const TenantDashboardAdditionalDocuments = () => {
  const classes = useStyles()
  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)
  const actions = actionDispatcher({
    setHeaderMainHeader,
    setFooterNotVisible,
    setGlobalLoaderAction,
    setCurrentUser,
  })
  const {control, errors, getValues} = useForm()

  const documentOptions = [
    {label: 'RG com CPF ou CNH', value: 'rg_cpf_cnh'},
    {label: 'Holerite', value: 'holerite'},
    {label: 'Extrato Bancario', value: 'extrato_bancario'},
    {label: 'Comprovacao de posse', value: 'comprovante_posse'},
    {label: 'Dividendos', value: 'dividendos'},
    {label: 'Investimentos', value: 'investimentos'},
    {label: 'Outros', value: 'outros'},
  ]

  actions.setHeaderMainHeader()
  actions.setFooterNotVisible()

  const handleDocChange = async (evt) => {
    const file = evt.target.files
    if (file) {
      actions.setGlobalLoaderAction(true)
      const uploadedFile = await firebaseFilesUploader(file, user.uid, 'user/documents/tenant')
      const data = user?.tenant_additional_documents
      data.push({document: uploadedFile[0], type: getValues('document-type')})
      await userRef.update({tenant_additional_documents: data})
      actions.setGlobalLoaderAction(false)
    }
  }

  const getLabel = (docType) => documentOptions.filter((item) => item.value === docType)[0].label

  return (
    <Fakedoor title={TENANT_DASHBOARD_BLOCK_TITLES.additional_documents} hideWhatsappBtn>
      <Grid container>
        <Grid item xs={12} style={{margin: '10px 0'}}>
          <Typography variant="body1">
            Utilizando a Moben você nunca mais vai precisar ficar procurando comprovantes. Basta fazer o upload dos
            comprovantes no sistema e sempre estará disponível para consultas.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{margin: '10px 0'}}>
          <Typography variant="body1">
            <strong>Tipo de Documento</strong>
          </Typography>
          <FormItem
            control={control}
            errors={errors}
            type="select"
            label="Tipo de Documento"
            name="document-type"
            options={documentOptions}
            rules={{validate: validateRequired}}
          />
        </Grid>
        <Grid item xs={12} style={{margin: '10px 0'}} className={classes.uploadContainer}>
          <label htmlFor="upload-documents" className={classes.uploadBtnWrapper}>
            <span>UPLOAD DE DOCUMENTOS</span>
            <input
              onChange={handleDocChange}
              type="file"
              id="upload-documents"
              accept="application/pdf,image/*"
              multiple
              capture
              style={{display: 'none'}}
            />
          </label>
        </Grid>
        <Grid item xs={12} style={{margin: '10px 0'}}>
          <h3>Documentos Enviados</h3>
          {user?.tenant_additional_documents && (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {user?.tenant_additional_documents.map((item) => (
                <>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <h4 style={{margin: '0 8px 0 0'}}>{getLabel(item.type)}</h4>
                    <img src={checkIcon} alt="Check Icon" />
                  </div>
                  <ul>
                    <li>
                      <a
                        href={item.document.download_url}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.documentName}>
                        {item.document.name_original}
                      </a>
                    </li>
                  </ul>
                </>
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    </Fakedoor>
  )
}

export default TenantDashboardAdditionalDocuments
