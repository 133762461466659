import Confirm from '@root/components/Confirm'
import useSignin from '@root/hooks/useSignin'
import PropTypes from 'prop-types'
import React from 'react'

const ErrorModal = ({signinError, setSigninError}) => {
  return (
    <Confirm
      alertMode={true}
      type="error"
      open={signinError.isOpen}
      content={signinError.humanMessage}
      onOk={() => {
        setSigninError({isOpen: false})
      }}
    />
  )
}

ErrorModal.propTypes = {}
ErrorModal.defaultProps = {}

ErrorModal.openPayload = (message) => {
  return {
    isOpen: true,
    humanMessage: message,
  }
}

ErrorModal.closePayload = () => {
  return {
    isOpen: true,
  }
}

export default ErrorModal
