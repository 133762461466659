import {Grid} from '@material-ui/core'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import PageMini from '@root/components/PageMini'
import {getURL} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import {stepsList} from '@root/lib/contract/contractStepsMap'
import {getStepById} from '@root/lib/contract/stepWalker'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {addApprovalObservationAction} from '@root/redux/approval/approval.actions'
import routes, {routeWithID} from '@root/utils/routes'

const sectionsOptions = [
  {label: 'Outros', value: 'others'},
  ...stepsList
    .filter((step) => step.can_mention)
    .map((step) => ({
      label: step.title,
      value: step.id,
    })),
]
const TenantApprovalObservation = ({history}) => {
  const {control, errors, handleSubmit} = useForm()
  const propertyId = getURL().searchParams.get('id')

  const user = useSelector((s) => s.user.user)
  const {ready, approved} = useSelector((s) => s.approval)

  const actions = actionDispatcher({
    addApprovalObservationAction,
  })

  const addObservation = (form) => {
    actions.addApprovalObservationAction({
      ...form,
      section_name: getStepById(form.section_id)?.title ?? 'Outros',
    })
    history.push(routeWithID(routes.CONTRACT_APPROVAL_SUMMARY, propertyId))
  }

  useEffect(() => {
    if (!ready) {
      history.replace(routeWithID(routes.CONTRACT_APPROVAL, propertyId))
    }
  }, [])

  return (
    <PageMini
      navtitle="Sugestão de alteração/correção"
      innerTitle="Esta sugestão estará sujeita ao aceite do proprietário. Por favor aguarde até que ela possa ser analisada">
      <Grid container>
        <Grid container item direction="column" justify="center">
          <FormItem
            fitContainer
            control={control}
            errors={errors}
            type="select"
            label="Tipo de alteração"
            name="section_id"
            options={sectionsOptions}
            rules={{
              validate: validateRequired,
            }}
          />

          <FormItem
            fitContainer
            control={control}
            errors={errors}
            type="textarea"
            label="Por favor decreva-a"
            name="text"
            rows={8}
            rules={{
              validate: validateRequired,
            }}
            style={{margin: '10px 0'}}
          />
        </Grid>
        <Button m="0 auto" w="182px" h="48px" fz="15px" fw={600} bgType="purple" onClick={handleSubmit(addObservation)}>
          Adicionar
        </Button>
      </Grid>
    </PageMini>
  )
}

export default TenantApprovalObservation
