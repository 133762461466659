import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ErrorModal from '@root/components/App/Login/ErrorModal'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import PageMini from '@root/components/PageMini'
import analyticsLogger from '@root/firebase/analytics.utils'
import firebase from 'firebase/app'
import useSignin from '@root/hooks/useSignin'
import actionDispatcher from '@root/lib/actionDispatcher'
import {isDevEnv, isProductionEnv} from '@root/lib/envDetector'
import errorHandler, {addBreadCrumb} from '@root/lib/errorHandler'
import {onlyNumbers} from '@root/lib/intVal'
import {hasInviteStored} from '@root/lib/invitePath'
import {PROVIDER_PHONE, signIn} from '@root/lib/signIn'
import {validatePhone} from '@root/lib/simpleValidators'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {setRegisterFormAction} from '@root/redux/signin/signin.actions'
import {setCurrentUser} from '@root/redux/user/user.actions'
import {LOADER_MODE_TEXT, PHONE_FORMAT} from '@root/utils/constants'
import routes from '@root/utils/routes'

const useStyles = makeStyles((theme) => ({
  tokenButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // '& > div': {flex: 1},
  },
  naoRecebeu: {
    margin: '40px 0',
    fontWeight: 600,
  },
  verificationInputBlock: {
    color: theme.extraColors.PURPLE,
    borderColor: theme.extraColors.PURPLE,
    borderStyle: 'solid',
    borderRadius: 6,
    padding: 8,
    width: 60,
    height: 60,
    fontSize: 48,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 45,
      height: 45,
      fontSize: '1.2rem',
    },
  },
}))

const VERIFICATION_CODE_LEN = 6
const COUNTDOWN_START = 0 // isProductionEnv() ? 90 : 0
let confirmationResult
let recaptchaVerifier
let countdownInterval

const verificationCodeList = Array.from(new Array(VERIFICATION_CODE_LEN))
const VerificationCode = () => {
  const history = useHistory()
  const {handleUserCreation, handlePostSigninSuccess, handlePostSigninFail, signinError, setSigninError} = useSignin()
  const {reset, getValues, setError, clearErrors} = useForm()
  const captchaRef = useRef()
  const classes = useStyles()

  const isDev = useMemo(() => isDevEnv(), [])

  const verificationInputsRefs = useRef([])

  const registerForm = useSelector((s) => s.signin.register_form)
  const phonenumber = registerForm?.phonenumber

  const [countdown, setCountdown] = useState(COUNTDOWN_START)
  const [isResendSmsAreaVisible, setResendSmsAreaVisibility] = useState(false)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setCurrentUser,
    setRegisterFormAction,
  })
  const handleEvents = (event_type, alternative_event_name, button_name) => {
    analyticsLogger.logEvent(event_type, {
      alternative_event_name,
      button_name,
    })
  }
  const toggleResendArea = () => {
    handleEvents('button_click', 'resend-sms', 'Ainda não recebeu o SMS?')
    setResendSmsAreaVisibility((isVisible) => !isVisible)
  }

  const triggerFieldValidation = (fieldname, validation) => {
    const message = validation(getValues(fieldname))
    const isValid = message === true
    if (!isValid) {
      setError(fieldname, {
        types: 'validate',
        shouldFocus: true,
        message,
      })
    } else {
      clearErrors(fieldname)
    }

    return isValid
  }

  const sendSMSCode = async (phone, validate) => {
    actions.setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, {
      title: 'Verificando identidade',
    })

    try {
      await recaptchaVerifier.verify()
      actions.setGlobalLoaderDataAction({
        title: 'Enviando código de autenticação',
      })

      confirmationResult = await firebase.auth().signInWithPhoneNumber(`+55${onlyNumbers(phone)}`, recaptchaVerifier)
    } catch (err) {
      errorHandler(err)
      setSigninError({
        humanMessage: 'Não foi possível enviar o código via SMS.',
        error: err,
        isOpen: true,
      })
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const resendSms = async () => {
    analyticsLogger.logEvent('reenviar_sms')
    const phone = registerForm.phonenumber || ''
    actions.setRegisterFormAction({
      ...registerForm,
      phonenumber: phone,
    })
    startCountDown()
    clearVerificationCodeInputs()
    await sendSMSCode(phone, true)
    autoFillEmulatorVerificationCode()
  }

  const autoFillEmulatorVerificationCode = () => {
    if (isProductionEnv()) {
      return
    }
    fetch('http://localhost:9005/emulator/v1/projects/dev-moben/verificationCodes')
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.verificationCodes.length > 0) {
          const last = resp.verificationCodes.pop()
          console.log('verification  code:', last.code)
          verificationCodeChange({
            target: {
              getAttribute: () => 'verification-input-0',
              id: 'verification-input-0',
              value: last.code,
            },
          })
        }
      })
      .catch((ex) => {
        console.error('Falha ao obter o codigo de verificacao no emulador.', ex)
      })
  }

  const triggerCodeValidation = () => {
    validateCode({...getValues(), smscode: getVerificationCodeFromInputs()})
  }
  const validateCode = (form) => {
    if (isDev && !confirmationResult) {
      alert(
        'para facilitar(ou nao) nossa vida de dev ao testar no emulador precisa clicar em REENVIAR CODIGO antes de validar.',
      )
      return
    }

    execLogin(PROVIDER_PHONE, {
      verificationId: confirmationResult.verificationId,
      verificationCode: form.smscode,
      ...registerForm,
      ...form,
    })
  }

  const execLogin = async (providerName, extra) => {
    actions.setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, {
      title: hasInviteStored()
        ? 'Preparando seu novo jeito de alugar um imóvel'
        : 'Preparando o seu novo jeito de administrar o imóvel alugado',
    })

    try {
      const authResult = await signIn(providerName, extra)
      const creationPayload = await handleUserCreation(authResult.user)
      handlePostSigninSuccess(creationPayload)
    } catch (ex) {
      handlePostSigninFail(ex)
    }
  }

  const startCountDown = () => {
    setCountdown(COUNTDOWN_START)
    countdownInterval = setInterval(() => {
      let updatedSecs
      setCountdown((secs) => {
        updatedSecs = secs - 1
        return updatedSecs
      })
      if (updatedSecs <= 0) {
        // setCountdown(0)
        clearInterval(countdownInterval)
      }
    }, 1000)
  }

  const getVerificationCodeIndex = (el) => parseInt(el.getAttribute('id').replace('verification-input-', ''))
  const setVerificationCodeRef = (idx) => (el) => (verificationInputsRefs.current[idx] = el)
  const verificationCodeSelect = (e) => {
    e.target.select()
  }

  const getVerificationCodeFromInputs = () => verificationInputsRefs.current.map((el) => el.value).join('')
  const clearVerificationCodeInputs = () => verificationInputsRefs.current.forEach((el) => (el.value = ''))

  const verificationCodeChange = (e) => {
    const idx = getVerificationCodeIndex(e.target)
    const currentEl = e.target
    const nextEl = verificationInputsRefs.current[idx + 1]
    const prevEl = verificationInputsRefs.current[idx - 1]

    const value = currentEl.value.replace(/\D/g, '').substr(0, VERIFICATION_CODE_LEN)
    const valueLen = value.length
    const isValueMaxLen = valueLen === VERIFICATION_CODE_LEN
    const isSingleChar = valueLen === 1
    const isEmpty = value === ''

    if (isEmpty) {
      currentEl.value = ''
      if (prevEl) {
        prevEl.focus()
      }
      return
    }

    if (!isSingleChar) {
      value.split('').forEach((digit, refIdx) => (verificationInputsRefs.current[refIdx].value = digit))

      if (isValueMaxLen) {
        triggerCodeValidation()
      }
      return // fill all the fields and stop func
    }

    if (nextEl) {
      nextEl.focus()
    } else {
      currentEl.blur()
    }

    const isVerificationCodeComplete = getVerificationCodeFromInputs().length === VERIFICATION_CODE_LEN
    if (isVerificationCodeComplete) {
      triggerCodeValidation()
    }
  }

  useEffect(() => {
    if (!registerForm.phonenumber) {
      if (!isDev) {
        history.replace(routes.SIGNUP_REGISTER_OPTIONS)
      } else {
        actions.setRegisterFormAction({
          phonenumber: '(11) 11111-1111',
          email: 'axelle.aikaterine@gmail.com',
          name: 'Axelle Aikaterine',
        })
      }
    } else if (isDev) {
      actions.setGlobalLoaderAction(false)
    }
  }, [])

  useEffect(() => {
    if (!phonenumber && !isDev) {
      actions.setGlobalLoaderAction(false)
      return
    }
    const recaptchaConfig = {
      'size': 'invisible',
      'expired-callback': (err) => {
        addBreadCrumb('recaptcha - expired-callback')
        errorHandler(err)
        alert('Verificação expirada, precisamos recarregar a pagina para que você tente novamente.')
        window.location.reload()
      },
      'error-callback': (err) => {
        addBreadCrumb('recaptcha - error-callback')
        alert(errorHandler(err, 'Falha na verificação da pagina.'))
      },
      'error': async () => {
        addBreadCrumb('recaptcha - error')
      },
      'callback': async (responseId) => {
        addBreadCrumb('recaptcha - success')
        // onVerify(recaptchaVerifier, responseId)
      },
    }
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(captchaRef.current, recaptchaConfig)
    if (!isDev) {
      sendSMSCode(phonenumber, false)
      startCountDown()
    }

    return () => {
      recaptchaVerifier.clear()
      recaptchaVerifier = undefined
    }
  }, [])

  useEffect(() => {
    reset(registerForm)
  }, [registerForm])

  return (
    <PageMini
      navtitle="Validação Token"
      innerTitle={[
        `Foi enviada uma mensagem para o telefone ${registerForm.phonenumber}. Por favor digite abaixo o código recebido.`,
        'O código de verificação pode levar até 90 segundos para ser recebido',
      ]}>
      <div className={classes.tokenButtonContainer}>
        {verificationCodeList.map((_, i) => (
          <div key={i}>
            <input
              onBlur={(e) =>
                handleEvents(
                  `input_out_${e.target.value === '' ? 'clear' : 'with_content'}`,
                  `input-codigo-verificacao-${i}`,
                  `Código de verificação ${i}`,
                )
              }
              type="tel"
              autoComplete="off"
              ref={setVerificationCodeRef(i)}
              onChange={verificationCodeChange}
              onFocus={(e) => {
                handleEvents('input_click', `input-codigo-verificacao-${i}`, `Código de verificação ${i}`)
                verificationCodeSelect(e)
              }}
              id={`verification-input-${i}`}
              className={classes.verificationInputBlock}
            />
          </div>
        ))}
      </div>

      <Typography
        variant="h6"
        color="secondary"
        align="center"
        className={`${classes.naoRecebeu} clickable`}
        onClick={toggleResendArea}>
        Ainda não recebeu o SMS?
      </Typography>

      {isResendSmsAreaVisible && (
        <>
          <Divider dashed />

          {/*<FormItem
            onFocus={() => handleEvents('input_click', 'input-telefone-celular', 'Telefone Celular')}
            onBlur={() =>
              handleEvents(
                `input_out_${watch('phonenuber') === '' ? 'clear' : 'with_content'}`,
                'input-telefone-celular',
                'Telefone Celular',
              )
            }
            fitContainer
            control={control}
            errors={errors}
            type="mask"
            label="Telefone celular"
            name="phonenumber"
            rules={{
              validate: validatePhone,
            }}
            maskInputProps={{
              format: PHONE_FORMAT,
            }}
          />*/}
          <Typography variant="body2" align="center" className={classes.naoRecebeu}>
            Caso você tenha recebido mais de um código, por favor insira o último recebido.
          </Typography>
          <Button
            m="0 auto"
            w="220px"
            h="48px"
            fz="15px"
            fw={600}
            onClick={resendSms}
            disabled={countdown > 0}
            id="resend-sms">
            REENVIAR CÓDIGO {countdown > 0 && <> {countdown}s</>}
          </Button>
        </>
      )}

      <ErrorModal signinError={signinError} setSigninError={setSigninError} />
      <div id="recaptcha-container" ref={captchaRef}></div>
    </PageMini>
  )
}

export default VerificationCode
