import {paymentRepoInstance} from '@root/application/service/repository/payment/PaymentRepository'
import {Button} from '@root/components/Button/Button'
import FilePicker from '@root/components/FilePicker'
import FileUploadPreview from '@root/components/FileUploadPreview'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import PageMini from '@root/components/PageMini'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const MarkAsPaid = () => {
  const url = useQuery()
  const userId = url.getParam('uid')
  const propertyId = url.getParam('iid')
  const paymentId = url.getParam('pid')

  const [file, setFile] = useState()
  const [done, setDone] = useState(false)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })
  const onFileChange = (file) => {
    setFile(file)
  }

  const handlePaid = async () => {
    actions.setGlobalLoaderAction(true)
    try {
      const uploaded = await firebaseUploader(file, '', `property/${propertyId}/document/`)
      await paymentRepoInstance.markAsPaid(paymentId, propertyId, userId, uploaded)
      setDone(true)
    } catch (ex) {
      alert(errorHandler(ex, 'Não foi possivel finalizar o pagamento'))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  if (done) {
    return (
      <PageMini navtitle="Comprovante de pagamento">
        <Text size={18}>Obrigado. Vamos encaminhar o comprovante ao proprietário e daremos baixa no pagamento.</Text>
      </PageMini>
    )
  }
  return (
    <PageMini navtitle="Comprovante de pagamento">
      <TextBlock>
        Para informar o pagamento é necessário o envio do comprovante, que será encaminhado ao proprietário.
      </TextBlock>

      <div style={{margin: '24px 0'}}>
        <FilePicker accept="image/*,.pdf" onChange={onFileChange}>
          {!file && 'Escolher Arquivo'}
          {file && (
            <div style={{margin: '0 auto'}}>
              <FileUploadPreview file={file} width="30%" />
            </div>
          )}
        </FilePicker>
      </div>

      <Button disabled={!file} onClick={handlePaid}>
        Enviar
      </Button>
    </PageMini>
  )
}

MarkAsPaid.propTypes = {}
MarkAsPaid.defaultProps = {}

export default MarkAsPaid
