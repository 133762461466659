/** @module filePickerHandler */
/**
 * Dado um evento to tipo onChange de um input to tipo file, retorna o primeiro item da lista ou undefined
 * @param event
 * @return {File|undefined}
 */
const singleFilePickerHandler = (event) => {
  if (event.target.files && event.target.files.length > 0) {
    return event.target.files[0]
  }
  return undefined
}

export {singleFilePickerHandler}
