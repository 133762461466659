import {dateSortAsc} from '@root/lib/dateSort'

export default class ProposalRepositoryInterface {
  /**
   * @abstract
   * @param {Property.Proposals} proposals
   * @return {Promise<{[key: string]: { user: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentReference>, type: string, data: object }}>}
   */
  loadAll(proposals) {
    throw new Error('code me')
  }

  /**
   * @abstract
   * @param {string} proposalId
   * @param {ProposalEntry} proposalSingle
   * @return {Promise<{[key: string]: { user: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentReference>, type: string, data: object }}>}
   */
  loadSingle(proposalId, proposalSingle) {
    throw new Error('code me')
  }

  /**
   * @abstract
   * @param {string} propertyId
   * @param {string} [mode]
   * @param {string} [name]
   * @return {Promise<{id: string, analysis: boolean, created_at: Date, interesteds: Interested[]}>}
   */
  async createEmpty(propertyId, mode, name) {
    throw new Error('code me')
  }

  // -----------------------------
  /**
   * @param entries
   * @return {Object.entries<Proposal>[]}
   */
  sortEntries = (entries) => {
    const sorted = [...entries]
    sorted.sort((entryA, entryB) => dateSortAsc(entryA[1].created_at, entryB[1].created_at))
    return sorted
  }

  /**
   * @param proposals
   * @return {Object.entries<Proposal>[]}
   */
  sort = (proposals) => this.sortEntries(Object.entries(proposals))
}
