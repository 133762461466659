/** @module fetcher */
import HttpApiInterface from '@root/application/domain/HttpApiInterface'
import {mobenAPIInstance} from '@root/application/infra/datasource/MobenAPI'

/**
 * @param {string} endpoint
 * @param {object} [body]
 * @return {Promise<any>}
 */
const execRequest = async (endpoint, body = undefined) => {
  const method = body ? HttpApiInterface.METHOD_POST : HttpApiInterface.METHOD_GET
  return mobenAPIInstance.request(method, endpoint, body)
}

/**
 * @param {string} endpoint
 * @return {Promise<any>}
 */
const get = (endpoint) => execRequest(endpoint)

/**
 * @param {string} endpoint
 * @param {object} body
 * @return {Promise<any>}
 */
const post = (endpoint, body) => execRequest(endpoint, body)

/**
 *
 * @param obj
 * @return {string}
 */
export const objectToQueryString = (obj, separator = '&') => {
  if (!obj) {
    return ''
  }

  return Object.entries(obj)
    .filter(([prop, value]) => value)
    .map(([prop, value]) => `${prop}=${value}`)
    .join(separator)
}

const fetcher = {
  get,
  post,
}
export default fetcher
