import Typography from '@material-ui/core/Typography'
import useStyles from '@root/components/App/Dashboard/styles'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {setRouteInfoAction} from '../redux/application/application.actions'
import store from '../redux/store'
import PageMini from './PageMini'

const NotFound = () => {
  const classes = useStyles()
  const isLogged = useSelector((s) => s.user.isLogged)
  const routeInfo = useSelector((s) => s.application.routeInfo)

  useEffect(() => {
    let newRouteInfo = {...routeInfo}
    newRouteInfo.isHeaderless = true
    store.dispatch(setRouteInfoAction(newRouteInfo))
  }, [isLogged])

  if (!isLogged) {
    return (
      <PageMini>
        <main className={classes.dashboardTenant}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h5">Ops! Não conseguimos encontrar a pagina que você procura.</Typography>
        </main>
      </PageMini>
    )
  } else {
    return (
      <main className={classes.dashboardTenant}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h5">Ops! Não conseguimos encontrar a pagina que você procura.</Typography>
      </main>
    )
  }
}

export default NotFound
