import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@material-ui/core'
import {firestore} from '@root/firebase/firebase.utils'
import propertyModel from '@root/model/propertyModel'
import userModel from '@root/model/userModel'
import React, {useEffect, useMemo, useState} from 'react'
import {CSVLink} from 'react-csv'
import {useDispatch} from 'react-redux'
import {useTable} from 'react-table'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

import useActiveContracts from './useActiveContracts'

const ActiveContracts = ({hits}) => {
  const dispatch = useDispatch()
  const {columns, data} = useActiveContracts()
  const {getTableProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data,
  })

  useEffect(() => {
    dispatch(setNavbarTitleAction('Contratos Ativos'))
  }, [])

  const csvData = useMemo(() => {
    return data.map((d) => Object.values(d))
  }, [data])

  const csvHeaders = useMemo(() => columns.map((c) => c.Header), [columns])

  /* eslint-disable */
  return (
    <>
      <div style={{marginTop: -20, marginBottom: 20}}>
        <CSVLink filename="contratos-ativos.csv" data={csvData} headers={csvHeaders}>
          Download CSV
        </CSVLink>
      </div>
      <TableContainer style={{maxHeight: 'calc(100vh - 200px)'}}>
        <Table
          {...getTableProps()}
          stickyHeader
          aria-label="sticky table"
          style={{width: '100%', overflowX: 'scroll', minWidth: 1350}}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell style={{fontWeight: 600, fontSize: '1rem'}} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell style={{fontStyle: 'normal', fontSize: '1rem'}} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ActiveContracts
