import Typography from '@material-ui/core/Typography'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import {Button} from '@root/components/Button/Button'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import Title from '@root/components/Layout/Typography/Title'
import {useStyles} from '@root/components/YourProperty/styles'
import useContract from '@root/hooks/useContract'
import dateParser, {convertDateToBr, fromTimestampToDate, getMonthYear} from '@root/lib/dateParser'
import {dateSortAsc, dateSortDesc} from '@root/lib/dateSort'
import {paymentMethodsMap} from '@root/lib/paymentMethods'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import paymentMapper from 'status-mapper/mapper/payment-mapper'
import paymentMethodMapper from 'status-mapper/mapper/payment-method-mapper'
import {getBillsIncludedInRent, getBillsIncludedInRentWithAmount} from '@root/utils/rentfeesCalc'
import routes from '@root/utils/routes'

const SectionPayment = () => {
  const classes = useStyles()
  const history = useHistory()

  const [currentPayment, setCurrentPayment] = useState()
  const hasPayment = currentPayment !== undefined

  const property = useSelector((state) => state.property.data)
  const payments = property?.contract?.payments ?? []
  const paymentsSize = payments.length
  const hasPayments = paymentsSize > 0
  const firstPayment = hasPayments && payments[0]

  const readjustmentHistory = property?.contract?.readjustment_history ?? []
  const readjustmentHistorySize = readjustmentHistory.length
  const hasReadjustmentHistory = readjustmentHistorySize > 0
  const lastReadjustment = hasReadjustmentHistory ? readjustmentHistory[readjustmentHistorySize - 1] : undefined

  const nextReadjustment = property?.contract?.date_next_readjustment_index ?? undefined
  const hasNextReadjustment = nextReadjustment !== undefined
  const nextReadjustmentPayment = hasNextReadjustment && hasPayments ? payments[nextReadjustment] : undefined

  const {isInProgress} = useContract(property)

  const paymentMethod = property?.contract?.payment_method?.option

  // TODO isso aqui não está correto, depois tem que filtrar pelos pagamentos pendentes.
  useEffect(() => {
    const currentDate = new Date()
    if (payments) {
      let curPayment = payments.find((payment) => dateParser(payment.due_date).getMonth() === currentDate.getMonth())
      const lastPayment = payments.pop()
      if (!curPayment && lastPayment) {
        const insideValidity = currentDate <= dateParser(lastPayment.due_date)
        if (insideValidity) {
          curPayment = payments[0]
        }
      }
      setCurrentPayment(curPayment)
    }
  }, [payments])

  const handleControleOnClick = () => {
    let path = routes.DASHBOARD_BOLETOS_STATUS
    if (!paymentMethod) {
      path = routes.DASHBOARD_BOLETO_FORM
    }
    history.push(path)
  }

  return (
    <div className={classes.sectionItem}>
      <Title bold>Recebimentos</Title>
      {!hasPayment && (
        <>
          <Typography variant="subtitle1">Contrato ainda não foi aprovado e assinado</Typography>
        </>
      )}

      {isInProgress && (
        <>
          <DataDisplay asTable label="Aluguel inicial" value={firstPayment.amount} readableType="money" />
          {lastReadjustment && (
            <DataDisplay
              asTable
              label="Último reajuste anual"
              value={lastReadjustment.created_at}
              readableType="date"
            />
          )}
          {nextReadjustmentPayment && (
            <DataDisplay
              asTable
              label="Próximo pagamento reajustado"
              value={nextReadjustmentPayment.due_date}
              readableType="date"
            />
          )}

          <br />
          <Text bold>Valores inclusos na cobrança Moben</Text>
          {getBillsIncludedInRentWithAmount(property.contract.rentfees, true, true).map((entry, key) => (
            <DataDisplay asTable key={key} label={entry.label} value={entry.amount} readableType="money" />
          ))}
        </>
      )}

      {paymentMethod && hasPayment && (
        <Box bgType="gray" smallPadding onClick={handleControleOnClick}>
          <Text>Método: {paymentMethodMapper.translate(paymentMethod)}</Text>
          <Text>
            Atual - Vencimento: {convertDateToBr(currentPayment.due_date)} -{' '}
            {paymentMapper.translate(currentPayment.status)}
          </Text>

          <div className={classes.paymentGoToIconWrapper}>
            <IconWrapper>
              <LaunchOutlinedIcon />
            </IconWrapper>
          </div>
        </Box>
      )}
    </div>
  )
}

export default SectionPayment
