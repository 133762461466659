import useOptimize from '@root/hooks/useOptimize'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

export const MatcherOption = ({variant, children}) => {
  return children
}
MatcherOption.displayName = 'OptimizeMatcher.Match'
MatcherOption.propTypes = {
  variant: PropTypes.string.isRequired,
}

const OptimizeMatcher = ({alias, children}) => {
  const matcher = {default: null}
  const [variantElement, setVariantElement] = useState(null)
  const variantId = useSelector((s) => s.application.optimize[alias])

  useEffect(() => {
    let variant
    const childrenList = Array.isArray(children) ? children : [children]

    childrenList.forEach((element) => {
      if (React.isValidElement(element)) {
        variant = element.props.variant
        if (!variant) {
          matcher.default = element
        } else {
          matcher[variant] = element
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!alias) {
      console.log('no alias')
      return
    }

    const variant = variantId ?? 'default'
    const el = matcher[variant] ?? matcher.default
    console.log('optimize', alias, variantId, variant)
    setVariantElement(el)
  }, [variantId])

  return <>{variantElement}</>
}
OptimizeMatcher.propTypes = {
  alias: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(MatcherOption),
}
OptimizeMatcher.Option = MatcherOption

export default OptimizeMatcher
