/** module stepsMap */
import OwnerBankInfo from '@root/components/Contract/BankAccount/OwnerBankInfo'
import {ContractCreated} from '@root/components/Contract/ContractCreated'
import {ExtraInformation} from '@root/components/Contract/ExtraInformation'
import {FireInsurance} from '@root/components/Contract/FireInsurance'
import {Forum} from '@root/components/Contract/Forum'
import Guarantee from '@root/components/Contract/Guarantee'
import OwnerAddress from '@root/components/Contract/OwnerAddress'
import OwnerInfo from '@root/components/Contract/OwnerInfo'
import {Penalties} from '@root/components/Contract/Penalties'
import {ReadjustmentIndex} from '@root/components/Contract/ReajustmentIndex/ReajustmentIndex'
import {RentDue} from '@root/components/Contract/RentDue'
import {RentFees} from '@root/components/Contract/RentFees'
import Survey from '@root/components/Contract/Survey'
import TenantInfo from '@root/components/Contract/TenantInfo'
import {Validity} from '@root/components/Contract/Validity'
import {Witnesses} from '@root/components/Contract/Witnesses'
import {PropertyRegister} from '@root/components/PropertyRegister/PropertyRegister'
import routes from '@root/utils/routes'

export const stepsList = [
  {
    id: 'ownerdata',
    path: routes.CONTRACT_OWNER_DATA,
    reducer: undefined,
    component: OwnerInfo,
    title: 'Dados do proprietário',
    has_form: true,
    can_mention: false,
    display_on_panel: false,
  },
  {
    id: 'owneraddress',
    path: routes.CONTRACT_OWNER_ADDRESS,
    reducer: undefined,
    component: OwnerAddress,
    title: 'Residência do proprietário',
    has_form: true,
    can_mention: false,
    display_on_panel: false,
  },
  {
    id: 'bankinfo',
    path: routes.CONTRACT_BANK_INFO,
    reducer: undefined,
    component: OwnerBankInfo,
    title: 'Dados bancários de recebimento',
    has_form: true,
    can_mention: false,
    display_on_panel: false,
  },
  {
    id: 'property',
    path: routes.CONTRACT_PROPERTY,
    reducer: 'property',
    component: PropertyRegister,
    title: 'Dados do imóvel',
    has_form: true,
    can_mention: true,
    display_on_panel: false,
  },
  {
    id: 'validity',
    path: routes.CONTRACT_VALIDITY,
    reducer: 'validity',
    component: Validity,
    title: 'Vigência',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'rentdue',
    path: routes.CONTRACT_RENTDUE,
    reducer: 'rentdue',
    component: RentDue,
    title: 'Vencimento do aluguel',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  /*{
    id: 'fire_insurance',
    path: routes.CONTRACT_FIRE_INSURANCE,
    reducer: 'fire_insurance',
    component: FireInsurance,
    title: 'Seguro Residencial',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },*/
  {
    id: 'rentfees',
    path: routes.CONTRACT_RENTFEES,
    reducer: 'rentfees',
    component: RentFees,
    title: 'Valor do aluguel',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'penalties',
    path: routes.CONTRACT_PENALTIES,
    reducer: 'penalties',
    component: Penalties,
    title: 'Multas',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'readjustment_index',
    path: routes.CONTRACT_READJUSTMENT_INDEX,
    reducer: 'readjustment_index',
    component: ReadjustmentIndex,
    title: 'Índice de reajuste',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'forum',
    path: routes.CONTRACT_FORO,
    reducer: 'forum',
    component: Forum,
    title: 'Foro',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'tenant',
    path: routes.CONTRACT_TENANT,
    reducer: 'tenant',
    component: TenantInfo,
    title: 'Dados do inquilino',
    has_form: true,
    can_mention: true,
    display_on_panel: false,
  },
  {
    id: 'guarantees',
    path: routes.CONTRACT_GUARANTEES,
    reducer: 'guarantees',
    component: Guarantee,
    title: 'Garantia',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'survey',
    path: routes.CONTRACT_SURVEY,
    reducer: 'survey',
    component: Survey,
    title: 'Vistoria',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'witnesses',
    path: routes.CONTRACT_WITNESSES,
    reducer: 'witnesses',
    component: Witnesses,
    title: 'Testemunhas',
    has_form: true,
    can_mention: true,
    display_on_panel: true,
  },
  {
    id: 'extrainfo',
    path: routes.CONTRACT_EXTRA_INFO,
    reducer: undefined,
    component: ExtraInformation,
    title: 'Assinatura Eletrônica',
    has_form: false,
    can_mention: false,
    display_on_panel: false,
  },
  {
    // é aqui que manda o email para o inquilino aprovar, de la vai para cadastro escolhido
    id: 'created',
    path: routes.CONTRACT_CREATED,
    reducer: undefined,
    component: ContractCreated,
    title: undefined,
    has_form: false,
    can_mention: false,
    display_on_panel: false,
  },
]

export const stepFireInsurance = {
  id: 'fire_insurance',
  path: routes.CONTRACT_FIRE_INSURANCE,
  reducer: undefined,
  component: null,
  title: 'Seguro Residencial',
  has_form: false,
  can_mention: false,
  display_on_panel: true,
}

const contractStepsMap = stepsList.map((step, index) => ({...step, path_orig: step.path, index}))
export default contractStepsMap
