import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {AdminTypes} from '@root/redux/admin/admin.types'

const initialState = {
  property: undefined,
  owner: undefined,
  real_owner: undefined,
}
const reducers = {
  [AdminTypes.SET_PROPERTY]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.property = payload.property
    })
  },
  [AdminTypes.SET_OWNER]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.owner = payload.owner
      draft.real_owner = payload.realOwner
    })
  },
}

export default reducerMatch(reducers, initialState)
