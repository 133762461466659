import {convertEnumeration} from '@root/lib/contract/enumeration'

const sectionsTransalteMap = {
  clauses: 'Clausula',
  appendixes: 'Anexo',
}

const actionsTransalteMap = {
  add: 'Adicionar',
  edit: 'Editar',
  delete: 'Deletar',
}

export const sectionTranslate = (section) => {
  return sectionsTransalteMap[section] || section
}

export const actionsTranslate = (action) => {
  return actionsTransalteMap[action] || action
}
const incrementPath = (piece) => {
  const num = parseInt(piece)
  if (isNaN(num)) {
    return sectionTranslate(piece)
  }
  return num + 1
}
export const pathToEnumeration = (path, keepSection = false, type) => {
  let pieces = xpathStrToList(path)
  const sliceSize = keepSection ? 0 : 1
  pieces = pieces.slice(sliceSize).map((piece) => incrementPath(piece, type))
  if (type) {
    const lastIdx = pieces.length - 1
    pieces[lastIdx] = convertEnumeration(pieces[lastIdx], type)
  }
  return pieces.join('.')
}

/**
 * @param {string|string[]} xpath
 * @return {string[]}
 */
const xpathStrToList = (xpath) => {
  if (typeof xpath === 'string') {
    return xpath.split('.')
  }
  return xpath
}

/**
 * @param {string|string[]} xpath
 * @param {object} rawContract
 * @return {*}
 */
const finder = (xpath, rawContract) => {
  const pieces = xpathStrToList(xpath)
  const mainEntry = pieces.shift()
  const startIndex = pieces.shift()
  const startItem = rawContract[mainEntry][startIndex]
  let curItem = startItem
  pieces.forEach((piece) => {
    curItem = curItem.items[piece]
  })

  return curItem
}

export const xpathFinder = (xpath, rawContract) => {
  return finder(xpath, rawContract)
}

/**
 *
 * @param {string} xpath
 * @param {object} rawContract
 * @return {{parent: *, target: string}}
 */
export const xpathFinderParent = (xpath, rawContract) => {
  const pieces = xpathStrToList(xpath)
  const target = pieces.pop()

  return {
    parent: finder(pieces, rawContract),
    target,
  }
}
