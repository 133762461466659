/** @module signIn */
import 'firebase/auth'

import firebase from 'firebase/app'
import {auth} from '@root/firebase/firebase.utils'
import storage from '@root/lib/storage'
import {logoutAction} from '@root/redux/signin/signin.actions'
import store from '@root/redux/store'
import {STORAGE_COOKIES, STORAGE_GUIDE1} from '@root/utils/constants'

const PROVIDER_GOOGLE = firebase.auth.GoogleAuthProvider.PROVIDER_ID
const PROVIDER_FACEBOOK = firebase.auth.FacebookAuthProvider.PROVIDER_ID
const PROVIDER_CREDENTIALS = firebase.auth.EmailAuthProvider.PROVIDER_ID
const PROVIDER_PHONE = firebase.auth.PhoneAuthProvider.PROVIDER_ID
const LINK_PROVIDER_CODE = 'auth/account-exists-with-different-credential'

const humanErrorMessageMap = {
  'auth/cancelled-popup-request': 'Mais de uma solicitação de login esta em andamento.',
  'auth/popup-closed-by-user':
    'A solicitação de login cancelada, a janela deve permanecer aberta ate a finalização do processo.',
  'auth/operation-not-allowed': 'Esse tipo de login não é permitido.',
  'auth/user-disabled': 'Conta inativa',
  'auth/popup-blocked': 'O navegador impediu a janela de login de ser aberta.',
  'auth/unauthorized-domain': 'Dominio não esta habilitado',
  'auth/account-exists-with-different-credential':
    'O email "%email" já esta cadastrado, mas foi usado outro método de login. Tente fazer o login novamente com outro provedor, como Google, Facebook ou Telefone',
  'auth/network-request-failed':
    'Parece que houve um erro na rede e não foi possível se comunicar com o servidor de validação.',
  'auth/invalid-phone-number': 'Número de telefone inválido.',
  'auth/missing-verification-code': 'Código de verificação não foi encontrado',
  'auth/missing-verification-id': 'ID de verificação não foi encontrado',
  'auth/invalid-verification-code': 'Código de verificação inválido.',
  'auth/code-expired': 'Código de verificação expirado, solicite um novo.',

  'auth/invalid-email': 'E-mail inválido',
  'auth/user-not-found': 'Conta não encontrada',
  'auth/wrong-password': 'E-mail ou senha incorreto',
  'auth/email-already-in-use': 'E-Mail já esta cadastrado',
  'auth/weak-password': 'Senha muito fraca, inclua números letras e caracteres especiais',
  'auth/expired-action-code': 'Código para recuperação de senha expirado',
  'auth/invalid-action-code': 'Código para recuperação de senha inválido ou já foi usado',

  'auth/missing-android-pkg-name':
    'An Android package name must be provided if the Android app is required to be installed.',
  'auth/missing-continue-uri': 'A continue URL must be provided in the request.',
  'auth/missing-ios-bundle-id': 'An iOS Bundle ID must be provided if an App Store ID is provided.',
  'auth/invalid-continue-uri': 'The continue URL provided in the request is invalid.',
  'auth/unauthorized-continue-uri':
    'The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.',
}

/**
 * @param {string} providerName google, facebook, credentials
 * @param {object?} extraData dados extras para o provider, email e senha por ex
 * @return {firebase.auth.AuthProvider|firebase.auth.AuthCredential}
 */
const getProvider = (providerName, extraData) => {
  let provider
  switch (providerName) {
    case PROVIDER_GOOGLE:
      provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({prompt: 'select_account'})
      break
    case PROVIDER_FACEBOOK:
      provider = new firebase.auth.FacebookAuthProvider()
      break
    case PROVIDER_CREDENTIALS:
      provider = firebase.auth.EmailAuthProvider.credential(extraData.email, extraData.password)
      break
    case PROVIDER_PHONE:
      provider = firebase.auth.PhoneAuthProvider.credential(extraData.verificationId, extraData.verificationCode)
      break
    default:
      throw `provider name ${providerName} not found`
  }

  return provider
}

export const getHumanSigninErrorMessage = (signinError, defaultMessage) => {
  const {email, code} = signinError
  if (!defaultMessage) {
    defaultMessage = 'Não foi possível fazer o login.\n' + (code ?? '')
  }
  if (humanErrorMessageMap[code]) {
    const errMessage = humanErrorMessageMap[code]
    return errMessage.replace('%email', email)
  } else {
    return defaultMessage
  }
}

/**
 * Faz o login o devido provider e cria um usuario no banco com o payload inicial
 * @param {Promise<firebase.auth.UserCredential>} authPromise
 * @param {firebase.auth.AuthProvider} provider
 * @param {Promise<firebase.auth.UserCredential>} extraData
 * @return {Promise<{user:object,auth:firebase.auth.UserCredential, userRef:firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, isFirstLogin:boolean}>}
 */
const defaultSignInPromiseHandler = (authPromise, provider = undefined, extraData = undefined) => {
  return new Promise((resolve, reject) => {
    authPromise.then(resolve).catch((err) => {
      reject({
        humanMessage: getHumanSigninErrorMessage(err),
        providerErr: err,
      })
    })
  })
}

/**
 * @param {string} providerName
 * @param {object} extraData dados extras para o provider, email e senha por exemplo.
 * @return {Promise<{user:object,auth:firebase.auth.UserCredential}>}
 */
const signIn = (providerName, extraData = undefined) => {
  const provider = getProvider(providerName, extraData)
  let authPromise

  if (providerName === PROVIDER_PHONE) {
    authPromise = auth.signInWithCredential(provider)
  } else if (providerName === PROVIDER_CREDENTIALS) {
    if (extraData.create) {
      authPromise = auth.createUserWithEmailAndPassword(extraData.email, extraData.password)
    } else {
      authPromise = auth.signInWithEmailAndPassword(extraData.email, extraData.password)
    }
  } else {
    authPromise = auth.signInWithCredential(provider)
    authPromise = auth.signInWithPopup(provider)
  }

  return defaultSignInPromiseHandler(authPromise, provider, extraData)
}

const signOut = (history) => {
  auth.signOut()
  storage.clearExcept([STORAGE_COOKIES, STORAGE_GUIDE1])
  store.dispatch(logoutAction())
  // store.dispatch(setUserNull())
  history.replace('/')
}
export {PROVIDER_GOOGLE, PROVIDER_FACEBOOK, PROVIDER_CREDENTIALS, PROVIDER_PHONE, signIn, signOut}
