import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import getContractDownloadURL from '@root/components/App/Contract/getContract'
import {Button} from '@root/components/Button/Button'
import {ContractButton} from '@root/components/Contract/ContractButton'
import ContractNotification from '@root/components/ContractNotification/ContractNotification'
import BaseModal from '@root/components/Modal/BaseModal'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import {getStepByPath, getStepsIndexRoute} from '@root/lib/contract/stepWalker'
import {discordNotifyContractSentToTenant} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {addContractObservationAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import routes from '@root/utils/routes'

import {useStyles} from './styles'

let contractPreviewWindow
export function ContractCreated() {
  const classes = useStyles()
  const history = useHistory()

  const [snackDataMail, setSnackDataMail] = useState()
  const [showLoaderMessageSuccess, setShowLoaderMessageSuccess] = useState(false)
  const [isCopySnackVisible, setCopySnackVisibility] = useState(false)
  const [isSentToTenantVisible, setSentToTenantVisibility] = useState(false)

  const user = useSelector((s) => s.user.user)
  const propertySnap = useSelector((s) => s.property.snap)
  const property = useSelector((s) => s.property.data)
  const propertyId = useSelector((s) => s.property.currentPropertyId)
  const notificationsList = useSelector((s) => s.property.data?.contract?.observations)
  const notifications = notificationsList ?? []

  const approvedProp = property?.contract?.approval
  const {
    isInProgress,
    isContractBlocked,
    canContractBeGenerated,
    isContractEditable,
    hasContractGenerated,
    generateContract,
    mailTenant,
  } = useContract(property)

  const pdfLink = getContractDownloadURL(property)
  const proposalHelper = useMemo(() => ProposalHelper.initWithProperty(property), [propertyId])

  const actions = actionDispatcher({
    setContractStepAction,
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    addContractObservationAction,
    setNavbarTitleAction,
  })

  const editContract = () => {
    history.push(getStepsIndexRoute(property.uid))
  }

  const closeSnackMail = () => setSnackDataMail()

  const goToPayments = () => {
    history.push(routes.DASHBOARD_BOLETOS_STATUS)
  }

  const handleGenerateContract = () => generateContract(propertySnap, user, closeContract)

  const handleSendTenantMail = async () => {
    if (!hasContractGenerated) {
      setSentToTenantVisibility(true)
      return
    }

    if (canContractBeGenerated) {
      await handleGenerateContract()
    }
    sendMailToTenant()
  }

  const sendMailToTenant = () => {
    mailTenant()
    discordNotifyContractSentToTenant(property, pdfLink)
  }

  const handleViewContract = async (e) => {
    e.preventDefault()

    prepareOpen()
    if (canContractBeGenerated || !hasContractGenerated) {
      try {
        const contractResp = await handleGenerateContract()
        openContract(contractResp.download_url)
        return
      } catch (ex) {
        closeContract()
      }
    }
    openContract(pdfLink)
  }

  const prepareOpen = () => {
    contractPreviewWindow = window.open('/contract-loader.html', '_blank')
    // contractPreviewWindow.document.write('')
  }
  const openContract = (url) => {
    contractPreviewWindow.location.href = url
  }
  const closeContract = () => {
    contractPreviewWindow.close()
  }

  const handleGenerateContractBeforeSent = (e) => {
    setSentToTenantVisibility(false)
    handleViewContract(e)
  }

  const handleSentWithoutRead = async () => {
    setSentToTenantVisibility(false)
    await handleGenerateContract()
    await sendMailToTenant()
  }

  const onRefuseObservation = async (observation, id) => {
    actions.setGlobalLoaderAction(true)
    // const obs = observations[index]
    try {
      const newObsList = notifications.map((obs) => {
        const newObs = {...obs}
        if (obs.id === id) {
          newObs.status = 'refused'
        }
        return newObs
      })
      await propertySnap.ref.update({
        'contract.observations': newObsList,
      })
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const openSnack = () => {
    setShowLoaderMessageSuccess(true)
  }
  const closeSnack = () => {
    setShowLoaderMessageSuccess(false)
    setCopySnackVisibility(false)
  }

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    // actions.setNavbarTitleAction(addressDisplay(property.address, ADDRESS_VERBOSITY_COMPLETE))
    actions.setNavbarTitleAction(property.alias)
  }, [property])

  useEffect(() => {
    const run = async () => {
      actions.setGlobalLoaderAction(true)
      await proposalHelper.loadUsersRefs()
      actions.setGlobalLoaderAction(false)
    }
    run()
  }, [proposalHelper])

  return (
    <div className={classes.contractCreated}>
      <header>
        <Typography variant="body2">
          Parabéns! Você concluiu todo o processo de criação do contrato. Leia com atenção todo o contrato e veja se não
          ficou faltando nada. Depois disso clique em “Enviar para Inquilino” para que ele possa ler e aprovar o
          contrato. Assim que ele estiver “ok” com o documento, enviaremos para a assinatura eletrônica. Caso seja
          necessário, você poderá voltar e “Editar” seu contrato antes da assinatura eletrônica.
        </Typography>

        <div className="buttonsContainer">
          <ContractButton type="visualizar" onClick={handleViewContract} />

          {!isInProgress && (
            <>
              {isContractEditable && (
                <ContractButton type="editar" onClick={editContract} disabled={isContractBlocked} />
              )}
              <ContractButton type="enviariq" onClick={handleSendTenantMail} />
            </>
          )}

          {isInProgress && <ContractButton type="recebimento" onClick={goToPayments} />}
        </div>
      </header>

      <section className="notificationsSection">
        <ContractNotification allowEdit={true} notifications={notifications} onRefuse={onRefuseObservation} />
      </section>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={4000}
        open={showLoaderMessageSuccess}
        onClose={closeSnack}>
        <Alert severity="success">email enviado</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={7000}
        open={isCopySnackVisible}
        onClose={closeSnack}>
        <Alert severity="success">O link para aprovação do inquilino foi copiado para a area de transferência.</Alert>
      </Snackbar>

      <BaseModal
        isOpen={isSentToTenantVisible}
        onClose={() => setSentToTenantVisibility(false)}
        title="Enviar o contrato para o inquilino"
        okLabel=""
        cancelLabel="">
        <>
          <Typography
            variant="h5"
            align="center"
            style={
              {
                // color: config.theme.color,
              }
            }>
            Você ainda não leu o seu contrato, tem certeza que deseja enviar para o inquilino aprovar?
          </Typography>
          <Button bgType="green" h="auto" onClick={handleSentWithoutRead}>
            Sim, enviar para o inquilino
          </Button>
          <Button bgType="purple" h="auto" onClick={handleGenerateContractBeforeSent}>
            Não, ler contrato
          </Button>
        </>
      </BaseModal>
    </div>
  )
}
