/* eslint-disable react/prop-types */
import {Avatar} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Button} from '@root/components/Button/Button'
import FilePicker from '@root/components/FilePicker'
import InviteForm from '@root/components/Forms/InviteForm'
import IconCheck from '@root/components/icons/IconCheck'
import IconPencil from '@root/components/icons/IconPencil'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import userModel from '@root/model/userModel'
import React, {useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

import useStyles from './styles'

const useMyStyles = makeStyles((theme) => ({
  root: {
    margin: '16px 0px',
    display: 'flex',
    position: 'relative',
    width: '128px',
    height: '128px',
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',

    width: '28px',
    height: '28px',
    borderRadius: '8px',
    backgroundColor: '#831ccd',

    bottom: 10,
    right: 10,
    zIndex: 100,
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
    backgroundColor: '#e1e2e3',
  },
  buttonContainer: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const Profile = () => {
  const formHook = useForm()
  const {handleSubmit} = formHook

  const myClasses = useMyStyles()
  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const user = useSelector((state) => state.user.user)
  const userRef = useSelector((state) => state.user.firebaseRef)

  const [avatar, setAvatar] = useState(user?.photoURL ?? null)

  const saveProfile = async (data) => {
    actions.setGlobalLoaderAction(true)
    try {
      await userRef.update(
        userModel.prepareForDatabase({
          ...user,
          ...data,
        }),
      )
      //TODO notificar de alguma forma que o form soi salvo
    } catch (ex) {
      alert(errorHandler(ex), 'Erro ao salvar os dados do seu perfil')
    }
    actions.setGlobalLoaderAction(false)
  }

  async function handleUploadImage(e) {
    let uploadImageStatus
    actions.setGlobalLoaderAction(true)

    try {
      uploadImageStatus = await firebaseUploader(e, user.uid, `users/${user.uid}`)
    } catch (error) {
      alert('Houve um erro ao enviar a imagem!')
      actions.setGlobalLoaderAction(false)
      return
    }

    try {
      await userRef.update({photoURL: uploadImageStatus.download_url})
      setAvatar(uploadImageStatus.download_url)
    } catch (ex) {
      alert(errorHandler(ex), 'Erro ao salvar os dados do seu perfil')
      actions.setGlobalLoaderAction(false)
      return
    }
    actions.setGlobalLoaderAction(false)
  }

  return (
    <FormProvider {...formHook}>
      <div className={myClasses.root}>
        <FilePicker onChange={handleUploadImage}>
          <div className={myClasses.button}>
            <IconPencil />
          </div>
        </FilePicker>

        <Avatar src={avatar} className={myClasses.image} />
      </div>

      <InviteForm user={user} displayUploads={false} displayIncomeField={false} allowFullEdit={false} />

      <div className={myClasses.buttonContainer}>
        <Button icon={IconCheck} onClick={handleSubmit(saveProfile)}>
          Salvar alterações
        </Button>
      </div>
    </FormProvider>
  )
}

export default Profile
