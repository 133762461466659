/*
  ESQUEMA CORES:
    nomeclatura de 1-N
    conforme o numero aumenta, a cor fica mais escura.
*/

const colors = {
  purple: '#8301CD',
  blue1: '#0C2DD1',
  blue2: '#2207D6',
  blueDark1: '#1C08A7',
  blueDark2: '#120377',
  blueDark3: '#0C005F',
  white: '#FFF',
  grey1: '#e2e2e2',
  grey2: '#999',
  whatsapp: '#25D366',
  grey3: '#444',
}

const sizes = {
  dashboardBlockRadius: 10,
}

const effects = {
  dashboardBlockShadow: '3px 4px 5px rgba(0, 0, 0, 0.2)',
}

const css = {
  colors,
  sizes,
  effects,
}

export default css
