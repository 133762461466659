/** @module banksList */
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'

export const banksMap = [
  {value: 1, label: '1 - Banco do Brasil S.A.'},
  {value: 33, label: '33 - BANCO SANTANDER (BRASIL) S.A.'},
  {value: 69, label: '69 - Banco Crefisa S.A.'},
  {value: 77, label: '77 - Banco Inter S.A.'},
  {value: 104, label: '104 - CAIXA ECONOMICA FEDERAL'},
  {value: 212, label: '212 - Banco Original S.A.'},
  {value: 237, label: '237 - Banco Bradesco S.A.'},
  {value: 260, label: '260 - Nu Payments S.A.'},
  {value: 290, label: '290 - Pagseguro Internet S.A.'},
  {value: 299, label: '299 - SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.'},
  {value: 318, label: '318 - Banco BMG S.A.'},
  {value: 323, label: '323 - MERCADOPAGO.COM REPRESENTACOES LTDA.'},
  {value: 336, label: '336 - Banco C6 S.A.'},
  {value: 341, label: '341 - ITAÚ UNIBANCO S.A.'},
  {value: 389, label: '389 - Banco Mercantil do Brasil S.A.'},
  {value: 655, label: '655 - Banco Votorantim S.A.'},
  {value: 745, label: '745 - Banco Citibank S.A.'},
]

export const findBankByCode = (code) => banksMap.find((item) => item.value == code)

export const findBankByExactName = (name) => banksMap.find((item) => item.label === name)

export const bankCodeToName = (code) => {
  const bank = findBankByCode(code)

  if (bank) {
    return bank.label
  }

  return ''
}
export const banksMenuItens = () => {
  return banksMap.map(({label, value}) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ))
}

/**
 * @param {object} bank
 * @param {string} bank.legal_name
 * @param {string} bank.document_number
 * @param {string} bank.type
 * @param {string} bank.label
 * @param {string} bank.agency
 * @param {string} bank.agency_digit
 * @param {string} bank.account
 * @param {string} bank.account_digit
 * @return {string}
 */
export const bankDataToText = (bank) => {
  const types = {
    conta_corrente: 'Conta corrente',
    conta_poupanca: 'Conta poupança',
    conta_corrente_conjunta: 'Conta corrente conjunta',
    conta_poupanca_conjunta: 'Conta Poupança conjunta',
  }
  return `Nome do titular: ${bank.legal_name}
Documento: ${bank.document_number}
Tipo de conta: ${types[bank.type]}
Instituição: ${bank.label}
Agência: ${bank.agency}-${bank.agency_digit}
Conta: ${bank.account}-${bank.account_digit}`
}
