/** @module proposalSort */

import {proposalRepoFireInstance} from '@root/application/service/repository/proposal/ProposalRepositoryFirestore'

/**
 * @deprecated
 * @see ProposalRepositoryInterface.sortEntries
 * @param entries
 * @return {Object.entries<Proposal>[]}
 */
export const proposalsSortEntries = (entries) => proposalRepoFireInstance.sortEntries(entries)

/**
 * @deprecated
 * @see ProposalRepositoryInterface.sort
 * @param proposals
 * @return {Object.entries<Proposal>[]}
 */
export const proposalsSort = (proposals) => proposalRepoFireInstance.sort(Object.entries(proposals))
