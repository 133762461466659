import {SigninTypes} from '@root/redux/signin/signin.types'

export const logoutAction = () => ({type: SigninTypes.LOG_OUT})

export const setRegisterFormAction = (form) => {
  return {
    type: SigninTypes.SET_REGISTER_FORM,
    payload: {form},
  }
}

export const setTermsAction = (accepted) => {
  return {
    type: SigninTypes.SET_ACCEPTED_TERMS,
    payload: {accepted},
  }
}

export const setSignInExtra = (extraData) => {
  return {
    type: SigninTypes.SET_EXTRA_DATA,
    payload: {extraData},
  }
}
