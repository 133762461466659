import {Dialog, Grid} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Document, Page, pdfjs} from 'react-pdf'
import {serializeFile} from '@root/utils/helper-functions'
// import ContractHtmlPreview from '@root/components/App/Contract/ContractHtmlPreview'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

/**
 * @param {File|{content:string, name:string, size: number, type: string}} file
 * @returns {Promise<{content:string, name:string, size: number, type: string}>}
 */
const loadFromFile = (file) =>
  new Promise((resolve) => {
    if (file.content) {
      resolve(file)
    } else if (typeof file.text == 'function') {
      serializeFile(file).then((serialized) => resolve(serialized))
    }
  })

/**
 * Wrapper ta tag img que recebe um file e se encarrega de fazer o load da imagem, bem util para fazer o preview nos uploads de imagem
 */
const FileUploadPreview = (fullProps) => {
  const {file, loader, ...props} = fullProps
  const [source, setSource] = useState()
  loadFromFile(file).then((evTarget) => {
    setSource(evTarget.content)
  })

  if (source) {
    if (file.type === 'application/pdf') {
      return (
        <div>
          <Document file={source}>
            <Page pageNumber={1} />
          </Document>
        </div>
      )
    } else {
      return <img src={source} {...props} />
    }
  }

  return loader ? loader : <></>
}

FileUploadPreview.propTypes = {
  file: PropTypes.object,
  loader: PropTypes.node,
}

export const PreviewPdf = ({url, height, propertyId}) => {
  /*
    return propertyId ? (
      <ContractHtmlPreview propertyId={propertyId} />
    ) : ()
  */

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        height: `${height ? height : '700px'}`,
      }}>
      {url ? (
        <object
          data={url + '#zoom=100&view=fith&toolbar=0&statusbar=0&navpanes=0'}
          width="100%"
          minHeight="100%"></object>
      ) : (
        <div>
          <h3>Não foi possível carregar a pre-visualização</h3>
        </div>
      )}
    </div>
  )
}

export const PreviewHtml = ({html, height, propertyId}) => {
  /*
    return propertyId ? (
      <ContractHtmlPreview propertyId={propertyId} />
    ) : ()
  */

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        height: `${height ? height : '700px'}`,
      }}>
      {html ? (
        <iframe srcDoc={html} width="100%" />
      ) : (
        <div>
          <h3>Não foi possível carregar a pre-visualização</h3>
        </div>
      )}
    </div>
  )
}

export const PreviewPdfModal = ({pdfLink, isModalOpen, toggleModal}) => {
  return (
    <Dialog fullScreen open={isModalOpen} style={{zIndex: '100'}}>
      <Grid
        container
        style={{
          margin: '120px auto',
          maxWidth: '700px',
          height: '700px',
          border: '1px solid #707070',
          borderRadius: '15px',
        }}>
        <Grid Item xs={12}>
          <div style={{display: 'flex', justifyContent: 'flex-end', padding: '5px'}}>
            <GetAppIcon onClick={() => window.open(pdfLink)} style={{cursor: 'pointer', fontSize: '30px'}} />
            <Close onClick={toggleModal} style={{cursor: 'pointer', fontSize: '30px'}} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <PreviewPdf url={pdfLink} height="600px" />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default FileUploadPreview
