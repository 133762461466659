import {useQuery} from '@root/hooks/urlHook'
import storage from '@root/lib/storage'

export const REWARDS_QUERY_PARAM = 'codigo'
const useRewards = () => {
  const query = useQuery()
  let rewardCode = query.getParam(REWARDS_QUERY_PARAM)
  let hasCode = !!rewardCode

  if (!hasCode) {
    rewardCode = storage.load(REWARDS_QUERY_PARAM, '')
    hasCode = !!rewardCode
  }

  return {
    code: rewardCode,
    hasCode,
    save: (code) => storage.save(REWARDS_QUERY_PARAM, code),
    clear: () => storage.remove(REWARDS_QUERY_PARAM),
  }
}

export default useRewards
