import {Card, Grid} from '@material-ui/core'
import ButtonSecondary from '@root/components/ButtonSecondary'
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import analyticsLogger from '@root/firebase/analytics.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import {convertDateToBr, dateFormatCustom} from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import propertyModel from '@root/model/propertyModel'
import * as PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'

import {filterStatus} from './BoletosStatus'

export const BOLETOS_INFO_OPTIONS = ['pagos', 'futuros', 'abertos']
const COMPONENT_TYPES = ['manual', 'automatic']

const BoletosInfo = ({type, options}) => {
  const property = useSelector((s) => s.property.data)
  const [showPagos, setShowPagos] = useState(false)
  const [showFuturos, setShowFuturos] = useState(false)
  const [showAbertos, setShowAbertos] = useState(false)
  const [payments, setPayments] = useState()
  const paids = filterStatus(payments, 'paid')
  const waitings = filterStatus(payments, 'waiting_payment')
  const schedules = filterStatus(payments, 'waiting_schedule', false)

  const actions = actionDispatcher({
    setCurrentProperty,
    setGlobalLoaderAction,
  })

  useEffect(() => {
    if (property) {
      setPayments(property.contract?.payments)
    }
  }, [property])

  useEffect(() => {
    if (options) {
      if (options.indexOf('pagos') !== -1) {
        setShowPagos(true)
      }
      if (options.indexOf('futuros') !== -1) {
        setShowFuturos(true)
      }
      if (options.indexOf('abertos') !== -1) {
        setShowAbertos(true)
      }
    }
  }, [])

  const changeStatusToPaid = async (paymentId) => {
    analyticsLogger.logEvent('button_click', {
      alternative_event_name: 'click_controle_recebido',
      button_name: 'recebido',
    })
    let updatedPayments = payments.map((p) => {
      if (p.id === paymentId) {
        return {...p, status: 'paid', paid_at: new Date(), gateway_name: 'manual'}
      } else {
        return p
      }
    })

    setPayments(updatedPayments)
    updateContractPayments(updatedPayments)
  }

  const updateContractPayments = async (payments) => {
    try {
      await propertyModel.updateById(property?.uid, 'contract.payments', payments)
      actions.setCurrentProperty({
        ...property,
        contract: {
          ...property.contract,
          payments,
        },
      })
    } catch (err) {
      alert(errorHandler(err))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const getPaidBoletos = () => (
    <div>
      <h4>Pagos</h4>
      <Grid container spacing={2}>
        {paids.map((payment) => (
          <Grid key={payment.id} item xs={12} sm={6}>
            <Card style={{padding: 12, height: 80}}>
              {dateFormatCustom(payment.due_date, 'MMMM yyyy')} -{' '}
              <small>
                <NumberFormatCustom value={payment.amount} displayType="text" />
              </small>
              <br />
              <small>pago em: {convertDateToBr(payment.paid_at, true)}</small>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )

  const getFuturos = () => (
    <div>
      <h4>Boletos Futuros</h4>
      <Grid container spacing={2} wrap="wrap">
        {schedules.map((payment) => (
          <Grid key={payment.id} item xs={12} sm={6}>
            <Card style={{padding: 12}}>
              <p>
                {dateFormatCustom(payment.due_date, 'MMMM yyyy')} -{' '}
                <NumberFormatCustom value={payment.amount} displayType="text" />
              </p>
              <p>Vecimento: {convertDateToBr(payment.due_date)}</p>
              {type === 'manual' && (
                <div>
                  <ButtonSecondary size="small" onClick={() => changeStatusToPaid(payment.id)}>
                    recebido
                  </ButtonSecondary>
                </div>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )

  const getAbertos = () => (
    <div>
      <h4>Aguardando Pagamento</h4>
      <Grid container spacing={2} wrap="wrap">
        {waitings.map((payment) => (
          <Grid key={payment.id} item xs={12} sm={6}>
            <Card style={{padding: 12, minHeight: 90}}>
              {dateFormatCustom(payment.due_date, 'MMMM yyyy')} -{' '}
              <NumberFormatCustom value={payment.amount} displayType="text" />
              <br />
              Vecimento: {convertDateToBr(payment.due_date)}
              <br />
              <small>emitido em: {convertDateToBr(payment.reminder_at, true)}</small>
              <br />
              <small>
                <a href={payment.boleto_url} target="_blank" rel="noreferrer">
                  ver boleto
                </a>
              </small>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )

  return (
    <>
      {showAbertos ? getAbertos() : null}
      {showPagos ? getPaidBoletos() : null}
      {showFuturos ? getFuturos() : null}
    </>
  )
}

BoletosInfo.propTypes = {
  options: PropTypes.oneOf(BOLETOS_INFO_OPTIONS),
  type: PropTypes.oneOf(COMPONENT_TYPES),
}

export default BoletosInfo
