/** @module proposalFinder */

/**
 * @param {object} proposals
 * @param {string} userId
 * @return {{exists: boolean, id: string|undefined, interested:FirebaseRef|undefined}}
 */
export const proposalInterestedFinder = (proposals, userId) => {
  const result = {
    exists: false,
    interested: undefined,
    id: undefined,
  }

  if (!proposals || typeof proposals === 'string') {
    return result
  }

  let interested, id, proposal
  for ([id, proposal] of Object.entries(proposals)) {
    interested = proposal.interesteds.find((interested) => interested.user.id === userId)
    if (interested) {
      result.interested = interested
      result.exists = true
      result.id = id
      break
    }
  }

  return result
}

/**
 * @param {object} proposals
 * @param {string} userId
 * @return {{exists: boolean, id: string|undefined}}
 */
const proposalFinder = (proposals, userId) => {
  const interestedFinder = proposalInterestedFinder(proposals, userId)
  return {
    exists: interestedFinder.exists,
    id: interestedFinder.id,
  }
}

export default proposalFinder
