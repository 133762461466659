import {firestore} from '@root/firebase/firebase.utils'
import propertyModel from '@root/model/propertyModel'
import {ImoveisActionTypes} from '@root/redux/property/property.types'

export const resetPropertyFromSnap = (propertySnap) => ({
  type: ImoveisActionTypes.RESET_PROPERTY_FROM_SNAP,
  payload: {
    snap: propertySnap,
    uid: propertySnap?.ref.id,
    data: propertySnap?.data(),
  },
})

export const updatePropertyAddressAction = (address) => ({
  type: ImoveisActionTypes.UPDATE_PROPERTY_ADDRESS,
  payload: {
    address,
  },
})

export const setCurrentPropertyId = (uid) => ({
  type: ImoveisActionTypes.SET_CURRENT_PROPERTY_ID,
  payload: {uid},
})
export const setCurrentProperty = (property) => ({
  type: ImoveisActionTypes.SET_CURRENT_IMOVEL,
  payload: property,
})
export const setCurrentImovel = setCurrentProperty

export const setInviteProperty = (property) => ({
  type: ImoveisActionTypes.SET_INVITE_IMOVEL,
  payload: property,
})

export const setCurrentPropertySnap = (propertySnap) => ({
  type: ImoveisActionTypes.SET_CURRENT_PROPERTY_REF,
  payload: propertySnap,
})

export const resetPropertyInitialState = () => ({
  type: ImoveisActionTypes.RESET_PROPERTY_INITIAL_STATE,
})

export const cadastrarImovel = (imovel) => ({
  type: ImoveisActionTypes.CADASTRAR_IMOVEL,
  payload: imovel,
})

export const setPropertyOccupationTypeAction = (occupationType) => ({
  type: ImoveisActionTypes.SET_ESTADO_DO_IMOVEL,
  payload: occupationType,
})

export const setEstadoDoImovel = setPropertyOccupationTypeAction

export const setPropertyOccupationEmptyAction = () => setPropertyOccupationTypeAction(propertyModel.OCCUPATION_EMPTY)

export const setPropertyOccupationFullAction = () => setPropertyOccupationTypeAction(propertyModel.OCCUPATION_FULL)

export const setFormaDePagamento = (formaDePagamento) => ({
  type: ImoveisActionTypes.SET_FORMA_DE_PAGAMENTO,
  payload: formaDePagamento,
})

export const setGarantias = (garantias) => ({
  type: ImoveisActionTypes.SET_GARANTIAS,
  payload: garantias,
})

export const addCreditAnalyseRefToPropertyAction = (uid) => ({
  type: ImoveisActionTypes.ADD_CREDIT_ANALYSIS,
  payload: {uid},
})

export const clearPropertyCreditAnalysisAction = () => ({
  type: ImoveisActionTypes.CLEAR_CREDIT_ANALYSIS,
})

export const setPropertyTenantsAction = ({data, index}) => ({
  type: ImoveisActionTypes.SET_TENANT,
  payload: {data, index},
})

export const setPropertyAddressFromViaCepAction = (viaCepResp) => ({
  type: ImoveisActionTypes.SET_PROPERTY_VIACEP_ADDRESS,
  payload: viaCepResp,
})

export const setPropertyAddressNumberAction = (number) => ({
  type: ImoveisActionTypes.SET_PROPERTY_ADDRESS_NUMBER,
  payload: {number},
})

export const setPropertyContractFile = (fileSerialized) => ({
  type: ImoveisActionTypes.SET_PROPERTY_CONTRACT_FILE,
  payload: {file: fileSerialized},
})

export const setPropertyContractFiles = (contractDocument, extraFiles) => ({
  type: ImoveisActionTypes.SET_PROPERTY_CONTRACT_FILES,
  payload: {contractDocument, extraFiles},
})

export const setOrUpdatePropertyAlias = (alias) => ({
  type: ImoveisActionTypes.SET_OR_UPDATE_PROPERTY_ALIAS,
  payload: alias,
})

export const setPropertyProposalManagerAction = (manager) => ({
  type: ImoveisActionTypes.SET_PROPERTY_PROPOSAL_MANAGER_INSTANCE,
  payload: {manager},
})

export const clearPropertyProposalManagerAction = () => ({
  type: ImoveisActionTypes.SET_PROPERTY_PROPOSAL_MANAGER_INSTANCE,
  payload: {manager: undefined},
})
