import {createMuiTheme} from '@material-ui/core/styles'

const BLACK = '#262626'
const WHITE = '#FFFFFF'
const PURPLE = '#831CCB' // '#831CCD' mudanca minima na cor, mas em fundo branco vai garantir score maximo no contraste
const STRIPE = {
  light: '#fdfdfd',
  dark: '#f9f9f9',
}

const GRAY = {
  100: '#F5F4F4',
  200: '#E1E2E3',
  300: '#E6E6E6',
  500: '#C3C3C3',
  900: '#00000040',
}
const YELLOW = {
  100: '#FED667',
  200: '#FFD74F',
  500: '#FFB100',
  900: '#C38F00',
}

const ORANGE = '#FFB100'
const HOME_BLUE = '#2D7FFC'
const BLUE = '#0A00CE'
const BLUE_LAGOON = '#006683'
const GREEN = '#BADD48'
const RED = '#FF3838'
const TURQUOISE = '#D0F4FF'
const CYAN = '#23CCFD'
const VALID = '#36F446'
const INVALID = '#F44336'

const H4_FONT_SIZE = '1.5625rem' // 25px

const H5_FONT_SIZE = '1.25rem' // 20px
const H5_LINE_HEIGHT = '1.6875rem' // 27px
const H5_LETTER_SPACING = '0.1px'

const H6_FONT_SIZE = '0.9375rem' // 15px

const BODY_AND_SUBTITLE_FONT_SIZE = '0.8125rem' // 13px

const BODY_LINE_HEIGHT = '1.125rem' // 18px
const BODY_LETTER_SPACING = '0.07px'

const CAPTION_FONT_SIZE = '0.8125rem' // 13px
const FORM_FONT_SIZE = '0.95rem'

const mobenTheme = createMuiTheme({
  // Override para alguns componentes do Material UI
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#FFFFFF',
        color: '#8E8B8B',
      },
    },
    MuiInputBase: {
      input: {
        'fontSize': FORM_FONT_SIZE,
        'color': BLUE,
        '&:-webkit-autofill': {
          'WebkitBoxShadow': '0 0 0 1000px white inset',
          '-webkit-text-fill-color': BLUE,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        margin: '0 0 12px 0',
        minHeight: '1em',
      },
    },
    MuiInput: {
      root: {},
      underline: {
        '&:before': {
          borderBottomColor: GRAY[200],
        },
        '&:hover:not($disabled):before': {
          borderBottom: `1px solid ${PURPLE}`,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: FORM_FONT_SIZE,
      },
    },
    MuiRadio: {
      root: {
        'color': GRAY[500],
        '&$checked': {
          color: `${YELLOW[500]} !important`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        'color': GRAY[500],
        '&$checked': {
          color: `${YELLOW[500]} !important`,
        },
      },
    },
    MuiList: {
      root: {
        overflow: 'hidden',
      },
    },
    // MuiCheckbox: {
    //   root: {
    //     color: PURPLE,
    //   },
    // },
  },
  breakpoints: {
    keys: ['xs', 'ss', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      ss: 320,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: PURPLE,
    },
    secondary: {
      main: BLUE,
    },
    text: {
      primary: BLACK,
      secondary: BLUE_LAGOON,
    },
    valid: {
      main: VALID,
    },
    invalid: {
      main: INVALID,
    },
  },
  extraColors: {
    GRAY,
    YELLOW,
    GREEN,
    RED,
    WHITE,
    TURQUOISE,
    CYAN,
    HOME_BLUE,
    ORANGE,
    BLACK,
    PURPLE,
    VALID,
    INVALID,
    STRIPE,
  },
  redButton: {
    bg: RED,
    color: BLACK,
    iconColor: BLACK,
  },
  purpleButton: {
    bg: PURPLE,
    color: WHITE,
    iconColor: WHITE,
  },
  orangeButton: {
    bg: ORANGE,
    color: WHITE,
    iconColor: WHITE,
  },
  greenButton: {
    bg: GREEN,
    color: BLACK,
    iconColor: WHITE,
  },
  yellowButton: {
    bg: YELLOW[100],
    color: BLACK,
    iconColor: YELLOW[900],
  },
  typography: {
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
    h4: {
      fontSize: H4_FONT_SIZE,
    },
    h5: {
      fontSize: H5_FONT_SIZE,
      lineHeight: H5_LINE_HEIGHT,
      letterSpacing: H5_LETTER_SPACING,
      fontWeight: 400,
    },
    h6: {
      fontSize: H6_FONT_SIZE,
      lineHeight: H6_FONT_SIZE,
    },
    body1: {
      color: BLACK,
      fontSize: BODY_AND_SUBTITLE_FONT_SIZE,
      lineHeight: BODY_LINE_HEIGHT,
      letterSpacing: BODY_LETTER_SPACING,
    },
    body2: {
      color: BLUE_LAGOON,
      fontSize: BODY_AND_SUBTITLE_FONT_SIZE,
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: BODY_LINE_HEIGHT,
      letterSpacing: BODY_LETTER_SPACING,
    },
    subtitle1: {
      color: BLACK,
      fontSize: BODY_AND_SUBTITLE_FONT_SIZE,
      lineHeight: BODY_AND_SUBTITLE_FONT_SIZE,
      fontStyle: 'italic',
    },
    caption: {
      fontSize: CAPTION_FONT_SIZE,
      lineHeight: CAPTION_FONT_SIZE,
    },
  },
  shape: {
    borderRadius: 6,
  },
  borderRadius: {
    small: 6,
    big: 10,
  },
  margin: {
    1: '5px',
    2: '10px',
    3: '15px',
    4: '20px',
    5: '30px',
  },
  padding: {
    main: '0 10px',
  },
})

export default mobenTheme
