import React from 'react'
import {MOBEN_EMAIL} from '@root/utils/constants'

import useStyles from './styles'

const EmailLink = () => <a href={`mailto:${MOBEN_EMAIL}`}>{MOBEN_EMAIL}</a>

export const termConditionsDropdown = {
  accept: {title: 'Aceitação dos Termos', id: 'accept'},
  initial: {title: 'Considerações iniciais', id: 'initial'},
  rules: {title: 'Regras de Utilização da Plataforma', id: 'rules'},
  property: {title: 'Cadastro de imóveis', id: 'property'},
  interested: {title: 'Cadastro de Interessados', id: 'interested'},
  analysis: {title: 'Análise de Crédito', id: 'analysis'},
  tenant: {title: 'Escolha do Inquilino', id: 'tenant'},
  contract: {title: 'Contrato de Locação', id: 'contract'},
  garantuees: {title: 'Garantias', id: 'garantuees'},
  views: {title: 'Vistorias', id: 'views'},
  monthly: {title: 'Envio de boletos e cobranças amigáveis', id: 'monthly'},
  neg: {title: 'Negativação', id: 'neg'},
  dashboard: {title: 'Painel de ControlePage', id: 'dashboard'},
  chat: {title: 'Chat', id: 'chat'},
  notification: {title: 'Notificações', id: 'notification'},
  cancel: {title: 'Rescisão', id: 'cancel'},
  pay: {title: 'Payments', id: 'pay'},
  law: {title: 'Legislação e Foros aplicáveis', id: 'law'},
}
export const PRIVACY_POLICY_PATH = '/politicasdeprivacidade'
export const ITEM_WITH_SUBMENU = 'Condições de Uso'
export const ITEM_WITH_SUBMENU_ID = 'conditions-intro'

export const AllTerms = () => {
  const classes = useStyles()
  return (
    <section className={classes.allTermsContainer}>
      <IntroTermTxt privacypage />
      <DefinitionsTermTxt />
      <ConditionsTermTxt />
      <PrivacyTermTxt />
      <CollectedData />
      <DataTermTxt />
      <ShareTermTxt />
      <StorageTermTxt />
      <termConditionsDropdown />
    </section>
  )
}

export const IntroTermTxt = ({privacypage}) => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>
        Termos e Condições de Uso e Política de Privacidade Moben
      </h2>
      {!privacypage ? (
        <>
          <p className={classes.text}>
            Trabalhamos para simplificar todos os processos relacionados ao processo de locação de um imóvel e não seria
            diferente dentro dos nossos{' '}
            <a className={classes.link} href={PRIVACY_POLICY_PATH} target="_blank" rel="noreferrer">
              <u>Termos e Condições</u>
            </a>{' '}
            e{' '}
            <a className={classes.link} href={PRIVACY_POLICY_PATH} target="_blank" rel="noreferrer">
              <u>Política de Privacidade</u>
            </a>
            . Tudo para garantir sua tranquilidade pois sabemo s a importância da segurança das suas informações.
          </p>
          <p className={classes.text}>
            Quando você contrata os serviços da Moben, você nos fornece os seus dados pessoais para que possamos
            viabilizar o bom funcionamento de nossos produtos e serviços e também para te entregar a melhor experiência
            possível. Na Moben, privacidade, segurança e transparência são valores fundamentais e sempre adotaremos as
            melhores práticas para garantir a confidencialidade e integridade dos seus dados pessoais.
          </p>
          <p className={classes.text}>
            Além disso, burocracia, letras miúdas e asteriscos não fazem parte da nossa missão por isso, desenvolvemos
            nossa{' '}
            <a className={classes.link} href={PRIVACY_POLICY_PATH} target="_blank" rel="noreferrer">
              <u>Política de Privacidade</u>
            </a>{' '}
            para explicar, de uma forma clara, simples e objetiva, as nossas práticas, como os seus dados pessoais são
            tratados e por que pedimos eles.
          </p>
        </>
      ) : null}

      <p className={classes.text}>
        Os presentes Termos e Condições de Uso e Política de Privacidade (“Termos”) têm como objetivo regular o acesso
        aos Serviços disponibilizados por meio da Plataforma Moben (doravante denominada “Plataforma”), pela MOBEN
        Softwares Serviços LTDA, inscrita no Cadastro Nacional de Pessoas Jurídicas (“CNPJ/ME”) sob nº
        35.269.925/0001-17 e sediada na Rua Cônego Eugênio Leite, 623 sala 5, São Paulo/SP, CEP 05414-011.
      </p>
    </section>
  )
}

export const DefinitionsTermTxt = () => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>DEFINIÇÕES INICIAIS</h2>
      <p className={classes.text}>Para fins destes Termos, considera-se:</p>
      <ul>
        <li className={classes.liSpace}>
          <strong>Proprietário</strong>: Usuário, detentor da posse legítima de imóvel residencial, com poderes para
          proceder à sua locação a terceiros, ou terceiros, com poderes legais para representá-lo;
        </li>
        <li className={classes.liSpace}>
          <strong>Interessado</strong>: Usuário que tem interesse em algum imóvel disponibilizado na Plataforma{' '}
          <strong>Moben</strong> e cadastra seus dados e informações, para avaliação do Proprietário.
        </li>
        <li className={classes.liSpace}>
          <strong>Inquilino</strong>: Usuário selecionado por um Proprietário, que com ele celebra Contrato de Locação
          disponibilizado na Plataforma <strong>Moben</strong>, na condição de locatário;
        </li>
        <li className={classes.liSpace}>
          <strong>Usuário</strong>: toda pessoa que acessa ou utiliza a Plataforma <strong>Moben</strong>.
        </li>
        <li className={classes.liSpace}>
          <strong>Partes</strong>: <strong>Proprietário</strong> e <strong>Inquilino</strong>, quando mencionados
          conjuntamente.
        </li>
        <li className={classes.liSpace}>
          <strong>Contrato de Locação</strong>: instrumento jurídico padrão, elaborado pela <strong>Moben</strong>, com
          base em premissas definidas pelo Proprietário, passível de alteração pelas Partes, de acordo com seus
          interesses específicos.
        </li>
        <li className={classes.liSpace}>
          <strong>Garantia</strong>: ato que assegura o cumprimento de obrigação assumida pelo Inquilino no Contrato de
          Locação.
        </li>
        <li className={classes.liSpace}>
          <strong>Garantidor</strong>: pessoa física ou jurídica que dá determinado bem ou direito em garantia do
          Contrato de Locação.
        </li>
      </ul>
    </section>
  )
}

export const ConditionsTermTxt = () => {
  const classes = useStyles()

  return (
    <section className={classes.termsTextSection} id={ITEM_WITH_SUBMENU_ID}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>CONDIÇÕES DE USO</h2>
      <p className={classes.text} id={termConditionsDropdown.accept.id}>
        <strong>1. Aceitação dos Termos</strong>
        <br />
        <br />
        1.1. Estes <u>Termos e Condições de Uso</u> e <u>Política de Privacidade</u> descrevem a forma como deve se dar
        a coleta, a utilização e o compartilhamento de dados e informações do Usuário, através da Plataforma e de outros
        serviços ali disponibilizados.
        <br />
        <br />
        1.2.{' '}
        <strong>
          Ao utilizar a Plataforma e os serviços nela disponíveis, o Usuário concorda com a realização de coleta,
          transferência, armazenamento e utilização de seus dados e informações
        </strong>
        , tal como descrito nestes Termos e Condições de Uso e Política de Privacidade.
        <br />
        <br />
        1.3. Ao aceitar estes Termos e Condições de Uso e Política de Privacidade, o Usuário declara que é maior de 18
        (dezoito) anos e é responsável pelos atos por ele praticados por meio da Plataforma.
        <br />
        <br />
        1.4. O acesso à Plataforma, bem como sua efetiva utilização, implica no aceite, pelo Usuário, desses Termos de
        Uso e da Política de Privacidade, independentemente da realização de seu cadastro na Plataforma.
        <br />
        <br />
        1.5. Por meio do aceite aos presentes Termos, o Usuário se declara ciente de que é única e exclusivamente
        responsável por todas as informações fornecidas quando de seu acesso à Plataforma, responsabilizando-se,
        inclusive perante terceiros, por quaisquer danos ou prejuízos decorrentes de informações incorretas, incompletas
        ou inverídicas.
        <br />
        <br />
        1.6. Estes Termos e Condições de Uso estão sujeitos a contínuos aprimoramentos, razão pela qual, recomenda-se ao
        Usuário que os acesse periodicamente.
        <br />
        <br />
        1.7. Todos os elementos, informações, ferramentas e questões encontradas na Plataforma estão sujeitos aos
        direitos de propriedade intelectual, a todas as leis vigentes no Brasil, bem como a tratados e convenções
        internacionais dos quais o Brasil seja signatário..
        <br />
        <br />
        1.8. Quaisquer dúvidas, reclamações, sugestões, ou comentários que o Usuário possua com relação a estes Termos
        poderão ser enviados para o e-mail <EmailLink />.
        <br />
        <br />
        1.9. Caso o Usuário discorde de alguma das previsões destes Termos, não deverá utilizar a Plataforma.
        <br />
        <br />
        1.10. O Usuário está ciente de que existem outros documentos que complementam estes Termos de Uso, e que serão
        disponibilizados/enviados para ele conforme avancem as fases da locação (ex.: Contrato de Locação).
      </p>
      <p className={classes.text} id={termConditionsDropdown.initial.id}>
        <strong>2. Considerações iniciais</strong>
        <br />
        <br />
        2.1. A <strong>Moben</strong> é uma plataforma que oferece ferramentas para o Proprietário administrar o próprio
        aluguel.
        <br />
        <br />
        2.2. A <strong>Moben</strong> não exerce papel de intermediação imobiliária ou administração de contratos de
        aluguel, mas de mero apoio técnico com a disponibilização de ferramentas por ela desenvolvidas, voltadas ao
        controle de prazos, elaboração e gestão de documentos, encaminhamento de avisos e notificações a ambas as
        Partes.
        <br />
        <br />
        2.3. O Usuário compreende que a utilização das ferramentas ocorrerá mediante o pagamento de remuneração.
        <br />
        <br />
        2.4. Os direitos autorais e de propriedade da Plataforma, desenhos gráficos e códigos fonte, marcas, nomes
        comerciais ou logotipos são de propriedade exclusiva da <strong>Moben</strong> ou de terceiros que tenham
        autorizado sua utilização na Plataforma, estando protegidos pelas leis e tratados internacionais, vedada sua
        cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e
        criminais correspondentes, nos termos das Leis 9.279/96, 9.609/98, 9.610/98.
        <br />
        <br />
        2.5. Para além das demais isenções de responsabilidade indicadas nos presentes Termos, o Usuário se declara
        ciente de que a <strong>Moben</strong> não será responsável
        <br />
        <ol type="a">
          <li>por garantir a eficácia de qualquer contratação, ou a qualidade de qualquer serviço ou imóvel;</li>
          <li>
            por quaisquer danos ou indenizações decorrentes de falha no cumprimento de obrigação por qualquer Usuário;
          </li>
          <li>
            pelas obrigações tributárias decorrentes das atividades dos Usuários relacionadas à utilização da Plataforma
            e ao Contrato de Locação.
          </li>
          <li>por qualquer ato ou omissão de qualquer Usuário em relação a outros Usuários ou terceiros;</li>
          <li>por quaisquer indisponibilidades, erros ou falhas apresentados pela Plataforma;</li>
          <li>
            por erros ou eventuais inconsistências na transmissão de dados, pela qualidade ou disponibilidade da conexão
            de Internet, que impeçam o adequado recebimento de informações pela <strong>Moben</strong> ou pelo Usuário;
          </li>
          <li>
            por dados desatualizados, incompletos ou inverídicos eventualmente disponibilizados e atualizados por
            terceiros, como de instituições financeiras, sites utilizados para anúncios e demais parceiros comerciais;
          </li>
          <li>
            pela presença de vírus ou demais elementos nocivos na Plataforma, capazes de causar alterações em seus
            sistemas informáticos (software e hardware), documentos eletrônicos e nos boletos gerados para pagamento por
            parte do Usuário, eximindo-se a <strong>Moben</strong> de qualquer responsabilidade por eventuais danos e
            prejuízos decorrentes de quaisquer elementos nocivos inseridos por terceiros;
          </li>
          <li>
            pelos danos e prejuízos de toda natureza decorrentes do conhecimento que terceiros não autorizados possam
            ter de quaisquer dados fornecidas pela Plataforma, em decorrência de falha exclusivamente relacionada ao
            Usuário ou a terceiros que fujam a qualquer controle razoável da <strong>Moben</strong>.
          </li>
        </ol>
        <br />
        2.6. A <strong>Moben</strong> se exime da responsabilidade por quaisquer informações imprecisas ou
        inconsistentes inseridas na Plataforma, que são de responsabilidade do Usuário que a fornece, sendo certo que,
        em caso de eventual divergência entre informações indicadas na Plataforma e quaisquer documentos e/ou
        informações fornecidas diretamente por representante da <strong>Moben</strong>, deverão prevalecer os segundos.
        <br />
        <br />
        2.7. A Plataforma poderá conter links para outros websites, o que não implica em nenhuma relação de sociedade,
        supervisão, cumplicidade, endosso ou solidariedade da <strong>Moben</strong> para com esses sites, seus
        conteúdos, produtos ou serviços, razão pela qual, o Usuário assume exclusiva e total responsabilidade por todos
        os riscos e eventuais danos e/ou prejuízos decorrentes do uso de quaisquer desses sites ou de seus conteúdos,
        produtos ou serviços.
        <br />
        <br />
        2.8. Nenhum conselho, orientação ou informação, verbal ou por escrito, obtidos da <strong>Moben</strong> ou
        através do Site, Serviços ou Conteúdos, cria qualquer garantia ou responsabilidade que não tenha sido
        expressamente assumida nestes Termos.
        <br />
        <br />
        2.9. A princípio, a Plataforma é disponibilizada ao Usuário por prazo indeterminado, mas poderá, a qualquer
        tempo, descontinuar a Plataforma, sem necessidade de prévio aviso ao Usuário e sem que lhe seja devida qualquer
        indenização em razão disso.
        <br />
        <br />
        2.10. A <strong>Moben</strong> pode, a qualquer momento, limitar o acesso do Usuário a Plataforma, sendo-lhe
        possível negá-lo ou suspendê-lo, em caso de suspeita de uso indevido ou ilícito da Plataforma, o que pode
        motivar a exclusão de todas as informações fornecidas por este, sem qualquer indenização ou compensação por
        conta disso.
        <br />
        <br />
        2.11. Todas as comunicações encaminhadas pelo Usuário para a <strong>Moben</strong> serão consideradas
        plenamente válidas quando realizadas por escrito e entregues via e-mail para o endereço <EmailLink />. Ainda, as
        comunicações realizadas por parte da <strong>Moben</strong> ao Usuário serão consideradas eficazes, para todos
        os efeitos, quando realizadas por quaisquer meios de contato indicados no cadastro do usuário, escritos ou não.
      </p>
      <p className={classes.text} id={termConditionsDropdown.rules.id}>
        <strong>3. Regras de Utilização da Plataforma</strong>
        <br />
        <br />
        3.1. Por meio do aceite aos presentes Termos, o Usuário se declara ciente que:
        <ol type="a">
          <li>Apenas pessoas civilmente capazes, à luz da legislação brasileira, poderão utilizar o aplicativo;</li>
          <li>
            Ele, Usuário, é o único responsável pela utilização da Plataforma, isentando o <strong>Moben</strong> de
            qualquer responsabilidade por danos e prejuízos decorrentes do uso indevido da Plataforma.
          </li>
          <li>Ele, Usuário, poderá ter diferentes papeis na plataforma como Proprietário, Interessado e Inquilino.</li>
          <li>Cada Conta de Usuário fica associada a um único CPF</li>
          <li>
            Não é permitida a criação de nova Conta de Usuário por pessoas que tenham sido inabilitadas pela{' '}
            <strong>Moben</strong>, temporária ou definitivamente, independente do motivo.
          </li>
          <li>
            Não poderá inserir na Plataforma qualquer conteúdo considerado ilícito ou capaz de violar direitos de
            terceiros.
          </li>
          <li>
            Não deverá inserir na Plataforma, por quaisquer meios, conteúdos ou materiais capazes de incorporar vírus ou
            outros elementos que possam impedir o normal funcionamento da Plataforma, de equipamentos informáticos da
            <strong>Moben</strong> ou de terceiros, bem como capazes de danificar documentos eletrônicos e arquivos
            armazenados em tais equipamentos.
          </li>
          <li>
            Não deverá compartilhar suas credenciais de acesso com terceiros, sendo o único responsável por quaisquer
            operações, inclusive financeiras, realizadas por meio de sua conta;
          </li>
          <li>
            Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência de Contas de
            Usuário da <strong>Moben</strong>, incluindo, mas não se limitando a, qualificações, histórico e reputação
            do Usuário.
          </li>
          <li>
            Não poderá republicar imóvel descadastrado da Plataforma pela <strong>Moben</strong>, salvo autorização
            específica.
          </li>
        </ol>
        <br />
        3.2. Ao efetuar seu cadastro, o Usuário deverá fornecer, entre outras informações, seu número de telefone ou
        e-mail, ao qual o seu cadastro ficará vinculado, sendo facultado à <strong>Moben</strong> o envio de mensagens a
        este número ou a este endereço para validar tal cadastro.
        <br />
        <br />
        3.3. Desde já, o Usuário se declara ciente de que a <strong>Moben</strong> utilizará meios de comunicação, como,
        por exemplo, mensagens de texto, e-mails e mensagens por WhatsApp, visando ao envio de informações diretamente
        relacionadas aos interesses dos Usuários, a prestação e suporte aos serviços desta Plataforma.
        <br />
        <br />
        3.4. A <strong>Moben</strong> se reserva o direito de recusar qualquer solicitação de cadastro e de cancelar um
        cadastro previamente aceito, a seu exclusivo critério e sem aviso prévio, não cabendo nenhuma indenização ou
        reparação pelo cancelamento de quaisquer cadastros ou de impossibilidade de cadastro.
      </p>
      <p className={classes.text} id={termConditionsDropdown.property.id}>
        <strong>4. Cadastro de imóveis</strong>
        <br />
        <br />
        4.1. Os imóveis podem ser cadastrados na plataforma pelos Proprietários ou seus representantes, devidamente
        autorizados por meio de instrumento válido de procuração.
        <br />
        <br />
        4.2. Ao cadastrar um imóvel na Plataforma, o Usuário declara possuir o direito de alugar, em nome próprio ou de
        terceiros, o imóvel cadastrado.
        <br />
        <br />
        4.3. O cadastro de imóveis deverá conter as seguintes informações, se houver:
        <ol type="a">
          <li>Matrícula do Imóvel no Oficial de Registro de Imóveis competente;</li>
          <li>
            f nº do contribuinte do imóvel no cadastro de Registros imobiliários do município em que o imóvel estiver
            localizado;
          </li>
          <li>modalidades de garantia aceitas, a princípio.</li>
        </ol>
        <br />
        4.3.1. A escolha das modalidades de garantias aceitas, a princípio, feita pelo Proprietário na forma da alínea
        c, do item 4.3, não o vincula ou o obriga a aceitar garantia daquela natureza, ofertada por eventuais
        Interessados.
        <br />
        <br />
        4.4. Os Proprietários são inteiramente responsáveis pelas informações referentes aos imóveis cadastrados,
        incluindo, mas não se limitando, conforme o caso, a definição do preço do aluguel, encargos locatícios,
        condições da locação, metragem, entre outros, podendo, inclusive, responder legalmente por seus atos ou
        omissões.
        <br />
        <br />
        4.5. A <strong>Moben</strong> não tem qualquer responsabilidade sobre as informações prestadas pelo
        Proprietário, nos termos da cláusula anterior.
        <br />
        <br />
        4.6. Para cada imóvel cadastrado na Moben, será gerado um link, com explicações sobre o funcionamento da
        Plataforma e um convite, com orientações para acesso e cadastro de Interessados e suas informações pessoais.
        <br />
        <br />
        <span>
          4.6.1. Após cadastrar o imóvel, o Proprietário deverá informar os e-mails dos Interessados, para que o link
          acima referido possa ser a eles enviado por meio da Plataforma.
        </span>
        <br />
        <br />
        <span>
          4.6.2. O endereço de e-mail informado é de inteira responsabilidade do Proprietário, não cabendo à{' '}
          <strong>Moben</strong> qualquer verificação de veracidade ou existência do e-mail inserido na plataforma.
        </span>
        4.7. O Proprietário declara estar ciente de que, apesar de a <strong>Moben</strong> enviar o link juntamente com
        um e-mail explicativo sobre a plataforma, ele deverá cientificar os destinatários sobre a função da Plataforma e
        a finalidade da contratação da <strong>Moben</strong>.
        <br />
        <br />
      </p>
      <p className={classes.text} id={termConditionsDropdown.interested.id}>
        <strong>5. Cadastro de Interessados</strong>
        <br />
        <br />
        5.1. Ao efetuar o cadastro na Plataforma, os Interessados deverão apresentar as informações pessoais e
        financeiras solicitadas, bem como apontar, dentre as modalidades de garantias aceitas pelo Proprietário, aquelas
        que teriam condições de oferecer.
        <br />
        <br />
        5.2. Os Interessados declaram estar cientes de que o mero cadastro não implica no aceite de sua proposta pelo
        Proprietário e que a confirmação da locação só se verifica com a assinatura do Contrato de Locação.
        <br />
        <br />
        5.3. Ao efetuar o cadastro, o Interessado autoriza a divulgação, o fornecimento e a utilização de seus dados
        cadastrais, pessoais, de crédito e de comunicação para o Proprietário, para qualquer finalidade de natureza
        financeira, de análise de crédito e idoneidade jurídica, podendo ser repassados a empresas parceiras da{' '}
        <strong>Moben</strong>, instituições financeiras, de crédito ou de consulta, com a mesma finalidade.
      </p>
      <p className={classes.text} id={termConditionsDropdown.analysis.id}>
        <strong>6. Análise de Crédito</strong>
        <br />
        <br />
        6.1. Os Interessados e que cadastrarem suas informações na plataforma autorizam que sejam feitas consultas
        adicionais relativas aos seus CPFs junto a entidades do sistema financeiro e bureaus de crédito, com vistas à
        análise de pontuação de crédito elaborada com base nas informações de adimplemento ali armazenadas, bem como o
        repasse das informações obtidas ao Proprietário.
        <br />
        <br />
        6.2. Todas as informações cadastradas pelo Interessado são de sua inteira responsabilidade, não cabendo à{' '}
        <strong>Moben</strong> qualquer requisição de anuência relativa as consultas realizadas.
        <br />
        <br />
        6.3. O Interessado que receber o link poderá compor a sua proposta com a indicação de CPFs solidários, a fim de
        deixar a análise de cadastro mais atrativa para o Proprietário.
        <br />
        <span>
          6.3.1. Todas as pessoas cujos CPFs sejam indicados pelo Inquilino, deverão efetuar cadastro próprio na
          Plataforma, na forma e nas condições previstas nos itens 5 e 6 destes Termos.
        </span>
        <br />
        <br />
        <span>
          6.3.2. Caso o Interessado seja selecionado pelo Proprietário, as pessoas acima mencionadas deverão assinar o
          Contrato de Locação, seja na condição de Coinquilinos, caso venham a residir no imóvel, ou na condição de
          Garantidores, nas modalidades previstas nos itens 9.1, b, iii, e c, ii (fiança e caução em imóveis).
        </span>
        <br />
        <br />
        6.4. As análises de cadastro são feitas segundo critérios exclusivos e subjetivos da <strong>Moben</strong>, em
        estrita obediência aos preceitos de confidencialidade e preservação de dados estipulados na legislação
        brasileira que rege a matéria.
        <br />
        <br />
        6.5. O Proprietário poderá solicitar documentos adicionais e comprobatórios através da plataforma, caso deseje
        mais informações antes de escolher seu Inquilino.
        <br />
        <br />
        6.6. A <strong>Moben</strong> não poderá ser responsabilizada por qualquer dano ou prejuízo decorrente de
        informações incorretas inseridas pelo Interessado.
      </p>
      <p className={classes.text} id={termConditionsDropdown.tenant.id}>
        <strong>7. Escolha do Inquilino</strong>
        <br />
        <br />
        7.1. Concluída a análise de crédito acima mencionada, o Proprietário é informado acerca dos resultados obtidos,
        para que possa autorizar a celebração do <strong>Contrato de Locação</strong> com o Interessado escolhido.
        <br />
        <br />
        7.2. Todas as informações disponibilizadas ao Proprietário são oriundas do mercado, do sistema financeiro e dos
        bureaus de crédito, e representam uma análise atual, estatística e histórica do comportamento do Interessado.
        <br />
        <br />
        7.3. Caso haja mais de um Interessado no imóvel com informação cadastrada, os Interessados preteridos somente
        serão notificados pela plataforma sobre o declínio da proposta, assim que houver a assinatura do{' '}
        <strong>Contrato de Locação</strong> com o Interessado escolhido.
        <br />
        <br />
        7.4. O Proprietário compreende que a escolha do Inquilino é de sua inteira responsabilidade, cabendo a ele,
        exclusivamente, a solicitação e análise de documentos que atestem a veracidade dos dados informados,
        especialmente aqueles relacionados à situação jurídica e condição financeira do Inquilino, dos Coinquilinos e
        Garantidores.
      </p>
      <p className={classes.text} id={termConditionsDropdown.contract.id}>
        <strong>8. Contrato de Locação</strong>
        <br />
        <br />
        8.1. A <strong>Moben</strong> fornecerá ao Proprietário um <strong>Contrato de Locação</strong> padrão,
        elaborado a partir de opções feitas por este no ambiente da Plataforma, mas que poderá ter parte de seu conteúdo
        adaptado ao interesse das Partes.
        <br />
        <br />
        8.2. A assinatura do contrato será realizada de forma digital, dispensado reconhecimento de firma em Cartório.
        <span>
          8.2.1. O contrato deve ser assinado digitalmente pelo Proprietário, ou seu procurador, pelo(s) Inquilino(s) e
          pelo Garantidor, quando ofertada uma das garantias previstas nos itens 9.1 , b, iii, e c, ii (fiança ou caução
          em imóveis).
        </span>
        <br />
        <br />
        8.3. Tão logo assinado o Contrato de Locação, recomenda-se às Partes que o imprimam e zelem por sua guarda, bem
        como pelos demais documentos trocados entre elas no curso da Locação, para eventuais fins de registro e execução
        contratual, pelas vias judiciais e extrajudiciais.
      </p>
      <p className={classes.text} id={termConditionsDropdown.garantuees.id}>
        <strong>9. Garantias</strong>
        <br />
        <br />
        9.1. Nos Contratos de Locação disponibilizados pela <strong>Moben</strong>, serão admitidas as seguintes
        modalidades de garantia:
        <ol type="a">
          <li>Contrato sem garantia;</li>
          <li>
            <div>
              <span>Caução</span>
              <ul className={classes.termsSublist}>
                <li>em dinheiro</li>
                <li>em título de capitalização</li>
                <li>em bens imóveis</li>
              </ul>
            </div>
          </li>
          <li>
            <span>Fiança</span>
            <ul className={classes.termsSublist}>
              <li>fiança bancária (carta fiança)</li>
              <li>fiador</li>
            </ul>
          </li>
          <li>Seguro-fiança</li>
        </ol>
        <br />
        9.2. Todas as modalidades de garantia acima mencionadas seguirão os padrões definidos em legislação própria, que
        rege as respectivas matérias, e aqueles definidos no Contrato de Locação, declarando as Partes, neste ato, serem
        conhecedoras de seus termos e das responsabilidades de caráter solidário decorrentes da assunção dessa
        obrigação.
      </p>
      <p className={classes.text} id={termConditionsDropdown.views.id}>
        <strong>10. Vistorias</strong>
        <br />
        <br />
        10.1. A <strong>Moben</strong> disponibiliza em sua plataforma um guia para realização de vistorias e opção para
        o Proprietário anexar a vistoria inicial.
        <br />
        <br />
        10.2. O Proprietário compreende que o guia para vistoria é um material informativo e que a realização de uma
        vistoria minuciosa é de sua responsabilidade.
      </p>
      <p className={classes.text} id={termConditionsDropdown.monthly.id}>
        <strong>11. Envio de boletos e cobranças amigáveis</strong>
        <br />
        <br />
        11.1. A <strong>Moben</strong> providenciará o envio mensal aos Inquilinos, dos Boletos de Locação, contendo os
        valores expressos no Contrato de Locação, devidamente atualizados.
        <br />
        <br />
        11.2. Por acordo entre as Partes, o valor expresso no boleto poderá ser alterado por um ou mais meses, seja para
        indenizar o Inquilino por alguma benfeitoria feita no imóvel, nos termos previstos no Contrato de Locação, seja
        por mera liberalidade do Proprietário.
        <span>
          11.2.1. A alteração dos valores mencionada no item anterior deverá ser solicitada conjuntamente, por ambas as
          Partes, com o mínimo de 30 dias de antecedência da data de vencimento do boleto.
        </span>
        <br />
        <br />
        11.3. Os Boletos de Locação serão emitidos por uma empresa parceira devidamente autorizada para esta atividade.
        <span>
          11.3.1. Os pagamentos efetuados via boleto bancário, contarão com o acréscimo de taxa de emissão, no valor de
          R$ 2,00 (dois reais), que serão destinados à empresa responsável pela emissão de referidos boletos.
        </span>
        <br />
        <br />
        11.4. Em caso de atraso no pagamento do aluguel, o Proprietário autoriza a <strong>Moben</strong>, desde já, a
        enviar uma série de mensagens de cobrança ao Inquilino, que serão disparadas automaticamente, para estimular o
        pagamento do boleto.
        <br />
        <br />
        11.5. A periodicidade e os meios pelos quais serão disparadas as mensagens referidas no item anterior, serão
        estabelecidos de acordo com as políticas da <strong>Moben</strong> e poderá ser por ela alterada a fim de
        melhorar a efetividade das cobranças.
        <br />
        <br />
        11.6. O Proprietário compreende que o envio de cobranças amigáveis ao Inquilino não implica na transferência à{' '}
        <strong>Moben</strong> da responsabilidade pelos pagamentos eventualmente em atraso.
      </p>
      <p className={classes.text} id={termConditionsDropdown.neg.id}>
        <strong>12. Negativação</strong>
        <br />
        <br />
        12.1. Após 5 (cinco) dias de atraso no pagamento do aluguel e/ou seus encargos, a <strong>Moben</strong> poderá
        encaminhar, em nome do Proprietário e sob seu comando específico, notificação de cobrança ao Inquilino e ao
        fiador, ou à Instituição que houver ofertado garantia à locação.
        <br />
        <br />
        12.2. Em caso de não purgação da mora em 15 (quinze) dias, a <strong>Moben</strong> poderá, sempre em nome do
        Proprietário e sob seu comando específico, encaminhar nova notificação ao Proprietário e ao fiador, se houver,
        para que o façam, sob pena de comunicação da inadimplência aos Serviços de Proteção ao Crédito.
        <br />
        <br />
        12.3. Escoado o prazo mencionado no item anterior, a <strong>Moben</strong> poderá, com anuência do Proprietário
        e sob seu comando, providenciar a inclusão do nome do Inquilino e do fiador, se houver, no cadastro de
        inadimplentes, que integram o Sistema de Proteção ao Crédito, comunicando o Inquilino acerca da adoção dessa
        providência.
        <br />
        <br />
        12.4. O Proprietário deverá outorgar procuração específica constante da Plataforma, para que a{' '}
        <strong>Moben</strong> adote, em seu nome, as medidas especificadas nos itens acima.
      </p>
      <p className={classes.text} id={termConditionsDropdown.dashboard.id}>
        <strong>13. Painel de Controle</strong>
        <br />
        <br />
        13.1. - Todas as informações relativas às ferramentas disponibilizadas pela <strong>Moben</strong> poderão ser
        administradas pelo Painel de Controle.
        <br />
        <br />
        13.2. Qualquer alteração realizada no painel de controle referente a valores cobrados no Boleto de Aluguel será
        de responsabilidade do Proprietário.
        <br />
        <br />
        13.3. Através do Painel de Controle poderão ser inseridos documentos, fotos, vídeos e outras informações
        referentes ao Imóvel, às vistorias, às Partes, aos Interessados, aos Garantidores e às Garantias, entre outras
        que se façam necessárias ao bom e regular exercício da relação locatícia, gerida por meio da Plataforma{' '}
        <strong>Moben</strong>.
        <br />
        <br />
        13.4. Os documentos submetidos não serão avaliados pela Plataforma, sendo certo que sua legitimidade e seu
        conteúdo são de exclusiva reponsabilidade dos Usuários responsáveis por sua inserção.
      </p>
      <p className={classes.text} id={termConditionsDropdown.chat.id}>
        <strong>14. Chat</strong>
        <br />
        <br />
        14.1. – A <strong>Moben</strong> disponibiliza um canal de comunicação direta entre Proprietário e Inquilino,
        por meio de um “chat”, para que estas possam estabelecer um diálogo direto, sempre que o Contrato de Locação não
        exigir o envio formal de notificações.
        <br />
        <br />
        14.2. A <strong>Moben</strong> deverá manter todo o conteúdo do “chat” preservado e disponível para utilização
        pelas Partes, enquanto o Proprietário mantiver seu cadastro ativo na <strong>Moben</strong>.
        <br />
        <br />
        14.3. As conversas são livres entre Proprietário e Inquilino, sem qualquer mediação da <strong>Moben</strong>,
        razão pela qual, a Plataforma fica isenta de qualquer reponsabilidade sobre o conteúdo inserido pelas Partes no
        “chat”.
      </p>
      <p className={classes.text} id={termConditionsDropdown.notification.id}>
        <strong>15. Notificações</strong>
        <br />
        <br />
        15.1. As Partes reconhecem, conforme previsto no Contrato de Locação, que, para que as comunicações entre elas
        deverão tenham validade jurídica, devem ser efetuadas por meio de <strong>notificações</strong> encaminhadas por
        intermédio da Plataforma <strong>Moben</strong>, reconhecendo, desde já, a plena validade jurídica dessas
        comunicações e renunciando, cada uma delas, desde já, ao direito de exigir que a outra parte se valha de outras
        formas de comunicação, ainda que expressamente previstas em lei.
        <br />
        <br />
        15.2. As Partes reconhecem que a manutenção do endereço de e-mail atualizado, no cadastro da Plataforma{' '}
        <strong>Moben</strong> é de suma importância e de sua exclusiva responsabilidade, não se podendo invocar o não
        recebimento de determinada mensagem, por qualquer razão, como causa do inadimplemento de qualquer obrigação
        assumida neste Contrato, no prazo ali assinalado.
        <br />
        <br />
        15.3. As Partes têm ciência de que o fato de a Plataforma disponibilizar canal para envio de notificações entre
        elas, não implica no exercício de atividade de intermediação da relação locatícia, nem torna a{' '}
        <strong>Moben</strong> responsável por qualquer obrigação relativa à administração do imóvel e da locação.
      </p>
      <p className={classes.text} id={termConditionsDropdown.cancel.id}>
        <strong>16. Rescisão</strong>
        <br />
        <br />
        16.1. A <strong>Moben</strong> pode cancelar, ou suspender a prestação dos serviços a qualquer momento, sem
        aviso ou consentimento prévios, a seu exclusivo critério, suspendendo-se, no mesmo ato, as cobranças pelos
        serviços prestados.
        <br />
        <br />
        16.2. Os Usuários podem cancelar a conta na plataforma a qualquer momento, mediante solicitação feita nos meios
        de comunicação disponíveis na Plataforma.
        <br />
        <br />
        16.3. Independentemente de quem lhe der causa, o cancelamento dos serviços prestados pela <strong>
          Moben
        </strong>{' '}
        encerram sua responsabilidade pela manutenção em arquivo de todos os documentos gerados pela Plataforma, ou nela
        inseridos, desde o cadastro do Usuário, até o efetivo cancelamento.
        <br />
        <br />
        16.4. O cancelamento dos serviços prestados pela <strong>Moben</strong> não implica na rescisão ou qualquer
        alteração nos Contratos de Locação firmados entre as Partes, que devem seguir as disposições próprias previstas
        nesses instrumentos.
        <br />
        <br />
        16.5. As Partes reiteram ter ciência de que a elas compete a exclusiva responsabilidade pela guarda e
        arquivamento de todos os documentos gerados ou inseridos na Plataforma, no curso da relação locatícia.
      </p>
      <p className={classes.text} id={termConditionsDropdown.pay.id}>
        <strong>17. Pagamentos</strong>
        <br />
        <br />
        17.1. O serviço prestado pela <strong>Moben</strong> será remunerado com pagamento, pelo Proprietário, das
        seguintes taxas:
        <ol type="a">
          <li>taxa de adesão, no valor de R$ (reais), cobrada no momento do cadastro do imóvel;</li>
          <li>taxa mensal de manutenção, no valor de R$ (reais), a ser paga desde a emissão do Contrato de Locação.</li>
        </ol>
        17.2. Os valores acima mencionados constam da tabela publicada no site, e sofrerão, anualmente, reajuste com
        base no IGP-M/FGV (Índice Geral de Preços ao Mercado) ou outro índice que vier a substituí-lo.
        <br />
        <br />
        17.3. Os pagamentos a que se referem o item anterior poderão ser efetuados via cartão de crédito, boleto
        bancário e outras formas que venham a ser disponibilizadas na Plataforma.
        <br />
        <br />
        17.4. Os boletos serão emitidos por uma empresa parceira devidamente autorizada para esta atividade.
        <br />
        <br />
        <span>
          17.4.1 Os pagamentos efetuados via boleto bancário, contarão com o acréscimo de taxa de emissão, no valor de
          R$ 2,00 (dois reais), que serão destinados à empresa responsável pela emissão de referidos boletos.
        </span>
      </p>
      <p className={classes.text} id={termConditionsDropdown.law.id}>
        <strong>18. Legislação e Foros aplicáveis</strong>
        <br />
        <br />
        18.1. A Plataforma <strong>Moben</strong> está, em qualquer hipótese, sujeita à legislação brasileira,
        respeitando todos os limites legais possíveis de aplicação a esta ferramenta.
        <br />
        <br />
        18.2. É dever do usuário informar-se a respeito das leis vigentes no Brasil.
        <br />
        <br />
        18.3. Fica eleito, desde já, o Foro Central da Comarca de São Paulo para dirimir quaisquer conflitos existentes
        entre usuários e a <strong>Moben</strong>, decorrentes do uso da Plataforma.
      </p>
    </section>
  )
}

export const PrivacyTermTxt = () => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>1. Da Política de Proteção de Dados</h2>
      <p className={classes.text}>
        1.1. Esta Política de Privacidade e Proteção de Dados foi formulada tendo por base o disposto no GDPR (General
        Data Protection Regulation) e na Lei nº 13.709, de 14 de agosto de 2018.
        <br />
        <br />
        1.2. Recomenda-se, firmemente, a leitura cuidadosa desta Política de Privacidade e Proteção de Dados, pois ao
        visitar a Plataforma Moben, o usuário estará anuindo com os termos e condições previstos nesta Política de
        Privacidade e Proteção de Dados, em relação à coleta e à utilização de suas informações pessoais.
        <br />
        <br />
        1.3. A <strong>Moben</strong> pode coletar “Informações pessoais” e “Informações não pessoais” de seus Usuários.
        <br />
        <br />
        1.4. Informações pessoais são aquelas individualmente identificáveis e consistem em quaisquer informações que
        permitem identificar um indivíduo, incluindo, mas não se limitando, ao seu nome, endereço fixo ou de e-mail,
        qualificação pessoal, inscrição no CPF ou CNPJ, número de telefone, demais dados de contato, dados de cobrança
        ou quaisquer outros dados que possam ser suficientes para serem vinculados a essas informações.
        <br />
        <br />
        1.5. Informações não pessoais não permitem a identificação do usuário.
        <br />
        <br />
        1.6. As informações são rastreadas para fins de administração e manutenção de rotina.
        <br />
        <br />
        1.7. A coleta das informações tem como finalidade permitir que a <strong>Moben</strong> ofereça serviços e
        funcionalidades que possam ser mais adequados às necessidades de seus Usuários.
      </p>
    </section>
  )
}

export const CollectedData = () => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>2. Dados tratados</h2>
      <p className={classes.text}>
        2.1. A <strong>Moben</strong> coleta dados das seguintes formas:
        <br />
        <ol type="a">
          <li>espontaneamente, fornecidas pelo Usuário na Plataforma;</li>
          <li>automaticamente por meio da Plataforma;</li>
        </ol>
        <br />
        2.2.Poderão ser coletados os seguintes dados dos Usuários:
        <ol type="a">
          <li>
            Dados pessoais: que permitam a identificação pessoal do Usuário, como dados cadastrais, financeiros,
            documentos e canais de contato;
          </li>
          <li>
            Dados de imóveis: dados oficiais, documentação dos imóveis, detalhes arquitetônicos, de compartimentação
            interna, metragem, endereço, valor, entre outros;
          </li>
          <li>
            Informações geradas automaticamente por meio do uso da Plataforma, como IP, data e hora, navegador,
            características do dispositivo de acesso, informações sobre cliques e cookies, com o fim de aperfeiçoar a
            experiência de navegação;
          </li>
          <li>
            Comunicações entre Usuários ou entre Usuários e a Moben, por meio da Plataforma, incluindo canais de
            comunicação como e-mail e telefone.
          </li>
        </ol>
        2.3. O uso das tecnologias que capturam dados automaticamente por meio da Plataforma poderá ser desativado pelo
        Usuário a qualquer tempo, de acordo com as configurações de seu dispositivo de acesso.
        <span>
          2.3.1. O Usuário declara ter ciência de que a desativação das funcionalidades acima mencionadas pode
          comprometer o funcionamento adequado da Plataforma.
        </span>
      </p>
    </section>
  )
}

export const DataTermTxt = () => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>3. Utilização e Tratamento dos Dados</h2>
      <p className={classes.text}>
        3.1. A <strong>Moben</strong> coleta dados com as seguintes finalidades:
        <ol type="a">
          <li>
            dar suporte às funcionalidades de suas ferramentas e garantir o correto funcionamento da Plataforma,
            incluindo: oferecer melhor experiência de navegação ao Usuário, desenvolvimento de novas funcionalidades e
            aprimoramento do produto e serviços;
          </li>
          <li>verificar a veracidade e a autenticidade das informações fornecidas e dos cadastros criados;</li>
          <li>
            prestar, adequadamente, o serviço ofertado ao Usuário, incluindo: contato direto pelos canais de
            comunicação, oferecimento de produtos e serviços, checagem de informações;
          </li>
          <li>
            compartilhar dados com terceiros, desde que necessário, útil ou relevante para o adequado funcionamento da
            Plataforma, respeitados os Termos de Uso;
          </li>
          <li>análise de crédito e diligências, conforme definido nos Termos de Uso.</li>
        </ol>
        <br />
        3.2. O Usuário autoriza a utilização dos dados e documentos coletados, sejam eles cadastrais, pessoais, de
        crédito ou de comunicação para a validação, consulta e demais operações cabíveis junto a instituições
        financeiras, judiciais, cartorárias e de crédito, bem como o fornecimento e a utilização desses dados para o
        Proprietário, a <strong>Moben</strong> e/ou empresas parceiras, incluindo mas não se limitando aquelas
        envolvidas em questões de natureza financeira, econômica, contratual, negocial ou que versem acerca do crédito
        do Usuário.
        <br />
        <br />
        3.3. O Usuário concorda em receber e-mails promocionais e marketing direcionado da <strong>Moben</strong> e de
        seus parceiros, bem como comunicações acerca dos Serviços prestados por meio de mensagens de texto ou WhatsApp,
        caso o utilize, sendo o único responsável por ler atenta e integralmente a Política de Privacidade implementada
        por este.
        <br />
        <br />
        <span>
          3.3.1. Caso o Usuário não queira mais receber mensagens promocionais e de marketing da Moben, deverá informar
          esta opção acessando o link de cancelamento disponível no e-mail, ou solicitar sua exclusão por meio de outros
          canais dos canais de comunicação eventualmente disponíveis na Plataforma.
        </span>
        <br />
        <br />
        3.4. A <strong>Moben</strong> pode utilizar dados anonimizados, agrupados e categorizados sem o consentimento do
        usuário, como para estatísticas e analytics.
        <br />
        <br />
        3.5. Por meio da Plataforma, poderão ser apresentados links que conduzam o Usuário a outras páginas eletrônicas,
        inclusive disponibilizadas por terceiros, as quais podem apresentar Política de Privacidade com previsões
        diversas do disposto neste documento.
        <br />
        <br />
        3.6. A <strong>Moben</strong> se exime de qualquer responsabilidade decorrente da coleta, utilização,
        compartilhamento e armazenamento de dados dos Usuários pelos responsáveis por tais páginas.
      </p>
    </section>
  )
}

export const ShareTermTxt = () => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>4. Compartilhamento de Informações</h2>
      <p className={classes.text}>
        4.1. A <strong>Moben</strong> poderá compartilhar os dados coletados por meio da Plataforma nos seguintes
        termos:
        <ol type="a">
          <li>
            para o Proprietário, instituições financeiras, cartórios, bureaus de crédito e outras instituições para as
            quais a divulgação dos dados se mostre necessária para o adequado desempenho das atividades previstas nestes
            Termos;
          </li>
          <li>
            para empresas parceiras, visando à emissão de boletos, cobranças, ou para fins promocionais e comerciais,
            conforme indicado nesta Política;
          </li>
          <li>
            para proteção dos interesses da <strong>Moben</strong>, em qualquer conflito, inclusive demandas judiciais;
          </li>
          <li>
            em transações e alterações societárias envolvendo a <strong>Moben</strong>, hipótese em que a transferência
            das informações será necessária para a continuidade dos Serviços;
          </li>
          <li>
            mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal
            para sua requisição;
          </li>
          <li>mediante expressa autorização do Usuário.</li>
        </ol>
      </p>
    </section>
  )
}

export const StorageTermTxt = () => {
  const classes = useStyles()
  return (
    <section className={classes.termsTextSection}>
      <h2 className={[classes.title, {textDecorator: 'none'}]}>5. Armazenamento dos Dados</h2>
      <p className={classes.text}>
        5.1. A <strong>Moben</strong> armazena as informações coletadas por meio da Plataforma em servidores próprios ou
        de terceiros contratados por esta, localizados no Brasil ou no exterior.
        <br />
        <br />
        5.2. A <strong>Moben</strong> emprega os melhores esforços visando à preservação da segurança de seus sistemas
        na guarda dos dados de seus Usuários.
        <br />
        <br />
        5.3. A <strong>Moben</strong> entende como confidenciais os dados coletados por meio da Plataforma, assegurando
        que estes serão tratados e armazenados nos termos desta Política, mediante adoção das medidas de segurança
        cabíveis.
        <br />
        <br />
        5.4. Em que pesem os esforços adotados pela <strong>Moben</strong> em sentido contrário, em razão da própria
        natureza da Internet, não há como assegurar que terceiros não autorizados não logrem sucesso em acessar
        indevidamente as informações armazenadas pela Plataforma.
      </p>
    </section>
  )
}
