import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useTheme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Button} from '@root/components/Button/Button'
import mobenTheme from '@root/mobenTheme'
import PropTypes from 'prop-types'
import React from 'react'

import useStyles from './styles'

const creditAnalysisPaperProps = {
  borderRadius: '20px',
  // width: '376px',
  // height: '460px',
  display: 'flex',
  flexDirection: 'column',
}

function BaseModal({
  onClose,
  isOpen,
  title,
  okLabel,
  okAction,
  cancelLabel,
  cancelAction,
  headerBg,
  headerColor,
  alignCenter,
  size,
  mainStyle,
  dialodContentStyle,
  children,
}) {
  const classes = useStyles({alignCenter})
  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))

  const cancelInterceptor = (ev) => {
    if (!cancelAction) {
      onClose()
      return
    }
    cancelAction(ev)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={isExtraSmallViewPort}
      maxWidth={size}
      PaperProps={{
        style: {...creditAnalysisPaperProps, ...mainStyle},
      }}
      BackdropProps={{
        style: {
          background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
        },
      }}>
      <DialogTitle
        style={{
          color: headerColor,
          background: headerBg,
        }}
        className={classes.modalAnalysisResultTitle}>
        <Typography
          variant="h5"
          align="center"
          style={
            {
              // color: config.theme.color,
            }
          }>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.analysisContent} style={dialodContentStyle}>
        {children}
      </DialogContent>

      {(cancelLabel || okLabel) && (
        <DialogActions className={classes.analysisActionsContainer}>
          {cancelLabel && (
            <Button withBorder={false} noBg={true} fz="12px" onClick={cancelInterceptor}>
              {cancelLabel}
            </Button>
          )}

          {okLabel && (
            <Button fz="18px" onClick={okAction}>
              {okLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

BaseModal.defaultProps = {
  okLabel: 'OK',
  cancelLabel: 'Fechar',
  headerBg: mobenTheme.palette.primary.main,
  headerColor: mobenTheme.extraColors.WHITE,
  alignCenter: true,
  size: 'xs',
  mainStyle: {},
  dialodContentStyle: {},
}

BaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  headerBg: PropTypes.string,
  headerColor: PropTypes.string,
  okLabel: PropTypes.string,
  okAction: PropTypes.func,
  cancelLabel: PropTypes.string,
  cancelAction: PropTypes.func,
  alignCenter: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
}
export default BaseModal
