import Typography from '@material-ui/core/Typography'
import analyticsLogger from '@root/firebase/analytics.utils'
import createAlternativeEventName from '@root/lib/createAlternativeEventName'
import * as PropTypes from 'prop-types'
import React from 'react'
import {useHistory} from 'react-router-dom'

const BackLink = (props) => {
  const history = useHistory()
  const event_name = createAlternativeEventName('voltar')
  const handleBack = (e) => {
    analyticsLogger.logEvent(event_name)
    if (!props.onClick) {
      history.goBack()
    } else {
      props.onClick(e)
    }
  }

  return (
    <div id={event_name} className="clickable" {...props} onClick={handleBack}>
      <Typography variant="body1" color="primary" style={{display: 'flex', alignItems: 'center', fontWeight: 700}}>
        <span style={{marginRight: '2px', fontWeight: 700}}>&#8592;</span>
        {props.title}
      </Typography>
    </div>
  )
}

BackLink.defaultProps = {
  title: 'Voltar',
}
BackLink.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
}
export default BackLink
