/** @module proposalSaver */
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import {uploadAndSaveFileWithStepedModal} from '@root/lib/firebaseUploader'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import {mailAnalysisConsentRequest} from '@root/model/mailing'
import {USER_SOURCE_SELF} from '@root/utils/constants'

import {firestore} from '../firebase/firebase.utils'

/**
 * @param {object} params
 * @param {File} params.doc
 * @param {string} params.readableName
 * @param {string} params.field
 * @param {object} params.userSnap
 * @param userSnap
 */
const uploadDoc = async ({doc, field, readableName, userSnap}) => {
  if (doc) {
    return uploadAndSaveFileWithStepedModal({
      file: doc,
      readableName,
      docRef: userSnap.ref,
      destinationDir: `user/${userSnap.id}`,
      docUpdatePath: `documents.${field}`,
      prefix: userSnap.id,
    })
  }
}

/**
 * @param {object} params
 * @param {ProposalManager} params.manager
 * @param {string} params.proposalId
 * @param {string} params.proposalType
 * @param {string} params.originSource
 * @param {boolean} [params.autoAnalysis=false]
 * @param {object} params.tenantFormData
 * @param {object} params.propertySnap
 * @param {object} [params.extraPropertyData={}]
 * @return {Promise<ProposalHelper>}
 */
export const proposalSaver = async ({
  manager,
  proposalId,
  autoAnalysis = false,
  proposalType = USER_TYPE_TENANT,
  tenantFormData,
  originSource,
  propertySnap,
  extraPropertyData = {},
  isTenantApproved = false,
}) => {
  const proposalManager = manager
  let proposalHelper = new ProposalHelper()
  proposalHelper.initEmptyProposal()
  proposalHelper.setAutoAnalysis(autoAnalysis)
  // const proposalHelper = ProposalHelper.initWithProperty(currentProperty)

  if (proposalId) {
    if (proposalManager.exists(proposalId)) {
      proposalHelper = proposalManager.findProposalById(proposalId)
    } else {
      throw new Error('A proposta não foi encontrada')
    }
  }
  const {docs, ...userData} = tenantFormData

  const newUserPayload = await ProposalHelper.saveUser(
    proposalType,
    {...proposalHelper.clearInjectedSnap(userData)},
    undefined,
    propertySnap.data(),
    originSource,
    isTenantApproved,
  )
  proposalHelper.addInterestedFromSnap(proposalType, newUserPayload.snap)
  manager.addProposal(proposalHelper)

  propertySnap.ref.update({
    ...extraPropertyData,
    [proposalHelper.getPath()]: proposalHelper.toObject(),
  })

  await uploadDoc({
    readableName: 'Identidade',
    field: 'identity',
    doc: docs?.identity,
    userSnap: newUserPayload.snap,
  })
  await uploadDoc({
    readableName: 'Declaração de renda',
    field: 'income',
    doc: docs?.income,
    userSnap: newUserPayload.snap,
  })

  const isNotSelfRegister = newUserPayload.snap.data().register_by !== USER_SOURCE_SELF
  if (isNotSelfRegister) {
    mailAnalysisConsentRequest({
      property_id: propertySnap.id,
      interested_id: newUserPayload.snap.id,
      proposal_id: proposalHelper.getId(),
    })
  }

  return proposalHelper
}
