import Grid from '@material-ui/core/Grid'
import {Button} from '@root/components/Button/Button'
import IconEye from '@root/components/icons/IconEye'
import BarcodeImageViewer from '@root/pages/Payment/BarcodeImageViewer'
import React from 'react'

function PaymentAreaBoleto({payment, onCopyCode, buttonLabel, buttonIcon}) {
  return (
    <>
      <Grid item>
        <div style={{textAlign: 'center', fontSize: 14}}>
          <span>{payment.copy_code}</span>
          <BarcodeImageViewer image={payment.image_code} />
        </div>
      </Grid>
      <Grid container item direction="row" justify="space-evenly">
        <Grid item>
          <Button component="a" href={payment.document_url} target="_blank" icon={IconEye}>
            Visualizar o Boleto
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={onCopyCode} icon={buttonIcon}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentAreaBoleto
