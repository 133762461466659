import {ApplicationTypes} from '@root/redux/application/application.types'

export const setApplicationReadyAction = () => ({
  type: ApplicationTypes.APP_READY,
})

export const setCookiesAction = (isAccepted) => {
  return {
    type: ApplicationTypes.ACCEPT_COOKIES,
    payload: {accepted: isAccepted},
  }
}

export const acceptCookiesAction = () => setCookiesAction(true)

export const declineCookiesAction = () => setCookiesAction(false)

export const setGuideVisibility = (visibility) => ({
  type: ApplicationTypes.SET_GUIDE_VISIBILITY,
  payload: {visibility},
})

export const setRouteInfoAction = (routeInfo) => ({
  type: ApplicationTypes.SET_ROUTE_INFO,
  payload: {routeInfo},
})

export const setHeaderlessAction = (isHeaderless) => ({
  type: ApplicationTypes.SET_HEADERLESS,
  payload: {isHeaderless},
})

export const setNavbarTitleAction = (title) => ({
  type: ApplicationTypes.SET_NAVBAR_TITLE,
  payload: {title},
})

export const setUseMainPadding = (useMainPadding) => ({
  type: ApplicationTypes.SET_USE_MAIN_PADDING,
  payload: {useMainPadding},
})

export const addOptimizeVariant = (alias, variantId) => ({
  type: ApplicationTypes.ADD_OPTIMIZE_VARIANT,
  payload: {alias, variantId},
})
