import {Dialog, Typography} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  ANSWER_COMMERCIAL,
  ANSWER_NO,
  ANSWER_RESIDENTIAL,
  ANSWER_YES,
} from '@root/components/App/Cadastro/cadastro-components/TenantMoreAbout/DefaultRadioGroup'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FilePickerMultiple from '@root/components/FilePickerMultiple'
import FormItem from '@root/components/FormHelper/FormItem'
import RequiredMark from '@root/components/FormHelper/RequiredMark'
import AddressForm from '@root/components/Forms/AddressForm'
import {Box} from '@root/components/Layout/Container/Box/Box'
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import {currencyFormatProps} from '@root/components/NumberMask'
import {RadioItem} from '@root/components/PropertyRegister/PropertyRegister'
import {discordNotifyCreateAd} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import pluralize from '@root/lib/intl/pluralize'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {
  adDescriptionPlaceholder,
  addressDisplay,
  featuresAccessAndLocalization,
  featuresDetailsAndServices,
  featuresExtraEquipment,
  featuresLeisure,
  featuresSecurity,
  propertyTypes,
} from '@root/lib/predefinedValuesOptions'
import {safePropertyReset} from '@root/lib/safeReset'
import {
  numberFormatValidateCurrency,
  numberFormatValidateCurrencyOptional,
  validateNumber,
  validateNumberOptional,
  validateRequired,
  validateTextLen,
} from '@root/lib/simpleValidators'
import propertyModel from '@root/model/propertyModel'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {MOBEN_WHATSAPP} from '@root/utils/constants'
import routes from '@root/utils/routes'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import Carousel from 'react-material-ui-carousel'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import propertyTypesMapper from 'status-mapper/mapper/property-type-mapper'

import AdFormPhotosContainer from './AdFormPhotosContainer/AdFormPhotosContainer'
import useStyles from './styles'

const MIN_IMAGES_LEN = 1
const PHOTOS_TO_DISPLAY = 11
const radioStyles = {
  radioLabelStyle: {textAlign: 'center'},
  style: {marginBottom: 10},
}
const incluirNoValorTotalStyle = {marginBottom: 22, fontSize: '14px', color: '#262626'}
const carouselPhotoWrapperStyles = {
  wrapper: {height: '100%', width: '100%', display: 'flex', alignItems: 'center'},
  img: {width: '100%'},
}

const validateYouTubeVideo = (link) => {
  if (!link) {
    return true
  }

  try {
    const url = new URL(link)
    if (url.host === 'www.youtube.com' || url.host === 'youtube.com') {
      return true
    }
  } catch (ex) {
    return 'Não é um link válido'
  }

  return 'Não é um link do youtube'
}

const FormLabel = ({label}) => (
  <Typography variant="h6" align="center" color="secondary">
    {label}
  </Typography>
)

const PropertyAdForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const formHook = useForm()
  const {handleSubmit, control, errors, reset, watch, setValue, getValues} = formHook

  const history = useHistory()

  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))

  const [adPhotos, setAdPhotos] = useState([])
  const [isDeletePhotosMode, setIsDeletePhotosMode] = useState(false)
  const [isMovePhotosMode, setIsMovePhotosMode] = useState(false)
  const [photosToDeleteURLs, setPhotosToDeleteURLs] = useState([])
  const [photosDialogOpen, setPhotosDialogOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [totalPhotosToDisplay, setTotalPhotosToDisplay] = useState(PHOTOS_TO_DISPLAY)

  const property = useSelector((s) => s.property.data)
  const propertyRef = useSelector((s) => s.property.snap.ref)
  const user = useSelector((s) => s.user.user)

  const formWatcher = watch([
    'rent_amount',
    'condominium_amount',
    'iptu_amount',
    'property_type',
    'occupation_sector',
    'iptu',
    'condominium',
  ])

  const includeCondominium = formWatcher.condominium?.include_condominium
  const includeIptu = formWatcher.iptu?.include_iptu

  const totalFee = useMemo(() => {
    return (
      formatMoneyBrToFloat(formWatcher.rent_amount) +
      (includeCondominium ? formatMoneyBrToFloat(formWatcher.condominium_amount) : 0) +
      (includeIptu ? formatMoneyBrToFloat(formWatcher.iptu_amount) / 12 : 0)
    )
  }, [formWatcher])

  const isApartment = propertyTypesMapper.assertStatus.isApartment(formWatcher.property_type)

  const ruleForApartment = {validate: isApartment ? validateRequired : undefined}

  const handleDialogOnClose = () => {
    setPhotosDialogOpen(false)
    setPhotoIndex(0)
  }

  const handleSolicitarFotografo = () => {
    isExtraSmallViewPort
      ? window.open(
          `https://api.whatsapp.com/send?phone=55${MOBEN_WHATSAPP}&text=Olá, gostaria de solicitar um fotógrafo gratuito para o anúncio do meu imóvel`,
          '_self',
        )
      : window.open(
          `https://api.whatsapp.com/send?phone=55${MOBEN_WHATSAPP}&text=Olá, gostaria de solicitar um fotógrafo gratuito para o anúncio do meu imóvel`,
          '_blank',
        )
  }

  const getPhotoClassName = (photo) => {
    let className = ''

    if (photo.isPrimary) {
      className = classes.photoPrimary
    }

    if (photosToDeleteURLs.length && photosToDeleteURLs.includes(photo.download_url)) {
      className = `${className} ${classes.photoDelete}`
    }

    return className
  }

  const handlePhotoOnClick = (clickedPhotoURL, idx) => {
    if (isDeletePhotosMode) {
      const photoExists = photosToDeleteURLs.find((url) => url === clickedPhotoURL)

      if (photoExists) {
        setPhotosToDeleteURLs([...photosToDeleteURLs.filter((url) => url !== clickedPhotoURL)])
      } else {
        setPhotosToDeleteURLs([...photosToDeleteURLs, clickedPhotoURL])
      }
    } else {
      setPhotoIndex(idx)
      setPhotosDialogOpen(true)
    }
  }

  const handleDeletePhotosOnClick = async () => {
    if (!photosToDeleteURLs.length) return

    await onDeleteFileOnClick()
    setPhotosToDeleteURLs([])
    setIsDeletePhotosMode(false)
  }

  const handleCancelDeletePhotosOnClick = () => {
    setPhotosToDeleteURLs([])
    setIsDeletePhotosMode(false)
  }

  const handleSetPhotoPrimaryOnClick = async () => {
    const currentPhoto = adPhotos[photoIndex]

    const primaryPhoto = adPhotos.find((photo) => photo.isPrimary)

    if (primaryPhoto.download_url === currentPhoto.download_url) return

    const newAdPhotos = adPhotos.map((photo) => {
      if (photo.download_url === currentPhoto.download_url) {
        return {
          ...photo,
          isPrimary: true,
        }
      } else {
        return {
          ...photo,
          isPrimary: false,
        }
      }
    })

    handleDialogOnClose()

    setAdPhotos(newAdPhotos)

    try {
      await propertyRef.update('ad.photos', newAdPhotos)
    } catch (ex) {
      alert(errorHandler(ex))
    }
  }

  const handlePreVisualizarAnuncioOnClick = (formProps) => {
    forwardHandleSubmit(formProps, 'preview_ad')
  }

  const handleCriarAnuncioOnClick = (formProps) => {
    forwardHandleSubmit(formProps, 'create_ad')
  }

  const forwardHandleSubmit = async ({specs, more_about, ad, total_amount, condominium, iptu, ...rest}, action) => {
    if (adPhotos.length < MIN_IMAGES_LEN) {
      alert(`Envie pelo menos ${MIN_IMAGES_LEN} ${pluralize(MIN_IMAGES_LEN, 'foto')}`)
      return
    }

    const newSpecs = {
      ...property.specs,
      ...specs,
    }

    const newMoreAbout = {
      ...property.more_about,
      ...more_about,
    }

    const propertyAd = {
      ...property.ad,
      description: ad.description,
      video_link: ad.video_link,
      photos: [...adPhotos],
    }

    const sanitizedProps = propertyModel.prepareForDatabase(rest)

    try {
      dispatch(setGlobalLoaderAction(true))

      const newPropertyProps = {
        ...sanitizedProps,
        include_condominium: condominium.include_condominium ?? false,
        include_iptu: iptu.include_iptu ?? false,
        total_amount: sanitizedProps.rent_amount + sanitizedProps.condominium_amount + sanitizedProps.iptu_amount / 12,
        ad: propertyAd,
        specs: newSpecs,
        more_about: newMoreAbout,
        ad_form_filled: true,
        ad_enabled: action === 'preview_ad' ? false : true,
      }

      if (property?.ad_disabled_by_admin) {
        await propertyRef.update({
          ...newPropertyProps,
        })
      } else {
        await propertyRef.update({
          ...newPropertyProps,
          ad_disabled_by_admin: false,
        })
      }

      dispatch(setGlobalLoaderAction(false))

      if (!property.ad_form_filled) {
        discordNotifyCreateAd({property, user})
      }

      if (action === 'preview_ad') {
        window.open(`https://next.moben.com.br/anuncios/${property?.uid}`, '_blank')
      } else {
        history.push(routes.DASHBOARD_ROOT)
      }
    } catch (ex) {
      alert(errorHandler(ex))
    }
  }

  const handleMostrarFotosOnClick = () => {
    totalPhotosToDisplay === PHOTOS_TO_DISPLAY
      ? setTotalPhotosToDisplay(adPhotos.length)
      : setTotalPhotosToDisplay(PHOTOS_TO_DISPLAY)
  }

  const onChangeFileCallback = useCallback(async (uploadedFiles, filePrefix) => {
    const list = property?.ad?.photos ?? []

    const uploadedPhotos = uploadedFiles.map((photo) => ({
      ...photo,
      isPrimary: false,
    }))

    const allPhotos = [...uploadedPhotos, ...list]

    await propertyModel.updateById(property.uid, 'ad.photos', [...list, ...uploadedPhotos])
  })

  const onDeleteFileOnClick = useCallback(async () => {
    const filteredArr = property?.ad?.photos.filter((doc) => !photosToDeleteURLs.includes(doc.download_url))

    await propertyModel.updateById(property.uid, 'ad.photos', [...filteredArr])
  })

  const handleSavePhotosOrderOnClick = async () => {
    try {
      await propertyRef.update('ad.photos', adPhotos)
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      setIsMovePhotosMode(!isMovePhotosMode)
    }
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction('Dados do Anúncio'))
  }, [])

  useEffect(() => {
    safePropertyReset(
      {
        ...property,
        iptu: {include_iptu: property.include_iptu},
        condominium: {include_condominium: property.include_condominium},
      },
      reset,
    )
  }, [])

  useEffect(() => {
    if (property?.ad?.photos.length) {
      const hasPrimary = property.ad.photos.some((photo) => photo.isPrimary)

      let photos = property.ad.photos.map((photo) => ({...photo}))

      if (!hasPrimary) {
        photos[0].isPrimary = true
      }

      const primary = photos.find((photo) => photo.isPrimary)
      const photosWithoutPrimary = photos.filter((photo) => !photo.isPrimary)

      const sortedPhotos = [primary, ...photosWithoutPrimary]

      setAdPhotos(sortedPhotos)
    }
  }, [property])

  return (
    <FormProvider {...formHook}>
      <form className={classes.adRoot}>
        <Typography variant="body2" align="left">
          Para divulgar o seu imóvel é importante que o anúncio seja bem detalhado. <br />
          Facilitamos o processo criando o formulário abaixo para você se lembrar de inserir todas as informações
          necessárias. <br />
          Seu anúncio será divulgado nos portais mais importantes do mercado: OLX, Viva Real e Zap imóveis.
        </Typography>

        <AddressForm address={property?.address} />

        <div className={classes.specsContainer}>
          <FormItem
            color="secondary"
            required
            control={control}
            errors={errors}
            type="textfield"
            name="specs.footage"
            label="Metragem (m&#178;)"
            rules={{
              validate: validateNumber,
            }}
          />

          <FormItem
            color="secondary"
            required
            control={control}
            errors={errors}
            type="textfield"
            name="specs.rooms"
            label="Quartos"
            rules={{
              validate: validateNumber,
            }}
          />

          <FormItem
            color="secondary"
            required
            control={control}
            errors={errors}
            type="textfield"
            name="specs.bathrooms"
            label="Banheiros"
            rules={{
              validate: validateNumber,
            }}
          />

          <FormItem
            color="secondary"
            required
            control={control}
            errors={errors}
            type="textfield"
            name="specs.garage"
            label="Garagens"
            rules={{
              validate: validateNumber,
            }}
          />
        </div>

        <RadioItem
          required
          fitContainer
          label="Tipo de imóvel"
          control={control}
          errors={errors}
          type="radio"
          name="occupation_sector"
          options={[
            {
              label: 'Residencial',
              value: ANSWER_RESIDENTIAL,
            },
            {
              label: 'Comercial',
              value: ANSWER_COMMERCIAL,
            },
          ]}
          rules={{validate: validateRequired}}
          extraStyles="row"
          style={radioStyles.style}
          radioLabelStyle={radioStyles.radioLabelStyle}
        />

        {formWatcher.occupation_sector === ANSWER_COMMERCIAL && (
          <Box bgType="gray" p="0 10px 4px" m="0 0 30px">
            <div className={classes.comercialActivityContainer}>
              <Typography variant="body1">Atividade Comercial*</Typography>
              <FormItem
                control={control}
                errors={errors}
                required={true}
                name="comercial_activity"
                rules={{
                  validate: validateRequired,
                }}
              />
            </div>
          </Box>
        )}

        <RadioItem
          required
          fitContainer
          label="Imóvel mobiliado?"
          control={control}
          errors={errors}
          type="radio"
          name="more_about.furnished"
          options={[
            {
              label: 'Sim',
              value: ANSWER_YES,
            },
            {
              label: 'Não',
              value: ANSWER_NO,
            },
          ]}
          rules={{validate: validateRequired}}
          extraStyles="row"
          style={radioStyles.style}
          radioLabelStyle={radioStyles.radioLabelStyle}
        />

        <RadioItem
          required
          fitContainer
          label="Animais são permitidos?"
          control={control}
          errors={errors}
          type="radio"
          name="more_about.pets"
          options={[
            {
              label: 'Sim',
              value: ANSWER_YES,
            },
            {
              label: 'Não',
              value: ANSWER_NO,
            },
          ]}
          rules={{validate: validateRequired}}
          extraStyles="row"
          style={radioStyles.style}
          radioLabelStyle={radioStyles.radioLabelStyle}
        />

        <FormLabel label="Categoria do imóvel" />
        <FormItem
          control={control}
          errors={errors}
          required
          label=""
          name="property_type"
          type="select"
          options={propertyTypes}
          rules={{validate: validateRequired}}
          style={{width: '300px', margin: '0 auto'}}
        />

        {isApartment && (
          <>
            <FormLabel label="Sobre o Condomínio" />

            <div className={classes.condominiumSpecs}>
              <FormItem
                color="secondary"
                control={control}
                errors={errors}
                label="Qual andar?"
                name="specs.floor"
                required={isApartment}
                rules={ruleForApartment}
              />

              <FormItem
                control={control}
                errors={errors}
                label="Número de andares"
                name="specs.floors"
                required={isApartment}
                rules={ruleForApartment}
              />

              <FormItem
                control={control}
                errors={errors}
                label="Unidades por andar"
                name="specs.units_per_floor"
                optional={true}
                rules={{validate: validateNumberOptional}}
              />

              <FormItem
                color="secondary"
                control={control}
                errors={errors}
                label="Quantas suítes possui?"
                name="specs.suites"
                optional={true}
                rules={{validate: validateNumberOptional}}
              />

              <FormItem
                control={control}
                errors={errors}
                label="Número de torres"
                name="specs.buildings"
                optional={true}
                rules={{validate: validateNumberOptional}}
              />

              <FormItem
                control={control}
                errors={errors}
                label="Ano de construção"
                name="specs.year_built"
                optional={true}
                rules={{validate: validateNumberOptional}}
              />
            </div>
          </>
        )}

        <RadioItem
          required
          fitContainer
          label="Divulgação do endereço"
          control={control}
          errors={errors}
          type="radio"
          name="address_display"
          options={addressDisplay}
          defaultValue="full_address"
          rules={{validate: validateRequired}}
          extraStyles="row"
          style={radioStyles.style}
          radioLabelStyle={radioStyles.radioLabelStyle}
        />

        <Typography variant="h6" align="center" style={{fontWeight: 600, margin: '0 0 20px'}}>
          Selecione os itens em cada sessão
        </Typography>

        <FormLabel label="Acesso e Localização" />

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="checkbox"
          name="features.accessAndLocalization"
          options={featuresAccessAndLocalization}
          optional={true}
        />

        <FormLabel label="Detalhes e Serviços" />

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="checkbox"
          name="features.detailsAndServices"
          options={featuresDetailsAndServices}
          optional={true}
        />

        <FormLabel label="Lazer" />

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="checkbox"
          name="features.leisure"
          options={featuresLeisure}
          optional={true}
        />

        <FormLabel label="Segurança" />
        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="checkbox"
          label=""
          name="features.security"
          options={featuresSecurity}
          optional={true}
        />

        <FormLabel label="Equipamentos Extras" />

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="checkbox"
          name="features.extraEquipment"
          options={featuresExtraEquipment}
          optional={true}
        />

        <Divider dashed />

        <FormLabel label="Valores" />

        <section>
          <Box bgType="gray" p="0 10px 4px" m="0 0 10px">
            <div className={classes.amountsRentContainer}>
              <Typography variant="body1">
                Valor mensal do aluguel <RequiredMark required={true} />{' '}
              </Typography>

              <div>
                <FormItem
                  control={control}
                  errors={errors}
                  type="mask"
                  name="rent_amount"
                  rules={{
                    validate: numberFormatValidateCurrency,
                  }}
                  maskInputProps={currencyFormatProps}
                  defaultValue="0"
                />
              </div>
            </div>
          </Box>

          <Box bgType="gray" p="0 10px 4px" m="0 0 10px">
            <div className={classes.amountsContainer}>
              <div>
                <Typography variant="body1">Valor mensal do condomínio</Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <FormItem
                    control={control}
                    errors={errors}
                    type="checkbox"
                    name="condominium"
                    options={[{value: 'include_condominium'}]}
                    optional={true}
                    defaultValue={{include_condominium: false}}
                  />
                  <p style={incluirNoValorTotalStyle}>Incluir no valor total</p>
                </div>
              </div>

              <div>
                <FormItem
                  control={control}
                  errors={errors}
                  required={false}
                  type="mask"
                  name="condominium_amount"
                  rules={{
                    validate: numberFormatValidateCurrencyOptional,
                  }}
                  maskInputProps={currencyFormatProps}
                  defaultValue="0"
                />
              </div>
            </div>
          </Box>

          <Box bgType="gray" p="0 10px 4px" m="0 0 10px">
            <div className={classes.amountsContainer}>
              <div>
                <Typography variant="body1">Valor anual to IPTU</Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <FormItem
                    control={control}
                    errors={errors}
                    type="checkbox"
                    name="iptu"
                    options={[{value: 'include_iptu'}]}
                    optional={true}
                    defaultValue={{include_iptu: false}}
                  />
                  <p style={incluirNoValorTotalStyle}>Incluir no valor total</p>
                </div>
              </div>

              <div>
                <FormItem
                  control={control}
                  errors={errors}
                  required={false}
                  type="mask"
                  name="iptu_amount"
                  rules={{
                    validate: numberFormatValidateCurrencyOptional,
                  }}
                  maskInputProps={currencyFormatProps}
                  defaultValue="0"
                />
              </div>
            </div>
          </Box>

          <Typography variant="body2" align="center" style={{margin: '15px 0 10px'}}>
            O valor do IPTU é dividido em 12 parcelas caso você escolha incluir no boleto
          </Typography>

          <div className={classes.valorTotalContainer}>
            <Box bgType="gray" h="106px" m="20px 0 0">
              <div className="wrapper">
                <Typography variant="h6" align="center">
                  Valor total do anúncio
                </Typography>
                <Typography variant="h4" color="secondary">
                  <NumberFormatCustom value={totalFee} displayType="text" />
                </Typography>
              </div>
            </Box>
          </div>
        </section>

        <Box bgType="gray" m="0 0 30px !important" smallPadding>
          <section className={classes.descricaoSection}>
            <Typography variant="h6" align="center">
              Descrição do Imóvel
            </Typography>
            <Typography variant="body2" align="left">
              A descrição do imóvel importante para as pessoas entenderem sobre detalhes como o tipo de mobília, o tipo
              de piso, se as janelas são amplas, se é luminoso, sobre posição do sol e o tipo de bairro. <br />
              Você pode indicar também algumas características como por exemplo bairro arborizado, vizinhança tranquila
              e vista ampla. Outras informações como proximidade de escolas, igrejas, parques e ciclovias podem ser
              fundamentais na decisão do interessado em alugar seu imóvel. <br />
              Capriche! Dê uma olhada no exemplo abaixo.
            </Typography>

            <FormItem
              control={control}
              errors={errors}
              type="textarea"
              name="ad.description"
              placeholder={adDescriptionPlaceholder}
              fitContainer={true}
              rows={10}
              rules={{validate: (text) => validateTextLen(text, 50, 3000)}}
              textAreaStyle={{backgroundColor: '#FFFFFF'}}
            />
          </section>
        </Box>

        <Box bgType="gray" smallPadding>
          <section className={classes.descricaoSection}>
            <Typography variant="h6" align="center">
              Vídeo do anúncio
            </Typography>
            <Typography variant="body2" align="left">
              Link para o vídeo que deve estar hospedado no Youtube.
            </Typography>
            <FormItem
              control={control}
              errors={errors}
              type="textfield"
              name="ad.video_link"
              fitContainer={true}
              rules={{validate: validateYouTubeVideo}}
            />
          </section>
        </Box>
        <Box bgType="gray" smallPadding>
          <section className={classes.photosSection}>
            <Typography variant="h6" align="center">
              Fotos
            </Typography>
            <Typography variant="body2" align="left">
              É necessário enviar pelo menos {MIN_IMAGES_LEN} {pluralize(MIN_IMAGES_LEN, 'foto')} para o seu anúncio.{' '}
              <br />
              O indicado é que você envie pelo menos três fotos, mas quanto mais imagens mandar, melhor!
              <br />
              Uma das fotos será a foto de destaque do anúncio. Basta clicar na foto que você quiser para selecioná-la
              como destaque.
              <br />
            </Typography>

            <div className={classes.carregarSolicitarFotosContainer}>
              <div>
                <FilePickerMultiple
                  label="Carregar Fotos"
                  uid={property?.uid}
                  filePrefix="ad_"
                  section={`property/${property?.uid}`}
                  list={property?.ad?.photos ?? []}
                  onChangeFileCallback={onChangeFileCallback}
                />
              </div>
              <div className={classes.solicitarFotografoContainer}>
                <Button
                  onClick={handleSolicitarFotografo}
                  noBg
                  fz="15px"
                  style={{color: '#0101CD', textDecoration: 'underline'}}>
                  Solicitar Fotógrafo Profissional
                </Button>
              </div>
            </div>

            {adPhotos.length ? <div style={{textAlign: 'center'}}>Fotos enviadas - {adPhotos.length} </div> : null}

            <AdFormPhotosContainer
              classes={classes}
              adPhotos={adPhotos}
              setAdPhotos={setAdPhotos}
              photosToDisplay={PHOTOS_TO_DISPLAY}
              totalPhotosToDisplay={totalPhotosToDisplay}
              handlePhotoOnClick={handlePhotoOnClick}
              getPhotoClassName={getPhotoClassName}
              handleMostrarFotosOnClick={handleMostrarFotosOnClick}
              isMovePhotosMode={isMovePhotosMode}
            />

            {adPhotos.length ? (
              <div className={classes.excluirPhotosContainer}>
                {isDeletePhotosMode ? (
                  <div className={classes.excluirCancelarWrapper}>
                    <Typography variant="body2" style={{marginRight: 20}}>
                      Selecione as fotos que deseja excluir e clique em Excluir
                    </Typography>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Button noBg fz="15px" onClick={() => handleDeletePhotosOnClick(!isDeletePhotosMode)}>
                        Excluir
                      </Button>
                      <Button noBg fz="15px" onClick={() => handleCancelDeletePhotosOnClick(!isDeletePhotosMode)}>
                        Cancelar
                      </Button>
                    </div>
                  </div>
                ) : isMovePhotosMode ? (
                  <div className={classes.excluirCancelarWrapper}>
                    <Typography variant="body2" style={{marginRight: 20}}>
                      Clique na foto e arraste-a para a posição desejada. <br />
                      Depois que tiver feito todas as alterações, clique em salvar.
                    </Typography>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Button noBg fz="15px" onClick={handleSavePhotosOrderOnClick}>
                        Salvar
                      </Button>
                      <Button noBg fz="15px" onClick={() => setIsMovePhotosMode(!isMovePhotosMode)}>
                        Cancelar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Button m="0 auto 15px" noBg fz="15px" onClick={() => setIsMovePhotosMode(!isMovePhotosMode)}>
                      Ordenar Fotos
                    </Button>
                    <Button m="0 auto 15px" noBg fz="15px" onClick={() => setIsDeletePhotosMode(!isDeletePhotosMode)}>
                      Excluir Fotos
                    </Button>
                  </div>
                )}
              </div>
            ) : null}
          </section>
        </Box>

        <div className={classes.btnWrapper}>
          <Button fz="15px" noBg color="primary" onClick={handleSubmit(handlePreVisualizarAnuncioOnClick)}>
            Pré-Visualizar o anúncio na Moben
          </Button>
        </div>

        <div className={classes.btnWrapper}>
          <Button fz="15px" onClick={handleSubmit(handleCriarAnuncioOnClick)}>
            Publicar anúncio
          </Button>
        </div>

        <Dialog
          fullWidth
          disableScrollLock
          maxWidth="xl"
          open={photosDialogOpen}
          onClose={handleDialogOnClose}
          classes={{paperFullWidth: classes.dialogPaper}}>
          <Carousel
            animation="fade"
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysVisible={true}
            index={photoIndex}
            next={(nextIndex) => setPhotoIndex(nextIndex)}
            prev={(prevIndex) => setPhotoIndex(prevIndex)}>
            {adPhotos.map((photo, i) => (
              <div key={photo.download_url} style={carouselPhotoWrapperStyles.wrapper}>
                <img src={photo.download_url} style={carouselPhotoWrapperStyles.img} />
              </div>
            ))}
          </Carousel>

          {!adPhotos[photoIndex]?.isPrimary && (
            <div
              className="fotoActionButtonsWrapper"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
              <Button fz="15px" m="0 0 5px" onClick={() => handleSetPhotoPrimaryOnClick()}>
                Selecionar como Foto de Destaque
              </Button>
              <Button noBg fz="18px" fw={600} onClick={() => setPhotosDialogOpen(false)}>
                Voltar
              </Button>
            </div>
          )}

          {adPhotos[photoIndex]?.isPrimary && (
            <div className="fotoActionButtonsWrapper">
              <Button noBg fz="18px" fw={600} onClick={() => setPhotosDialogOpen(false)}>
                Voltar
              </Button>
            </div>
          )}
        </Dialog>
      </form>
    </FormProvider>
  )
}

export default PropertyAdForm
