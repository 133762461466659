import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {useTheme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {WhatsApp} from '@material-ui/icons'
import Footer from '@root/components/Layout/Footer/Footer'
import OptimizeMatcher from '@root/components/optimize/OptimizeMatcher'
import HeaderBlock from '@root/components/page/Home/HeaderBlock'
import {HomeIcon} from '@root/components/page/Home/HomeIcon'
import {useStyles} from '@root/components/page/Home/styles'
import WhatsAppHomeButton from '@root/components/page/Home/WhatsAppHomeButton'
import analyticsLogger from '@root/firebase/analytics.utils'
import useOptimize from '@root/hooks/useOptimize'
import {isSafari} from '@root/lib/browserDetector'
import createAlternativeEventName from '@root/lib/createAlternativeEventName'
import {whatsAppLinkGenerator} from '@root/lib/simplePhoneFormatter'
import menuSections from '@root/lib/view/menu/menuSections'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {MOBEN_WHATSAPP} from '@root/utils/constants'
import routes from '@root/utils/routes'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import YouTube from 'react-youtube'

const headerBlocks = menuSections
const recursos = [
  'Grátis até a entrega das chaves para o inquilino',
  'Monte e veicule o seu anúncio gratuitamente, nos principais portais',
  // 'Análise de crédito de até 10 CPFs',
  'Análise de crédito dos interessados',
  'Contrato atualizado customizável',
  'Assinatura digital',
  'Envio de boletos mensais',
  'Alerta de obrigações contratuais',
  'Cobrança amigável em caso de atraso',
  'Painel de controle detalhado',
  'Lembretes de renovação e reajustes anuais do aluguel',
  'Armazenamento de documentos e histórico da locação',
  'Acesso à outros serviços e vantagens',
]

const SectionSideContent = ({src, alt}) => {
  const classes = useStyles()

  return (
    <div className={classes.imageContainer}>
      <img src={src} alt={alt} />
    </div>
  )
}

let isEven = false
let isOdd = false
function isEvenFunc(num) {
  return num % 2 === 0
}
export function Home() {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'))
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))
  const {variantMatcher} = useOptimize()

  const optsFixed = {
    width: '100%',
    cc_load_policy: 1,
    cc_lang_pref: 'pt',
  }

  const headerBlocksSize = headerBlocks.length
  const isHeaderBlocksEven = isEvenFunc(headerBlocksSize)

  const logEvent = (type, params) => analyticsLogger.logEvent('home_comofunciona_video_' + type, params)

  const logPlay = () => logEvent('play')
  const logPause = ({target}) => logEvent('pause', {played: target.getCurrentTime()})
  const logEnd = () => logEvent('end')

  const handleExperimente = (ev) => {
    const buttonLabel = ev.target.textContent
    analyticsLogger.logEvent(createAlternativeEventName(buttonLabel), {label: buttonLabel, location: 'experimente'})
    history.push(routes.REGISTER_SIGNUP)
  }

  const handleCadastrar = (ev) => {
    const buttonLabel = ev.target.textContent
    analyticsLogger.logEvent(createAlternativeEventName(buttonLabel), {label: buttonLabel, location: 'section'})
    history.push(routes.REGISTER_SIGNUP)
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction(undefined))

    const hashSection = window.location.hash

    if (hashSection) {
      if (isSafari()) {
        const id = hashSection.replace('#', '')

        setTimeout(() => {
          const element = document.getElementById(id)
          element.scrollIntoView()
        }, 600)
      } else {
        window.location.hash = ''
        window.location.hash = hashSection
      }
    }
  }, [])

  const vejaComoEFacilIconStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    border: 'none',
    borderRadius: '50%',
    minWidth: '74px',
    height: '74px',
    color: '#831CCD',
  }

  const mobenEContratoIconStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    border: 'none',
    borderRadius: '50%',
    minWidth: '111px',
    height: '111px',
    color: '#2D7FFC',
  }

  const mobenELocacaoIconStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderRadius: '50%',
    minWidth: '111px',
    height: '111px',
    color: '#2D7FFC',
  }

  return (
    <div className={classes.root}>
      <WhatsAppHomeButton />

      <section className={classes.heroSection}>
        <div>
          <div className="heroH1Wrapper">
            {isExtraSmallViewPort && <div className="mobileHeroPhoto" />}
            {/* <Typography variant="h1">
              Organize o seu aluguel em
              {isSmallViewPort ? ' ' : <br />}
              um único lugar com a Moben
            </Typography> */}
            <Typography variant="h1">
              Administre você mesmo seu
              {isSmallViewPort ? ' ' : <br />}
              imóvel alugado em um só lugar
              <OptimizeMatcher alias="optimizedot">
                <OptimizeMatcher.Option variant="1">.</OptimizeMatcher.Option>
              </OptimizeMatcher>
            </Typography>
          </div>

          <Grid container className={classes.heroCardsContainer}>
            {headerBlocks.map((block, key) => (
              <Grid key={key} item className={`${classes.heroCard} header-block`} id={`header-block-${block.id}`}>
                <HeaderBlock {...block.header} linkTo={block.id} icon={block.icon} />
              </Grid>
            ))}
          </Grid>
        </div>
      </section>

      <section className={classes.oQueEMobenSection}>
        <div className={classes.oQueEMobenSectionLeftContainer}>
          <Typography variant="h3" color="primary">
            O que é a Moben?
          </Typography>

          <Typography variant="h4">
            Moben é a plataforma para você administrar diretamente com o inquilino o seu imovel alugado.
            <br />
            <br />
            Aqui você encontra tecnologia e conhecimento para realizar a análise de crédito, contrato sob medida,
            controle de pagamentos, anúncios e muito mais em um único painel de controle.
          </Typography>

          <Button id="free-trial" variant="contained" onClick={handleExperimente} className={classes.experimenteButton}>
            Venha fazer parte
          </Button>
        </div>

        <div className={classes.oQueEMobenSectionImageContainer}>
          <img src={'/assets/new/celular_1@2x.png'} alt="Celular" />
        </div>
      </section>

      <section className={classes.vejaComoEFacilSection}>
        <div>
          <Typography variant="h2" color="primary">
            Veja como é fácil
          </Typography>

          <div className="videoContainer">
            <YouTube videoId="aPvNeswxHcs" opts={optsFixed} onPlay={logPlay} onPause={logPause} onError={logEnd} />
          </div>

          <div className={classes.vejaComoEFacilItensContainer}>
            <div className={classes.vejaComoEFacilItem}>
              <HomeIcon
                src={'/assets/new/find.svg'}
                alt="Contrato personalizado"
                imgWidth="55px"
                styles={vejaComoEFacilIconStyles}
              />
              <div>
                <Typography variant="h3" color="primary">
                  Escolha os melhores inquilinos
                </Typography>
                <Typography variant="body1">
                  Realize a análise de crédito de todos os interessados em seu imóvel, inclusive fiadores. É muito
                  simples: o próprio inquilino cadastra as informações e a análise é realizada online.
                </Typography>
              </div>
            </div>
            <div className={classes.vejaComoEFacilItem}>
              <HomeIcon
                src={'/assets/new/real.svg'}
                alt="Contrato personalizado"
                imgWidth="55px"
                styles={vejaComoEFacilIconStyles}
              />
              <div>
                <Typography variant="h3" color="primary">
                  Crie um contrato personalizado
                </Typography>
                <Typography variant="body1">
                  Utilize um contrato criado por advogados especializados e configure conforme sua necessidade. Basta
                  preencher todas as opções e o contrato ficará disponível para assinatura.
                </Typography>
              </div>
            </div>
            <div className={classes.vejaComoEFacilItem}>
              <HomeIcon
                src={'/assets/new/agreement_2.svg'}
                alt="Contrato personalizado"
                imgWidth="55px"
                styles={vejaComoEFacilIconStyles}
              />
              <div>
                <Typography variant="h3" color="primary">
                  Automatize a cobrança do aluguel
                </Typography>
                <Typography variant="body1">
                  Seja informado sobre pagamentos realizados, atrasos e correções. Boletos e alertas de vencimento
                  enviados direto para o inquilino.
                </Typography>
              </div>
            </div>
            <div className={classes.vejaComoEFacilItem}>
              <HomeIcon
                src={'/assets/new/agreement_3.svg'}
                alt="Contrato personalizado"
                imgWidth="55px"
                styles={vejaComoEFacilIconStyles}
              />
              <div>
                <Typography variant="h3" color="primary">
                  Mantenha o seu aluguel organizado
                </Typography>
                <Typography variant="body1">
                  Visualize todas as informações relacionadas ao aluguel em um Painel de Controle exclusivo. Pagamentos
                  recebidos, contrato, vistoria, datas de reajustes e muito mais.
                </Typography>
              </div>
            </div>
          </div>

          <div className={classes.vejaComoEFacilButtonContainer}>
            <Button
              id="start-now"
              variant="contained"
              onClick={handleCadastrar}
              color="primary"
              className={classes.comeceAgoraButton}>
              Comece agora!
            </Button>
          </div>
        </div>
      </section>

      {headerBlocks.map((block, index) => {
        isEven = isEvenFunc(index) // par
        isOdd = !isEven // impar

        const isLastElement = index === headerBlocksSize - 1
        const useEvenSection = isEven || (isHeaderBlocksEven && isLastElement)
        const sectionClass = useEvenSection ? classes.mobenEvenSection : classes.mobenOddSection
        const contentClass = isEven ? classes.mobenEvenContent : classes.mobenOddContent
        return (
          <section key={index} className={`${sectionClass} section-feature`} id={block.id}>
            <div>
              {isEven && <div className="mobenEContratoBg" />}
              {useEvenSection && isLastElement && <div className="mobenEContratoBgFake" />}

              <div className={contentClass}>
                {isOdd && <SectionSideContent src={block.section.image} alt={block.section.title} />}
                <div className={classes.contentContainer}>
                  <Typography variant="h2">{block.section.title}</Typography>
                  <div>
                    <HomeIcon
                      icon={block.icon}
                      alt={block.section.title}
                      imgWidth="89px"
                      styles={mobenEContratoIconStyles}
                    />
                    <div>
                      <ul>
                        {block.section.features.map((feat, key) => (
                          <li key={key}>
                            <Typography variant="body1">{feat}</Typography>
                          </li>
                        ))}
                      </ul>
                      <Button
                        variant="contained"
                        onClick={handleCadastrar}
                        className={`${classes.iniciarButton} button-cta`}
                        id={`cta-${block.id}`}>
                        {block.section.button.label}
                      </Button>
                    </div>
                  </div>
                </div>
                {isEven && <SectionSideContent src={block.section.image} alt={block.section.title} />}
              </div>
            </div>
          </section>
        )
      })}

      <section className={classes.quantoCustaSection} id="valores">
        <div>
          <div className="mobenEContratoBg" />
          <div className={classes.quantoCustaContentContainer}>
            <div className="quantoCustaOverlay" />
            <div className={classes.quantoCustaLeftContainer}>
              <Typography variant="h3">Preço Fixo</Typography>
              <Typography variant="h4">
                Trabalhamos com um preço fixo por imóvel, independente do valor do seu aluguel.
              </Typography>

              <div className="priceContainer">
                <Typography variant="h3">APENAS</Typography>
                <Typography variant="h2">R$ 49,90</Typography>
                <Typography variant="h3">por mês após a entrega das chaves para o inquilino</Typography>
              </div>

              <Button variant="contained" onClick={handleExperimente} className={classes.experimenteButton}>
                Quero meu imóvel na Moben
              </Button>
            </div>

            <div className={classes.quantoCustaRightContainer}>
              <Typography variant="h3">Recursos</Typography>

              {recursos.map((recurso) => (
                <Typography key={recurso} variant="body1">
                  <img src={'/assets/new/check_icon@2x.png'} alt="Check" /> {recurso}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={classes.quemSomosSection} id="quemsomos">
        <div>
          <Typography variant="h2">Quem faz a Moben</Typography>
          <div className={classes.quemSomesMainContainer}>
            <div className={classes.quemSomosText}>
              <Typography variant="h5">
                Proprietários que se aventuram no mundo da locação sem apoio especializado têm muito trabalho e
                inseguranças administrando o imóvel alugado.
                <br />
                <br />
                Nascemos como a plataforma que vai empoderar proprietários com muita tecnologia e conhecimento para que
                a tarefa de alugar o imóvel sozinho seja mais simples e prática.
                <br />
                <br />
                E por trás de toda esta tecnologia existe um time motivado a resolver a complexidade da locação. Pessoas
                com históricos diferentes e complementares que estão dedicadas a fazer a vida do proprietário livre de
                stress.
                <br />
                <br />
                Prazer em conhecê-lo, somos a Moben!
              </Typography>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}
