import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '65%',
    margin: '110px 0 auto',
    display: 'flex',
    [theme.breakpoints.down(1000)]: {
      width: '90%',
    },
    [theme.breakpoints.down(740)]: {
      width: '90%',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '14px',
    },
  },
  imgDiv: {
    width: '280px',
    [theme.breakpoints.down(740)]: {
      margin: '0 auto',
    },
  },
  contentDiv: {
    boxSizing: 'border-box',
    padding: '0 10px',
    marginLeft: '60px',
    marginTop: '10px',
    width: '500px',
    [theme.breakpoints.down(740)]: {
      margin: '20px auto 0',
    },
  },
  contentDivAlignRight: {
    boxSizing: 'border-box',
    padding: '0 10px',
    marginLeft: '10px',
    marginRight: '60px',
    marginTop: '10px',
    width: '500px',
    [theme.breakpoints.down(740)]: {
      margin: '20px auto 0',
    },
  },
  title: {
    color: '#060606',
    marginBottom: '10px',
    fontWeight: '600',
    [theme.breakpoints.down(740)]: {
      textAlign: 'center',
    },
  },
  texts: {
    color: '#030303',
    marginBottom: '10px',
    fontSize: '1.1rem',
  },
  titleAlignRight: {
    color: '#060606',
    marginBottom: '10px',
    fontWeight: '600',
    textAlign: 'right',
    [theme.breakpoints.down(740)]: {
      textAlign: 'center',
    },
  },
  textsAlignRight: {
    color: '#030303',
    marginBottom: '10px',
    textAlign: 'right',
    fontSize: '1.1rem',
  },
}))

export default useStyles
