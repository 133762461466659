import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class BankRepository extends BaseHttpRepository {
  async accounts() {
    return this._api.get('bank/account/list', {user_id: this._retrieveUserId()})
  }

  async accountsForProperty(propertyId) {
    return this._api.get('bank/account/list', {user_id: this._retrieveUserId(), property_id: propertyId})
  }

  async remainingAccountsForProperty(propertyId) {
    const all = await this.accounts()

    if (!all) {
      return []
    }

    const remaining = all.filter((acc) => acc.properties.indexOf(propertyId) === -1)
    return remaining
  }

  /**
   * @typedef {Object} AccountParams
   * @property {string} [account_id] - Caso seja uma nova conta pode ser uma string vazia
   * @property {string} [property_id] - Cria e ja associa a conta ao imovel
   * @property {Object} bank
   * @property {number|string} bank.code
   * @property {string} bank.agency
   * @property {string} bank.agency_digit
   * @property {string} bank.account
   * @property {string} bank.account_digit
   * @property {string} bank.legal_name
   * @property {string} bank.type
   * @property {string} bank.document_number
   */
  /**
   * @param {AccountParams} accountParams
   * @return {Promise<*>}
   */
  async add(accountParams) {
    const bank = accountParams.bank
    const account_id = accountParams.account_id ?? ''
    const property_id = accountParams.property_id ?? ''

    bank.document_number = bank.document_number.trim() //fix da GAMBI_NINJA
    if (!bank.account_digit) {
      bank.account_digit = '0'
    }
    if (!bank.agency_digit) {
      bank.agency_digit = '0'
    }

    return this._api.post('bank/account/add', {
      user_id: this._retrieveUserId(),
      property_id,
      account_id,
      bank,
    })
  }

  async addPropertyToAccount(accountId, propertyId, splitAmount = 0) {
    return this._api.post(`bank/account/${accountId}/${propertyId}/add`, {
      user_id: this._retrieveUserId(),
      split_amount: splitAmount,
    })
  }

  async removePropertyFromAccount(accountId, propertyId) {
    return this._api.post(`bank/account/${accountId}/${propertyId}/remove`, {
      user_id: this._retrieveUserId(),
    })
  }

  /**
   * @param {string} propertyId
   * @param {Array<{id: string, amount:number}>} splitList
   * @return {Promise<*>}
   */
  async updateSplits(propertyId, splitList = []) {
    return this._api.post(`bank/split/update/${propertyId}`, {user_id: this._retrieveUserId(), split_list: splitList})
  }

  /**
   * @param {string} userId
   * @return {Promise<*>}
   */
  async statement(userId) {
    return this._api.get('bank/statement', {user_id: userId})
  }
}

export const bankRepoInstance = new BankRepository()

export const useBankRepoAdd = hookRunner(bankRepoInstance, 'add')
export const useBankRepoListAccounts = hookRunner(bankRepoInstance, 'accounts', [])
export const useBankRepoListAccountsProperty = hookRunner(bankRepoInstance, 'accountsForProperty', [])
export const useBankRepoListRemaining = hookRunner(bankRepoInstance, 'remainingAccountsForProperty', [])
export const useBankRepoStatement = hookRunner(bankRepoInstance, 'statement', [])
