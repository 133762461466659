import {makeStyles} from '@material-ui/core/styles'

const splitTotalBase = {}
export const useStyles = makeStyles((theme) => ({
  'root': {
    '& .bold': {
      fontWeight: 600,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '& legend': {
      textAlign: 'center',
      marginBottom: 10,
    },
  },
  'twoFieldContainer': {
    'display': 'grid',
    'gridTemplateColumns': '1fr 1fr',
    'gap': theme.margin[5],
    '& > div': {
      flex: 1,
    },
  },
  'threeFieldContainer': {
    'display': 'grid',
    'gridTemplateColumns': '1fr 1fr 1fr',
    'gap': theme.margin[5],
    '& > div': {
      flex: 1,
    },
  },
  'proximoItemButtonWrapper': {
    width: '100%',
    marginTop: theme.margin[3],
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'validity': {
    '& > p:not(:first-child)': {
      marginTop: theme.margin[4],
    },
  },
  'validityNumberOfMonthsBoxWrapper': {
    'height': '100%',
    'display': 'flex',
    'alignItems': 'flex-end',
    '& > div': {
      height: '80%',
      marginBottom: '6px',
    },
  },
  'validityDatesContainer': {
    'display': 'flex',
    'flexDirection': 'column',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  'rentdueDateContainer': {
    marginTop: 40,
  },
  'rentdueDate': {
    'marginTop': 20,
    'display': 'flex',
    'maxWidth': 390,
    'flexDirection': 'column',
    'margin': '0 auto',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  'rentDueCalendarContainer': {
    'position': 'relative',
    'maxWidth': 260,
    'margin': '20px auto',
    '& > img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 214,
      transform: 'translateX(-10px)',
    },
  },
  'validityArrow': {
    'position': 'absolute',
    'top': 26,
    'left': -12,
    'width': 105,
    '& > img': {width: '100%'},
    'animation': '$validityArrow 2s cubic-bezier(0.05, 0.65, 0.58, 1) infinite',
    [theme.breakpoints.down('sm')]: {
      top: 18,
      left: -13,
      width: 90,
    },
  },
  '@keyframes validityArrow': {
    '0%': {
      transform: 'skew(0, 0)',
    },
    '50%': {
      transform: 'skew(0, 10deg)',
    },
    '100%': {
      transform: 'skew(0, 0deg)',
    },
  },
  'vencimentoArrow': {
    'position': 'absolute',
    'bottom': -10,
    'right': -56,
    'width': 90,
    '& > img': {width: '100%'},
    'animation': '$vencimentoArrow 2s cubic-bezier(0.05, 0.65, 0.58, 1) infinite',
    [theme.breakpoints.down('sm')]: {
      width: 76,
      right: -46,
    },
  },
  '@keyframes vencimentoArrow': {
    '0%': {
      transform: 'skew(0, 0)',
    },
    '50%': {
      transform: 'skew(0, 10deg)',
    },
    '100%': {
      transform: 'skew(0, 0deg)',
    },
  },
  'validityArrowText': {
    fontWeight: 700,
    color: theme.extraColors.YELLOW[500],
    position: 'absolute',
    top: 42,
    left: -68,
    [theme.breakpoints.down('sm')]: {
      top: 28,
      left: -63,
    },
  },
  'vencimentoArrowText': {
    fontWeight: 700,
    color: theme.extraColors.YELLOW[500],
    position: 'absolute',
    bottom: 36,
    right: -92,
    [theme.breakpoints.down('sm')]: {
      right: -83,
      bottom: 30,
    },
  },
  'validityNumberOfMonthsContainer': {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& > *': {
      flex: 1,
    },
    '& .arrow': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      '& > svg': {
        'cursor': 'pointer',
        'backgroundColor': theme.palette.primary.main,
        'borderRadius': '50%',
        'fill': '#FFF',
        'transition': 'filter 0.2s',
        '&:hover': {
          filter: 'brightness(0.9)',
        },
      },
    },
    '& .months': {
      'display': 'flex',
      'fontFamily': theme.typography.fontFamily,
      'color': theme.palette.secondary.main,
      'flexDirection': 'column',
      'alignItems': 'center',
      '& > :first-child': {
        fontSize: '66px',
        lineHeight: '66px',
      },
    },
  },
  'rentFees': {
    '& > section > p': {
      marginTop: theme.margin[2],
      marginBottom: theme.margin[4],
    },
    '& .MuiFormControl-root .MuiFormGroup-root > label:not(:last-child)': {
      marginBottom: theme.margin[2],
    },
    '& > hr': {
      marginBottom: theme.margin[3],
    },
    '& .totalCaption': {
      marginTop: theme.margin[2],
      marginBottom: theme.margin[5],
    },
  },
  'rentFeesAmountsContainer': {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& > div': {
      transform: 'translate(0, 20%)',
    },
  },
  'rentFeesCoverageContainer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  'rentFeesTotalContainer': {
    'width': '60%',
    'margin': '0 auto',
    '& .totalValueWrapper': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      '& > h6': {
        marginBottom: theme.margin[2],
        fontWeight: 600,
      },
    },
  },
  'rentFeesCoverageText': {
    marginLeft: 16,
    marginTop: -13,
  },
  'penalties': {
    '& > div > p': {
      'marginTop': 20,
      '&:last-child': {
        marginTop: 5,
      },
    },
    '& > p': {
      maxWidth: '45ch',
      margin: '20px auto 0',
    },
    '& .multaEncerramentoItemsWrapper': {
      position: 'relative',
    },
    '& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal': {
      'margin': '0 !important',
      'width': '155px',
      'position': 'absolute',
      'left': 30,
      'bottom': 0,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
      },
    },
  },
  'guarantee': {
    '& > p': {
      marginBottom: theme.margin[3],
    },
    // '& > :last-child': {
    //   marginTop: theme.margin[5],
    // },
    '& .fiador, .seguroFianca': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      '& > p': {
        marginBottom: theme.margin[2],
      },
      '& textarea, .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
        background: '#FFF',
      },
    },
    '& .dateWrapper': {
      'margin': '0 auto',
      'width': '140px',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
      },
    },
    '& .dateWrapperTitle': {
      marginBottom: '-10px !important',
    },
  },
  'witnesses': {
    '& section': {
      'marginTop': theme.margin[4],
      '& > p': {
        marginBottom: theme.margin[2],
      },
    },
  },
  'extraInformation': {
    '& > p': {
      marginBottom: theme.margin[3],
    },
    '& section:not(:first-child)': {
      marginTop: theme.margin[3],
    },
  },
  'survey': {
    '& > p': {
      marginBottom: theme.margin[3],
    },
  },
  'contractCreated': {
    'position': 'relative',
    // 'height': '100vh',
    // 'backgroundColor': theme.extraColors.GRAY[100],
    'width': '100%',
    '& header': {
      display: 'flow-root',
      backgroundColor: theme.extraColors.WHITE,
      paddingBottom: theme.margin[4],
    },
    '& header > p': {
      marginBottom: theme.margin[3],
    },
    '& .buttonsContainer': {
      display: 'flex',
      justifyContent: 'space-around',
      width: '90%',
      margin: '0 auto',
    },
    '& .notificationsSection': {
      'padding': theme.margin[3],
      '& :first-child .notificationDate': {
        paddingTop: 0,
        borderLeft: 'none',
      },
    },
    '& .notificationDate': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      marginLeft: theme.margin[3],

      borderLeft: `2px solid ${theme.extraColors.GRAY[500]}`,
      paddingLeft: theme.margin[1],
      paddingTop: theme.margin[2],
    },
    '& .notification': {
      'width': '100%',
      'display': 'flex',
      'alignItems': 'center',
      '& > svg': {
        fill: theme.extraColors.GRAY[500],
        transform: 'rotate(-45deg) translate(10%)',
      },
      '& > p': {margin: `0 ${theme.margin[1]} 0 0`},
      '& > *': {marginRight: theme.margin[1]},
    },
    '& .changeRequest': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      '& > p': {margin: 0},
      '& > p:first-child': {marginRight: theme.margin[1]},
      '& .buttonsContainer': {
        marginTop: theme.margin[1],
        display: 'flex',
        width: '100%',
        height: '29px',
      },
      '& .changePending': {
        color: theme.extraColors.BLACK,
        display: 'flex',
        alignItems: 'center',
      },
      '& .changeNotAccepted': {
        color: theme.extraColors.RED,
        display: 'flex',
        alignItems: 'center',
      },
      '& .changeAccepted': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  'anexarArquivoWrapper': {
    position: 'relative',
  },
  'anexarArquivoFile': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}))
