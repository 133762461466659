import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import {ReactComponent as ArrowIcon} from '@root/assets/icons/arrow_left_icon.svg'
import {darken} from 'polished'
import PropTypes from 'prop-types'
import React, {useCallback} from 'react'
import {useHistory} from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    'boxSizing': 'border-box',
    'position': 'relative',
    'display': 'flex',
    'width': '100%',
    'min-height': '43px',
    'padding': '5px 10px',
    'justifyContent': 'center',
    'alignItems': 'center',
    'backgroundColor': (stylesProps) =>
      stylesProps.isWarningNav ? theme.extraColors.YELLOW[100] : theme.extraColors.GRAY[200],
    'borderRadius': (stylesProps) => (stylesProps.br ? stylesProps.br : ''),
    '& button#nav-left-icon': {
      position: 'absolute',
      left: 0,
    },
    'transition': 'background-color 0.2s',
    '&:hover': {
      backgroundColor: (stylesProps) => {
        let color = stylesProps.isWarningNav ? theme.extraColors.YELLOW[100] : theme.extraColors.GRAY[200]
        return stylesProps.withHover ? darken(0.1, color) : color
      },
    },
  },
  navIcon: {
    color: theme.palette.text.primary,
  },
  disabled: {
    color: `${theme.yellowButton.iconColor} !important`,
  },
  navText: {
    fontSize: '0.9375rem',
    fontWeight: 600,
  },
}))

export function Navbar({type, children, onBackClick, displayBackButton, styles, br, withHover, ...rest}) {
  const history = useHistory()
  const isWarningNav = type === 'warning'

  const stylesProps = {
    isWarningNav,
    br,
    withHover,
  }

  const classes = useStyles(stylesProps)

  const handleBackClick = useCallback(
    (e) => {
      if (onBackClick) {
        onBackClick(e)
      } else {
        history.goBack()
      }
    },
    [onBackClick],
  )

  if (type === 'warning') {
    return (
      <nav className={`${classes.root} ${rest.onClick && 'clickable'}`} style={{...styles}} {...rest}>
        <IconButton disabled classes={{disabled: classes.disabled}} id="nav-left-icon">
          <WarningIcon />
        </IconButton>

        <Typography variant="caption">{children}</Typography>
      </nav>
    )
  }

  return (
    <nav className={classes.root} style={{...styles}}>
      {displayBackButton && (
        <IconButton className={classes.navIcon} onClick={handleBackClick} id="nav-left-icon">
          <ArrowIcon />
        </IconButton>
      )}

      <Typography variant="caption" color="secondary" className={classes.navText}>
        {children}
      </Typography>
    </nav>
  )
}

Navbar.defaultProps = {
  displayBackButton: true,
}

Navbar.propTypes = {
  type: PropTypes.oneOf(['nav', 'warning']),
  children: PropTypes.any,
  onClick: PropTypes.func,
  displayBackButton: PropTypes.bool,
  styles: PropTypes.object,
  br: PropTypes.string,
  withHover: PropTypes.bool,
}
