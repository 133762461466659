import {Checkbox} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, {useEffect, useState} from 'react'

// const checkboxRender = (helpers, controllerProps, formItemProps) => {
// const CheckboxRender = (helpers) => {
const CheckboxSingleRender = (controllerProps, helpers, formItemProps) => {
  const {forwardOnChange} = helpers
  const option = formItemProps.options[0]
  const name = formItemProps.name
  const [breakPoints, setBreakPoints] = useState({xs: 12, sm: 12, md: 12})

  // const {forwardOnChange} = helpers
  const [selected, setSelected] = useState(false)

  const handleChange = (evt) => {
    const isChecked = evt.target.checked
    if (controllerProps.onChange) {
      forwardOnChange({
        ...evt,
        target: {
          ...evt.target,
          value: isChecked,
          checked: isChecked,
        },
      })
    }

    setSelected(isChecked)
  }

  useEffect(() => {
    setSelected(controllerProps.value ?? false)
  }, [controllerProps.value])

  return (
    <Grid container>
      <Grid item xs={12} sm={breakPoints.sm} md={breakPoints.md}>
        <label>
          <Checkbox name={name} value={true} checked={!!selected} onChange={handleChange} />
          {option.label}
        </label>
      </Grid>
    </Grid>
  )
}

export default CheckboxSingleRender
