import {Badge, Chip, Collapse, MenuItem, Paper, TablePagination, TextField} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SearchIcon from '@material-ui/icons/Search'
import useStyles from '@root/components/App/Admin/styles'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import Loader from '@root/components/Loader/Loader'
import {validateRequired} from '@root/lib/simpleValidators'
import mailViewerModel from '@root/model/mailViewerModel'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

const EmailsList = () => {
  const classes = useStyles()
  const {control, errors, handleSubmit, reset, setValue} = useForm()

  const [isLoading, setLoading] = useState(false)
  const [filters, setFilters] = useState({page: 1})
  const [searchResults, setResults] = useState([])
  const searchResultsLen = searchResults.length

  const setFilterProp = (prop, value) => setFilters((curFilters) => ({...curFilters, [prop]: value}))
  // const setMultipleFilterProps = (props) => setFilters((curFilters) => ({...curFilters, ...props}))

  const handleChangePage = (evt, page) => {
    setFilterProp('page', page)
  }

  const handleSearch = (form) => {
    if (form.filter_field === 'recipient') {
      if (!form.filter_value.includes('@')) {
        form.filter_value = form.filter_value.replace(/\D/g, '')
      }
    }
    setFilters({
      page: 1,
      [form.filter_field]: form.filter_value,
    })
  }

  const resetSearch = () => {
    reset({
      filter_field: '',
      filter_value: '',
    })
    setFilters({
      page: 1,
    })
  }

  const loadMailList = async () => {
    if (isLoading) {
      return
    }

    setLoading(true)
    const results = await mailViewerModel.fetchMailList(filters)
    setResults(results)
    setLoading(false)
  }

  useEffect(() => {
    loadMailList()
  }, [filters])

  return (
    <div className={classes.userListRoot}>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} sm={4} md={3} lg={1}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer={true}
            type="select"
            name="filter_field"
            label="Filtrar por"
            rules={{validate: validateRequired}}
            options={[
              optionFactory('Tipo', 'type'),
              optionFactory('Destinatário', 'recipient'),
              optionFactory('ID Cliente', 'user_uid'),
              optionFactory('Código de email', 'mail_alias'),
              optionFactory('ID de pagamento', 'payment_id'),
            ]}
          />
        </Grid>

        <Grid item xs={8} sm={6} md={6} lg={3}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer={true}
            label="Valor"
            type="textfield"
            name="filter_value"
            rules={{validate: validateRequired}}
          />
        </Grid>

        <Grid item xs={4} sm={2} md={3} lg={1}>
          <IconButton onClick={handleSubmit(handleSearch)}>
            <SearchIcon />
          </IconButton>

          {Object.keys(filters).length > 1 && (
            <IconButton onClick={resetSearch}>
              <CancelOutlinedIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <div style={{position: 'relative'}}>
        <SimplePageNavigation count={searchResultsLen} page={filters.page} onChangePage={handleChangePage} />
        <TableContainer component={Paper} className={classes.card}>
          <Table size="small" aria-label="a dense table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Destinatário</TableCell>
                <TableCell>Assunto</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Código do email</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((row) => (
                <MainEntry key={row.id} data={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SimplePageNavigation count={searchResultsLen} page={filters.page} onChangePage={handleChangePage} />

        <Loader loading={isLoading} absolute={true} />
      </div>
    </div>
  )
}

const SimplePageNavigation = ({count, page, onChangePage}) => {
  const isNextDisabled = count === 0
  const isPrevDisabled = page <= 1

  const handleNextPage = (e) => {
    callPageChange(e, page + 1)
  }
  const handlePrevPage = (e) => {
    callPageChange(e, page - 1)
  }

  const callPageChange = (evt, newPage) => {
    if (!onChangePage) {
      return
    }
    onChangePage(evt, newPage)
  }

  return (
    <div style={{textAlign: 'right'}}>
      <IconButton onClick={handlePrevPage} disabled={isPrevDisabled} title="Anterior">
        <KeyboardArrowLeftIcon />
      </IconButton>

      <IconButton onClick={handleNextPage} disabled={isNextDisabled} title="Próximo">
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  )
}

const MainEntry = ({data}) => {
  const [isEventsOpen, setEventState] = useState(false)
  const toggleEventVisibility = () => setEventState((open) => !open)
  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton size="small" onClick={toggleEventVisibility}>
            {isEventsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.recipient}</TableCell>
        <TableCell>{data.subject}</TableCell>
        <TableCell>{data.created_at}</TableCell>
        <TableCell>{data.mail_alias}</TableCell>
        <TableCell>
          <Chip
            label={data.status_translated}
            icon={<FiberManualRecordIcon style={{color: data.status_color}} />}
            variant="outlined"
            size="small"
          />
        </TableCell>
      </TableRow>
      <EventsList events={data.events} isOpen={isEventsOpen} />
    </>
  )
}

const EventsList = ({isOpen, events}) => {
  return (
    <TableRow>
      <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              Eventos
            </Typography>
            <Table size="small" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => (
                  <TableRow key={event.notification_id} hover>
                    <TableCell>{event.type_translated}</TableCell>
                    <TableCell>{event.created_at}</TableCell>
                    <TableCell>{event.data.link}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default EmailsList
