import {propertyRepoInstance} from '@root/application/service/repository/property/PropertyRepository'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FilePickerMultiple from '@root/components/FilePickerMultiple'
import Text from '@root/components/Layout/Typography/Text'
import surveyModel from '@root/model/surveyModel'
import React, {useState} from 'react'

const DocDisplayList = ({files, label}) => {
  if (!files) {
    return null
  }

  return (
    <>
      <Text>{label}</Text>
      <br />
      <ul>
        {files.map((file, idx) => (
          <li key={idx} style={{margin: '4px 0'}}>
            <DocDisplay file={file} label={file.name_original} />
          </li>
        ))}
      </ul>
    </>
  )
}

const DocDisplay = ({file, label, lb}) => {
  return (
    <>
      {file && (
        <>
          <a target="_blank" rel="noreferrer" href={file.download_url}>
            {label}
          </a>
          {lb ?? null}
        </>
      )}
    </>
  )
}
const BlockFiles = ({property, surveyState, surveyImageState}) => {
  const propertyId = property.uid
  const {contract, contract_document, deed, power_attorney} = property
  const {contract_file, contract_signed} = contract
  const {insurance_fire, guarantee} = contract.documents ?? {}

  const [survey, setSurvey] = surveyState
  const [surveyImageList, setSurveyImageList] = surveyState
  const [receipts, setReceipts] = useState([])

  const hasReceipts = receipts.length > 0

  const surveyOnChange = async (uploadedFiles) => {
    const images = [...surveyImageList, ...uploadedFiles]

    setSurveyImageList(images)

    if (survey) {
      setSurvey({
        ...survey,
        entering: {
          ...(survey.entering ?? {}),
          images,
        },
      })
    }
    await surveyModel.createOrUpdateSurvey(
      {
        surveyType: 'entering',
        images,
      },
      property?.uid,
    )
  }

  const surveyOnDelete = async (url) => {
    const filteredSurveyImagesArr = survey?.entering?.images.filter((doc) => doc.download_url !== url)
    const updatedSurvey = await surveyModel.createOrUpdateSurvey(
      {
        surveyType: 'entering',
        images: filteredSurveyImagesArr,
      },
      property?.uid,
    )

    setSurvey(updatedSurvey)
    setSurveyImageList(filteredSurveyImagesArr)
  }

  const loadReceipts = async () => {
    const result = await propertyRepoInstance.receipts(propertyId)
    setReceipts(result)
  }

  return (
    <>
      <DocDisplay file={contract_document} label="Contrato que ja veio do PP" lb={<br />} />
      <DocDisplay file={contract_file} label="Contrato gerado (moben)" lb={<br />} />
      <DocDisplay file={contract_signed} label="Contrato assinado (moben)" lb={<br />} />
      <DocDisplay file={deed} label="Escritura" lb={<br />} />
      <DocDisplay file={power_attorney} label="Procuração" lb={<br />} />

      <Divider />
      <DocDisplayList files={insurance_fire} label="Seguro residencial" />

      <Divider />
      <DocDisplayList files={guarantee} label="Garantia" />

      <Divider />
      <DataDisplay
        label="Vistoria"
        value={
          <FilePickerMultiple
            label="Upload Vistoria"
            uid={propertyId}
            filePrefix="survey_"
            section="property/survey"
            list={survey?.entering?.images}
            displayLinks
            onChangeFileCallback={surveyOnChange}
            onDeleteFileCallback={surveyOnDelete}
          />
        }
      />

      {!hasReceipts && <Button onClick={loadReceipts}>Exibir comprovantes</Button>}
      {hasReceipts && (
        <>
          <Divider />
          Comprovantes
          <br />
          {receipts.map((receipt) => (
            <DocDisplayList key={receipt.uid} files={receipt.files} label={receipt.description} />
          ))}
        </>
      )}
    </>
  )
}

BlockFiles.propTypes = {}
BlockFiles.defaultProps = {}

export default BlockFiles
