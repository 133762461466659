import {xpathFinder, xpathFinderParent} from '@root/components/App/Admin/ContractEditor/xpath'
import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {AdminContractManagerTypes} from '@root/redux/adminContractManager/adminContractManager.types'

const initialState = {
  updateList: [],
  hasModified: false,
  appendixes: [],
  clauses: [],
}
const reducers = {
  [AdminContractManagerTypes.SET_INITIAL_DATA]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.appendixes = payload.appendixes
      draft.clauses = payload.clauses
    })
  },
  [AdminContractManagerTypes.SET_ADD]: (state, {payload}) => {
    return produce(state, (draft) => {
      const {xpath, action} = payload

      const item = xpathFinder(xpath, draft)
      item.has_sub = true
      item.items.push({
        content: 'NÃO ESQUEÇA DE EDITAR',
        enumeration_type: item.enumeration_type ?? 'numeric',
        has_sub: false,
        items: [],
      })

      draft.hasModified = true
      draft.updateList.push({xpath, action})
    })
  },
  [AdminContractManagerTypes.SET_DELETED]: (state, {payload}) => {
    return produce(state, (draft) => {
      const {xpath, action} = payload
      const result = xpathFinderParent(xpath, draft)

      draft.hasModified = true

      result.parent.items.splice(result.target, 1)

      draft.hasModified = true
      draft.updateList.push({xpath, action})
    })
  },

  [AdminContractManagerTypes.SET_MODIFIED]: (state, {payload}) => {
    return produce(state, (draft) => {
      const {xpath, content, action} = payload
      const item = xpathFinder(xpath, draft)
      const originalContent = item.content
      item.is_edited = true
      item.content = content

      draft.hasModified = true
      draft.updateList.push({xpath, action, prev_content: originalContent, current_content: content})
    })
  },

  [AdminContractManagerTypes.SET_ENUMERATION]: (state, {payload}) => {
    return produce(state, (draft) => {
      const {xpath, content, action} = payload
      const item = xpathFinder(xpath, draft)
      item.enumeration_type = content

      draft.hasModified = true
      draft.updateList.push({xpath, action})
    })
  },

  [AdminContractManagerTypes.CLEAR_MODIFIED]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.updateList = []
    })
  },
}

export default reducerMatch(reducers, initialState)
