import FormItem from '@root/components/FormHelper/FormItem'
import {validateFullName, validateNumber, validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useHistory} from 'react-router-dom'

/**
 * @component
 */
const CreditCardForm = () => {
  const history = useHistory()
  const {control, errors} = useFormContext()

  return (
    <>
      <FormItem
        control={control}
        errors={errors}
        type="mask"
        name="card.number"
        label="Número do cartão"
        maskInputProps={{format: '#### #### #### ####'}}
        rules={{validate: validateRequired}}
      />
      <FormItem
        control={control}
        errors={errors}
        name="card.name"
        label="Nome do titular"
        rules={{validate: validateFullName}}
      />
      <FormItem
        control={control}
        errors={errors}
        type="mask"
        name="card.expiration"
        label="Data de expiração"
        maskInputProps={{format: '##/##'}}
        rules={{validate: validateRequired}}
      />
      <FormItem
        control={control}
        errors={errors}
        name="card.cvv"
        label="Código de segurança"
        rules={{validate: validateNumber}}
      />
    </>
  )
}

export default CreditCardForm
