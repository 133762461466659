import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import Title from '@root/components/Layout/Typography/Title'
import PageMini from '@root/components/PageMini'
import actionDispatcher from '@root/lib/actionDispatcher'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setRegisterFormAction} from '@root/redux/signin/signin.actions'
import routes from '@root/utils/routes'
import React, {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'

const styleProviderText = {marginTop: 8}
const SignupOptions = ({isSignin, isRegister}) => {
  const location = useLocation()
  const currentPath = location.pathname
  console.log('currentPath', currentPath)
  const history = useHistory()
  const isLoggedUser = useSelector((state) => state.user.isLogged)

  const actions = actionDispatcher({
    setRegisterFormAction,
    setNavbarTitleAction,
    setGlobalLoaderAction,
  })

  const pageConfig = useMemo(() => {
    const config = {
      title: 'Entrar',
      // texto generico da tela toda
      description: [
        'Escolha a forma como você deseja acessar a sua conta.',
        'Se você já é cliente Moben agora também pode acessar usando o e-mail cadastrado anteriomente.',
      ],
      // texto especifico de cada provedor, seja sms ou email
      descriptionProvider: {
        phone: '',
        email: (
          <>
            Se você já se cadastrou usando o seu número de telefone e quer entrar utilizando seu email é só redefinir a
            sua senha <Link to={routes.SIGNUP_SIGNIN_MAIL_RECOVER}>clicando aqui.</Link>
          </>
        ),
      },
      alternatePageLink: routes.SIGNUP_REGISTER_OPTIONS,
      alternatePageText: 'Ainda não tem conta, cadastre-se agora',
    }

    if (isRegister) {
      config.title = 'Cadastrar'
      config.description = [
        'Escolha a forma como você deseja se cadastrar na Moben',
        <>
          Se você já é cliente é só entrar utilizando seu{' '}
          <Link to={routes.SIGNUP_SIGNIN_PHONE}>número de telefone</Link> ou com seu{' '}
          <Link to={routes.SIGNUP_SIGNIN_EMAIL}>e-mail e senha</Link>
        </>,
      ]
      config.alternatePageLink = routes.SIGNUP_SIGNIN_OPTIONS
      config.alternatePageText = 'Já é cadastrado, faça o login'
      config.descriptionProvider.phone = ''
      config.descriptionProvider.email = (
        <>
          Já tem cadastro mas ainda não fez login com email e senha? Basta redefinir a sua senha{' '}
          <Link to={routes.SIGNUP_SIGNIN_MAIL_RECOVER}>clicando aqui.</Link>
        </>
      )
    }

    return config
  }, [isRegister])

  const getRoute = (path) => {
    let route = ''
    if (isSignin) {
      if (path === 'email') {
        route = routes.SIGNUP_SIGNIN_EMAIL
      } else if (path === 'sms') {
        route = routes.SIGNUP_SIGNIN_PHONE
      }
    } else if (isRegister) {
      if (path === 'email') {
        route = routes.SIGNUP_REGISTER_EMAIL
      } else if (path === 'sms') {
        route = routes.SIGNUP_REGISTER_PHONE
      }
    }

    return route
  }

  const pickFormEmail = () => {
    history.push(getRoute('email'))
  }
  const pickFormPhone = () => {
    history.push(getRoute('sms'))
  }

  useEffect(() => {
    // actions.setNavbarTitleAction()
  }, [])

  useEffect(() => {
    if (isLoggedUser) {
      history.replace(routes.ROOT)
    }
  }, [isLoggedUser])

  const gridItemConfig = {
    xs: 12,
    md: 8,
  }
  return (
    <PageMini displayBackButton={false} navtitle={pageConfig.title} innerTitle={pageConfig.description}>
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid item md={5} style={{textAlign: 'center'}}>
          <Button onClick={pickFormPhone} p="12px 0" w={220} fz="1rem" fw={600}>
            TELEFONE
          </Button>
          <br />
          <Typography style={styleProviderText} variant="body2">
            {pageConfig.descriptionProvider.phone}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6">OU</Typography>
        </Grid>

        <Grid item md={8} style={{textAlign: 'center'}}>
          <Button onClick={pickFormEmail} p="12px 0" w={220} fz="1rem" fw={600}>
            E-MAIL
          </Button>
          <Typography style={styleProviderText} variant="body2">
            {pageConfig.descriptionProvider.email}
          </Typography>
        </Grid>
        <Grid item sm={6} md={8} style={{width: '100%'}}>
          <Divider dashed={true} />
        </Grid>
        <Grid item>
          <Link to={pageConfig.alternatePageLink}>{pageConfig.alternatePageText}</Link>
        </Grid>
      </Grid>
    </PageMini>
  )
}

SignupOptions.propTypes = {}

SignupOptions.defaultProps = {}
export default SignupOptions
