import {Button} from '@material-ui/core'
import React from 'react'

/** Botao de acao Secundario */
const ButtonSecondary = (props) => (
  <Button
    variant="contained"
    component="span"
    style={{
      color: '#FFFFFF',
      backgroundColor: '#707070',
      fontWeight: '600',
      textTransform: 'none',
    }}
    {...props}>
    {props.children}
  </Button>
)

export default ButtonSecondary
