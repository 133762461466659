import {Typography} from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {makeStyles} from '@material-ui/core/styles'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import {ReactComponent as BlogIcon} from '@root/assets/icons/dashboard_blog.svg'
import {ReactComponent as MeusImoveisIcon} from '@root/assets/icons/dashboard_meus_imoveis.svg'
import {ReactComponent as PainelIcon} from '@root/assets/icons/dashboard_painel.svg'
import {ReactComponent as PainelAdminIcon} from '@root/assets/icons/dashboard_painel_admin.svg'
import {profileTabsList} from '@root/components/profileTabsList'
import {signOut} from '@root/lib/signIn'
import mobenTheme from '@root/mobenTheme'
import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {useLocation} from 'react-router-dom'
import routes, {routeWithParams} from '@root/utils/routes'

import {LogoElement} from './LogoElement'

const useNewBagdeStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: 16,
    // height: 16,
    position: 'absolute',
    right: 6,
    fontSize: 10,
    color: '#FFF',
    padding: '2px 4px',
  },
}))

const NEW_BADGE_DURATION_DAYS = 40
const todayTime = Date.now()
const badgeDurationMillis = 86400 * NEW_BADGE_DURATION_DAYS * 1000

const NewFeature = ({since}) => {
  const badgeNewStyle = useNewBagdeStyle()
  const diff = todayTime - new Date(since).getTime()

  if (!since || diff > badgeDurationMillis) {
    return null
  }
  return <div className={badgeNewStyle.root}>novidade!</div>
}

export const DrawerMenuItens = ({classes, onClose}) => {
  const history = useHistory()
  const {pathname, hash} = useLocation()
  const propertyAlias = useSelector((s) => s.property?.data?.alias)
  const tenantPropertyAlias = useSelector((s) => s.tenantProperty?.data?.alias)
  const userState = useSelector((s) => s.user) ?? {}
  const user = userState?.user ?? {}
  const isLogged = userState?.isLogged
  const isAdmin = user?.user_role === 'admin'
  const isTenant = user?.is_tenant === true

  const handleClickItem = useCallback(
    (route) => () => {
      if (route === routes.SIGNOUT) {
        signOut(history)
      } else if (route === routes.BLOG) {
        onClose()
        window.open(route, '_blank')
      } else {
        onClose()
        history.push(route)
      }
    },
    [],
  )

  const materialUIIconStyle = {
    marginTop: 4,
    marginLeft: '-2px',
    fontSize: 18,
    width: 22,
    height: 22,
    color: '#2D7FFC',
  }

  return (
    <>
      <div className={classes.toolbar}>
        <LogoElement classes={classes} onClose={onClose} />
      </div>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Typography variant="body1">Sou proprietário</Typography>
        </ListItem>

        {propertyAlias && (
          <ListItem
            selected={pathname === routes.DASHBOARD_ROOT}
            button
            className={classes.listItem}
            onClick={handleClickItem(routes.DASHBOARD_ROOT)}>
            <PainelIcon />
            <ListItemText primary={`Painel - ${propertyAlias}`} />
          </ListItem>
        )}

        <ListItem
          selected={pathname === routes.DASHBOARD_PROPERTY_LIST}
          button
          className={classes.listItem}
          onClick={handleClickItem(routes.DASHBOARD_PROPERTY_LIST)}>
          <MeusImoveisIcon />
          <ListItemText primary="Meus imóveis" />
        </ListItem>

        {isTenant && (
          <>
            <ListItem className={classes.listItem}>
              <Typography variant="body1">Sou Inquilino</Typography>
            </ListItem>
            {tenantPropertyAlias && (
              <ListItem
                selected={pathname === routes.DASHBOARD_ROOT_TENANT}
                button
                className={classes.listItem}
                onClick={handleClickItem(routes.DASHBOARD_ROOT_TENANT)}>
                <PainelIcon />
                <ListItemText primary={`Painel - ${tenantPropertyAlias}`} />
              </ListItem>
            )}

            <ListItem
              selected={pathname === routes.DASHBOARD_TENANT_PROPERTY_LIST}
              button
              className={classes.listItem}
              onClick={handleClickItem(routes.DASHBOARD_TENANT_PROPERTY_LIST)}>
              <MeusImoveisIcon />
              <ListItemText primary="Meus imóveis" />
            </ListItem>
          </>
        )}

        <ListItem className={classes.listItem}>
          <Typography variant="body1">Minha Conta</Typography>
        </ListItem>

        {profileTabsList.map(
          (tab) =>
            tab.checkVisibility(user) && (
              <ListItem
                key={tab.hash}
                button
                selected={hash === `#${tab.hash}`}
                className={classes.listItem}
                onClick={handleClickItem(`${routes.MYDATA_PROFILE}#${tab.hash}`)}>
                {<tab.icon style={{color: mobenTheme.extraColors.HOME_BLUE}} width={22} height={22} />}
                <ListItemText primary={tab.title} />
                <NewFeature since={tab.available_since} />
              </ListItem>
            ),
        )}

        <ListItem className={classes.listItem}>
          <Typography variant="body1">Saiba Mais</Typography>
        </ListItem>

        <ListItem
          selected={pathname === routes.BLOG}
          button
          className={classes.listItem}
          onClick={handleClickItem(routes.BLOG)}>
          <BlogIcon />
          <ListItemText primary="Blog" />
        </ListItem>

        <ListItem
          selected={pathname === routes.TALK_TO_US}
          button
          className={classes.listItem}
          onClick={handleClickItem(routes.TALK_TO_US)}>
          <ChatOutlinedIcon
            style={{
              marginTop: 4,
              marginRight: '-3px',
              fontSize: 18,
              width: 22,
              height: 22,
              color: '#2D7FFC',
            }}
          />
          <ListItemText primary="Fale conosco" />
        </ListItem>

        <ListItem
          selected={pathname === routes.FAQ}
          button
          className={classes.listItem}
          onClick={handleClickItem(routes.FAQ)}>
          <ContactSupportOutlinedIcon style={materialUIIconStyle} />
          <ListItemText primary="Perguntas frequentes" />
        </ListItem>

        <ListItem
          selected={pathname === routes.TUTORIAL}
          button
          className={classes.listItem}
          onClick={handleClickItem(routes.TUTORIAL)}>
          <VisibilityIcon style={materialUIIconStyle} />
          <ListItemText primary="Como funciona" />
        </ListItem>

        {isLogged && (
          <ListItem button className={classes.listItem} onClick={handleClickItem(routes.SIGNOUT)}>
            <PowerSettingsNewOutlinedIcon style={materialUIIconStyle} />
            <ListItemText primary="Sair" />
          </ListItem>
        )}

        {isAdmin && (
          <ListItem
            className={classes.listItem}
            button
            onClick={handleClickItem(routeWithParams(routes.ADMIN, 'usuarios'))}>
            <PainelAdminIcon />
            <ListItemText primary="Painel admin" />
          </ListItem>
        )}
      </List>
    </>
  )
}
