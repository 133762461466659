import {Paper} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import SubjectIcon from '@material-ui/icons/Subject'
import addressDisplay, {ADDRESS_VERBOSITY_COMPLETE} from '@root/lib/addressDisplay'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import mapsLink from '@root/lib/admin/mapsLink'
import {convertDateToBr} from '@root/lib/dateParser'
import {readableValue} from '@root/lib/simpleValuesDisplayMap'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import {contractMapper} from 'status-mapper'

const PropertiesList = ({user, match, classes}) => {
  const history = useHistory()
  const [rows, setRows] = useState([])
  // const userId = match.params.userId
  const userId = user.uid

  const goToPropertyDetail = (property) => {
    history.push(adminPathBuilder(`/imoveis/${property.uid}`))
  }

  useEffect(() => {
    const load = async () => {
      const results = await propertyModel.getPropertiesOwned(userId)
      setRows(results)
    }
    load()
  }, [])

  return (
    <div className={classes.propertiesList}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Apelido</TableCell>
              <TableCell>Endereço</TableCell>
              <TableCell>Aluguel</TableCell>
              <TableCell>IPTU</TableCell>
              <TableCell>Condominio</TableCell>
              <TableCell>Ocupação</TableCell>
              <TableCell>Sttus contrato</TableCell>
              <TableCell>Anúncio ativo</TableCell>
              <TableCell>Criado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((property) => (
              <TableRow key={property.uid} hover>
                <TableCell>
                  <Link to={adminPathBuilder(`/imoveis/${property.uid}`)}>{property.uid}</Link>
                </TableCell>
                <TableCell component="th" scope="user">
                  {property.alias}
                </TableCell>
                <TableCell>
                  <a target="_blank" rel="noreferrer" href={mapsLink(property.address)}>
                    {addressDisplay(property.address, ADDRESS_VERBOSITY_COMPLETE)}
                  </a>
                </TableCell>
                <TableCell>{readableValue(property.rent_amount, 'money')}</TableCell>
                <TableCell>{readableValue(property.iptu_amount, 'money')}</TableCell>
                <TableCell>{readableValue(property.condominium_amount, 'money')}</TableCell>
                <TableCell>{readableValue(property.occupation_type)}</TableCell>
                <TableCell>{contractMapper.translate(property.contract?.status)}</TableCell>
                <TableCell>
                  {readableValue(property.ad?.ad_enabled && !property.ad?.ad_disabled_by_admin, 'boolean')}
                </TableCell>
                <TableCell>{convertDateToBr(property.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PropertiesList
