import {isOldIOS} from '@root/lib/browserDetector'

/** @module Clipboard */
window.Clipboard = (function (window, document, navigator) {
  let textArea, copy

  function createTextArea(text) {
    textArea = document.createElement('textArea')
    textArea.value = text
    document.body.appendChild(textArea)
  }

  function selectText() {
    let range, selection

    if (isOldIOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  function copyToClipboard() {
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  copy = function (text) {
    if (isOldIOS()) {
      return new Promise((resolve, reject) => {
        createTextArea(text)
        selectText()
        copyToClipboard()
        resolve()
      })
    } else {
      return navigator.clipboard.writeText(text)
    }
  }

  return {
    copy: copy,
  }
})(window, document, navigator)

export default window.Clipboard
