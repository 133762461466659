/** @module moneyFormater */
import intVal from '@root/lib/intVal'

/**
 * @param {string} moneyStr
 * @return {number} if cannot parse will return 0(zero)
 */
export const formatBrCurrencyToFloat = (moneyStr) => {
  if (typeof moneyStr === 'number') {
    return moneyStr
  }
  return intVal(String(moneyStr).replace(/\./g, '').replace(',', '.'))
}

/**
 * @deprecated
 * @see formatBrCurrencyToFloat
 * @param {string} moneyStr
 * @return {number} if cannot parse will return 0(zero)
 */
export const formatMoneyBrToFloat = formatBrCurrencyToFloat

/**
 * @param {number|string} value
 * @return {string}
 */
export const formatFloatToBrCurrency = (value) => {
  let num = parseFloat(value)
  if (isNaN(num)) {
    num = 0
  }
  return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(num).replaceAll(' ', ' ')
}

/**
 * @param {number} value
 * @return {number}
 */
export const fromCents = (value) => {
  let num = parseFloat(value)
  if (isNaN(num)) {
    num = 0
  }
  return value / 100
}

/**
 * @param {number|string} value
 * @param {number} decimalSize
 * @return {string}
 */
export const toFixedDecimal = (value, decimalSize = 2) => {
  return Number(value).toFixed(decimalSize)
}

/**
 * @param value
 * @param {number} pct
 * @return {number}
 */
export const calcPct = (value, pct) => {
  return (Number(value) / 100) * pct
}
