export const ImoveisActionTypes = {
  SET_CURRENT_IMOVEL: 'SET_CURRENT_IMOVEL',
  CADASTRAR_IMOVEL: 'CADASTRAR_IMOVEL',
  SET_ESTADO_DO_IMOVEL: 'SET_ESTADO_DO_IMOVEL',
  SET_CONTRATO_ENVIADO: 'SET_CONTRATO_ENVIADO',
  SET_FORMA_DE_PAGAMENTO: 'SET_FORMA_DE_PAGAMENTO',
  SET_GARANTIAS: 'SET_GARANTIAS',
  SET_INVITE_IMOVEL: 'SET_INVITE_IMOVEL',
  SET_TENANT: 'SET_TENANT',
  REMOVE_TENANT: 'REMOVE_TENANT',
  ADD_WARRANTY: 'ADD_WARRANTY',
  ADD_EMPTY_WARRANTY: 'ADD_EMPTY_WARRANTY',
  REMOVE_WARRANTY: 'REMOVE_WARRANTY',
  CLEAR_WARRANTY: 'CLEAR_WARRANTY',
  ADD_CREDIT_ANALYSIS: 'ADD_CREDIT_ANALYSIS',
  REMOVE_CREDIT_ANALYSIS: 'REMOVE_CREDIT_ANALYSIS',
  CLEAR_CREDIT_ANALYSIS: 'CLEAR_CREDIT_ANALYSIS',
  SET_PROPERTY_VIACEP_ADDRESS: 'SET_PROPERTY_VIACEP_ADDRESS',
  SET_PROPERTY_ADDRESS_NUMBER: 'SET_PROPERTY_ADDRESS_NUMBER',
  SET_PROPERTY_FULL: 'SET_PROPERTY_FULL',
  UPDATE_PROPERTY_ADDRESS: 'UPDATE_PROPERTY_ADDRESS',
  RESET_PROPERTY_INITIAL_STATE: 'RESET_PROPERTY_INITIAL_STATE',
  SET_PROPERTY_CONTRACT_FILE: 'SET_PROPERTY_CONTRACT_FILE',
  SET_PROPERTY_CONTRACT_FILES: 'SET_PROPERTY_CONTRACT_FILES',
  SET_CURRENT_PROPERTY_REF: 'SET_CURRENT_PROPERTY_REF',
  SET_OR_UPDATE_PROPERTY_ALIAS: 'SET_OR_UPDATE_PROPERTY_ALIAS',
  SET_CURRENT_PROPERTY_ID: 'SET_CURRENT_PROPERTY_ID',
  RESET_PROPERTY_FROM_SNAP: 'RESET_PROPERTY_FROM_SNAP',
  SET_PROPERTY_PROPOSAL_MANAGER_INSTANCE: 'SET_PROPERTY_PROPOSAL_MANAGER_INSTANCE',
  RESET_PROPERTY: 'RESET_PROPERTY',
}
