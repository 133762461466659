import {UserActionTypes} from './user.types'

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
})

/**
 * @param user
 * @param {firebase.firestore.DocumentReference} ref
 * @returns {{payload: {ref: *, user: *}, type: string}}
 */
export const setUserFromFirebaseAction = (user, ref) => {
  return {
    type: UserActionTypes.SET_USER_FROM_FIREBASE,
    payload: {
      user,
      ref,
    },
  }
}

/**
 * @param snap
 * @returns {{payload: {ref: *}, type: string}}
 */
export const setUserRefAction = (snap) => ({
  type: UserActionTypes.SET_USER_FIREBASE_REF,
  payload: {
    ref: snap,
  },
})

/**
 * @returns {{type: string}}
 */
export const setUserNull = () => ({
  type: UserActionTypes.SET_USER_NULL,
})

export const addUserCardAction = (card) => ({
  type: UserActionTypes.ADD_USER_CARD,
  payload: {card},
})

export const setUserAddressAction = (address) => ({
  type: UserActionTypes.SET_USER_ADDRESS,
  payload: {address},
})

export const setAuthFinishedAction = (finished) => ({
  type: UserActionTypes.SET_USER_AUTH_FINISHED,
  payload: {finished},
})

export const setUserLoggedAction = (logged) => ({
  type: UserActionTypes.SET_USER_LOGGED,
  payload: {logged},
})

export const setUserIdAction = (id) => ({
  type: UserActionTypes.SET_USER_ID,
  payload: {id},
})
export const setUserFirstLoginAction = (isFirst) => ({
  type: UserActionTypes.SET_USER_FIRST_LOGIN,
  payload: {isFirst},
})
export const setUserInitialAction = (isFirst, userId) => ({
  type: UserActionTypes.SET_USER_INITIAL,
  payload: {isFirst, id: userId, logged: !!userId},
})
