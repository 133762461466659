import {AdminContractManagerTypes} from '@root/redux/adminContractManager/adminContractManager.types'

/**
 *
 * @param {array<any>} appendixes
 * @param {array<any>} clauses
 * @return {{payload: {appendixes, clauses}, type: string}}
 */
export const setContractManagerInitial = (appendixes, clauses) => {
  return {
    type: AdminContractManagerTypes.SET_INITIAL_DATA,
    payload: {
      appendixes,
      clauses,
    },
  }
}

/**
 * @param {string} action
 * @param {string} xpath
 * @param {string} [content]
 * @param {string} [insertMode]
 * @return {{payload: {item, xpath, content}, type: string}}
 */
const dispatchAction = (type, action, xpath, content, insertMode) => {
  return {
    type,
    payload: {
      action,
      xpath,
      content,
      insertMode,
    },
  }
}

export const addContractManagerAdddEntry = (xpath) => {
  return dispatchAction(AdminContractManagerTypes.SET_ADD, 'add', xpath)
}

export const addContractManagerDeletedEntry = (xpath) => {
  return dispatchAction(AdminContractManagerTypes.SET_DELETED, 'delete', xpath)
}

export const addContractManagerModifiedEntry = (xpath, content) => {
  return dispatchAction(AdminContractManagerTypes.SET_MODIFIED, 'edit', xpath, content)
}

export const addContractManagerModifiedEnumeration = (xpath, enumType) => {
  return dispatchAction(AdminContractManagerTypes.SET_ENUMERATION, 'edit-enum', xpath, enumType)
}

export const clearContractManagerModified = () => {
  return {
    type: AdminContractManagerTypes.CLEAR_MODIFIED,
    payload: {},
  }
}
