import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@material-ui/core/'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CustomDialog from '@root/components/CustomDialog/CustomDialog'
import PropTypes from 'prop-types'
import React from 'react'

const iconsColorMap = {
  success: '#4bcc39',
  info: '#3998cc',
  warning: '#e9e84e',
  error: '#cc3939',
}

const iconsMap = {
  success: CheckCircleOutlinedIcon,
  info: InfoOutlinedIcon,
  warning: ReportProblemOutlinedIcon,
  error: ErrorOutlineOutlinedIcon,
}

const createIconComponent = (type) => {
  return React.createElement(iconsMap[type], {style: {fontSize: 36, color: iconsColorMap[type]}})
}

const Confirm = (props) => {
  const callFunc = (fn) => {
    if (fn) {
      fn()
    }
  }

  const handleOk = () => {
    callFunc(props.onOk)
  }

  const handleCancel = () => {
    callFunc(props.onCancel)
  }

  return (
    <CustomDialog
      open={props.open}
      // onClose={handleClose}
      // aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {/*<DialogTitle id="alert-dialog-title"></DialogTitle>*/}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>{createIconComponent(props.type)}</Grid>
            <Grid item>{props.content}</Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!props.alertMode ? <Button onClick={handleCancel}>{props.cancelText}</Button> : null}

        <Button onClick={handleOk} color="primary" autoFocus>
          {props.okText}
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

Confirm.defaultProps = {
  type: 'success',
  okText: 'OK',
  cancelText: 'Cancelar',
  alertMode: false,
}
Confirm.propTypes = {
  open: PropTypes.bool.isRequired,
  alertMode: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  content: PropTypes.node.isRequired,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
}
export default Confirm
