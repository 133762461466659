import {Card, CardContent, Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import analyticsLogger from '@root/firebase/analytics.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import routes, {routeWithUID} from '@root/utils/routes'

const Dashboard = () => {
  const history = useHistory()
  const user = useSelector((s) => s.user.user)
  const isLoggedUser = useSelector((state) => state.user.isLogged)
  const actions = actionDispatcher({setHeaderMainHeader})

  actions.setHeaderMainHeader()

  useEffect(() => {
    if (!isLoggedUser) {
      return history.push(routes.REGISTER_SIGNUP)
    }

    let count = 0
    if (user.is_owner) {
      count += 1
    }

    if (user.is_tenant) {
      count += 0.5
    }
    if (user.is_fiador) {
      count += 0.5
    }

    if (count < 1.5) {
      history.push(routes.ROOT)
    }
  }, [])

  const goToTenantDashboard = () => {
    analyticsLogger.logEvent('dashboard_choice', {alternative_event_name: 'inquilino_choice', button_name: 'inquilino'})
    const propertyId = user.tenant_of.id || user.fiador_of.id
    if (!propertyId) {
      return history.push(routes.ROOT)
    }
    history.push(routeWithUID(routes.DASHBOARD_TENANT_ROOT, propertyId))
  }

  const goToOwnerDashboard = () => {
    analyticsLogger.logEvent('dashboard_choice', {
      alternative_event_name: 'proprietario_choice',
      button_name: 'proprietario',
    })
    history.push(routes.DASHBOARD_ROOT)
  }

  return (
    <DashboardWrapper title="Atuar como">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card variant="outlined" onClick={goToTenantDashboard} style={{cursor: 'pointer'}}>
            <CardContent>
              <Typography variant="h5" component="p" style={{marginBottom: 10}}>
                Inquilino
              </Typography>
              <Typography variant="body2" component="p">
                Veja as suas opções como inquilino do imóvel.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined" onClick={goToOwnerDashboard} style={{cursor: 'pointer'}}>
            <CardContent>
              <Typography variant="h5" component="p" style={{marginBottom: 10}}>
                Proprietário
              </Typography>
              <Typography variant="body2" component="p">
                Veja as suas opções para gerir o seu imóvel.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardWrapper>
  )
}

export default Dashboard
