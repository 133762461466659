// Material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {connect} from 'react-redux'

import {setFooterIsVisible} from '../../redux/footer/footer.actions'
// Redux Actions
import {setHeaderMainHeader} from '../../redux/header/header.actions'
// Components
import BannerSection from './BannerSection/BannerSection'
import MainFeaturesSection from './MainFeaturesSection/MainFeaturesSection'
import useStyles from './styles'

const InquilinoPage = ({setHeaderMainHeader, setFooterIsVisible}) => {
  setHeaderMainHeader()
  setFooterIsVisible()

  const classes = useStyles()

  return (
    <div className={classes.home}>
      <BannerSection />
      <Grid container>
        <Grid item>
          <Typography variant="h1" className={classes.homeH1}>
            Entenda como ter todos os detalhes do seu aluguel organizados em um único lugar
          </Typography>
        </Grid>
      </Grid>
      <MainFeaturesSection />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setHeaderMainHeader: () => dispatch(setHeaderMainHeader()),
  setFooterIsVisible: () => dispatch(setFooterIsVisible()),
})

export default connect(null, mapDispatchToProps)(InquilinoPage)
