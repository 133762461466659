import Page from '@root/components/Page'
import WhatsAppButton from '@root/components/WhatsAppButton'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

const IRPF = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarTitleAction('IRPF'))
  }, [])

  return (
    <Page>
      <p style={{margin: '20px 0px'}}>
        A declaração de Imposto de Renda deve ser feita pelo proprietário e pelo inquilino seguindo as indicações da
        Receita Federal utilizando o Carne Leão.
      </p>

      <h4 style={{margin: '20px 0px'}}>
        Acesse o nosso{' '}
        <a href="https://moben.com.br/blog" target="_blank" rel="noreferrer">
          Blog
        </a>{' '}
        para saber mais
      </h4>

      <a
        style={{margin: '20px 0px'}}
        href="https://www.gov.br/receitafederal/pt-br/assuntos/orientacao-tributaria/pagamentos-e-parcelamentos/pagamento-do-imposto-de-renda-de-pessoa-fisica/carne-leao/carne-leao"
        target="_blank"
        rel="noreferrer">
        Site da Receita Federal
      </a>

      <h3 style={{margin: '20px 0px'}}>Entre em contato caso precise de alguma ajuda</h3>

      <WhatsAppButton message="Preciso de ajuda com IRPF" />
    </Page>
  )
}

export default IRPF
