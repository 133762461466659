import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .bold': {
      fontWeight: 600,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '& legend': {
      textAlign: 'center',
      marginBottom: 10,
    },
  },

  reajustmentIndex: {
    '& > p': {
      marginBottom: theme.margin[3],
    },
    // '& > :last-child': {
    //   marginTop: theme.margin[5],
    // },
    '& .fiador, .seguroFianca': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      '& > p': {
        marginBottom: theme.margin[2],
      },
      '& textarea, .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
        background: '#FFF',
      },
    },
    '& .dateWrapper': {
      'margin': '0 auto',
      'width': '140px',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
      },
    },
    '& .dateWrapperTitle': {
      marginBottom: '-10px !important',
    },
  },
  chartWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'gap': 40,
    'alignItems': 'center',
    'margin': '40px 0',
    '& > div': {
      transform: 'translateX(-14px)',
    },
  },
  verHistoricoBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 20px',
  },
  chartCaption: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontStyle: 'italic',
    fontSize: '.7rem',
  },
}))
