/* eslint-disable no-empty */
import TextField from '@material-ui/core/TextField'
import RGMask from '@root/components/RGMask'
import React from 'react'

const RGRender = (controllerProps, helpers, formItemProps) => {
  const {hasError, applyShrink, forwardOnChange, forwardOnBlur, forwardOnFocus} = helpers
  const {value} = controllerProps
  return (
    <TextField
      inputRef={helpers.inputRef}
      error={hasError}
      label={formItemProps.label}
      required={formItemProps.required}
      InputLabelProps={{shrink: applyShrink}}
      InputProps={{
        inputComponent: RGMask,
        ...formItemProps,
        defaultValue: value || '',
        onChange: forwardOnChange,
        onBlur: forwardOnBlur,
        onFocus: forwardOnFocus,
      }}
    />
  )
}

export default RGRender
