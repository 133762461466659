import {Grid, Typography} from '@material-ui/core'
import {INVITE_TYPE_FIADOR, INVITE_TYPE_TENANT} from '@root/components/InviteRegister/Invite'
import Page from '@root/components/Page'
import React from 'react'

const MySelfScreen = ({inviteType}) => {
  const translateType = () => {
    let txt
    switch (inviteType) {
      case INVITE_TYPE_TENANT:
        txt = 'inquilino'
        break
      case INVITE_TYPE_FIADOR:
        txt = 'fiador'
        break
      default:
        txt = 'não foi possível identificar'
    }

    return txt
  }

  return (
    <Page>
      <Grid>
        <Typography variant="h4" style={{fontWeight: '600'}}>
          Ops.....
        </Typography>
        <br />
        <Typography variant="h6" style={{fontWeight: '600'}}>
          Parece que você esta tentando se cadastrar como seu próprio {translateType()} e isso não é permitido.
        </Typography>
      </Grid>
    </Page>
  )
}

export default MySelfScreen
