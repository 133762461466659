import PropTypes from 'prop-types'
import React from 'react'

const SectionContainer = ({divider, children}) => {
  const cls = `subsection-container ${divider ? 'section-divider' : ''}`
  return <section className={cls}>{children}</section>
}

SectionContainer.propTypes = {
  divider: PropTypes.bool,
}
SectionContainer.defaultProps = {
  divider: false,
}

export default SectionContainer
