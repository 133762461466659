import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  contractCreated: {
    'padding': '0 10px !important',
    'position': 'relative',
    'height': '100vh',
    // 'backgroundColor': theme.extraColors.GRAY[100],
    '& header': {
      display: 'flow-root',
      backgroundColor: theme.extraColors.WHITE,
      paddingBottom: theme.margin[4],
    },
    '& header > p': {
      marginBottom: theme.margin[3],
    },
    '& .buttonsContainer': {
      display: 'flex',
      justifyContent: 'space-around',
      width: '90%',
      margin: '0 auto',
    },
    '& .notificationsSection': {
      'padding': theme.margin[3],
      '& :first-child .notificationDate': {
        paddingTop: 0,
        borderLeft: 'none',
      },
    },
    '& .notificationDate': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      marginLeft: theme.margin[3],

      borderLeft: `2px solid ${theme.extraColors.GRAY[500]}`,
      paddingLeft: theme.margin[1],
      paddingTop: theme.margin[2],
    },
    '& .notification': {
      'width': '100%',
      'display': 'flex',
      'alignItems': 'center',
      '& > svg': {
        fill: theme.extraColors.GRAY[500],
        transform: 'rotate(-45deg) translate(10%)',
      },
      '& > p': {margin: `0 ${theme.margin[1]} 0 0`},
      '& > *': {marginRight: theme.margin[1]},
    },
    '& .changeRequest': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      '& > p': {margin: 0},
      '& > p:first-child': {marginRight: theme.margin[1]},
      '& .buttonsContainer': {
        marginTop: theme.margin[1],
        display: 'flex',
        width: '100%',
        height: '29px',
      },
      '& .changeNotAccepted': {
        color: theme.extraColors.RED,
        display: 'flex',
        alignItems: 'center',
      },
      '& .changeAccepted': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
}))
