import PropTypes from 'prop-types'
import React from 'react'
const IconPaper = ({fill, size}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={{width: size, height: size}}
      viewBox="0 0 109 109">
      <path
        id="paper-11960"
        d="M54.5,0A54.5,54.5,0,1,0,109,54.5,54.517,54.517,0,0,0,54.5,0Zm0,2.271A52.229,52.229,0,1,1,2.271,54.5,52.212,52.212,0,0,1,54.5,2.271ZM28.27,24.984a1.136,1.136,0,0,0-.146.027,5.678,5.678,0,0,0-5.415,5.65v3.406h9.083v4.542h2.271V30.661A5.623,5.623,0,0,0,32.9,27.254H71.531a3.374,3.374,0,0,1,3.406,3.406V40.9h2.271V30.736q0-.038,0-.075a5.69,5.69,0,0,0-5.677-5.677H28.385a1.136,1.136,0,0,0-.115,0Zm.115,2.271a3.374,3.374,0,0,1,3.406,3.406V31.8H24.979V30.661A3.374,3.374,0,0,1,28.385,27.254ZM38.6,31.8v2.271h9.083V31.8Zm11.354,9.066V61.743l-.093.089.093.1v3.907H47.688v2.271h2.271v2.271h2.271V68.112h27.25V65.841H52.229v-3.1L59.8,55.68l4.644,3.477,3.145-3.783L72.671,60,79.4,52.007v.71h2.271V48.175H77.133v2.271h.612l-5.287,6.285-3.411-3.1,3.619-4.347v.665h2.271V45.4H70.4v2.271h.652L67.367,52.1,59.8,45.2l-7.571,7.762v-12.1Zm-17.049.018a1.136,1.136,0,0,0-.785.333L20.77,52.566a1.136,1.136,0,0,0,.8,1.938h1.135v12.49a1.136,1.136,0,0,0,1.135,1.135H42.01a1.136,1.136,0,0,0,1.135-1.135V54.5h1.135a1.136,1.136,0,0,0,.8-1.938l-1.938-1.938V46.574a1.136,1.136,0,1,0-2.271,0v1.783L33.73,41.212A1.136,1.136,0,0,0,32.909,40.879Zm.018,2.741,8.063,8.063a1.135,1.135,0,0,0,.523.523l.027.027H24.314Zm26.966,4.759,6.019,5.482-1.832,2.2-4.435-3.318-7.416,6.906V56.23ZM24.979,54.5h15.9V65.859H36.333V60.182A1.136,1.136,0,0,0,35.2,59.046H30.656a1.136,1.136,0,0,0-1.135,1.135v5.677H24.979Zm6.813,6.813h2.271v4.542H31.792Zm0,9.074v7.948a5.69,5.69,0,0,0,5.677,5.677H80.615a5.69,5.69,0,0,0,5.677-5.677V74.933H77.208V70.391H74.938v4.542H40.875v3.406a3.406,3.406,0,0,1-6.812,0V70.391Zm11.354,6.821H84.021v1.135a3.374,3.374,0,0,1-3.406,3.406H41.975a5.609,5.609,0,0,0,1.171-3.406Z"
        fill={fill}
      />
    </svg>
  )
}

IconPaper.propTypes = {
  size: PropTypes.any,
}
IconPaper.defaultProps = {
  size: '109px',
}
export default IconPaper
