import {Button, Typography} from '@material-ui/core'
import React from 'react'

const ButtonTransparentBg = (props) => {
  let btColor = '#a1a1a1'
  let defaultStyle = {
    background: 'transparent',
    border: `2px solid ${btColor}`,
    // padding: '4px 8px',
    transition: '0.4s',
    ...props.buttonStyle,
  }
  props.disabled ? (btColor = '#a1a1a1') : (btColor = '#120377')

  if (props.fullWidth) {
    defaultStyle.width = '100%'
  }

  return (
    <Button size="small" {...props} style={defaultStyle}>
      <Typography
        variant="body1"
        style={{fontWeight: '600', transition: '0.4s', textTransform: 'none', color: btColor, ...props.style}}>
        {props.children}
      </Typography>
    </Button>
  )
}

export default ButtonTransparentBg
