import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import {
  usePropertyAlerts,
  usePropertyPaymentStatuses,
  usePropertyServices,
} from '@root/application/service/repository/property/PropertyRepository'
import {ANSWER_YES} from '@root/components/App/Contract/DefaultRadioGroup'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import BaseModal from '@root/components/Modal/BaseModal'
import useModalControl from '@root/components/Modal/useModalControl'
import Property from '@root/components/YourProperty/Property'
import PropertyAlert from '@root/components/YourProperty/sections/PropertyAlert'
import SectionContract from '@root/components/YourProperty/sections/SectionContract'
import SectionDocuments from '@root/components/YourProperty/sections/SectionPayment/SectionDocuments'
import SectionPayment from '@root/components/YourProperty/sections/SectionPayment/SectionPayment'
import SectionPropertyAd from '@root/components/YourProperty/sections/SectionPropertyAd'
import SectionStatuses from '@root/components/YourProperty/sections/SectionStatuses/SectionStatuses'
import SectionTenant from '@root/components/YourProperty/sections/SectionTenant'
import {useStyles} from '@root/components/YourProperty/styles'
import usePropertyServicesService from '@root/components/YourProperty/usePropertyServicesService'
import analyticsLogger from '@root/firebase/analytics.utils'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import {yesOrNotOptions} from '@root/lib/predefinedValuesOptions'
import {validateRequired} from '@root/lib/simpleValidators'
import sleep from '@root/lib/sleep'
import propertyModel from '@root/model/propertyModel'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import routes from '@root/utils/routes'
import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

const dividerMargin = '10px 0 10px'
const DashboardDivider = () => <Divider dashed m={dividerMargin} />

const Container = ({userId, propertyId, children}) => {
  const classes = useStyles()
  const paymentsStatuses = usePropertyPaymentStatuses(propertyId)

  if (paymentsStatuses.isLoading || paymentsStatuses.isError) {
    return children
  }

  return (
    <div className={classes.sectionsContainer}>
      <div className={classes.mainSections}>{children}</div>

      <SectionStatuses paymentsStatuses={paymentsStatuses.response} />
    </div>
  )
}

export function YourProperty() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {control, errors, handleSubmit} = useForm()
  const modalControl = useModalControl()

  const userUID = useSelector((state) => state.user.id)
  const user = useSelector((state) => state.user.user)
  const propertyStore = useSelector((state) => state.property)
  const property = propertyStore?.data ?? {}
  const propertyId = property.uid
  const propertyAlias = useSelector((state) => state.property?.data?.alias)
  const alerts = usePropertyAlerts(propertyId)
  const services = usePropertyServicesService(property)

  const sendBudget = async (service) => {
    dispatch(setGlobalLoaderAction(true))
    await services.sendBudget(user, service)
    dispatch(setGlobalLoaderAction(false))

    alert('Solicitação enviada, em breve a nossa equipe entrara em contato com você.')
  }

  const sendBudgetPhoto = () => sendBudget(services.photo)

  const sendBudgetSurvey = (evt, checkTrousseau = true, addTrousseauTax = false) => {
    const survey = {...services.survey}

    if (!services.hasTrousseau && checkTrousseau) {
      modalControl.open()
      return
    }

    // gambeta(2023-07-07), como o trousseau e um campo novo nem sempre vai ser calculado direto na api, entao aqui e so um fix temporario que vai se auto corrigir com o tempo
    if (addTrousseauTax) {
      let amount = survey.payload.amount
      const hasFurnished = property.more_about.furnished === ANSWER_YES

      const furnishedTax = (amount / 100) * 30 // 30% para imovel com mobilia
      const trousseauTax = (amount / 100) * 50 // 50% para imovel com enxoval

      // nao pode calcular as 2 taxas, mobilia + enxoval, entao vai gabeta em cima de gambeta aqui
      // basicamente preciso remover a taxa da mobilia se houver, e ai sim aplicar a do enxoval
      if (hasFurnished) {
        amount = amount - furnishedTax
      }
      amount += trousseauTax

      survey.payload.amount = amount
      survey.payload.amount_formatted = formatFloatToBrCurrency(amount)
    }
    return sendBudget(survey)
  }

  const handleTrousseauForm = async (form) => {
    const trousseau = form.more_about.trousseau
    const addTax = trousseau === ANSWER_YES

    property.more_about.trousseau = trousseau
    await propertyModel.updateTrousseau(property.uid, trousseau)

    services.refresh()
    await sendBudgetSurvey(null, false, addTax)
    modalControl.close()
  }

  useEffect(() => {
    if (!propertyStore?.currentPropertyId) {
      history.push(routes.DASHBOARD_PROPERTY_LIST)
    }
  }, [])

  useEffect(() => {
    dispatch(setNavbarTitleAction(propertyAlias))
  }, [])

  return (
    <Container propertyId={propertyId} userId={userUID}>
      <Box type="simple" bgType="white" m="0 auto">
        <Property property={propertyStore} />
        <DashboardDivider />

        <Grid container direction="row" spacing={2}>
          {alerts.response.map((alert) => (
            <Grid key={alert.id} item xs={12}>
              <PropertyAlert {...alert} propertyId={propertyId} />
            </Grid>
          ))}

          {services.hasSurvey && (
            <Grid item xs={12} md={6}>
              <PropertyAlert
                key={services.survey.id}
                propertyId={propertyId}
                onClick={sendBudgetSurvey}
                {...services.survey}
                description={
                  <>
                    <Text>{services.survey.description}</Text>
                    <br />
                    <br />
                    <small>{services.survey.payload.disclaimer}</small>
                  </>
                }
              />
            </Grid>
          )}

          {services.hasPhoto && (
            <Grid item xs={12} md={6}>
              <PropertyAlert
                key={services.photo.id}
                propertyId={propertyId}
                onClick={sendBudgetPhoto}
                {...services.photo}
                description={
                  <>
                    <Text>{services.photo.description}</Text>
                    <br />
                    <br />
                    <small>{services.photo.payload.disclaimer}</small>
                  </>
                }
              />
            </Grid>
          )}
        </Grid>

        <br />
        <SectionTenant />
        <DashboardDivider />

        <SectionContract />
        <DashboardDivider />

        <SectionPayment />
        <DashboardDivider />

        <SectionDocuments />
        <DashboardDivider />

        <SectionPropertyAd />
        <DashboardDivider />
      </Box>

      <BaseModal
        title="Enxoval"
        isOpen={modalControl.visibility}
        cancelLabel="cancelar"
        okAction={handleSubmit(handleTrousseauForm)}
        cancelAction={modalControl.close}
        size="sm">
        <>
          <TextBlock>
            O enxoval e composto por itens como louças, talheres, artigos de cama, mesa e banho entre outros.
          </TextBlock>
          <FormItem
            control={control}
            errors={errors}
            name="more_about.trousseau"
            type="radio"
            label="Imóvel possui enxoval?"
            options={yesOrNotOptions}
            rules={{validate: validateRequired}}
          />
        </>
      </BaseModal>
    </Container>
  )
}
