/** @module simplePhoneFormatter */
import {MOBEN_WHATSAPP, MOBEN_WHATSAPP_SALES} from '@root/utils/constants'
import {onlyNumbers} from '@root/lib/intVal'


/**
 * @param {string} phonestr
 * @return {string}
 */
export const simplePhoneFormatter = (phonestr) => {
  const maxLen = 11
  const str = onlyNumbers(phonestr).padEnd(maxLen, '0').substr(0, maxLen)
  return str.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
}

/**
 * @param {string} phone
 * @param {string} [text]
 * @return {string}
 */
export const whatsAppLinkGenerator = (phone, text = '') => {
  let message = ''
  if (text) {
    message = encodeURIComponent(text)
  }

  return `https://api.whatsapp.com/send?phone=55${onlyNumbers(phone)}&text=${message}`
}

/**
 * @param {string} [text]
 * @return {string}
 */
export const whatsAppLinkMobenDefault = (text) => whatsAppLinkGenerator(MOBEN_WHATSAPP, text)

/**
 * @param {string} [text]
 * @return {string}
 */
export const whatsAppLinkMobenSales = (text) => whatsAppLinkGenerator(MOBEN_WHATSAPP_SALES, text)
