/** @module ProposalManager */
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import uidGenerator from '@root/lib/uidGenerator'

const validateProposalItemInstance = (proposal) => {
  const isProposalInstance = proposal instanceof ProposalHelper
  if (!isProposalInstance) {
    throw new Error('Not an instance of ProposalHelper')
  }
}

// https://jestjs.io/pt-BR/docs/es6-class-mocks

export default class ProposalManager {
  /** @type {ProposalHelper[]} */
  #proposals = []

  /** @type {ProposalHelper} */
  #selected = undefined

  /**
   * @param property
   * @return {ProposalManager}
   */
  static initWithProperty(property) {
    const manager = new ProposalManager()
    manager.initFromProperty(property)
    return manager
  }

  /**
   * @param property
   */
  initFromProperty(property) {
    const proposals = property?.proposals ?? {}
    const entries = Object.entries(proposals)
    // const selectedId = property?.selected_proposal?.uid

    let helper
    entries.forEach(([id, proposal]) => {
      helper = this._createProposalItem(proposal, id)
      this.addProposal(helper)
    })
  }

  /**
   * @private
   * @param proposalItem
   */
  _setSelected(proposalItem) {
    validateProposalItemInstance(proposalItem)
    this.#selected = proposalItem
  }

  /**
   * @private
   * @param {object} proposal
   * @param {string} id
   * @return {ProposalHelper}
   */
  _createProposalItem(proposal, id) {
    let helper
    helper = new ProposalHelper()
    helper.setProposal(id, proposal)

    return helper
  }

  /**
   * @return {string}
   */
  generateId() {
    return uidGenerator()
  }

  /**
   * @param id
   * @return {ProposalHelper}
   */
  findProposalByInterestedId(id) {
    const proposalFound = this.#proposals.find((helper) => {
      return helper.getInterestedsRaw().find((interested) => {
        return interested.user.id === id
      })
    })
    return proposalFound
  }

  /**
   * @param id
   * @return {ProposalHelper}
   */
  findProposalById(id) {
    return this.#proposals.find((helper) => helper.getId() === id)
  }

  /**
   * @param id
   * @return {boolean}
   */
  exists(id) {
    return this.#proposals.find((helper) => helper.getId() === id) !== undefined
  }

  /**
   * @param id
   */
  selectProposalById(id) {
    const proposalItem = this.findProposalById(id)

    if (proposalItem) {
      this._setSelected(proposalItem)
    }
  }

  /**
   * @param index
   */
  selectProposalAt(index) {
    const proposalItem = this.getProposalAt(index)

    if (proposalItem) {
      this._setSelected(proposalItem)
    }
  }

  /**
   * @param property
   * @param proposalId
   * @return {*}
   */
  selectProposalFromPropertyOrId(property, proposalId) {
    let searchId
    const selectedId = property.selected_proposal?.uid
    // const userInProposal = proposalFinder(proposals, user?.uid)
    // const helper = new ProposalHelper()

    if (proposalId) {
      searchId = proposalId
    } else if (selectedId) {
      searchId = selectedId
    }

    this.selectProposalById(searchId)
    return this.getSelected()
  }

  /**
   * @param {ProposalHelper} proposal
   */
  addProposal(proposal) {
    validateProposalItemInstance(proposal)

    if (!proposal.isValid()) {
      throw new Error('Invalid proposal')
    }

    if (this.exists(proposal.getId())) {
      this.replaceProposal(proposal)
    } else {
      this.#proposals.push(proposal)
    }
  }

  /**
   * @param index
   * @return {undefined|ProposalHelper}
   */
  getProposalAt(index) {
    if (this.#proposals[index]) {
      return this.#proposals[index]
    }
    return undefined
  }

  /**
   * @return {ProposalHelper[]}
   */
  getProposalsList() {
    return this.#proposals
  }

  /**
   *
   * @param {ProposalHelper} proposal
   */
  replaceProposal(proposal) {
    const index = this.#proposals.findIndex((item) => item.getId() === proposal.getId())
    this.#proposals[index] = proposal
  }

  /**
   * @return {number}
   */
  getProposalsLen() {
    return this.#proposals.length
  }

  /**
   * @return {boolean}
   */
  hasProposals() {
    return this.getProposalsLen() > 0
  }

  /**
   * @return {boolean}
   */
  hasSelected() {
    return this.#selected !== undefined
  }

  /**
   * @return {ProposalHelper}
   */
  getSelected() {
    return this.#selected
  }

  async loadUsersFromProposals() {
    const loads = this.#proposals.map((helper) => helper.loadUsersRefs())
    await Promise.all(loads)
  }

  /**
   * @return {{}}
   */
  toObject() {
    const proposalsObj = {}
    this.#proposals.forEach((item) => {
      proposalsObj[item.getId()] = item.toObject()
    })
    return proposalsObj
  }
}
