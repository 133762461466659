import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import EmailIcon from '@material-ui/icons/Email'
import analyticsLogger from '@root/firebase/analytics.utils'
import React, {useEffect} from 'react'

import sentryErrorImg from '../../assets/sentry-error.jpg'
import useStyles from './styles'

/**
 *
 * @param {{error: Error, componentStack: string, resetError: string}} param
 * @returns
 */
const DevError = (param) => {
  const {error, componentStack, resetError} = param
  return (
    <div>
      <div>You have encountered an error</div>
      <div>{error.toString()}</div>
      <pre>{componentStack}</pre>
      {/* <pre>{error.stack}</pre> */}
    </div>
  )
}

export default DevError
