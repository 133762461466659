import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import {getDefaultProps, getPropTypes} from '@root/components/Forms/Invite/invite-props'
import {currencyFormatProps} from '@root/components/NumberMask'
import {maritalStatusOptions, occupationOptions} from '@root/lib/predefinedValuesOptions'
import {
  numberFormatValidateCurrency,
  validateCpf,
  validateDate,
  validateEmail,
  validateFullName,
  validatePhone,
  validateRequired,
} from '@root/lib/simpleValidators'
import React, {useMemo} from 'react'
import {useFormContext} from 'react-hook-form'
import {CPF_FORMAT, DATE_BR_FORMAT, PHONE_FORMAT} from '@root/utils/constants'

const SPACING = 2
const BaseUserForm = ({
  allowFullEdit,
  incomeText,
  displayUploads,
  displayIncomeField,
  requireIdentityDoc,
  requireIncomeDoc,
}) => {
  const formHookContext = useFormContext()
  const {control, errors} = formHookContext

  const identityValidation = useMemo(() => buildDocValidation(requireIdentityDoc), [])
  const incomeValidation = useMemo(() => buildDocValidation(requireIncomeDoc), [])

  function buildDocValidation(validate) {
    if (!validate) {
      return
    }
    return {
      validate: validateRequired,
    }
  }

  return (
    <Grid container alignItems="flex-start" direction="column">
      <Grid container spacing={SPACING} direction="row" justify="space-between">
        <Grid item xs={12}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            label="Nome completo"
            name="name"
            rules={{validate: validateFullName}}
          />
        </Grid>
      </Grid>

      <Grid container spacing={SPACING} direction="row" justify="space-between">
        <Grid item xs={6} sm={5}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="mask"
            label="CPF"
            name="cpf"
            rules={{
              validate: validateCpf,
            }}
            maskInputProps={{
              format: CPF_FORMAT,
            }}
            // style={{width: '100%', marginBottom: '30px'}}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="textfield"
            label="RG"
            name="rg"
            rules={{
              validate: validateRequired,
            }}
          />
        </Grid>
        <Grid item xs={5} sm={3}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="textfield"
            label="Orgão emissor"
            name="rg_issuing_agency"
            rules={{
              validate: validateRequired,
            }}
          />
        </Grid>
        <Grid item xs={7} sm={4} lg={3}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="mask"
            label="Data de nascimento"
            name="birthdate"
            rules={{
              validate: validateDate,
            }}
            maskInputProps={{
              format: DATE_BR_FORMAT,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={SPACING} direction="row" justify="space-between">
        <Grid item xs={12}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            label="E-Mail"
            name="email"
            InputProps={{readOnly: !allowFullEdit}}
            rules={{
              validate: validateEmail,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={SPACING} direction="row" justify="space-between">
        <Grid item xs={12}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="mask"
            label="Telefone"
            name="phone"
            rules={{validate: validatePhone}}
            maskInputProps={{
              format: PHONE_FORMAT,
              disabled: !allowFullEdit,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={SPACING} direction="row" justify="space-between">
        <Grid item xs={6}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="select"
            label="Estado civil"
            name="marital_status"
            rules={{required: 'campo obrigatório'}}
            options={maritalStatusOptions}
          />
        </Grid>
      </Grid>

      <Grid container spacing={SPACING} direction="row" justify="space-between">
        <Grid item xs={6}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            label="Profissão"
            name="profession"
            rules={{validate: validateRequired}}
          />
        </Grid>

        <Grid item xs={6}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="select"
            label="Ocupação"
            name="occupation"
            options={occupationOptions}
            rules={{validate: validateRequired}}
          />
        </Grid>
      </Grid>

      {incomeText && <Typography>{incomeText}</Typography>}

      {displayIncomeField && (
        <Grid item xs={6}>
          <FormItem
            control={control}
            errors={errors}
            type="mask"
            label="Renda Bruta Mensal"
            name="income"
            rules={{
              validate: numberFormatValidateCurrency,
            }}
            maskInputProps={currencyFormatProps}
          />
        </Grid>
      )}

      {displayUploads && (
        <>
          <Grid item xs={12}>
            <Typography variant="body2" style={{marginBottom: 5}}>
              Documento de identificação: RG com CPF ou CNH
            </Typography>
          </Grid>

          <Grid container spacing={SPACING} direction="row" justify="space-between">
            <Grid item xs={12}>
              <FormItem
                control={control}
                errors={errors}
                fitContainer
                type="file"
                name="docs.identity"
                required={requireIdentityDoc}
                rules={identityValidation}
                // onChange={handleDocChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" style={{marginBottom: -10}}>
                Comprovante de renda
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormItem
                control={control}
                errors={errors}
                fitContainer
                type="file"
                label=""
                name="docs.income"
                required={requireIncomeDoc}
                rules={incomeValidation}
                // onChange={handleDocChange}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

BaseUserForm.defaultProps = getDefaultProps()
BaseUserForm.propTypes = getPropTypes()
export default BaseUserForm
