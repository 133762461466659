import {TenantPropertyTypes} from '@root/redux/tenantProperty/tenantProperty.types'

export const loadTenantPropertyAction = (property) => {
  return {
    type: TenantPropertyTypes.LOAD_TENANT_PROPERTY,
    payload: {
      property,
    },
  }
}

export const loadTenantPropertyListAction = (properties) => {
  return {
    type: TenantPropertyTypes.LOAD_TENANT_PROPERTIES,
    payload: {
      properties,
    },
  }
}
