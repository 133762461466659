import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {Button} from '../../components/Button/Button'
import FilePickerMultiple from '../../components/FilePickerMultiple'
import FormItem from '../../components/FormHelper/FormItem'
import IconOpen from '../../components/icons/IconOpen'
import {IconWrapper} from '../../components/IconWrapper/IconWrapper'
import {Box} from '../../components/Layout/Container/Box/Box'
import {Modal} from '../../components/Modal/Modal'
import Page from '../../components/Page'
import {firestore} from '../../firebase/firebase.utils'
import actionDispatcher from '../../lib/actionDispatcher'
import {convertDateToBr} from '../../lib/dateParser'
import {firebaseFilesUploader} from '../../lib/firebaseUploader'
import {validateRequired} from '../../lib/simpleValidators'
import {setNavbarTitleAction} from '../../redux/application/application.actions'
import {setGlobalLoaderAction} from '../../redux/loader/loader.actions'

const typeOptions = [
  {value: 'alteracao_titularidade_gas', label: 'Alteração de Titularidade - Gás'},
  {value: 'alteracao_titularidade_energia', label: 'Alteração de Titularidade - Energia'},
  {value: 'pagamento_condominio', label: 'Pagamento Condomínio'},
  {value: 'pagamento_iptu', label: 'Pagamento IPTU'},
  {value: 'pagamento_aluguel', label: 'Pagamento Aluguel'},
  {value: 'vistoria_entrada', label: 'Vistoria de Entrada'},
  {value: 'vistoria_saida', label: 'Vistoria de Saída'},
  {value: 'seguro_residencia', label: 'Seguo Residencial'},
  {value: 'seguro_fianca', label: 'Seguro Fiança'},
  {value: 'seguro_caucao', label: 'Caução'},
  {value: 'outro', label: 'Outro'},
]

const DocumentArea = () => {
  const history = useHistory()

  const isOwnerPagePath = !history.location.pathname.includes('inquilino')

  const {handleSubmit, control, errors, watch, reset} = useForm()

  const property = useSelector((s) => s.property?.data)
  const tenantProperty = useSelector((s) => s.tenantProperty?.data)
  const user = useSelector((s) => s.user.user)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isObservationRequired, setIsObservationRequired] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState([])
  const [documentList, setDocumentList] = useState([])
  const [uploadedDocumentList, setUploadedDocumentList] = useState([])

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setNavbarTitleAction,
  })

  async function loadUploadedDocs(property_uid) {
    const uploadedDocuments = await firestore
      .collection('property_docs')
      .where('property_uid', '==', property_uid)
      .orderBy('created_at', 'desc')
      .get()

    let docsArray = []
    let currentDoc
    let doc

    for (doc of uploadedDocuments.docs) {
      currentDoc = doc.data()

      if (isOwnerPagePath) {
        docsArray.push(currentDoc)
      } else if (!currentDoc.is_private) {
        docsArray.push(currentDoc)
      }
    }

    setUploadedDocumentList(docsArray)
  }

  const handleSaveDocument = async (formData) => {
    const property_uid = isOwnerPagePath ? property.uid : tenantProperty.uid

    actions.setGlobalLoaderAction(true)
    if (documentList.length === 0) {
      alert('É necessário adicionar arquivos para salvar!')
      actions.setGlobalLoaderAction(false)
      return
    }
    const uploadedFiles = await firebaseFilesUploader(documentList, 'doc', `property/${property_uid}/document`)

    let isCurrentPrivate
    if (isOwnerPagePath) {
      isCurrentPrivate = formData.document.is_private ? true : false
    } else {
      isCurrentPrivate = false
    }

    const data = {
      files: uploadedFiles ?? [],
      property_uid: property_uid,
      type: formData.document.type,
      description: formData.document.description,
      user_id: user.uid,
      is_private: isCurrentPrivate,
      created_at: new Date(),
    }

    const propertyDocsRef = firestore.collection('property_docs')
    await propertyDocsRef.add(data)

    loadUploadedDocs(property.uid)
    reset()
    setDocumentList([])
    actions.setGlobalLoaderAction(false)
  }

  const onChangeFileCallback = async (uploadedFiles) => {
    const currentDocs = [...documentList, ...uploadedFiles]
    setDocumentList(currentDocs)
  }

  const onDeleteFileCallback = async (item) => {
    const filteredDocumentsArr = documentList?.filter((file) => file.name !== item.name)
    setDocumentList(filteredDocumentsArr)
  }

  const typeToLabel = (type) => {
    return typeOptions.find((option) => option.value === type)?.label ?? type
  }

  const handleOpenDocument = (doc) => {
    window.open(doc.files[0].download_url, '_blank')
  }

  const handleOpen = (doc, index) => () => {
    uploadedDocumentList[index].files.length === 1 ? handleOpenDocument(doc) : handleOpenModal(doc)
  }
  const handleOpenModal = (doc) => {
    actions.setGlobalLoaderAction(true)
    setSelectedDocument(doc.files)
    setIsModalOpen(true)
    actions.setGlobalLoaderAction(false)
  }

  const handleCloseModal = () => {
    setSelectedDocument([])
    setIsModalOpen(false)
  }

  useEffect(() => {
    actions.setNavbarTitleAction('Documentos e Comprovantes')
  }, [])

  useEffect(() => {
    const existUid = isOwnerPagePath ? property?.uid : tenantProperty?.uid
    if (existUid) {
      loadUploadedDocs(existUid)
    } else {
      console.log('(property.uid || tenantProperty.uid) vazio')
    }
  }, [property?.uid, tenantProperty?.uid])

  useEffect(() => {
    setIsObservationRequired(watch('document.type') === 'outro')
  }, [watch('document.type')])

  return (
    <Page>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1.4rem'}}>
        <Typography variant="body2">
          Todos os documentos e comprovantes relacionados ao aluguel deste imóvel devem ser enviados de acordo com a
          necessidade de renovação para cada caso. Estes documentos e comprovantes ficam disponíveis tanto para
          proprietário quanto para o inquilino.
        </Typography>
      </div>
      <form style={{display: 'flex', flexDirection: 'column', gap: '0.8rem'}}>
        <div style={{display: 'grid', gridTemplateColumns: '18rem 1fr', gap: '0.8rem'}}>
          <FormItem
            required
            control={control}
            errors={errors}
            type="select"
            name="document.type"
            label="Tipo de documento"
            options={typeOptions}
            fitContainer
            rules={{required: validateRequired}}
          />

          <FormItem
            required={isObservationRequired}
            control={control}
            errors={errors}
            fitContainer
            type="textfield"
            name="document.description"
            label="Observações"
            rules={isObservationRequired ? {validate: validateRequired} : {}}
          />
        </div>
        {isOwnerPagePath && (
          <div style={{display: 'flex'}}>
            <FormItem
              control={control}
              errors={errors}
              fitContainer
              type="checkbox-single"
              name="document.is_private"
              label="Privacidade do documento"
              options={[
                {
                  label: 'Apenas eu posso ver',
                  value: true,
                },
              ]}
            />
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'column', gap: '0.8rem'}}>
          <FilePickerMultiple
            label="Anexar arquivo"
            uid="document.files"
            filePrefix="propertyDocument_"
            section="property/document"
            list={documentList}
            displayLinks
            autoUpload={false}
            onChangeFileCallback={onChangeFileCallback}
            onDeleteFileCallback={onDeleteFileCallback}
          />
        </div>

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1.4rem'}}>
          <Button
            style={{width: 'fit-content'}}
            size="large"
            bgType="green"
            icon={CheckOutlinedIcon}
            onClick={handleSubmit(handleSaveDocument)}>
            Salvar documentos
          </Button>
        </div>
      </form>

      {uploadedDocumentList.length > 0 && (
        <>
          <Divider dashed />

          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1.4rem'}}>
            <Typography variant="body2">Documentos e comprovantes enviados</Typography>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', gap: '0.8rem'}}>
            {uploadedDocumentList.map((doc, index) => {
              return (
                <Box
                  key={index}
                  bgType="gray"
                  smallPadding
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0.8rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Typography variant="body1" style={{fontWeight: 'bold', color: '#061BCD'}}>
                      {typeToLabel(doc.type)}
                    </Typography>
                    <Typography variant="body1">
                      Enviado em: <strong>{convertDateToBr(doc.created_at)}</strong>
                    </Typography>
                    <Typography variant="body1">
                      Observação: <strong>{doc.description}</strong>
                    </Typography>
                  </div>
                  <IconWrapper onClick={handleOpen(doc, index)}>
                    <IconOpen />
                  </IconWrapper>
                </Box>
              )
            })}
          </div>
        </>
      )}

      <Modal open={isModalOpen}>
        <div
          style={{
            display: 'flex',
            width: '28rem',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2.8rem',
          }}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 0 1.4rem 0'}}>
            <Typography variant="body2">Documentos enviados .....</Typography>
          </div>

          <Divider dashed />

          {selectedDocument.map((doc, index) => (
            <Box
              key={index}
              bgType="gray"
              smallPadding
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0.8rem',
                margin: '0.8rem',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: '#061BCD'}}>
                  {doc.name_original}
                </Typography>
              </div>
              <IconWrapper onClick={() => window.open(doc.download_url, '_blank')}>
                <IconOpen />
              </IconWrapper>
            </Box>
          ))}
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1.4rem'}}>
            <Button style={{width: 'fit-content'}} size="large" bgType="green" onClick={handleCloseModal}>
              Fechar
            </Button>
          </div>
        </div>
      </Modal>
    </Page>
  )
}

export default DocumentArea
