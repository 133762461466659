import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import AddButton from '@root/components/App/Admin/ContractEditor/AddButton'
import {contractItemEntryProps} from '@root/components/App/Admin/ContractEditor/contractPropTypes.ts'
import {pathToEnumeration, xpathFinder, xpathFinderParent} from '@root/components/App/Admin/ContractEditor/xpath'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import IconClose from '@root/components/icons/IconClose'
import IconPencil from '@root/components/icons/IconPencil'
import IconPlus from '@root/components/icons/IconPlus'
import IconSaveVintage from '@root/components/icons/IconSaveVintage'
import IconTrashCan from '@root/components/icons/IconTrashCan'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import actionDispatcher from '@root/lib/actionDispatcher'
import {convertionEnumerationTable} from '@root/lib/contract/enumeration'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {
  addContractManagerAdddEntry,
  addContractManagerDeletedEntry,
  addContractManagerModifiedEntry,
  addContractManagerModifiedEnumeration,
} from '@root/redux/adminContractManager/adminContractManager.actions'

const INDENT_SIZE = 24
const ItemEntry = ({item, level, path, enableAdd, enableDelete}) => {
  const actions = actionDispatcher({
    addContractManagerAdddEntry,
    addContractManagerDeletedEntry,
    addContractManagerModifiedEntry,
    addContractManagerModifiedEnumeration,
  })

  // const raw = useSelector((s) => s.adminContract)

  const {control, errors, handleSubmit} = useForm()
  const [editing, setEditing] = useState(false)
  const [isEnumarationEditable, setEnumarationEdition] = useState(false)
  const [selectedEnumeration, setSelectedEnumeration] = useState(item.enumeration_type)

  const originalContent = item.content
  const bgColor = item.is_edited ? '#FFFEB1' : ''
  const xpath = path.join('.')

  const enumerationText = pathToEnumeration(path, false, selectedEnumeration)

  const enableEnumerationEdit = (e) => {
    e.stopPropagation()
    setEnumarationEdition(true)
  }
  const disableEnumerationEdit = (e) => {
    e.stopPropagation()
    setEnumarationEdition(false)
  }

  const enableEdit = () => {
    setEditing(true)
  }

  const cancelEdit = () => {
    setEditing(false)
  }

  const handleAdd = () => {
    actions.addContractManagerAdddEntry(xpath)
  }

  const handleDelete = () => {
    actions.addContractManagerDeletedEntry(xpath)
  }

  const handleEdit = (form) => {
    const currentContent = form.content
    const isContentUpdate = currentContent !== originalContent

    if (isContentUpdate) {
      actions.addContractManagerModifiedEntry(xpath, currentContent)
    }
    cancelEdit()
  }

  const handleEditEnumeration = (e) => {
    actions.addContractManagerModifiedEnumeration(xpath, selectedEnumeration)
    disableEnumerationEdit(e)
  }

  return (
    <Grid container direction="row" justify="space-between" style={{paddingLeft: INDENT_SIZE * level}}>
      {!editing && enableDelete && (
        <Grid container item xs={2} sm={1} direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <IconWrapper bgType="red" onClick={handleDelete}>
              <IconTrashCan />
            </IconWrapper>
          </Grid>
        </Grid>
      )}
      <Grid item xs={9} sm={10} style={{backgroundColor: bgColor}}>
        <span className="clickable" onClick={enableEnumerationEdit}>
          {enumerationText} -&nbsp;
          {isEnumarationEditable && (
            <div>
              {Object.entries(convertionEnumerationTable).map(([type, enumItem], idx) => (
                <Chip
                  key={idx}
                  clickable={true}
                  label={enumItem.label}
                  color={selectedEnumeration === type ? 'primary' : ''}
                  onClick={() => setSelectedEnumeration(type)}
                />
              ))}
              &nbsp;&nbsp;
              <IconWrapper bgType="red" onClick={disableEnumerationEdit}>
                <IconClose />
              </IconWrapper>
              &nbsp;&nbsp;
              <IconWrapper onClick={handleEditEnumeration}>
                <IconSaveVintage />
              </IconWrapper>
            </div>
          )}
        </span>

        {editing && (
          <FormItem
            control={control}
            errors={errors}
            fitContainer={true}
            type="textarea"
            name="content"
            defaultValue={item.content}
            rows={5}
            rules={{validate: validateRequired}}
          />
        )}
        {!editing && <span>{item.content}</span>}
      </Grid>

      <Grid container item direction="column" spacing={1} xs={2} sm={1} justify="space-around" alignItems="center">
        {!editing && (
          <>
            <Grid item>
              <IconWrapper onClick={enableEdit}>
                <IconPencil />
              </IconWrapper>
            </Grid>

            {enableAdd && (
              <Grid item>
                <IconWrapper onClick={handleAdd}>
                  <IconPlus />
                </IconWrapper>
              </Grid>
            )}
          </>
        )}

        {editing && (
          <>
            <Grid item>
              <IconWrapper onClick={cancelEdit}>
                <IconClose />
              </IconWrapper>
            </Grid>

            <Grid item>
              <IconWrapper onClick={handleSubmit(handleEdit)}>
                <IconSaveVintage />
              </IconWrapper>
            </Grid>
          </>
        )}
      </Grid>

      <Divider />
    </Grid>
  )
}

ItemEntry.propTypes = contractItemEntryProps
export default ItemEntry
