import {makeStyles} from '@material-ui/core/styles'
import boletoIcon from '@root/assets/boleto_icon.svg'
import BackLink from '@root/components/BackLink'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import WhatsAppButton from '@root/components/WhatsAppButton'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'
import contractMapper from 'status-mapper/mapper/contract-mapper'
import routes from '@root/utils/routes'

const useStyles = makeStyles({
  boletos: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'flexDirection': 'column',
    'height': 'calc(100vh - 65px)',
    'width': '100%',
    'maxWidth': '500px',
    'margin': '56px auto 0',
    'padding': '0 15px',
    '& header': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'margin': '15px 0',
      '& h1': {
        marginLeft: '10px',
        fontSize: '1.5rem',
        textAlign: 'center',
      },
    },
    '& > p': {
      margin: '0 0 20px',
    },
  },
  gerarBoletosWrapper: {
    'marginBottom': '20px',
    'width': '200px',
    '& button': {
      'width': '100%',

      '& p': {
        fontSize: '20px',
      },
    },
  },
  backLinkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
})

const Boletos = () => {
  const classes = useStyles()

  const history = useHistory()

  const {getParam, hasParam} = useQuery()
  const propertyUid = getParam('uid')
  const property = useSelector((s) => s.property.data)

  const actions = actionDispatcher({
    setHeaderMainHeader,
    setFooterNotVisible,
    setCurrentProperty,
  })

  actions.setHeaderMainHeader()
  actions.setFooterNotVisible()

  useEffect(() => {
    const load = async () => {
      if (hasParam('uid')) {
        const uid = getParam('uid')
        const propertyDb = await propertyModel.getById(uid)
        if (propertyDb.exists) {
          actions.setCurrentProperty(propertyDb.data())
        }
      }
    }
    load()
  }, [])

  if (contractMapper.assertStatus.isInProgress(property?.contract?.status)) {
    history.push(`${routes.DASHBOARD_BOLETOS_STATUS}?uid=${propertyUid}`)
  }

  return (
    <div className={classes.boletos}>
      <header>
        <img src={boletoIcon} alt="Icon" />
        <h1>Boletos</h1>
      </header>

      <p>
        O modo mais fácil e seguro de receber o pagamento do seu aluguel é utilizando boletos automáticos.
        <br />
        Aqui na Moben você consegue contratar esse serviço em apenas alguns cliques.
      </p>

      <div className={classes.gerarBoletosWrapper}>
        <ButtonTransparentBg onClick={() => history.push(`${routes.DASHBOARD_BOLETO_FORM}?uid=${propertyUid}`)}>
          Gerar Boletos
        </ButtonTransparentBg>
      </div>

      <p>Se você precisa de ajuda com a geração dos boletos entre em contato sem compromisso. </p>

      <WhatsAppButton message="Preciso de ajuda com Boletos" />

      <div className={classes.backLinkWrapper}>
        <BackLink />
      </div>
    </div>
  )
}

export default Boletos
