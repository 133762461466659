import Grid from '@material-ui/core/Grid'
import {useBankRepoStatement} from '@root/application/service/repository/bank/BankRepository'
import {Divider} from '@root/components/Divider/Divider'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import Title from '@root/components/Layout/Typography/Title'
import Page from '@root/components/Page'
import {useQuery} from '@root/hooks/urlHook'
import {isDevEnv} from '@root/lib/envDetector'
import StatmentInfo from '@root/pages/BankStatement/StatmentInfo'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

const BankStatement = () => {
  const dispatch = useDispatch()
  const userId = useQuery().getParam('user_id', undefined)

  const bankStatement = useBankRepoStatement(userId)
  const hasEntries = bankStatement.response.length > 0

  const [statements, setStatements] = useState([])

  useEffect(() => {
    setStatements(bankStatement.response)
  }, [bankStatement.response])

  useEffect(() => {
    dispatch(setNavbarTitleAction('Extrato'))
  }, [])

  return (
    <Page loading={bankStatement.isLoading} showContent={hasEntries}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={9}>
          {statements.map((statement, key) => (
            <>
              <Grid key={key} container direction="column" spacing={1} sm={12}>
                <Grid item xs={12}>
                  <Title>
                    {statement.date} <small>({statement.weekday})</small>
                  </Title>
                </Grid>
                <Grid container item xs={12} spacing={1} style={{paddingLeft: 20}}>
                  {statement.entries.map((entry) =>
                    entry.entries.map((entry, key) => (
                      <Grid key={key} container item xs={12} direction="row">
                        <Grid container item xs={10} justify="space-between">
                          <Grid item xs={12}>
                            <Text>{entry.description}</Text>
                          </Grid>
                          <Grid item style={{paddingLeft: 16}}>
                            {entry.entries.map((extraEntry, extraKey) => (
                              <p key={extraKey}>
                                <Text>{extraEntry.description}</Text>
                              </p>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={2} style={{textAlign: 'right'}}>
                          <TextSecondary color={entry.color}>{entry.currency}</TextSecondary>
                        </Grid>
                      </Grid>
                    )),
                  )}
                </Grid>
              </Grid>
              <Divider m="22px 0" />
            </>
          ))}
        </Grid>
        <Grid container sm={3}>
          <StatmentInfo />
        </Grid>
      </Grid>
    </Page>
  )
}

BankStatement.propTypes = {}
BankStatement.defaultProps = {}

export default BankStatement
