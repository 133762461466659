import Icon from '@material-ui/core/Icon'
import Radio from '@material-ui/core/Radio'
import PropTypes from 'prop-types'
import React from 'react'

const ButtonIcon = ({title}) => (
  <Icon
    style={{
      backgroundColor: '#8E8B8B',
      color: '#FFF',
      width: '100%',
      height: '100%',
      padding: '4px 8px',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
    }}>
    <span style={{fontSize: '1.2rem', fontWeight: '600'}}>{title}</span>
  </Icon>
)

const ButtonIconChecked = ({title}) => (
  <Icon
    style={{
      backgroundColor: '#0101CD',
      color: '#FFF',
      width: '100%',
      height: '100%',
      padding: '4px 8px',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
    }}>
    <span style={{fontSize: '1.2rem', fontWeight: '600'}}>{title}</span>
  </Icon>
)

const RadioItem = (props) => {
  if (props.hide) {
    return null
  }
  return (
    <Radio
      style={{backgroundColor: 'transparent', ...props.otherRadioItemStyles}}
      value={props.value}
      checked={props.checked}
      icon={<ButtonIcon title={props.title} />}
      checkedIcon={<ButtonIconChecked title={props.title} />}
      disableRipple
    />
  )
}

RadioItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  style: PropTypes.object,
  hide: PropTypes.bool,
  otherRadioItemStyles: PropTypes.object,
}

ButtonIcon.propTypes = {
  title: PropTypes.string.isRequired,
}

ButtonIconChecked.propTypes = {
  title: PropTypes.string.isRequired,
}

export default RadioItem
