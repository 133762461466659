import {actionsTranslate, pathToEnumeration} from '@root/components/App/Admin/ContractEditor/xpath'
import {Divider} from '@root/components/Divider/Divider'
import {convertDateToBr} from 'date-parser'
import React from 'react'

const ContractHistoryList = ({history}) => {
  return history.map((entry, idx) => (
    <div key={idx}>
      <span>
        <strong>Alterado por:</strong> {entry.edited_by}
      </span>
      <br />
      <span>
        <strong>Em:</strong> {convertDateToBr(entry.created_at, true)}
      </span>
      <br />
      <span>
        <strong>Local:</strong> {pathToEnumeration(entry.xpath, true)}
      </span>
      <br />
      <span>
        <strong>Ação:</strong> {actionsTranslate(entry.action)}
      </span>
      <br />
      <Divider dashed={true} />
    </div>
  ))
}

export default ContractHistoryList
