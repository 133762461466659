import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import React from 'react'

const StatmentInfo = () => {
  return (
    <Box
      style={{
        position: 'sticky',
        top: 150,
        height: 550,
      }}>
      <p>
        <Text bold>Pagamento do aluguel</Text>
        <br />
        <Text>Data de pagamento, no caso do Boleto pode levar até 3 dias úteis para a compensação.</Text>
      </p>
      <br />

      <p>
        <Text bold>Compensação</Text>
        <br />
        <Text>É quando efetivamente o pagamento fica disponível para ser transferido para a sua conta bancária.</Text>
      </p>
      <br />

      <p>
        <Text bold>Transferência</Text>
        <br />
        <Text>Quando o valor pago pelo inquilino é transferido para a sua conta bancária.</Text>
      </p>
      <br />

      <p>
        <Text bold>Custo da transferência</Text>
        <br />
        <Text>
          Custo bancario para a realização da transferência para a sua conta bancária, para compesar esse custo a Moben
          já faz o desconto de R$ 3,67 dentro da Mensalidade.
        </Text>
      </p>
      <br />

      <p>
        <Text bold>Mensalidade</Text>
        <br />
        <Text>Valor referente a mensalidade Moben.</Text>
      </p>
      <p></p>
    </Box>
  )
}

StatmentInfo.propTypes = {}
StatmentInfo.defaultProps = {}

export default StatmentInfo
