import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import UserAvatar from '@root/components/UserAvatar'
import analyticsLogger from '@root/firebase/analytics.utils'
import routes, {routeWithParams} from '@root/utils/routes'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div:not(:last-child)': {
      marginBottom: '10px',
    },
  },
  avatarNames: {
    'position': 'relative',
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    '& > div:last-child': {
      'display': 'flex',
      'flexDirection': 'column',
      'marginLeft': theme.margin[2],
      '& p': {
        lineHeight: '18px',
        letterSpacing: '0.07px',
        fontWeight: 600,
      },
      '& > p:last-child': {
        marginTop: 0,
        lineHeight: '20px',
        fontWeight: 400,
      },
    },
  },
  avatarGroup: {
    '& .MuiAvatarGroup-avatar': {
      border: 0,
    },
    '& > div + div': {
      marginLeft: '-18px',
      zIndex: '3 !important',
    },
  },
}))

export function ProposalsListItem({index, selectedProposalId, id, proposalName, interesteds, onClick}) {
  const classes = useStyles()
  const history = useHistory()

  const isSelectedProposal = selectedProposalId === id
  const proposalFinalName = proposalName ? proposalName : `Proposta ${index + 1}`

  const goToProposal = () => {
    analyticsLogger.logEvent('lista_propostas_abrir', {})
    history.push(`${routeWithParams(routes.DASHBOARD_CREDIT_ANALYSIS, id)}?name=${proposalFinalName}`)
  }

  const handleClick = () => {
    if (!onClick) {
      goToProposal()
    } else {
      onClick(id)
    }
  }

  return (
    <div className={classes.root}>
      <Box type="withCheckBox" isChecked={isSelectedProposal} smallPadding h="64px" m="0 0 10px" onClick={handleClick}>
        <div className={classes.avatarNames}>
          <AvatarGroup max={4} className={classes.avatarGroup}>
            {interesteds.map((user) => (
              <UserAvatar key={user.uid} user={user} />
            ))}
          </AvatarGroup>
          <div>
            <Typography variant="body1" color="secondary">
              {proposalFinalName}
            </Typography>
            <Typography variant="body1">{interesteds.map((interested) => interested.name).join(', ')}</Typography>
          </div>
        </div>
        <IconWrapper isAbsolutePositionRightCenter>
          <LaunchOutlinedIcon />
        </IconWrapper>
      </Box>
    </div>
  )
}

ProposalsListItem.defaultProps = {
  interesteds: [],
}
