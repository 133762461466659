import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {Button} from '@root/components/Button/Button'
import IconEye from '@root/components/icons/IconEye'
import Loader from '@root/components/Loader/Loader'
import LocalLoader from '@root/components/Loader/LocalLoader'
import PageMini from '@root/components/PageMini'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import actionDispatcher from '@root/lib/actionDispatcher'
import Clipboard from '@root/lib/Clipboard'
import {convertDateToBr} from '@root/lib/dateParser'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import propertyModel from '@root/model/propertyModel'
import PaymentAreaBoleto from '@root/pages/Payment/PaymentAreaBoleto'
import PaymentAreaPix from '@root/pages/Payment/PaymentAreaPix'
import * as PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import {setGlobalLoaderAction, setGlobalLoaderWithModeAction} from '@root/redux/loader/loader.actions'
import paymentMethodMapper from 'status-mapper/mapper/payment-method-mapper'
import {LOADER_MODE_TEXT, MOBEN_WHATSAPP} from '@root/utils/constants'

const PAGE_TITLE = 'Seu aluguel em dia!'
const WA_HELP_MESSAGE = `Preciso de ajuda com o pagamento por PIX.\nEndereço da pagina: ${window.location.href}`

const createCopyButton = (label, isCopied) => {
  return {
    label: label.toUpperCase(),
    icon: isCopied ? CheckCircleOutlineIcon : FileCopyIcon,
  }
}
const BUTTON_COPY_COPIED = createCopyButton('Copiado', true)
// parametros para teste: eyJwcm9wZXJ0eSI6IjJKMHRaUDN4NDRTRm84MzBUcERCIiwicGF5bWVudCI6IjFmZjVjNDdkY2VkZDk1In0=
const PaymentView = ({match}) => {
  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
  })

  const queryParams = useMemo(() => {
    try {
      return JSON.parse(atob(match.params.encodedParams))
    } catch (err) {
      return {
        error: 'Dados inválidos',
      }
    }
  }, [])

  const [buttonCopy, setCopy] = useState('')
  const [paymentData, setPayData] = useState()
  const [errors, setErrors] = useState()
  console.log('errors', errors)

  const propertyId = queryParams.property
  const paymentId = queryParams.payment

  const payment = paymentData?.payment ?? {}
  const isPaid = payment.is_paid
  const property = paymentData?.property ?? {}
  const owner = paymentData?.owner ?? {}
  const tenant = paymentData?.tenant ?? {}

  const paymentCode = payment.copy_code ?? ''
  const dueDate = payment.due_date ?? ''

  const isBoleto = paymentMethodMapper.assertStatus.isBoleto(payment.method ?? '')
  const isPix = paymentMethodMapper.assertStatus.isPix(payment.method ?? '')

  const layoutConfig = useMemo(() => {
    return {
      direction: isPix ? 'row' : 'column',
      spacing: isPix ? 0 : 2,
      imageBlockSize: isPix ? 5 : 12,
      payerBlockSize: isPix ? 7 : 12,
      alignButton: isPix ? '' : 'center',
    }
  }, [isBoleto, isPix])

  const loadPaymentData = async () => {
    actions.setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, {
      title: 'Consultando pagamento',
      subtitle: 'Aguarde enquanto obtemos as informações sobre o pagamento',
    })
    try {
      const result = await propertyModel.fetchPaymentData(propertyId, paymentId)
      setPayData(result)
      setCopy(createCopyButton(result.payment.button_label))
    } catch (ex) {
      setErrors(ex)
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const handleCopyCode = () => {
    Clipboard.copy(paymentCode)
    setCopy(BUTTON_COPY_COPIED)
    setTimeout(() => setCopy(createCopyButton(paymentData.payment.button_label)), 600)
  }

  useEffect(() => {
    if (paymentId && propertyId) {
      loadPaymentData()
    }
  }, [])

  if (!paymentData && !errors) {
    return null
  }

  if (errors) {
    return (
      <PageMini navtitle={PAGE_TITLE}>
        <br />
        <Typography variant="h5" style={{textAlign: 'center'}}>
          Ops! Não encontramos o pagamento.
        </Typography>
        {errors.map((err, index) => (
          <Typography key={index} variant="body2" style={{textAlign: 'center'}}>
            {err}
          </Typography>
        ))}
        <br />
      </PageMini>
    )
  }

  if (isPaid) {
    return (
      <PageMini navtitle={PAGE_TITLE}>
        <Typography variant="h5" style={{textAlign: 'center'}}>
          Boa noticia!!
        </Typography>
        <br />
        <Typography variant="body2" style={{textAlign: 'center'}}>
          O aluguel referente ao imóvel situado na {property.address} com vencimento em {dueDate} está pago.
        </Typography>
      </PageMini>
    )
  }

  return (
    <PageMini navtitle={PAGE_TITLE}>
      <Typography variant="body2" style={{textAlign: 'center'}}>
        Aluguel referente ao imóvel situado na {property.address}
      </Typography>
      <br />
      <Grid container direction={layoutConfig.direction} spacing={2}>
        <Grid
          container
          spacing={layoutConfig.spacing}
          item
          xs={12}
          sm={layoutConfig.imageBlockSize}
          alignItems={layoutConfig.alignButton}
          direction="column">
          {isPix && (
            <PaymentAreaPix
              payment={payment}
              onCopyCode={handleCopyCode}
              buttonLabel={buttonCopy.label}
              buttonIcon={buttonCopy.icon}
            />
          )}
          {isBoleto && (
            <PaymentAreaBoleto
              payment={payment}
              onCopyCode={handleCopyCode}
              buttonLabel={buttonCopy.label}
              buttonIcon={buttonCopy.icon}
            />
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={layoutConfig.payerBlockSize}
          direction="column"
          spacing={0}
          justify="space-between"
          style={{paddingTop: 20, paddingBottom: 10}}>
          <Grid item>
            <Typography variant="h5" color="secondary">
              {owner.name}
            </Typography>
            {owner.cpf}
          </Grid>
          <Grid item>
            <Typography variant="h5" color="secondary">
              {tenant.name}
            </Typography>
            {tenant.cpf}

            <Grid container item spacing={0} direction="row">
              <Grid item xs={6}>
                <ListItemText primary="Vencimento" secondary={dueDate} />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary="Valor a pagar" secondary={payment.amount} />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary="Multa" secondary={payment.fine} />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary="Juros por dia de atraso" secondary={payment.interest} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <WhatsAppLinkWrapper phone={MOBEN_WHATSAPP} message={WA_HELP_MESSAGE}>
              <Typography variant="body2">
                Precisa de ajuda? Clique aqui e fale com a Moben atraves do nosso WhatsApp
              </Typography>
            </WhatsAppLinkWrapper>
          </Grid>
        </Grid>
      </Grid>
    </PageMini>
  )
}

export default PaymentView
