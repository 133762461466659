// Material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import useStyles from './styles'

const BannerSection = () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.banner}>
      <div className={classes.leftPinkDiv}></div>
      <div className={classes.rightPinkDiv}></div>
      <Grid item className={classes.photoContainer}>
        <div className={classes.blackOverlayDiv} />
        <div className={classes.contentDiv}>
          <Typography variant="h4" className={classes.bannerTitle}>
            Acompanhe todos os detalhes do seu aluguel
          </Typography>
          <Typography variant="h6" className={classes.bannerSubtitle}>
            Uma ferramenta pensada exclusivamente para a relação entre inquilino e proprietário.
          </Typography>
          <Typography variant="h6" className={classes.bannerSubtitle}>
            Análise de crédito, contrato sob medida, envio de boletos e muito mais em um único painel de controle
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default BannerSection
