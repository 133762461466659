import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {Box} from '@root/components/Layout/Container/Box/Box'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBtnWrapper: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'width': '100%',
    'marginBottom': '20px',
    'background': 'transparent',
    'cursor': 'pointer',
    '& span': {
      color: 'rgb(1, 1, 205) !important',
      textDecoration: 'underline',
      fontSize: '15px',
    },
  },
  documentNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  documentName: {
    maxWidth: '270px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: `${theme.extraColors.BLACK} !important`,
    textDecoration: 'underline !important',
  },
}))

const getField = (filePrefix) => {
  const filePrefixArray = filePrefix.split('')
  filePrefixArray.splice(-1, 1)
  return filePrefixArray.join('')
}

const FilePickerMultiple = ({
  label,
  filePrefix,
  uid,
  section,
  id,
  list = [],
  displayLinks = false,
  autoUpload = true,
  onChangeFileCallback,
  onDeleteFileCallback,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputRef = useRef()

  const idProp = id ?? getField(filePrefix)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  })

  const handleFileOnChange = async (evt) => {
    if (evt.target.files.length > 0) {
      const files = Array.from(evt.target.files)

      const filteredFiles = files.filter((file) => !list.some((listFile) => listFile.name_original === file.name))

      if (!filteredFiles.length) return

      if (!autoUpload) {
        await onChangeFileCallback(filteredFiles)
        return
      }

      dispatch(setGlobalLoaderAction(true))

      const uploadedFiles = await firebaseFilesUploader(filteredFiles, filePrefix + uid, section)

      await onChangeFileCallback(uploadedFiles, filePrefix)

      dispatch(setGlobalLoaderAction(false))
    }
  }

  const handleDeleteFile = async (item) => {
    if (!autoUpload) {
      let newList = removeFileFromList(item)
      await onDeleteFileCallback(item, filePrefix, newList)
      return
    }

    dispatch(setGlobalLoaderAction(true))

    await onDeleteFileCallback(item.download_url, filePrefix)

    dispatch(setGlobalLoaderAction(false))
  }

  const removeFileFromList = (file) => {
    const tmpList = [...list]
    const idx = tmpList.indexOf(file)
    tmpList.splice(idx, 1)

    return tmpList
  }

  return (
    <>
      <div className={classes.uploadContainer}>
        <label htmlFor={idProp} className={classes.uploadBtnWrapper}>
          <span>{label}</span>
          <input
            ref={inputRef}
            onChange={handleFileOnChange}
            type="file"
            id={idProp}
            accept="application/pdf,image/*"
            multiple
            capture
            style={{display: 'none'}}
          />
        </label>
      </div>

      {displayLinks && list?.length ? (
        <Box bgType="gray">
          {list.map((item) => {
            const title = !autoUpload ? item.name : item.name_original
            const download_url = !autoUpload ? item.name : item.download_url
            return (
              <div key={download_url} className={classes.documentNameWrapper}>
                <a target="_blank" rel="noreferrer" href={download_url} className={classes.documentName}>
                  {title}
                </a>
                <IconButton aria-label="deletar" color="primary" size="small" onClick={() => handleDeleteFile(item)}>
                  <DeleteOutlineIcon fontSize="small" style={{color: 'red'}} />
                </IconButton>
              </div>
            )
          })}
        </Box>
      ) : null}
    </>
  )
}

FilePickerMultiple.defaultProps = {
  label: 'Carregar Documentos',
}

export default FilePickerMultiple
