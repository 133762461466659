import update from 'immutability-helper'
import React, {useCallback, useMemo} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

import PhotoContainer from './PhotoContainer'

const AdFormPhotosContainer = ({
  classes,
  adPhotos,
  setAdPhotos,
  photosToDisplay,
  totalPhotosToDisplay,
  handlePhotoOnClick,
  getPhotoClassName,
  handleMostrarFotosOnClick,
  isMovePhotosMode,
}) => {
  const movePhotoContainer = useCallback((dragIndex, hoverIndex) => {
    setAdPhotos((prevPhotos) =>
      update(prevPhotos, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevPhotos[dragIndex]],
        ],
      }),
    )
  }, [])

  const renderPhotoContainer = useCallback((photo, index, getPhotoClassName, handlePhotoOnClick) => {
    return (
      <PhotoContainer
        key={photo.download_url}
        id={photo.download_url}
        photo={photo}
        index={index}
        getPhotoClassName={getPhotoClassName}
        handlePhotoOnClick={handlePhotoOnClick}
        movePhotoContainer={movePhotoContainer}
      />
    )
  }, [])

  const memoizedAdPhotos = useMemo(() => adPhotos, [adPhotos])

  return (
    <div className={classes.photosContainer}>
      {isMovePhotosMode ? (
        <>
          <DndProvider backend={HTML5Backend}>
            {memoizedAdPhotos.length
              ? memoizedAdPhotos.map((photo, index) =>
                  renderPhotoContainer(photo, index, getPhotoClassName, handlePhotoOnClick, isMovePhotosMode),
                )
              : null}
          </DndProvider>
        </>
      ) : (
        <>
          {memoizedAdPhotos.length
            ? memoizedAdPhotos.slice(0, totalPhotosToDisplay).map((photo, idx) => (
                <div key={photo.download_url} index={idx} id={photo.download_url}>
                  <div
                    name={photo.download_url}
                    className={getPhotoClassName(photo)}
                    style={{
                      backgroundImage: `url(${photo.download_url})`,
                    }}
                    onClick={() => handlePhotoOnClick(photo.download_url, idx)}
                  />
                </div>
              ))
            : null}

          {memoizedAdPhotos.length > photosToDisplay ? (
            <div className={classes.mostrarTodas} onClick={handleMostrarFotosOnClick}>
              {totalPhotosToDisplay === photosToDisplay ? <span>ver todas...</span> : <span>ver menos</span>}
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default AdFormPhotosContainer
