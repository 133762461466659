import Typography from '@material-ui/core/Typography'
import {isSameDay} from 'date-fns'
import dateParser, {convertDateToBr} from 'date-parser'
import React, {useMemo} from 'react'

import {Box} from '../../../Layout/Container/Box/Box'
import {useStyles} from '../../styles'

const legendColors = ['#BADD48', '#FF3838', '#831CCD', '#FFD667', '#C3C3C3']
const contractStatusChartLegend = ['Pago', 'Atrasado', 'Boleto emitido', 'Aguardando compensação', 'Aguardando emissão']
const contractStatusChartLegendStyles = {
  display: 'inline-block',
  width: 10,
  height: 10,
  borderRadius: '50%',
  marginRight: 5,
}

const monthTagStatusStyles = {
  width: 14,
  height: 14,
  borderRadius: '50%',
}

const ContractStatus = ({payments, pendingPayments}) => {
  const classes = useStyles()

  const sortedPayments = useMemo(() => {
    const pays = payments ?? []
    return pays
      .map((payment) => ({due_date: dateParser(payment.due_date), status: payment.status}))
      .sort((p1, p2) => (p1.due_date > p2.due_date ? 1 : -1))
  }, [payments])

  const getStyle = (idx) => {
    if (sortedPayments[idx].status === 'paid') {
      return {...monthTagStatusStyles, backgroundColor: legendColors[0], border: `1px solid ${legendColors[0]}`}
    }

    if (
      pendingPayments.length &&
      pendingPayments.some((p) => isSameDay(dateParser(p.due_date), sortedPayments[idx].due_date))
    ) {
      return {...monthTagStatusStyles, backgroundColor: legendColors[1], border: `1px solid ${legendColors[1]}`}
    }

    if (sortedPayments[idx].status === 'waiting_payment' && new Date() < sortedPayments[idx].due_date) {
      return {...monthTagStatusStyles, backgroundColor: legendColors[2], border: `1px solid ${legendColors[2]}`}
    }

    if (sortedPayments[idx].status === 'waiting_payment')
      return {...monthTagStatusStyles, backgroundColor: legendColors[3], border: `1px solid ${legendColors[3]}`}

    return {...monthTagStatusStyles, backgroundColor: 'transparent', border: `1px solid ${legendColors[4]}`}
  }

  return (
    <Box m="10px 0 0" bgType="white" smallPadding>
      <Typography variant="h5" align="center" color="primary">
        STATUS DO CONTRATO
      </Typography>

      <div className={classes.contractStatusMonthsWrapper}>
        {sortedPayments.map((payment, idx) => (
          <div key={idx}>
            <span style={getStyle(idx)} />
            <span>{convertDateToBr(payment.due_date, false, 'month_year')}</span>
          </div>
        ))}
      </div>

      <div className={classes.contractStatusChartLegendContainer}>
        {contractStatusChartLegend.map((legend, idx) => (
          <div key={idx}>
            <div>
              <span
                style={{
                  ...contractStatusChartLegendStyles,
                  border: `1px solid ${legendColors[idx]}`,
                  backgroundColor: idx === contractStatusChartLegend.length - 1 ? 'transparent' : legendColors[idx],
                }}
              />
            </div>
            <span>{legend}</span>
          </div>
        ))}
      </div>
    </Box>
  )
}

export default ContractStatus
