/** @module storage */
const save = (key, data) => {
  const json = JSON.stringify(data)
  localStorage.setItem(key, json)
}

/**
 * @param key
 * @param defaultValue NAO pode usar `undefined` aqui
 * @return {{}|any}
 */
const load = (key, defaultValue = {}) => {
  const jsonStr = localStorage.getItem(key)
  if (jsonStr) {
    const json = JSON.parse(jsonStr)
    return json
  } else {
    return defaultValue
  }
}

const remove = (key) => {
  localStorage.removeItem(key)
}

const clear = () => {
  localStorage.clear()
}

/**
 * @param {string[]} keysToPreserve
 */
const clearExcept = (keysToPreserve) =>
  Object.keys(localStorage)
    .filter((keyName) => {
      const needRemoval = keysToPreserve.indexOf(keyName) === -1
      return needRemoval
    })
    .forEach(remove)

const storage = {
  save,
  load,
  remove,
  clear,
  clearExcept,
}

export default storage
