import {getInviteFiador, getInviteTenant} from '@root/lib/inviteLinkCreator'
import uidGenerator from '@root/lib/uidGenerator'
import {useState} from 'react'

const useShareInvite = () => {
  const [isOpen, setShareVisibility] = useState(false)
  return {
    isOpen,
    open: () => setShareVisibility(true),
    close: () => setShareVisibility(false),

    // -------------
    getTenantGlobalWithAnalysis: (propertyId, proposalId) => getInviteTenant(propertyId, undefined, true),
    getTenantGlobalNoAnalysis: (propertyId, proposalId) => getInviteTenant(propertyId, undefined, false),
    getTenantGroupWithAnalysis: (propertyId, proposalId) => getInviteTenant(propertyId, proposalId),
    getTenantGroupNoAnalysis: (propertyId, proposalId) => getInviteTenant(propertyId, proposalId, false),
  }
}

export default useShareInvite
