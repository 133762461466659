/** @module specsDisplay */
import pluralize from '@root/lib/intl/pluralize'

const specsDisplay = (property) => {
  if (!property) return ''

  const {bathrooms, footage, garage, rooms} = property.specs

  const garageNumber = `${garage} ${pluralize(garage, 'garagem')}`
  const roomNumber = `${rooms} ${pluralize(rooms, 'quarto')}`
  const bathroomNumber = `${bathrooms} ${pluralize(bathrooms, 'banheiro')}`

  return `${footage}m\u00B2, ${roomNumber}, ${bathroomNumber}, ${garageNumber}`
}

export default specsDisplay
