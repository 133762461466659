import {SigninTypes} from '@root/redux/signin/signin.types'

import {ProposalActionTypes} from './proposal.types'

const initialState = {
  propertyRef: undefined,
  property: {},
  proposals: {},
  selected_proposal_uid: undefined,
  view_proposal: undefined,
}

const proposalReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SigninTypes.LOG_OUT:
      return {...initialState}

    case ProposalActionTypes.SET_PROPOSALS_WITH_TENANTS:
      return {
        ...state,
        propertyRef: payload.propertyRef,
        property: payload.propertyRef.data(),
        proposals: payload.proposals,
      }

    case ProposalActionTypes.SET_SELECTED_PROPOSAL:
      return {
        ...state,
        selected_proposal_uid: payload.proposalUID,
      }
    case ProposalActionTypes.SET_VIEW_PROPOSAL:
      return {
        ...state,
        view_proposal: payload.proposalUID,
      }

    default:
      return state
  }
}

export default proposalReducer
