import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  floatingBg: {
    width: '300px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    boxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)',
    borderRadius: '0.8rem',
    padding: '0.8rem',
  },
  buttonHelper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1.4rem',
    height: '1.4rem',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '1px solid #061BCD',
    color: '#061BCD',
  },
}))
