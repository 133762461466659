import DateFnsUtils from '@date-io/date-fns'
import TextField from '@material-ui/core/TextField'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import React from 'react'

const isValidDate = (dateValue) => {
  return dateValue instanceof Date && !isNaN(dateValue.getTime())
}

// https://material-ui-pickers.dev/api/KeyboardDatePicker
const DatePickerRender = (controllerProps, helpers, formItemProps) => {
  const {hasError, applyShrink, forwardOnChange, forwardOnBlur} = helpers
  const {value} = controllerProps
  const {onFocus, label} = formItemProps

  let curValue = value || formItemProps.value
  if (!curValue) {
    curValue = null
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        clearable
        inputRef={helpers.inputRef}
        error={hasError}
        label={label}
        variant="inline"
        mask="__/__/____"
        maskChar="_"
        format="dd/MM/yyyy"
        margin="normal"
        invalidDateMessage={null}
        autoOk={true}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...formItemProps}
        onChange={forwardOnChange}
        onBlur={forwardOnBlur}
        onFocus={onFocus}
        // inputValue={curValue}
        value={curValue}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePickerRender
