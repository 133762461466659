import fetcher from '@root/application/infra/api/fetcher'
import userModel from '@root/model/userModel'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setFormInitial} from '@root/redux/rdstation/rdstation.actions'

const useRD = () => {
  const user = useSelector((s) => s.user.user)
  const formInitial = useSelector((s) => s.rdstation.formInitial)

  const forms = user?.marketing?.rd?.form ?? {}
  const dispatch = useDispatch()

  /**
   * @param {string} formName
   * @param {string[]} checkFields
   * @return boolean
   */
  const hasFilledFieldsHandler = (formName, checkFields) => {
    const formFields = forms[formName] ?? {}
    const availableKeys = Object.keys(formFields)
    return checkFields.some((field) => availableKeys.includes(field))
  }

  /**
   * @param {string} userId
   * @param {string} formName
   * @param {{name:string, value:string|string[]|number|boolean}[]} fields
   * @returns {*}
   */
  const saveFieldsHandler = async (userId, formName, fields) => {
    let tmpFields = fields

    if (!Array.isArray(fields)) {
      tmpFields = prepareFieldsFromForm(fields)
    }

    await fetcher
      .post('/rdstation/form', {
        user_id: userId,
        fields: tmpFields,
      })
      .catch(() => undefined)

    await userModel.saveRdForm(user.uid, formName, fields).catch(() => undefined)
  }

  const setFormInitialVisibility = (visibility) => {
    dispatch(setFormInitial(visibility))
  }

  const prepareFieldsFromForm = (formData) => {
    return Object.entries(formData)
      .filter(([, value]) => value)
      .map(([name, value]) => ({name, value}))
  }

  useEffect(() => {
    setFormInitialVisibility(
      hasFilledFieldsHandler('initial', [
        'cf_voce_e_proprietario_de_de_imovel_de_aluguel',
        'cf_sobre_a_administracao_do_seu_imovel',
        'cf_quanto_a_ocupacao_do_seu_imovel',
      ]),
    )
  }, [])

  return {
    hasFilledFields: (formName, fields) => hasFilledFieldsHandler(formName, fields),
    sendFields: (formName, fields) => saveFieldsHandler(user.uid, formName, fields),
    prepareFieldsFromForm,
    isFilledFormInitial: formInitial,
    setFormInitialVisibility,
  }
}

export default useRD
