import HttpApiInterface from '@root/application/domain/HttpApiInterface'
import firebase from '@root/firebase/firebase.utils'
import {branchToEndpoint} from '@root/lib/branch'
import {BASE_URL_API} from '@root/utils/constants'

const removeInitialSlashRegex = /^\//

export class MobenAPI extends HttpApiInterface {
  /** @var {string} */
  _token = ''

  constructor() {
    super({baseUrl: BASE_URL_API})
  }

  async _retrieveToken() {
    if (!this._token) {
      const token = await firebase.auth().currentUser?.getIdToken()
      this._token = token
    }
    return this._token
  }

  async request(method, endpoint, body = undefined) {
    await this._retrieveToken()

    const finalEp = endpoint.replace(removeInitialSlashRegex, '')
    const slash = '/'
    const url = this.baseUrl + slash + branchToEndpoint(finalEp)

    const requestOptions = {
      method,
      // credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this._token}`,
        // como a api passa pelo api gateway da aws, o Authorization fica "reservado" para uso interno la, entao achei essa "alternativa" na docs deles. Passthrough nao funcionou diga-se de passagem
        // https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-known-issues.html
        'X-Amzn-Remapped-Authorization': `Bearer ${this._token}`,
      },
    }

    if (body) {
      const jsonSend = JSON.stringify(body)
      requestOptions.body = jsonSend
      requestOptions.headers['content-length'] = jsonSend.length
    }

    return new Promise((resolve, reject) => {
      fetch(url, requestOptions)
        .then((resp) => {
          resp.json().then((json) => {
            if (resp.ok) {
              resolve(json)
            } else {
              reject(json)
            }
          })
        })
        .catch((err) => {
          this.logRequestError(err, url, requestOptions)
          reject(['Ops! Não foi possível completar a requisição.\n' + err])
        })
    })
  }
}

export const mobenAPIInstance = new MobenAPI()
