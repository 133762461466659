import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ActiveContracts from '@root/components/App/Admin/ActiveContracts/ActiveContracts'
import ContractManager from '@root/components/App/Admin/ContractManager'
import Dashboard from '@root/components/App/Admin/Dashboard/Dashboard'
import EmailsList from '@root/components/App/Admin/EmailsList'
import Payments from '@root/components/App/Admin/Payments'
import PropertyDetail from '@root/components/App/Admin/Property/PropertyDetail'
import SSRDash from '@root/components/App/Admin/SSRDash'
import UserDetail from '@root/components/App/Admin/UserDetail'
import UsersList from '@root/components/App/Admin/UsersList'
import {AvatarBadge} from '@root/components/Header/AvatarBadge'
import {Navbar} from '@root/components/Navbar/Navbar'
import {
  adminPathActiveContracts,
  adminPathAds,
  adminPathBuilder,
  adminPathEmails,
  adminPathHome,
  adminPathPayments,
} from '@root/lib/admin/adminPathHelper'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, Route, Switch, useLocation} from 'react-router-dom'

import useStyles from './styles'

// http://localhost:3000/admin/a7Hcd2jsEwS849xKSgEDXL/usuarios
const Admin = () => {
  const classes = useStyles()
  const navbar = useSelector((s) => s.application.navbar)
  const pathname = useLocation().pathname

  const [isActive, setIsActive] = useState('usuarios')

  useEffect(() => {
    const path = pathname.split('/')
    setIsActive(path[3])
  }, [pathname])

  return (
    <>
      <AppBar component="nav" position="fixed" className={classes.appBar}>
        <Toolbar>
          <LogoElement classes={classes} />
          <Typography variant="h6" color="primary">
            admin
          </Typography>
          <div className={classes.headerMenuContainer}>
            <Link to={adminPathHome}>
              <Typography variant="h5" color={isActive === 'usuarios' ? 'secondary' : 'textPrimary'}>
                Painel
              </Typography>
            </Link>
            <Link to={adminPathEmails}>
              <Typography variant="h5" color={isActive === 'emails' ? 'secondary' : 'textPrimary'}>
                Emails
              </Typography>
            </Link>
            <Link to={adminPathAds}>
              <Typography variant="h5" color={isActive === 'emails' ? 'secondary' : 'textPrimary'}>
                Anúncios
              </Typography>
            </Link>
            <Link to={adminPathPayments}>
              <Typography variant="h5" color={isActive === 'pagamentos' ? 'secondary' : 'textPrimary'}>
                Alugueis
              </Typography>
            </Link>
            <Link to={adminPathActiveContracts}>
              <Typography variant="h5" color={isActive === 'contratos-ativos' ? 'secondary' : 'textPrimary'}>
                Contratos
              </Typography>
            </Link>
          </div>
          <AvatarBadge />
        </Toolbar>
      </AppBar>
      <div className={classes.navBar}>
        <Navbar>{navbar?.title}</Navbar>
      </div>
      <div className={classes.pagesContainer}>
        <Switch>
          <Route path={adminPathBuilder('/newdash/:path+')} component={SSRDash} />
          <Route exact path={adminPathBuilder('/dashboard')} component={Dashboard} />
          <Route exact path={adminPathBuilder('/usuarios')} component={UsersList} />
          <Route exact path={adminPathBuilder('/usuarios/:userId')} component={UserDetail} />
          <Route exact path={adminPathBuilder('/imoveis/:propertyId')} component={PropertyDetail} />
          <Route exact path={adminPathBuilder('/pagamentos')} component={Payments} />
          <Route exact path={adminPathBuilder('/emails')} component={EmailsList} />
          <Route exact path={adminPathBuilder('/contratos-ativos')} component={ActiveContracts} />
          <Route exact path={adminPathBuilder('/contrato/editor/:propertyId/')} component={ContractManager} />
        </Switch>
      </div>
    </>
  )
}

const LogoElement = ({classes}) => {
  return (
    <Link to="/" className="home-logo-link">
      <img src="/images/logo/Moben-Logo-header.png" className={classes.logoElementImg} />
    </Link>
  )
}

export default Admin
