import CheckIcon from '@material-ui/icons/Check'
import {convertDateToBr} from 'date-parser'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import React, {useEffect, useRef} from 'react'
import {connectInfiniteHits} from 'react-instantsearch-dom'
import {Link} from 'react-router-dom'

const UsersHits = ({hits, hasMore, refineNext}) => {
  const lastItemRef = useRef(null)

  useEffect(() => {
    if (!hasMore) return

    let observer = new IntersectionObserver(onIntersection)
    observer.observe(lastItemRef.current)

    return () => observer.disconnect()
  }, [hits])

  const onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  return (
    <ul className="ais-InfiniteHits-list">
      {hits.map((hit) => (
        <li key={hit.objectID} className="ais-InfiniteHits-item">
          <UserHit hit={hit} />
        </li>
      ))}
      <li className="ais-InfiniteHits-last-item" ref={lastItemRef} />
    </ul>
  )
}

const UserHit = ({hit}) => {
  const userDetailPath = adminPathBuilder(`/usuarios/${hit.uid}`)

  const styles = {
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <div>
      <Link to={userDetailPath}>
        <strong>{hit.name}</strong>
      </Link>
      <p>{hit.uid}</p>
      <p>{hit.email}</p>
      <p>{hit.phone}</p>
      <br />
      <p style={styles}>
        <span>PP: </span>
        {hit.is_owner ? <CheckIcon color="secondary" /> : ''}
      </p>
      <p style={styles}>
        <span>IQ: </span>
        {hit.is_tenant ? <CheckIcon color="secondary" /> : ''}
      </p>
      <p style={styles}>
        <span>FI: </span>
        {hit.is_fiador ? <CheckIcon color="secondary" /> : ''}
      </p>
      <p style={styles}>
        <span style={{marginRight: 5}}>Criado em: </span>
        {convertDateToBr(hit.created_at, true)}
      </p>
    </div>
  )
}

export const UsersScrollableHits = connectInfiniteHits(UsersHits)
