import {ContractTypes} from '@root/redux/contract/contract.types'

export const resetContractAction = () => ({
  type: ContractTypes.RESET_CONTRACT,
})

export const setContractSnapAction = (propertySnap) => ({
  type: ContractTypes.SET_CONTRACT_SNAP,
  payload: {propertySnap},
})

export const setContractFromDatabaseAction = (contract, propertySnap) => ({
  type: ContractTypes.SET_CONTRACT_FROM_DATABASE,
  payload: {contract, propertySnap},
})
export const setContractTenantFromDatabaseAction = (tenant) => ({
  type: ContractTypes.SET_CONTRACT_TENANT_FROM_DATABASE,
  payload: {tenant},
})

export const setContractPropertyAction = (property) => ({
  type: ContractTypes.SET_CONTRACT_PROPERTY,
  payload: {property},
})

export const setContractValidityAction = (validity) => ({
  type: ContractTypes.SET_CONTRACT_VALIDITY,
  payload: {validity},
})
export const setContractRentdueAction = (rentdue) => ({
  type: ContractTypes.SET_CONTRACT_RENTDUE,
  payload: {rentdue},
})
export const setContractRentfeesAction = (rentfees) => ({
  type: ContractTypes.SET_CONTRACT_RENTFEES,
  payload: {rentfees},
})
export const setContractYourinfoAction = (yourinfo) => ({
  type: ContractTypes.SET_CONTRACT_YOURINFO,
  payload: {yourinfo},
})
export const setContractPenaltiesAction = (penalties) => ({
  type: ContractTypes.SET_CONTRACT_PENALTIES,
  payload: {penalties},
})
export const setContractGuaranteesAction = (guarantees) => ({
  type: ContractTypes.SET_CONTRACT_GUARANTEES,
  payload: {guarantees},
})
export const setContractForumAction = (forum) => ({
  type: ContractTypes.SET_CONTRACT_FORUM,
  payload: {forum},
})
export const setContractReadjustmentIndexAction = (readjustment_index) => ({
  type: ContractTypes.SET_CONTRACT_READJUSTMENT_INDEX,
  payload: {readjustment_index},
})
export const setContractFireInsuranceAction = (fire_insurance) => ({
  type: ContractTypes.SET_CONTRACT_FIRE_INSURANCE,
  payload: {fire_insurance},
})
export const setContractWitnessesAction = (witnesses) => ({
  type: ContractTypes.SET_CONTRACT_WITNESSES,
  payload: {witnesses},
})
export const setContractTenantAction = (tenant) => ({
  type: ContractTypes.SET_CONTRACT_TENANT,
  payload: {tenant},
})
export const setContractSurveyAction = (survey) => ({
  type: ContractTypes.SET_CONTRACT_SURVEY,
  payload: {survey},
})
export const setContractStepAction = (step) => ({
  type: ContractTypes.SET_CONTRACT_STEP,
  payload: {step},
})
export const addContractObservationAction = (observation) => ({
  type: ContractTypes.ADD_CONTRACT_OBSERVATION,
  payload: {observation},
})
