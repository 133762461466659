import Typography from '@material-ui/core/Typography'
import {SurveyHandler} from '@root/components/SurveyHandler/SurveyHandler'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import {whatsAppLinkMobenDefault} from '@root/lib/simplePhoneFormatter'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {MOBEN_WHATSAPP} from '@root/utils/constants'

import {useStyles} from './styles'

const Survey = () => {
  const classes = useStyles()
  const history = useHistory()
  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const propertyId = useSelector((s) => s.property.currentPropertyId)
  const survey = useSelector((s) => s.property.data?.contract?.survey)
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const {handleSubmit, control, errors, reset} = useForm()

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
  })

  // const surveyOptionSelected = watch('survey')

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)
    const hasSurvey = formData.survey === 'yes'
    try {
      const surveyPayload = {
        ...survey,
        has_survey: hasSurvey,
      }

      await propertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.survey': surveyPayload,
        }),
      )

      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.guarantee}`}>
        <Typography variant="body2">
          As vistorias de entrada e saída são os documentos que podem garantir que o imóvel seja entregue da mesma forma
          que foi alugado. Uma vistoria completa e detalhada é fundamental e deve ser anexada ao contrato de locação.
          Caso você não tenha feito uma vistoria, podemos providenciar um orçamento. Entre em contato no{' '}
          <WhatsAppLinkWrapper phone={MOBEN_WHATSAPP} message="Olá, preciso realizar uma vistoria no meu imóvel">
            WhatsApp da Moben
          </WhatsAppLinkWrapper>
          .
        </Typography>

        <SurveyHandler control={control} errors={errors} reset={reset} isContract />
      </form>

      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
export default Survey
