import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  fakedoorContainer: {
    marginTop: 100,
    // border: '1px solid red',
    maxWidth: '500px',
    [theme.breakpoints.down('lg')]: {
      margin: '100px auto',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
      maxWidth: 'unset',
    },
  },
  fakedoorTitle: {
    fontWeight: 700,
    fontSize: '1.8rem',
    marginLeft: 20,
  },
  fakedoorMiddle: {
    marginBottom: 20,
  },
  whatsappBtn: {
    display: 'flex',
    color: '#54C410',
    cursor: 'pointer',
    alignItems: 'center',
    width: '155px',
    height: '35px',
    fontWeight: 700,
    fontSize: '1.2em',
    border: '3px solid #54C410',
  },
  whatsappIcon: {
    fontSize: 30,
    margin: '0 5px',
  },
  backBtn: {
    fontWeight: 700,
    fontSize: '1.5rem',
    textAlign: 'center',
    marginTop: '20px',
    cursor: 'pointer',
  },
  backdrop: {
    backgroundColor: 'white',
  },
}))

export default useStyles
