import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import {contractRepoInstance} from '@root/application/service/repository/contract/ContractRepository'
import {Button} from '@root/components/Button/Button'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import Title from '@root/components/Layout/Typography/Title'
import useConfirmModal from '@root/components/Modal/useConfirmModal'
import Page from '@root/components/Page'
import {convertDateToBr} from 'date-parser'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import routes, {routeWithParams} from '@root/utils/routes'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 24,
    height: '100%',
    display: 'flex',
  },
  content: {
    'marginTop': 8,
    '& p': {
      marginTop: 7,
      marginBottom: 7,
    },
  },
  footer: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: 1,
  },
  list: {
    marginLeft: 36,
  },
  button: {
    margin: '0 auto',
  },
}))

let selectedMethod = {}
const Renew = () => {
  const classes = useStyles()
  const history = useHistory()

  const dispatch = useDispatch()
  const property = useSelector((s) => s.property.data)

  const propertyAlias = property?.alias
  const contract = property?.contract
  const validity = contract?.validity
  const isLongDuration = false
  const validityLen = 12

  const confirm = useConfirmModal('Confirmar método de renovação', '', handleConfirmOk.bind(this))

  const confirmConfig = (option, label) => {
    selectedMethod = {option, label}
    confirm.setQuestion(`Deseja realmente definir o método de renovação como: ${label}?`)
    confirm.open()
  }

  const handleFinish = () => confirmConfig('finish', 'ENCERRAR CONTRATO')
  const handleRenew = () => confirmConfig('redo', 'RENOVAR CONTRATO')
  const handleKeep = () => confirmConfig('keep', 'MANTER CONTRATO ATUAL')

  async function handleConfirmOk() {
    dispatch(setGlobalLoaderAction(true))
    await contractRepoInstance.renew(selectedMethod.option, property.uid)
    dispatch(setGlobalLoaderAction(false))

    history.replace(`${routes.DEFAULT_CONFIRMATION}/renovacao?option=${selectedMethod.option}`)
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction(`Renovação ${propertyAlias}`))
  }, [])

  return (
    <Page>
      <div className={classes.content}>
        <Text component="p">
          O contrato de locação do seu imóvel está chegando ao final da vigência {convertDateToBr(validity.end)} e por
          isso trouxemos algumas informações importantes para este momento.
        </Text>
        <Text component="p">
          A lei do inquilinato tem cláusulas específicas sobre a retomada do imóvel a pedido do proprietário e por isso
          este momento deve ser revisitado com bastante atenção.
        </Text>
      </div>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={4}>
          <Box className={classes.container}>
            <Title>1. Encerrar o contrato com o inquilino atual</Title>
            <div className={classes.content}>
              <Text component="p">
                Independente do motivo, você pode pedir o seu imóvel de volta por conta do final da vigência do contrato
                de locação.
              </Text>
              <Text component="p">
                Para que esse processo ocorra com tranquilidade, recomendamos que você sinalize com no mínimo 30 dias de
                antecedência para que o inquilino consiga se organizar e deixar o imóvel.
              </Text>
              <Text component="p">
                Em paralelo, é necessário entender possíveis pendências tanto financeiras quanto relativas ao estado do
                imóvel. Com este ponto acertado entre as partes você pode preparar o seu imóvel para o próximo
                inquilino.
              </Text>
              <Text component="p">
                Na Moben você encontrará os recursos necessários para a realização dos cálculos finais e também para
                todas as formalizações como recibos de entregas de chaves e contratação de vistoria de saída.
              </Text>
            </div>
            <div className={classes.footer}>
              <div className={classes.button}>
                <Button fz="1.2rem" p="14px" onClick={handleFinish}>
                  ENCERRAR CONTRATO
                </Button>
              </div>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className={classes.container}>
            <Title>2. Novo contrato mantendo o inquilino atual</Title>
            <div className={classes.content}>
              <Text component="p">
                Aqui você pode renegociar e alterar qualquer ponto do contrato com o seu inquilino (índice de reajuste,
                data de vencimento, responsabilidade de seguros, IPTU, entre outros detalhes).
              </Text>
              <Text component="p">
                Além disso, você pode contar novamente com a multa por rescisão antecipada do contrato. Ponto que deixa
                de existir caso você opte por deixar o contrato atual seguir em renovação automática.
              </Text>
              <Text component="p">
                Importante ressaltar que se você celebrar um novo contrato, as regras para solicitar a devolução do
                imóvel antes do final da vigência passam a valer também:
              </Text>
            </div>
            <ul className={classes.list}>
              <li>
                <Text>Mútuo acordo</Text>
              </li>
              <li>
                <Text>infração legal ou contratual</Text>
              </li>
              <li>
                <Text>Falta de pagamento</Text>
              </li>
              <li>
                <Text>Reparações urgentes determinadas pelo poder público</Text>
              </li>
              <li>
                <Text>Alienação (desde que não haja uma cláusula específica garantindo o contrário)</Text>
              </li>
            </ul>
            <div className={classes.footer}>
              <div className={classes.button}>
                <Button fz="1.2rem" p="14px" onClick={handleRenew}>
                  RENOVAR CONTRATO
                </Button>
              </div>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className={classes.container}>
            <Title>3. Seguir com o contrato atual</Title>
            <div className={classes.content}>
              <Text component="p">
                O contrato padrão emitido através da plataforma já tem uma cláusula de renovação automática. Isso
                significa que nada muda em termos de responsabilidades do proprietário e inquilino.
              </Text>

              {isLongDuration && (
                <Text component="p">
                  Como o seu contrato tem mais de 30 meses de vigência, você pode encerrar este contrato a qualquer
                  momento mesmo após o final da vigência. Para isso basta que você sinalize o inquilino com 30 dias de
                  antecedência. Você poderá contar com todos os recursos da Moben para realização dos cálculos finais e
                  formalizações.
                </Text>
              )}
              {!isLongDuration && (
                <>
                  <Text component="p">
                    Como o seu contrato tem menos de 30 meses de vigência ({validityLen} meses), existem regras
                    específicas para que o proprietário solicite a devolução do imóvel de acordo com a lei do
                    inquilinato:
                  </Text>
                  <ul className={classes.list}>
                    <li>
                      <Text>Mútuo acordo</Text>
                    </li>
                    <li>
                      <Text>Infração legal ou contratual</Text>
                    </li>
                    <li>
                      <Text>Falta de pagamento</Text>
                    </li>
                    <li>
                      <Text>Reparações urgentes determinadas pelo poder público</Text>
                    </li>
                    <li>
                      <Text>Alienação (desde que não haja uma cláusula específica garantindo o contrário)</Text>
                    </li>
                    <li>
                      <Text>Uso próprio</Text>
                    </li>
                    <li>
                      <Text>
                        Demolição e edificação que aumentem a área construída em 20%(ou 50% para hotel e pensão)
                      </Text>
                    </li>
                    <li>
                      <Text>Locação com vigência ininterrupta maior que 5 anos</Text>
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className={classes.content}>
              <Text component="p">
                Ou seja, caso você decida deixar o contrato ser renovado automaticamente, estas são as únicas razões
                pelas quais você poderá pedir de volta o imóvel.
              </Text>
            </div>
            <div className={classes.footer}>
              <div className={classes.button}>
                <Button fz="1.2rem" p="14px" onClick={handleKeep}>
                  MANTER CONTRATO ATUAL
                </Button>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>

      {confirm.modal}
    </Page>
  )
}

Renew.propTypes = {}
Renew.defaultProps = {}

export default Renew
