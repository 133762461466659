import {Typography} from '@material-ui/core'
import Fakedoor, {MobenBlogLink} from '@root/components/App/Dashboard/DashboardPages/Fakedoor/Fakedoor'
import React from 'react'
import {TENANT_DASHBOARD_BLOCK_TITLES} from '@root/utils/constants'

const TenantDashboardAsk = () => {
  return (
    <Fakedoor title={TENANT_DASHBOARD_BLOCK_TITLES.ask}>
      <Typography variant="body1">
        Caso você tenha qualquer dúvida em relação ao processo de aluguel entre em contato conosco pois será um prazer
        ajudar.
      </Typography>
      <MobenBlogLink />
      <strong>Conte-nos de que forma podemos ajudar:</strong>
    </Fakedoor>
  )
}

export default TenantDashboardAsk
