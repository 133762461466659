import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  /*
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    margin: '40px 0 0',
  },
  */
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    textDecoration: 'underline',
    color: 'black',
  },
  text: {
    fontSize: '1.2rem',
    margin: '15px 0',
  },
  link: {
    'color': '#831CCD',
    'fontSize': '1.2rem',
    '&:link': {
      color: '#831CCD',
    },
    '&:visited': {
      color: '#831CCD',
    },
    '&:hover': {
      color: '#a233f2',
    },
    '&:active': {
      color: '#330a52',
    },
  },
  lastText: {
    marginTop: '20px',
    marginBottom: '60px',
  },
  dialog: {
    // margin: 'auto',
  },
  dialogGridcontainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  dialogBtns: {
    position: 'relative',
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #555',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      background: '#fff',
      zIndex: 5,
      padding: '5px',
      justifyContent: 'center',
    },
  },
  termsContainer: {
    minHeight: 400,
    maxHeight: 500,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      maxHeight: 'unset',
      // background: 'purple',
      // height: 300,
    },
    overflowY: 'scroll',
  },
  termsTextSection: {
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 105,
    },
  },
  termsNav: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 5,
      padding: '12px 12px 30px',
      // height: 180,
      overflow: 'scroll',
      background: 'linear-gradient(to bottom, #fff 0%, #fff 90%, transparent 100%)',
    },
  },
  termsNavLinks: {
    padding: '28px 0 28px 28px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 12px !important',
    },
  },
  navIcon: {
    position: 'fixed',
    top: 12,
    right: 12,
  },
  termsNavLink: {
    'padding': '4px',
    'textDecoration': 'none',
    'color': '#831CCD',
    'cursor': 'pointer',
    'display': 'flex',
    'margin-bottom': '5px',
    'transition': '0.3s',
    '&:hover': {
      color: '#aaa',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  dialogContentRoot: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 12px !important',
      marginTop: 236,
      zIndex: 1,
    },
  },
  liSpace: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 5,
    },
  },
  subMenuDiv: {
    // background: 'orange',
    maxHeight: 240,
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      '& > li:not(:first-child)': {
        marginTop: 8,
      },
      '& > li': {
        paddingLeft: 10,
      },
    },
  },
  allTermsContainer: {
    paddingTop: 24,
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  rejectTerms: {
    'fontFamily': 'Ubuntu',
    'fontSize': '12px !important',
    'fontWeight': '500 !important',
    'color': '#831CCD',
    // 'color': '#061BCD',
    'textDecoration': 'underline',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  acceptTerms: {
    marginLeft: '30px',
    fontSize: '15px !important',
    fontWeight: '600 !important',
    textTransform: 'none',
  },
}))

export default useStyles
