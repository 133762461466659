import {NotificationEntry, ObservationEntry} from '@root/components/ContractNotification/ObservationHistory'
import {isNotificationType, isObservationType} from '@root/lib/notification'
import React from 'react'

const ContractNotification = ({notifications, allowEdit, onEdit, onRefuse}) => {
  const list = [...(notifications ?? [])].reverse()

  return (
    <section className="notificationsSection">
      {list.map((notifi) => {
        const isNotification = isNotificationType(notifi.type)
        const isObservation = isObservationType(notifi.type)

        if (isNotification) {
          return <NotificationEntry key={notifi.id} notification={notifi} />
        } else if (isObservation) {
          return (
            <ObservationEntry
              key={notifi.id}
              allowEdit={allowEdit}
              observation={notifi}
              onEdit={onEdit}
              onRefuse={onRefuse}
            />
          )
        }
      })}
    </section>
  )
}

ContractNotification.defaultProps = {
  allowEdit: false,
}

export default ContractNotification
