import {propertyRepoInstance} from '@root/application/service/repository/property/PropertyRepository'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {validateRequired} from '@root/lib/simpleValidators'
import React from 'react'
import {useForm} from 'react-hook-form'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const BlockFinishContract = ({property, owner, closeModal}) => {
  const {control, errors, handleSubmit} = useForm()
  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const handleFinish = async (form) => {
    try {
      actions.setGlobalLoaderAction(true)
      await propertyRepoInstance.finishContract(property.uid, owner.uid, form.mode)
      closeModal()
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  return (
    <>
      <FormItem
        control={control}
        errors={errors}
        name="mode"
        label="Modo de reset"
        type="radio"
        rules={{validate: validateRequired}}
        options={[
          optionFactory('Completo', 'full', 'Zera tudo'),
          optionFactory('Manter interessados', 'keep_interested', 'Mantem apenas os interessados'),
          optionFactory(
            'Apenas parar',
            'stop_only',
            'Só muda o status do contrato para building e mantem todos os dados intactos',
          ),
        ]}
      />
      <Button onClick={handleSubmit(handleFinish)}>Encerrar</Button>
    </>
  )
}

BlockFinishContract.propTypes = {}
BlockFinishContract.defaultProps = {}

export default BlockFinishContract
