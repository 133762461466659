import Typography from '@material-ui/core/Typography'
import getContractDownloadURL from '@root/components/App/Contract/getContract'
import {ContractButton} from '@root/components/Contract/ContractButton'
import {useStyles as useContractStyles} from '@root/components/Contract/styles.js'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {convertDateToBr} from '@root/lib/dateParser'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {addContractObservationAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import routes from '@root/utils/routes'

import ContractNotification from '../../components/ContractNotification/ContractNotification'
import {Divider} from '../../components/Divider/Divider'
import {useStyles} from './styles'

export function TenantContractDetails() {
  const classes = useStyles()
  const classesContract = useContractStyles()
  const history = useHistory()
  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.tenantProperty.data)
  const contract = useSelector((s) => s.tenantProperty.data.contract)

  const notificationsList = property?.contract?.observations
  const notifications = notificationsList ?? []
  const hasNotifications = notifications.length > 0

  const {hasContractGenerated} = useContract(property)
  const pdfLink = getContractDownloadURL(property)

  const actions = actionDispatcher({
    setContractStepAction,
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    addContractObservationAction,
    setNavbarTitleAction,
  })

  const handleViewContract = (e) => {
    e.preventDefault()
    window.open(pdfLink, '_blank')
  }
  const handleGoToApprovePage = (e) => {
    e.preventDefault()
    history.push(`${routes.CONTRACT_APPROVAL}?id=${property.uid}`)
  }

  useEffect(() => {
    actions.setNavbarTitleAction(property.alias)
  }, [property])

  return (
    <div className={classes.contractCreated}>
      {!hasContractGenerated ? (
        <Typography variant="h4" align="center">
          O prorprietário ainda não finalizou o contrato.
        </Typography>
      ) : (
        <header>
          <Typography variant="body2">
            Documento gerado em {convertDateToBr(contract.contract_file.created_at)}.
          </Typography>

          <div className="buttonsContainer">
            <ContractButton type="visualizar" onClick={handleViewContract} disabled={!hasContractGenerated} />
            {contract.status === 'building' && <ContractButton type="aprovar" onClick={handleGoToApprovePage} />}
          </div>
        </header>
      )}
      {hasNotifications && (
        <>
          <Divider dashed m="15px 0 20px" />
          <div className={classesContract.contractCreated}>
            <ContractNotification notifications={notifications} allowEdit={false} />
          </div>
        </>
      )}
    </div>
  )
}
