import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {useStyles} from '@root/components/Contract/styles'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import InviteForm from '@root/components/Forms/InviteForm'
import Title from '@root/components/Layout/Typography/Title'
import TitleRaw from '@root/components/Layout/Typography/TitleRaw'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {ownerContractSave, ownerLoader} from '@root/lib/contract/ownerLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler, {addBreadCrumb} from '@root/lib/errorHandler'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import {safeUserReset} from '@root/lib/safeReset'
import {validateRequired} from '@root/lib/simpleValidators'
import userModel from '@root/model/userModel'
import React, {useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentUser, setUserFromFirebaseAction} from '@root/redux/user/user.actions'
import {DATASOURCE_ADMIN, DATASOURCE_ME, DATASOURCE_OTHER} from '@root/utils/constants'
import routes from '@root/utils/routes'

const DEFAULT_DATASOURCE = DATASOURCE_ME

/**
 * @see https://moben.atlassian.net/wiki/spaces/PLANEJAMEN/pages/20578305?atlOrigin=eyJpIjoiYzRhODZhNzk2MmNmNDFlYmE0M2E1OTZjYWQ0MGNkMDciLCJwIjoiaiJ9
 */
const OwnerInfo = () => {
  const classes = useStyles()
  const history = useHistory()
  const formMe = useForm()
  const formOther = useForm()
  const formGeneral = useForm()

  const [userMe, setUserMe] = useState()
  const [userOther, setUserOther] = useState()

  const dataSource = formGeneral.watch('owner_source')

  const user = useSelector((s) => s.user.user)
  const loggedUserRef = useSelector((s) => s.user.firebaseRef)
  const contractOwner = useSelector((s) => s.property?.data?.contract?.owner)
  const property = useSelector((s) => s.property?.data)
  const contractPropertySnap = useSelector((s) => s.property?.snap)

  const propertyId = property.uid

  const isDataSourceMe = dataSource === DATASOURCE_ME
  const isDataSourceOther = dataSource === DATASOURCE_OTHER
  const isDataSourceAdmin = dataSource === DATASOURCE_ADMIN

  const formSize = isDataSourceAdmin ? 6 : 12

  const configs = useMemo(() => {
    const displayOwnerForm = isDataSourceAdmin || isDataSourceMe
    const displayOtherForm = isDataSourceAdmin || isDataSourceOther
    const users = {
      owner: {
        title: 'Dados do Proprietário',
        data: userMe,
      },
      other: {
        title: 'Dados do Administrador',
        data: userOther,
      },
    }

    if (isDataSourceOther) {
      users.other.title = users.owner.title
    } else if (isDataSourceAdmin) {
      // aqui preciso inverter por que o pp (owner) continua sendo um user "de fora"
      users.owner.data = userOther
      users.other.data = userMe
    }

    return {
      displayOwnerForm,
      displayOtherForm,
      users,
    }
  }, [isDataSourceMe, isDataSourceOther, isDataSourceAdmin, userMe, userOther])

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
    setUserFromFirebaseAction,
    setCurrentUser,
  })

  const handleSubmit = async () => {
    const results = await Promise.all([formMe.trigger(), formOther.trigger(), formGeneral.trigger()])
    const isValid = results.every((valid) => valid)

    if (isValid) {
      handleClickContinuar()
    }
  }

  const handleClickContinuar = async () => {
    const generalFormData = formGeneral.getValues()

    const owner = configs.users.owner.data
    const ownerData = owner.userData
    const ownerFormData = formMe.getValues()

    const other = configs.users.other.data
    const otherData = other.userData
    const otherFormData = formOther.getValues()

    actions.setGlobalLoaderAction(true)
    let powerAttorneyFiles = null
    try {
      if (configs.displayOtherForm) {
        const updateOtherData = {...otherData, ...otherFormData}
        await ownerContractSave(contractPropertySnap, dataSource, other.userRef, updateOtherData)
      }

      if (configs.displayOwnerForm) {
        const updateOwnerData = {...ownerData, ...ownerFormData}
        await ownerContractSave(contractPropertySnap, dataSource, owner.userRef, updateOwnerData)
      }

      const progressInfo = updateProgressDataFromRedux(property)
      const updatePropertyPayload = {
        [progressInfo.path]: progressInfo.payload,
        'contract.status': 'building',
      }

      if (isDataSourceAdmin) {
        const powerAttorneyFiles = await firebaseFilesUploader(
          generalFormData.power_attorney,
          `power_attorney_${propertyId}`,
          `property/${propertyId}`,
        )

        updatePropertyPayload.power_attorney = powerAttorneyFiles
      }
      await contractPropertySnap.ref.update(updatePropertyPayload)

      actions.setGlobalLoaderAction(false)
      history.push(getNextStep().path)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      addBreadCrumb('form owner', owner.userData)
      addBreadCrumb('form other', other.userData)
      alert(errorHandler(ex))
    }
  }

  const loadSources = async () => {
    actions.setGlobalLoaderAction(true)
    await Promise.all([
      loadSingleSource(DATASOURCE_ME, setUserMe, formMe.reset),
      loadSingleSource(DATASOURCE_OTHER, setUserOther, formOther.reset),
    ])

    formGeneral.reset({owner_source: property.contract?.owner?.data_source ?? DEFAULT_DATASOURCE})
    // formGeneral.reset({owner_source: property.contract?.owner?.data_source})
    actions.setGlobalLoaderAction(false)
  }

  const loadSingleSource = async (type, setStateFunc, resetFunc) => {
    const user = await ownerLoader(contractOwner, type)
    setStateFunc(user)
    safeUserReset(user?.userData ?? {}, resetFunc)
  }

  useEffect(() => {
    loadSources()
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  return (
    <ContractContainer>
      <FormItem
        control={formGeneral.control}
        errors={formGeneral.errors}
        fitContainer={true}
        type="radio"
        extraStyles="row"
        name="owner_source"
        label="Insira os dados de quem vai assinar o contrato como Locador"
        options={[
          optionFactory('Sou o Proprietário', DATASOURCE_ME),
          optionFactory('Sou o Administrador e tenho a procuração para assinar', DATASOURCE_ADMIN),
          optionFactory('Não sou o Proprietário e o Proprietário vai assinar', DATASOURCE_OTHER),
        ]}
        rules={{validate: validateRequired}}
      />
      <Grid container direction="row" spacing={2}>
        {isDataSourceAdmin && (
          <Grid item xs={12}>
            <FormItem
              control={formGeneral.control}
              errors={formGeneral.errors}
              fitContainer={true}
              multiple={true}
              accept="*"
              type="file"
              name="power_attorney"
              label="Procuração"
              rules={{validate: validateRequired}}
            />
          </Grid>
        )}
        {configs.displayOwnerForm && (
          <Grid item xs={12} md={formSize}>
            <FormProvider {...formMe}>
              <TitleRaw>{configs.users.owner.title}</TitleRaw>
              <br />
              <InviteForm
                user={configs.users.owner?.data?.userData}
                allowFullEdit={!isDataSourceMe}
                displayUploads={false}
                displayIncomeField={false}
                displayAddress={false}
              />
            </FormProvider>
          </Grid>
        )}

        {configs.displayOtherForm && (
          <Grid item xs={12} md={formSize}>
            <FormProvider {...formOther}>
              <TitleRaw>{configs.users.other.title}</TitleRaw>
              <br />
              <InviteForm
                user={configs.users.other?.data?.userData}
                allowFullEdit={!isDataSourceMe}
                displayUploads={false}
                displayIncomeField={false}
                displayAddress={false}
              />
            </FormProvider>
          </Grid>
        )}
      </Grid>

      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit} onGoBackClick={() => history.push(routes.DASHBOARD_ROOT)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}

const diatricsRegExp = /[\u0300-\u036f]/g
export const strContains = (str, term, allowEmpty) => {
  const contains = str
    .toLowerCase()
    .normalize('NFD')
    .replace(diatricsRegExp, '')
    .includes(term.toLowerCase().normalize('NFD').replace(diatricsRegExp, ''))
  return contains
}

export default OwnerInfo
