import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {ApplicationTypes} from '@root/redux/application/application.types'
import {ContractTypes} from '@root/redux/contract/contract.types'

const initialState = {
  cookies: {
    accepted: false,
  },
  routeInfo: {
    isRoot: true,
    hasMatchProtected: false,
    isHeaderless: false,
    isDashboardProperty: false,
  },
  navbar: {
    title: undefined,
  },
  optimize: {},
  isGuideVisible: false,
  ready: false,
  useMainPadding: true,
}

const reducers = {
  [ApplicationTypes.ACCEPT_COOKIES]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.cookies.accepted = payload.accepted
    })
  },
  [ApplicationTypes.SET_GUIDE_VISIBILITY]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.isGuideVisible = payload.visibility
    })
  },
  [ApplicationTypes.APP_READY]: (state) => {
    return produce(state, (draft) => {
      draft.ready = true
    })
  },
  [ApplicationTypes.SET_ROUTE_INFO]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.routeInfo = payload.routeInfo
    })
  },
  [ApplicationTypes.SET_HEADERLESS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.routeInfo.isHeaderless = payload.isHeaderless
    })
  },
  [ApplicationTypes.SET_NAVBAR_TITLE]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.navbar.title = payload.title
    })
  },
  [ApplicationTypes.SET_USE_MAIN_PADDING]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.useMainPadding = payload.useMainPadding
    })
  },
  [ContractTypes.SET_CONTRACT_STEP]: (state, {payload}) =>
    produce(state, (draft) => {
      draft.navbar.title = payload.step ? `${payload.step.index + 1} - ${payload.step.title}` : undefined
    }),

  [ApplicationTypes.ADD_OPTIMIZE_VARIANT]: (state, {payload}) =>
    produce(state, (draft) => {
      draft.optimize[payload.alias] = String(payload.variantId)
    }),
}

export default reducerMatch(reducers, initialState)
