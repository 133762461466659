/** @module firestorePromisify */
import {firestore} from '@root/firebase/firebase.utils'

const defaultRejection = () => Promise.reject(['Não foi possível obter os dados'])

/**
 * @param {string} collectionName
 * @param {string} id
 * @return {Promise<DocumentSnapshot<DocumentData>>}
 */
export const getCollectionById = async (collectionName, id) => {
  return getCollectionFromRef(firestore.collection(collectionName).doc(id))
}

/**
 * @param {DocumentReference<DocumentData>} ref
 * @return {Promise<DocumentSnapshot<DocumentData>>}
 */
export const getCollectionFromRef = (ref) => {
  return new Promise((resolve, reject) => {
    ref.get().then((results) => {
      if (results.exists || !results.empty) {
        resolve(results)
      } else {
        reject(defaultRejection())
      }
    })
  })
}
