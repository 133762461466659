import {isDevEnv, isProductionEnv} from '@root/lib/envDetector'
import {getPropertyAdminLink} from '@root/utils/links/getAdminLink'
import {
  batchMention,
  DISCORD_ID_BOTTO,
  DISCORD_ID_LEANDRO,
  DISCORD_ID_MARCEL,
  discordNotify,
  LINE_DIVIDER,
  mentionUser,
  setDebug,
  setMode,
} from 'discord-notify'

const mode = isProductionEnv() ? 'production' : 'console'
setMode(mode)
setDebug(isDevEnv())

const discordNotifyWrapper = (message, url) => {
  return discordNotify(message, url, true)
}

export const discordTest = async (message) => discordNotifyWrapper(message)

export const discordNotifyUserRegister = async (user, isTenant = false, propertyId = '') => {
  const userRole = isTenant ? 'inquilino' : 'usuario'
  const propertyLine = propertyId ? `Imovel: ${propertyId}` : ''
  return discordNotifyWrapper(
    `novo ${userRole} :tada:
ID: ${user.uid}
Nome: ${user.name}
Email: ${user.email}
Telefone: ${user.phone}
${propertyLine}
${mentionUser(DISCORD_ID_MARCEL)} ${mentionUser(DISCORD_ID_BOTTO)}
`,
  )
}

export const discordNotifyUserDeclinedTerms = async (user) => {
  return discordNotifyWrapper(
    `usuario deletado (recusou termos) :pirate_flag:
ID: ${user.uid}
Nome: ${user.name}
Email: ${user.email}
Telefone: ${user.phone}
`,
  )
}

export const discordNotifyTenantApprovedContract = async (user, contractUrl, property) => {
  const lines = ['CONTRATO APROVADO :white_check_mark:']

  if (property) {
    lines.push(`Imovel: \`${property.uid}\``)
  }

  if (user) {
    lines.push('Cliente:')
    lines.push('- ' + user.uid)
    lines.push('- ' + user.name)
  }

  lines.push('contrato: ' + contractUrl)
  return discordNotifyWrapper(lines)
}

export const discordNotifyTenantContractObservation = async (user, contractUrl, observation) => {
  const lines = ['CONTRATO REPROVADO COM OBSERVACOES :stop_sign:', 'contrato: ' + contractUrl]
  if (user) {
    lines.push('Cliente:')
    lines.push('- ' + user.uid)
    lines.push('- ' + user.name)
  }
  lines.push('Observacoes')
  lines.push(observation)
  lines.push('vai que é tua ' + mentionUser(DISCORD_ID_LEANDRO))
  lines.push(LINE_DIVIDER)
  return discordNotifyWrapper(lines.join('\n'))
}

/** @deprecated */
export const discordNotifyContractCreated = async (user, propertyId) => {
  return discordNotifyWrapper(
    `
:writing_hand: PP gerou um contrato
id user: \`${user.uid}\`
proprietario: ${user.name}
id imovel: ${propertyId}
`,
  )
}

export const discordNotifyContractWaitingApproval = async (user, contractUrl) => {
  return discordNotifyWrapper(
    `
:mailbox_with_mail: Contrato enviado para IQ
id user: \`${user.uid}\`
inquilino: ${user.name}
contrato: ${contractUrl}
`,
  )
}

export const discordNotifyNewProposal = async (user, property, mode) => {
  let message = [
    ':vertical_traffic_light: Nova proposta cadastrada',
    'Interessado:',
    `ID: \`${user.uid}\``,
    `Nome: ${user.name}`,
    `Email: ${user.email}`,
    `Telefone: ${user.phone}`,
    `Imovel: ${getPropertyAdminLink(property.uid)}`,
    `Modo: ${mode}`,
  ]

  return discordNotifyWrapper(message)
}

/** @deprecated */
export const discordNotifyProperty = async (owner, propertiesByRef) => {
  let newProperties = ''
  propertiesByRef.map((p) => {
    let property = p.data()
    newProperties += `
Imovel:
ID: ${property.uid}
Nome: ${property.alias}
`
  })

  let output = `Novo imovel :house:
  
Proprietario:
ID: ${owner.uid}
Nome: ${owner.name}
${newProperties}
`

  return discordNotifyWrapper(output)
}

/** @deprecated */
export const discordNotifyInviteCopied = async (user, propertyId, inviteLink) => {
  const origin = window.location.pathname
  let output = `
:love_letter: convite copiado ${origin}
  
id user: \`${user.uid}\`
Nome: ${user.name}
id imovel: ${propertyId}
convite: ${inviteLink}

`

  return discordNotifyWrapper(output)
}

/** @deprecated */
export const discordNotifyInviteSentByMail = async (user, propertyId, email) => {
  const origin = window.location.pathname
  let output = `
:love_letter: convite enviado por email ${origin}
  
id user: \`${user.uid}\`
Nome: ${user.name}
id imovel: ${propertyId}
email convidado: ${email}

`

  return discordNotifyWrapper(output)
}

export const discordNotifySurveyUpload = async (user, propertyId, link) => {
  let output = `
:newspaper: upload vistoria 

id user: \`${user.uid}\`
Nome: ${user.name}
id imovel: \`${propertyId}\`
Link vistoria: ${link}

`
  return discordNotifyWrapper(output)
}

export const discordNotifyNewProperty = async (owner, property) => {
  let output = `
:house: Novo imovel: \`${property.uid}\`

Proprietario:
Nome: ${owner.name}
Email: ${owner.email}
Telefone: ${owner.phone}
Admin: ${getPropertyAdminLink(property.uid)}

`

  return discordNotifyWrapper(output)
}

export const discordNotifyOwnerRegisteredProposal = async (property, newUser) => {
  let output = `
:house: PP cadastrou interessado 

Interessado:
Nome: ${newUser.name}
Email: ${newUser.email}
Telefone: ${newUser.phone}

Admin: ${getPropertyAdminLink(property.uid)}

`

  return discordNotifyWrapper(output)
}

export const discordNotifyApproveProposal = async (proposalId, property) => {
  let output = `
:white_check_mark: Proposta Aprovada: \`${proposalId}\`

Admin: ${getPropertyAdminLink(property.uid)}

`

  return discordNotifyWrapper(output)
}

export const discordNotifyBoletoUpdate = (
  propertyId,
  issuerName,
  issuerId,
  currentAmount,
  newAmount,
  dueDate,
  description,
) => {
  return discordNotifyWrapper([
    'Alteração valor do boleto',
    `Imovel: ${propertyId}
Solicitante: ${issuerName} - ${issuerId}
Valor atual: ${currentAmount}
Valor novo: ${newAmount}
Boleto com vencimento em: ${dueDate}
Motivo:
${description}
${mentionUser(DISCORD_ID_LEANDRO)} ${mentionUser(DISCORD_ID_MARCEL)}
`,
  ])
}

export const discordNotifyContractSentToTenant = (property, pdfLink) =>
  discordNotifyWrapper([
    'Contrato enviado para o Inquilino',
    `Imovel: \`${property.uid}\``,
    `Arquivo enviado: ${pdfLink}`,
  ])

export const discordNotifyCreateAd = ({property, user}) => {
  discordNotifyWrapper([
    'PP Criou Anuncio',
    `Imovel: [${property.uid}] ${property.address?.formatted}`,
    `Cliente: [${user.uid}] ${user.name}`,
    batchMention([DISCORD_ID_BOTTO, DISCORD_ID_MARCEL]),
  ])
}
