/*eslint-disable react/prop-types */
import Dialog from '@material-ui/core/Dialog'
import React from 'react'

/*
    ===DIALOG STATE AND HANDLERS===

    // State of the Dialog => open or closed.
    // Put it into your component which will use the Dialog, and pass
    // open property to the Dialog as props.
    const [open, setOpen] = React.useState(false)

    // Pass it to the element's onClick to trigger Dialog to open
    const handleClickOpen = () => {
      setOpen(true)
    }

    // Pass it to Dialog as props
    const handleClose = () => {
      setOpen(false)
    }
*/

const CustomDialog = (props) => (
  <Dialog
    onClose={props.onClose || props.handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperProps={{
      style: {
        borderRadius: '12px',
      },
    }}
    BackdropProps={{
      style: {
        background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,1) 100%)',
      },
    }}
    {...props}>
    {props.children}
  </Dialog>
)

export default CustomDialog
