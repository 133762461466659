import Typography from '@material-ui/core/Typography'
import Page from '@root/components/Page'
import PaymentsList from '@root/components/YourProperty/sections/SectionPayment/PaymentsList'
import usePaymentsHelper from '@root/components/YourProperty/sections/SectionPayment/usePaymentsHelper'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useStyles} from './styles'

export function Payments() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const propertySnap = useSelector((state) => state.property.snap)
  const rentfees = useSelector((state) => state.property.data?.contract?.rentfees ?? {})

  const {isLoading, payments} = usePaymentsHelper(propertySnap.id)

  useEffect(() => {
    dispatch(setNavbarTitleAction('Recebimentos'))
  }, [dispatch])

  return (
    <Page loading={isLoading}>
      <div className={classes.root}>
        <div className={classes.recebimentos}>
          <PaymentsList payments={payments} propertyId={propertySnap.id} rentfees={rentfees} tenantMode={false} />
        </div>
      </div>
    </Page>
  )
}
