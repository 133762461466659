import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ShareInvite'
import Confirm from '@root/components/Confirm'
import {useQuery} from '@root/hooks/urlHook'
import {getInviteTenant} from '@root/lib/inviteLinkCreator'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const useStyles = makeStyles({
  root: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > p': {
      margin: '0 0 10px',
    },
  },
})

export function SendInvite() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const proposalId = useQuery().getParam('proposal')

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const [confirmText, setConfirmText] = useState()
  const [isConfirmOpen, setConfirmOpen] = useState(false)

  const inviteLink = useMemo(() => getInviteTenant(property.uid, proposalId, false), [property])

  const inviteByEmail = async (email) => {
    dispatch(setGlobalLoaderAction(true))
    const response = await propertyModel.fetchInviteByEmail(email, user.uid, property.uid)
    setConfirmText(response)
    setConfirmOpen(true)

    dispatch(setGlobalLoaderAction(false))
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction('Convidar interessado'))
  }, [])

  return (
    <div className={classes.root}>
      <Typography variant="body2" align="center">
        Na <strong>Moben</strong>, os próprios interessados podem cadastrar seus dados e caso sejam selecionados também
        terão um Painel de Controle exclusivo. Caso você já tenha uma proposta com mais de um inquilino, escolha um
        deles para enviar o convite e ele convidará os outros inquilinos. Faça o convite para o cadastro na plataforma
        escolhendo uma das opções abaixo:
      </Typography>

      <ShareInvite shareLink={inviteLink} onInviteByEmail={inviteByEmail} />

      <Confirm open={isConfirmOpen} content={confirmText} alertMode={true} onOk={() => setConfirmOpen(false)} />
    </div>
  )
}
