import {makeStyles} from '@material-ui/core/styles'
import BackLink from '@root/components/BackLink'
import actionDispatcher from '@root/lib/actionDispatcher'
import React from 'react'
import {setFooterNotVisible} from '@root/redux/footer/footer.actions'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'

const useStyles = makeStyles({
  statusAluguel: {
    'boxSizing': 'border-box',
    'display': 'flex',
    'flexDirection': 'column',
    'height': 'calc(100vh - 65px)',
    'width': '100%',
    'maxWidth': '500px',
    'margin': '56px auto 0',
    'padding': '0 15px',
    '& header': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'margin': '15px 0',
      '& h1': {
        marginLeft: '10px',
        fontSize: '1.5rem',
        textAlign: 'center',
      },
    },
  },
  backLinkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
})

const Construction = ({title}) => {
  const classes = useStyles()

  const actions = actionDispatcher({
    setHeaderMainHeader,
    setFooterNotVisible,
  })

  actions.setHeaderMainHeader()
  actions.setFooterNotVisible()

  return (
    <div className={classes.statusAluguel}>
      <header>
        <img src="/assets/under_construction_icon.svg" alt="Icon" />
        <h1>{title ? title : 'em breve'}</h1>
      </header>
      <h4>Em Construção!</h4>
      <img src="/assets/under_construction.png" alt="Em construção" />
      <div className={classes.backLinkWrapper}>
        <BackLink />
      </div>
    </div>
  )
}

export default Construction
