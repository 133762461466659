/* eslint-disable react/prop-types */
// import {useSelector} from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import ButtonSecondary from '@root/components/ButtonSecondary'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import actionDispatcher from '@root/lib/actionDispatcher'
import React, {useState} from 'react'
import {setHeaderMainHeader} from '@root/redux/header/header.actions'

import useStyles from './styles'

const Receipts = () => {
  const classes = useStyles()

  const [selectedDate, setSelectedDate] = useState(new Date())

  const actions = actionDispatcher({setHeaderMainHeader})

  actions.setHeaderMainHeader()

  // const user = useSelector((state) => state.user.user)

  const handleChangeDate = (e) => {
    setSelectedDate(e)
  }

  return (
    <div className={classes.receipts}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Typography variant="h4" className={classes.titleContainer}>
          Carregar Comprovantes
        </Typography>

        <Typography variant="body1">
          É muito importante carregar todos os comprovantes para evitar cobranças indevidas.
        </Typography>

        <Paper elevation={2} className={classes.paper}>
          <div className={classes.typeDateReceiptWrapper}>
            <div className={classes.descriptionWrapper}>
              <Typography variant="body1">Tipo de comprovante</Typography>
            </div>

            <div className={classes.descriptionWrapper}>
              <Typography variant="body1">Data do comprovante</Typography>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="receipt_date"
                value={selectedDate}
                onChange={handleChangeDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
          </div>

          <div style={{marginBottom: '40px'}}>
            <ButtonTransparentBg>
              <span>Upload do Comprovante</span>
            </ButtonTransparentBg>
          </div>

          <div className={classes.descriptionWrapper}>
            <Typography variant="body1">Descrição</Typography>
            <textarea />
          </div>
        </Paper>

        <div style={{margin: '20px 0 40px'}}>
          <ButtonSecondary>
            <span className={classes.confirmButtonText}>CONFIRMAR</span>
          </ButtonSecondary>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default Receipts
