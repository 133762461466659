import {usePaymentsList} from '@root/application/service/repository/payment/PaymentRepository'
import useStyles from '@root/components/App/Admin/styles'
import PaymentsList from '@root/components/YourProperty/sections/SectionPayment/PaymentsList'
import usePaymentsHelper from '@root/components/YourProperty/sections/SectionPayment/usePaymentsHelper'
import PropTypes from 'prop-types'
import React from 'react'

const BlockPayment = ({rentfees, paymentMethod, propertyId, ownerId}) => {
  const classes = useStyles()

  const {isLoading, payments} = usePaymentsHelper(propertyId)

  return (
    <div className={classes.recebimentos}>
      <PaymentsList
        payments={payments}
        propertyId={propertyId}
        rentfees={rentfees}
        tenantMode={false}
        displayAdminInfo={true}
      />
    </div>
  )
}

BlockPayment.propTypes = {
  payments: PropTypes.array.isRequired,
  rentfees: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
}
BlockPayment.defaultProps = {}

export default BlockPayment
