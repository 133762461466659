import Typography from '@material-ui/core/Typography'
import fetcher from '@root/application/infra/api/fetcher'
import {isSameDay} from 'date-fns'
import dateParser from 'date-parser'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import React from 'react'
import Chart from 'react-apexcharts'

import {Box} from '../../../Layout/Container/Box/Box'
import {useStyles} from '../../styles'
import ContractStatus from './ContractStatus'

const chartIncomeOptions = {
  chart: {
    width: 300,
    type: 'donut',
    selection: {
      enabled: false,
    },
  },
  colors: ['#FED667', '#FFB100', '#2D7FFC'],
  legend: {
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
    position: 'bottom',
  },
  tooltip: {
    enabled: false,
  },
  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    hover: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
        value: 0,
      },
    },
  },
}

const SectionStatuses = ({paymentsStatuses}) => {
  const classes = useStyles()

  if (!paymentsStatuses) {
    return
  }

  const {
    rent_total,
    total_payments_number,
    original_payments_total_value,
    current_payments_total_value,
    paid_payments_accumulated,
    paid_payments_number,
    toReceive_payments_accumulated,
    toReceive_payments_number,
    interest_accumulated,
    fines_accumulated,
    pending_payments,
    payments,
  } = paymentsStatuses

  const chartLabels = [
    `Aluguel: ${formatFloatToBrCurrency(original_payments_total_value)}`,
    `Multa: ${formatFloatToBrCurrency(fines_accumulated)}`,
    `Juros: ${formatFloatToBrCurrency(interest_accumulated)}`,
  ]

  const chartIncomeSeries = [original_payments_total_value, fines_accumulated, interest_accumulated]

  return (
    <div className={classes.statusesSection}>
      <Box bgType="white" smallPadding>
        <Typography variant="h5" align="center" color="primary">
          ALUGUÉIS RECEBIDOS
        </Typography>
        <Typography variant="body1" align="center">
          Não inclui IPTU/ condomínio
        </Typography>

        <div className={classes.mainContainer}>
          <div className={classes.valoresParcelasRecebidos}>
            <Typography variant="body1" align="center">
              Valor recebido
            </Typography>
            <span>{formatFloatToBrCurrency(paid_payments_accumulated)}</span>
            <span>{paid_payments_number} parcelas</span>

            <div className={classes.valoresExtraInfoContainer}>
              <Typography variant="body1">Valor do contrato</Typography>
              <Typography variant="subtitle2">
                ({total_payments_number} parcelas de {formatFloatToBrCurrency(rent_total)})
              </Typography>
            </div>

            <div className={classes.valoresExtraInfoContainer}>
              <Typography variant="body1">Valor recebido + à receber</Typography>
              <Typography variant="subtitle2">(inclui multas e juros)</Typography>
            </div>

            <div className={`${classes.valoresExtraInfoContainer} ${classes.multasRecebidas}`}>
              <Typography variant="body1">Multas recebidas</Typography>
            </div>

            <div className={`${classes.valoresExtraInfoContainer} ${classes.jurosRecebidas}`}>
              <Typography variant="body1">Juros recebidos</Typography>
            </div>
          </div>
          <div className={`${classes.valoresParcelasRecebidos} ${classes.valoresParcelasAReceber}`}>
            <Typography variant="body1" align="center">
              Valor a receber
            </Typography>
            <span>{formatFloatToBrCurrency(toReceive_payments_accumulated)}</span>
            <span>{toReceive_payments_number} parcelas</span>

            <div className={classes.valoresExtraInfoContainer}>
              <span className={`${classes.valoresExtraInfoSpan} ${classes.originalPaymentsTotalValue}`}>
                {formatFloatToBrCurrency(original_payments_total_value)}
              </span>
            </div>
            <div className={classes.valoresExtraInfoContainer}>
              <span className={`${classes.valoresExtraInfoSpan} ${classes.currentPaymentsTotalValue}`}>
                {formatFloatToBrCurrency(current_payments_total_value)}
              </span>
            </div>

            <div className={classes.valoresExtraInfoContainer}>
              <span className={`${classes.valoresExtraInfoSpan} ${classes.finesAccumulated}`}>
                {formatFloatToBrCurrency(fines_accumulated)}
              </span>
            </div>
            <div className={classes.interestAccumulatedContainer}>
              <span className={`${classes.valoresExtraInfoSpan} ${classes.interestAccumulated}`}>
                {formatFloatToBrCurrency(interest_accumulated)}
              </span>
            </div>
          </div>
        </div>
      </Box>

      <ContractStatus payments={payments} pendingPayments={pending_payments} />

      <Box m="10px 0 0" bgType="white" smallPadding>
        <Typography variant="h5" align="center" color="primary">
          RECEITAS
        </Typography>

        <div>
          <Chart options={{...chartIncomeOptions, labels: [...chartLabels]}} series={chartIncomeSeries} type="donut" />
        </div>
      </Box>
    </div>
  )
}

export default SectionStatuses
