import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  property: {
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'flex-start',
    '& > p': {
      width: '100%',
    },
    '& ul': {
      'width': '100%',
      'margin': 0,
      'padding': '0',
      'paddingLeft': '20px',
      '& li > p': {
        position: 'relative',
        left: '-5px',
      },
      '& li::marker': {
        fontSize: '.5rem',
      },
    },
    '& img': {
      marginTop: '10px',
    },
  },
  propertyEditIconWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  tenant: {
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    '& h2': {
      width: '100%',
      margin: '4px 0',
    },
    '& h6': {
      width: '100%',
    },
  },
  tenantAvatarPhoto: {
    margin: '0 !important',
    width: '55px',
    height: '55px',
  },
  tenantGoToIconWrapper: {
    position: 'absolute',
    top: 8,
    right: 8,
    transform: 'translate(0, calc(50% - 4px))',
  },
  paymentGoToIconWrapper: {
    position: 'absolute',
    top: 8,
    right: 8,
    transform: 'translate(0, calc(25% - 4px))',
  },
  tenantAvatarInfo: {
    display: 'flex',
    height: '100%',
  },
  tenantInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sectionItem: {
    '& h2': {
      margin: '4px 0',
    },
    '& h6': {
      marginBottom: '8px',
    },
    '& button + button': {
      marginLeft: theme.margin[1],
    },
  },
  buttonsContainer: {
    'display': 'flex',
    '& button': {
      fontSize: '11px',
      fontWeight: 600,
      padding: '0.5rem 1rem',
    },
    '& button + button': {
      marginLeft: theme.margin[1],
    },
  },
  buttonsTenantContainer: {
    'margin-top': 12,
    'align-self': 'flex-start',
  },

  divider: {
    margin: '0',
  },
  sectionsContainer: {
    display: 'flex',
    gap: 20,
    flexWrap: 'wrap',
  },
  mainSections: {flex: 1},
  statusesSection: {
    'flex': 1,
    'display': 'flex',
    'flexDirection': 'column',
    '& h5': {
      fontWeight: 600,
    },
    '& p': {
      fontSize: 15,
    },
    '& h6': {
      fontSize: 13,
    },
  },
  mainContainer: {
    display: 'flex',
    marginTop: 20,
  },
  valoresParcelasRecebidos: {
    'flex': 1,
    'display': 'flex',
    'flexDirection': 'column',
    '& > p': {
      marginBottom: 5,
    },
    '& > span': {
      padding: '15px 20px',
      textAlign: 'center',
      color: theme.extraColors.GREEN,
      fontSize: 20,
      fontWeight: 600,
      border: `1px solid ${theme.extraColors.GRAY[500]}`,
    },
    '& > span + span': {
      color: 'inherit',
      borderTop: 'none',
      fontWeight: 400,
      fontSize: 18,
    },
  },
  valoresParcelasAReceber: {
    '& > span': {
      borderLeft: 'none',
    },
    '& > span + span': {
      borderLeft: 'none',
    },
  },
  valoresExtraInfoContainer: {
    marginTop: 20,
  },
  valoresExtraInfoSpan: {
    textAlign: 'right',
    color: theme.extraColors.GREEN,
    fontSize: 20,
    fontWeight: 600,
    display: 'block',
  },
  multasRecebidas: {
    '& > p': {
      marginTop: 5,
    },
  },
  jurosRecebidas: {
    '& > p': {
      marginTop: 8,
    },
  },
  originalPaymentsTotalValue: {
    marginTop: 5,
  },
  currentPaymentsTotalValue: {
    marginTop: 10,
  },
  finesAccumulated: {
    marginTop: 7,
  },
  interestAccumulatedContainer: {
    marginTop: 20,
  },
  interestAccumulated: {
    marginTop: '0 !important',
  },
  contractStatusMonthsWrapper: {
    'margin': '20px 0',
    'display': 'grid',
    'gridTemplateColumns': 'repeat(6, 1fr)',
    'gridAutoRows': 60,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  contractStatusChartLegendContainer: {
    'display': 'flex',
    'gap': 15,
    'flexWrap': 'wrap',
    'justifyContent': 'center',
    'width': '85%',
    'margin': '10px auto',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))
