import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import {darken} from 'polished'
import PropTypes from 'prop-types'
import React from 'react'

const isPurple = (color) => color === 'purple'
const isRed = (color) => color === 'red'

const getBgColor = (bgType, theme) => (isPurple(bgType) ? theme.palette.primary.main : theme.extraColors.RED)
const getBgDarken = (bgType) => (isPurple(bgType) ? 0.1 : 0.2)

const useStyles = makeStyles((theme) => ({
  root: {
    'width': ({width}) => width ?? 31,
    'height': ({height}) => height ?? 31,
    'padding': 2,
    'color': '#FFFFFF',
    'fontSize': 0,
    'background': (stylesProps) => getBgColor(stylesProps.bgType, theme),
    'borderRadius': theme.shape.borderRadius,
    '&:hover': {
      background: (stylesProps) => darken(getBgDarken(stylesProps.bgType), getBgColor(stylesProps.bgType, theme)),
    },
    'position': (stylesProps) => (stylesProps.isAbsolutePositionRightCenter ? 'absolute' : ''),
    'right': (stylesProps) => (stylesProps.isAbsolutePositionRightCenter ? 16 : ''),
    'top': (stylesProps) => (stylesProps.isAbsolutePositionRightCenter ? '50%' : ''),
    'transform': (stylesProps) => (stylesProps.isAbsolutePositionRightCenter ? 'translate(0, -50%)' : ''),
    'margin': (styleProps) => `0px ${styleProps.spaceRight}px 0px ${styleProps.spaceLeft}px`,
  },
}))

export function IconWrapper({
  children,
  width,
  height,
  bgType,
  isAbsolutePositionRightCenter,
  spaceRight,
  spaceLeft,
  ...rest
}) {
  const stylesProps = {width, height, bgType, spaceRight, spaceLeft, isAbsolutePositionRightCenter}

  const classes = useStyles(stylesProps)
  return (
    <IconButton className={classes.root} {...rest}>
      {children}
    </IconButton>
  )
}

IconWrapper.defaultProps = {
  bgType: 'purple',
  spaceRight: 0,
  spaceLeft: 0,
  width: 31,
  height: 31,
}

IconWrapper.propTypes = {
  children: PropTypes.element,
  bgType: PropTypes.oneOf(['purple', 'red']),
  isAbsolutePositionRightCenter: PropTypes.bool,
  spaceRight: PropTypes.number,
  spaceLeft: PropTypes.number,
  width: PropTypes.number,
  hwight: PropTypes.number,
}
