import {AllTerms} from '@root/components/../../src/TermsData'
import PageMini from '@root/components/PageMini'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

const PrivacyPolicy = ({history}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setNavbarTitleAction('Políticas de privacidade'))
  }, [])

  return (
    <PageMini displayBackButton={true}>
      <AllTerms />
    </PageMini>
  )
}

export default PrivacyPolicy
