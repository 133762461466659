import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ErrorModal from '@root/components/App/Login/ErrorModal'
import PhoneRegister from '@root/components/App/Login/forms/PhoneRegister'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import PageMini from '@root/components/PageMini'
import useRewards from '@root/hooks/useRewards'
import useSignin from '@root/hooks/useSignin'
import actionDispatcher from '@root/lib/actionDispatcher'
import {isDevEnv} from '@root/lib/envDetector'
import errorHandler from '@root/lib/errorHandler'
import {isMatchPath} from '@root/lib/invitePath'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setRegisterFormAction} from '@root/redux/signin/signin.actions'
import routes from '@root/utils/routes'
import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'

import RegisterTermsAccept from '../../../RegisterTermsAccept'

const Signup = ({formComp}) => {
  const history = useHistory()
  const reward = useRewards()
  const formHook = useForm()
  const btnContinuarRef = useRef()
  const screenContent = useMemo(() => formComp, [])

  const {handleUserCreation, handlePostSigninSuccess, handlePostSigninFail, signinError, setSigninError} = useSignin()
  const [isShowTermsModal, setIsShowTermsModal] = useState(false)

  const {handleSubmit, reset, getValues} = formHook

  const isLoggedUser = useSelector((state) => state.user.isLogged)
  const registerForm = useSelector((s) => s.signin.register_form)

  const path = window.location.pathname
  const isSigninPage = screenContent.isSignin
  const isRegisterPage = screenContent.isRegister

  const layoutConfig = useMemo(() => {
    const config = {
      outer: undefined,
      shownavback: true,
      navtitle: 'Cadastro',
      title: 'Por favor preencha os campos abaixo para continuar',
    }
    if (isSigninPage) {
      config.navtitle = 'Entrar'
    } else if (isMatchPath(path, routes.CONTRACT_APPROVAL_DONE)) {
      config.title = 'Gostaria de aproveitar e criar seu cadastro na Moben?'
      config.outer = (
        <>
          <Typography variant="body2">Muito obrigado! O proprietário já foi informado.</Typography>
          <Typography variant="body2">
            Em breve você receberá um email com um link para a assinatura digital do contrato.
          </Typography>
          <Typography variant="body2">Em caso de dúvidas fale conosco.</Typography>
        </>
      )
    }
    return config
  }, [path, isSigninPage])

  const actions = actionDispatcher({
    setRegisterFormAction,
    setNavbarTitleAction,
    setGlobalLoaderAction,
  })

  const handleContinue = async (form) => {
    actions.setGlobalLoaderAction(true)

    screenContent.formData = form
    const inUseResult = await screenContent.isInUse()

    if (inUseResult.exist) {
      actions.setGlobalLoaderAction(false)
      setSigninError(ErrorModal.openPayload(inUseResult.message))
      return
    }

    if (isRegisterPage) {
      actions.setGlobalLoaderAction(false)
      setIsShowTermsModal(true)
    } else {
      await handleSignup(form)
    }
  }

  const handleSignup = async (form) => {
    actions.setGlobalLoaderAction(true)

    try {
      const result = await screenContent.handler()

      if (result.redirectTo) {
        actions.setRegisterFormAction(form)
        history.push(result.redirectTo)
      } else {
        const creationPayload = await handleUserCreation(result.auth.user, result.extra)
        handlePostSigninSuccess(creationPayload)
      }
    } catch (e) {
      const message = e.humanMessage ?? 'Ops! Não foi possível fazer o login no momento'
      setSigninError(ErrorModal.openPayload(errorHandler(e, message)))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const handleAcceptAndCloseModal = async (status) => {
    setIsShowTermsModal(false)
    if (status) {
      const form = getValues()
      await handleSignup(form)
    }
  }

  useEffect(() => {
    isSigninPage ? actions.setNavbarTitleAction('Entrar') : actions.setNavbarTitleAction('Cadastrar')
  }, [actions, isSigninPage])

  useEffect(() => {
    const reward_code = reward.code
    if (registerForm.phonenumber) {
      reset({...registerForm})
    } else if (isDevEnv()) {
      const pass = '@k3jh324jsdf7@'
      reset({
        email: 'axelle.aikaterine@gmail.com',
        name: 'Axelle Aikaterine',
        phonenumber: '(11) 11111-1111',
        password: pass,
        confirm_password: pass,
      })
    }
    reset({reward_code})
  }, [])

  useEffect(() => {
    if (isLoggedUser) {
      history.replace(routes.ROOT)
    }
  }, [isLoggedUser])

  const FormComp = screenContent
  return (
    <>
      <PageMini
        displayBackButton={layoutConfig.shownavback}
        onBackClick={history.goBack}
        outerNode={layoutConfig.outer}
        navtitle={layoutConfig.navtitle}
        innerTitle={layoutConfig.title}>
        <Grid container direction="column" alignItems="center">
          <FormProvider {...formHook}>
            <Grid container direction="row" spacing={4} justify="center">
              <FormComp />
            </Grid>
            <Grid item>
              {isRegisterPage && (
                <FormItem
                  control={formHook.control}
                  errors={formHook.errors}
                  type="textfield"
                  name="reward_code"
                  label="Código de indicação"
                />
              )}
            </Grid>
          </FormProvider>

          <Grid item>
            <Button w="182px" h="48px" m="15px auto" f z="15px" fw={600} onClick={handleSubmit(handleContinue)}>
              CONTINUAR
            </Button>
          </Grid>

          <Grid item style={{width: '70%'}}>
            <Divider dashed={true} />
          </Grid>
          <Grid item>
            <Link to={screenContent.alternate.route}>{screenContent.alternate.text}</Link>
          </Grid>
        </Grid>

        <ErrorModal signinError={signinError} setSigninError={setSigninError} />
        {isShowTermsModal && <RegisterTermsAccept onClick={handleAcceptAndCloseModal} />}
      </PageMini>
    </>
  )
}

Signup.propTypes = {
  form: PropTypes.node.isRequired,
}

Signup.defaultProps = {
  form: PhoneRegister,
}
export default Signup
