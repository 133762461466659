import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import BankInfoForm from '@root/components/Forms/Bank/BankInfoForm'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import Title from '@root/components/Layout/Typography/Title'
import {currencyFormatProps} from '@root/components/NumberMask'
import RentFeesTotalDisplay from '@root/components/RentFeesTotalDisplay'
import {WhatsAppLinkWrapper} from '@root/components/WhatsAppLinkWrapper'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {bankDataToText} from '@root/lib/banksList'
import {buildFeesValues, shouldIncludeFeeWithRent} from '@root/lib/contract/buildFeesValues'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import {formatBrCurrencyToFloat, formatFloatToBrCurrency, formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {
  coverageFireInsuranceOptions,
  feesCondominiumOptions,
  feesFireInsuranceOptions,
  feesIptuOptions,
  paymentMethodsOptions,
} from '@root/lib/predefinedValuesOptions'
import {
  numberFormatValidateCurrency,
  numberFormatValidateCurrencyOptional,
  validateNumber,
  validateRequired,
} from '@root/lib/simpleValidators'
import React, {useEffect, useMemo} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setContractRentfeesAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {MOBEN_WHATSAPP} from '@root/utils/constants'
import {getTotalRent} from '@root/utils/rentfeesCalc'

import {Divider} from '../Divider/Divider'
import {Box} from '../Layout/Container/Box/Box'
import {useStyles} from './styles'

const PAYMENT_OPTIONS = paymentMethodsOptions

export function RentFees() {
  const classes = useStyles()
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, control, errors, reset, watch, setValue, getValues} = formHook
  const amounts = watch()

  const notIncludeIptu = watch('iptu_immune')
  const notIncludeCondominium = watch('condominium_immune')

  const responsibleCondominium = watch('condominium_responsible')
  const responsibleIptu = watch('iptu_responsible')
  const responsibleFire = watch('fire_insurance_responsible')

  const paymentMethod = watch('payment_method')

  const isMethodTransfer = paymentMethod === 'transfer'
  const isMethodOther = paymentMethod === 'other'

  const isInsuranceResponsibleOwnerContractTenantPay = responsibleFire == 'owner_contract_tenant_pay_with_rent'
  const isInsuranceResponsibleOwner = responsibleFire == 'owner'
  const isInsuranceResponsibleTenant = responsibleFire == 'tenant'

  const displayBillingCoverage = isInsuranceResponsibleOwnerContractTenantPay || isInsuranceResponsibleTenant
  const displayBillingAmount = isInsuranceResponsibleOwnerContractTenantPay

  const user = useSelector((s) => s.user.user)
  const propertySnap = useSelector((s) => s.property.snap)
  const property = useSelector((s) => s.property.data)
  const rentfees = useSelector((s) => s.property.data?.contract?.rentfees)
  const paymentData = useSelector((s) => s.property.data?.contract?.payment_method?.data)
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractRentfeesAction,
    setContractStepAction,
  })

  const updatedRentfees = useMemo(() => {
    const updated = {
      ...rentfees,
      rent: formatBrCurrencyToFloat(amounts.rent),
      ...buildFeesValues('iptu', amounts, 'year', notIncludeIptu),
      ...buildFeesValues('condominium', amounts, 'year', notIncludeCondominium), //banco ta uma zona, aqui precisa ser year por que ai o valor "mensal" fica OK
      ...buildFeesValues('fire_insurance', amounts, 'year'), // idem condominio
    }
    updated.total = getTotalRent(updated)
    return updated
  }, [amounts])

  const extraPaymentForm = useMemo(() => {
    let form = null
    if (isMethodTransfer) {
      form = (
        <>
          <Typography variant="body1" align="center" className="bold">
            Dados para transferência
          </Typography>
          <BankInfoForm bankdata={paymentData?.bank} delayInit={700} />
        </>
      )
    } else if (isMethodOther) {
      form = (
        <>
          <Typography variant="body1" align="center" className="bold">
            Como deve ser realizado o pagamento?
          </Typography>
          <FormItem
            control={control}
            errors={errors}
            fitContainer={true}
            type="textarea"
            label="Instruções"
            placeholder="informe os detalhes de como serão feitos os pagamentos"
            name="extra.instructions"
            defaultValue={paymentData?.instructions}
            rows={6}
            rules={{validate: validateRequired}}
          />
        </>
      )
    }

    return form
  }, [paymentMethod])

  const suggestedCoverage = formatFloatToBrCurrency((updatedRentfees.rent ?? 0) * 200)

  /** ----------------------------------------- */

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)
    let paymentMethodExtra = {}

    if (isMethodTransfer) {
      paymentMethodExtra = {bank: formData.bank}
      paymentMethodExtra.instructions = bankDataToText(formData.bank)
    } else if (isMethodOther) {
      paymentMethodExtra = {instructions: formData.extra.instructions}
    }
    try {
      const dataToUpdate = {
        'rent_amount': updatedRentfees.rent,
        'condominium_amount': updatedRentfees.condominium_annual,
        'iptu_amount': updatedRentfees.iptu,
        'include_iptu': updatedRentfees.include_iptu,
        'total_amount': updatedRentfees.total,
        'contract.total_amount': updatedRentfees.total,
        // --
        'contract.rentfees': {
          ...updatedRentfees,
          condominium_immune: formData.condominium_immune,
          iptu_immune: formData.iptu_immune,
          iptu_immune_confirmation: formData.iptu_immune_confirmation ?? false,
        },
        // --
        'contract.fire_insurance': {
          ...(property.contract.fire_insurance ?? {}),
          responsible: formData.fire_insurance_responsible,
          amount_coverage: formatBrCurrencyToFloat(formData.fire_insurance_amount_coverage),
          amount_prize: formatBrCurrencyToFloat(formData.fire_insurance),
          coverage: 0,
        },
        // --
        'contract.payment_method': {
          option: amounts.payment_method,
          data: paymentMethodExtra,
        },
      }

      await propertySnap.ref.update(prepareContractStepUpdate(dataToUpdate))

      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const handleValidateCondominium = (value) =>
    handleCurrencyValidation(value, shouldIncludeFeeWithRent(responsibleCondominium, notIncludeCondominium))
  const handleValidateIptu = (value) =>
    handleCurrencyValidation(value, shouldIncludeFeeWithRent(responsibleIptu, notIncludeIptu))

  const handleCurrencyValidation = (value, isRequired) => {
    if (isRequired) {
      return numberFormatValidateCurrency(value)
    }
    return numberFormatValidateCurrencyOptional(value)
  }

  useEffect(() => actions.setContractStepAction(getStepByPath(history.location.pathname)), [])

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    const rent = rentfees?.rent ?? property?.rent_amount
    const condominium = rentfees?.condominium ?? property?.condominium_amount
    const condominium_immune = rentfees?.condominium_immune ?? false
    const iptu = rentfees?.iptu ?? property?.iptu_amount
    const iptu_immune = rentfees?.iptu_immune ?? false
    const fire_insurance = property?.contract?.fire_insurance
    const payment_method = property?.contract?.payment_method?.option

    const resetPayload = {
      ...rentfees,
      fire_insurance: fire_insurance?.amount_prize,
      fire_insurance_amount_coverage: fire_insurance?.amount_coverage,
      fire_insurance_responsible: fire_insurance?.responsible,
      rent,
      condominium,
      condominium_immune,
      iptu,
      iptu_immune,
      payment_method,
    }
    reset(resetPayload)
  }, [rentfees])

  return (
    <ContractContainer>
      <FormProvider {...formHook}>
        <form className={`${classes.root} ${classes.rentFees}`}>
          <section>
            <Title>Aluguel</Title>
            <Box bgType="gray" smallPadding>
              <div className={classes.rentFeesAmountsContainer}>
                <Text>Valor mensal do aluguel</Text>
                <FormItem
                  className={classes.rentfeeGridItemValue}
                  control={control}
                  errors={errors}
                  type="mask"
                  name="rent"
                  rules={{
                    validate: numberFormatValidateCurrency,
                  }}
                  maskInputProps={currencyFormatProps}
                />
              </div>
            </Box>
            <TextBlock>
              Esse é o valor que você quer cobrar de aluguel sem considerar os custos de Condomínio e IPTU.
            </TextBlock>
          </section>
          <Divider dashed />

          <section>
            <Title>Condomínio</Title>
            <Box bgType="gray" smallPadding>
              <div className={classes.rentFeesAmountsContainer}>
                <Text>Valor mensal do condomínio</Text>
                <FormItem
                  className={classes.rentfeeGridItemValue}
                  control={control}
                  errors={errors}
                  type="currency"
                  name="condominium"
                  defaultValue="0"
                  maskInputProps={currencyFormatProps}
                  rules={{validate: handleValidateCondominium}}
                />
              </div>
            </Box>
            <FormItem
              control={control}
              errors={errors}
              name="condominium_immune"
              type="checkbox-single"
              options={[
                {label: 'Imóvel não integra condomínio e não é cobrado nenhum valor desta natureza', value: false},
              ]}
            />

            {!notIncludeCondominium && (
              <>
                <TextBlock>
                  No valor do condomínio as despesas ordinárias são de responsabilidade do inquiliino e despesas
                  extraordinárias são de responsabilidade do proprietário.
                </TextBlock>
                <FormItem
                  fitContainer
                  control={control}
                  errors={errors}
                  type="radio"
                  name="condominium_responsible"
                  options={feesCondominiumOptions}
                  rules={{validate: validateRequired}}
                />
              </>
            )}
          </section>
          <Divider dashed />

          <section>
            <Title>Seguro Incêndio</Title>
            <TextBlock>
              Por lei a contratação do Seguro Incêndio é uma responsabilidade do Proprietário. Mas você pode repassar
              essa obrigação para o Inquilino especificando esta opção no contrato. Como deseja proceder em relação a
              forma de contratação e valores do Seguro Incêndio.
            </TextBlock>
            <FormItem
              fitContainer
              control={control}
              errors={errors}
              type="radio"
              name="fire_insurance_responsible"
              options={feesFireInsuranceOptions}
              rules={{validate: validateRequired}}
            />

            {displayBillingCoverage && (
              <Box bgType="gray" smallPadding>
                <div className={classes.rentFeesAmountsContainer}>
                  <Typography variant="body1">Valor de cobertura do seguro</Typography>
                  <div className={classes.rentFeesCoverageContainer}>
                    <FormItem
                      className={classes.rentfeeGridItemValue}
                      control={control}
                      errors={errors}
                      type="currency"
                      name="fire_insurance_amount_coverage"
                      rules={{validate: numberFormatValidateCurrency}}
                      options={coverageFireInsuranceOptions}
                    />
                    <Text className={classes.rentFeesCoverageText}>
                      Valor sugerido
                      <br />
                      {suggestedCoverage}
                    </Text>
                    {/*
                      <Typography variant="body1" className={classes.rentFeesCoverageText}>
                        vezes o valor
                        <br />
                        do aluguel
                      </Typography>
                    */}
                  </div>
                </div>
                <TextBlock>
                  Valor de cobertura do seguro é o valor de cobertura máximo que a seguradora pagará em caso de
                  sinistro.
                  <br />
                  É importante definir em contrato para dar tranquilidade ao inquilino de que a renovação do seguro
                  estará dentro de um limite e, desta forma, não haverá um aumento repentino e não programado desta
                  cobrança.
                  <br />
                  Sugerimos um valor de até 200 vezes o valor do aluguel.
                </TextBlock>
              </Box>
            )}

            {displayBillingAmount && (
              <Box bgType="gray" smallPadding style={{marginTop: 12}}>
                <div className={classes.rentFeesAmountsContainer}>
                  <Typography variant="body1">Valor da Mensalidade do Seguro</Typography>
                  <div className={classes.rentFeesCoverageContainer}>
                    <FormItem
                      className={classes.rentfeeGridItemValue}
                      control={control}
                      errors={errors}
                      type="currency"
                      name="fire_insurance"
                      defaultValue="0"
                      rules={{validate: numberFormatValidateCurrency}}
                    />
                  </div>
                </div>
                <TextBlock>
                  Valor da Mensalidade de Seguro é o valor cobrado pela seguradora (preço do seguro).
                  <br />
                  Este valor será cobrado do inquilino no total mensal a ser pago para o proprietário.
                </TextBlock>
              </Box>
            )}
          </section>
          <Divider dashed />

          <section>
            <Title>IPTU</Title>
            <Box bgType="gray" smallPadding>
              <div className={classes.rentFeesAmountsContainer}>
                <Text>Valor anual do IPTU</Text>
                <FormItem
                  className={classes.rentfeeGridItemValue}
                  control={control}
                  errors={errors}
                  type="currency"
                  name="iptu"
                  defaultValue="0"
                  maskInputProps={currencyFormatProps}
                  rules={{validate: handleValidateIptu}}
                />
              </div>
            </Box>
            <TextBlock>Responsável pelo pagamento e quitação do IPTU</TextBlock>
            <FormItem
              control={control}
              errors={errors}
              name="iptu_immune"
              type="checkbox-single"
              options={[{label: 'Meu imóvel  é isento de IPTU', value: false}]}
            />
            {notIncludeIptu && (
              <FormItem
                control={control}
                errors={errors}
                name="iptu_immune_confirmation"
                type="checkbox-single"
                rules={{validate: validateRequired}}
                options={[
                  {
                    label:
                      'Para efeito de contrato, caso o IPTU volte a ser cobrado neste imóvel eu estou ciente que estou fazendo a opção abaixo',
                    value: false,
                  },
                ]}
              />
            )}

            <FormItem
              fitContainer
              control={control}
              errors={errors}
              type="radio"
              name="iptu_responsible"
              options={feesIptuOptions}
              rules={{validate: validateRequired}}
            />

            <TextBlock>
              *Caso você escolha incluir no boleto, o valor total do IPTU será dividido em 12 parcelas.
            </TextBlock>
          </section>
          <Divider dashed />

          <section>
            <TextBlock>
              Nos pagamentos monitorados pela Moben enviaremos notificações antes do vencimento, cobranças em caso de
              atraso e avisos de liquidação do boleto. Você pode decidir por outras formas de cobrar o aluguel, mas
              nestes casos, a Moben não terá acesso à liquidação mensal do aluguel.
            </TextBlock>

            <FormItem
              fitContainer
              control={control}
              errors={errors}
              type="radio"
              name="payment_method"
              options={PAYMENT_OPTIONS}
              rules={{validate: validateRequired}}
            />
          </section>

          {extraPaymentForm && (
            <>
              <Divider dashed />
              {extraPaymentForm}
            </>
          )}

          <div className={classes.rentFeesTotalContainer}>
            <RentFeesTotalDisplay rentfees={updatedRentfees} />
          </div>
        </form>
      </FormProvider>
      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
