import PropTypes from 'prop-types'
import React from 'react'
const IconPayment = ({fill, size}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={{width: size, height: size}}
      viewBox="0 0 109 109">
      <g id="Grupo_67" data-name="Grupo 67" transform="translate(18982 9755)">
        <g id="Grupo_65" data-name="Grupo 65" transform="translate(-4295.931 484)">
          <g
            id="Retângulo_54"
            data-name="Retângulo 54"
            transform="translate(-14663.121 -10209.244)"
            fill="#fff"
            stroke={fill}
            strokeWidth="2">
            <rect width="61.914" height="46.651" rx="10" stroke="none" />
            <rect x="1" y="1" width="59.914" height="44.651" rx="9" fill="none" />
          </g>
          <g id="União_1" data-name="União 1" transform="translate(-14632.165 -10219)" fill="#fff">
            <path
              d="M 1.691461682319641 28.46474838256836 L 5.200370788574219 16.96368026733398 L 5.266940593719482 16.74551010131836 L 5.232310771942139 16.52005958557129 C 5.162700653076172 16.06682968139648 5.127400875091553 15.60275077819824 5.127400875091553 15.140700340271 L 5.127400875091553 9.999900817871094 C 5.127400875091553 5.03734016418457 9.164740562438965 1.000000357627869 14.12730121612549 1.000000357627869 L 32.0265007019043 1.000000357627869 C 36.98905944824219 1.000000357627869 41.02640151977539 5.03734016418457 41.02640151977539 9.999900817871094 L 41.02640151977539 15.140700340271 C 41.02640151977539 20.1032600402832 36.98905944824219 24.14060020446777 32.0265007019043 24.14060020446777 L 14.12730121612549 24.14060020446777 C 13.03908061981201 24.14060020446777 11.97567081451416 23.94868087768555 10.9666109085083 23.57018089294434 L 10.52295112609863 23.40376091003418 L 10.11319065093994 23.64173126220703 L 8.621177673339844 24.50823211669922 L 1.691461682319641 28.46474838256836 Z"
              stroke="none"
            />
            <path
              d="M 3.382936239242554 26.34749412536621 L 8.1221923828125 23.6416187286377 L 10.43049049377441 22.30105018615723 L 11.31782054901123 22.63389015197754 C 12.21419048309326 22.97011947631836 13.15943050384521 23.14060020446777 14.12730121612549 23.14060020446777 L 32.0265007019043 23.14060020446777 C 36.43766021728516 23.14060020446777 40.02640151977539 19.55186080932617 40.02640151977539 15.140700340271 L 40.02640151977539 9.999900817871094 C 40.02640151977539 5.588740348815918 36.43766021728516 2.000000476837158 32.0265007019043 2.000000476837158 L 14.12730121612549 2.000000476837158 C 9.716140747070312 2.000000476837158 6.127400875091553 5.588740348815918 6.127400875091553 9.999900817871094 L 6.127400875091553 15.140700340271 C 6.127400875091553 15.55209064483643 6.158800601959229 15.96509075164795 6.220720767974854 16.36824035644531 L 6.289980888366699 16.81915092468262 L 3.382936239242554 26.34749412536621 M 7.598877118653036e-07 30.58200073242188 L 4.243900775909424 16.67187118530273 C 4.167250633239746 16.17283058166504 4.127400875091553 15.66121006011963 4.127400875091553 15.140700340271 L 4.127400875091553 9.999900817871094 C 4.127400875091553 4.477500438690186 8.604900360107422 3.662109406832315e-07 14.12730121612549 3.662109406832315e-07 L 32.0265007019043 3.662109406832315e-07 C 37.54890060424805 3.662109406832315e-07 42.02640151977539 4.477500438690186 42.02640151977539 9.999900817871094 L 42.02640151977539 15.140700340271 C 42.02640151977539 20.66399955749512 37.54890060424805 25.14060020446777 32.0265007019043 25.14060020446777 L 14.12730121612549 25.14060020446777 C 12.89164066314697 25.14060020446777 11.70798110961914 24.91631126403809 10.61540031433105 24.5064811706543 L 9.120600700378418 25.37459945678711 L 7.598877118653036e-07 30.58200073242188 Z"
              stroke="none"
              fill={fill}
            />
          </g>
          <g
            id="Retângulo_55"
            data-name="Retângulo 55"
            transform="translate(-14663.121 -10172.471)"
            fill="#fff"
            stroke={fill}
            strokeWidth="2">
            <rect width="61.914" height="15.385" stroke="none" />
            <rect x="1" y="1" width="59.914" height="13.385" fill="none" />
          </g>
          <g id="Caminho_129" data-name="Caminho 129" transform="translate(-14668 -10164.021)" fill="#fff">
            <path
              d="M 68.66826629638672 8.561930656433105 L 3.001895427703857 8.561930656433105 C 1.898045420646667 8.561930656433105 1.00000536441803 7.663890361785889 1.00000536441803 6.560040473937988 L 1.00000536441803 2.80767035484314 C 1.00000536441803 1.70382022857666 1.898045420646667 0.8057802319526672 3.001895427703857 0.8057802319526672 L 20.47064781188965 0.8057802319526672 L 27.62477493286133 3.733180284500122 L 27.80680465698242 3.80767035484314 L 28.00348472595215 3.80767035484314 L 44.34347534179688 3.80767035484314 L 44.55391693115234 3.80767035484314 L 44.74650573730469 3.722860336303711 L 51.63221740722656 0.6905577182769775 L 68.66150665283203 0.8057602643966675 L 68.66488647460938 0.8057802319526672 L 68.66826629638672 0.8057802319526672 C 69.77211761474609 0.8057802319526672 70.67015838623047 1.70382022857666 70.67015838623047 2.80767035484314 L 70.67015838623047 6.560040473937988 C 70.67015838623047 7.663890361785889 69.77211761474609 8.561930656433105 68.66826629638672 8.561930656433105 Z"
              stroke="none"
            />
            <path
              d="M 51.83942413330078 1.691980838775635 L 44.76435089111328 4.807674884796143 L 27.6101188659668 4.807674884796143 L 20.27394866943359 1.805785655975342 L 3.001884460449219 1.805785655975342 C 2.449440002441406 1.805785655975342 2 2.25523042678833 2 2.807674884796143 L 2 6.560041427612305 C 2 7.112485885620117 2.449440002441406 7.561930179595947 3.001884460449219 7.561930179595947 L 68.66826629638672 7.561930179595947 C 69.22071075439453 7.561930179595947 69.67015075683594 7.112485885620117 69.67015075683594 6.560041427612305 L 69.67015075683594 2.807674884796143 C 69.67015075683594 2.25523042678833 69.22071075439453 1.805785655975342 68.66826629638672 1.805785655975342 L 68.65473175048828 1.805741310119629 L 51.83942413330078 1.691980838775635 M 51.42501831054688 -0.3108701705932617 L 68.66826629638672 -0.1942138671875 C 70.32616424560547 -0.1942138671875 71.67015075683594 1.149774551391602 71.67015075683594 2.807674884796143 L 71.67015075683594 6.560041427612305 C 71.67015075683594 8.217929840087891 70.32616424560547 9.561930656433105 68.66826629638672 9.561930656433105 L 3.001884460449219 9.561930656433105 C 1.343994140625 9.561930656433105 0 8.217929840087891 0 6.560041427612305 L 0 2.807674884796143 C 0 1.149774551391602 1.343994140625 -0.1942138671875 3.001884460449219 -0.1942138671875 L 20.66732025146484 -0.1942138671875 L 28.00348663330078 2.807674884796143 L 44.34347534179688 2.807674884796143 L 51.42501831054688 -0.3108701705932617 Z"
              stroke="none"
              fill={fill}
            />
          </g>
          <text
            id="_"
            data-name="$"
            transform="translate(-14614.001 -10200)"
            fill={fill}
            fontSize="21"
            fontFamily="Tahoma"
            letterSpacing="-0.03em">
            <tspan x="0" y="0">
              $
            </tspan>
          </text>
          <rect
            id="Retângulo_57"
            data-name="Retângulo 57"
            width="6.379"
            height="1.126"
            rx="0.563"
            transform="translate(-14658.619 -10167.968)"
            fill={fill}
          />
        </g>
        <g id="Grupo_66" data-name="Grupo 66">
          <path
            id="paper-11960"
            d="M54.5,0A54.5,54.5,0,1,0,109,54.5,54.517,54.517,0,0,0,54.5,0Zm0,2.271A52.229,52.229,0,1,1,2.271,54.5,52.212,52.212,0,0,1,54.5,2.271Z"
            transform="translate(-18982 -9755)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

IconPayment.propTypes = {
  size: PropTypes.any,
}
IconPayment.defaultProps = {
  size: '109px',
}
export default IconPayment
