import Page from '@root/components/Page'
import WhatsAppButton from '@root/components/WhatsAppButton'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

function AssistenciaJuridica() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarTitleAction('Assistência Jurídica'))
  }, [])

  return (
    <Page>
      <p>
        Nosso serviço de Assistência Jurídica é prestado por advogados especializados em contratos de locação de
        imóveis.
      </p>
      <p style={{fontWeight: 700, margin: '20px 0px'}}>
        Entre em contato e faremos uma cotação personalizada sem compromisso.
      </p>
      <WhatsAppButton />
    </Page>
  )
}

export default AssistenciaJuridica
