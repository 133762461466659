import Typography from '@material-ui/core/Typography'
import proptypesTypography from '@root/components/Layout/Typography/proptypesTypography'
import React from 'react'

const TextBlock = ({children, ...props}) => {
  return (
    <Typography {...props} variant="body2">
      {children}
    </Typography>
  )
}

TextBlock.propTypes = {}
TextBlock.defaultProps = {}

export default TextBlock
