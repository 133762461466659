import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      overflow: 'hidden',
    },
    p: {
      fontSize: '1.2rem',
    },
  },
  'root': {
    flexGrow: 1,
    height: '100vh',
  },
  'gridContainer': {
    height: '100%',
  },
  'gridItem': {
    height: '100%',
    padding: 0,
  },
  'gridItemTxt': {
    padding: '4rem',
  },
  'gridImgContainer': {
    alignItems: 'stretch',
  },
  'errorImg': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  'logoImg': {
    display: 'block',
    width: '68%',
    marginBottom: '3rem',
    cursor: 'pointer',
  },
  'smallContainer': {
    marginTop: '1rem',
  },
  'emailLink': {
    color: 'black',
  },
  '@media (max-width: 868px)': {
    '@global': {
      body: {
        overflowY: 'scroll',
      },
    },
    'gridItemTxt': {
      padding: '2rem',
    },
  },
  '@media (max-width: 600px)': {
    gridItemTxt: {
      padding: '1rem',
    },
  },
  '@media (max-height: 650px)': {
    '@global': {
      body: {
        overflowY: 'scroll',
      },
    },
  },
}))

export default useStyles
