import {Typography} from '@material-ui/core'
import ShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ShareInvite'
import useStyles from '@root/components/App/Contract/styles.js'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import InviteForm from '@root/components/Forms/InviteForm'
import IconCheck from '@root/components/icons/IconCheck'
import {INVITE_HAS_ANALYSIS} from '@root/components/InviteRegister/Invite'
import PageMini from '@root/components/PageMini'
import analyticsLogger from '@root/firebase/analytics.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import ProposalManager from '@root/lib/contract/ProposalManager'
import errorHandler from '@root/lib/errorHandler'
import {getInviteFiador} from '@root/lib/inviteLinkCreator'
import {proposalSaver} from '@root/lib/proposalSaver'
import {USER_TYPE_FIADOR} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {USER_SOURCE_INVITE_FIADOR} from '@root/utils/constants'
import routes, {routeWithParams} from '@root/utils/routes'

// const proposalHelper = new ProposalHelper()
const proposalManager = new ProposalManager()
const TenantFiadorRegister = ({match}) => {
  const classes = useStyles()
  const history = useHistory()

  const formHook = useForm()
  const {handleSubmit, control, errors, setValue} = formHook

  const user = useSelector((s) => s.user.user)
  const [isFiadorOptionsVisible, setFiadorOptionsVisible] = useState(false)
  const [screenView, setScreenView] = useState()
  const [currentFiador, setFiador] = useState({})
  const [propertySnap, setPropertySnap] = useState()

  const propertyIdParam = match.params.propertyId
  const creditAnalysisParam = match.params.creditAnalysis
  const proposalIdParam = match.params.proposalId

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setNavbarTitleAction,
  })

  const goToIQPainel = () => {
    history.push(routeWithParams(routes.DASHBOARD_TENANT_PROPERTY_LIST))
  }

  const handleUseFiador = (e) => {
    if (e.target.value === 'yes') {
      analyticsLogger.logEvent('convite_fiador_sim')
      setFiadorOptionsVisible(() => !isFiadorOptionsVisible)
    } else {
      analyticsLogger.logEvent('convite_fiador_nao')
      goToIQPainel()
    }
  }
  const handleFiadorOptions = (e) => {
    setScreenView(e.target.value)
  }

  const inviteEmailHandler = async (email) => {
    actions.setGlobalLoaderAction(true)
    const response = await propertyModel.fetchInviteByEmail(email, user.uid, propertyIdParam)
    actions.setGlobalLoaderAction(false)
    alert(response)
  }

  const saveFiador = async (form) => {
    actions.setGlobalLoaderAction(true)

    try {
      await proposalSaver({
        propertySnap,
        proposalId: proposalIdParam,
        manager: proposalManager,
        tenantFormData: {...currentFiador, ...form},
        proposalType: USER_TYPE_FIADOR,
        originSource: USER_SOURCE_INVITE_FIADOR,
      })
    } catch (ex) {
      alert(errorHandler(ex))
      actions.setGlobalLoaderAction(false)
      return
    }

    goToIQPainel()
    actions.setGlobalLoaderAction(false)
  }

  useEffect(() => {
    actions.setNavbarTitleAction('Fiador')

    const run = async () => {
      const propertySnap = await propertyModel.getById(propertyIdParam)
      const property = propertySnap.data()

      setPropertySnap(propertySnap)
      proposalManager.initFromProperty(property)

      if (proposalManager.exists(proposalIdParam)) {
        proposalManager.selectProposalById(proposalIdParam)

        actions.setGlobalLoaderAction(true)
        await proposalManager.getSelected().loadUsersRefs()
        const fiador = proposalManager.getSelected().getFiadorAt(0)
        if (fiador) {
          setValue('use_fiador', 'yes')

          setFiadorOptionsVisible(true)
          setScreenView('form')
          setTimeout(() => {
            setValue('fiador_options', 'form')
            setFiador(fiador)
            actions.setGlobalLoaderAction(false)
          }, 500)
        } else {
          actions.setGlobalLoaderAction(false)
        }
      } else {
        alert('Proposta não encontrada.')
        history.push(routes.REGISTER_MYDATA_INCOME)
      }
    }
    run()
  }, [])

  return (
    <PageMini>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10}}>
        <Typography>Você vai utilizar Fiador como garantia?</Typography>
        <FormItem
          control={control}
          errors={errors}
          required={false}
          extraStyles="row"
          type="radio"
          label=""
          name="use_fiador"
          onChange={handleUseFiador}
          options={[optionFactory('Sim', 'yes'), optionFactory('Não', 'no')]}
        />
      </div>

      {isFiadorOptionsVisible && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <FormItem
            control={control}
            errors={errors}
            required={false}
            extraStyles="row"
            type="radio"
            label=""
            name="fiador_options"
            onChange={handleFiadorOptions}
            options={[optionFactory('Cadastrar fiador', 'form'), optionFactory('Convidar fiador por email', 'invite')]}
          />
        </div>
      )}

      {screenView === 'form' && (
        <FormProvider {...formHook}>
          <InviteForm allowFullEdit={true} user={currentFiador} />

          <Button
            m="10px auto"
            w="168px"
            h="48px"
            fz="15px"
            bgType="green"
            icon={IconCheck}
            onClick={handleSubmit(saveFiador)}>
            Continuar
          </Button>
        </FormProvider>
      )}

      {screenView === 'invite' && (
        <>
          <ShareInvite
            shareLink={getInviteFiador(propertyIdParam, proposalIdParam, creditAnalysisParam === INVITE_HAS_ANALYSIS)}
            onInviteByEmail={inviteEmailHandler}
            subtitle=""
          />
          <Button m="10px auto" w="168px" h="48px" fz="15px" bgType="green" icon={IconCheck} onClick={goToIQPainel}>
            Continuar
          </Button>
        </>
      )}
    </PageMini>
  )
}
export default TenantFiadorRegister
