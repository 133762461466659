import {singleFilePickerHandler} from '@root/lib/filePickerHandler'
import PropTypes from 'prop-types'
import React, {useRef} from 'react'

/**
 * Abstracao para selecao de arquivos.
 * O elemento filho fica automaticamente clicavel, abrindo a tela de selecao de arquivo.
 *
 * o onChange retorna um objeto do tipo File ou undefined
 */
const FilePicker = (props) => {
  const fileRef = useRef()

  const triggerInputClick = () => fileRef.current.click()
  const handleFileChange = (e) => {
    let selected = []
    if (props.multiple) {
      selected = Array.from(e.target?.files ?? [])
    } else {
      selected = singleFilePickerHandler(e)
    }
    if (selected && props.onChange) {
      props.onChange(selected, e)
    }
  }

  return (
    <div className="file-picker" id={props.id} style={props.style} onClick={triggerInputClick}>
      {props.children}
      <input
        style={{display: 'none'}}
        id={props.id + '-picker'}
        onChange={handleFileChange}
        type="file"
        accept={props.accept}
        name={props.name}
        multiple={props.multiple}
        ref={fileRef}
      />
    </div>
  )
}
FilePicker.defaultProps = {
  accept: 'image/*',
  multiple: false,
}

FilePicker.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  multiple: PropTypes.bool,
}
export default FilePicker
