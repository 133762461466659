/* eslint-disable no-empty */
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import NumberMask from '@root/components/NumberMask'
import React from 'react'

const useStyles = makeStyles({
  helperText: {
    color: 'red !important',
  },
})

const MaskRender = (controllerProps, helpers, formItemProps) => {
  const {hasError, applyShrink, forwardOnChange, forwardOnBlur, forwardOnFocus} = helpers
  const {value} = controllerProps
  const {maskInputProps, label, type, 'data-testid': dataTestId, id, ...restFormItemProps} = formItemProps

  const classes = useStyles()
  return (
    <TextField
      inputRef={helpers.inputRef}
      error={hasError}
      label={label}
      InputLabelProps={{shrink: applyShrink}}
      InputProps={{
        inputComponent: NumberMask,
        inputProps: {
          ...maskInputProps,
          'id': id,
          'data-testid': dataTestId ?? '',
          'defaultValue': value || '',
        },
      }}
      {...restFormItemProps}
      onChange={forwardOnChange}
      onBlur={forwardOnBlur}
      onFocus={forwardOnFocus}
      FormHelperTextProps={{
        style: {color: 'red'},
      }}
    />
  )
}

export default MaskRender
