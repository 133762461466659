import Grid from '@material-ui/core/Grid'
import {addBreadcrumb} from '@sentry/react'
import {propertyRepoInstance} from '@root/application/service/repository/property/PropertyRepository'
import ContractDisplay from '@root/components/App/Admin/ContractEditor/ContractIDisplay'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import {Button} from '@root/components/Button/Button'
import IconSave from '@root/components/icons/IconSave'
import {forceArrayUnion, forceArrayUnionSingle} from '@root/firebase/firebase.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import AddressDisplay from '@root/lib/addressDisplay'
import addressDisplay from '@root/lib/addressDisplay'
import errorHandler, {addBreadCrumb} from '@root/lib/errorHandler'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  clearContractManagerModified,
  setContractManagerInitial,
} from '@root/redux/adminContractManager/adminContractManager.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {LOADER_MODE_TEXT} from '@root/utils/constants'

let loadTries = 0
const ContractManager = ({match}) => {
  const propertyId = match.params.propertyId

  const [managerInfo, setManagerInfo] = useState()

  const actions = actionDispatcher({
    setContractManagerInitial,
    clearContractManagerModified,
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
  })

  const user = useSelector((s) => s.user.user)

  const appendixes = useSelector((s) => s.adminContract.appendixes)
  const clauses = useSelector((s) => s.adminContract.clauses)
  const itemsUpdated = useSelector((s) => s.adminContract.updateList)
  const hasItemsModified = useSelector((s) => s.adminContract.hasModified)

  const property = managerInfo?.property

  const loadProperty = async () => {
    if (loadTries > 1) {
      alert('Ops! Falhas continuas foram detectadas, entre me contato conosco.')
      actions.setGlobalLoaderAction(false)
      return
    }

    actions.setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, {title: 'Carregando contrato para edição'})
    const propSnap = await propertyModel.getById(propertyId)
    const prop = propSnap.data()
    const contractRaw = prop?.contract?.raw
    setManagerInfo({
      ref: propSnap.ref,
      property: prop,
    })

    if (!contractRaw) {
      await fetchCreateContractRaw()
    } else {
      actions.setGlobalLoaderAction(false)
      dispatchContractInitial(contractRaw)
    }
  }

  const fetchCreateContractRaw = async () => {
    actions.setGlobalLoaderWithModeAction(true, LOADER_MODE_TEXT, {
      title: 'Contrato ainda não existe',
      subtitle: 'Criando ele...',
    })
    try {
      await propertyRepoInstance.createContract(property)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
      return
    }
    loadTries++
    await loadProperty()
  }

  const dispatchContractInitial = (raw) => {
    actions.setContractManagerInitial(raw.appendixes, raw.clauses)
  }

  const handleSave = async () => {
    actions.setGlobalLoaderAction(true)
    try {
      await managerInfo.ref.update({
        'contract.raw': {
          appendixes,
          clauses,
        },
        'contract.is_manually_edited': true,
        'contract_editor_history': forceArrayUnion(
          itemsUpdated.map((itemUpdate) => ({
            created_at: new Date(),
            edited_by: user.name,
            ...itemUpdate,
          })),
        ),
      })
      actions.clearContractManagerModified()
      alert('Salvo com sucesso')
    } catch (ex) {
      addBreadCrumb('history list', itemsUpdated)
      alert(errorHandler(ex, 'Erro ao salvar contrato'))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  useEffect(() => {
    loadProperty()
  }, [])

  return (
    <Grid container direction="column">
      <Grid item>
        {property && (
          <ul>
            <li>
              <DataDisplay label="ID" value={property.uid} />
            </li>
            <li>
              <DataDisplay label="ID Proprietario" value={property.ower_id} />
            </li>
            <li>
              <DataDisplay label="Apelido" value={property.alias} />
            </li>
            <li>
              <DataDisplay label="Endereco" value={addressDisplay(property.address)} />
            </li>
          </ul>
        )}
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item>
          <Button icon={IconSave} disabled={!hasItemsModified} onClick={handleSave}>
            SALVAR
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4} direction="row">
        <Grid item xs={12} md={6}>
          <ContractDisplay
            section="Anexo"
            entryPoint="appendixes"
            enableAdd={true}
            enableDelete={false}
            list={appendixes}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ContractDisplay
            section="Clausulas"
            entryPoint="clauses"
            enableAdd={true}
            enableDelete={true}
            list={clauses}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContractManager
