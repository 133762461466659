/** @module ContractContinueButton */
import {ButtonProximoItem} from '@root/components/Button/Button'
import {useStyles} from '@root/components/Contract/styles'
import {isContractBlocked as isContractBlockedFunc} from '@root/lib/contract/progressChecker'
import {getNextStep} from '@root/lib/contract/stepWalker'
import createAlternativeEventName from '@root/lib/createAlternativeEventName'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'

const ContractContinueButton = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const {...buttonProps} = props
  const property = useSelector((s) => s.property.data)
  const isContractBlocked = isContractBlockedFunc(property)

  const handleBlockedContinue = () => history.push(getNextStep().path)

  const buttonConfig = useMemo(() => {
    const config = {
      isBlocked: false,
      id: 'continuar',
    }

    if (isContractBlocked) {
      config.isBlocked = true
      config.id = 'continuar_bloqueado'
    }

    return config
  }, [isContractBlocked])

  const handler = buttonConfig.isBlocked ? handleBlockedContinue : props?.onClick

  if (props.children) {
    return <div className={classes.proximoItemButtonWrapper}>{props.children}</div>
  }
  return (
    <div className={classes.proximoItemButtonWrapper}>
      <ButtonProximoItem {...buttonProps} onClick={handler} id={createAlternativeEventName(buttonConfig.id)} />

      {buttonConfig.isBlocked && <small style={{fontSize: '0.75rem', marginTop: 12}}>não pode ser editado</small>}
    </div>
  )
}

export default ContractContinueButton
