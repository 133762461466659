import {generateConfig} from '@root/components/App/CreditAnalysis/ButtonsMap/creditAnalysisMaps'
import {Button} from '@root/components/Button/Button'
import analyticsLogger from '@root/firebase/analytics.utils'
import React from 'react'

/* 
{
  "name": "RAIMUNDO GOMES FERREIRA",
  "document_status": {
    "code": 1,
    "icon": "IconCheck",
    "label": "Status do CPF",
    "hex": "#087E04",
    "color": "green",
    "prop_name": "document",
    "reason": "CPF consta como regular na Receita Federal"
  },
  "score": {
    "code": 4,
    "icon": "IconTrashCan",
    "label": "Score",
    "hex": "#F8120A",
    "color": "red",
    "prop_name": "score",
    "reason": "A pessoa interessada pertence a um grupo de score no qual possivelmente mais de 3 em cada 10 pessoas deve apresentar débitos nos próximos 12 meses."
  }, 
*/
const AnalysisButton = (props) => {
  if (!props.analysisItem) {
    return null
  }

  const {code, icon, label, hex, accent, color, prop_name, reason} = props.analysisItem

  const handleClick = (e) => {
    analyticsLogger.logEvent('analise_credito_info_' + prop_name, {prop_name, code})
    if (props.onClick) {
      props.onClick(e)
    }
  }
  return (
    // tem alguns icones desalinhas e cores erradas, por hora sem icones
    // <Button bgType={config.color} icon={config.icon} p="8px 0" w="100%" h="29px" {...props} onClick={handleClick}>
    <Button bgType={color} p="12px 8px" w="100%" {...props} onClick={handleClick}>
      <span style={{fontSize: '14px', fontWeight: 600, color: accent}}>{label}</span>
    </Button>
  )
}

export default AnalysisButton
