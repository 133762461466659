/** @module dateParser */
/* eslint-disable no-prototype-builtins */
import 'firebase/firestore'

import {differenceInYears, format, parse} from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import firebase from 'firebase/app'

const isoPattern = /^\d{4}-\d{2}-\d{2}((\s|T|t)?\d{1,2}:)?/
const brPattern = /^\d{2}\/\d{2}\/\d{4}$/
const brDatetimePattern = /^\d{2}\/\d{2}\/\d{4}(\s|T|t)?\d{1,2}:/
const timePiece = 'T00:00:00'

const monthsShortMap = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ',
}

const monthsFullMap = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
}

const localeConfig = {locale: pt}
/**
 * @typedef {{seconds:number, nanoseconds:number}|{_seconds:number, _nanoseconds:number}} TimestampLike
 */
/**
 * @typedef {Date|string|TimestampLike|firebase.firestore.Timestamp} DateLike
 */

export const fromTimestampToDate = (ts) => {
  let date, seconds
  if (ts.seconds) {
    seconds = ts.seconds
  } else if (ts._seconds) {
    seconds = ts._seconds
  }
  date = new Date(seconds * 1000)
  return date
}

/**
 * @param {TimestampLike} ts
 * @return {boolean}
 */
const isFirebaseTimeStampLike = (ts) => {
  return (
    ts &&
    ((ts.hasOwnProperty('seconds') && ts.hasOwnProperty('nanoseconds')) ||
      (ts.hasOwnProperty('_seconds') && ts.hasOwnProperty('_nanoseconds')))
  )
}

/**
 * @param {DateLike} dateValue Se string precisa estar no formato YYYY-MM-DD ou DD/MM/YYYY ou timestamp do firebase
 * @return {Date|undefined}
 */
export const dateParser = (dateValue) => {
  let dateStr
  let date

  if (dateValue instanceof Date && !isNaN(dateValue.getTime())) {
    date = dateValue
  } else if (dateValue instanceof firebase.firestore.Timestamp) {
    date = dateValue.toDate()
  } else if (isFirebaseTimeStampLike(dateValue)) {
    date = fromTimestampToDate(dateValue)
  } else if (isoPattern.test(dateValue)) {
    date = new Date(dateValue)
  } else if (brPattern.test(dateValue)) {
    date = parse(dateValue, 'dd/MM/yyyy', new Date())
  }

  if (dateStr) {
    let datetime = `${dateStr}${timePiece}`
    date = new Date(datetime)
  }

  return date
}

/**
 * @param {DateLike} dateValue
 * @param {boolean} withTime
 * @return {string}
 */
export const convertDateToBr = (dateValue, withTime = false, type = 'full') => {
  const date = dateParser(dateValue)
  let dateFormat = 'dd/MM/yyyy'

  if (type === 'short') {
    dateFormat = 'dd/MM'
  }

  let timeFormat = ''
  if (withTime) {
    timeFormat = ' HH:mm'
  }

  return date ? format(date, dateFormat + timeFormat, localeConfig) : ''
}

/**
 * @param {DateLike} dateValue
 * @return {string}
 */
export const convertDateToIso = (dateValue) => format(dateParser(dateValue), 'yyyy-MM-dd', localeConfig)

/**
 * @param {DateLike} dateValue
 * @param {string} dateFormat
 * @return {string}
 */
export const dateFormatCustom = (dateValue, dateFormat) => format(dateParser(dateValue), dateFormat, localeConfig)

export const calcAge = (date) => differenceInYears(Date.now(), dateParser(date))

export default dateParser

export const getYear = (date) => date.getFullYear()

export const getMonth = (date, type = 'short') => {
  if (!date) return ''

  if (type === 'short') {
    return monthsShortMap[date.getMonth()]
  } else {
    return monthsFullMap[date.getMonth()]
  }
}

export const getMonthYear = (date) => {
  if (!date) return ''

  return `${getMonth(date, 'full')} ${getYear(date)}`
}
