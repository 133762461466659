import 'firebase/firestore'

import {Button} from '@root/components/Button/Button'
import FilePickerMultiple from '@root/components/FilePickerMultiple'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import IconCheck from '@root/components/icons/IconCheck'
import {currencyFormatProps} from '@root/components/NumberMask'
import PageMini from '@root/components/PageMini'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {numberFormatValidateCurrency, validateCheckboxMultiple, validateRequired} from '@root/lib/simpleValidators'
import userModel from '@root/model/userModel'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {setCurrentUser} from '@root/redux/user/user.actions'
import routes from '@root/utils/routes'

const INCOME_SOURCE_OPTIONS = [
  optionFactory('Salário', 'salario'),
  optionFactory('Pró-Labore', 'prolabore'),
  optionFactory('Dividendos', 'dividendos'),
  optionFactory('Aluguéis', 'alugueis'),
  optionFactory('Aposentadoria', 'aposentaria'),
  optionFactory('Investimentos', 'investimentos'),
  optionFactory('Outros', 'outros'),
]

const RegisterIncome = ({match}) => {
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, control, errors, reset, watch} = formHook

  const incomeSources = watch('income_sources')
  const hasIncomeSourceOthers = incomeSources?.outros

  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setCurrentUser,
    setNavbarTitleAction,
  })

  const handleContinue = async (form) => {
    actions.setGlobalLoaderAction(true)
    try {
      await userRef.update({
        income: formatMoneyBrToFloat(form.income),
        income_sources: {
          ...form.income_sources,
          outros_resposta: form.outros_resposta ?? '',
        },
      })
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
      return
    }

    // try {
    //   if (form.docs.income instanceof File) {
    //     const uploaded = await firebaseUploader(form.docs.income, user.uid, `/user/documents/${user.uid}/`)
    //     await userRef.update('documents.income', uploaded)
    //   }
    // } catch (ex) {
    //   actions.setGlobalLoaderAction(false)
    //   alert('Erro ao enviar o arquivo')
    // }

    actions.setGlobalLoaderAction(false)
    history.push(routes.REGISTER_MYDATA_FINANCIAL_OBLIGATIONS)
  }

  useEffect(() => {
    reset({
      income: user.income,
      income_sources: user.income_sources,
      outros_resposta: user.income_sources?.outros_resposta ?? '',
    })
  }, [user])

  useEffect(() => {
    actions.setNavbarTitleAction('Renda')
  }, [])

  const onChangeFileCallback = async (uploadedFiles) => {
    const list = user?.documents.income ?? []
    const images = [...list, ...uploadedFiles]

    await userModel.updateUser(
      {
        ['documents.income']: images,
      },
      user?.uid,
    )
  }

  const onDeleteFileCallback = async (url) => {
    const list = user?.documents.income ?? []

    const filteredImagesArr = list.filter((doc) => doc.download_url !== url)

    await userModel.updateUser(
      {
        ['documents.income']: filteredImagesArr,
      },
      user?.uid,
    )
  }

  return (
    <PageMini
      innerTitle="Você precisa comprovar renda mensal bruta, aqui você pode somar valores
          como salário, rendimentos com investimentos, alugueis entre outras fontes de renda. Tenha em mãos todos os
          documentos para comprovar as informações inseridas!">
      <FormProvider {...formHook}>
        <FormItem
          control={control}
          errors={errors}
          type="mask"
          label="Renda bruta mensal"
          name="income"
          maskInputProps={currencyFormatProps}
          rules={{
            validate: numberFormatValidateCurrency,
          }}
        />

        {/* <FormItem
          control={control}
          errors={errors}
          fitContainer
          type="file"
          label="Comprovante de renda"
          name="docs.income"
          rules={{
            validate: validateRequired,
          }}
        /> */}

        <FilePickerMultiple
          label="Anexar comprovantes de rendas"
          uid={user.uid}
          list={user?.documents.income ?? []}
          filePrefix={user.uid}
          section={`/user/documents/${user.uid}/`}
          displayLinks
          onChangeFileCallback={onChangeFileCallback}
          onDeleteFileCallback={onDeleteFileCallback}
        />

        <div style={{marginTop: 20}}>
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            type="checkbox"
            extraStyles="row"
            label="Marque abaixo a natureza desta fonte de renda"
            name="income_sources"
            options={INCOME_SOURCE_OPTIONS}
            rules={{
              validate: validateCheckboxMultiple,
            }}
          />
        </div>

        {hasIncomeSourceOthers && (
          <FormItem
            control={control}
            errors={errors}
            fitContainer
            label=""
            name="outros_resposta"
            placeholder="Especifique quais as outras fontes"
            rules={{
              validate: validateRequired,
            }}
          />
        )}

        <Button
          m="10px auto"
          w="168px"
          h="48px"
          fz="15pt"
          bgType="green"
          icon={IconCheck}
          onClick={handleSubmit(handleContinue)}>
          Continuar
        </Button>
      </FormProvider>
    </PageMini>
  )
}
RegisterIncome.propTypes = {
  match: PropTypes.any,
}
export default RegisterIncome
