import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  home: {
    backgroundColor: '#FFF',
    maxWidth: '1439px',
    margin: '0 auto',
  },
  homeH1: {
    color: '#8301CD',
    fontSize: '36px',
    width: '65%',
    margin: '20px auto 0',
    textAlign: 'center',
    fontWeight: '600',
    [theme.breakpoints.down(740)]: {
      width: '95%',
      margin: '0 auto',
      fontSize: '24px',
    },
  },
}))

export default useStyles
