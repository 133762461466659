import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import analyticsLogger from '@root/firebase/analytics.utils'
import {auth} from '@root/firebase/firebase.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import {discordNotifyUserDeclinedTerms} from '@root/lib/discordNotification'
import {signOut} from '@root/lib/signIn'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setTermsAction} from '@root/redux/signin/signin.actions'
import {setCurrentUser, setUserNull} from '@root/redux/user/user.actions'

import useStyles from './styles'
import {
  CollectedData,
  ConditionsTermTxt,
  DataTermTxt,
  DefinitionsTermTxt,
  IntroTermTxt,
  ITEM_WITH_SUBMENU,
  ITEM_WITH_SUBMENU_ID,
  PRIVACY_POLICY_PATH,
  PrivacyTermTxt,
  ShareTermTxt,
  StorageTermTxt,
  termConditionsDropdown,
} from './TermsData'

const RegisterTermsAccept = ({onClick = undefined}) => {
  const history = useHistory()
  const appIsReady = useSelector((s) => s.application.ready)
  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)
  const isLogged = useSelector((s) => s.user.isLogged)
  const userId = useSelector((s) => s.user.id)
  const classes = useStyles()
  const [subMenu, showSubMenu] = useState(false)
  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))
  const actions = actionDispatcher({
    setCurrentUser,
    setUserNull,
    setTermsAction,
  })

  const handleEvents = ( event_type, alternative_event_name, button_name ) =>{
    console.log("GA EVENT: ", event_type, alternative_event_name, button_name)
    analyticsLogger.logEvent(event_type, {
      alternative_event_name,
      button_name,
    })
  }
  
  const termsNavLinks = [
    {title: 'Introdução', component: <IntroTermTxt />},
    {title: 'Definições Iniciais', component: <DefinitionsTermTxt />},
    {
      title: ITEM_WITH_SUBMENU,
      submenu: true,
      // symbol: subMenu ? <ArrowDropDownIcon /> : <ArrowLeftIcon />,
      component: <ConditionsTermTxt />,
    },
    {title: 'Política de Privacidade', component: <PrivacyTermTxt />},
    {title: 'Dados Coletados', component: <CollectedData />},
    {title: 'Utilização de Dados', component: <DataTermTxt />},
    {title: 'Compartilhamento de Informações', component: <ShareTermTxt />},
    {title: 'Armazenamento de Dados', component: <StorageTermTxt />},
  ]
  const [selectedTermsInfo, setSelectedTermsInfo] = useState(termsNavLinks[0])
  // const hideTerms =
  //   !appIsReady ||
  //   !isLogged ||
  //   // userId ||
  //   user?.terms_accepted?.register?.status === 'accepted' ||
  //   window.location.pathname === PRIVACY_POLICY_PATH
  //
  // if (hideTerms) {
  //   return null
  // }

  // const declineTerms = () => {
  //   analyticsLogger.logEvent('delete_account', {
  //     alternative_event_name: 'recusar_termos',
  //     button_name: 'cancelar',
  //   })
  //   discordNotifyUserDeclinedTerms(user)
  //   userRef.delete()
  //   auth.currentUser.delete()
  //   signOut(history)
  // }

  // const acceptTerms = () => {
  //   const acceptRegisterData = {
  //     status: 'accepted',
  //     accepted_at: new Date(),
  //   }
  //   const updateUserData = {
  //     'terms_accepted.register': acceptRegisterData,
  //   }
  //   userRef.update(updateUserData)
  //   actions.setTermsAction(true)
  //
  //   analyticsLogger.logEvent('accept_terms', {
  //     alternative_event_name: 'aceitar_termos',
  //     button_name: 'aceitar',
  //   })
  // }

  const toggleSubMenu = (term) => {
    const conditionsObj = termsNavLinks[2]
    if (term.title === conditionsObj.title) {
      renderTermsComponent(conditionsObj)
      setTimeout(() => (window.location.hash = ITEM_WITH_SUBMENU_ID), 100)
    }
    showSubMenu(!subMenu)
  }

  const renderTermsComponent = (item) => {
    if (!item.submenu) {
      showSubMenu(false)
    }
    setSelectedTermsInfo(item)
    const eventName = `click_term_${item.title.split(' ').join('_').toLowerCase()}`
    analyticsLogger.logEvent(eventName)
  }

  const loopDropdown = () => {
    let location,
      title,
      submenu = ''
    for (const [name, innerObj] of Object.entries(termConditionsDropdown)) {
      title = innerObj.title
      location = `#${innerObj.id}`
      submenu += `
      <li key=${name}>
        <a href=${location} style='color:#831CCD !important'>${title}</a>
      </li>
      `
    }
    return <div className={classes.subMenuDiv} dangerouslySetInnerHTML={{__html: submenu}} />
  }

  return (
    <Dialog
      fullScreen={!!isExtraSmallViewPort}
      scroll="body"
      maxWidth="md"
      open={true}
      classes={{paper: classes.dialog}}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignContent="center"
        className={classes.dialogGridcontainer}>
        <Grid item xs={12} sm={5} className={classes.termsNavLinks}>
          <ul className={classes.termsNav}>
            {termsNavLinks.map((termObj) => (
              <>
                <a
                  key={termObj.title}
                  className={classes.termsNavLink}
                  // onClick={termObj?.submenu ? () => toggleSubMenu(termObj) : () => renderTermsComponent(termObj)}>
                  onClick={() => renderTermsComponent(termObj)}>
                  {termObj.title} {termObj?.submenu ? termObj.symbol : null}
                </a>
                {/* <ul>{termObj?.submenu && subMenu ? loopDropdown() : null}</ul> */}
              </>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.termsContainer}>
          {/* <DialogTitle>{selectedTermsInfo.title}</DialogTitle> */}
          <DialogContent className={classes.dialogContentRoot}>{selectedTermsInfo.component}</DialogContent>
        </Grid>
        <Grid item xs={12} className={classes.dialogBtns}>
          <Button onClick={() => {
            handleEvents('button_click', 'reject-terms', 'Não Aceitar')
            onClick(false)
          }} className={classes.rejectTerms}>
            
            Não aceitar
          </Button>
          <Button variant="contained" onClick={() => {
            handleEvents('button_click', 'accept-terms', 'Aceitar')
            onClick(true)
          }} color="primary" className={classes.acceptTerms}>
            Aceitar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default RegisterTermsAccept
