import {Popper} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import {
  ANSWER_COMMERCIAL,
  ANSWER_EMPTY,
  ANSWER_NO,
  ANSWER_OCCUPIED,
  ANSWER_RESIDENTIAL,
  ANSWER_YES,
} from '@root/components/App/Cadastro/cadastro-components/TenantMoreAbout/DefaultRadioGroup'
import {ButtonProximoItem} from '@root/components/Button/Button'
import FilePickerMultiple from '@root/components/FilePickerMultiple'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import RequiredMark from '@root/components/FormHelper/RequiredMark'
import AddressForm from '@root/components/Forms/AddressForm'
import NumberFormatCustom from '@root/components/NumberFormatCustom'
import {currencyFormatProps} from '@root/components/NumberMask'
import {firestore} from '@root/firebase/firebase.utils'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {isContractEditable, updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import createAlternativeEventName from '@root/lib/createAlternativeEventName'
import {discordNotifyNewProperty} from '@root/lib/discordNotification'
import errorHandler from '@root/lib/errorHandler'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import {isMatchPath} from '@root/lib/invitePath'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {yesOrNotOptions} from '@root/lib/predefinedValuesOptions'
import {safePropertyReset} from '@root/lib/safeReset'
import {
  numberFormatValidateCurrency,
  numberFormatValidateCurrencyOptional,
  validateNumber,
  validateRequired,
} from '@root/lib/simpleValidators'
import propertyModel from '@root/model/propertyModel'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setContractFromDatabaseAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {resetPropertyFromSnap} from '@root/redux/property/property.actions'
import {createPropertyInitialState} from '@root/redux/property/property.reducer'
import routes from '@root/utils/routes'
import produce from 'immer'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'

import {Divider} from '../Divider/Divider'
import HelpTextComponent from '../HelpTextComponent/HelpTextComponent'
import {Box} from '../Layout/Container/Box/Box'
import FilePickerMultipleNewProperty from './FilePickerMultipleNewProperty'
import {useStyles} from './styles'

export function PropertyRegister() {
  const classes = useStyles()
  const inputHelper = {
    imovelMobiliado: [
      'Consideramos um imóvel como mobiliado se você vai deixar no imóvel ao menos 4 dos seguintes itens: armários na cozinha, armários no quarto, geladeira, cama, sofá, mesas e cadeiras.',
      '\n',
      'Armário embutido;',
      'Armário comum;',
      'Linha Branca;',
      'Sofá;',
      'Cama;',
      'Mesa;',
      'Cadeira;',
      'Box vidro;',
      'Outros',
    ],

    imovelEnxoval: [
      'Consideramos como enxoval itens como louças, talheres, artigos de cama, mesa e banho entre outros.',
    ],
  }

  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, watch, control, errors, reset, getValues, setValue} = formHook
  const [docs, setDocs] = useState({
    deed: [],
    power_attorney: [],
    contract_document: [],
  })

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
    setNavbarTitleAction,
    resetPropertyFromSnap,
    setContractFromDatabaseAction,
  })

  const formWatcher = watch([
    'rent_amount',
    'condominium_amount',
    'iptu_amount',
    'occupation_type',
    'occupation_sector',
  ])

  const totalFee = useMemo(() => {
    const total =
      formatMoneyBrToFloat(formWatcher.rent_amount) +
      formatMoneyBrToFloat(formWatcher.condominium_amount) +
      formatMoneyBrToFloat(formWatcher.iptu_amount) / 12
    return total <= 0 ? 0 : total
  }, [formWatcher])

  const user = useSelector((s) => s.user.user)
  const userSnap = useSelector((s) => s.user.firebaseRef)
  const currentPropertySnap = useSelector((s) => s.property?.snap)
  const property = useSelector((s) => s.property?.data)
  const propertyId = useSelector((s) => s.property.currentPropertyId)

  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const isContractRoute = useMemo(() => isMatchPath(window.location.pathname, routes.CONTRACT_PROPERTY), [])
  const isNewRoute = useMemo(() => isMatchPath(window.location.pathname, routes.REGISTER_PROPERTY_NEW, false, true), [])
  const isEditRoute = useMemo(
    () => isMatchPath(window.location.pathname, routes.REGISTER_PROPERTY_EDIT, false, true),
    [],
  )

  const operationHandlers = {
    contract: {
      buttonTitle: 'Próximo item',
      navTitle: '',
      disabled: !isContractEditable(property),
      prefillAddress: true,
      router: () => history.push(pathNextStepAfterSave()),
      buildPayload: (propertySnap, formData) => {
        const propertyData = propertySnap.data()
        const progressInfo = updateProgressDataFromRedux(propertyData)
        return {
          rent_amount: property.rent_amount,
          iptu_amount: property.iptu_amount,
          condominium_amount: property.condominium_amount,
          total_amount: property.total_amount,
          ...formData,
          [progressInfo.path]: progressInfo.payload,
          more_about: {
            ...propertyData?.more_about,
            ...formData.more_about,
          },
          specs: {
            ...propertyData?.specs,
            ...formData.specs,
          },
          address: {
            ...propertyData?.address,
            ...formData.address,
          },
        }
      },
      save: async (propertyRef, data) => propertyRef.update(prepareContractStepUpdate(data)),
      postSave: undefined,
    },
    update: {
      buttonTitle: 'Salvar',
      navTitle: 'Alterar meu imóvel',
      disabled: !isContractEditable(property),
      prefillAddress: true,
      router: () => history.replace(routes.DASHBOARD_ROOT),
      buildPayload: (propertySnap, formData) => {
        const propertyData = propertySnap.data()
        return {
          ...formData,
          more_about: {
            ...propertyData?.more_about,
            ...formData.more_about,
          },
          specs: {
            ...propertyData?.specs,
            ...formData.specs,
          },
          address: {
            ...propertyData?.address,
            ...formData.address,
          },
        }
      },
      save: async (propertyRef, data) => propertyRef.update(prepareContractStepUpdate(data)),
      postSave: undefined,
    },
    create: {
      buttonTitle: 'Criar imóvel',
      navTitle: 'Cadastrando um imóvel',
      disabled: false,
      prefillAddress: false,
      router: () => history.replace(routes.DASHBOARD_ROOT),
      buildPayload: (propertySnap, formData, propertyId) => ({
        ...createPropertyInitialState().data,
        ...formData,
        uid: propertyId,
        ower_id: user.uid,
        created_at: new Date(),
      }),
      save: (propertyRef, data) => propertyRef.set(data),
      postSave: async (propertyRef) => {
        const savedPropertySnap = await propertyRef.get()
        actions.resetPropertyFromSnap(savedPropertySnap)
        actions.setContractFromDatabaseAction(savedPropertySnap.data().contract, savedPropertySnap)

        userSnap.update('is_owner', true)
      },
    },
  }

  const currentOperationHandler = useMemo(() => {
    if (isContractRoute) {
      return operationHandlers.contract
    } else if (isEditRoute) {
      return operationHandlers.update
    } else {
      return operationHandlers.create
    }
  }, [isContractRoute, isNewRoute, isEditRoute])

  const handleRouterOperation = () => {
    if (currentOperationHandler.router) {
      currentOperationHandler.router()
    }
  }

  const getField = (filePrefix) => {
    const filePrefixArray = filePrefix.split('')
    filePrefixArray.splice(-1, 1)
    return filePrefixArray.join('')
  }

  const onChangeFileCallback = useCallback(async (uploadedFiles, filePrefix) => {
    const field = getField(filePrefix)

    const list = property?.[field] ?? []

    await propertyModel.updateById(propertyId, field, [...list, ...uploadedFiles])

    field === 'contract_document' && setValue('occupation_type', ANSWER_OCCUPIED)
  })

  const onDeleteFileCallback = useCallback(async (url, filePrefix) => {
    const field = getField(filePrefix)

    const filteredArr = property?.[field].filter((doc) => doc.download_url !== url)

    await propertyModel.updateById(propertyId, field, [...filteredArr])

    field === 'contract_document' && setValue('occupation_type', ANSWER_OCCUPIED)
  })

  const handleClickContinuar = async (formData) => {
    if (currentOperationHandler.disabled) {
      handleRouterOperation()
      return
    }
    actions.setGlobalLoaderAction(true)

    let propertyRef, propertySnap
    if (isNewRoute) {
      propertyRef = firestore.collection(propertyModel.COLLECTION_NAME).doc()
    } else {
      propertyRef = currentPropertySnap.ref
      propertySnap = currentPropertySnap
    }
    let currentPropertyId = propertyRef.id

    try {
      const tempProperty = currentOperationHandler.buildPayload(propertySnap, formData, currentPropertyId)
      tempProperty.total_amount = totalFee

      if (isNewRoute) {
        if (docs.contract_document.length) {
          const contract_document = await handleUpload('contract_document', currentPropertyId)
          tempProperty.contract_document = [...contract_document]
        }

        if (docs.deed.length) {
          const deed = await handleUpload('deed', currentPropertyId)
          tempProperty.deed = [...deed]
        }

        if (docs.power_attorney.length) {
          const power_attorney = await handleUpload('power_attorney', currentPropertyId)
          tempProperty.power_attorney = [...power_attorney]
        }
      }

      const sanitized = propertyModel.prepareForDatabase(tempProperty)
      // esta passando o propertyRef e o sanitized por que ali em cima cria uma "isntancia" diferente dependendo da situacao
      // esse propertyRef seria mais uma referencia de onde salvar, o sanitezes sao os dados ja prontos de fato
      await currentOperationHandler.save(propertyRef, sanitized)

      if (currentOperationHandler.postSave) {
        await currentOperationHandler.postSave(propertyRef)
      }

      if (isNewRoute) {
        discordNotifyNewProperty(user, tempProperty)
      }
      handleRouterOperation()
    } catch (ex) {
      alert(errorHandler(ex))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  const handleUpload = async (uploadField, uid) => {
    if (docs[uploadField].length) {
      return await firebaseFilesUploader(docs[uploadField], `${uploadField}_` + uid, `property/${uid}`)
    }
  }

  const handleDocChange = (file, event) => {
    const name = event.target.name
    setDocs(
      produce(docs, (draft) => {
        draft[name].file = file
      }),
    )
  }

  useEffect(() => {
    const step = getStepByPath(history.location.pathname)
    if (step && isContractRoute) {
      actions.setContractStepAction(step)
      // operationHandlers.contract.navTitle = step.title
    } else {
      actions.setNavbarTitleAction(currentOperationHandler.navTitle)
    }
  }, [])

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    if (property && propertyId && (isEditRoute || isContractRoute)) {
      safePropertyReset(property, reset)
    }
  }, [property])

  const radioStyles = {
    radioLabelStyle: {textAlign: 'center'},
    style: {marginBottom: 10},
  }

  return (
    <ContractContainer>
      <FormProvider {...formHook}>
        <form>
          <section className={classes.section}>
            <header style={{margin: '0 0 15px'}}>
              <Typography variant="h6" color="secondary" align="center">
                Informações básicas do imóvel
              </Typography>
            </header>

            <FormItem
              color="secondary"
              required
              fitContainer
              control={control}
              errors={errors}
              label="Nome do imóvel (visível apenas para você)"
              name="alias"
              rules={{validate: validateRequired}}
              style={{margin: '0 0 0'}}
            />

            {/* <AddressForm address={isEditRoute ? property?.address : undefined} /> */}
            <AddressForm address={currentOperationHandler.prefillAddress ? property?.address : undefined} />

            <div className={classes.specsContainer}>
              <FormItem
                color="secondary"
                required
                control={control}
                errors={errors}
                type="textfield"
                name="specs.footage"
                label="Metragem (m&#178;)"
                rules={{
                  validate: validateNumber,
                }}
              />

              <FormItem
                color="secondary"
                required
                control={control}
                errors={errors}
                type="textfield"
                name="specs.rooms"
                label="Quartos"
                rules={{
                  validate: validateNumber,
                }}
              />

              <FormItem
                color="secondary"
                required
                control={control}
                errors={errors}
                type="textfield"
                name="specs.bathrooms"
                label="Banheiros"
                rules={{
                  validate: validateNumber,
                }}
              />

              <FormItem
                color="secondary"
                required
                control={control}
                errors={errors}
                type="textfield"
                name="specs.garage"
                label="Garagens"
                rules={{
                  validate: validateNumber,
                }}
              />
            </div>

            <FormItem
              fitContainer
              formItemId="numero_matricula"
              color="secondary"
              control={control}
              errors={errors}
              type="textfield"
              name="taxpayer"
              required={false}
              label="Número de matrícula do contribuinte (opcional)"
              defaultValue=""
            />
          </section>

          <Divider dashed />

          <section className={classes.section}>
            <header style={{margin: '15px 0 0'}}>
              <Typography variant="h6" color="secondary" align="center">
                Qual a situação do imóvel?
              </Typography>
            </header>

            <FormItem
              fitContainer
              control={control}
              errors={errors}
              type="radio"
              name="occupation_type"
              options={[optionFactory('Disponível', ANSWER_EMPTY), optionFactory('Ocupado', ANSWER_OCCUPIED)]}
              rules={{validate: validateRequired}}
              extraStyles="row"
            />

            {formWatcher.occupation_type === ANSWER_OCCUPIED && (
              <>
                <div className={classes.anexarArquivoWrapper}>
                  <div className={classes.body2Wrapper}>
                    <Typography variant="body2" align="center" style={{marginBottom: '10px'}}>
                      Se você já tem um contrato assinado inclua na plataforma para podermos analisar.
                    </Typography>
                  </div>
                  <div>
                    {isNewRoute ? (
                      <FilePickerMultipleNewProperty
                        label="Carregar Documentos"
                        filePrefix="contract_document"
                        displayLinks
                        handleDocs={setDocs}
                        docs={docs}
                      />
                    ) : (
                      <FilePickerMultiple
                        label="Carregar Documentos"
                        uid={property?.uid}
                        filePrefix="contract_document_"
                        section={`property/${property?.uid}`}
                        list={property?.contract_document ?? []}
                        displayLinks
                        onChangeFileCallback={onChangeFileCallback}
                        onDeleteFileCallback={onDeleteFileCallback}
                      />
                    )}
                  </div>
                </div>

                <RadioItem
                  required
                  fitContainer
                  label="Imóvel gerenciado por imobiliária?"
                  control={control}
                  errors={errors}
                  type="radio"
                  name="managed_by_realestate"
                  options={[optionFactory('Sim', ANSWER_YES), optionFactory('Não', ANSWER_NO)]}
                  rules={{validate: validateRequired}}
                  extraStyles="row"
                  style={radioStyles.style}
                  radioLabelStyle={radioStyles.radioLabelStyle}
                />
              </>
            )}

            <RadioItem
              required
              fitContainer
              label="Tipo de imóvel"
              control={control}
              errors={errors}
              type="radio"
              name="occupation_sector"
              options={[
                {
                  label: 'Residencial',
                  value: ANSWER_RESIDENTIAL,
                },
                {
                  label: 'Comercial',
                  value: ANSWER_COMMERCIAL,
                },
              ]}
              rules={{validate: validateRequired}}
              extraStyles="row"
              style={radioStyles.style}
              radioLabelStyle={radioStyles.radioLabelStyle}
            />

            {formWatcher.occupation_sector === ANSWER_COMMERCIAL && (
              <Box bgType="gray" p="0 10px 4px" m="0 0 30px">
                <div className={classes.comercialActivityContainer}>
                  <Typography variant="body1">Atividade Comercial*</Typography>
                  <FormItem
                    control={control}
                    errors={errors}
                    required={true}
                    name="comercial_activity"
                    rules={{
                      validate: validateRequired,
                    }}
                  />
                </div>
              </Box>
            )}

            <RadioItem
              required
              fitContainer
              helpText={inputHelper['imovelMobiliado']}
              label="Imóvel mobiliado?"
              control={control}
              errors={errors}
              type="radio"
              name="more_about.furnished"
              options={yesOrNotOptions}
              rules={{validate: validateRequired}}
              extraStyles="row"
              style={radioStyles.style}
              radioLabelStyle={radioStyles.radioLabelStyle}
            />

            <RadioItem
              required
              fitContainer
              helpText={inputHelper['imovelEnxoval']}
              label="Imóvel possui enxoval?"
              control={control}
              errors={errors}
              type="radio"
              name="more_about.trousseau"
              options={yesOrNotOptions}
              rules={{validate: validateRequired}}
              extraStyles="row"
              style={radioStyles.style}
              radioLabelStyle={radioStyles.radioLabelStyle}
            />

            <RadioItem
              required
              fitContainer
              label="Animais são permitidos?"
              control={control}
              errors={errors}
              type="radio"
              name="more_about.pets"
              options={yesOrNotOptions}
              rules={{validate: validateRequired}}
              extraStyles="row"
              style={radioStyles.style}
              radioLabelStyle={radioStyles.radioLabelStyle}
            />

            <div className={classes.anexarArquivoWrapper}>
              <Typography variant="body2" align="center" style={{marginBottom: '5px'}}>
                Você pode organizar todos os documentos deste imóvel aqui conosco! Deseja adicionar a escritura ou
                documento de comprovação de posse?
              </Typography>
              <div>
                {isNewRoute ? (
                  <FilePickerMultipleNewProperty
                    label="Carregar Documentos"
                    filePrefix="deed"
                    displayLinks
                    handleDocs={setDocs}
                    docs={docs}
                  />
                ) : (
                  <FilePickerMultiple
                    label="Carregar Documentos"
                    uid={property?.uid}
                    filePrefix="deed_"
                    section={`property/${property?.uid}`}
                    list={property?.deed ?? []}
                    displayLinks
                    onChangeFileCallback={onChangeFileCallback}
                    onDeleteFileCallback={onDeleteFileCallback}
                  />
                )}
              </div>
            </div>

            <div className={classes.anexarArquivoWrapper}>
              <Typography variant="body2" align="center" style={{margin: '10px 0'}}>
                Caso não seja o proprietário anexar uma procuração para prosseguir
              </Typography>
              <div>
                {isNewRoute ? (
                  <FilePickerMultipleNewProperty
                    label="Carregar Documentos"
                    filePrefix="power_attorney"
                    displayLinks
                    handleDocs={setDocs}
                    docs={docs}
                  />
                ) : (
                  <FilePickerMultiple
                    label="Carregar Documentos"
                    uid={property?.uid}
                    filePrefix="power_attorney_"
                    section={`property/${property?.uid}`}
                    list={property?.power_attorney ?? []}
                    displayLinks
                    onChangeFileCallback={onChangeFileCallback}
                    onDeleteFileCallback={onDeleteFileCallback}
                  />
                )}
              </div>
            </div>
          </section>

          {!isContractRoute && (
            <>
              <Divider dashed />
              <section className={classes.section}>
                <header style={{margin: '15px 0 0'}}>
                  <Typography variant="h6" color="secondary" align="center">
                    Qual valor que pretende pedir de aluguel?
                  </Typography>
                </header>

                <Typography variant="body2" align="center" style={{margin: '16px 0 25px'}}>
                  Os valores abaixo servirão para calcular a análise de crédito e poderão ser alterados na confecção do
                  contrato
                </Typography>

                <Box bgType="gray" p="0 10px 4px" m="0 0 10px">
                  <div className={classes.amountsContainer}>
                    <Typography variant="body1">
                      Valor mensal do aluguel <RequiredMark required={true} />{' '}
                    </Typography>

                    <div>
                      <FormItem
                        control={control}
                        errors={errors}
                        type="mask"
                        name="rent_amount"
                        rules={{
                          validate: numberFormatValidateCurrency,
                        }}
                        maskInputProps={currencyFormatProps}
                        defaultValue="0"
                      />
                    </div>
                  </div>
                </Box>

                <Typography variant="body2" align="center" style={{marginBottom: '15px'}}>
                  Este é o valor que você quer cobrar de aluguel sem considerar os custos de Condomínio e IPTU
                </Typography>

                <Box bgType="gray" p="0 10px 4px" m="0 0 10px">
                  <div className={classes.amountsContainer}>
                    <Typography variant="body1">Valor mensal do condomínio</Typography>

                    <div>
                      <FormItem
                        control={control}
                        errors={errors}
                        required={false}
                        type="mask"
                        name="condominium_amount"
                        rules={{
                          validate: numberFormatValidateCurrencyOptional,
                        }}
                        maskInputProps={currencyFormatProps}
                        defaultValue="0"
                      />
                    </div>
                  </div>
                </Box>

                <Box bgType="gray" p="0 10px 4px" m="0 0 10px">
                  <div className={classes.amountsContainer}>
                    <Typography variant="body1">Valor anual do IPTU</Typography>

                    <div>
                      <FormItem
                        control={control}
                        errors={errors}
                        required={false}
                        type="mask"
                        name="iptu_amount"
                        rules={{
                          validate: numberFormatValidateCurrencyOptional,
                        }}
                        maskInputProps={currencyFormatProps}
                        defaultValue="0"
                      />
                    </div>
                  </div>
                </Box>

                <Typography variant="body2" align="center" style={{margin: '15px 0 10px'}}>
                  O valor do IPTU é dividido em 12 parcelas caso você escolha incluir no boleto
                </Typography>

                <div className={classes.valorTotalContainer}>
                  <Box bgType="gray" w="250px" h="106px" m="20px 0 0">
                    <div className="wrapper">
                      <Typography variant="h6" align="center">
                        Valor total
                      </Typography>
                      <Typography variant="h4" color="secondary">
                        <NumberFormatCustom value={totalFee} displayType="text" />
                      </Typography>
                    </div>
                  </Box>
                </div>
              </section>
            </>
          )}
        </form>
      </FormProvider>

      <div className={classes.salvarButtonWrapper}>
        <ContractContainerBottom showBackToIndex={isContractRoute}>
          <ButtonProximoItem
            id={createAlternativeEventName('continuar')}
            onClick={handleSubmit(handleClickContinuar)}
            label={currentOperationHandler.buttonTitle}
            buttonProps={{disabled: currentOperationHandler.disabled}}
          />
        </ContractContainerBottom>
      </div>
    </ContractContainer>
  )
}

export const RadioItem = ({label, helpText = undefined, ...rest}) => {
  return (
    <div>
      <header>
        <Typography
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.4rem'}}
          variant="h6"
          color="secondary"
          align="center">
          {label}*{helpText && <HelpTextComponent title={'O que é um imóvel mobiliado?'} helpText={helpText} />}
        </Typography>
      </header>

      <FormItem {...rest} />
    </div>
  )
}
