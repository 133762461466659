export const HeaderActionTypes = {
  SET_HEADER_MAIN_HEADER: 'SET_HEADER_MAIN_HEADER',
  SET_HEADER_CADASTRO_PROPRIETARIO_LOGIN_SIGNUP: 'SET_HEADER_CADASTRO_PROPRIETARIO_LOGIN_SIGNUP',
  SET_HEADER_CADASTRO_PROPRIETARIO_SMS_LOGIN: 'SET_HEADER_CADASTRO_PROPRIETARIO_SMS_LOGIN',
  SET_HEADER_CADASTRO_PROPRIETARIO_ESTADO_DO_IMOVEL: 'SET_HEADER_CADASTRO_PROPRIETARIO_ESTADO_DO_IMOVEL',
  SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL: 'SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL',
  SET_HEADER_CADASTRO_PROPRIETARIO_GARANTIAS: 'SET_HEADER_CADASTRO_PROPRIETARIO_GARANTIAS',
  SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL_CADASTRADO: 'SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL_CADASTRADO',
  SET_HEADER_CADASTRO_PROPRIETARIO_INQUILINOS: 'SET_HEADER_CADASTRO_PROPRIETARIO_INQUILINOS',
  SET_HEADER_CADASTRO_PROPRIETARIO_FORMA_PAGAMENTO: 'SET_HEADER_CADASTRO_PROPRIETARIO_FORMA_PAGAMENTO',
  SET_HEADER_CADASTRO_PROPRIETARIO_ANALISE_CONTRATO: 'SET_HEADER_CADASTRO_PROPRIETARIO_ANALISE_CONTRATO',
  SET_HEADER_CADASTRO_PROPRIETARIO_PARABENS: 'SET_HEADER_CADASTRO_PROPRIETARIO_PARABENS',
  SET_HEADER_PAINEL_DE_CONTROLE: 'SET_HEADER_PAINEL_DE_CONTROLE',
  SET_HEADER_CADASTRO_PROPRIETARIO_RESUMO: 'SET_HEADER_CADASTRO_PROPRIETARIO_RESUMO',
  SET_HEADER_TITLE: 'SET_HEADER_TITLE',
  SET_HEADER_TERMOS_E_CONDICOES: 'SET_HEADER_TERMOS_E_CONDICOES',
  SET_UNESCAPABLE: 'SET_UNESCAPABLE',
}
