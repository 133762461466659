import mobenLogo from '@root/assets/new/logo144.png'
import OLXLogo from '@root/assets/new/olx_logo.svg'
import vivaRealLogo from '@root/assets/new/viva_real@2x.png'
import ZapImoveisLogo from '@root/assets/new/zap_imoveis_logo.svg'
import React from 'react'

const AdButton = ({href, portal}) => {
  let logo
  let alt
  let portalHref
  let imageStyles

  switch (portal) {
    case 'olx':
      logo = OLXLogo
      alt = 'OLX Logo'
      break

    case 'zap_imoveis':
      logo = ZapImoveisLogo
      alt = 'Zap Imóveis Logo'
      break

    case 'viva_real':
      logo = vivaRealLogo
      alt = 'Viva Real Logo'
      break

    default:
      logo = mobenLogo
      alt = 'Moben Logo'
      break
  }

  if (href) {
    portalHref = href
    imageStyles = {
      width: '100%',
      transform: `${portal === 'olx' || portal === 'zap_imoveis' ? 'scale(1.3)' : null} ${
        portal === 'zap_imoveis' ? 'translateY(3px)' : null
      }`,
    }
  } else {
    href = '#'
    imageStyles = {
      width: '100%',
      filter: 'grayscale(1)',
      pointerEvents: 'none',
      transform: `${portal === 'olx' || portal === 'zap_imoveis' ? 'scale(1.3)' : null} ${
        portal === 'zap_imoveis' ? 'translateY(3px)' : null
      }`,
    }
  }

  return (
    <a
      style={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid lightgray',
        padding: 8,
        borderRadius: 10,
        width: 50,
        height: 50,
      }}
      href={portalHref}
      target="_blank"
      rel="noreferrer">
      <img src={logo} style={imageStyles} alt={alt} />
    </a>
  )
}

export default AdButton
