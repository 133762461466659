import DateFnsUtils from '@date-io/date-fns'
import Typography from '@material-ui/core/Typography'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import FormItem from '@root/components/FormHelper/FormItem'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import calcContractLengthMonths, {isDateInRange} from '@root/lib/calcContractLengthMonths'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {buildStepUrl, getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import dateParser, {dateFormatCustom} from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractPenaltiesAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import routes from '@root/utils/routes'

import {useStyles} from './styles'

const PENALTIES_OPTIONS = [
  {
    value: 'exempt_12',
    label: 'Isentar a partir do 12º mês',
  },
  {
    value: 'keep',
    label: 'Manter a multa durante todo o contrato',
  },
  {
    value: 'from',
    label: 'Isentar à partir de um mês específico',
  },
]

export function Penalties() {
  const classes = useStyles()
  const history = useHistory()
  const {handleSubmit, control, errors, watch, reset, setValue, getValues} = useForm()
  const [exemptFrom, setExemptFrom] = useState(0)
  const [exemptDate, setExemptDate] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  // console.log(exemptFrom, exemptDate)

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const validity = useSelector((s) => s.property.data?.contract?.validity)
  const penalties = useSelector((s) => s.property.data?.contract?.penalties)
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const exempt = watch('exempt')
  const isExemptFrom = exempt === 'from'
  const isContinueDisabled = !exempt || (isExemptFrom && !exemptDate)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractPenaltiesAction,
    setContractStepAction,
  })

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)

    try {
      const newPenalties = {exempt_from: exemptFrom, exempt_date: exemptDate ?? null, type: formData.exempt}
      await propertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.penalties': newPenalties,
        }),
      )
      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const handleExemptDate = (date) => {
    const startDate = dateParser(validity.start)
    const endDate = dateParser(validity.end)
    const isInRange = isDateInRange(startDate, endDate, date)
    if (!isInRange) {
      alert(
        `A data esta fora do período de vigência que é de ${dateFormatCustom(
          startDate,
          'MM/yyyy',
        )} até ${dateFormatCustom(endDate, 'MM/yyyy')}`,
      )
      return
    }

    const diff = calcContractLengthMonths(startDate, date)
    setExemptFrom(diff)
    setExemptDate(date)
    // setValue('exempt', 'from')
  }

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    let val = 0
    let date = null
    if (exempt === 'exempt_12') {
      date = new Date(dateParser(validity.start))
      date.setMonth(date.getMonth() + 12)
      val = 12
    } else if (exempt === 'keep') {
      val = 0
    }

    setExemptDate(date)
    setExemptFrom(val)
  }, [exempt])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    if (!validity?.start || !validity?.end) {
      alert('As multas dependem da vigência, por favor preencha esta etapa primeiro.')
      history.push(buildStepUrl(routes.CONTRACT_VALIDITY))
    }
  }, [validity])

  useEffect(() => {
    setExemptDate(dateParser(penalties?.exempt_date))
    setExemptFrom(penalties?.exempt_from)
    reset({exempt: penalties?.type})
  }, [penalties])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.penalties}`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography variant="body1" align="center" className="bold">
            No contrato de aluguel existem 3 tipos de multas:
          </Typography>

          <div>
            <Typography variant="body1" className="bold">
              1 - Descumprimento contratual
            </Typography>
            <Typography variant="body2">
              Aplicada em caso de descumprimento de qualquer cláusula do contrato. Valor fixo equivalente a 3 aluguéis.
            </Typography>
          </div>
          <div>
            <Typography variant="body1" className="bold">
              2 - Multa por inadimplência
            </Typography>
            <Typography variant="body2">
              Aplicada em caso de atrasos nos pagamentos. Valor fixo equivalente a 3 aluguéis.
            </Typography>
          </div>
          <div>
            <Typography variant="body1" className="bold">
              3 - Encerramento de contrato
            </Typography>
            <Typography variant="body2">
              Aplicada em caso de término antecipado do contrato. Valor de 3 aluguéis proporcionais ao tempo restante do
              contrato.
            </Typography>
          </div>

          <Typography variant="body1" align="center" className="bold">
            Como deseja proceder em relação à multa de encerramento de contrato?
          </Typography>

          <div className="multaEncerramentoItemsWrapper">
            <FormItem
              fitContainer
              control={control}
              errors={errors}
              type="radio"
              name="exempt"
              options={PENALTIES_OPTIONS}
              rules={{validate: validateRequired}}
            />

            {isExemptFrom && (
              <>
                <Typography>
                  Seu período de vigência é de {dateFormatCustom(validity.start, 'MM/yyyy')} até{' '}
                  {dateFormatCustom(validity.end, 'MM/yyyy')}
                </Typography>
                <br />
                <br />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/yyyy"
                  margin="normal"
                  views={['month', 'year']}
                  openTo="month"
                  id="exempt_from_date"
                  value={exemptDate}
                  onChange={handleExemptDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </>
            )}
          </div>
        </MuiPickersUtilsProvider>
      </form>
      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} disabled={isContinueDisabled} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
