import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {HomeIcon} from '@root/components/page/Home/HomeIcon'
import PropTypes from 'prop-types'
import React from 'react'
import {HashLink} from 'react-router-hash-link'

const heroSectionIconStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  margin: '0 auto',
  transform: 'translate(0, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#FFF',
  border: 'none',
  borderRadius: '50%',
  width: '135px',
  height: '135px',
  color: '#831CCD',
  zIndex: 1,
}

const HeaderBlock = ({title, text, linkTo, icon}) => {
  const logHashLink = () => {}
  return (
    <>
      <HomeIcon icon={icon} alt={title} imgWidth="109px" styles={heroSectionIconStyles} />
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
      <Typography variant="body1">{text}</Typography>
      <Button className="readmore" id={`readmore-${linkTo}`}>
        <HashLink to={`#${linkTo}`} onClick={logHashLink}>
          SAIBA MAIS
        </HashLink>
      </Button>
    </>
  )
}

HeaderBlock.defaultProps = {}
HeaderBlock.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
}

export default HeaderBlock
