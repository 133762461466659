/** @module ContractContainerBottom */
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import routes, {routeWithID} from '@root/utils/routes'

const ContractContainerBottom = ({children, showBackToIndex}) => {
  const history = useHistory()
  const property = useSelector((s) => s.property.data)

  const goToStepsIndex = () => history.push(routeWithID(routes.CONTRACT_STEPS_LIST, property.uid))

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 40, marginTop: 30}}>
      {children}

      {showBackToIndex && (
        <Typography
          variant="h6"
          color="secondary"
          align="center"
          className="clickable"
          onClick={goToStepsIndex}
          style={{marginTop: 24}}>
          Voltar ao indice
        </Typography>
      )}
    </div>
  )
}

ContractContainerBottom.defaultProps = {
  showBackToIndex: true,
}

export default ContractContainerBottom
