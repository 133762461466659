import useWhatappLinker from '@root/hooks/useWhatappLinker'
import React from 'react'
import {MOBEN_WHATSAPP} from '@root/utils/constants'

export const WhatsAppLinkWrapper = ({phone, message, children, ...rest} = {}) => {
  const waLinker = useWhatappLinker()
  const link = waLinker.create(phone, message)
  const linkTarget = waLinker.openTarget

  return (
    <a href={link} rel="noreferrer" target={linkTarget} {...rest}>
      {children}
    </a>
  )
}

WhatsAppLinkWrapper.defaultProps = {
  phone: MOBEN_WHATSAPP,
}
