/** @module userType */
export const USER_TYPE_TENANT = 'tenant'
export const USER_TYPE_OWNER = 'owner'
export const USER_TYPE_FIADOR = 'fiador'
export const USER_TYPE_BOTH = 'both'

/**
 * @param {Array} propertiesList
 * @return {string}
 */
export const guessUserType = (propertiesList) => (propertiesList.length === 0 ? USER_TYPE_TENANT : USER_TYPE_OWNER)
/**
 * @param {object} property
 * @return {string|undefined}
 */
export const guessUserTypeFromProperty = (property, user) => {
  const userId = user?.uid
  const ower_id = property?.ower_id
  const tenant = property?.tenant

  if (ower_id === userId) {
    return USER_TYPE_OWNER
  } else if (tenant === userId) {
    return USER_TYPE_TENANT
  }
}

/**
 * @param {string} type
 * @return {boolean}
 */
export const isUserTypeTenant = (type) => type === USER_TYPE_TENANT

/**
 * @param {string} type
 * @return {boolean}
 */
export const isUserTypeOwner = (type) => type === USER_TYPE_OWNER

/**
 * @param {string} type
 * @return {boolean}
 */
export const isUserTypeBoth = (type) => type === USER_TYPE_BOTH
