/** @module backToLink */
import storage from '@root/lib/storage'
import {STORAGE_BACKLINK} from '@root/utils/constants'

export const saveBackLink = (path) => {
  storage.save(STORAGE_BACKLINK, path)
}

export const retriveBackLink = () => {
  return storage.load(STORAGE_BACKLINK, null)
}

export const clearBackLink = () => {
  return storage.remove(STORAGE_BACKLINK)
}

export const getPropertyIdFromInviteLink = (link) => {
  const regxTenant = /inquilino\/(.*)\/analysis/g
  const regxFiador = /fiador\/(.*)\/analysis/g

  const linkHasTenantKeyword = link.indexOf('inquilino') !== -1
  const linkHasFiadorKeyword = link.indexOf('fiador') !== -1
  const linkHasInviteKeyword = link.indexOf('analysis') !== -1 && link.indexOf('convite') !== -1

  let type = null
  if (linkHasTenantKeyword && linkHasInviteKeyword) {
    type = 'inquilino'
  } else if (linkHasFiadorKeyword && linkHasInviteKeyword) {
    type = 'fiador'
  }

  if (!type) return type

  let strMatch
  switch (type) {
    case 'inquilino':
      strMatch = link.match(regxTenant)[0]
      strMatch = strMatch.replace('inquilino/', '')
      break
    case 'fiador':
      strMatch = link.match(regxFiador)[0]
      strMatch = strMatch.replace('fiador/', '')
      break
  }

  strMatch = strMatch.replace('/analysis', '')
  return strMatch
}
