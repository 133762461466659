import Backdrop from '@material-ui/core/Backdrop'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ButtonPrimary from '@root/components/ButtonPrimary'
import analyticsLogger from '@root/firebase/analytics.utils'
import actionDispatcher from '@root/lib/actionDispatcher'
import storage from '@root/lib/storage'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {acceptCookiesAction} from '@root/redux/application/application.actions'
import {BASE_URL, STORAGE_COOKIES} from '@root/utils/constants'

const CookiesAccept = ({location}) => {
  const accepted = useSelector((s) => s.application.cookies.accepted)
  const actions = actionDispatcher({
    acceptCookiesAction,
  })

  const handleAcceptCookie = () => {
    storage.save(STORAGE_COOKIES, 'yes')
    actions.acceptCookiesAction()
  }

  const handleTermsBtn = () => {
    analyticsLogger.logEvent('button_click', {
      alternative_event_name: 'abriu_termos_condicoes',
      button_name: 'Termos e Condições',
    })
    window.open(`${BASE_URL}/politicasdeprivacidade`)
  }

  useEffect(() => {
    // setTimeout(() => window.scroll(0, 10), 1300)
    // setTimeout(() => window.scroll(0, 0), 1500)
    // window.scroll(0, 0)
  }, [])

  if (location.pathname.match(/politicasdeprivacidade/)) {
    return null
  }

  return (
    <Backdrop style={{zIndex: 9999}} open={!accepted}>
      <Paper style={{position: 'absolute', bottom: 6, left: 6, right: 6, padding: 16}}>
        <Grid container direction="row" justify="space-between" alignContent="center">
          <Grid item xs={12} sm={8} lg={10}>
            Nós utilizamos cookies e outras tecnologias semelhantes para melhorar sua experiência em nossos serviços e
            recomendar conteúdos de seu interesse. Ao utilizar nossos serviços você concorda com tal monitoramento.
            Conheça melhor nossos{' '}
            <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleTermsBtn}>
              Termos e Condições
            </span>{' '}
            e{' '}
            <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleTermsBtn}>
              Políticas de Privacidade
            </span>
            .
          </Grid>

          {/* 
              TODO:
              "aligContent" e "alignItems" nãoo existem em um Grid item. Verificar depois se tem que mexer
              nos estilos... */}
          {/* <Grid item xs={12} sm={3} lg={2} alignContent="center" alignItems="center">
            <ButtonPrimary onClick={handleAcceptCookie} button={{size: 'small'}}>
              CONCORDO
            </ButtonPrimary>
          </Grid> */}

          <Grid item xs={12} sm={3} lg={2}>
            <ButtonPrimary onClick={handleAcceptCookie} button={{size: 'small'}}>
              CONCORDO
            </ButtonPrimary>
          </Grid>
        </Grid>
      </Paper>
    </Backdrop>
  )
}

export default withRouter(CookiesAccept)
