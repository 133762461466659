import {Button, Tab, Tabs} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Divider} from '@root/components/Divider/Divider'
import {profileTabsList} from '@root/components/profileTabsList'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

import useStyles from './styles'

const getIconStyle = (isCurrentTab) => {
  return {
    width: 30,
    height: 30,
    color: isCurrentTab ? '#FFF' : '#2D7FFC',
    fill: isCurrentTab ? '#FFF' : '#2D7FFC',
  }
}

const TabPanel = ({selected, index, tabItem, user, ...other}) => {
  const isSelected = selected === index

  return (
    <div
      role="tabpanel"
      hidden={!isSelected}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}>
      {tabItem.checkVisibility(user) && <tabItem.panel selected={isSelected} />}
    </div>
  )
}

// https://material-ui.com/components/tabs/#customized-tabs
const MainProfilePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [currentTab, setTab] = useState(0)
  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.down('xs'))
  const loggedUser = useSelector((s) => s.user.user)

  let {hash} = window.location
  const handleTabChange = (event, newValue) => {
    window.location.hash = profileTabsList[newValue].hash
    setTab(newValue)
  }

  useEffect(() => {
    dispatch(setNavbarTitleAction('Minha conta'))
    const urlHash = window.location.hash
    const tabIndex = profileTabsList.findIndex((tab) => `#${tab.hash}` === urlHash)
    if (tabIndex !== -1) {
      setTab(tabIndex)
    }
  }, [hash])

  const getButton = (props, index) => {
    const isCurrentTab = index === currentTab
    const getStyle = () =>
      isCurrentTab ? {color: '#FFF', background: '#831CCD'} : {color: '#000', background: 'transparent'}
    return (
      <Button
        style={{opacity: 1, textTransform: 'none', fontWeight: 600, ...getStyle()}}
        variant={isCurrentTab ? 'contained' : ''}
        {...props}
      />
    )
  }

  return (
    <div>
      <Tabs
        TabIndicatorProps={{style: {display: 'none'}}}
        value={currentTab}
        className={classes.tabsContainer}
        onChange={handleTabChange}>
        {profileTabsList.map(
          (tab, index) =>
            tab.checkVisibility(loggedUser) && (
              <Tab
                key={tab.hash}
                disableRipple
                label={isExtraSmallViewPort ? '' : tab.title}
                icon={isExtraSmallViewPort ? <tab.icon style={getIconStyle(currentTab === index)} /> : null}
                selected={currentTab}
                component={(props) => getButton(props, index)}
              />
            ),
        )}
      </Tabs>
      <Divider dashed m="20px 0" />
      {profileTabsList.map((tab, index) => (
        <TabPanel
          key={tab.hash}
          selected={currentTab}
          index={index}
          className={classes.tabPanel}
          user={loggedUser}
          tabItem={tab}
        />
      ))}
    </div>
  )
}

export default MainProfilePage
