import {makeStyles} from '@material-ui/core'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

const questions = [
  {
    question_title: 'O que é a Moben?',
    answer:
      'Somos uma plataforma focada no proprietário que aluga o seu próprio imóvel. Oferecemos análise de crédito dos interessados, criação do contrato de acordo com o que foi negociado e assinatura digital (sem cartórios). Controle de pagamentos, emissão de  boletos e alertas de datas importantes organizados em um único painel de controle acessível de qualquer lugar.',
  },
  {
    question_title: 'Como funciona?',
    answer:
      'Para utilizar os serviços da Moben é muito simples! Basta você cadastrar o seu imóvel e gerar o link do seu imóvel para enviar para os seus futuros inquilinos. Eles mesmos poderão cadastrar as suas informações diretamente no site e tudo ficará disponível para você prosseguir para análise de crédito, contrato de aluguel ou emissão de boletos.',
  },
  {
    question_title: 'Quanto custa?',
    answer: 'Aqui na Moben você paga R$ 49,9/ mês independente do valor do seu aluguel.',
  },
  {
    question_title: 'Meu imóvel está alugado. Posso utilizar a Moben mesmo assim?',
    answer: `Claro! Aqui o proprietário vai encontrar as ferramentas certas 
    para todas as etapas da locação! \n \n- Você poderá fazer um contrato, caso ainda não tenha um; \n - Você poderá fazer um aditivo ao contrato, caso precise formalizar alguma mudança no contrato; \n - Você pode só fazer o upload do seu contrato atual e passar a controlar os pagamentos por aqui!`,
  },
  {
    question_title: 'Posso fazer só a análise de crédito?',
    answer:
      'Sim! O processo de cadastro é o mesmo. Após a análise basta você cancelar a sua assinatura. Mas lembre-se, neste valor mensal, já estão inclusos as outras funcionalidades como contrato e assinatura digital! Então vale a pena experimentar!',
  },
  {
    question_title: 'Gostaria apenas de emitir os boletos, como faço?',
    answer:
      'Você se cadastra em nosso site, inclui todas as informações para emissão e pronto. É só relaxar e deixar a tecnologia trabalhar para você! Ficará tudo organizado para você olhar no seu painel de controle!',
  },
  {
    question_title: 'Tenho muitos imóveis, posso ter um valor diferenciado?',
    answer: 'Sim! Basta entrar em contato conosco que ficaremos felizes em chegar a um acordo!',
  },
  {
    question_title: 'Existe carência para a assinatura?',
    answer: 'De jeito nenhum! Aqui na Moben você só fica se estiver feliz!',
  },
  {
    question_title: 'Por que eu deveria usar a Moben?',
    answer:
      'Sabemos que a administração de um aluguel pode ser uma experiência cheia de "dor de cabeça". E é por isso que estamos aqui. Utilizamos a tecnologia para facilitar todas as suas atividades e tomadas de decisão ao longo do aluguel. Decida pelo inquilino mais adequado, faça um contrato sob medida, assine sem ter que ir ao catório e muito mais!',
  },
  {
    question_title: 'A Moben vai administrar o meu aluguel?',
    answer:
      'Não! A Moben é uma plataforma que disponibiliza ao proprietário ferramentas que vão facilitar o dia-a-dia do aluguel possibilitando que ele mesmo administre, sem dor de cabeça!',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
  },
}))

export function FrequentAskedQuestionsPage() {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarTitleAction('Perguntas Frequentes'))
  }, [])

  const escapedNewLineToLineBreakTag = (string) => {
    return string.split('\n').map((item, index) => {
      return index === 0 ? item : [<br key={index} />, item]
    })
  }

  return (
    <div className={classes.root}>
      {questions.map((question, index) => (
        <ExpansionPanel key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}>
            <Typography className={classes.heading}>
              {index + 1}. {question.question_title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>{escapedNewLineToLineBreakTag(question.answer)}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}
