import ModalShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ModalShareInvite'
import ShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ShareInvite'
import useShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/useShareInvite'
import {Button} from '@root/components/Button/Button'
import {HeaderButton} from '@root/components/HeaderButton/HeaderButton'
import IconPlus from '@root/components/icons/IconPlus'
import MailOpen from '@root/components/icons/MailOpen'
import BaseModal from '@root/components/Modal/BaseModal'
import {useQuery} from '@root/hooks/urlHook'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import routes from '@root/utils/routes'

/**
 * @component
 */
const AddProposalInterestedActions = ({proposalId, shareLink}) => {
  const history = useHistory()
  const {hasParam} = useQuery()
  const inviteShare = useShareInvite()

  const gotToAddNewInterested = () => {
    history.push(`${routes.DASHBOARD_PROPOSALS_REGISTER}?proposal=${proposalId}`)
  }

  useEffect(() => {
    if (hasParam('invite')) {
      inviteShare.open()
    }
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'strech',
        marginTop: 24,
        marginBottom: 24,
        gap: 15,
      }}>
      <HeaderButton
        onClick={gotToAddNewInterested}
        icon={IconPlus}
        size={6}
        label="Eu mesmo quero cadastrar o interessado"
        description="Utilize esta opção caso já tenha os dados do interessado em mãos."
      />

      <HeaderButton
        onClick={inviteShare.open}
        icon={MailOpen}
        size={6}
        label="Enviar link de convite para essa proposta"
        description="Envie um convite para que o interessado se cadastre."
      />

      <ModalShareInvite inviteShare={inviteShare} shareLink={shareLink} />
    </div>
  )
}

export default AddProposalInterestedActions
