import {HeaderActionTypes} from './header.types'

const initialState = {
  title: '',
  useMain: false,
  unescapable: false,
}
const headerReducer = (state = initialState, action) => {
  const newState = {
    ...state,
  }
  switch (action.type) {
    case HeaderActionTypes.SET_UNESCAPABLE:
      newState.unescapable = action.allowScape
      newState.title = ''
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_LOGIN_SIGNUP:
      newState.title = 'Login/Signup'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_ESTADO_DO_IMOVEL:
      newState.title = action.payload.user ? `Olá, ${action.payload.user.name.split(' ')[0]}` : ''
      newState.useMain = false
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_IMOVEL:
      newState.title = 'Cadastro do Imóvel'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_INQUILINOS:
      newState.title = 'Cadastro de Inquilinos'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_GARANTIAS:
      newState.title = 'Garantias'
      break

    case HeaderActionTypes.SET_HEADER_PAINEL_DE_CONTROLE:
      newState.title = 'Painel de ControlePage'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_ANALISE_CONTRATO:
      newState.title = 'Análise de Contrato'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_FORMA_PAGAMENTO:
      newState.title = 'Contratação da Ferramenta'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_SMS_LOGIN:
      newState.title = 'Cadastro'
      break

    case HeaderActionTypes.SET_HEADER_CADASTRO_PROPRIETARIO_RESUMO:
      newState.title = 'Resumo da Contratação'
      break

    case HeaderActionTypes.SET_HEADER_MAIN_HEADER:
      newState.title = 'main'
      newState.useMain = true
      break

    case HeaderActionTypes.SET_HEADER_TERMOS_E_CONDICOES:
      newState.title = 'Termos e Condições'
      newState.useMain = false
      break
  }

  return newState
}

export default headerReducer
