/** @module simpleValidators */
import {findBankByExactName} from '@root/lib/banksList'
import cpfValidator from '@root/lib/cpfValidator'
import dateParser from '@root/lib/dateParser'
import {onlyNumbers} from '@root/lib/intVal'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import phoneFormater from 'no-phone-formater'

const REQUIRED_FIELD_MESSAGE = 'campo obrigatório'
const textRegex = new RegExp('[a-zA-Z.,+ -]')
const floatCommaRegex = new RegExp('\\d+(([,.])\\d+)?', 'g')

const isEmpty = (str) => {
  if (str) {
    const val = String(str).trim()
    return val === ''
  }
  return true
}

/**
 * @param value
 * @param allowZero
 * @return {boolean|string}
 */
export const numberFormatValidateCurrency = (value, allowZero = false) => {
  const val = formatMoneyBrToFloat(value)

  return val > 0 || allowZero || REQUIRED_FIELD_MESSAGE
}

/**
 * @param value
 * @return {boolean|string}
 */
export const numberFormatValidateCurrencyOptional = (value) => numberFormatValidateCurrency(value, true)

/**
 * @param value
 * @param message
 * @return {boolean|string}
 */
export const validateRequired = (value, message = REQUIRED_FIELD_MESSAGE) => {
  return !isEmpty(value) || message
}

/**
 * @param value
 * @param allowEmpty
 * @return {string|boolean}
 */
export const validateNumber = (value, allowEmpty = false) => {
  const empty = isEmpty(value)
  if (allowEmpty && empty) {
    return true
  } else if (empty) {
    return REQUIRED_FIELD_MESSAGE
  }

  const hasText = textRegex.test(value)
  const num = parseInt(value)

  if (isNaN(num) || hasText) {
    return 'apenas números, sem virgulas ou pontos'
  }
  return true
}

export const validateFloat = (value) => {
  const empty = isEmpty(value)
  if (empty) {
    return REQUIRED_FIELD_MESSAGE
  }

  const matched = floatCommaRegex.test(value)

  if (!matched) {
    return 'formato inválido'
  }
  return true
}

/**
 * @param value
 * @param allowEmpty
 * @return {string|boolean}
 */
export const validateNumberOptional = (value, allowEmpty = false) => validateNumber(value, true)

/**
 * @param value
 * @return {string|boolean}
 */
export const validateCnpj = (value) => {
  if (isEmpty(value) || !value) {
    return REQUIRED_FIELD_MESSAGE
  }
  const nums = onlyNumbers(value)

  if (nums.length !== 14) {
    return 'CNPJ inválido'
  }

  return true
}

/**
 * @function
 * @param {string} value
 * @return {boolean|string}
 */
export const validateFullName = (value) => {
  const valueStr = String(value)
  const len = valueStr.length

  return (valueStr.split(' ').length >= 2 && len > 2) || 'nome completo'
}

/**
 * @function
 * @param {string|Date} dateValue Se string precisa estar no formato YYYY-MM-DD ou DD/MM/YYYY
 * @param {boolean} [allowAhead] Permite datas no futuro - Default false
 * @return {boolean|string}
 */
export const validateDate = (dateValue, allowAhead = false) => {
  let time
  const date = dateParser(dateValue)
  const dateMax = new Date()
  const dateMin = new Date()

  if (!dateValue) {
    return REQUIRED_FIELD_MESSAGE
  }

  if (!date) {
    return 'formato inválido'
  }

  time = date.getTime()
  dateMin.setFullYear(dateMin.getFullYear() - 100)

  if (!time) {
    return 'data inválida'
  }

  if (time < dateMin || (!allowAhead && time > dateMax.getTime())) {
    return 'data fora da faixa permitida'
  }

  return true
}

/**
 * @function
 * @param {string|Date} dateValue Se string precisa estar no formato YYYY-MM-DD ou DD/MM/YYYY
 * @return {boolean|string}
 */
export const validateDateAhead = (dateValue) => validateDate(dateValue, true)

/**
 * @function
 * @param {Date|string} date
 * @param {number} minAge
 * @param {number} maxAge
 * @return {string|boolean}
 */
export const validateAge = (date, minAge = 18, maxAge = 70) => {
  const oneDay = 24 * 60 * 60 * 1000
  const oneYear = 365
  const today = new Date()
  const birthDate = dateParser(date)

  if (!birthDate) {
    return REQUIRED_FIELD_MESSAGE
  }

  const yearsOfLife = Math.round(Math.abs((today - birthDate) / oneDay)) / oneYear

  if (yearsOfLife < minAge) {
    return `muito jovem, você precisa ter pelo menos ${minAge} anos`
  }

  if (yearsOfLife > maxAge) {
    return 'idade fora da faixa permitida'
  }

  return true
}

/**
 * @function
 * @param {string} cpfNumber
 * @return {boolean|string}
 */
export const validateCpf = (cpfNumber) => {
  const cpf = onlyNumbers(cpfNumber)
  const cpfLen = cpf.length

  if (!cpfLen) {
    return REQUIRED_FIELD_MESSAGE
  }

  if (cpfLen < 11) {
    return 'menor do que o esperado'
  }
  if (cpfLen > 11) {
    return 'maior do que o esperado'
  }

  return cpfValidator(cpf) || 'cpf inválido'
}

/**
 * @param email
 * @return {string|boolean|string}
 */
export const validateEmail = (email) => {
  if (isEmpty(email)) {
    return REQUIRED_FIELD_MESSAGE
  }
  return /^[a-z0-9+._-]+@[a-z0-9]+\.[a-z]+(.*)$/.test(email.toLowerCase()) || 'e-mail inválido'
}

/**
 * @param phone
 * @return {string|boolean}
 */
export const validatePhone = (phone) => {
  try {
    phoneFormater.normalize(phone, '', 11)
  } catch (e) {
    if (e.message === 'Empty value') {
      return REQUIRED_FIELD_MESSAGE
    } else if (e.message === 'Minimum length is 11') {
      return 'número muito curto'
    } else {
      return 'número inválido'
    }
  }

  return true
}

/**
 * @param itens
 * @param min
 * @param max
 * @return {string|boolean}
 */
export const validateCheckboxMultiple = (itens, min = 1, max) => {
  if (!itens) {
    return REQUIRED_FIELD_MESSAGE
  }
  const values = Object.values(itens)
  const selectedCount = values.filter((value) => value).length

  if (selectedCount < min) {
    return `Escolha pelo menos ${min} opções`
  }

  if (max && selectedCount > max) {
    return `Escolha no máximo ${max} opções`
  }

  return true
}
/**
 * @param itens
 * @return {string|boolean}
 */
export const validateCheckboxSingle = (itens) => validateCheckboxMultiple(itens, 1, 1)

/**
 * @function
 * @param {string} text
 * @param {number?} min
 * @param {number?} max
 */
export const validateTextLen = (text, min = 0, max) => {
  if (!text) {
    return REQUIRED_FIELD_MESSAGE
  }

  const textLen = text.length
  if (textLen < min) {
    return `texto muito curto, mínimo de ${min} caracteres`
  }

  if (max && textLen > max) {
    return `texto muito longo, máximo de ${max} caracteres`
  }

  return true
}

/**
 * @function
 * @param {string} label
 */
export const validateBankCodeLabel = (label) => {
  const bank = findBankByExactName(label)
  if (!bank) {
    return REQUIRED_FIELD_MESSAGE
  }

  return true
}

/**
 * @function
 * @param {string} label
 */
export const validateMatch = (currentValue, valueToMatch) => {
  if (currentValue != valueToMatch) {
    return 'Os campos não coincidem'
  }

  return true
}
