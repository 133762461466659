import Loader from '@root/components/Loader/Loader'
import React from 'react'

export const StartLoader = () => {
  return (
    <div id="loader-start">
      <Loader loading={true} absolute={true} />
    </div>
  )
}

export default StartLoader
