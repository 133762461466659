import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  adRoot: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > h6, > p, > div:not(:last-child)': {
      marginBottom: 15,
    },
    '& li': {
      marginLeft: 20,
    },
    '& > h6:not(:first-child)': {
      margin: '20px 0 20px',
    },
    '& > h6:last-of-type': {
      margin: '0 0 15px',
    },
  },
  apartmentType: {
    margin: '0 !important',
  },
  condominiumSpecs: {
    'margin': '20px 0',
    'display': 'flex',
    'flexWrap': 'wrap',
    'justifyContent': 'space-between',
    'gap': 15,
    '& > div': {
      width: '46%',
    },
  },
  floorSuiteWrapper: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'gap': 15,
    '& > div': {
      width: '46%',
    },
  },
  descricaoSection: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > h6': {
      marginBottom: 20,
    },
    '& > p': {
      marginBottom: 15,
    },
  },
  photosSection: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > h6': {
      marginBottom: 20,
    },
    '& > p': {
      marginBottom: 15,
    },
  },
  photosContainer: {
    'display': 'grid',
    'justifyContent': 'center',
    'gridTemplateColumns': 'repeat(auto-fit, 100px)',
    'margin': '20px 0 !important',
    'gap': 32,
    '& > div': {
      'position': 'relative',
      '& > button': {
        position: 'absolute',
        bottom: -30,
        right: '50%',
        transform: 'translateX(50%)',
      },
    },
    '& > div > div': {
      'width': 100,
      'height': 100,
      'backgroundSize': 'cover',
      'borderRadius': 5,
      'position': 'relative',
      'transition': 'transform 0.2s',
      'cursor': 'pointer',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  },
  photoPrimary: {
    'outline': `3px solid ${theme.palette.primary.main}`,
    '&::after': {
      content: '"DESTAQUE"',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      fontWeight: 700,
      textAlign: 'center',
      opacity: 0.7,
    },
  },
  photoDelete: {
    'position': 'relative',
    '&::before': {
      content: '"X"',
      position: 'absolute',
      inset: 0,
      color: 'red',
      textAlign: 'right',
      borderRadius: 5,
      transform: 'translateY(-6px)',
      fontSize: '1.5rem',
      fontWeight: 700,
      zIndex: 10,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundColor: 'lightgrey',
      opacity: 0.6,
      borderRadius: 5,
    },
  },
  btnWrapper: {
    margin: '0 auto',
  },
  dialogPaper: {
    'height': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& > .fotoActionButtonsWrapper': {
      position: 'absolute',
      bottom: 24,
      height: 40,
    },
  },
  mostrarTodas: {
    width: 100,
    height: 100,
    borderRadius: 5,
    position: 'relative',
    cursor: 'pointer',
    border: '1px solid lightgrey',
    color: theme.palette.primary.main,
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    fontSize: '14px',
  },
  excluirPhotosContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  excluirCancelarWrapper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  carregarSolicitarFotosContainer: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  solicitarFotografoContainer: {
    display: 'grid',
    placeItems: 'center',
    transform: 'translateY(-10px)',
  },
  specsContainer: {
    'display': 'flex',
    '& > div': {
      'width': '24%',
      '& + div': {
        marginLeft: theme.margin[4],
      },
      '&:first-child': {
        width: '35%',
      },
    },
  },
  amountsContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& > div': {
      // width: '116px',
      transform: 'translateY(12px)',
    },
  },
  amountsRentContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& > div': {
      transform: 'translateY(12px)',
    },
    '& > p': {
      transform: 'translateY(4px)',
    },
  },
  valorTotalContainer: {
    'width': '100%',
    'margin': '0 auto 30px',
    'display': 'flex',
    'justifyContent': 'center',
    '& .wrapper': {
      'width': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      '& h4, h6': {
        width: '100%',
        textAlign: 'center',
      },
      '& h6': {
        margin: '8px 0 18px',
      },
    },
  },
}))

export default useStyles
