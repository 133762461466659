/** @module errorHandler */
import * as Sentry from '@sentry/react'
import {isDevEnv, isDevTestEnv} from '@root/lib/envDetector'

const DEFAULT_ERROR_MESSAGE =
  'Ups! Erramos alguma vírgula.\nMas fique calmo os Devs já foram notificados e vão providenciar a correção o mais breve possível.'
/**
 * @param {Error|string|Array<string>} exception
 * @param {string} customMessage
 */
const errorHandler = (exception, customMessage) => {
  // const isError = exception instanceof Error
  const isArray = exception instanceof Array
  const isException = exception instanceof Error
  const isString = typeof exception === 'string'
  let ex = exception

  let finalMessage = customMessage ?? DEFAULT_ERROR_MESSAGE
  if (isString) {
    finalMessage = exception
    ex = new Error(finalMessage)
  } else if (isArray) {
    finalMessage = exception.join('\n')
    ex = new Error(finalMessage)
  } else if (!isException) {
    ex = new Error(JSON.stringify(exception))
  }

  addBreadCrumb(finalMessage)
  Sentry.captureException(ex)

  if (isDevTestEnv()) {
    console.log(exception)
  }

  return finalMessage
}

export const addBreadCrumb = (message, data, silent = false) => {
  if (isDevEnv()) {
    if (message && !silent) {
      console.log('[breadcrumb]', message, data)
    }
  } else {
    Sentry.addBreadcrumb({
      // type: string
      // level: Severity
      // event_id: string
      // category: string
      message,
      // timestamp: number
      data,
      // data: {
      //   [key: string]: any
      // }
    })
  }
}

export default errorHandler
