import 'firebase/firestore'

import {ANSWER_NO, ANSWER_YES} from '@root/components/App/Contract/DefaultRadioGroup'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import IconCheck from '@root/components/icons/IconCheck'
import {currencyFormatProps} from '@root/components/NumberMask'
import PageMini from '@root/components/PageMini'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {firebaseUploader} from '@root/lib/firebaseUploader'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {numberFormatValidateCurrency, validateRequired} from '@root/lib/simpleValidators'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {setCurrentUser} from '@root/redux/user/user.actions'
import routes from '@root/utils/routes'

const FINANCIAL_OBLIGATION_OPTIONS = [optionFactory('Sim', ANSWER_YES), optionFactory('Não', ANSWER_NO)]
const FinancialObligations = ({match}) => {
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, control, errors, reset, watch} = formHook

  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)

  const hasObligation = watch('obligation') === ANSWER_YES

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setCurrentUser,
    setNavbarTitleAction,
  })

  const handleContinue = async (form) => {
    actions.setGlobalLoaderAction(true)
    try {
      await userRef.update({
        recurring_debts: formatMoneyBrToFloat(form.recurring_debts),
      })
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
      return
    }

    try {
      if (form.docs.identity instanceof File) {
        const uploaded = await firebaseUploader(form.docs.identity, user.uid, `/user/documents/${user.uid}/`)
        await userRef.update('documents.identity', uploaded)
      }
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert('Erro ao enviar o arquivo')
    }

    actions.setGlobalLoaderAction(false)
    history.push(routes.REGISTER_MYDATA_EXTRA_DATA)
  }

  useEffect(() => {
    actions.setNavbarTitleAction('Compromissos Financeiros')
  }, [])

  useEffect(() => {
    reset({
      'obligation': user.recurring_debts > 0 ? ANSWER_YES : ANSWER_NO,
      'recurring_debts': user.recurring_debts,
      'docs.identity': user.documents.identity,
    })
  }, [user])

  return (
    <PageMini innerTitle="Consideramos compromissos recorrentes dividas como financiamentos, empréstimos, renegociações de cartão, cheque especial, entre outros. Estes valores poderão ser comprovados com seu extrato bancário dos últimos 3 meses.">
      <FormProvider {...formHook}>
        <FormItem
          control={control}
          errors={errors}
          fitContainer
          type="radio"
          extraStyles="row"
          label="Você possui compromissos financeiros recorrentes?"
          name="obligation"
          options={FINANCIAL_OBLIGATION_OPTIONS}
          rules={{
            validate: validateRequired,
          }}
        />

        {hasObligation && (
          <FormItem
            control={control}
            errors={errors}
            type="mask"
            label="Valor total mensal"
            name="recurring_debts"
            maskInputProps={currencyFormatProps}
            rules={{
              validate: numberFormatValidateCurrency,
            }}
          />
        )}

        <FormItem
          control={control}
          errors={errors}
          fitContainer
          type="file"
          label="Precisamos de um documento. CNH ou RG com CPF."
          name="docs.identity"
          rules={{
            validate: validateRequired,
          }}
        />

        <Button
          m="10px auto"
          w="168px"
          h="48px"
          fz="15pt"
          bgType="green"
          icon={IconCheck}
          onClick={handleSubmit(handleContinue)}>
          Continuar
        </Button>
      </FormProvider>
    </PageMini>
  )
}
FinancialObligations.propTypes = {
  match: PropTypes.any,
}
export default FinancialObligations
