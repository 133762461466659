/* eslint-disable no-empty */
import MaskRender from '@root/components/FormHelper/renderers/MaskRender'
import {currencyFormatProps} from '@root/components/NumberMask'
import React from 'react'

const CurrencyRender = (controllerProps, helpers, formItemProps) => {
  return MaskRender(controllerProps, helpers, {
    ...formItemProps,
    maskInputProps: currencyFormatProps,
  })
}

export default CurrencyRender
