import Guide1 from '@root/components/Guide1'
import React from 'react'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

const HowWorkPage = () => {
  const history = useHistory()
  const handleStart = () => history.replace(routes.DASHBOARD_PROPERTY_LIST)

  return <Guide1 onStart={handleStart} />
}

export default HowWorkPage
