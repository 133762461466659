import {makeStyles} from '@material-ui/core/styles'

const baseCardStyle = {
  'border': '1px solid lightgray',
  '& h3': {textAlign: 'center', marginBottom: 20},
  'padding': '10px',
  'height': '100%',
  '& button': {fontSize: '13px'},
}

const card2ColumnsBaseStyle = (theme) => ({
  ...baseCardStyle,
  'gridColumn': 'span 2',
  'overflowY': 'hidden',
  '& hr': {
    margin: '20px 0',
  },
  [theme.breakpoints.down('xs')]: {
    gridColumn: 'span 1',
  },
})

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginTop: '120px',
    marginBottom: '50px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
    },
  },
  mainContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '410px 410px 410px',
    gridTemplateRows: 'repeat(15, 70px)',
    gridGap: '25px',
    marginBottom: '30px',
  },
  listContainer: {
    listStyle: 'none',
  },
  resultsContainer: {
    padding: '2rem 1rem',
    backgroundColor: '#f1f1f1',
    border: '1px solid #999',
  },
  titles: {
    fontWeight: 700,
    fontSize: '2rem',
    textTransform: 'uppercase',
    marginBottom: '2rem',
  },
  uploadMessageSuccess: {
    color: 'green',
  },
  uploadMessageFail: {
    color: 'red',
  },
  appBar: {
    'height': 89,
    'justifyContent': 'center',
    '& h6': {marginLeft: 5, transform: 'translate(-55px,-19px)'},
    '& .MuiBadge-root': {
      'marginLeft': 'auto',
      'alignItems': 'center',
      '& > p': {
        marginRight: '5px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 68,
    },
  },
  headerMenuContainer: {
    'display': 'flex',
    'height': '100%',
    'marginLeft': -20,
    '& a': {
      'display': 'flex',
      'alignItems': 'center',
      'height': '100%',
      '& > h5': {fontSize: '15px'},
    },
    '& > a + a': {
      marginLeft: 15,
    },
  },
  userDetailRoot: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > div:last-child': {
      marginTop: '20px',
    },
  },
  userDetailTopContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridAutoRows: '550px',
    gap: '15px',
    justifyContent: 'center',
  },
  pagesContainer: {
    'padding': '10px !important',
    'width': '100% !important',
    'maxWidth': '100% !important',
    'minHeight': '80vh',
    'margin': '0 !important',
    '& a': {
      fontSize: '16px !important',
      color: theme.palette.secondary.main,
    },
    // '& hr': {
    //   'margin': '20px 0',
    //   '&:last-child': {display: 'none'},
    // },
  },
  logoElementImg: {
    width: '178px',

    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      width: '146px',
    },
  },
  navBar: {
    marginTop: '89px !important',
    width: '100% !important',
    padding: '0 !important',
    maxWidth: '100% !important',
  },
  mainHitsContainer: {
    'display': 'flex',
    'gap': 10,
    'height': '75vh',
    'alignItems': 'flex-start',
    'margin': '20px 0',
    '& > div': {flex: 1},
    '& h5': {marginBottom: 20},
  },
  userListRoot: {
    'display': 'flex',
    'flexDirection': 'column',
    '& .ais-SearchBox-form': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .ais-SearchBox-submit, .ais-SearchBox-reset': {
      display: 'none',
    },
    '& .ais-InfiniteHits-list': {
      flex: 1,
      // display: 'grid',
      // gridTemplateColumns: 'repeat(auto-fill, 318px)',
      // gridTemplateRows: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
      justifyContent: 'center',
    },
    '& .ais-InfiniteHits-list.property': {
      flex: 1,
      // display: 'grid',
      // gridTemplateColumns: 'repeat(auto-fill, 318px)',
      // gridRow: '100px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
      justifyContent: 'center',
    },
    '& .ais-InfiniteHits-item': {
      listStyle: 'none',
      border: '1px solid lightgray',
      borderRadius: 5,
      padding: 10,
      width: 330,
    },
    '& .ais-InfiniteHits-last-item': {
      width: '100%',
      listStyle: 'none',
    },
    '& .ais-SearchBox-input': {
      color: '#061BCD',
      width: '318px',
      height: '3rem',
      padding: '10px 14px',
      borderRadius: 8,
      border: '1px solid lightgray',
    },
  },
  fieldsContainer: {
    'display': 'flex',
    'marginBottom': 50,
    '& > div:first-child': {
      width: '30%',
      marginRight: 20,
    },
    '& > div:last-child': {
      'display': 'flex',
      'alignItems': 'center',
      '& > p': {
        marginRight: 15,
      },
    },
  },
  iconsContainer: {
    'display': 'flex',
    '& > a': {marginRight: 10, display: 'inline-block'},
    '& > button': {
      background: 'transparent',
      border: 'none',
      padding: 0,
    },
  },

  card: baseCardStyle,
  card2Columns: card2ColumnsBaseStyle(theme),
  inquilinoCard: card2ColumnsBaseStyle(theme),

  cardContent: {
    'height': '95%',
    'padding': '0 10px',
    '& > div': {
      'width': '100%',
      'height': '100%',
      'overflowY': 'scroll',
      'overflowX': 'hidden',
      '&::-webkit-scrollbar': {width: '4px'},
      '&::-webkit-scrollbar-thumb': {backgroundColor: theme.extraColors.GRAY[900]},
    },
    '& > div > div:last-child': {
      '& hr': {display: 'none'},
    },
  },
  propertiesList: {
    '& a': {
      fontSize: '16px !important',
      color: theme.palette.secondary.main,
    },
  },
  houseCountWrapper: {
    'position': 'relative',
    '& > p': {
      color: theme.palette.secondary.main,
      position: 'absolute',
      right: -7,
      top: -2,
      fontSize: '14px',
    },
  },
  btn16: {fontSize: '16px !important'},
  propertyDetailRoot: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridAutoRows: '550px',
    gap: '15px',
    justifyContent: 'center',
  },
  buttonsContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
  cardBtnWrapper: {
    'display': 'flex',
    'justifyContent': 'center',
    'width': '45%',
    'flexGrow': 1,
    '& button': {
      width: '100%',
      padding: '0.60rem',
      textTransform: 'lowercase',
    },
    '& a': {
      color: '#FFF',
    },
  },
  inquilinoCardH3: {
    'display': 'flex',
    'alignItems': 'center',
    '& > small': {
      marginLeft: 10,
    },
  },
  paymentsTable: {
    border: 'none',
  },
  boletosContainer: {
    'marginTop': 20,
    'width': '100%',
    'height': '70%',
    'display': 'flex',
    'justifyContent': 'space-between',
    '& > div': {
      'flex': 1,
      'maxWidth': '251px',
      'padding': 6,
      '& > h6': {marginBottom: 10, textAlign: 'center', fontWeight: 600},
      '& > div': {border: '1px solid gray', borderRadius: 6, padding: '6px 10px'},
      '& > div + div': {marginTop: 10},
    },
  },
  pEllipsis: {
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    '& a': {fontSize: '13px !important'},
  },
  linksDosAnuncios: {
    '& li': {
      'margin': '8px 0',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      '& > a': {
        display: 'inline-block',
        maxWidth: '25ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > button': {
        'width': 22,
        'height': 22,
        'marginRight': 10,
        '& > span': {width: '70%'},
      },
    },
  },
  adicionarAdLinkWrapper: {
    'margin': '5px 0',
    'display': 'flex',
    'alignItems': 'center',
    'gap': 10,
    '& > input': {
      flex: 3,
      padding: '5px 10px',
    },
    '& > button': {
      flex: 1,
    },
  },
  yearContainer: {
    'margin': `${theme.margin[2]} 0`,
    'display': 'flex',
    'justifyContent': 'flex-start',
    '& > button': {
      fontSize: 20,
      padding: 0,
    },
  },
  year: {
    color: theme.extraColors.GRAY[500],
  },
  isActive: {
    color: theme.palette.secondary.main,
  },
  recebimentos: {
    '& > div:not(:first-child)': {
      marginTop: theme.margin[2],
    },
  },
  recebimentoContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& p': {
      margin: '0 !important',
    },
    '& > div:first-child': {
      'display': 'flex',
      'alignItems': 'center',
      '& > h6': {
        marginRight: '12px',
        fontSize: 20,
        fontWeight: 600,
        width: '45px',
      },
    },
    '& > div:last-child': {
      'display': 'flex',
      '& > button + button': {
        marginLeft: '10px',
      },
    },
  },
  paymentDetail: {
    'marginTop': '-10px !important',
    'display': 'flex',
    'flexDirection': 'column',
    '& > p': {
      marginBottom: 10,
    },
    '& > div': {
      marginTop: 10,
    },
  },
  amountContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  amount: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  valorBoletoContainer: {
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    '& > h6': {
      fontSize: '15px',
      fontWeight: 600,
    },
    '& h5': {
      fontSize: '24px',
    },
  },
  motivoContainer: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'marginTop': '20px !important',
    '& > p:last-child': {
      textAlign: 'right',
      transform: 'translateY(-15px)',
    },
  },
  gerarBoletoBtnContainer: {
    margin: '15px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  fieldsContainer2: {
    'width': '100%',
    'display': 'flex',
    'flexWrap': 'wrap',
    'marginBottom': '20px',
    'justifyContent': 'space-between',
    '& > div': {
      width: '45%',
      minWidth: '220px',
      [theme.breakpoints.down(460)]: {
        width: '100%',
      },
    },
  },
  valorContainer: {
    marginTop: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tipoDeRecebimento: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
