/** @module actionDispatcher */
import store from '@root/redux/store'

const actionDispatcher = (funcs) => {
  const actionProps = {}
  Object.keys(funcs).forEach((fnname) => {
    actionProps[fnname] = function () {
      store.dispatch(funcs[fnname](...arguments))
    }
  })
  return actionProps
}

export default actionDispatcher
