import Typography from '@material-ui/core/Typography'
import FormItem from '@root/components/FormHelper/FormItem'
import {Box} from '@root/components/Layout/Container/Box/Box'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractForumAction, setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

import {useStyles} from './styles'

const FORO_OPTIONS = [
  {
    label: 'Justiça comum',
    value: 'justica_comum',
  },
  {
    label: 'Câmara arbitral',
    value: 'camara_arbitral',
  },
]

export function Forum() {
  const classes = useStyles()
  const history = useHistory()

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const forum = useSelector((s) => s.property.data?.contract?.forum)

  const {handleSubmit, control, errors, reset} = useForm()
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractForumAction,
    setContractStepAction,
  })

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)
    try {
      const forumType = formData.forum
      await propertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.forum.type': forumType,
        }),
      )

      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  useEffect(() => {
    reset({
      forum: forum?.type,
    })
  }, [forum])

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.guarantee}`}>
        <Typography variant="body2">
          A escolha do foro determina de que forma serão julgados os processos decorrentes de possíveis quebras de
          contrato durante a vigência.
        </Typography>
        <Typography variant="body2">
          A causa mais comum de quebra de contrato de locação é falta de pagamento. Nestes casos, é muito importante que
          se retome o imóvel o mais rápido possível para diminuir os prejuízos do proprietário.
        </Typography>

        <FormItem
          fitContainer
          control={control}
          errors={errors}
          type="radio"
          name="forum"
          options={FORO_OPTIONS}
          rules={{validate: validateRequired}}
          extraStyles="row"
          style={{marginBottom: -30}}
        />
      </form>

      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
