import React from 'react'

const IconBank = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57.566" height="56" viewBox="0 0 57.566 56" {...props}>
      <g id="Grupo_552" data-name="Grupo 552" transform="translate(-13.718 -401.088)">
        <g id="Grupo_538" data-name="Grupo 538" transform="translate(14941.674 12959.762)">
          <circle
            id="Elipse_126"
            data-name="Elipse 126"
            cx="28"
            cy="28"
            r="28"
            transform="translate(-14927.674 -12558.674)"
            fill="#f5f4f4"
          />
          <circle
            id="Elipse_128"
            data-name="Elipse 128"
            cx="28"
            cy="28"
            r="28"
            transform="translate(-14927.674 -12558.674)"
            fill="#f5f4f4"
          />
        </g>
        <g id="Grupo_547" data-name="Grupo 547" transform="translate(13.523 402.744)">
          <path
            id="Caminho_327"
            data-name="Caminho 327"
            d="M0-104.728l27.9-13.484,27.649,13.484v3.308H0Z"
            transform="translate(1.315 118.538)"
            fill="#8b8686"
          />
          <g id="Grupo_546" data-name="Grupo 546" transform="translate(6.448 18.164)">
            <path
              id="Caminho_328"
              data-name="Caminho 328"
              d="M0-161.975,2.1-179.5.485-180.941v-1.889H8.6v1.889L6.82-179.5,8.9-161.975v2.053H0Z"
              transform="translate(0 182.83)"
              fill="#8b8686"
            />
            <path
              id="Caminho_329"
              data-name="Caminho 329"
              d="M0-161.975,2.1-179.5.485-180.941v-1.889H8.6v1.889L6.82-179.5,8.9-161.975v2.053H0Z"
              transform="translate(12.091 182.83)"
              fill="#8b8686"
            />
            <path
              id="Caminho_330"
              data-name="Caminho 330"
              d="M0-161.975,2.1-179.5.485-180.941v-1.889H8.6v1.889L6.82-179.5,8.9-161.975v2.053H0Z"
              transform="translate(24.182 182.83)"
              fill="#8b8686"
            />
            <path
              id="Caminho_331"
              data-name="Caminho 331"
              d="M0-161.975,2.1-179.5.485-180.941v-1.889H8.6v1.889L6.82-179.5,8.9-161.975v2.053H0Z"
              transform="translate(36.274 182.83)"
              fill="#8b8686"
            />
          </g>
          <rect
            id="Retângulo_380"
            data-name="Retângulo 380"
            width="52.211"
            height="4.016"
            transform="translate(2.872 42.183)"
            fill="#c3c3c3"
          />
          <rect
            id="Retângulo_381"
            data-name="Retângulo 381"
            width="57.566"
            height="4.016"
            transform="translate(0.194 44.861)"
            fill="#8b8686"
          />
          <ellipse
            id="Elipse_127"
            data-name="Elipse 127"
            cx="6.024"
            cy="6.694"
            rx="6.024"
            ry="6.694"
            transform="translate(22.953 2.021)"
            fill="#831ccd"
          />
          <text
            id="_"
            data-name="$"
            transform="translate(25.63 12.344)"
            fill="#ffb100"
            fontSize="11"
            fontFamily="OpenSans-Semibold, Open Sans"
            fontWeight="600"
            letterSpacing="-0.005em">
            <tspan x="0" y="0">
              $
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}
IconBank.defaultProps = {
  fill: '#000',
}

export default IconBank
