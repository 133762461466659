import {makeStyles} from '@material-ui/core/styles'
import cancelMemberShipImg from '@root/assets/cancel-membership-img.png'

const useStyles = makeStyles((theme) => ({
  profile: {
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleContainer: {
    marginTop: '122px',
    marginBottom: '20px',
    textAlign: 'center',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
    },
  },
  paper: {
    margin: '30px 0',
    borderRadius: '12px',
    boxSizing: 'border-box',
    padding: '20px',
    width: 'min(100%, 800px)',
    display: 'flex',
    flexDirection: 'column',
  },
  profilePaper: {
    borderRadius: '12px',
    boxSizing: 'border-box',
    padding: '21px',
    width: 'min(100%, 800px)',
    display: 'flex',
    flexDirection: 'column',
  },
  paperLast: {
    margin: '30px 0 60px',
    // border: '1px solid #808080',
    borderRadius: '12px',
    boxSizing: 'border-box',
    padding: '20px',
    width: 'min(100%, 800px)',
    display: 'flex',
    flexDirection: 'column',
  },
  paperTitleEditarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.2rem',
  },
  profileTitle: {
    fontWeight: 700,
    fontSize: '1.5rem',
    marginBottom: '2rem',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    margin: 'auto',
    marginTop: '120px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  personalInfoPhotoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  personalInfoContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  profileButtons: {
    display: 'flex',
    marginTop: '50px',
    justifyContent: 'center',
  },
  personalInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  personalInfoItemLast: {
    display: 'flex',
    flexDirection: 'column',
  },
  photoContainer: {
    flex: 1,
  },
  photoDiv: {
    width: '120px',
    margin: '0 auto',
  },
  infoTexts: {
    color: '#707070',
    fontSize: '1.6rem',
    marginBottom: '-6px',
  },
  gridItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  cancelarTitleEditarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  cancelar: {
    fontSize: '.9rem',
  },
  cancelarBtnsContainer: {
    display: 'flex',
    marginTop: '20px',
  },
  cancelarBtns: {
    padding: '2px 5px',
    marginRight: '20px',
  },
  cancelMembership: {
    boxSizing: 'border-box',
    background: `#FFF url(${cancelMemberShipImg}) no-repeat right bottom`,
    backgroundSize: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  calcelarMembershipPaper: {
    margin: '30px 0',
    // border: '1px solid #808080',
    borderRadius: '12px',
    boxSizing: 'border-box',
    padding: '20px',
    marginLeft: '160px',
    width: 'min(100%, 700px)',
    display: 'flex',
    flexDirection: 'column',
  },
  calcelarMembershipBtnsContainer: {
    width: 'min(100%, 700px)',
    margin: '30px 0 60px',
    marginLeft: '160px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  cancelMembershipBtn: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  tabsContainer: {
    'margin': '0 0 20px !important',
    '& .MuiTabs-flexContainer': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      // overflowX: 'scroll',
    },
  },
  tabPanel: {
    width: '100%',
  },
  transfers: {
    'marginTop': '-10px !important',
    '& .bold': {fontWeight: 600},
    '& header': {
      padding: theme.margin[2],
      backgroundColor: theme.extraColors.GRAY[100],
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  yearContainer: {
    'margin': `${theme.margin[2]} 0`,
    'display': 'flex',
    'justifyContent': 'flex-start',
    '& > button': {
      fontSize: 20,
      padding: 0,
    },
  },
  year: {
    color: theme.extraColors.GRAY[500],
  },
  isActive: {
    color: theme.palette.secondary.main,
  },
  recebimentos: {
    '& > div:not(:first-child)': {
      marginTop: theme.margin[2],
    },
  },
  recebimentoContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& p': {
      margin: '0 !important',
    },
    '& > div:first-child': {
      'display': 'flex',
      'alignItems': 'center',
      'gap': 10,
      '& > h6': {
        // marginRight: '12px',
        fontSize: 20,
        fontWeight: 600,
        minWidth: 50,
      },
    },
  },
}))

export default useStyles
