import Page from '@root/components/Page'
import WhatsAppButton from '@root/components/WhatsAppButton'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

function SeguroFianca() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarTitleAction('Seguro Fiança'))
  }, [])
  return (
    <Page>
      <p>
        O seguro fiança é a opção mais segura para o proprietário. Além de garantir os valores referentes ao aluguel,
        ele também pode cobrir os valores de IPTU, condomínio e eventuais reparos que ficarem inadimplentes. Em muitos
        casos, a seguradora também oferece assistência jurídica para a realização de despejo.
      </p>
      <p style={{margin: '20px 0 0'}}>
        A contratação do seguro pode ser feita tanto pelo proprietário como pelo inquilino e dispensa a dificuldade de
        arrumar um fiador ou a necessidade de ter o dinheiro a vista de um depósito caução.
      </p>
      <p style={{margin: '20px 0'}}>
        Na Moben customizamos o contrato de acordo com as exigências da seguradora escolhida. Caso você ainda não tenha
        contratado um Seguro Fiança, podemos providenciar uma cotação. Entre em contato no WhatsApp da Moben.
      </p>

      <WhatsAppButton message="Olá, quero solicitar uma cotação de Seguro Fiança para o meu imóvel" />
    </Page>
  )
}

export default SeguroFianca
