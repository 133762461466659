import {Button, Typography} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

/** Botao de acao Primario */
const ButtonPrimary = (props) => {
  let buttonColor = '#0101CD'
  let textColor = '#FFF'
  let appendStyle = {}

  if (props.button) {
    if (props.button.style) {
      appendStyle = props.button.style
    }

    if (props.button.disabled) {
      buttonColor = '#D4D4D4'
      textColor = '#767676'
    }
  }

  return (
    <Button
      onClick={props.onClick}
      type={props.type}
      variant="outlined"
      size={props.size}
      {...props.button}
      style={{backgroundColor: buttonColor, ...appendStyle, ...props.style}}>
      <Typography variant="h5" style={{fontWeight: '600', color: props.textColor || textColor}} {...props.typography}>
        {props.children}
      </Typography>
    </Button>
  )
}

ButtonPrimary.defaultProps = {
  size: 'large',
}

ButtonPrimary.propTypes = {
  type: PropTypes.string,
  button: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

export default ButtonPrimary
