// import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'

import envConfig from '@root/env-config.js'
import {isCypressEnabled} from '@root/lib/envDetector'
import firebase from 'firebase/app'

const runInEmulator =
  envConfig.FIRESTORE_EMULATOR_HOST !== undefined && envConfig.FIREBASE_AUTH_EMULATOR_HOST !== undefined

const config = {
  apiKey: envConfig.API_KEY,
  authDomain: envConfig.AUTH_DOMAIN,
  //databaseURL: envConfig.DATABASE_URL,
  projectId: envConfig.PROJECT_ID,
  storageBucket: envConfig.STORAGE_BUCKET,
  messagingSenderId: envConfig.MESSAGING_SENDER_ID,
  appId: envConfig.APP_ID,
  // measurementId: envConfig.MEASUREMENT_ID,
}
if (runInEmulator) {
  config.authDomain = undefined
  config.databaseURL = undefined
}
const firebaseApp = firebase.initializeApp(config)

if (runInEmulator) {
  const [firestoreHost, firestorePort] = envConfig.FIRESTORE_EMULATOR_HOST.split(':')
  const [databaseHost, databasePort] = envConfig.FIREBASE_DATABASE_EMULATOR_HOST.split(':')
  const [storageHost, storagePort] = envConfig.FIREBASE_STORAGE_EMULATOR_HOST.split(':')
  const authUrl = envConfig.FIREBASE_AUTH_EMULATOR_HOST

  if (isCypressEnabled()) {
    // preciso disso para rodar no cypress
    // https://stackoverflow.com/a/61619639/6749912
    firebaseApp.firestore().settings({experimentalForceLongPolling: true})
    console.log('[cypress] experimentalForceLongPolling')
  }

  firebaseApp.firestore().useEmulator(firestoreHost, firestorePort)
  firebaseApp.database().useEmulator(databaseHost, databasePort)
  firebaseApp.storage().useEmulator(storageHost, storagePort)
  firebaseApp.auth().useEmulator('http://' + authUrl)

  // window.firestore = firebaseApp.firestore()
  console.log('running on emulator')
}

export const auth = firebaseApp.auth()
export const firestore = firebaseApp.firestore()
export const storage = firebaseApp.storage()
// export const analytics = firebaseApp.analytics()
export const database = firebaseApp.database()

export const forceArrayUnion = (entries) => firebase.firestore.FieldValue.arrayUnion.apply(this, entries)

export const forceArrayUnionSingle = (entry) => forceArrayUnion([entry])

export default firebase
