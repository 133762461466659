import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DatePickerRange from '@root/components/App/Admin/Dashboard/DatePickerRange'
import GrowthBlock from '@root/components/App/Admin/Dashboard/GrowthBlock'
import {Button} from '@root/components/Button/Button'
import {Divider} from '@root/components/Divider/Divider'
import FormItem from '@root/components/FormHelper/FormItem'
import {subDays} from 'date-fns'
import {convertDateToBr, convertDateToIso, dateParser} from 'date-parser'
import {useQuery} from '@root/hooks/urlHook'
import {dotParse} from '@root/lib/dotParse'
import {validateDate} from '@root/lib/simpleValidators'
import reportModel from '@root/model/reportModel'
import React, {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {FormProvider, useForm} from 'react-hook-form'
import {getGenericAdminLink} from '@root/utils/links/getAdminLink'

import useStyles from '../styles'

const Dashboard = () => {
  const classes = useStyles()
  const form = useForm()
  const {control, errors, setValue, getValues, handleSubmit} = form
  const query = useQuery()

  const [dateRange, setDateRange] = useState()
  const [userChart, setUserChart] = useState({series: [], options: {}})
  const [propertyChart, setPropertiesChart] = useState({series: [], options: {}})
  const [paymentsChart, setPaymentsChart] = useState({series: [], options: {}})

  const [reportResult, setReportResult] = useState()
  const [dailyCategories, setDailyCategories] = useState([])

  const buildChart = ({id, categories, series, title}) => {
    return {
      options: {
        curves: 'straight',
        lineCap: 'butt',
        title: {
          text: title,
        },
        legend: {
          show: true,
        },
        chart: {
          id,
          toolbar: {
            autoSelected: 'pan',
            tools: {
              download: false,
              selection: false,
            },
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ';',
              headerCategory: 'category',
              headerValue: 'value',
              /*dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },*/
            },
          },
        },
        xaxis: {
          categories,
        },
      },
      series,
    }
  }

  const getPeriodFromQuery = (paramName, fallback) => {
    if (query.hasParam(paramName)) {
      return dateParser(query.getParam(paramName) + 'T00:00:00')
    }

    if (fallback) {
      return fallback()
    }
  }

  const extractResult = (data, initialProp, path) => {
    const extracted = data[initialProp].map((data) => dotParse(data, path))
    return extracted
  }

  const fetchReport = async () => {
    const results = await reportModel.fetchDailyWithQueryParams(getReportQueryParams(dateRange))
    const categories = extractResult(results, 'current_period', 'created_at')
    setDailyCategories(categories)

    setUserChart(
      buildChart({
        id: 'usuario',
        title: 'Clientes',
        categories,
        series: [
          {
            name: 'qtd',
            data: extractResult(results, 'current_period', 'users.count'),
          },
        ],
      }),
    )

    setPropertiesChart(
      buildChart({
        id: 'property',
        title: 'Imoveis',
        categories,
        series: [
          {
            name: 'qtd',
            data: extractResult(results, 'current_period', 'properties.count'),
          },
        ],
      }),
    )

    /* setPaymentsChart(
      buildChart({
        id: 'payments',
        title: 'Pagamentos',
        categories,
        series: [
          {
            name: 'Aguardando pagamento',
            color: '#E6BA78',
            data: extractResult(results, 'current_period', 'payments.waiting_payment.count'),
          },
          {
            name: 'Pago',
            color: '#5a8c46',
            data: extractResult(results, 'current_period', 'payments.paid.count'),
          },
          {
            name: 'Atrasado',
            color: '#8c4646',
            data: extractResult(results, 'current_period', 'payments.arrear.count'),
          },
        ],
      }),
    ) */

    setReportResult({
      current: results.current_totals,
      prev: results.prev_totals,
      growth: results.growth,
    })
  }

  const getReportQueryParams = (data) => {
    const start = convertDateToIso(data.start)
    const end = convertDateToIso(data.end)
    const prevStart = convertDateToIso(data.prevStart)
    const prevEnd = convertDateToIso(data.prevEnd)

    return `start=${start}&end=${end}&prev-period-start=${prevStart}&prev-period-end=${prevEnd}`
  }

  const handleSearchDate = (data) => {
    window.history.pushState('', '', getGenericAdminLink(`dashboard?${getReportQueryParams(data)}`))

    setDateRange(data)
  }

  useEffect(() => {
    if (dateRange) {
      fetchReport()
    }
  }, [dateRange])

  useEffect(() => {
    const end = getPeriodFromQuery('end', () => subDays(new Date(), 1))
    const start = getPeriodFromQuery('start', () => subDays(end, 7))

    const prevStart = getPeriodFromQuery('prev-period-start', () => subDays(start, 8))
    const prevEnd = getPeriodFromQuery('prev-period-end', () => subDays(start, 1))

    setValue('start', start)
    setValue('end', end)

    setValue('prevStart', prevStart)
    setValue('prevEnd', prevEnd)

    setDateRange({start, end, prevStart, prevEnd})
  }, [])
  return (
    <Grid container direction="row">
      <Grid container item xs={12} direction="row" justify="center">
        <Grid container direction="row" alignItems="center" spacing={1} xs={12} md={5} lg={3}>
          <Grid item>Periodo</Grid>
          <DatePickerRange
            control={control}
            errors={errors}
            names={{start: 'start', end: 'end'}}
            xs={12}
            sm={4}
            md={4}
            lg={4}
          />
        </Grid>
        <Grid container direction="row" alignItems="center" spacing={1} xs={12} md={5} lg={3}>
          <Grid item>Comparar</Grid>
          <DatePickerRange
            control={control}
            errors={errors}
            names={{start: 'prevStart', end: 'prevEnd'}}
            xs={12}
            sm={4}
            md={4}
            lg={4}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={handleSubmit(handleSearchDate)}>Buscar</Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={4}>
        <Grid container direction="row" spacing={2}>
          <GrowthBlock label="Clientes" growth={reportResult} path="users.count" />
          <GrowthBlock label="Imoveis" growth={reportResult} path="properties.count" />
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h2">
              Contratos
            </Typography>
          </Grid>
          <GrowthBlock label="Total" growth={reportResult} path="contract.count" />
          <GrowthBlock label="Construção" growth={reportResult} path="contract.building" />
          <GrowthBlock label="Andamento" growth={reportResult} path="contract.in_progress" />
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h2">
              Pagamentos
            </Typography>
          </Grid>
          {/* <GrowthBlock label="Total" growth={reportResult} path="payments.total.count" /> */}
          <GrowthBlock label="Aguardando emissão" growth={reportResult} path="payments.waiting_schedule.count" />
          <GrowthBlock label="Aguardanto pagamento" growth={reportResult} path="payments.waiting_payment.count" />
          <GrowthBlock label="Pago" growth={reportResult} path="payments.paid.count" />
          <GrowthBlock
            label="Atrasado"
            growth={reportResult}
            path="payments.payment_arrear.count"
            successDirection="down"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            <Chart options={userChart.options} series={userChart.series} type="line" width="100%" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Chart options={propertyChart.options} series={propertyChart.series} type="line" width="100%" />
          </Grid>
          {/* <Grid item xs={12}>
            <Chart options={paymentsChart.options} series={paymentsChart.series} type="line" width="100%" />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard
