import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'

const rgRegex = new RegExp(/^(\d{1,2})?(\d{1,3})?(\d{1,3})?(\d{1,2}|x|X)?|\s+|.*$/)
const rgRegexClean = new RegExp('[^0-9xX]', 'g')
const RG_MAX_LEN = 10
const applyMask = (value) => {
  const raw = String(value).replace(rgRegexClean, '').substr(0, RG_MAX_LEN)

  // const masked = raw.replace(rgRegex, '$1.$2.$3-$4')
  const matches = raw.match(rgRegex).filter((match) => match)
  if (!matches) {
    return value
  }

  let masked = []
  if (matches[1]) {
    masked.push(matches[1])
  }
  if (matches[2]) {
    masked.push('.')
    masked.push(matches[2])
  }
  if (matches[3]) {
    masked.push('.')
    masked.push(matches[3])
  }
  if (matches[4]) {
    masked.push('-')
    masked.push(matches[4])
  }
  return {
    raw,
    masked: masked.join(''),
  }
}
const RGMask = (props) => {
  const {displayType, defaultValue, ...restProps} = props
  const [rgValue, setRgValue] = useState({raw: '', masked: ''})

  useEffect(() => {
    setRgValue(applyMask(defaultValue))
  }, [defaultValue])

  const maskValue = (ev) => {
    const value = ev.target.value
    const inputValues = applyMask(value)
    setRgValue(inputValues)

    if (typeof props.onChange === 'function') {
      ev.target.value = inputValues.masked
      restProps.onChange(ev)
    }
    return inputValues
  }

  if (displayType === 'text') {
    return rgValue.masked
  }
  return <input type="input" {...restProps} value={rgValue.masked} onChange={maskValue} />
}
RGMask.defaultProps = {
  displayType: 'input',
}

RGMask.propTypes = {
  displayType: PropTypes.oneOf(['input', 'text']),
}

export default RGMask
