import {useStyles} from '@root/components/Contract/styles'
import FormItem from '@root/components/FormHelper/FormItem'
import BankPickerDialog from '@root/components/Forms/Bank/BankPickerDialog'
import {banksMap, findBankByCode} from '@root/lib/banksList'
import {onlyNumbers} from '@root/lib/intVal'
import {
  validateBankCodeLabel,
  validateCnpj,
  validateCpf,
  validateNumber,
  validateNumberOptional,
  validateRequired,
} from '@root/lib/simpleValidators'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {CNPJ_FORMAT, CPF_FORMAT} from '@root/utils/constants'

const GAMBI_CPF_FORMAT = CPF_FORMAT + '#'
const BankInfoForm = ({bankdata, delayInit}) => {
  const classes = useStyles()
  const {control, errors, reset, setValue, clearErrors} = useFormContext()

  const [bankPickerVisibility, setBankPickerVisibility] = useState(false)
  const [selectedBank, setSelectedBank] = useState()
  const [documentNumberRules, setDocumentNumberRules] = useState({format: GAMBI_CPF_FORMAT, rule: validateCpf})

  const handleCpfOrCnpjInput = (event) => {
    const docnumber = onlyNumbers(event.target.value)
    const doclen = docnumber.length
    let newFormat, newRule

    if (doclen <= 11) {
      newFormat = GAMBI_CPF_FORMAT
      newRule = validateCpf
    } else if (doclen > 11) {
      newFormat = CNPJ_FORMAT
      newRule = validateCnpj
    }

    if (newFormat && newFormat !== documentNumberRules.format) {
      setDocumentNumberRules({
        format: newFormat,
        rule: newRule,
        lastlen: doclen,
      })
    }
  }

  const displayBankPicker = () => setBankPickerVisibility(true)
  const hideBankPicker = () => setBankPickerVisibility(false)

  /**
   * @param bank objeto que vem do {@link banksMap}
   */
  const onChangeBank = (bank) => {
    const bankLabel = bank?.label
    const bankCode = bank?.value
    setValue('bank.code', bankCode)
    setValue('bank.label', bankLabel)
    clearErrors('bank.label')
    // setSelectedBank(bank)
    hideBankPicker()
  }

  useEffect(() => {
    if (!bankdata) {
      return
    }

    const bank = {...bankdata}
    const code = bank.code ?? bank.bank_code

    const bankInfo = findBankByCode(code)

    if (bankInfo) {
      bank.label = bankInfo.label
      bank.code = bankInfo.value

      if (delayInit) {
        setTimeout(() => {
          // reset({bank})
          setValue('bank', bank)
        }, delayInit)
      }
    }
  }, [bankdata])

  return (
    <>
      <FormItem
        required
        fitContainer
        label="Nome do titular"
        control={control}
        errors={errors}
        type="textfield"
        name="bank.legal_name"
        rules={{validate: validateRequired}}
      />

      <div className={classes.twoFieldContainer}>
        <FormItem
          required
          fitContainer
          control={control}
          errors={errors}
          type="mask"
          name="bank.document_number"
          label="CPF ou CNPJ"
          onChange={handleCpfOrCnpjInput}
          rules={{validate: documentNumberRules.rule}}
          maskInputProps={{format: documentNumberRules.format}}
        />
      </div>

      <div className={classes.twoFieldContainer}>
        <FormItem
          required
          fitContainer
          control={control}
          errors={errors}
          type="select"
          name="bank.type"
          label="Tipo de conta"
          options={[
            {value: 'conta_corrente', label: 'Conta corrente'},
            {value: 'conta_poupanca', label: 'Conta poupança'},
            {value: 'conta_corrente_conjunta', label: 'Conta corrente conjunta'},
            {value: 'conta_poupanca_conjunta', label: 'Conta Poupança conjunta'},
          ]}
          rules={{validate: validateRequired}}
        />
        <FormItem
          control={control}
          errors={errors}
          required
          type="select"
          name="bank.code"
          label="Instituição"
          options={banksMap}
          rules={{validate: validateRequired}}
        />
      </div>

      <div className={classes.twoFieldContainer}>
        <div className={classes.twoFieldContainer}>
          <FormItem
            inputProps={{maxLength: 4}}
            control={control}
            errors={errors}
            required
            type="textfield"
            name="bank.agency"
            label="Agência"
            rules={{validate: validateNumber}}
          />

          <FormItem
            inputProps={{maxLength: 1}}
            control={control}
            errors={errors}
            required={false}
            type="textfield"
            name="bank.agency_digit"
            label="Digito"
            defaultValue=""
            rules={{validate: validateNumberOptional}}
          />
        </div>
      </div>
      <div className={classes.twoFieldContainer}>
        <div className={classes.twoFieldContainer}>
          <FormItem
            inputProps={{maxLength: 13}}
            control={control}
            errors={errors}
            required
            type="textfield"
            name="bank.account"
            label="Conta"
            rules={{validate: validateNumber}}
          />

          <FormItem
            inputProps={{maxLength: 2}}
            control={control}
            errors={errors}
            required={false}
            type="textfield"
            name="bank.account_digit"
            label="Digito"
            defaultValue=""
            rules={{validate: validateNumberOptional}}
          />
        </div>
      </div>

      <BankPickerDialog open={bankPickerVisibility} onChange={onChangeBank} onClose={hideBankPicker} />
    </>
  )
}

BankInfoForm.defaultProps = {
  delayInit: 100,
}
BankInfoForm.propTypes = {
  bankdata: PropTypes.object.isRequired,
  delayInit: PropTypes.number,
}

export default BankInfoForm
