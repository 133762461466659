import {Button, makeStyles} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined'
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined'
import analyticsLogger from '@root/firebase/analytics.utils'
import {isMatchPath} from '@root/lib/invitePath'
import storage from '@root/lib/storage'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setGuideVisibility, setNavbarTitleAction} from '@root/redux/application/application.actions'
import {STORAGE_GUIDE1} from '@root/utils/constants'
import routes from '@root/utils/routes'

const guideInstructions = [
  {
    title: 'Cadastro de imóvel',
    message:
      'A primeira coisa que você precisa fazer por aqui é cadastrar um imóvel. Você terá um painel de controle exclusivo para cada um dos seus imóveis. Nesse painel você poderá cadastrar o seu futuro inquilino e personalizar o seu contrato de aluguel.',
  },
  {
    title: 'Inquilinos',
    message:
      'Após cadastrar seu imóvel você poderá cadastrar seus inquilinos ou então enviar um convite para que eles mesmos se cadastrem. Essas informações são necessárias para você fazer a análise de crédito e o contrato de aluguel. Você será avisado sempre que um inquilino finalizar o cadastro.',
  },
  {
    title: 'Criação do contrato',
    message:
      'Você pode começar a criação do seu contrato logo após o cadastro do imóvel. Caso você ainda não tenha negociado todos os itens referentes ao aluguel, fique tranquilo pois você pode voltar para finalizar depois. O seu futuro inquilino só receberá uma cópia da minuta depois que você tiver lido e aprovado. Uma vez que todos aprovarem o contrato é enviado para assinatura digital.',
  },
]
const useStyles = makeStyles((theme) => ({
  guideRoot: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& h5': {
      marginBottom: 20,
    },
  },
  guideInstructionsContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    '& > div + div': {marginTop: 10},
    '& > div': {
      'display': 'flex',
      'alignItems': 'center',
      '& > svg': {
        fill: theme.palette.primary.main,
        marginRight: 8,
      },
    },
  },
  continueButton: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    textTransform: 'none',
    margin: '30px 0 20px',
    width: 150,
  },
}))

export function useGuide() {
  const isGuideVisible = useSelector((state) => state.application.isGuideVisible)
  const dispatch = useDispatch()

  useEffect(() => {
    //Evita do usuario ver novamente salvando no sessionStorage
    if (localStorage.getItem('guide1') === null) {
      const guideVisibility = localStorage.getItem('guide1') !== 'hide'
      dispatch(setGuideVisibility(guideVisibility))
    }
  }, [])

  return isGuideVisible
}

function Guide1({onStart}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const user = useSelector((s) => s.user.user)

  const [hideForever, setHideForever] = useState(false)

  const handleHideChange = (evt) => {
    analyticsLogger.logEvent('check_guia_inicial', {visibility: evt.target.checked})
    setHideForever(evt.target.checked)
  }

  const handleStart = () => {
    analyticsLogger.logEvent('guia_inicial_comecar')

    storage.save(STORAGE_GUIDE1, 'hide')
    dispatch(setGuideVisibility(false))
    if (onStart) {
      onStart(hideForever)
    }

    history.push(routes.DASHBOARD_PROPERTY_LIST)
  }

  useEffect(() => {
    analyticsLogger.logEvent('guia_inicial_exibido')

    // const pathname = history.location.pathname
    // const isInit = isMatchPath(pathname, routes.DASHBOARD_INIT)
    // const isTutorial = isMatchPath(pathname, routes.TUTORIAL)
    // const isHidden = storage.load(STORAGE_GUIDE1, false)
    // const needToHide = !isInit && (isHidden || isTutorial)

    // setStartButtonVisibility(needToHide)
  }, [])

  useEffect(() => {
    const currentName = user?.name?.split(' ')
    const firstName = currentName[0] ?? ''
    dispatch(setNavbarTitleAction(`Olá ${firstName}. Entenda o que acontece a partir de agora`))
  }, [user])

  return (
    <div
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginBottom: 85}}>
      <Typography style={{textAlign: 'center', margin: '0.8rem 0'}} variant={'body2'}>
        São basicamente 3 etapas para você conseguir administrar o seu próprio aluguel. <br />
        Nosso sistema salva todas as informações em cada passo, assim você pode preencher com calma e voltar sempre que
        precisar.
      </Typography>

      {guideInstructions.map(({title, message}, index) => {
        return (
          <Grid
            key={index}
            container
            wrap="nowrap"
            spacing={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0.8rem 0',
              padding: '0.8rem',
              border: '1px solid #ccc',
              borderRadius: '0.8rem',
            }}>
            <Grid item>
              <Avatar style={{backgroundColor: '#831CCD', color: '#FED667', fontWeight: 'bold'}}>{index + 1}</Avatar>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography style={{color: '#4454D9', marginBottom: '0.4rem'}} variant={'h6'}>
                {title}
              </Typography>
              <Typography variant={'subtitle1'} style={{lineHeight: '1.4rem'}}>
                {message}
              </Typography>
            </Grid>
          </Grid>
        )
      })}

      <div style={{position: 'fixed', bottom: 10}}>
        <Button variant="contained" onClick={handleStart} color="primary" className={classes.continueButton}>
          Começar
        </Button>
      </div>
    </div>
  )
}

export default Guide1
