import addressDisplay, {ADDRESS_VERBOSITY_MINIMAL} from '@root/lib/addressDisplay'
import {adminPathBuilder} from '@root/lib/admin/adminPathHelper'
import React, {useEffect, useRef} from 'react'
import {connectInfiniteHits} from 'react-instantsearch-dom'
import {Link} from 'react-router-dom'

const PropertiesHits = ({hits, hasMore, refineNext}) => {
  const lastItemRef = useRef(null)

  useEffect(() => {
    if (!hasMore) return

    let observer = new IntersectionObserver(onIntersection)
    observer.observe(lastItemRef.current)

    return () => observer.disconnect()
  }, [hits])

  const onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  return (
    <ul className="ais-InfiniteHits-list property">
      {hits.map((hit) => (
        <li key={hit.objectID} className="ais-InfiniteHits-item">
          <PropertyHit hit={hit} />
        </li>
      ))}
      <li className="ais-InfiniteHits-last-item" ref={lastItemRef} />
    </ul>
  )
}

const PropertyHit = ({hit}) => {
  const propertyDetailPath = adminPathBuilder(`/imoveis/${hit.uid}`)
  return (
    <div>
      <Link to={propertyDetailPath}>
        <strong>{hit.alias}</strong>
      </Link>
      <p>{addressDisplay(hit.address, ADDRESS_VERBOSITY_MINIMAL)}</p>
      <p>
        <span style={{marginRight: 5, userSelect: 'none'}}>PP:</span>
        <span>{hit.ower_id}</span>
      </p>
    </div>
  )
}

export const PropertiesScrollableHits = connectInfiniteHits(PropertiesHits)
