import {Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ParabensImg from '@root/assets/convite_parabens_img.png'
import ButtonTransparentBg from '@root/components/ButtonTransparentBg'
import WhatsAppButton from '@root/components/WhatsAppButton'
import React from 'react'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

const registerFinishedStyle = makeStyles(() => ({
  leftSection: {
    marginTop: '122px',
    marginRight: '40px',
    paddingTop: '40px',
    width: '55vw',
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
  },
  rightSection: {
    width: '40vw',
    height: '90vh',
    marginTop: '40px',
    backgroundImage: `url(${ParabensImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
  },
}))

const RegisterFinishedScreen = () => {
  const classes = registerFinishedStyle()
  const history = useHistory()

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className={classes.leftSection}>
        <Grid container justify="center">
          <Grid
            item
            style={{
              boxSizing: 'border-box',
              padding: '20px 40px',
              textAlign: 'left',
              marginBottom: '40px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}>
            <Typography variant="h4" style={{width: '100%', marginBottom: '40px', fontWeight: '600'}}>
              Parabéns! <br />
              Cadastro concluído com sucesso.
            </Typography>
            <Typography variant="body1" style={{marginTop: '20px'}}>
              Já temos os dados necessários para dar continuidade ao processo.
            </Typography>
            <Typography variant="body1" style={{marginTop: '20px'}}>
              Caso o sistema encontre alguma divergência nossa equipe de Experiência de Clientes entrará em contato.
            </Typography>
            <Typography variant="body1" style={{marginTop: '20px'}}>
              Se tiver alguma dúvida entre em contato com o proprietário ou então conosco.
            </Typography>
            <Typography variant="body1" style={{marginTop: '20px'}}>
              Não se preocupe, avisaremos no seu email quando estiver pronto!
            </Typography>
            <Typography variant="body1" style={{marginTop: '20px'}}>
              Qualquer dúvida pode nós chamar no WhatsApp
            </Typography>
          </Grid>

          <WhatsAppButton />
        </Grid>
      </div>
      <div className={classes.rightSection}></div>
    </div>
  )
}
export default RegisterFinishedScreen
