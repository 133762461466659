import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import RadioItem from '@root/components/App/Cadastro/cadastro-components/TenantMoreAbout/RadioItem'
import PropTypes from 'prop-types'
import React from 'react'

export const ANSWER_YES = 'yes'
export const ANSWER_NO = 'no'
export const ANSWER_NONE = 'no_response'
export const ANSWER_OCCUPIED = 'occupied'
export const ANSWER_EMPTY = 'empty'
export const ANSWER_COMMERCIAL = 'commercial'
export const ANSWER_RESIDENTIAL = 'residential'

const DefaultRadioGroup = (props) => {
  const radioValue = props.form[props.name]
  // console.log(`${props.name} = ${radioValue}`)

  return (
    <div style={{marginTop: '30px'}}>
      <Typography variant="h6" style={{fontWeight: '600'}}>
        {props.title}
      </Typography>
      <RadioGroup
        onChange={props.onChange}
        name={props.name}
        value={radioValue}
        style={{display: 'flex', flexDirection: 'row'}}>
        <RadioItem title="Sim" value={ANSWER_YES} checked={radioValue === ANSWER_YES} hide={props.hideYes} />
        {props.afterYes}

        <RadioItem title="Não" value={ANSWER_NO} checked={radioValue === ANSWER_NO} hide={props.hideNo} />
        {props.afterNo}

        <RadioItem
          title="Prefiro não responder"
          value={ANSWER_NONE}
          checked={radioValue === ANSWER_NONE}
          hide={props.hideNone}
        />
        {props.afterNone}
      </RadioGroup>
    </div>
  )
}

DefaultRadioGroup.defaultProps = {
  hideYes: false,
  hideNo: false,
  hideNone: false,
}

DefaultRadioGroup.propTypes = {
  hideYes: PropTypes.bool,
  hideNo: PropTypes.bool,
  hideNone: PropTypes.bool,
  afterYes: PropTypes.node,
  afterNo: PropTypes.node,
  afterNone: PropTypes.node,

  title: PropTypes.string.isRequired,
  name: PropTypes.any.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DefaultRadioGroup
