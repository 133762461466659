import {isDevEnv} from '@root/lib/envDetector'

let branchName = ''
const protectedBranchs = ['master', 'main']

const isBranchProtected = () => protectedBranchs.indexOf(branchName) !== -1

export const setBranchName = (name) => {
  branchName = name ?? ''
}
export const branchToGroup = () => branchName.replace(/\s|\/|-|_/gi, '')
export const branchToEndpoint = (endpoint) => {
  const isProtected = isBranchProtected()
  let ep = endpoint
  if (branchName && !isProtected && !isDevEnv()) {
    ep = branchToGroup() + '-' + endpoint
  }
  return ep
}
