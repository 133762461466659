import {getProgressAsList} from '@root/lib/contract/progressChecker'
import {dateSortDesc} from '@root/lib/dateSort'

export const isContractNeedToBeGenerated = (property) => {
  const progressEntries = getProgressAsList(property)
  progressEntries.sort((entryA, entryB) => dateSortDesc(entryA[1].updated_at, entryB[1].updated_at))

  //esse [1] e por que o progressEntries é um array feito com Object.entries, ou seja 0=chave 1=valor
  const firstProgress = progressEntries.shift()
  const mostRecentProgressEntry = firstProgress ? firstProgress[1].updated_at : undefined
  const generatedContractDate = property?.contract?.contract_file?.created_at

  // se a data da ultima atualizacao(em qualquer etapa) for mais recente do que a data do contrato gerado (PDF) ai sim sera permitido gerar um novo
  const recentUpdateGreaterThanContract = dateSortDesc(generatedContractDate, mostRecentProgressEntry) === 1
  const canBeGenerated = recentUpdateGreaterThanContract || !generatedContractDate
  return canBeGenerated
}
