import Typography from '@material-ui/core/Typography'
import {Button} from '@root/components/Button/Button'
import Title from '@root/components/Layout/Typography/Title'
import {useStyles} from '@root/components/YourProperty/styles'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

import {firestore} from '../../../../firebase/firebase.utils'

const SectionDocuments = () => {
  const classes = useStyles()
  const history = useHistory()

  const isOwner = !history.location.pathname.includes('inquilino')

  const property = useSelector((s) => s.property?.data)
  const tenantProperty = useSelector((s) => s.tenantProperty?.data)

  const [hasDocuments, setHasDocuments] = useState(0)

  async function loadUploadedDocs(property_uid) {
    const uploadedDocuments = await firestore
      .collection('property_docs')
      .where('property_uid', '==', property_uid)
      .orderBy('created_at', 'desc')
      .get()
    setHasDocuments(uploadedDocuments.docs.length)
  }

  const handleOnClick = () => {
    if (!history.location.pathname.includes('inquilino')) {
      history.push(routes.DASHBOARD_DOCUMENT_AREA)
    } else {
      history.push(routes.DASHBOARD_TENANT_DOCUMENT_AREA)
    }
  }

  useEffect(() => {
    const existUid = isOwner ? property.uid : tenantProperty.uid
    if (existUid) loadUploadedDocs(existUid)
  }, [])

  return (
    <div className={classes.sectionItem}>
      <Title bold>Documentos e comprovantes</Title>

      <Typography variant="subtitle1">Envie documentos referentes as negociações desta propriedade.</Typography>

      <div className={classes.buttonsContainer}>
        <Button onClick={handleOnClick}>{hasDocuments > 0 ? 'Ver documentos' : 'Enviar documentos'}</Button>
      </div>
    </div>
  )
}

export default SectionDocuments
