import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import {useTheme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

export const AvatarBadge = () => {
  const user = useSelector((s) => s.user.user)
  const theme = useTheme()
  const isExtraSmallViewPort = useMediaQuery(theme.breakpoints.up('xs'))
  const history = useHistory()

  const getAvatarUserName = () => {
    if (!user) return ''

    const userNameArr = user.name?.split(' ') ?? []

    if (userNameArr.length > 1) {
      const sliceEnd = isExtraSmallViewPort ? 1 : 2
      return userNameArr.slice(0, sliceEnd).join(' ')
    }

    return userNameArr[0] ?? ''
  }

  return (
    <Badge
      onClick={() => history.push(routes.DASHBOARD_ROOT)}
      className="clickable"
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      <Typography variant="body1">{getAvatarUserName()}</Typography>
      <Avatar src={user?.photoURL} alt={user?.name} />
    </Badge>
  )
}
