/** @module ContractContainer */
import PropTypes from 'prop-types'
import React from 'react'

const ContractContainer = ({children, style}) => {
  return <div style={style}>{children}</div>
}
ContractContainer.defaultProps = {
  sideText: [],
}
ContractContainer.propTypes = {
  title: PropTypes.string.isRequired,
  sideText: PropTypes.arrayOf(PropTypes.node),
}

export default ContractContainer
