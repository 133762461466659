export const isStatusPending = (status) => status === 'pending'
export const isStatusAccepted = (status) => status === 'accepted'
export const isStatusRefused = (status) => status === 'refused'

/**
 *
 * @param {string} section
 * @param {object[]} observationsList
 */
export const approveObservations = (section, observationsList) => {
  if (!Array.isArray(observationsList)) {
    return []
  }

  const observationsUpdated = observationsList.map((obs) => {
    if (obs.section === section && isStatusPending(obs.status)) {
      return {
        ...obs,
        status: 'accepted',
      }
    }

    return obs
  })

  return observationsUpdated
}
