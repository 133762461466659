import {Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {getURL} from '@root/hooks/urlHook'
import {getStepsIndexRoute} from '@root/lib/contract/stepWalker'
import {setUseMainPadding} from '@root/redux/application/application.actions'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {Button} from '../Button/Button'

const useStyles = makeStyles((theme) => ({
  mainSection: {
    'overflowX': 'hidden',
    '& h5': {
      margin: '10px 0 20px',
      fontWeight: 600,
    },
    '& p': {textAlign: 'center'},
    '& p + p': {
      marginTop: theme.margin[3],
    },
    '& img': {
      width: '100%',
    },
    '& button + button': {
      marginTop: theme.margin[3],
    },
    '& > :last-child': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '244px',
      margin: '30px auto 0',
    },
  },
  textContainer: {
    padding: '0 8px',
  },
  imageContainer: {
    'transform': 'scaleX(1.1)',
    'position': 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: -2,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(to top, transparent 0%, transparent 70%, #fff 98%)',
    },
  },
}))

export function ContractLandingPage() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const url = getURL()

  if (!url.searchParams.has('id')) {
    history.goBack()
  }

  const propertyId = url.searchParams.get('id')

  const handleCriarContratoAgoraOnClick = () => history.push(getStepsIndexRoute(propertyId))

  useEffect(() => {
    dispatch(setUseMainPadding(false))

    return () => dispatch(setUseMainPadding(true))
  }, [])

  return (
    <>
      <Grid container align="center" className={classes.mainSection}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography variant="h5" color="secondary">
            Agora vamos guiá-lo pelo contrato!
          </Typography>

          <Typography variant="body2" align="left">
            A seguir você poderá inserir todas as informações negociadas deixando seu contrato exatamente como foi
            combinado com o seu futuro inquilino!
          </Typography>
          <Typography variant="body2" align="left">
            Para ter certeza de que seu contrato estará completo, basta seguir nosso índice com todos os pontos a serem
            preenchidos! É bem simples!
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} className={classes.imageContainer}>
          <img src={'/assets/new/contract_landpage_img.jpg'} alt="Contrato Início" />
        </Grid>

        <Grid item xs={12}>
          <Button size="large" w="244px" fz="15px" p="18px 0" fw={600} onClick={handleCriarContratoAgoraOnClick}>
            Criar um contrato agora
          </Button>

          <Button noBg size="large" onClick={() => history.goBack()}>
            Quero fazer isso depois
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
