import Grid from '@material-ui/core/Grid'
import React from 'react'

const GuaranteeTypeCaucaoCapitalizacao = () => {
  // const {control, errors} = useFormContext()
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}></Grid>
      </Grid>
    </>
  )
}

export default GuaranteeTypeCaucaoCapitalizacao
