import {LoaderTypes} from '@root/redux/loader/loader.types'

export const toogleGlobalLoaderAction = () => {
  return {
    type: LoaderTypes.TOGGLE_GLOBAL_LOADING,
  }
}

export const setGlobalLoaderAction = (loading) => {
  return {
    type: LoaderTypes.SET_GLOBAL_LOADING,
    payload: {
      loading,
    },
  }
}

/**
 * @param {boolean} loading
 * @param {string} mode One of constants LOADER_MODE_TEXT | LOADER_MODE_SPINNER
 * @param {object} data
 */
export const setGlobalLoaderWithModeAction = (loading, mode, data = {}) => {
  return {
    type: LoaderTypes.SET_GLOBAL_LOADING_WITH_MODE,
    payload: {
      loading,
      mode,
      data,
    },
  }
}

export const setGlobalLoaderDataAction = (data = {}) => {
  return {
    type: LoaderTypes.SET_GLOBAL_LOADING_DATA,
    payload: {
      data,
    },
  }
}
