import {CartTypes} from '@root/redux/cart/cart.types'

export const setCartListAction = (list) => {
  return {
    type: CartTypes.SET_CART_LIST,
    payload: {list},
  }
}

export const setCartListAllAction = (list) => {
  return {
    type: CartTypes.SET_CART_LIST_ALL,
    payload: {list},
  }
}

export const setCartItemById = (uid, item) => {
  return {
    type: CartTypes.SET_CART_ITEM_BY_ID,
    payload: {uid, item},
  }
}

export const setCartCurrentIndexAction = (index) => {
  return {
    type: CartTypes.SET_CART_CURRENT_INDEX,
    payload: {index},
  }
}

export const addToCartAction = (item) => {
  return {
    type: CartTypes.ADD_TO_CART,
    payload: {
      item,
    },
  }
}

export const setCartCurrentItemAction = (item) => {
  return {
    type: CartTypes.SET_CURRENT_CART,
    payload: {
      item,
    },
  }
}

export const removeItemFromCart = (index) => {
  return {
    type: CartTypes.REMOVE_FROM_CART,
    payload: {
      index,
    },
  }
}

export const removeItemFromListAll = (index) => {
  return {
    type: CartTypes.REMOVE_FROM_LIST_ALL,
    payload: {
      index,
    },
  }
}

export const removeItemFromCartById = (id) => {
  return {
    type: CartTypes.REMOVE_FROM_CART_BY_ID,
    payload: {
      id,
    },
  }
}

export const clearCartAction = () => {
  return {
    type: CartTypes.CLEAR_CART,
  }
}

export const setCartPaymentMethod = (method) => {
  return {
    type: CartTypes.SET_CART_PAYMENT_METHOD,
    payload: {method},
  }
}

export const setCartPropertyTypeAction = (type) => {
  return {
    type: CartTypes.SET_PROPERTY_TYPE,
    payload: {type},
  }
}
