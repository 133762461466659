import Grid from '@material-ui/core/Grid'
import ProgressRowEntry from '@root/components/PropertyList/inProgress/ProgressRowEntry'
import {useStyles} from '@root/components/PropertyList/styles'
import React from 'react'

const InProgressList = ({properties, onSelectProperty}) => {
  const classes = useStyles()

  return (
    <Grid container>
      {properties.map((property, index) => (
        <ProgressRowEntry key={index} index={index} property={property} onClick={() => onSelectProperty(property)} />
      ))}
    </Grid>
  )
}

InProgressList.propTypes = {}
InProgressList.defaultProps = {}

export default InProgressList
