import * as PropTypes from 'prop-types'
import React from 'react'

// o width precisa ter esse tamanho de 405px mais ou menos comeca a ficar estranha a imgem, a proporcao deve ser de 27:4
function BarcodeImageViewer({image}) {
  return <img src={image} width={405} height={60} />
}
BarcodeImageViewer.propTypes = {image: PropTypes.string, height: PropTypes.number}
BarcodeImageViewer.defaultProps = {}

export default BarcodeImageViewer
