/** @module pluralize */
/**
 * @param {number} quantity
 * @param {string} singularStr
 * @return {string}
 */
const pluralize = (quantity, singularStr) => {
  let finalStr = singularStr
  if (quantity > 1) {
    const lastLetter = singularStr.slice(-1)
    finalStr = singularStr + 's'

    if (lastLetter === 'm') {
      finalStr = singularStr.slice(0, -1) + 'ns'
    } else if (lastLetter === 'l') {
      finalStr = singularStr.slice(0, -1) + 'is'
    } else if (lastLetter === 'r') {
      finalStr = singularStr + 'es'
    }
  }
  return finalStr
}

export default pluralize
