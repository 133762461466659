export const getPropertyOwnerId = (propertyId, currentProperty, currentContract) => {
  /*
  O user que registrou o imovel nem sempre é o proprietario,
  pois existe a opcao de registrar um imovel no nome de outra pessoa.
  Por isso, devemos verificar primeiramente, se existe um owner.user_uid na entidade Contract.
  Se nao, puxamos o uid de quem registrou o imovel.
  
  1. verificando quem registrou o imovel
    contract.owner.data_source = 'me' | 'other'
  
      'me'    ->    user id = property.ower_id
      'other' ->    user id = contract.owner.user.id
  */
  if (currentContract?.owner?.data_source === 'other') {
    return currentContract?.owner?.user?.id
  } else if (propertyId === currentProperty?.uid) {
    return currentProperty?.ower_id
  }
  return null
}
