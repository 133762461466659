import Typography from '@material-ui/core/Typography'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import {Divider} from '@root/components/Divider/Divider'
import Guide1, {useGuide} from '@root/components/Guide1'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import UserAvatar from '@root/components/UserAvatar'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import paymentMapper from 'status-mapper/mapper/payment-mapper'

import SectionDocuments from '../../components/YourProperty/sections/SectionPayment/SectionDocuments'
import {firestore} from '../../firebase/firebase.utils'
import dateParser, {convertDateToBr, fromTimestampToDate, getMonthYear} from '../../lib/dateParser'
import {paymentMethodsMap} from '../../lib/paymentMethods'
import userModel from '../../model/userModel'
import routes from '../../utils/routes'
import {useStyles} from './styles'

const dividerMargin = '10px 0 0'
const DashboardDivider = () => <Divider dashed m={dividerMargin} />

export function TenantProperty() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const property = useSelector((state) => state.tenantProperty.data)
  const contract = useSelector((state) => state.tenantProperty?.data?.contract)
  const paymentMethod = useSelector((state) => state.tenantProperty?.data?.contract?.payment_method?.option)
  const payments = useSelector((state) => state.tenantProperty?.data?.contract?.payments)
  const ownerId = useSelector((state) => state.tenantProperty?.data?.ower_id)
  const [owner, setOwner] = useState(null)

  const [currentPayment, setCurrentPayment] = useState()
  const hasPayment = payments !== undefined
  const hasContractFile = contract?.contract_file !== undefined

  const pageTitle = useSelector((state) => state.tenantProperty?.data?.address.formatted)

  useEffect(() => {
    const currentDate = new Date()
    if (payments && payments.length > 0) {
      let curPayment = payments.find((payment) => dateParser(payment.due_date).getMonth() === currentDate.getMonth())
      if (!curPayment) {
        const insideValidity = currentDate <= dateParser(payments[payments.length - 1].due_date)
        if (insideValidity) {
          curPayment = payments[0]
        }
      }
      setCurrentPayment(curPayment)
    }
  }, [payments])

  useEffect(() => {
    async function loadOwner() {
      const ownerRefs = await firestore.collection(userModel.COLLECTION_NAME).where('uid', '==', ownerId).get()
      const ownerArray = ownerRefs.docs.map((owner) => owner.data())
      const currentOwner = ownerArray.find((owner) => owner.uid === ownerId)
      setOwner(currentOwner)
    }
    if (ownerId) {
      loadOwner()
    }
  }, [ownerId])

  useEffect(() => {
    dispatch(setNavbarTitleAction(pageTitle))
  }, [])

  return (
    <Box type="simple" bgType="white" m="0 auto">
      <div className={classes.sectionItem}>
        <Typography variant="h5" color="secondary">
          Proprietário
        </Typography>
        {owner && (
          <Box bgType="gray" smallPadding>
            <div className={classes.tenantAvatarInfo}>
              <UserAvatar user={owner} className={classes.tenantAvatarPhoto} />
              <div className={classes.tenantInfo}>
                <Typography variant="body1">{owner.name}</Typography>
                <Typography variant="body1">{owner.phone}</Typography>
                <Typography variant="body1">{owner.email}</Typography>
              </div>
            </div>

            <div className={classes.tenantGoToIconWrapper}>
              <IconWrapper onClick={() => {}}>
                <LaunchOutlinedIcon />
              </IconWrapper>
            </div>
          </Box>
        )}
      </div>

      <DashboardDivider />
      <div className={classes.sectionItem}>
        <Typography variant="h5" color="secondary">
          Contrato
        </Typography>
        <Box bgType="gray" smallPadding className={classes.boxContainer}>
          {!hasContractFile ? (
            <div className={classes.tenantInfo}>
              <Typography variant="body1">O prorprietário ainda não finalizou o contrato.</Typography>
            </div>
          ) : (
            <>
              <div className={classes.tenantInfo}>
                <Typography variant="body1">Contrato ({convertDateToBr(contract.validity.start)})</Typography>
              </div>
              <IconWrapper
                onClick={() => {
                  history.push(routes.DASHBOARD_CONTRACT_TENANT)
                }}>
                <LaunchOutlinedIcon />
              </IconWrapper>
            </>
          )}
        </Box>
      </div>

      <DashboardDivider />
      <div className={classes.sectionItem}>
        <Typography variant="h5" color="secondary">
          Pagamentos
        </Typography>
        <Box
          bgType="gray"
          smallPadding
          className={classes.boxContainer}
          onClick={() => {
            history.push(routes.DASHBOARD_TENANT_PAYMENT_LIST)
          }}>
          {!(currentPayment && paymentMethod && hasPayment) ? (
            <div className={classes.tenantInfo}>
              <Typography variant="body1">Método de pagamento não definido</Typography>
            </div>
          ) : (
            <>
              <div className={classes.tenantInfo}>
                <Typography variant="body1">Metodo: {paymentMethodsMap[paymentMethod]}</Typography>
                <Typography variant="body1">
                  Vigente: {convertDateToBr(currentPayment.due_date)} - {paymentMapper.translate(currentPayment.status)}
                </Typography>
              </div>
              <IconWrapper>
                <LaunchOutlinedIcon />
              </IconWrapper>
            </>
          )}
        </Box>
      </div>

      <DashboardDivider />
      <SectionDocuments />
    </Box>
  )
}
