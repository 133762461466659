import {Typography} from '@material-ui/core'
import FireIcon from '@root/assets/seguroincendio.svg'
import Page from '@root/components/Page'
import WhatsAppButton from '@root/components/WhatsAppButton'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

import BackLink from '../../../../BackLink'

function SeguroIncendio() {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarTitleAction('Seguro Incêndio'))
  }, [])
  return (
    <Page>
      <div style={{display: 'flex', alignItems: ' center', justifyContent: 'center', gap: '0.8rem', margin: '1.4rem'}}>
        <img src={FireIcon} alt="Seguro Incendio" style={{marginRight: '10px'}} />
        <Typography variant={'h4'}>Seguro Incendio</Typography>
      </div>

      <p style={{margin: '0 0 20px'}}>
        A lei sobre locações de imóveis urbanos, conhecida como “Lei do Inquilinato”, traz que é responsabilidade do
        proprietário do imóvel o pagamento de seguro complementar contra fogo, salvo disposição expressa em contrário no
        contrato.
      </p>
      <p style={{margin: '0 0 20px'}}>
        <strong>
          Acesse nosso
          <a style={{margin: '0 5px'}} href="https://moben.com.br/blog" target="_blank" rel="noreferrer">
            Blog
          </a>
          para saber mais
        </strong>
      </p>

      <p style={{marginBottom: 20}}>
        <strong>Entre em contato e faremos uma cotação personalizada sem compromisso.</strong>
      </p>

      <WhatsAppButton message="Olá, quero solicitar uma cotação de seguro incêndio para o meu imóvel" />
    </Page>
  )
}

export default SeguroIncendio
