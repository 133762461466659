import {Dialog} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import ShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ShareInvite'
import BaseModal from '@root/components/Modal/BaseModal'
import PropTypes from 'prop-types'
import React from 'react'

const ModalShareInvite = (props) => {
  const {inviteShare, ...restProps} = props

  return (
    <BaseModal
      isOpen={inviteShare.isOpen}
      okAction={inviteShare.close}
      onClose={inviteShare.close}
      size="md"
      cancelLabel=""
      title="Convidar interessado para esta proposta">
      <ShareInvite {...restProps} />
    </BaseModal>
  )
}

ModalShareInvite.defaultProps = {}

ModalShareInvite.propTypes = {
  // onInviteByEmail: PropTypes.func.isRequired,
  inviteShare: PropTypes.object.isRequired,
}

export default ModalShareInvite
