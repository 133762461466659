import {Divider as MUDivider} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: (stylesProps) => (stylesProps.w ? stylesProps.w : '100%'),
    margin: (stylesProps) => (stylesProps.m ? stylesProps.m : `${theme.margin[2]} 0`),
    backgroundColor: (stylesProps) => (stylesProps.dashed ? 'transparent' : ''),
    borderTop: (stylesProps) => (stylesProps.dashed ? `1px dashed ${theme.palette.secondary.main}` : ''),
  },
}))

export function Divider({dashed, m, w}) {
  const stylesProps = {
    dashed,
    m,
    w,
  }
  const classes = useStyles(stylesProps)

  return <MUDivider className={classes.root} />
}

Divider.defaultProps = {
  dashed: false,
}
Divider.propTypes = {
  dashed: PropTypes.bool,
  w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
