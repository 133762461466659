/** @module proposalLoader */
// {{[key:string]:{, type:string, data:object}}}
/**
 * @typedef {object.<string, {user:firebase.Firestore.DocumentSnapshot<DocumentReference>, type:string, data:object}>} proposalLoaderReturn
 */

import {proposalRepoFireInstance} from '@root/application/service/repository/proposal/ProposalRepositoryFirestore'
import ProposalManager from '@root/lib/contract/ProposalManager'
import {setPropertyProposalManagerAction} from '@root/redux/property/property.actions'
import store from '@root/redux/store'

/**
 * @deprecated
 * @see ProposalRepositoryInterface.loadAll
 * @param {Property.Proposals} proposals
 */
const proposalLoader = async (proposals) => proposalRepoFireInstance.loadAll(proposals)

/**
 * @deprecated
 * @see ProposalRepositoryInterface.loadSingle
 * @param {string} proposalId
 * @param {ProposalsEntry} proposalSingle
 */
export const proposalLoaderSingle = async (proposalId, proposalSingle) =>
  proposalRepoFireInstance.loadSingle(proposalId, proposalSingle)

/**
 * Basicamnte um singleton, puxando e setando no redux
 * @return {Promise<ProposalManager|*>}
 */
export const loadProposalWithManagerAndRedux = async () => {
  const propertyStore = store.getState().property
  const {data, proposals} = propertyStore
  if (proposals.manager) {
    return proposals.manager
  }

  const manager = ProposalManager.initWithProperty(data)
  await manager.loadUsersFromProposals()
  store.dispatch(setPropertyProposalManagerAction(manager))

  return manager
}

export default proposalLoader
