import {ReceiptsActionTypes} from './receipts.types'

export const setPropertyUIDForReceipts = (propertyUID) => ({
  type: ReceiptsActionTypes.SET_PROPERTY_UID_FOR_RECEIPTS,
  payload: propertyUID,
})

export const setReceiptsFromDatabase = (receiptObj) => ({
  type: ReceiptsActionTypes.SET_RECEIPTS_FROM_DATABASE,
  payload: receiptObj,
})

export const addReceipt = (receipt) => ({
  type: ReceiptsActionTypes.ADD_RECEIPT,
  payload: receipt,
})
