import Typography from '@material-ui/core/Typography'
import {propertyRepoInstance} from '@root/application/service/repository/property/PropertyRepository'
import {Button} from '@root/components/Button/Button'
import FilePicker from '@root/components/FilePicker'
import FileUploadPreview from '@root/components/FileUploadPreview'
import FormItem from '@root/components/FormHelper/FormItem'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import Title from '@root/components/Layout/Typography/Title'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {firebaseFilesUploader} from '@root/lib/firebaseUploader'
import {validateRequired} from '@root/lib/simpleValidators'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {setContractStepAction, setContractValidityAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'

const ReceiptCondominium = () => {
  /* query string params
  rid:        - reminder UID
  uid:        - user UID (tenant id)
  pid:        - property UID
  bill_name:  - year perdio (int)
  bill_type:  - year perdio (int)
  bill_subtype:  - year perdio (int)
  */
  const {control, errors, handleSubmit} = useForm()
  const {getParam} = useQuery()

  const reminderId = getParam('rid')
  const tenantId = getParam('uid')
  const propertyId = getParam('pid')
  const billName = getParam('bill_name')
  const billType = getParam('bill_type')
  const billSubtype = getParam('bill_subtype')

  const actions = actionDispatcher({
    setGlobalLoaderAction,
  })

  const [isFinished, setFinished] = useState(false)

  const sendFiles = async ({files}) => {
    actions.setGlobalLoaderAction(true)

    try {
      const uploadedFiles = await firebaseFilesUploader(files, billSubtype, `property/${propertyId}/${billType}`)
      await propertyRepoInstance.sendReceiptOwnership(propertyId, tenantId, reminderId, uploadedFiles)
      setFinished(true)
    } catch (ex) {
      alert(errorHandler(ex, 'Não foi possível completar a solicitação'))
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  if (isFinished) {
    return (
      <>
        <br />
        <Title>Tudo certo!</Title>
        <TextBlock>Registramos o seu comprovante e o proprietário já foi avisado.</TextBlock>
      </>
    )
  }
  return (
    <>
      <TextBlock>
        Para informar o pagamento é necessário o envio do comprovante, que será encaminhado ao proprietário.
      </TextBlock>

      <div style={{margin: '24px 0'}}>
        <FormItem
          control={control}
          errors={errors}
          type="file"
          name="files"
          label={`Comprovante da troca de titularidade da conta de ${billName}`}
          rules={{validade: validateRequired}}
          multiple={true}
          accept="application/pdf,image/*"
        />
      </div>

      <Button onClick={handleSubmit(sendFiles)}>Enviar</Button>
    </>
  )
}

ReceiptCondominium.propTypes = {}
ReceiptCondominium.defaultProps = {}

export default ReceiptCondominium
