import {MenuItem} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import IconBurgerMenu from '@root/components/icons/IconBurgerMenu'
import OptimizeMatcher from '@root/components/optimize/OptimizeMatcher'
import menuSections, {menuSections2} from '@root/lib/view/menu/menuSections'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import routes from '@root/utils/routes'

import {AvatarBadge} from './AvatarBadge'
import {LogoElement} from './LogoElement'

const menuItemFactory = (type, id, label, link, target = '_blank') => ({
  type,
  id: 'menu-entry-' + id,
  label,
  link,
  target,
})
const menuList = menuSections.map((block) => menuItemFactory('hash', block.id, block.section.title, block.id))
menuList.push(menuItemFactory('hash', 'quemsomos', 'Quem somos', 'quemsomos'))
menuList.push(menuItemFactory('hash', 'valores', 'Valores', 'valores'))
menuList.push(menuItemFactory('anchor', 'blog', 'Blog', 'https://moben.com.br/blog'))
menuList.push(menuItemFactory('anchor', 'calculadora', 'Calculadora', routes.CALCULATOR_READJUST, '_self'))

const menuList2 = menuSections2.map((block) => menuItemFactory('hash', block.id, block.section.title, block.id))
menuList2.push(menuItemFactory('anchor', 'blog', 'Blog', 'https://moben.com.br/blog'))
menuList2.push(menuItemFactory('anchor', 'calculadora', 'Calculadora', routes.CALCULATOR_READJUST, '_self'))

const MenuEntry = ({type, id, label, link, target}) => {
  const cls = 'nav-item'
  if (type === 'hash') {
    return (
      <HashLink id={id} to={`#${link}`} className={cls}>
        {label}
      </HashLink>
    )
  } else if (type === 'anchor') {
    return (
      <a target={target} rel="noreferrer" id={id} href={link} className={cls}>
        {label}
      </a>
    )
  }

  return null
}

const SignupItens = ({classes, styles}) => {
  const isLogged = useSelector((s) => s.user.isLogged)

  return (
    <>
      <ul className="home-register">
        {isLogged ? (
          <li>
            <AvatarBadge styles={styles} />
          </li>
        ) : (
          <>
            <li style={{marginRight: 8}}>
              <Link to={routes.REGISTER_LOGIN} className={classes.login}>
                LOGIN
              </Link>
            </li>
            <li>
              <Link to={routes.REGISTER_SIGNUP}>
                <Button variant="contained" color="primary" className={classes.cadastreseBtn}>
                  Cadastrar
                </Button>
              </Link>
            </li>
          </>
        )}
      </ul>
    </>
  )
}

const createBurgerMenuEntries = (list, onClose) =>
  list.map((entry, index) => (
    <MenuItem key={index} onClick={onClose}>
      <MenuEntry {...entry} />
    </MenuItem>
  ))

const createPlainMenuEntries = (list) =>
  list.map((entry, index) => (
    <li key={index}>
      <MenuEntry {...entry} />
    </li>
  ))
const BurgerMenu = () => {
  const anchorRef = useRef(null)
  const [isOpen, setOpenStatus] = useState(false)

  const handleOpen = () => setOpenStatus(true)
  const handleClose = () => setOpenStatus(false)
  const getAnchorEl = () => anchorRef.current

  return (
    <div style={{padding: 12}}>
      <div ref={anchorRef}>
        <IconBurgerMenu onClick={handleOpen} />
      </div>
      <Menu anchorEl={getAnchorEl} keepMounted={true} open={isOpen} onClose={handleClose} id="mobile-nav">
        <OptimizeMatcher alias="home">
          <OptimizeMatcher.Option variant="default">
            {createBurgerMenuEntries(menuList, handleClose)}
          </OptimizeMatcher.Option>
          <OptimizeMatcher.Option variant="1">{createBurgerMenuEntries(menuList2, handleClose)}</OptimizeMatcher.Option>
        </OptimizeMatcher>
      </Menu>
    </div>
  )
}

export const HomeMenuItens = (menuProps) => {
  const {classes, styles} = menuProps
  const theme = useTheme()

  // const displayPlainMenu = useMediaQuery(theme.breakpoints.up('md'))
  // e o tamanho minimo que precisa para os texto ficarem todos em uma unica linha
  const displayPlainMenu = useMediaQuery(theme.breakpoints.up(1290))
  const displayBurgerMenu = !displayPlainMenu

  return (
    <nav className={styles.nav} id="main-nav">
      {displayPlainMenu && (
        <>
          <LogoElement {...menuProps} />
          <ul className="home-menu">
            <OptimizeMatcher alias="home">
              <OptimizeMatcher.Option variant="default">{createPlainMenuEntries(menuList)}</OptimizeMatcher.Option>
              <OptimizeMatcher.Option variant="1">{createPlainMenuEntries(menuList2)}</OptimizeMatcher.Option>
            </OptimizeMatcher>
          </ul>
          <SignupItens {...menuProps} />
        </>
      )}

      {displayBurgerMenu && (
        <>
          <BurgerMenu />
          <LogoElement {...menuProps} />
          <SignupItens {...menuProps} />
        </>
      )}
    </nav>
  )
}
