import AddButton from '@root/components/App/Admin/ContractEditor/AddButton'
import {contractDisplayProps} from '@root/components/App/Admin/ContractEditor/contractPropTypes.ts'
import Iterator from '@root/components/App/Admin/ContractEditor/Iterator'
import React from 'react'

const ContractDisplay = ({section, list, entryPoint, enableAdd, enableDelete}) => {
  return (
    <>
      <h3>{section}</h3>
      {list.map((mainEntry, idx) => (
        <div key={idx}>
          <strong>
            {mainEntry.position.readable} {mainEntry.title}
          </strong>
          <Iterator
            items={mainEntry.items}
            level={0}
            path={[entryPoint, idx]}
            enableAdd={enableAdd}
            enableDelete={enableDelete}
          />
        </div>
      ))}
    </>
  )
}

ContractDisplay.defaultProps = {
  enableDelete: false,
  enableAdd: false,
}
ContractDisplay.propTypes = contractDisplayProps

export default ContractDisplay
