import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import DescriptionIcon from '@material-ui/icons/Description'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import MailIcon from '@material-ui/icons/Mail'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import VerticalAlignBottomOutlinedIcon from '@material-ui/icons/VerticalAlignBottomOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import {darken, lighten} from 'polished'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    '& > p': {
      marginTop: theme.margin[1],
      color: (stylesProps) => (stylesProps.disabled ? lighten(0.5, theme.palette.text.primary) : ''),
    },
  },
  button: {
    'width': 64,
    'height': 64,
    'borderRadius': 6,
    'background': theme.purpleButton.bg,
    'color': theme.purpleButton.color,
    '&:hover': {
      background: darken(0.1, theme.purpleButton.bg),
    },
    '&:disabled': {
      backgroundColor: theme.purpleButton.bg,
      color: theme.purpleButton.color,
      opacity: 0.5,
    },
    '& svg': {
      fill: theme.purpleButton.iconColor,
      transform: (stylesProps) => (stylesProps.type === 'enviar' ? 'rotate(-45deg) translate(10%)' : ''),
    },
  },
}))

const buttonType = {
  visualizar: {
    title: 'Visualizar',
    icon: <VisibilityOutlinedIcon fontSize="large" />,
  },
  baixar: {
    title: 'Baixar',
    icon: <VerticalAlignBottomOutlinedIcon fontSize="large" />,
  },
  enviar: {
    title: 'Enviar',
    icon: <SendOutlinedIcon fontSize="large" />,
  },
  editar: {
    title: 'Editar',
    icon: <EditOutlinedIcon fontSize="large" />,
  },
  criar: {
    title: 'Gerar PDF',
    icon: <DescriptionIcon fontSize="large" />,
  },
  recebimento: {
    title: 'Recebimentos',
    icon: <ReceiptIcon fontSize="large" />,
  },
  enviariq: {
    title: 'Enviar para o inquilino',
    icon: <MailIcon fontSize="large" />,
  },
  aprovar: {
    title: 'Aprovar contrato',
    icon: <CheckIcon fontSize="large" />,
  },
}

export function ContractButton({type, onClick, disabled, ...rest}) {
  const stylesProps = {disabled, type}

  const classes = useStyles(stylesProps)

  return (
    <span className={classes.root}>
      <Button variant="contained" className={classes.button} disabled={disabled} onClick={onClick} {...rest}>
        {buttonType[type].icon}
      </Button>
      <Typography variant="body1">{buttonType[type].title}</Typography>
    </span>
  )
}

ContractButton.propTypes = {
  type: PropTypes.oneOf(['visualizar', 'baixar', 'enviar', 'editar']).isRequired,
}
