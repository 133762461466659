// Material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

import useStyles from './styles'

const MainContainer = ({children}) => {
  const classes = useStyles()
  return (
    <Grid item className={classes.mainContainer}>
      {children}
    </Grid>
  )
}

const Image = ({img, alt}) => {
  const classes = useStyles()
  return (
    <div className={classes.imgDiv}>
      <img src={img} style={{width: '100%'}} alt={alt} />
    </div>
  )
}

const Content = ({title, textOne, textTwo}) => {
  const classes = useStyles()
  return (
    <div className={classes.contentDiv}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.texts}>
        {textOne}
      </Typography>
      <Typography variant="body1" className={classes.texts}>
        {textTwo}
      </Typography>
    </div>
  )
}
const ContentAlignRight = ({title, textOne, textTwo}) => {
  const classes = useStyles()
  return (
    <div className={classes.contentDivAlignRight}>
      <Typography variant="h5" className={classes.titleAlignRight}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.textsAlignRight}>
        {textOne}
      </Typography>
      <Typography variant="body1" className={classes.textsAlignRight}>
        {textTwo}
      </Typography>
    </div>
  )
}

const ImgContentContainer = ({imgFirst, img, alt, title, textOne, textTwo}) => {
  const matchMediaQuery = useMediaQuery('(max-width: 740px)')

  if (matchMediaQuery) {
    return (
      <MainContainer>
        <Image img={img} alt={alt} />
        <Content title={title} textOne={textOne} textTwo={textTwo} />
      </MainContainer>
    )
  } else {
    if (imgFirst) {
      return (
        <MainContainer>
          <Image img={img} alt={alt} />
          <Content title={title} textOne={textOne} textTwo={textTwo} />
        </MainContainer>
      )
    } else {
      return (
        <MainContainer>
          <ContentAlignRight title={title} textOne={textOne} textTwo={textTwo} />
          <Image img={img} alt={alt} />
        </MainContainer>
      )
    }
  }
}

export default ImgContentContainer
