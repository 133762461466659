import Typography from '@material-ui/core/Typography'
import AddressForm from '@root/components/Forms/AddressForm'
import actionDispatcher from '@root/lib/actionDispatcher'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {ownerContractSave, ownerLoader} from '@root/lib/contract/ownerLoader'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentUser, setUserFromFirebaseAction} from '@root/redux/user/user.actions'
import {DATASOURCE_ME} from '@root/utils/constants'

import {useStyles} from './styles'

const OwnerAddress = () => {
  const classes = useStyles()
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, reset} = formHook
  const [currentOwner, setCurrentOwner] = useState()

  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)
  const property = useSelector((s) => s.property?.data)
  const contractPropertySnap = useSelector((s) => s.property?.snap)
  const contractOwner = useSelector((s) => s.property?.data?.contract?.owner)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,
    setUserFromFirebaseAction,
    setCurrentUser,
  })

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)
    const updatedUserData = {
      ...currentOwner.userData,
      address: {
        ...formData.address,
      },
    }

    try {
      await ownerContractSave(contractPropertySnap, currentOwner.source, currentOwner.userRef, updatedUserData)

      const progressInfo = updateProgressDataFromRedux(property)
      await contractPropertySnap.ref.update(progressInfo.path, progressInfo.payload)

      actions.setGlobalLoaderAction(false)
      history.push(getNextStep().path)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const handleDataSource = async (contractOwner) => {
    actions.setGlobalLoaderAction(true)
    const ownerInfo = await ownerLoader(contractOwner)

    const isMe = ownerInfo.source === DATASOURCE_ME
    const loaderDelay = isMe ? 0 : 700

    setCurrentOwner(ownerInfo)
    reset({address: ownerInfo?.userData?.address})
    setTimeout(() => actions.setGlobalLoaderAction(false), loaderDelay)
  }

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  useEffect(() => {
    if (contractOwner) {
      handleDataSource(contractOwner)
    }
  }, [contractOwner])

  return (
    <ContractContainer>
      {/* <Typography variant="body1" style={{textAlign: 'center', margin: '-20px 0 20px'}}>
        Endereço de {currentOwner?.userData?.name}
      </Typography> */}
      <Typography variant="body1" style={{textAlign: 'center', margin: '0 0 20px'}}>
        Insira os dados de quem vai assinar o contrato como locador
      </Typography>
      <FormProvider {...formHook}>
        <form className={classes.root}>
          <AddressForm address={currentOwner?.userData?.address} />
        </form>
      </FormProvider>
      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}

export default OwnerAddress
