import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {CreditAnalysisTypes} from '@root/redux/creditAnalysis/creditAnalysis.types'
import {SigninTypes} from '@root/redux/signin/signin.types'

const initialState = {
  propertyRef: undefined,
  property: {},
  tenants: [],
  fiador: undefined,
}
const reducers = {
  [SigninTypes.LOG_OUT]: () => ({...initialState}),

  [CreditAnalysisTypes.SET_CREDIT_ANALYSIS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.tenants = payload.tenants
      draft.fiador = payload.fiador
      draft.propertyRef = payload.propertyRef
      draft.property = payload.propertyRef.data()
    })
  },
  [CreditAnalysisTypes.SET_CREDIT_PROPERTY_REF]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.propertyRef = payload.propertyRef
      draft.property = payload.propertyRef.data()
    })
  },
  [CreditAnalysisTypes.SET_CREDIT_INVITE_EMAILS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.property.invite_emails = payload.emails
    })
  },
  [CreditAnalysisTypes.SET_CREDIT_TENANTS]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.tenants = payload.tenants
    })
  },
  [CreditAnalysisTypes.SET_CREDIT_FIADOR]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.fiador = payload.fiador
    })
  },
}

export default reducerMatch(reducers, initialState)
