import {Button} from '@root/components/Button/Button'
import FilePicker from '@root/components/FilePicker'
import IconTrashCan from '@root/components/icons/IconTrashCan'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import React from 'react'

export const FileRenderContent = ({files, name, onClearFile}) => {
  const isNotEmpty = !!files
  const isMultiple = isNotEmpty && Array.isArray(files)
  let filesList = []
  if (isNotEmpty) {
    filesList = isMultiple ? files : [files]
  }

  const hasFiles = filesList.length > 0

  return (
    <Box
      bgType="gray"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isMultiple ? 'space-between' : 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}>
      {!hasFiles ? (
        <Button w="110px" fz="11px" p="8px 4px">
          Anexar arquivo
        </Button>
      ) : (
        filesList.map((file, idx) => (
          <div key={idx} style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <IconWrapper name={name} bgType="red" onClick={onClearFile}>
              <IconTrashCan />
            </IconWrapper>
            <span
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                paddingRight: '8px',
                display: 'flex',
                alignItems: 'center',
              }}>
              {file.name}
            </span>
          </div>
        ))
      )}
    </Box>
  )
}

const FileRender = (controllerProps, helpers, formItemProps) => {
  const {forwardOnChange, forwardOnBlur, forwardOnFocus} = helpers
  const {multiple, accept, name, id} = formItemProps
  const files = controllerProps.value

  const handleClearFile = (e) => {
    e.stopPropagation()
    e.target.name = name
    forwardOnChange(undefined, e)
  }

  return (
    <FilePicker
      inputRef={helpers.inputRef}
      accept={accept}
      multiple={multiple}
      inputCompatibility={false}
      onChange={forwardOnChange}
      onBlur={forwardOnBlur}
      onFocus={forwardOnFocus}
      id={id}
      name={name}>
      <FileRenderContent onClearFile={handleClearFile} name={name} files={files} multiple={multiple} />
    </FilePicker>
  )
}

export default FileRender
