import LocalLoader from '@root/components/Loader/LocalLoader'
import React from 'react'
import {useSelector} from 'react-redux'

const Loader = (props) => {
  const {loading, mode, data} = useSelector((s) => s.globalLoader)
  return <LocalLoader {...props} loading={loading} mode={mode} data={data} />
}

Loader.defaultProps = LocalLoader.defaultProps
Loader.propTypes = LocalLoader.propTypes

export default Loader
