import {Grid} from '@material-ui/core'
import {alternateFactory} from '@root/components/App/Login/signupHelper'
import FormItem from '@root/components/FormHelper/FormItem'
import useSignin from '@root/hooks/useSignin'
import {validatePhone} from '@root/lib/simpleValidators'
import userModel from '@root/model/userModel'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {PHONE_FORMAT} from '@root/utils/constants'
import routes from '@root/utils/routes'

const PhoneSignin = () => {
  const {control, errors, watch} = useFormContext()
  const {handleFocusBlurForLogger} = useSignin()

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormItem
          {...handleFocusBlurForLogger(watch, 'Telefone celular', 'phonenumber')}
          control={control}
          errors={errors}
          fitContainer={true}
          type="mask"
          label="Telefone celular"
          name="phonenumber"
          rules={{
            validate: validatePhone,
          }}
          maskInputProps={{
            format: PHONE_FORMAT,
          }}
        />
      </Grid>
    </>
  )
}

PhoneSignin.propTypes = {}

PhoneSignin.defaultProps = {}

PhoneSignin.formData = {}
PhoneSignin.isSignin = true
PhoneSignin.isInUse = async () => {
  const form = PhoneSignin.formData
  const isPhoneInUse = await userModel.getByPhone(form.phonenumber)

  const resp = {
    exist: false,
    message: '',
  }

  if (!isPhoneInUse) {
    resp.exist = true
    resp.message = 'Este telefone não esta cadastrado'
  }

  return resp
}
PhoneSignin.handler = async () => {
  return {
    auth: undefined,
    extra: undefined,
    redirectTo: routes.SIGNUP_VERIFICATION_CODE,
  }
}
PhoneSignin.alternate = alternateFactory(PhoneSignin.isRegister, routes.SIGNUP_REGISTER_PHONE)

export default PhoneSignin
