/** @module envDetector */
import envConfig from '@root/env-config.js'

const env = envConfig.MODE
const hostname = window.location.hostname
const ENV_PRODUCTION = 'production'
const ENV_STAGING = 'staging'
const ENV_DEVELOPMENT = 'development'
const ENV_TEST = 'test'

export const isCypressEnabled = () => envConfig.CYPRESS_ENABLED === '1'
export const getEnv = () => env
export const isDevEnv = () => env === 'development' || env === 'dev' || env === 'local'
export const isTestEnv = () => env === 'test' || env === 'testing'
export const isStagingEnv = () => env === 'staging' || hostname.indexOf('dev-moben') !== -1
export const isDevTestEnv = () => isDevEnv() || isTestEnv()
export const isProductionEnv = () => !isDevTestEnv() && !isStagingEnv()
export const getRealEnv = () => {
  if (isProductionEnv()) {
    return ENV_PRODUCTION
  } else if (isStagingEnv()) {
    return ENV_STAGING
  } else if (isDevEnv()) {
    return ENV_DEVELOPMENT
  } else if (isTestEnv()) {
    return ENV_TEST
  }

  return 'unknown'
}
