/** @module propertySumAmounts */
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'

export const IPTU_BILLING_MONTHLY = 'monthly'
export const IPTU_BILLING_ANNUAL = 'annual'

const extractAmountsData = ({rent_amount, condominium_amount, iptu_amount}) => ({
  rent_amount,
  condominium_amount,
  iptu_amount,
})

/**
 * @param {{iptu_billing_type, rent_amount, condominium_amount, iptu_amount}} amountsProps
 * @param {boolean} convertFromCents defaults to false
 * @return {number}
 */
export const propertySumAmounts = (amountsProps, convertFromCents = false) => {
  if (!amountsProps) {
    return 0
  }
  // iptu_billing_type:
  const iptuType = IPTU_BILLING_ANNUAL // IPTU vai ser sempre calculado anualmente
  const amounts = extractAmountsData(amountsProps)
  const isAnnual = iptuType === IPTU_BILLING_ANNUAL

  const totalSum = Object.keys(amounts).reduce((total, field) => {
    let amount = formatMoneyBrToFloat(amounts[field])
    const isIptu = field === 'iptu_amount'

    if (!iptuType && isIptu) {
      return total
    }

    return total + (isIptu && isAnnual ? amount / 12 : amount)
  }, 0)

  return convertFromCents ? totalSum / 100 : totalSum
}
