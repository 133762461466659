import {objectToQueryString} from '@root/application/infra/api/fetcher'
import {isDevTestEnv} from '@root/lib/envDetector'
import useSWR, {useSWRConfig} from 'swr'

/**
 * @typedef {{isLoading: boolean, isError: any, error:any, response: any, isListing: boolean, hasListingEntries:boolean, listingSize: number, refresh: ()=>void}} UseRepoResult
 * @typedef {(...any)=>UseRepoResult} HookRunnerResult
 */

const needToClearCache = isDevTestEnv()
const SEPARATOR = '.'

export const getAvailablePropsFromRepoInstance = (repoIntance) => {
  const props = Reflect.ownKeys(Object.getPrototypeOf(repoIntance))

  return props.filter((prop) => {
    return prop[0] !== '_' && prop !== 'constructor'
  })
}

const paramsToKey = (params) => {
  const keyPieces = params
    .filter((param) => typeof param !== 'function')
    .reduce((pieces, param) => {
      if (Array.isArray(param)) {
        pieces.push(paramsToKey(param))
      } else if (typeof param === 'object') {
        pieces.push(objectToQueryString(param, SEPARATOR))
      } else {
        pieces.push(param)
      }

      return pieces
    }, [])

  return keyPieces.join(SEPARATOR)
}

/**
 * @param {HttpRepositoryInterface} repoInstance
 * @param {string} method
 * @param {any[]} argsList arguments list to be passed to instance::method(argsList)
 * @param {any} fallback value to use as fallback if response is undefined, defaults to undefined
 *
 * @return {UseRepoResult}
 */
export const useRepo = (repoInstance, method, argsList = [], fallback = undefined) => {
  const key = paramsToKey([repoInstance._retrieveUserId(), method, ...argsList])
  const {mutate, cache} = useSWRConfig()

  if (needToClearCache) {
    // cache.clear()
  }

  // console.log('useRepo', {repoInstance: repoInstance, method, argsList})
  const {data, error} = useSWR(key, () => repoInstance[method].apply(repoInstance, argsList), {
    refreshWhenOffline: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    errorRetryCount: 2,
    dedupingInterval: 4000,
    loadingTimeout: 5000,
  })
  const resp = data ?? fallback
  const isResponseListing = Array.isArray(resp)
  const listingSize = isResponseListing ? resp.length : 0
  const hasEntries = listingSize > 0

  const hasError = error !== undefined
  return {
    response: resp,
    refresh: (data) => mutate(key, data),
    isLoading: !hasError && !data,
    isError: hasError,
    error: error,
    isListing: isResponseListing,
    hasListingEntries: hasEntries,
    listingSize: listingSize,
  }
}

/**
 * A function that number of arguments depends on the implementation.
 * This returned function will return an UseRepoResult
 *
 * @param {HttpRepositoryInterface} instance
 * @param {string} method
 * @param {any} fallback value to use as fallback if response is undefined, defaults to undefined
 * @see {@link UseRepoResult}
 *
 * @return {HookRunnerResult}
 */
export const hookRunner = (instance, method, fallback = undefined) => {
  return function () {
    return useRepo.apply(this, [instance, method, Array.from(arguments), fallback])
  }
}
