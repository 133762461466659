import Typography from '@material-ui/core/Typography'
import {ConfirmationTitle} from '@root/components/page/DefaultConfirmationPage/DefaultConfirmationPage'
import PropTypes from 'prop-types'
import React from 'react'

const ContractRenewContent = () => {
  return (
    <>
      <ConfirmationTitle />
      <Typography variant="body2">
        Sua solicitação foi enviada. <br />
        Em breve nossa equipe vai entrar me contato com você para darmos continuidade no processo.
      </Typography>
    </>
  )
}

ContractRenewContent.propTypes = {}
ContractRenewContent.defaultProps = {}

export default ContractRenewContent
