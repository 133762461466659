/** @module cpfValidator */
const getVerificationCode = (total) => {
  let mod = (total * 10) % 11
  mod = mod < 10 ? mod : 0
  return String(mod)
}

const calculateDigit = (list, baseMultiplier = 10) => {
  const sum = list.reduce((total, num, index) => {
    const multiplier = baseMultiplier - index
    const preTotal = num * multiplier
    return preTotal + total
  }, 0)

  return getVerificationCode(sum)
}

/**
 * @param {number|string} cpfNumber
 * @return {boolean}
 */
const cpfValidator = (cpfNumber) => {
  const numbersNoDigit = String(cpfNumber).substring(0, 9).split('')
  const digits = cpfNumber.substring(9)

  const verificationCode1 = calculateDigit(numbersNoDigit)
  numbersNoDigit.push(verificationCode1)

  const verificationCode2 = calculateDigit(numbersNoDigit, 11)
  const digitsVerification = verificationCode1 + verificationCode2

  return digits === digitsVerification
}

export default cpfValidator
