import {BASE_URL} from '@root/utils/constants'
import routes from '@root/utils/routes'

export const getPropertyAdminLink = (propertyId) => {
  return adminUrlBuilder('imoveis', propertyId)
}

export const getUserAdminLink = (userId) => {
  return adminUrlBuilder('usuarios', userId)
}

export const getGenericAdminLink = (path) => {
  return adminUrlBuilder(path)
}

const adminUrlBuilder = (adminPath, id) => {
  // adminPath = 'usuarios' || 'imoveis'
  if (typeof adminPath !== 'string') {
    return false
  }
  const urlPieces = [`${BASE_URL}${routes.ADMIN}`, adminPath]

  if (id) {
    urlPieces.push(id)
  }
  return urlPieces.join('/')
}
