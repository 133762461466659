import DateFnsUtils from '@date-io/date-fns'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import InviteForm from '@root/components/Forms/InviteForm'
import {currencyFormatProps} from '@root/components/NumberMask'
import Page from '@root/components/Page'
import actionDispatcher from '@root/lib/actionDispatcher'
import ProposalManager from '@root/lib/contract/ProposalManager'
import {dateParser} from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import {formatMoneyBrToFloat} from '@root/lib/moneyFormater'
import {paymentMethodsOptions} from '@root/lib/predefinedValuesOptions'
import {proposalSaver} from '@root/lib/proposalSaver'
import {numberFormatValidateCurrency, validateRequired} from '@root/lib/simpleValidators'
import {USER_TYPE_TENANT} from '@root/lib/userType'
import propertyModel from '@root/model/propertyModel'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentProperty} from '@root/redux/property/property.actions'
import contractMapper from 'status-mapper/mapper/contract-mapper'
import {USER_SOURCE_BOLETO} from '@root/utils/constants'
import routes from '@root/utils/routes'

import {useStyles} from './styles'

const CreatePayments = () => {
  const classes = useStyles()
  const history = useHistory()

  const formHook = useForm()
  const {handleSubmit, control, errors, reset, watch} = formHook

  const loggedUser = useSelector((s) => s.user.user)
  const propertySnap = useSelector((s) => s.property.snap)
  const property = useSelector((s) => s.property.data)
  const contract = useSelector((s) => s.property.data.contract)

  const actualDate = new Date()

  const [selectedStartDate, setSelectedStartDate] = useState(new Date())
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(actualDate.setMonth(actualDate.getMonth() + 30)))
  const [defaultAmount, setDefaultAmount] = useState()

  const paymentMethodSelected = watch('payment_method')
  const isPaymentBoleto = paymentMethodSelected === 'boleto'

  const actions = actionDispatcher({
    setCurrentProperty,
    setGlobalLoaderAction,
    setNavbarTitleAction,
  })

  const handleChangeStartDate = (e) => {
    if (e === undefined || e === null) return alert('Ocorreu algum problema ao selecionar a data.')

    const endDate = new Date(e)
    endDate.setMonth(e.getMonth() + 30)

    setSelectedStartDate(e)
    setSelectedEndDate(endDate)
  }

  const handleGerarBoletoOnClick = async (formData) => {
    const proposalManager = ProposalManager.initWithProperty(property)

    const {payment_method, ...tenantData} = formData

    try {
      actions.setGlobalLoaderAction(true)
      const proposalHelper = await proposalSaver({
        manager: proposalManager,
        propertySnap,
        originSource: USER_SOURCE_BOLETO,
        autoAnalysis: false,
        tenantFormData: tenantData,
        proposalType: USER_TYPE_TENANT,
      })

      const total = formatMoneyBrToFloat(formData.boleto_amount)
      const contractStatus = contractMapper.status.INPROGRESS

      const propertyDataUpdate = {
        rent_amount: total,
        total_amount: total,
        contract: {
          ...contract,
          total_amount: total,
          status: contractStatus,
          payment_method: {option: payment_method},
          rentfees: {
            ...contract.rentfees,
            total,
            rent: total,
          },
          validity: {
            start: selectedStartDate,
            end: selectedEndDate,
          },
        },
      }
      await propertySnap.ref.update(propertyDataUpdate)

      await propertyModel.fetchCreatePaymentsList(property.uid, loggedUser.uid)
      await propertySnap.ref.update({
        [proposalHelper.getPath()]: proposalHelper.toObject(),
        selected_proposal: {
          uid: proposalHelper.getId(),
          interested: 0,
        },
        tenant: proposalHelper.getTenantAt(0).uid,
      })

      actions.setGlobalLoaderAction(false)
      history.push(routes.DASHBOARD_BOLETOS_STATUS)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)

      alert(errorHandler(ex, 'Não foi possível gerar os boletos nesse momento.'))
    }
  }

  useEffect(() => {
    actions.setNavbarTitleAction('Recebimentos')
  }, [actions])

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(contract, 'validity')) {
      setSelectedStartDate(dateParser(property.contract.validity.start))
      setSelectedEndDate(dateParser(property.contract.validity.end))
    }

    if (Object.prototype.hasOwnProperty.call(contract, 'rentfees')) {
      reset({boleto_amount: contract.rentfees.total})
    }
  }, [contract, property])

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(contract, 'payments')) {
      history.push(routes.DASHBOARD_BOLETOS_STATUS)
    }
  }, [contract, history])

  return (
    <Page>
      <FormProvider {...formHook}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <div>
              <FormItem
                defaultValue={contract?.payment_method?.option}
                control={control}
                errors={errors}
                fitContainer={true}
                type="select"
                name="payment_method"
                label="Como gostaria de gerenciar os recebimentos?"
                options={paymentMethodsOptions}
                rules={{validate: validateRequired}}
                extraStyles="row"
              />
            </div>

            {isPaymentBoleto && <InviteForm allowFullEdit displayUploads={false} displayIncomeField={false} />}

            <div className={classes.fieldsContainer}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="validity_start_date"
                label="Início"
                value={selectedStartDate}
                onChange={handleChangeStartDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />

              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="validity_end_date"
                label="Término"
                value={selectedEndDate}
                onChange={setSelectedEndDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>

            <div className={classes.valorContainer}>
              <FormItem
                label="Valor"
                control={control}
                errors={errors}
                type="mask"
                name="boleto_amount"
                rules={{
                  validate: numberFormatValidateCurrency,
                }}
                maskInputProps={currencyFormatProps}
              />
            </div>
          </div>

          <div className={classes.gerarBoletoBtnContainer}>
            <Button bgType="green" onClick={handleSubmit(handleGerarBoletoOnClick)}>
              Gerar Boletos
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </FormProvider>
    </Page>
  )
}

export default CreatePayments
