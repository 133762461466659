import Typography from '@material-ui/core/Typography'
import proptypesTypography, {extractProps} from '@root/components/Layout/Typography/proptypesTypography'
import useTypographyStyle from '@root/components/Layout/Typography/useTypographyStyle'
import PropTypes from 'prop-types'
import React from 'react'

const TextSecondary = (props) => {
  const resultProps = extractProps(props, {secondary: true})
  const classes = useTypographyStyle(resultProps.style)

  return (
    <Typography component="span" {...resultProps.comp} className={classes.merged}>
      {props.children}
    </Typography>
  )
}

TextSecondary.propTypes = {...proptypesTypography.constraints}
TextSecondary.defaultProps = {...proptypesTypography.defaults}

export default TextSecondary
