import {Typography} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import IconWhatsApp from '@root/components/icons/IconWhatsApp'
import {Box} from '@root/components/Layout/Container/Box/Box'
import Text from '@root/components/Layout/Typography/Text'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import analyticsLogger from '@root/firebase/analytics.utils'
import useWhatappLinker from '@root/hooks/useWhatappLinker'
import Clipboard from '@root/lib/Clipboard'
import errorHandler from '@root/lib/errorHandler'
import {validateEmail, validatePhone} from '@root/lib/simpleValidators'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {CPF_FORMAT, PHONE_FORMAT} from '@root/utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    'marginTop': theme.margin[4],
    '& > p': {
      marginBottom: theme.margin[2],
    },
    '& > div': {
      marginBottom: theme.margin[5],
    },
  },
  emailContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    '& > div': {
      transform: 'translate(0, 11px)',
      marginRight: '15px',
    },
  },
  linkContainer: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'wordBreak': 'break-all',
    '& > p': {
      marginBottom: theme.margin[2],
      fontWeight: 600,
    },
  },
}))

const ShareInvite = ({shareLink, hideEmail, hideWhatsapp, onInviteByEmail}) => {
  const classes = useStyles()
  const formEmail = useForm()
  const formWA = useForm()

  const waLinker = useWhatappLinker()

  const [isCopySnackVisibile, setCopySnackVisibility] = useState(false)

  const inviteByEmail = async (form) => {
    analyticsLogger.logEvent('button_click', {alternative_event_name: 'convite_enviado_email', button_name: 'ENVIAR'})
    if (onInviteByEmail) {
      await onInviteByEmail(form.email)
    }
    formEmail.reset('email', '')
  }

  const inviteByWA = async (form) => {
    analyticsLogger.logEvent('button_click', {
      alternative_event_name: 'convite_enviado_whatsapp',
      button_name: 'ENVIAR',
    })

    const text = `Você esta sendo convidado para fazer parte do aluguel na Moben, a plataforma que descomplica o processo de locação.\nCadastre-se no link abaixo e o resto deixa com a gente\n\n${shareLink}`
    waLinker.open(waLinker.create(form.phone, text))
    formWA.reset('phone', '')
  }

  const copyShareLink = () => {
    Clipboard.copy(shareLink)
      .then(() => setCopySnackVisibility(true))
      .catch((err) => {
        alert(
          errorHandler(err),
          'Não foi possível copiar o link para a área de transferência, tente copiar manualmente',
        )
      })
  }

  const closeSnackCopy = () => {
    setCopySnackVisibility(false)
  }

  return (
    <div className={classes.root}>
      {!hideEmail && (
        <>
          <Text>Convidar por email</Text>
          <Box bgType="gray" smallPadding h="46px">
            <div className={classes.emailContainer}>
              <FormItem
                fitContainer
                control={formEmail.control}
                errors={formEmail.errors}
                type="textfield"
                name="email"
                placeholder="nome@provedor.com.br"
                rules={{
                  validate: validateEmail,
                }}
              />
              <Button w="79px" h="29px" fz="11px" onClick={formEmail.handleSubmit(inviteByEmail)}>
                ENVIAR
              </Button>
            </div>
          </Box>
        </>
      )}

      {!hideWhatsapp && (
        <>
          <Text>
            Envie diretamente pelo Whatsapp <IconWhatsApp style={{verticalAlign: 'bottom'}} />
          </Text>

          <Box bgType="gray" smallPadding>
            <div className={classes.linkContainer}>
              <FormItem
                control={formWA.control}
                errors={formWA.errors}
                name="phone"
                type="mask"
                label="Telefone do interessado"
                maskInputProps={{
                  format: PHONE_FORMAT,
                }}
                rules={{validate: validatePhone}}
              />
              <Button w="234px" h="29px" fz="11px" onClick={formWA.handleSubmit(inviteByWA)}>
                ENVIAR
              </Button>
            </div>
          </Box>
        </>
      )}

      <Text>Copie o link clicando no botão abaixo, e envie através do seu aplicativo de mensagens favorito.</Text>
      <Box bgType="gray" smallPadding h="102px">
        <div className={classes.linkContainer}>
          <TextSecondary bold>{shareLink}</TextSecondary>
          <Button w="234px" h="29px" fz="11px" m="12px 0 0 0" onClick={copyShareLink}>
            COPIAR LINK
          </Button>
        </div>
      </Box>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={4000}
        onClose={closeSnackCopy}
        open={isCopySnackVisibile}>
        <Alert severity="success">copiado</Alert>
      </Snackbar>
    </div>
  )
}

ShareInvite.defaultProps = {
  title: (
    <>
      <Typography variant="body2">
        Na Moben, os próprios interessados podem cadastras seus dados e caso sejam selecionados também terão um Painel
        de Controle exclusivo.
      </Typography>
      <Typography variant="body2">
        Caso você já tenha uma proposta com mais de um inquilino, escolha um deles para enviar o convite e ele convidará
        os outros inquilinos.
      </Typography>
      <Typography variant="body2">
        Faça o convite para o cadastro na plataforma escolhendo uma das opções abaixo:
      </Typography>

      <Typography variant="body1" align="center">
        Convidar por email
      </Typography>
    </>
  ),
  subtitle: '',
  titleMarginSize: 'large',
  hideEmail: true,
  hideWhatsapp: false,
}

ShareInvite.propTypes = {
  shareLink: PropTypes.string.isRequired,
  onInviteByEmail: PropTypes.func,
  onClickBack: PropTypes.func,
  hideEmail: PropTypes.bool,
  hideWhatsapp: PropTypes.bool,
}

export default ShareInvite
