import DateFnsUtils from '@date-io/date-fns'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {addMonths, subMonths} from 'date-fns'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import calcContractLengthMonths, {validateContractLength} from '@root/lib/calcContractLengthMonths'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import {dateParser} from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractStepAction, setContractValidityAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {MIN_CONTRACT_LENGTH} from '@root/utils/constants'

import {Box} from '../Layout/Container/Box/Box'
import {useStyles} from './styles'

export function Validity() {
  const history = useHistory()
  const classes = useStyles()
  const {handleSubmit, reset} = useForm()
  const actualDate = new Date()
  const [selectedStartDate, setSelectedStartDate] = useState(new Date())
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(actualDate.setMonth(actualDate.getMonth() + 30)))
  const contractLength = useMemo(() => {
    const len = calcContractLengthMonths(selectedStartDate, selectedEndDate)
    return isNaN(len) ? 0 : len
  }, [selectedStartDate, selectedEndDate])

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const validity = useSelector((s) => s.property.data?.contract?.validity)
  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractValidityAction,
    setContractStepAction,
  })

  const handleClickContinuar = async () => {
    if (!checkContractLength(selectedStartDate, selectedEndDate)) {
      return
    }

    actions.setGlobalLoaderAction(true)
    try {
      const validityData = {
        start: selectedStartDate,
        end: selectedEndDate,
        has_validity_clause: 'no',
        months: contractLength,
      }

      await propertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.validity': validityData,
        }),
      )
      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const handleChangeStartDate = (startDate) => {
    if (!startDate) return
    const endDate = new Date(startDate)
    endDate.setMonth(startDate.getMonth() + 30)

    setSelectedStartDate(startDate)
    setSelectedEndDate(endDate)
  }

  const handleAddMonthOnClick = () => {
    setSelectedEndDate(addMonths(selectedEndDate, 1))
  }

  const handleRemoveMonthOnClick = () => {
    const newEnd = subMonths(selectedEndDate, 1)
    if (checkContractLength(selectedStartDate, newEnd)) {
      setSelectedEndDate(newEnd)
    }
  }

  const checkContractLength = (startDate, endDate) => {
    const isValid = validateContractLength(startDate, endDate)
    if (!isValid) {
      alert(`Período minímo para locação é de ${MIN_CONTRACT_LENGTH} meses`)
    }

    return isValid
  }

  useEffect(() => {
    if (validity && validity.start && validity.end) {
      setSelectedStartDate(dateParser(validity.start))
      setSelectedEndDate(dateParser(validity.end))
      reset({has_validity_clause: validity.has_validity_clause})
    }
  }, [validity])

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.validity}`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography variant="body2">
            O contrato começa a valer na data identificada como "Início". A partir dessa data o inquilino deve estar
            liberado para acessar o imóvel.
          </Typography>
          <div className={classes.twoFieldContainer}>
            <div className={classes.validityDatesContainer}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="validity_start_date"
                label="Início"
                value={selectedStartDate}
                onChange={handleChangeStartDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="validity_end_date"
                label="Término"
                value={selectedEndDate}
                onChange={setSelectedEndDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>

            <div className={classes.validityNumberOfMonthsBoxWrapper}>
              <Box bgType="gray">
                <div className={classes.validityNumberOfMonthsContainer}>
                  <span className="arrow" id="validity-month-dec" onClick={handleRemoveMonthOnClick}>
                    <ExpandMoreIcon />
                  </span>

                  <div className="months">
                    <span>{contractLength}</span>
                    <span>meses</span>
                  </div>

                  <span className="arrow" id="validity-month-inc" onClick={handleAddMonthOnClick}>
                    <ExpandLessIcon />
                  </span>
                </div>
              </Box>
            </div>
          </div>

          <Box bgType="gray">
            <Typography variant="body1" style={{fontWeight: 600, marginBottom: 5}}>
              Rescisão
            </Typography>
            <Typography variant="body2" component="p">
              O Proprietário não pode rescindir o contrato antecipadamente, exceto nas hipóteses previstas no artigo 8˚
              (rescisão pelo adquirente do imóvel) e 9˚ (infração contratual, inadimplência e reparos urgentes pelo
              Poder Público) da Lei do Inquilinato n˚8.245/91.
            </Typography>
          </Box>
        </MuiPickersUtilsProvider>
      </form>
      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
