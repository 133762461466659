import 'firebase/firestore'

import {Typography} from '@material-ui/core'
import {Button} from '@root/components/Button/Button'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionFactory} from '@root/components/FormHelper/optionFactory'
import IconCheck from '@root/components/icons/IconCheck'
import PageMini from '@root/components/PageMini'
import actionDispatcher from '@root/lib/actionDispatcher'
import errorHandler from '@root/lib/errorHandler'
import {validateRequired} from '@root/lib/simpleValidators'
import {readableValue} from '@root/lib/simpleValuesDisplayMap'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {
  setGlobalLoaderAction,
  setGlobalLoaderDataAction,
  setGlobalLoaderWithModeAction,
} from '@root/redux/loader/loader.actions'
import {setCurrentUser} from '@root/redux/user/user.actions'
import routes, {routeWithParams} from '@root/utils/routes'

const OPTIONS = [
  {label: readableValue('adult'), field: 'adult'},
  {label: readableValue('child'), field: 'child'},
  {label: readableValue('dog_large'), field: 'dog_large'},
  {label: readableValue('dog_medium'), field: 'dog_medium'},
  {label: readableValue('dog_small'), field: 'dog_small'},
  {label: readableValue('cat'), field: 'cat'},
]

const QUANTITY_OPTIONS = Array(9)
  .fill()
  .map((_, i) => optionFactory(i + 1, `${i + 1}`))
QUANTITY_OPTIONS.push(optionFactory('10+', '10+'))

const ExtraInformation = ({match}) => {
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, control, errors, reset, watch, clearErrors, setValue} = formHook

  const user = useSelector((s) => s.user.user)
  const userRef = useSelector((s) => s.user.firebaseRef)

  const livingBeings = watch('living_beings')

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setGlobalLoaderWithModeAction,
    setGlobalLoaderDataAction,
    setCurrentUser,
    setNavbarTitleAction,
  })

  const handleContinue = async (form) => {
    actions.setGlobalLoaderAction(true)
    try {
      await userRef.update('more_about.living_beings', form.living_beings)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
      return
    }
    actions.setGlobalLoaderAction(false)

    const {property, proposal, analysis} = user.tenant_invite_link
    const fiadorPath = routeWithParams(routes.REGISTER_TENANT_FIADOR, property, analysis, proposal)
    history.push(!property ? routes.MYDATA_PROFILE : fiadorPath)
  }

  const handleCheck = (item) => (evt) => {
    const isChecked = evt.target.value
    const fieldNameQty = `living_beings.${item.field}.quantity`
    if (!isChecked) {
      setValue(fieldNameQty, 0)
      clearErrors(fieldNameQty)
    }
  }

  const handleQuantity = (item) => (evt) => {}

  useEffect(() => {
    actions.setNavbarTitleAction('Informações adicionais')
  }, [])

  useEffect(() => {
    reset({
      living_beings: user.more_about?.living_beings ?? {},
    })
  }, [user])

  return (
    <PageMini innerTitle="Agora por favor informe o proprietário a quantidade de pessoas e animais que ocuparão o imóvel.">
      <FormProvider {...formHook}>
        <Typography>Clique nos itens correspondentes e informe a quantidade</Typography>
        {OPTIONS.map((option, i) => (
          <div key={i}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <FormItem
                control={control}
                errors={errors}
                type="checkbox-single"
                name={`living_beings.${option.field}.check`}
                defaultValue={false}
                options={[optionFactory(option.label, true)]}
                onChange={handleCheck(option)}
              />
              <FormItem
                control={control}
                errors={errors}
                type="select"
                name={`living_beings.${option.field}.quantity`}
                defaultValue={0}
                options={QUANTITY_OPTIONS}
                onChange={handleQuantity(option)}
                disabled={!(livingBeings && livingBeings[`${option.field}`]?.check)}
                rules={{
                  validate: livingBeings && livingBeings[`${option.field}`]?.check ? validateRequired : undefined,
                }}
              />
            </div>
          </div>
        ))}

        <Button
          m="10px auto"
          w="168px"
          h="48px"
          fz="15pt"
          bgType="green"
          icon={IconCheck}
          onClick={handleSubmit(handleContinue)}>
          Continuar
        </Button>
      </FormProvider>
    </PageMini>
  )
}
ExtraInformation.propTypes = {
  match: PropTypes.any,
}
export default ExtraInformation
