import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {makeStyles} from '@material-ui/core/styles'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import React, {Fragment} from 'react'

const useStyles = makeStyles({
  root: {
    'width': '100%',
    'marginTop': (isRow) => (isRow ? '8px' : null),
    'display': 'flex',
    'justifyContent': (isRow) => (isRow ? 'center' : null),
    'flexDirection': (isRow) => (isRow ? 'row' : null),
    '& .MuiFormControlLabel-label': {
      // fontSize: '13px',
    },
    '& > label': {
      margin: 0,
    },
    '& > label:first-child': {
      marginRight: (isRow) => (isRow ? '8px' : null),
    },
  },

  description: {
    marginTop: -12,
    marginLeft: 12,
    marginBottom: 12,
  },
})

const ItensList = ({options, stateValue, helpers, parentId, isRow}) => {
  const classes = useStyles(isRow)
  const {forwardOnChange} = helpers

  if (!Array.isArray(options)) {
    return null
  }

  return options.map((item) => {
    if (React.isValidElement(item)) {
      return item
    }

    const {value, label, description} = item
    return (
      <Fragment key={value}>
        <FormControlLabel
          className={`${parentId}-option`}
          checked={value == stateValue}
          label={label}
          value={value}
          onChange={forwardOnChange}
          control={<Radio />}
        />
        {description && (
          <small className={classes.description}>
            <TextBlock size="0.75rem">{description}</TextBlock>
          </small>
        )}
      </Fragment>
    )
  })
}

const RadioRender = (controllerProps, helpers, formItemProps) => {
  const {options, id, extraStyles} = formItemProps

  const isRow = extraStyles === 'row'

  const classes = useStyles(isRow)

  return (
    <RadioGroup classes={{root: classes.root}} id={id}>
      <ItensList
        key={id}
        options={options}
        stateValue={controllerProps.value}
        helpers={helpers}
        parentId={id}
        isRow={isRow}
      />
    </RadioGroup>
  )
}

export default RadioRender
