/** module stepWalker */

import contractStepsMap from '@root/lib/contract/contractStepsMap'
import {isMatchPath} from '@root/lib/invitePath'
import store from '@root/redux/store'
import routes, {routeWithID} from '@root/utils/routes'

const getCurrentStepFromState = () => {
  const contract = store.getState().contract
  return contract.step
}

const getStepByPath = (path) => contractStepsMap.find((step) => step.path_orig === path)
const getStepById = (id) => contractStepsMap.find((step) => step.id === id)

export const stepRouteMatchCurrent = (path) => {
  const curStep = getCurrentStepFromState()
  return isMatchPath(path, curStep.path_orig)
}

const getStepsIndexRoute = (propertyId) => {
  const state = store.getState()
  const id = propertyId ?? state.property.propertyId
  return routeWithID(routes.CONTRACT_STEPS_LIST, id)
}

const getCreatedRoute = (propertyId) => {
  const state = store.getState()
  const id = propertyId ?? state.property.propertyId
  return routeWithID(routes.CONTRACT_CREATED, id)
}

const getInitialStep = (backToEnd = false) => {
  const state = store.getState()
  const step = contractStepsMap[0]
  step.path = step.path_orig + '?id=' + state.contract?.propertyId
  if (backToEnd) {
    step.path += '&backend=1'
  }

  return step
}

const getNextStep = () => {
  const state = store.getState()
  const nextIndex = getCurrentStepFromState().index + 1
  const step = contractStepsMap[nextIndex]

  if (!step) {
    return
  }

  step.path = step.path_orig + '?id=' + state.property.currentPropertyId
  return step
}

const getPrevStep = () => {
  const prevIndex = getCurrentStepFromState().index - 1
  return contractStepsMap[prevIndex]
}

const buildStepUrl = (path, backToEnd = false) => {
  const state = store.getState()
  const step = getStepByPath(path)

  if (!step) {
    return
  }

  step.path = routeWithID(step.path_orig, state.property.currentPropertyId)

  if (backToEnd) {
    step.path += '&backend=1'
  }
  return step.path
}

const validatePreviousSteps = () => {
  const curStep = getCurrentStepFromState()
  const state = store.getState()
  const contractStore = state.contract
  let step
  for (let i = 0; i < curStep.index; i++) {
    step = contractStepsMap[i]
    if (step.reducer && !contractStore[step.reducer]) {
      return false
    }
  }

  return true
}

export {
  getStepsIndexRoute,
  getCreatedRoute,
  getInitialStep,
  getStepByPath,
  getStepById,
  getNextStep,
  getPrevStep,
  buildStepUrl,
  validatePreviousSteps,
  getCurrentStepFromState,
}
