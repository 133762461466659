import {Typography} from '@material-ui/core'
import useStyles from '@root/components/App/Dashboard/Proposals/styles'
import IconPencil from '@root/components/icons/IconPencil'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {BaseModal} from '@root/components/Modal/Modal'
import UserAvatar from '@root/components/UserAvatar'
import {calcAge} from '@root/lib/dateParser'
import {formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import React from 'react'

/**
 * @component
 * @param {string} label
 * @param {*} value
 * @param {boolean} show
 */
const DataEntry = ({label, value, show}) =>
  show && (
    <Typography variant="body1">
      {label}: {value}
    </Typography>
  )
DataEntry.defaultProps = {
  show: true,
}

/**
 * @component
 * @param {User} user
 * @param {boolean} showFullData
 */
const InterestedAnalysis = ({user, showFullData, onEdit}) => {
  const classes = useStyles()
  const canEdit = onEdit && user?.uid

  const handleEditAction = () => {
    if (canEdit) {
      onEdit(user.uid)
    }
  }
  if (!user) {
    return null
  }

  return (
    <div className={`${classes.container} ${classes.containerTop}`}>
      <>
        <div style={{display: 'flex', alignItems: 'flex-start'}}>
          <div>
            <Typography variant="body1" style={{fontWeight: 700}}>
              {user.name}
            </Typography>

            <DataEntry label="Telefone" value={user.phone} />
            <DataEntry label="E-Mail" value={user.email} show={showFullData} />
            <DataEntry label="CPF" value={user.cpf} />
            <DataEntry label="RG" value={user.rg} />
            <DataEntry label="Idade" value={calcAge(user.birthdate)} />
            <DataEntry label="Estado Civil" value={user.marital_status} />
            <DataEntry label="Profissão" value={user.profession} show={showFullData} />
            <DataEntry label="Ocupação" value={user.occupation} show={showFullData} />
            <DataEntry label="Renda Bruta Mensal" value={formatFloatToBrCurrency(user.income)} show={showFullData} />
            {user.documents?.income?.length && (
              <div style={{display: 'flex', gap: 5, flexWrap: 'wrap'}}>
                <Typography style={{flex: 1}} variant="body1">
                  Comprovante de Renda:
                </Typography>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                  {user.documents?.income.map((inc) => (
                    <a key={inc.download_url} href={inc.download_url} target="_blank" rel="noreferrer">
                      {inc.name_original}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>

          {canEdit && (
            <IconWrapper style={{marginLeft: 24}} onClick={handleEditAction}>
              <IconPencil />
            </IconWrapper>
          )}
        </div>
        <div>
          <UserAvatar user={user} className={classes.large} />
        </div>
      </>
    </div>
  )
}

InterestedAnalysis.defaultProps = {
  showFullData: false,
}

export default InterestedAnalysis
