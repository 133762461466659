import Typography from '@material-ui/core/Typography'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import justicaIcon from '@root/assets/assistencia_icon.svg'
import contractIcon from '@root/assets/como_funciona_icon_5.png'
import contractUploadIcon from '@root/assets/download_file.png'
import askIcon from '@root/assets/Icon_awesome-question.svg'
import moneyIcon from '@root/assets/Icon_material-attach-money.svg'
import folderIcon from '@root/assets/Icon_metro-folder-open.svg'
import idIcon from '@root/assets/Icon_metro-profile.svg'
import reparosIcon from '@root/assets/reparos_house.svg'
import seguroFiancaIcon from '@root/assets/segurofianca.svg'
import seguroIncendioIcon from '@root/assets/seguroincendio.svg'
import DashboardWrapper from '@root/components/App/Dashboard/DashboardWrapper'
import BackLink from '@root/components/BackLink'
import analyticsLogger from '@root/firebase/analytics.utils'
import createAlternativeEventName from '@root/lib/createAlternativeEventName'
import {whatsAppLinkGenerator} from '@root/lib/simplePhoneFormatter'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {BLOG_URL, MOBEN_WHATSAPP} from '@root/utils/constants'
import {OWNER_DASHBOARD_BLOCK_TITLES, TENANT_DASHBOARD_BLOCK_TITLES} from '@root/utils/constants'

import useStyles from './styles'

export const MobenBlogLink = () => {
  const handleBlogLinkClick = () => {
    const pathName = window.location.pathname.replaceAll('/', '_').substr(1)
    analyticsLogger.logEvent('button_click', {
      button_name: 'blog',
      alternative_event_name: `${pathName}_visitar_blog`,
    })
  }

  return (
    <h4>
      Acesse o nosso{' '}
      <a
        href={BLOG_URL}
        onClick={handleBlogLinkClick}
        target="_blank"
        rel="noreferrer"
        style={{color: '#0C005F', textDecoration: 'underline'}}>
        Blog
      </a>{' '}
      para saber mais
    </h4>
  )
}

export const ContatoSemCompromisso = () => (
  <p style={{fontWeight: 700}}>Entre em contato e faremos uma cotação personalizada sem compromisso.</p>
)

const Fakedoor = ({children, title, hideWhatsappBtn, whatsappMsg, eventParams}) => {
  const classes = useStyles()
  const [uriEncodedWhatsappMsg, setUriEncodedWhatsappMsg] = useState('')
  const [iconImg, setIconImg] = useState('')

  useEffect(() => {
    switch (title.toLowerCase()) {
      case OWNER_DASHBOARD_BLOCK_TITLES.reformas_reparos.toLowerCase():
        setIconImg(reparosIcon)
        break
      case OWNER_DASHBOARD_BLOCK_TITLES.assistencia_juridica.toLowerCase():
        setIconImg(justicaIcon)
        break
      case OWNER_DASHBOARD_BLOCK_TITLES.seguro_fianca.toLowerCase():
        setIconImg(seguroFiancaIcon)
        break
      case OWNER_DASHBOARD_BLOCK_TITLES.seguro_incendio.toLowerCase():
        setIconImg(seguroIncendioIcon)
        break
      case OWNER_DASHBOARD_BLOCK_TITLES.contrato_vigente.toLowerCase():
        setIconImg(contractUploadIcon)
        break
      case TENANT_DASHBOARD_BLOCK_TITLES.ask.toLowerCase():
        setIconImg(askIcon)
        break
      case TENANT_DASHBOARD_BLOCK_TITLES.contract.toLowerCase():
        setIconImg(contractIcon)
        break
      case TENANT_DASHBOARD_BLOCK_TITLES.additional_documents.toLowerCase():
        setIconImg(idIcon)
        break
      case TENANT_DASHBOARD_BLOCK_TITLES.comprovantes.toLowerCase():
        setIconImg(folderIcon)
        break
      case TENANT_DASHBOARD_BLOCK_TITLES.payments.toLowerCase():
        setIconImg(moneyIcon)
        break
    }

    if (whatsappMsg && whatsappMsg.length > 0) {
      setUriEncodedWhatsappMsg(encodeURIComponent(whatsappMsg))
    }
  }, [title, whatsappMsg])

  const sendWhatsappMsg = () => {
    window.open(whatsAppLinkGenerator(MOBEN_WHATSAPP, uriEncodedWhatsappMsg))
    analyticsLogger.logEvent('button_click', {
      ...eventParams,
      button_name: title,
      alternative_event_name: `${createAlternativeEventName(title)}_whatsapp`,
    })
  }

  return (
    <DashboardWrapper title={title} iconImg={iconImg}>
      <section className={classes.fakedoorMiddle}>{children}</section>
      <section className={classes.fakedoorBclassName}>
        {!hideWhatsappBtn ? (
          <Typography className={classes.whatsappBtn} onClick={sendWhatsappMsg}>
            <WhatsAppIcon className={classes.whatsappIcon} />
            <span>WhatsApp</span>
          </Typography>
        ) : null}
        <div style={{display: 'flex', marginTop: 30, justifyContent: 'center'}}>
          <BackLink />
        </div>
      </section>
    </DashboardWrapper>
  )
}

export default Fakedoor
