import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {ContractTypes} from '@root/redux/contract/contract.types'
import {UserActionTypes} from '@root/redux/user/user.types'

export const createEmptyContract = () => ({
  step: {
    index: -1,
    path: undefined,
  },
  // propertyId: isProductionEnv() ? undefined : 'lzhXZp4tctSIcfSCxzUc',
  snap: undefined,
  propertyId: undefined,
  owner: undefined,

  yourinfo: undefined,
  property: undefined,
  validity: undefined,
  rentdue: undefined,
  rentfees: undefined,
  penalties: undefined,
  guarantees: undefined,
  readjustment_index: {
    type: undefined,
  },
  forum: undefined,
  fire_insurance: undefined,
  witnesses: undefined,
  tenant: undefined,
  survey: undefined,
  observations: undefined,
  status: undefined,
  approval: undefined,
})
const initialState = createEmptyContract()

const reducers = {
  [UserActionTypes.SET_USER_NULL]: (state, {payload}) => {
    return createEmptyContract()
  },

  [ContractTypes.SET_CONTRACT_FROM_DATABASE]: (state, {payload}) => {
    const contract = payload.contract
    const propSnap = payload.propertySnap
    return produce(state, (draft) => {
      draft.property = contract.property
      draft.propertyId = contract.property.uid
      draft.validity = contract.validity
      draft.rentdue = contract.rentdue
      draft.rentfees = contract.rentfees
      draft.yourinfo = contract.yourinfo
      draft.penalties = contract.penalties
      draft.guarantees = contract.guarantees
      draft.readjustment_index = contract.readjustment_index
      draft.forum = contract.forum
      draft.fire_insurance = contract.fire_insurance
      draft.witnesses = contract.witnesses
      draft.survey = contract.survey
      draft.owner = contract.owner
      draft.observations = contract.observations
      draft.status = contract.status
      draft.approval = contract.approval

      if (propSnap) {
        // draft.propertySnap = propSnap
        draft.snap = propSnap
      }
    })
  },

  [ContractTypes.RESET_CONTRACT]: createEmptyContract,

  [ContractTypes.SET_CONTRACT_SNAP]: (state, {payload}) => {
    const propSnap = payload.propertySnap
    return produce(state, (draft) => {
      draft.snap = propSnap
    })
  },

  [ContractTypes.SET_CONTRACT_FROM_DATABASE]: (state, {payload}) => {
    const propSnap = payload.propertySnap
    return produce(state, (draft) => {
      if (propSnap) {
        draft.snap = propSnap
        draft.property = propSnap.data()
        draft.propertyId = propSnap.id
      }
    })
  },

  [ContractTypes.SET_CONTRACT_TENANT_FROM_DATABASE]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.tenant = payload.tenant
    })
  },

  [ContractTypes.SET_CONTRACT_PROPERTY]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.propertyId = payload.property.uid
      draft.property = payload.property
    })
  },

  [ContractTypes.SET_CONTRACT_VALIDITY]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.validity = {
        ...payload.validity,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_RENTDUE]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.rentdue = {
        ...payload.rentdue,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_RENTFEES]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.rentfees = {
        ...payload.rentfees,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_YOURINFO]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.yourinfo = {
        ...payload.yourinfo,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_PENALTIES]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.penalties = {
        ...payload.penalties,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_GUARANTEES]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.guarantees = payload.guarantees
    })
  },

  [ContractTypes.SET_CONTRACT_READJUSTMENT_INDEX]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.readjustment_index = {
        ...payload.readjustment_index,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_FORUM]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.forum = {
        ...payload.forum,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_FIRE_INSURANCE]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.fire_insurance = {
        ...payload.fire_insurance,
      }
    })
  },
  [ContractTypes.SET_CONTRACT_WITNESSES]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.witnesses = {
        ...payload.witnesses,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_TENANT]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.tenant = {
        ...payload.tenant,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_SURVEY]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.survey = {
        ...payload.survey,
      }
    })
  },

  [ContractTypes.SET_CONTRACT_STEP]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.step = {
        ...payload.step,
      }
    })
  },

  [ContractTypes.ADD_CONTRACT_OBSERVATION]: (state, {payload}) => {
    return produce(state, (draft) => {
      if (!draft.observations) {
        draft.observations = []
      }
      draft.observations.push(payload.observation)
    })
  },
}

export default reducerMatch(reducers, initialState)
