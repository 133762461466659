import {mobenAPIInstance} from '@root/application/infra/datasource/MobenAPI'
import Page from '@root/components/Page'
import React, {useEffect} from 'react'
import {BASE_URL_API} from '@root/utils/constants'

const SSRDash = ({match}) => {
  const dashPath = match.params.path

  const checkAuth = async () => {
    // /admin/m9UO5b5uIE2Sn/dashboard/general
    try {
      const dashurl = BASE_URL_API + '/admin/m9UO5b5uIE2Sn/dashboard/' + dashPath
      const response = await mobenAPIInstance.get('/auth/admin')
      const token = response.token

      // const expiresIn = 60 * 60 * 24 * 2 * 1000 // 2 days
      // cookie options {maxAge: expiresIn, httpOnly: true, secure: !isLocalTestEnv()}
      document.cookie = `session=${token}; Path=/; HttpOnly; SameSite=lax;`
      window.location.href = dashurl
    } catch (ex) {
      alert('erro ao autenticar o admin')
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])
  return (
    <Page>
      <h2>Segura a emoção ai</h2>
      <h4>fazendo uns paranaue para você acessar o painel novo</h4>
    </Page>
  )
}

SSRDash.propTypes = {}
SSRDash.defaultProps = {}

export default SSRDash
