import {createStyles, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconPlus from '@root/components/icons/IconPlus'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import PropTypes from 'prop-types'
import React from 'react'

const useBoxActionStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    marginRight: 12,
  },
  actionsWrapper: {},
}))

// <img src={require('../../assets/new/house_dashboard.png')} width={'60px'} />
const BoxAction = ({children, icon, onClick}) => {
  const classes = useBoxActionStyle()
  return (
    <Box onClick={onClick}>
      <div className={classes.root}>
        <div className={classes.contentWrapper}>
          <div className={classes.iconWrapper}>{icon}</div>
          <div>{children}</div>
        </div>
        <div className={classes.actionsWrapper}>
          <IconWrapper>
            <IconPlus />
          </IconWrapper>
        </div>
      </div>
    </Box>
  )
}

BoxAction.propTypes = {}
BoxAction.defaultProps = {}

export default BoxAction
