import * as Sentry from '@sentry/react'
import {objectToQueryString} from '@root/application/infra/api/fetcher'
import {addBreadCrumb} from '@root/lib/errorHandler'

export default class HttpApiInterface {
  static METHOD_POST = 'post'
  static METHOD_GET = 'get'
  static METHOD_PUT = 'put'
  static METHOD_PATCH = 'patch'
  static METHOD_DELETE = 'delete'

  /**
   * @param {object} config
   * @param {string} config.baseUrl
   */
  constructor(config) {
    this.baseUrl = config.baseUrl
  }

  logRequestError(err, url, requestOptions) {
    addBreadCrumb('URL', {
      message: 'URL',
      data: {url},
    })

    //para nao logar e enviar para o sentry dados sensiveis sobre o cartao de credito
    if (url.indexOf('/addCard') === -1) {
      addBreadCrumb('Request Options', {request: JSON.stringify(requestOptions)})
    }
    Sentry.captureException(err)
  }

  /**
   * @param {string} method
   * @param {string} endpoint
   * @param {object} [body]
   * @return Promise<any>
   */
  request(method, endpoint, body = undefined) {
    throw new Error('code me')
  }

  /**
   * @param {string} url
   * @param {object} [params]
   */
  get(url, params) {
    return this.request(HttpApiInterface.METHOD_GET, `${url}?${objectToQueryString(params)}`)
  }

  /**
   * @param {string} url
   * @param {object} [body]
   */
  post(url, body) {
    return this.request(HttpApiInterface.METHOD_POST, url, body)
  }

  /**
   * @param {string} url
   * @param {object} [body]
   */
  put(url, body) {
    return this.request(HttpApiInterface.METHOD_PUT, url, body)
  }

  /**
   * @param {string} url
   * @param {object} [body]
   */
  patch(url, body) {
    return this.request(HttpApiInterface.METHOD_PATCH, url, body)
  }

  /**
   * @param {string} url
   * @param {object} [params]
   */
  delete(url, params) {
    return this.request(HttpApiInterface.METHOD_DELETE, url + objectToQueryString(params))
  }
}
