import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {
  bankRepoInstance,
  useBankRepoListAccountsProperty,
  useBankRepoListRemaining,
} from '@root/application/service/repository/bank/BankRepository'
import {Button} from '@root/components/Button/Button'
import AccountItem from '@root/components/Contract/BankAccount/AccountItem'
import {useStyles} from '@root/components/Contract/styles'
import {Divider} from '@root/components/Divider/Divider'
import BankInfoForm from '@root/components/Forms/Bank/BankInfoForm'
import IconBank from '@root/components/icons/IconBank'
import IconPlus from '@root/components/icons/IconPlus'
import BoxAction from '@root/components/Layout/Container/Box/BoxAction'
import SectionContainer from '@root/components/Layout/Container/SectionContainer'
import Text from '@root/components/Layout/Typography/Text'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import Title from '@root/components/Layout/Typography/Title'
import BaseModal from '@root/components/Modal/BaseModal'
import actionDispatcher from '@root/lib/actionDispatcher'
import {updateProgressDataFromRedux} from '@root/lib/contract/progressChecker'
import {getNextStep, getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import errorHandler from '@root/lib/errorHandler'
import pluralize from '@root/lib/intl/pluralize'
import {calcPct, formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import mobenTheme from '@root/mobenTheme'
import {setContractStepAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {setCurrentUser, setUserFromFirebaseAction} from '@root/redux/user/user.actions'
import produce from 'immer'
import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'

const MAX_TOTAL_SPLIT = 100
const OwnerBankInfo = () => {
  const classes = useStyles()
  const history = useHistory()
  const formHook = useForm()
  const {handleSubmit, reset} = formHook

  const property = useSelector((s) => s.property?.data)
  const contractPropertySnap = useSelector((s) => s.property?.snap)

  const accountsRemaining = useBankRepoListRemaining(property.uid)
  const accountsPropertyResult = useBankRepoListAccountsProperty(property.uid)

  const [bankAccount, setBankAccount] = useState()
  const [currentOwner, setCurrentOwner] = useState()
  const [isAddAccount, setAddAccount] = useState(false)
  const [isAccountFormVisible, setAccountFormVisibility] = useState(false)
  const [isEditSplits, setEditSplits] = useState(false)
  const [accountsPropertyAmountSum, setAmountSum] = useState({split: 0, rent: 0})

  const [accountsList, setAccountsList] = useState([])
  const accountsListSize = accountsList.length
  const isAccountListEmpty = accountsListSize === 0

  const isValidTotalSplit = accountsPropertyAmountSum.split === MAX_TOTAL_SPLIT
  const hasEmptySplit = accountsList.some((acc) => acc.amount_pct === 0)
  const isContinueDisabled = !isValidTotalSplit || hasEmptySplit

  const rentAmount = property?.contract?.total_amount ?? 0

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractStepAction,

    setUserFromFirebaseAction,
    setCurrentUser,
  })

  const startNewAccount = () => setAddAccount(true)
  const endNewAccount = () => setAddAccount(false)

  const startEditSplits = () => setEditSplits(true)
  const endEditSplits = () => setEditSplits(false)

  const showAccountForm = () => setAccountFormVisibility(true)
  const hideAccountForm = () => setAccountFormVisibility(false)

  const addExistingAccounts = async (accounts) => {
    return Promise.all(
      accounts.map(async (acc) => {
        return bankRepoInstance.addPropertyToAccount(acc.uid, property.uid, acc.amount_pct)
      }),
    )
  }

  const removeExistingAccount = async (account) => {
    return bankRepoInstance.removePropertyFromAccount(account.uid, property.uid)
  }

  const updateSplitAmounts = async (updateAccounts, extraAccounts = []) => {
    const updateList = [...updateAccounts, ...extraAccounts].map((updAcc) => ({
      id: updAcc.uid,
      amount: updAcc.amount_pct,
    }))
    return bankRepoInstance.updateSplits(property.uid, updateList)
  }

  const addNewAccounts = async (newAccounts) => {
    return Promise.all(
      newAccounts.map(async (newAcc) => {
        const response = await bankRepoInstance.add({
          account_id: '',
          property_id: property.uid,
          bank: {
            code: newAcc.bank_code,
            agency: newAcc.agency,
            agency_digit: newAcc.agency_digit,
            account: newAcc.account,
            account_digit: newAcc.account_digit,
            legal_name: newAcc.legal_name,
            type: newAcc.type,
            document_number: newAcc.document_number,
          },
        })

        response.amount_pct = newAcc.amount_pct
        return response
      }),
    )
  }

  const addLocalAccount = (account) => {
    setAccountsList([...accountsList, account])
  }

  const checkAddExisting = (acc) => acc.is_add === true
  const checkIsNew = (acc) => acc.uid.indexOf('new_') === 0
  const checkNotNew = (acc) => acc.uid.indexOf('new_') === -1

  const handleClickContinuar = async () => {
    if (!isValidTotalSplit) {
      alert('As porcentagens da conta bancária devem totalizar 100%')
      return
    } else if (hasEmptySplit) {
      alert('Alguma das contas está com a porcentagem zerada')
      return
    }

    actions.setGlobalLoaderAction(true)
    try {
      const addedAccounts = await addNewAccounts(accountsList.filter(checkIsNew))
      await addExistingAccounts(accountsList.filter(checkAddExisting))
      await updateSplitAmounts(accountsList.filter(checkNotNew), addedAccounts)

      const progressInfo = updateProgressDataFromRedux(property)
      await contractPropertySnap.ref.update(progressInfo.path, progressInfo.payload)

      actions.setGlobalLoaderAction(false)
      history.push(getNextStep().path)
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const handleAddNewAccount = async (form) => {
    const bank = form.bank
    addLocalAccount({
      uid: `new_${Date.now()}`,
      is_new: true,
      amount_pct: 0,
      legal_name: bank.legal_name,
      document_number: bank.document_number,
      bank_code: bank.code,
      agency: bank.agency,
      agency_digit: bank.agency_digit,
      account: bank.account,
      account_digit: bank.account_digit,
      type: bank.type,
    })

    endNewAccount()
    hideAccountForm()
    startEditSplits()

    reset()
  }

  const handleRemoveAccount = async (account, index) => {
    if (!checkAddExisting(account) && !checkIsNew(account)) {
      actions.setGlobalLoaderAction(true)
      await removeExistingAccount(account)
      actions.setGlobalLoaderAction(false)
    }

    const newState = produce(accountsList, (draft) => {
      draft.splice(index, 1)
    })
    setAccountsList(newState)

    startEditSplits()
  }

  const handleAddExistingAccount = (account) => {
    addLocalAccount({...account, is_add: true})
    startEditSplits()
    endNewAccount()
  }

  const handleSplitAmountChange = (evt, account) => {
    let value = Number(evt.target.value)

    if (isNaN(value)) {
      value = 0
    }

    const index = accountsList.findIndex((acc) => acc.uid === account.uid)
    const newState = produce(accountsList, (draft) => {
      draft[index].amount_pct = value
    })
    setAccountsList(newState)
  }

  useEffect(() => {
    setAccountsList(accountsPropertyResult.response)
  }, [accountsPropertyResult.response])

  useEffect(() => {
    const splitSum = accountsList.reduce((total, acc) => total + acc.amount_pct, 0)
    const rentSum = accountsList.reduce((total, acc) => total + calcPct(rentAmount, acc.amount_pct), 0)
    setAmountSum({
      split: splitSum,
      rent: rentSum,
    })
  }, [accountsList])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  return (
    <ContractContainer>
      <SectionContainer>
        <TextBlock style={{marginBottom: '24px'}}>
          Para cada imóvel você deve selecionar uma conta que sera utilizada para o recebimento.
          <br />É possível adicionar mais de uma conta para o mesmo imóvel para dividir o alguel entre várias contas
          bancárias.
        </TextBlock>
        <BoxAction icon={<IconBank />} onClick={showAccountForm}>
          <Title>Adicionar nova conta</Title>
          <Text>Cadastre uma nova conta bancária para recebimento do aluguel.</Text>
        </BoxAction>

        <SectionContainer divider>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={5}>
              <Text>
                {isAccountListEmpty ? (
                  'Nenhuma conta bancária para este imóvel'
                ) : (
                  <>
                    {[
                      accountsListSize,
                      pluralize(accountsListSize, 'conta'),
                      pluralize(accountsListSize, 'bancária'),
                      pluralize(accountsListSize, 'registrada'),
                    ].join(' ')}
                  </>
                )}
              </Text>
            </Grid>
            <Grid item>
              <TextSecondary color={!isValidTotalSplit ? mobenTheme.extraColors.INVALID : ''}>
                TOTAL&nbsp;&nbsp;
                <TextSecondary bold>{accountsPropertyAmountSum.split}%</TextSecondary>
                &nbsp;&nbsp;
                {formatFloatToBrCurrency(accountsPropertyAmountSum.rent)}
              </TextSecondary>
            </Grid>
          </Grid>
        </SectionContainer>

        <SectionContainer divider>
          {accountsList.map((account, index) => (
            <AccountItem
              key={index}
              index={index}
              editable={true}
              totalAmount={rentAmount}
              account={account}
              isEditing={isEditSplits}
              onRemove={handleRemoveAccount}
              onSplitChange={handleSplitAmountChange}
              onEdit={startEditSplits}
            />
          ))}

          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item alignContent="flex-end" style={{textAlign: 'right'}}>
              <TextBlock component="span" style={{marginRight: '24px'}}>
                O total das divisões deve ser igual a {MAX_TOTAL_SPLIT}
              </TextBlock>
            </Grid>
            <Grid item alignContent="flex-end" style={{textAlign: 'right'}}>
              <>
                {!isEditSplits && <Button onClick={startEditSplits}>Editar divisão dos valores</Button>}
                {isEditSplits && (
                  <Button onClick={endEditSplits} disabled={hasEmptySplit || !isValidTotalSplit}>
                    Finalizar
                  </Button>
                )}
              </>
            </Grid>
          </Grid>
        </SectionContainer>

        {accountsRemaining.response.length > 0 && (
          <SectionContainer divider>
            <Divider dashed />
            <Typography variant="body2" style={{marginBottom: '24px'}}>
              Você já possui outras contas cadastradas que podem ser usadas também para recebimento neste imóvel. Clique
              no botão <IconPlus style={{fontSize: 18, position: 'relative', top: 4}} /> ao lado da conta para coloca-la
              na lista.
            </Typography>

            {accountsRemaining.response.map((account, index) => (
              <AccountItem
                key={index}
                index={index}
                editable={false}
                onUse={handleAddExistingAccount}
                totalAmount={rentAmount}
                account={account}
              />
              // <Button onClick={() => (account)}>Adicionar</Button>
            ))}
          </SectionContainer>
        )}
      </SectionContainer>

      <ContractContainerBottom>
        <ContractContinueButton buttonProps={{disabled: isContinueDisabled}} onClick={handleClickContinuar} />
      </ContractContainerBottom>

      <BaseModal
        size="sm"
        isOpen={isAccountFormVisible}
        title="Nova conta bancária"
        okLabel="Adicionar"
        okAction={handleSubmit(handleAddNewAccount)}
        onClose={hideAccountForm}>
        <section className="subsection-container">
          <Typography variant="body2" style={{marginBottom: '24px'}}>
            Aqui você deve colocar os dados da conta bancária para onde será feita a transferência dos aluguéis pagos
            pelo inquilino mensalmente.
          </Typography>
          <form className={classes.root}>
            <FormProvider {...formHook}>
              <BankInfoForm bankdata={bankAccount} delayInit={700} />
            </FormProvider>
          </form>
        </section>
      </BaseModal>
    </ContractContainer>
  )
}

export default OwnerBankInfo
