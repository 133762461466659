import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  footer: {
    'padding': '40px 10px 60px',
    'background': '#484848',
    '& *': {
      color: '#FFF',
    },
    '& h2': {
      fontSize: '27px',
      lineHeight: 1.07,
      maxWidth: '23ch',
      marginBottom: '20px',
    },
    '& h3': {
      fontSize: '21px',
      fontWeight: 600,
      // maxWidth: '23ch',
    },
    '& h4': {
      'fontSize': '17px',
      'lineHeight': 1.29,
      // 'maxWidth': '45ch',
      'fontWeight': 600,
      '&:first-child': {
        // marginBottom: 20,
      },
    },
    '& h5': {
      fontSize: '16px',
      lineHeight: 1.15,
      marginBottom: '15px',
      fontWeight: 600,
    },
    [theme.breakpoints.down('lg')]: {
      '& div > div': {minWidth: '310px !important'},
    },
    [theme.breakpoints.down('sm')]: {
      '& div > div': {minWidth: '310px !important'},
    },
    [theme.breakpoints.down('700px')]: {
      '& > div > div': {
        minWidth: 'unset',
      },
      '& > div > div + div': {
        marginTop: '20px',
      },
      'padding': '20px 10px',
      '& h2': {
        fontSize: '22px',
      },
    },
  },
  footerOuterContainer: {
    'maxWidth': '1010px',
    'padding': '0 24px',
    'margin': '0 auto',
    'display': 'flex',
    'flexDirection': 'column',
    'flexWrap': 'wrap',
    '& > div': {
      flex: 1,
      minWidth: '310px',
    },
  },
  bottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))
