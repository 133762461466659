export const isIOS = () => navigator.userAgent.match(/ipad|iphone/i) !== null

export const isChrome = () => navigator.userAgent.match(/chrome/i) !== null
export const isSafari = () => navigator.userAgent.match(/safari/i) !== null

export const isOldIOS = () => isIOS() && getVersion() <= 12

// https://chromium.googlesource.com/chromium/src/+/HEAD/docs/ios/user_agent.md#:~:text=The%20User%20Agent%20(UA)%20in,of%20Version%2F%20.
// https://sentry.io/organizations/moben/issues/2865434793/?project=5477507&query=is%3Aunresolved
export const getVersion = () => {
  const userAgent = navigator.userAgent
  let version = -1
  if (isIOS()) {
    const matches = userAgent.match(/version\/(\d+)/i)
    if (matches && matches[1]) {
      version = parseInt(matches[1])
    }
  }
  return version
}
