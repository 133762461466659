import {makeStyles} from '@material-ui/core/styles'
import {darken} from 'polished'

const buttonStyleMaker = (theme, buttonThemeName) => {
  const {color, bg, iconColor} = theme[buttonThemeName]
  return {
    'background': (stylesProps) => (stylesProps.noBg ? 'none' : bg),
    'boxShadow': (stylesProps) => (stylesProps.noBg ? 'none' : ''),
    'color': (stylesProps) => (stylesProps.noBg ? bg : color),
    'border': (stylesProps) => (stylesProps.noBg && stylesProps.withBorder ? `1px solid ${bg}` : ''),
    '&:hover': {
      background: (stylesProps) => (stylesProps.noBg ? 'none' : darken(0.1, bg)),
      // border: (stylesProps) => (stylesProps.noBg ? 'none' : ''),
      boxShadow: (stylesProps) => (stylesProps.noBg ? 'none' : ''),
      color: (stylesProps) => (stylesProps.noBg ? darken(0.1, bg) : ''),
    },
    '&:disabled': {
      backgroundColor: bg,
      color: color,
      opacity: 0.5,
    },
    '& svg': {
      fill: iconColor,
    },
  }
}

export const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: (stylesProps) => (stylesProps.fw ? stylesProps.fw : 400),
    width: (stylesProps) => (stylesProps.w ? stylesProps.w : ''),
    // height: (stylesProps) => (stylesProps.h ? stylesProps.h : '100%'),
    height: (stylesProps) => (stylesProps.h ? stylesProps.h : 'auto'),
    padding: (stylesProps) => (stylesProps.p ? stylesProps.p : '0.55rem 1.9rem'),
    fontSize: (stylesProps) => (stylesProps.fz ? stylesProps.fz : '0.8125rem'),
    lineHeight: (stylesProps) => (stylesProps.lh ? stylesProps.lh : '0.8125rem'),
    margin: (stylesProps) => (stylesProps.m ? stylesProps.m : 0),
  },
  // sizeSmall: {
  //   fontSize: '0.6875rem',
  //   lineHeight: '0.6875rem',
  // },
  // sizeLarge: {
  //   fontSize: '1rem',
  //   lineHeight: '0.9375rem',
  //   padding: '0.7rem 2.2rem',
  // },
  buttonPurple: buttonStyleMaker(theme, 'purpleButton'),
  buttonOrange: buttonStyleMaker(theme, 'orangeButton'),
  buttonGreen: buttonStyleMaker(theme, 'greenButton'),
  buttonYellow: buttonStyleMaker(theme, 'yellowButton'),
  buttonRed: buttonStyleMaker(theme, 'redButton'),
}))
