import Page from '@root/components/Page'
import WhatsAppButton from '@root/components/WhatsAppButton'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'

function Reparos() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarTitleAction('Reformas e Reparos'))
  }, [])

  return (
    <Page>
      <p>
        Trabalhamos com parceiros especialistas em todo o tipo de reparos. Caso você precise fazer algum reparo antes,
        durante ou depois do contrato de locação basta entrar em contato conosco informando qual serviço você precisa.
      </p>
      <ul style={{fontWeight: 600, margin: '20px 20px'}}>
        <li>Encanador</li>
        <li>Eletricista</li>
        <li>Serviços Gerais</li>
        <li>Montador de Móveis</li>
        <li>Pintor</li>
        <li>Pequenas Reformas</li>
        <li>Ar Condicionado</li>
        <li>Outros</li>
      </ul>

      <WhatsAppButton />
    </Page>
  )
}

export default Reparos
