import DateFnsUtils from '@date-io/date-fns'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import ValidityArrowImg from '@root/assets/new/validity_arrow@2x.png'
import VencimentoArrowImg from '@root/assets/new/vencimento_arrow@2x.png'
import CalendarImg from '@root/assets/new/vencimento_calendar@2x.png'
import FormItem from '@root/components/FormHelper/FormItem'
import {optionsYesNo} from '@root/components/FormHelper/optionFactory'
import {addMonths, getDate, isThisMonth} from 'date-fns'
import useContract from '@root/hooks/useContract'
import actionDispatcher from '@root/lib/actionDispatcher'
import calcContractLengthMonths, {validateContractLength} from '@root/lib/calcContractLengthMonths'
import {handleContractLoad, loadContract} from '@root/lib/contract/contractLoader'
import {getStepByPath} from '@root/lib/contract/stepWalker'
import ContractContainer from '@root/lib/ContractContainer'
import ContractContainerBottom from '@root/lib/ContractContainerBottom'
import ContractContinueButton from '@root/lib/ContractContinueButton'
import {convertDateToBr, dateParser} from '@root/lib/dateParser'
import errorHandler from '@root/lib/errorHandler'
import {daysOfTheMonthInNumber} from '@root/lib/predefinedValuesOptions'
import {validateRequired} from '@root/lib/simpleValidators'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setContractStepAction, setContractValidityAction} from '@root/redux/contract/contract.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import {MIN_CONTRACT_LENGTH} from '@root/utils/constants'

import {Box} from '../Layout/Container/Box/Box'
import {useStyles} from './styles'

export function RentDue() {
  const history = useHistory()
  const classes = useStyles()
  const {handleSubmit, reset, control, errors} = useForm()

  const currentDate = new Date()

  const user = useSelector((s) => s.user.user)
  const property = useSelector((s) => s.property.data)
  const propertySnap = useSelector((s) => s.property.snap)
  const rentdue = useSelector((s) => s.property.data?.contract?.rentdue)
  const validity = useSelector((s) => s.property.data?.contract?.validity)

  const [validityStart, setValidityStart] = useState(new Date())

  const {pathNextStepAfterSave, prepareContractStepUpdate} = useContract(property)

  const [firstPayment, setFirstPayment] = useState(new Date())

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setContractValidityAction,
    setContractStepAction,
  })

  const handleClickContinuar = async (formData) => {
    actions.setGlobalLoaderAction(true)
    try {
      const rentdueData = {
        day: formData.rentdue_day,
        first_payment: firstPayment,
      }

      await propertySnap.ref.update(
        prepareContractStepUpdate({
          'contract.rentdue': rentdueData,
        }),
      )
      actions.setGlobalLoaderAction(false)
      history.push(pathNextStepAfterSave())
    } catch (ex) {
      actions.setGlobalLoaderAction(false)
      alert(errorHandler(ex))
    }
  }

  const getCustomDate = (day) => {
    const date = new Date()
    date.setDate(day)

    return date
  }

  const handleRentdueDayOnChange = (evt) => {
    const day = evt.target.value

    const paymentDate = new Date(validityStart.getTime())
    paymentDate.setDate(day)

    const firstPay = calcFirstPaymentDate(paymentDate, validityStart)
    return setFirstPayment(firstPay)
  }

  const calcFirstPaymentDate = (payDate, validityDate) => {
    const payTime = payDate.getTime()
    const validityTime = validityDate.getTime()
    if (payTime < validityTime) {
      payDate = addMonths(payDate, 1)
    }
    return payDate
  }

  useEffect(() => {
    if (rentdue && rentdue.day && rentdue.first_payment) {
      setFirstPayment(dateParser(rentdue.first_payment))
      reset({rentdue_day: rentdue.day})
    }
  }, [rentdue])

  useEffect(() => {
    if (validity && !rentdue) {
      const validityParsed = dateParser(validity.start)
      reset({rentdue_day: getDate(validityParsed)})
      setFirstPayment(calcFirstPaymentDate(validityParsed, validityParsed))
      setValidityStart(validityParsed)
    }

    if (!validity && !rentdue) {
      reset({rentdue_day: getDate(currentDate)})
      setFirstPayment(addMonths(currentDate, 1))
    }
  }, [validity, rentdue])

  useEffect(() => {
    handleContractLoad(loadContract, user, history)
  }, [user])

  useEffect(() => {
    actions.setContractStepAction(getStepByPath(history.location.pathname))
  }, [])

  return (
    <ContractContainer>
      <form className={`${classes.root} ${classes.validity}`}>
        <Typography variant="body2">
          Selecione a data de vencimento do boleto de aluguel que será enviado para seus inquilinos. Lembre-se de que o
          prazo para a compensação do boleto é de até 3 dias úteis. <br />
        </Typography>

        <Typography variant="body2">
          Para simplificar, recomendamos que você utilize a mesma data de vigência do seu contrato.
        </Typography>

        <Box bgType="gray" m="30px 0">
          <Typography variant="body1">
            <strong>Entenda</strong>
          </Typography>
          <Typography variant="body2">
            Se você escolher uma data diferente da vigência do contrato o primeiro boleto será proporcional aos dias até
            a data escolhida. <br />
            Exemplo: se o seu contrato é de R$ 1000 e começa em 10 de novembro, mas você escolhe dia 5 como a data de
            vencimento, seu primeiro boleto ficaria assim:
          </Typography>

          <div className={classes.rentDueCalendarContainer}>
            <Typography variant="body1" className={classes.validityArrowText}>
              Início da <br />
              vigência
            </Typography>
            <div className={classes.validityArrow}>
              <img src={ValidityArrowImg} />
            </div>
            <img src={CalendarImg} />
            <div className={classes.vencimentoArrow}>
              <img src={VencimentoArrowImg} />
            </div>
            <Typography variant="body1" className={classes.vencimentoArrowText}>
              Vencimento
              <br />
              do boleto
            </Typography>
          </div>

          <Typography variant="body2">
            Aluguel mensal: R$ 1000
            <br />
            Tempo de proporção: 25 dias <br />
            Proporção do primeiro boleto: R$ 1000/30 x 25 = R$ 833,34
            <br />
            <strong>Valor apenas do primeiro boleto: R$ 833,34</strong>
          </Typography>
        </Box>

        <div className={classes.rentdueDateContainer}>
          <div className={classes.rentdueDate}>
            <FormItem
              required
              fitContainer
              control={control}
              errors={errors}
              type="select"
              name="rentdue_day"
              label="Dia do Vencimento"
              rules={{required: 'campo obrigatório'}}
              options={daysOfTheMonthInNumber}
              onChange={handleRentdueDayOnChange}
            />
          </div>
        </div>
      </form>
      <ContractContainerBottom>
        <ContractContinueButton onClick={handleSubmit(handleClickContinuar)} />
      </ContractContainerBottom>
    </ContractContainer>
  )
}
