import Typography from '@material-ui/core/Typography'
import {extractProps} from '@root/components/Layout/Typography/proptypesTypography'
import useTypographyStyle from '@root/components/Layout/Typography/useTypographyStyle'
import PropTypes from 'prop-types'
import React from 'react'

const TitleRaw = (props) => {
  const resultProps = extractProps(props, {size: '1.25rem'})
  const classes = useTypographyStyle(resultProps.style)

  return (
    <Typography {...props} className={classes.merged}>
      {props.children}
    </Typography>
  )
}

TitleRaw.propTypes = {}
TitleRaw.defaultProps = {}

export default TitleRaw
