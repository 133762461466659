import {objectToQueryString} from '@root/application/infra/api/fetcher'
import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class CalculatorRepository extends BaseHttpRepository {
  /**
   * @param {string} readjusment_index
   * @param {number} rent
   * @param {number} month
   * @param {number} year
   * @return {Promise<*>}
   */
  async readjustment(index, rent, month, year) {
    const resp = await this._api.post('calculator/readjustment', {
      readjusment_index: index,
      rent: rent,
      month: month,
      year: year,
    })

    return resp
  }
}

export const calculatorRepoInstance = new CalculatorRepository()
