import {makeStyles} from '@material-ui/core/styles'

const DRAWER_WIDTH = 240

export const useStyles = makeStyles((theme) => ({
  appBar: {
    'height': 89,
    'justifyContent': 'center',
    '& .MuiBadge-root': {
      'marginLeft': 'auto',
      'alignItems': 'center',
      '& > p': {
        marginRight: '5px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 68,
    },
    '&.MuiPaper-elevation4': {
      boxShadow: 'none !important',
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  menuButton: {
    'marginRight': theme.margin[1],
    'color': theme.extraColors.HOME_BLUE,
    'padding': theme.margin[2],
    '&:hover': {
      backgroundColor: theme.extraColors.TURQUOISE,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'minHeight': '89px',
    'maxWidth': '1366px',
    'width': '100%',
    'margin': '0 auto',
    '& h6': {
      color: theme.extraColors.HOME_BLUE,
      fontStyle: 'normal',
      display: 'block',
      marginLeft: 227,
      overflow: 'visible',
      fontSize: '18px',
    },
    '& .home-logo-link': {
      display: 'flex',
      flexDirection: 'column',
      aligItems: 'center', // so de MD para baixo
    },
    [theme.breakpoints.down('sm')]: {
      'minHeight': '68px',
      '& h6': {
        marginLeft: 0,
        fontSize: '15px',
      },
    },
  },
  list: {
    'height': '100%',
    'paddingTop': '36px',
    'overflowY': 'scroll',
    'overflowX': 'hidden',
    '&::-webkit-scrollbar': {width: '4px'},
    '&::-webkit-scrollbar-thumb': {backgroundColor: theme.extraColors.GRAY[900]},
    'backgroundColor': theme.extraColors.GRAY[200],
    '& > .MuiListItem-root': {
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  listItem: {
    'height': '43px',
    'display': 'flex',
    'alignItems': 'center',
    '& > p': {
      fontWeight: 600,
    },
    '& span': {
      display: 'inline-block',
      padding: '0 0 2px 10px',
    },
    '& svg': {width: 18, height: 18},
    '&:first-child': {
      'height': '36px',
      '& > p': {
        transform: 'translateY(-3px)',
      },
    },
  },

  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  content: {
    flexGrow: 1,
  },
  sublist: {
    marginLeft: 10,
  },
  avatarRoot: {
    'position': 'relative',
    'display': 'flex',
    '& > p': {
      whiteSpace: 'nowrap',
    },
  },
  avatarBadge: {
    background: '#FFFFFFDD',
    color: '#000',
    borderRadius: '50%',
  },
  logoElementImg: {
    width: '178px',

    [theme.breakpoints.down('xs')]: {
      width: '146px',
    },
  },
}))

export const useStylesHomeNav = makeStyles((theme) => ({
  nav: {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'width': '100%',
    '& .home-logo-link': {
      // flexGrow: 3, //remover de MD para baixo
      display: 'flex',
      flexDirection: 'column',
    },
    '& ul': {
      'display': 'flex',
      'flexDirection': 'row',
      'justifyContent': 'flex-start',
      'alignItems': 'center',
      'listStyleType': 'none',
      '& li': {
        'margin': '0 16px',
        '& > a': {
          fontSize: '14px',
          fontFamily: 'Ubuntu',
          fontWeight: 300,
          color: '#393939',
        },
      },
      '&:last-child li': {
        margin: 0,
      },
    },
    '& ul.home-menu': {
      'marginLeft': 15,
      'flexGrow': 6,
      '& li': {},
    },
    '& ul.home-register': {
      // flexGrow: 3, // remover de MD para baixo
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      '& ul.home-menu': {
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& ul.home-register li': {
        margin: '0 4px',
      },
    },
  },
  cadastreseBtn: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    textTransform: 'none',
  },
  login: {
    fontFamily: 'Ubuntu',
    fontSize: '15px !important',
    textDecoration: 'underline',
    fontWeight: '500 !important',
    color: `${theme.palette.primary.main} !important`,
  },
}))
