/** @module addressDisplay */
export const ADDRESS_VERBOSITY_MINIMAL = 1
export const ADDRESS_VERBOSITY_COMPLETE = 2
export const ADDRESS_VERBOSITY_FULL = 3
const addressDisplay = (address, verbosity = ADDRESS_VERBOSITY_MINIMAL, prefferLocalFormat = false) => {
  if (!address) {
    return ''
  }

  if (address.formatted && !prefferLocalFormat) {
    return address.formatted
  }

  const {street, street_number, city, state, neighborhood, zip, complement} = address
  switch (verbosity) {
    case ADDRESS_VERBOSITY_MINIMAL:
      return `${street}, ${street_number}${complement ? ' - ' + complement : ''}`
    case ADDRESS_VERBOSITY_COMPLETE:
      return `${street}, ${street_number} - ${city}/${state}`
    case ADDRESS_VERBOSITY_FULL:
      return `${street}, ${street_number} - ${neighborhood} - ${city}/${state} - ${zip}${
        complement ? ' - ' + complement : ''
      }`
  }

  return ''
}

export default addressDisplay
