import {ANSWER_NO, ANSWER_YES} from '@root/components/App/Contract/DefaultRadioGroup'
import {optionFactory, optionFactoryFromMapper, optionFactorySingle} from '@root/components/FormHelper/optionFactory'
import ProposalHelper from '@root/lib/contract/ProposalHelper'
import propertyTypesMapper from 'status-mapper/mapper/property-type-mapper'
import registrationTypeMapper from 'status-mapper/mapper/registration-type-mapper'

export const maritalStatusOptions = [
  optionFactory('Casado', 'Casado'),
  optionFactory('Solteiro', 'Solteiro'),
  optionFactory('Divorciado', 'Divorciado'),
  optionFactory('Viúvo', 'Viúvo'),
]

export const occupationOptions = [
  optionFactory('Autônomo', 'Autônomo'),
  optionFactory('CLT', 'CLT'),
  optionFactory('Pensionista', 'Pensionista'),
  optionFactory('Estatutário', 'Estatutário'),
  optionFactory('Aposentado', 'Aposentado'),
  optionFactory('Empresário', 'Empresário'),
  optionFactory('Outros', 'Outros'),
]

export const rolesOptions = [
  optionFactory('Presidente', 'Presidente'),
  optionFactory('Sócio', 'Sócio'),
  optionFactory('Proprietário', 'Proprietário'),
  optionFactory('Diretor', 'Diretor'),
  optionFactory('Gerente', 'Gerente'),
  optionFactory('Analista', 'Analista'),
  optionFactory('Auxiliar', 'Auxiliar'),
  optionFactory('Estagiário', 'Estagiário'),
  optionFactory('Gestor', 'Gestor'),
  optionFactory('Supervisor', 'Supervisor'),
  optionFactory('Coordenador', 'Coordenador'),
  optionFactory('Consultor', 'Consultor'),
  optionFactory('Executivo', 'Executivo'),
  optionFactory('Outro', 'Outro'),
]

export const paymentMethodsOptions = [
  optionFactory('Boleto Moben', 'boleto'),
  optionFactory('PIX Moben', 'pix'),
  optionFactory('Em dinheiro', 'money'),
  optionFactory('Via transferência bancária', 'transfer'),
  // optionFactory('Outra forma', 'other'),
]

export const userRegisterOptions = [
  optionFactoryFromMapper(registrationTypeMapper, 'PERSONAL'),
  optionFactoryFromMapper(registrationTypeMapper, 'COMPANY'),
]

export const yesOrNotOptions = [optionFactory('Sim', ANSWER_YES), optionFactory('Não', ANSWER_NO)]

export const daysOfTheMonthInNumber = new Array(31).fill('').map((_, idx) => optionFactory(`${idx + 1}`, idx + 1))

export const featuresAccessAndLocalization = [
  optionFactory('Perto de vias de acesso', 'Close to main roads/avenues'),
  optionFactory('Perto de Shopping Center', 'Close to shopping centers'),
  optionFactory('Perto de transporte público', 'Close to public transportation'),
  optionFactory('Perto de Escolas', 'Close to schools'),
  optionFactory('Perto de hospitais', 'Close to hospitals'),
  optionFactory('Serviços públicos essenciais', 'Utilities'),
  optionFactory('Vista para o mar', 'Ocean View'),
  optionFactory('Vista para a montanha', 'Mountain View'),
  optionFactory('Vista para lago', 'Lake View'),
  optionFactory('Cascalho', 'Gravel'),
  optionFactory('Rua de terra', 'Land'),
]

export const featuresDetailsAndServices = [
  optionFactory('Lareira', 'Fireplace'),
  optionFactory('Quintal', 'Backyard'),
  optionFactory('Sacada', 'Balcony'),
  optionFactory('Cozinha', 'Kitchen'),
  optionFactory('Lavanderia', 'Laundry'),
  optionFactory('Elevador', 'Elevator'),
  optionFactory('Condomínio fechado', 'Fenced Yard'),
  optionFactory('Jardim', 'Garden Area'),
  optionFactory('Depósito', 'Warehouse'),
  optionFactory('Cabeamento estruturado', 'Fully Wired'),
  optionFactory('Vista exterior', 'Exterior View'),
  optionFactory('Escritório', 'Home Office'),
  optionFactory('Mais de um andar', 'Number of stories'),
  optionFactory('Recepção', 'Reception room'),
  optionFactory('Varanda gourmet', 'Veranda'),
  optionFactory('Serviço pay per use', 'Pay-per-use Services'),
  optionFactory('Interfone', 'Intercom'),
  optionFactory('Massagem', 'Massage Room'),
]

export const featuresExtraEquipment = [
  optionFactory('Ar-condicionado', 'Cooling'),
  optionFactory('Aquecimento', 'Heating'),
  optionFactory('TV a cabo', 'Cable Television'),
  optionFactory('Conexão à internet', 'Internet Connection'),
  optionFactory('Gerador elétrico', 'Generator'),
]

export const featuresLeisure = [
  optionFactory('Academia', 'Gym'),
  optionFactory('Espaço gourmet', 'Gourmet Area'),
  optionFactory('Quadra de tenis', 'Tennis court'),
  optionFactory('Quadra poliesportiva', 'Sports Court'),
  optionFactory('Playground', 'Playground'),
  optionFactory('Piscina', 'Pool'),
  optionFactory('Churrasqueira', 'BBQ'),
  optionFactory('Sauna', 'Sauna'),
  optionFactory('Spa', 'Spa'),
  optionFactory('Pista de cooper', 'Jogging track'),
  optionFactory('Quadra de squash', 'Squash'),
  optionFactory('Salão de festa', 'Party Room'),
  optionFactory('Salão de jogos', 'Game room'),
  optionFactory('Gramado', 'Lawn'),
  optionFactory('Cinema', 'Media Room'),
  optionFactory('Espaço verde / Parque', 'Green space / Park'),
]

export const featuresSecurity = [
  optionFactory('Segurança 24h', 'Security Guard on Duty'),
  optionFactory('Vigia', 'Controlled Access'),
  optionFactory('Circuito de segurança', 'TV Security'),
  optionFactory('Sistema de alarme', 'Alarm System'),
]

export const propertyTypes = propertyTypesMapper.statusValues.map((type) =>
  optionFactory(propertyTypesMapper.translate(type), type),
)

export const addressDisplay = [
  optionFactory('Endereço completo', 'full_address'),
  optionFactory('Apenas nome da rua', 'street_only'),
  optionFactory('Apenas o bairro', 'neighborhood_only'),
]
// fonte: https://www.jornalcontabil.com.br/o-que-e-e-quais-as-principais-opcoes-de-natureza-juridica/
export const companyOriginOptions = [
  optionFactorySingle('201-1 Empresa Pública	Administrador'),
  optionFactorySingle('203-8 Sociedade de Economia Mista	Diretor ou Presidente	10 ou 16'),
  optionFactorySingle('204-6 Sociedade Anônima Aberta	Administrador'),
  optionFactorySingle('205-4 Sociedade Anônima Fechada	Administrador'),
  optionFactorySingle('206-2 Sociedade Empresária Limitada	Administrador ou Sócio-Administrador	05 ou 49'),
  optionFactorySingle('207-0 Sociedade Empresária em Nome Coletivo	Sócio-Administrador	49'),
  optionFactorySingle('208-9 Sociedade Empresária em Comandita Simples	Sócio Comanditado	24'),
  optionFactorySingle('209-7 Sociedade Empresária em Comandita porAções	Diretor ou Presidente	10 ou 16'),
  optionFactorySingle('212-7 Sociedade em Conta de Participação	Procurador ou Sócio Ostensivo	17 ou 31'),
  optionFactorySingle('213-5 Empresário (Individual)	Empresário	50'),
  optionFactorySingle('214-3 Cooperativa	Diretor ou Presidente	10 ou 16'),
  optionFactorySingle('215-1 Consórcio de Sociedades	Administrador	05'),
  optionFactorySingle('216-0 Grupo de Sociedades	Administrador	05'),
  optionFactorySingle('217-8 Estabelecimento'),
  optionFactorySingle('219-4 Estabelecimento'),
  optionFactorySingle('221-6 Empresa Domiciliada no Exterior	Procurador	17'),
  optionFactorySingle('222-4 Clube/Fundo de Investimento	Responsável	43'),
  optionFactorySingle('223-2 Sociedade Simples Pura'),
  optionFactorySingle('224-0 Sociedade Simples Limitada'),
  optionFactorySingle('225-9 Sociedade Simples em Nome Coletivo'),
  optionFactorySingle('226-7 Sociedade Simples em Comandita Simples'),
  optionFactorySingle('227-5 Empresa Binacional'),
  optionFactorySingle('228-3 Consórcio de Empregadores'),
  optionFactorySingle('229-1 Consórcio Simples'),
  optionFactorySingle('230-5 Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)	Administrador'),
  optionFactorySingle('231-3 Empresa Individual de Responsabilidade Limitada (de Natureza Simples)	Administrador'),

  optionFactorySingle('101-5 Órgão Público do Poder Executivo Federal'),
  optionFactorySingle('102-3 Órgão Público do Poder Executivo Estadualou do Distrito Federal'),
  optionFactorySingle('103-1 Órgão Público do Poder Executivo Municipal'),
  optionFactorySingle('104-0 Órgão Público do Poder Legislativo Federal'),
  optionFactorySingle('105-8 Órgão Público do Poder Legislativo Estadualou do Distrito Federal'),
  optionFactorySingle('106-6 Órgão Público do Poder Legislativo Municipal'),
  optionFactorySingle('107-4 Órgão Público do Poder Judiciário Federal'),
  optionFactorySingle('108-2 Órgão Público do Poder Judiciário Estadual'),
  optionFactorySingle('110-4 Autarquia Federal'),
  optionFactorySingle('111-2 Autarquia Estadual ou do Distrito Federal'),
  optionFactorySingle('112-0 Autarquia Municipal'),
  optionFactorySingle('113-9 Fundação Federal'),
  optionFactorySingle('114-7 Fundação Estadual ou do Distrito Federal'),
  optionFactorySingle('115-5 Fundação Municipal'),
  optionFactorySingle('116-3 Órgão Público Autônomo Federal'),
  optionFactorySingle('117-1 Órgão Público Autônomo Estadual ou doDistrito Federal'),
  optionFactorySingle('118-0 Órgão Público Autônomo Municipal'),
  optionFactorySingle('119-8 Comissão Polinacional'),
  optionFactorySingle('120-1 Fundo Público'),
  optionFactorySingle('121-0 Associação Pública'),

  optionFactorySingle('303-4 Serviço Notarial e Registral (Cartório)'),
  optionFactorySingle('306-9 Fundação Privada'),
  optionFactorySingle('307-7 Serviço Social Autônomo'),
  optionFactorySingle('308-5 Condomínio Edilício'),
  optionFactorySingle('310-7 Comissão de Conciliação Prévia'),
  optionFactorySingle('311-5 Entidade de Mediação e Arbitragem'),
  optionFactorySingle('312-3 Partido Político'),
  optionFactorySingle('313-1 Entidade Sindical'),
  optionFactorySingle('320-4 Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras'),
  optionFactorySingle('321-2 Fundação ou Associação domiciliada no exterior'),
  optionFactorySingle('322-0 Organização Religiosa'),
  optionFactorySingle('323-9 Comunidade Indígena Responsável'),
  optionFactorySingle('324-7 Fundo Privado'),
  optionFactorySingle('399-9 Associação Privada'),

  optionFactorySingle('401-4 Empresa Individual Imobiliária'),
  optionFactorySingle('408-1 Contribuinte Individual'),
  optionFactorySingle('409-0 Candidato a Cargo Político Eletivo'),

  optionFactorySingle('501-0 Organização Internacional'),
  optionFactorySingle('502-9 Representação Diplomática Estrangeira'),
  optionFactorySingle('503-7 Outras Instituições Extraterritoriais'),
]

export const feesCondominiumOptions = [
  {
    value: 'include_with_rent',
    label: 'Condomínio pago pelo proprietário e valor repassado para o inquilino na forma de pagamento',
  },
  {
    value: 'tenant',
    label: 'Condomínio pago diretamente pelo inquilino',
  },
  {
    value: 'owner',
    label: 'Condomínio pago pelo proprietário e valor não repassado para o inquilino na forma de pagamento',
  },
]

export const feesIptuOptions = [
  {
    value: 'include_with_rent',
    label: 'IPTU pago pelo proprietário e valor repassado para o inquilino na forma de pagamento*',
  },
  {
    value: 'tenant',
    label: 'IPTU pago diretamente pelo inquilino',
  },
  {
    value: 'owner',
    label: 'IPTU pago pelo proprietário e valor não repassado para o inquilino na forma de pagamento*',
  },
]

export const feesFireInsuranceOptions = [
  {
    value: 'owner',
    label: 'Eu contrato e pago, mas o valor não é repassado para o inquilino',
  },
  {
    value: 'tenant',
    label: 'Inquilino contrata e paga direto para a seguradora',
  },
  {
    value: 'owner_contract_tenant_pay_with_rent',
    label: 'Eu contrato e inquilino paga junto com o boleto de alguel',
  },
  /*{
    value: 'owner_contract_tenant_pay_directly',
    label: 'Eu contrato e inquilino paga direto para a seguradora',
  },*/
]

export const coverageFireInsuranceOptions = [
  {
    value: '100',
    label: '100',
  },
  {
    value: '200',
    label: '200',
  },
]

export const creditAnalysisModesOptions = [
  optionFactory(
    'Com analise de crédito',
    ProposalHelper.MODE_DEFAULT,
    'Opção padrão da plataforma onde pedimos a renda do interessado para gerar análise de crédito.',
  ),
  optionFactory(
    'Sem analise de crédito',
    ProposalHelper.MODE_EXPRESS,
    'Cadastro simplificado sem a necessidade de declarar renda.',
  ),
]

export const adDescriptionPlaceholder =
  'Apartamento para alugar 94m², reformado, completo e mobiliado. Com piso de madeira, sala ampla com janelão e pequena sacada, cozinha integrada, suíte com closet e área homeoffice. Sala de TV tem sofá-cama e poderia virar um 3o quarto (modificações e ajustes podem ser considerados). Sol na varanda no amanhecer. Localizado em condomínio charmoso de predinhos baixos, silencioso, arborizado e portaria 24h. Vizinhos simpáticos e astral muito bom. Frequentado por pássaros e até macaquinhos - nem parece que vc está em São Paulo. Situado a 1 quarteirão da Berrini e ciclovia. Próx. a Rede Globo, Ponte Estaiada, Shoppings Morumbi, Market Place e Nações Unidas; 1Km do Trem; 1,8Km do Metrô Campo Belo; 4,5Km do Aeroporto de Congonhas. Próxima a Universidade Anhembi Morumbi e Escolas: Pueri Domus, Rudolf Steiner, Porto Seguro.Obs: Anúncio do Proprietário. Não estou negociando com imobiliárias ou corretores.'

export const montsList = [
  optionFactory('Janeiro', '1'),
  optionFactory('Fevereiro', '2'),
  optionFactory('Março', '3'),
  optionFactory('Abril', '4'),
  optionFactory('Mail', '5'),
  optionFactory('Junho', '6'),
  optionFactory('Julho', '7'),
  optionFactory('Agosto', '8'),
  optionFactory('Setembro', '9'),
  optionFactory('Outubro', '10'),
  optionFactory('Novembro', '11'),
  optionFactory('Dezembro', '12'),
]
