import {AdminTypes} from '@root/redux/admin/admin.types'

export const adminSetPropertyAction = (property) => {
  return {
    type: AdminTypes.SET_PROPERTY,
    payload: {property},
  }
}

export const adminSetOwnerAction = (owner, realOwner) => {
  return {
    type: AdminTypes.SET_OWNER,
    payload: {owner, realOwner},
  }
}
