// import React from 'react'
import IconAgreement from '@root/components/icons/IconAgreement'
import IconMoney from '@root/components/icons/IconMoney'
import IconPaper from '@root/components/icons/IconPaper'
import IconPayment from '@root/components/icons/IconPayment'
import routes from '@root/utils/routes'

const menuSections = [
  {
    icon: IconPaper,
    id: 'anuncio',
    route: routes.REGISTER_SIGNUP,
    header: {
      title: 'Criação de anúncio e revisão de especialistas',
      text: 'Quero anunciar nos principais portais gratuitamente.',
    },
    section: {
      image: '/assets/new/Tela_3.png',
      button: {
        label: 'anunciar',
      },
      title: 'Moben é anúncio',
      features: [
        'Grátis',
        'Seu anúncio nos principais portais do Brasil e controle em um único lugar',
        'Contratação de fotógrafo profissional',
        'Gestão de leads mais eficiente',
      ],
    },
  },
  {
    icon: IconAgreement,
    id: 'contrato',
    route: routes.REGISTER_SIGNUP,
    header: {
      title: 'Contrato de aluguel personalizado',
      text: 'Tenho um imóvel de aluguel e preciso de um contrato.',
    },
    section: {
      image: '/assets/new/Tela_1.png',
      button: {
        label: 'criar contrato',
      },
      title: 'Moben é segurança',
      features: [
        'Customização prática do contrato, direto na plataforma',
        'Aprovação e assinatura digital, sem necessidade de cartórios',
        'Guia de contrato para você não se esquecer de nenhum item',
      ],
    },
  },
  {
    icon: IconPayment,
    id: 'pagamentos',
    route: routes.REGISTER_SIGNUP,
    header: {
      title: 'Gestão de Pagamentos e Cobranças Moben',
      text: 'Tenho contrato em andamento e quero gerir meus pagamentos.',
    },
    section: {
      image: '/assets/new/Tela_4.png',
      button: {
        label: 'Iniciar',
      },
      title: 'Moben é controle',
      features: [
        'Gerencie os pagamentos através da Moben',
        'Mensagens automáticas via WhatsApp e e-mail',
        'Aplicação de juros e multas em caso de inadimplência',
      ],
    },
  },
  {
    icon: IconMoney,
    id: 'conhecimento',
    route: routes.REGISTER_SIGNUP,
    header: {
      title: 'Análise de Crédito do inquilino',
      text: 'Quero fazer análise de crédito do meu futuro inquilino.',
    },
    section: {
      image: '/assets/new/Tela_2.png',
      button: {
        label: 'Começar já',
      },
      title: 'Moben é conhecimento',
      features: [
        'Analise as informações de crédito do inquilino disponível nos principais bureaus do país',
        'Melhores práticas de análise do mercado imobiliário',
        'Análise online entregue em segundos',
      ],
    },
  },
]

export const menuSections2 = [
  {
    icon: IconAgreement,
    id: 'plataforma',
    header: {
      title: 'Contrato de aluguel personalizado',
      text: 'Tenho um imóvel de aluguel e preciso de um contrato.',
    },
    section: {
      image: '/assets/new/Tela_1.png',
      button: {
        label: 'Eu Quero',
      },
      title: 'Plataforma',
      features: [
        'Customização prática do contrato, direto na plataforma',
        'Aprovação e assinatura digital, sem necessidade de cartórios',
        'Guia de contrato para você não se esquecer de nenhum item',
      ],
    },
  },
  {
    icon: IconMoney,
    id: 'depoimentos',
    route: routes.REGISTER_SIGNUP,
    header: {
      title: 'Análise de Crédito do inquilino',
      text: 'Quero fazer análise de crédito do meu futuro inquilino.',
    },
    section: {
      image: '/assets/new/Tela_2.png',
      button: {
        label: 'Começar já',
      },
      title: 'Depoimentos',
      features: [
        'Analise as informações de crédito do inquilino disponível nos principais bureaus do país',
        'Melhores práticas de análise do mercado imobiliário',
        'Análise online entregue em segundos',
      ],
    },
  },
  {
    icon: IconPaper,
    id: 'vantagens',
    route: routes.REGISTER_SIGNUP,
    header: {
      title: 'Criação de anúncio e revisão de especialistas',
      text: 'Quero anunciar nos principais portais gratuitamente.',
    },
    section: {
      image: '/assets/new/Tela_3.png',
      button: {
        label: 'iniciar',
      },
      title: 'Vantagens',
      features: [
        'Controle o seu anúncio veiculado nos principais portais',
        'Receba notificações de interessados no imóvel em um unico lugar',
        'Conte com fotógrafos especializados',
      ],
    },
  },
]

export default menuSections
