import {Popper} from '@material-ui/core'
import React, {useState} from 'react'

import {useStyles} from './styles'

const HelpTextComponent = ({title = undefined, helpText = undefined, HelpComponent = undefined}) => {
  const classes = useStyles()
  const [isOpenPopper, setIsOpenPopper] = useState(null)

  const handleClick = (event) => {
    setIsOpenPopper(isOpenPopper ? null : event.currentTarget)
  }

  const open = Boolean(isOpenPopper)
  const id = open ? 'popper-helper' : undefined

  const isComponent = HelpComponent !== undefined

  return (
    <>
      <button aria-describedby={id} type={'button'} className={classes.buttonHelper} onClick={handleClick}>
        ?
      </button>
      <Popper id={id} open={open} anchorEl={isOpenPopper}>
        <div className={classes.floatingBg}>
          {isComponent ? (
            HelpComponent
          ) : (
            <>
              <p style={{marginBottom: '10px', fontSize: 12, color: '#061BCD'}}>
                <strong>{!!title && title}</strong>
              </p>
              <div style={{fontSize: 12}}>
                {helpText.map((line) => {
                  return (
                    <>
                      {line} <br />
                    </>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </Popper>
    </>
  )
}

export default HelpTextComponent
