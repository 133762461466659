import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    // 'marginTop': '135px !important',
    '& .bold': {fontWeight: 600},
    '& header': {
      padding: theme.margin[2],
      backgroundColor: theme.extraColors.GRAY[100],
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  yearContainer: {
    'margin': `${theme.margin[2]} 0`,
    'display': 'flex',
    'justifyContent': 'flex-start',
    '& > button': {
      fontSize: 20,
      padding: 0,
    },
  },
  year: {
    color: theme.extraColors.GRAY[500],
  },
  isActive: {
    color: theme.palette.secondary.main,
  },
  recebimentos: {
    '& > div:not(:first-child)': {
      marginTop: theme.margin[2],
    },
  },
  recebimentoContainer: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& p': {
      margin: '0 !important',
    },
    '& > div:first-child': {
      'display': 'flex',
      'alignItems': 'center',
      '& > h6': {
        marginRight: '12px',
        fontSize: 20,
        fontWeight: 600,
        width: '45px',
      },
    },
    '& > div:last-child': {
      'display': 'flex',
      '& > button + button': {
        marginLeft: '10px',
      },
    },
  },
  paymentDetail: {
    'marginTop': '-10px !important',
    'display': 'flex',
    'flexDirection': 'column',
    '& > p': {
      marginBottom: 10,
    },
    '& > div': {
      marginTop: 10,
    },
  },
  amountContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  amount: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  valorBoletoContainer: {
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    '& > h6': {
      fontSize: '15px',
      fontWeight: 600,
    },
    '& h5': {
      fontSize: '24px',
    },
  },
  motivoContainer: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'marginTop': '20px !important',
    '& > p:last-child': {
      textAlign: 'right',
      transform: 'translateY(-15px)',
    },
  },
  gerarBoletoBtnContainer: {
    margin: '15px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  fieldsContainer: {
    'width': '100%',
    'display': 'flex',
    'flexWrap': 'wrap',
    'marginBottom': '20px',
    'justifyContent': 'space-between',
    '& > div': {
      width: '45%',
      minWidth: '220px',
      [theme.breakpoints.down(460)]: {
        width: '100%',
      },
    },
  },
  valorContainer: {
    marginTop: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tipoDeRecebimento: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))
