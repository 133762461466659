import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

let optimize = {}
const useOptimize = (experimentId) => {
  const [variantId, setVariantId] = useState()
  const [isOptimizeLoaded, setOptimizeLoaded] = useState(false)
  const optimizeState = useSelector((s) => s.application.optimize)

  const getVariantByExperiment = (experimentId) => {
    return optimize.get(experimentId)
  }

  const getVariantByAlias = (alias) => {
    return optimizeState[alias]
  }

  const isVariantAvailable = (alias, expectedVariant) => {
    return optimizeState[alias] === String(expectedVariant)
  }

  useEffect(() => {
    if (isOptimizeLoaded || !window.google_optimize) {
      return
    }

    setOptimizeLoaded(true)
    optimize = window.google_optimize
    const id = getVariantByExperiment(experimentId)
    if (id) {
      setVariantId(id)
    }
  }, [window.google_optimize, isOptimizeLoaded])

  return {optimize, variantId, isOptimizeLoaded, getVariantByAlias, getVariantByExperiment, isVariantAvailable}
}

export default useOptimize
