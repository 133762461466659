/** @module fullyEmpty */

/**
 * Verifica se o valor e vazio de varias maneiras possiveis, é considerado vazio:
 * - undefined
 * - null
 * - string vazia
 * @param {*} val
 * @return {boolean}
 */
export const isFullyEmpty = (val) => val === undefined || val === null || val === ''
