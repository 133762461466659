/** @module surveyModel */
import {firestore, forceArrayUnion} from '@root/firebase/firebase.utils'

const COLLECTION_NAME = 'survey'

const createOrUpdateSurvey = async (payload, propertyUid) => {
  if (!payload || !propertyUid) return

  const surveyCollectionRef = firestore.collection(COLLECTION_NAME)
  const surveysSnap = await surveyCollectionRef
    .where('is_active', '==', true)
    .where('property_uid', '==', propertyUid)
    .get()

  const dataProp = payload.surveyType === 'entering' ? 'entering' : 'leaving'
  let surveyToBeSaved = {
    is_active: true,
    property_uid: propertyUid,
    created_at: new Date(),
    entering: {
      created_at: null,
      scheduled_for: null,
      images: [],
    },
    leaving: {
      created_at: null,
      scheduled_for: null,
      images: [],
    },
  }

  surveyToBeSaved[dataProp] = {
    created_at: new Date(),
    scheduled_for: null,
    images: payload.images,
  }

  if (surveysSnap.empty) {
    await surveyCollectionRef.doc().set(surveyToBeSaved)
  } else {
    const currentSnap = surveysSnap.docs[0]
    await currentSnap.ref.update({[`${dataProp}.images`]: payload.images})
  }

  return surveyToBeSaved
}

const getSurvey = async (propertyUid) => {
  const surveySnap = await firestore
    .collection(COLLECTION_NAME)
    .where('is_active', '==', true)
    .where('property_uid', '==', propertyUid)
    .get()

  if (surveySnap.empty) return

  return surveySnap.docs[0].data()
}

const surveyModel = {
  createOrUpdateSurvey,
  getSurvey,
}

export default surveyModel
