const MODE = import.meta.env.MODE
const APP_VERSION = import.meta.env.APP_VERSION
const CYPRESS_ENABLED = import.meta.env.REACT_APP_CYPRESS_ENABLED

const API_KEY = import.meta.env.REACT_APP_API_KEY
const AUTH_DOMAIN = import.meta.env.REACT_APP_AUTH_DOMAIN
const DATABASE_URL = import.meta.env.REACT_APP_DATABASE_URL
const PROJECT_ID = import.meta.env.REACT_APP_PROJECT_ID
const STORAGE_BUCKET = import.meta.env.REACT_APP_STORAGE_BUCKET
const MESSAGING_SENDER_ID = import.meta.env.REACT_APP_MESSAGING_SENDER_ID
const MEASUREMENT_ID = import.meta.env.REACT_APP_MEASUREMENT_ID
const APP_ID = import.meta.env.REACT_APP_APP_ID

const FIRESTORE_EMULATOR_HOST = import.meta.env.REACT_APP_FIRESTORE_EMULATOR_HOST
const FIREBASE_DATABASE_EMULATOR_HOST = import.meta.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST
const FIREBASE_STORAGE_EMULATOR_HOST = import.meta.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST
const FIREBASE_AUTH_EMULATOR_HOST = import.meta.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST

export default {
  MODE,
  CYPRESS_ENABLED,
  APP_VERSION,

  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  MEASUREMENT_ID,
  APP_ID,

  FIRESTORE_EMULATOR_HOST,
  FIREBASE_DATABASE_EMULATOR_HOST,
  FIREBASE_STORAGE_EMULATOR_HOST,
  FIREBASE_AUTH_EMULATOR_HOST,
}
