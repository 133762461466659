import Text from '@root/components/Layout/Typography/Text'
import PropTypes from 'prop-types'
import React from 'react'

const NoContentAvailable = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Text size={16}>Nenhum resultado disponível no momento.</Text>
    </div>
  )
}

NoContentAvailable.propTypes = {}
NoContentAvailable.defaultProps = {}

export default NoContentAvailable
