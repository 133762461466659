import Typography from '@material-ui/core/Typography'
import {useAdLeads} from '@root/application/service/repository/ad/AdRepository'
import AdButton from '@root/components/AdButton/AdButton'
import DataDisplay from '@root/components/App/Admin/DataDisplay'
import {Button} from '@root/components/Button/Button'
import Title from '@root/components/Layout/Typography/Title'
import {useStyles} from '@root/components/YourProperty/styles'
import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import routes from '@root/utils/routes'

const SectionPropertyAd = () => {
  const classes = useStyles()
  const history = useHistory()

  const property = useSelector((s) => s.property.data)
  const adLinksDb = property?.ad?.links ?? []
  const isAdDisableByAdmin = property.ad_disabled_by_admin
  const isAdFilled = property.ad_form_filled
  const isAdEnabled = property.ad_enabled
  const isAdFullyEnabled = isAdEnabled && isAdFilled
  const isAdActive = isAdFullyEnabled && !isAdDisableByAdmin

  const adsLeads = useAdLeads(property?.uid)

  const subText = useMemo(() => {
    const config = {
      text: '',
      label: '',
      display: false,
    }

    if (!isAdFilled) {
      config.text = 'Nenhum anúncio encontrado'
      config.label = 'Criar anúncio'
    } else if (!isAdEnabled || isAdDisableByAdmin) {
      config.text = 'Anúncio pausado'
      config.label = ''
    } else if (isAdActive) {
      config.text = 'Anunciado'
      config.label = 'Editar anúncio'
    }

    config.display = !!config.text
    return config
  }, [isAdDisableByAdmin, isAdFilled, isAdFullyEnabled, isAdActive])

  const [adLinks, setAdLinks] = useState([
    {portal: 'olx', href: null},
    {portal: 'zap_imoveis', href: null},
    {portal: 'viva_real', href: null},
  ])

  const handleAdPath = () => {
    history.push(routes.DASHBOARD_AD_CREATE)
  }
  const goToAdLeadsList = () => {
    history.push(routes.DASHBOARD_AD_LEADS)
  }

  useEffect(() => {
    if (adLinksDb.length > 0) {
      const links = [...adLinks, ...adLinksDb]
      const portals = links.map((link) => link.portal)
      const filteredLinks = [
        ...links.filter(({portal}, idx) => !portals.includes(portal, idx + 1)).sort((l1, _) => (l1.href ? -1 : 1)),
      ]
      setAdLinks([...filteredLinks])
    }
  }, [property])

  return (
    <div className={classes.sectionItem}>
      <Title bold>Anúncios</Title>

      {subText.display && (
        <>
          <Typography variant="subtitle1" style={{marginTop: 10}}>
            {subText.text}
          </Typography>
          {subText.label && (
            <div className={classes.buttonsContainer}>
              <Button onClick={handleAdPath}>{subText.label}</Button>
            </div>
          )}
        </>
      )}

      {isAdEnabled && adsLeads.hasListingEntries && (
        <>
          <br />
          <DataDisplay asTable label="Interessados recebidos" value={adsLeads.listingSize} />
          <div className={classes.buttonsContainer}>
            <Button onClick={goToAdLeadsList}>Ver interessados</Button>
          </div>
          <br />
        </>
      )}

      {isAdActive && (
        <>
          <Typography variant="subtitle1" style={{marginTop: 10}}>
            Clique no logo do portal para ver o anúncio
          </Typography>
          <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
            <AdButton portal="moben" href={`https://next.moben.com.br/anuncios/${property?.uid}`} />
            {adLinks.map(({portal, href}) => (
              <AdButton key={portal} portal={portal} href={href} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default SectionPropertyAd
