export const tagsInfo = {
  renda_valor_pessoa: {
    title: 'Renda x Valor do Aluguel',
    text: {
      1: 'Valor do aluguel ocupa menos de 30% do valor da renda declarada pelo interessado. Está em linha com o praticado no mercado e sua renda seria considerada suficiente para prosseguir com a proposta de locação.',
      3: 'Valor do aluguel ocupa entre 30% e 50% da renda declarada pelo interessado. O mercado pratica que o aluguel ocupe até 30% da renda do interessado. Nestes casos, costuma-se solicitar que sejam incluídas mais pessoas como Inquilinos solidários para compor a renda antes de seguir com a proposta de locação.',
      4: 'Valor do aluguel ocupa mais de 50% da renda declarada pelo interessado. O mercado pratica que o aluguel ocupe até 30% da renda do interessado. Nestes casos, costuma-se solicitar que sejam incluídas mais pessoas como Inquilinos solidários para compor a renda antes de seguir com a proposta de locação.',
    },
  },
  renda_valor_proposta: {
    title: 'Renda x Valor do Aluguel',
    text: {
      1: 'Valor do aluguel ocupa menos de 30% da soma das rendas declaradas pelos interessados. Está em linha com o praticado no mercado e poderia proseguir com a proposta de locação.',
      3: 'Valor do aluguel ocupa entre 30% e 50% da soma das rendas declaradas pelos interessados. O mercado pratica que o aluguel ocupe até 30% da soma das rendas dos interessados. Nestes casos, costuma-se solicitar que sejam incluídas mais pessoas como Inquilinos solidários para compor a renda antes de seguir com a proposta de locação.',
      4: 'Valor do aluguel ocupa acima de 50% da soma das rendas declaradas pelos interessados. O mercado pratica que o aluguel ocupe até 30% da soma das rendas dos interessados. Nestes casos, costuma-se solicitar que sejam incluídas mais pessoas como Inquilinos solidários para compor a renda antes de seguir com a proposta de locação.',
    },
  },
  status_do_cpf: {
    title: 'Status do CPF',
    text: {
      1: 'CPF consta como regular na Receita Federal',
      4: 'CPF consta como irregular na Receita Federal',
    },
  },
  ocorrencia_de_cobrancas: {
    title: 'Ocorrência de cobranças',
    text: {
      1: 'Não foram encontradas ocorrências de cobranças relacionadas ao CPF.',
      3: 'Foram encontradas menos de 3 ocorrências de cobranças relacionadas ao CPF. Importante indagar ao interessado se elas ainda existem ou se ja foram resolvidas.',
      4: 'Foram encontradas mais de 3 ocorrências de cobranças relacionadas ao CPF. Importante indagar ao interessado se elas ainda existem ou se ja foram resolvidas.',
    },
  },
  ocorrencia_de_processos: {
    title: 'Ocorrência de processos',
    text: {
      1: 'Não foram encontrados processos judiciais relacionados ao CPF.',
      3: 'Foram encontrados processos judiciais relacionados ao CPF. Importante indagar ao interessado se elas ainda existem ou se ja foram resolvidas.',
    },
  },
  negativacao: {
    title: 'Negativação',
    text: {
      1: 'Não foram encontradas restrições no CPF nos ultimos 3 meses. Considera-se um CPF ok para seguir com a proposta de locação.',
      3: 'Foram encontradas restrições no CPF com valores até R$ 100. Importante checar se o interessado tem ciência destas restrições no Serasa e se ainda estiver ativa, a pratica do mercado seria aguardar a resolução antes de prosseguir com a proposta de locação.',
      4: 'Foram encontradas restrições no CPF com valores acima de R$ 100. Importante checar se o interessado tem ciência destas restrições no Serasa e se ainda estiver ativa, a pratica do mercado seria aguardar a resolução antes de prosseguir com a proposta de locação.',
    },
  },
  score_serasa: {
    title: 'Score Serasa',
    text: {
      1: 'A pessoa interessada pertence a um grupo de score no qual possivelmente 1 em cada 10 pessoas deve apresentar débitos nos próximos 12 meses.',
      3: 'A pessoa interessada pertence a um grupo de score no qual possivelmente 2 em cada 10 pessoas deve apresentar débitos nos próximos 12 meses.',
      4: 'A pessoa interessada pertence a um grupo de score no qual possivelmente mais de 3 em cada 10 pessoas deve apresentar débitos nos próximos 12 meses.',
    },
  },
}
