import Grid from '@material-ui/core/Grid'
import FormItem from '@root/components/FormHelper/FormItem'
import {formatBrCurrencyToFloat, formatFloatToBrCurrency} from '@root/lib/moneyFormater'
import React, {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useSelector} from 'react-redux'

const GuaranteeTypeCaucaoDinheiro = () => {
  const {control, errors} = useFormContext()
  const property = useSelector((s) => s.property.data)
  const guarantee = useSelector((s) => s.property.data?.contract?.guarantees)

  const propertyAmount = property?.contract?.rentfees?.rent ?? 0

  const [total, setTotal] = useState(propertyAmount)

  const validateFloatBr = (value) => {
    const num = formatBrCurrencyToFloat(value)

    if (num <= 0) {
      return 'não pode ser 0(zero)'
    }
    if (num > 3) {
      return 'máximo 3 alugueis'
    }

    return true
  }
  const handleRentMonthsChange = (e) => setTotal(propertyAmount * formatBrCurrencyToFloat(e.target.value))

  useEffect(() => {
    const months = formatBrCurrencyToFloat(guarantee?.data?.rent_months ?? 0)
    setTotal(propertyAmount * months)
  }, [propertyAmount])

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={4} sm={2} md={3} lg={2} xl={2}>
          No valor de
        </Grid>
        <Grid item xs={3} sm={1} md={2} lg={1}>
          <FormItem
            control={control}
            errors={errors}
            type="mask"
            name="rent_months"
            label=""
            placeholder="X"
            onChange={handleRentMonthsChange}
            defaultValue={0}
            rules={{
              validate: validateFloatBr,
            }}
            maskInputProps={{
              format: '#,##',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={9} md={7} lg={9} xl={9}>
          alugueis, totalizando {formatFloatToBrCurrency(total)}
        </Grid>
      </Grid>
    </>
  )
}

export default GuaranteeTypeCaucaoDinheiro
