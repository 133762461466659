import produce from 'immer'
import reducerMatch from '@root/lib/reducerMatch'
import {RdstationTypes} from '@root/redux/rdstation/rdstation.types'

const initialState = {
  formInitial: false,
}
const reducers = {
  [RdstationTypes.SET_FORM_INITIAL]: (state, {payload}) => {
    return produce(state, (draft) => {
      draft.formInitial = payload.visible
    })
  },
}

export default reducerMatch(reducers, initialState)
