import Grid from '@material-ui/core/Grid'
import {IconWrapper} from '@root/components/IconWrapper/IconWrapper'
import {Box} from '@root/components/Layout/Container/Box/Box'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import TextSecondary from '@root/components/Layout/Typography/TextSecondary'
import Title from '@root/components/Layout/Typography/Title'
import {useStyles} from '@root/components/PropertyList/styles'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'

const isString = (value)=>typeof value === 'string'

export const HeaderButton = ({label, description, imageRef, size, icon, onClick}) => {
  const classes = useStyles()
  const descText = useMemo(() => {
    if (React.isValidElement(description)) {
      return description
    } else if (isString(description)) {
      return <TextBlock>{description}</TextBlock>
    }
    return null
  }, [description])

  const labelText = useMemo(() => {
    if (React.isValidElement(label)) {
      return label
    } else if (isString(label)) {
      return <TextSecondary size="1.1rem">{label}</TextSecondary>
    }
    return null
  }, [label])

  return (
    <Grid item xs={12} sm={size}>
      <Box onClick={onClick}>
        <div className={classes.cadastrarImovelContainer}>
          <div>
            {imageRef && <img src={imageRef} width={'60px'} />}
            <div>
              {labelText}
              {descText}
            </div>
          </div>

          {icon && <IconWrapper>{React.createElement(icon)}</IconWrapper>}
        </div>
      </Box>
    </Grid>
  )
}

HeaderButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imageRef: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.node,
  onClick: PropTypes.func,
}
HeaderButton.defaultProps = {
  size: 4,
}
